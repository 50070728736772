import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T} from "@ticketag/i18nlib";
import ChipCard from "@ticketag/ticketag-uilib/src/components/complex/ChipCard/ChipCard";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import Box from "@material-ui/core/Box";
import {Icon} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";
import ListItem from "@ticketag/ticketag-uilib/src/components/base/ListItem/ListItem";


const styles = (theme) => {
    return {
        root: {}
    }
};

export const GroupCashCard = ({amount=0, isAdmin=false, ...props}) => {
    return <ChipCard header={<T defaultVal="Cash">components.group_cash_card.header</T>} headerColor="secondary" footer="Vai a netflix" {...props}>
        <ListItem prepend={<Icon size="large" color="textPrimary">cash_circle</Icon>}>
            <Typography variant="h6" color={"textSecondary"}>{isAdmin?<T defaultVal="Ricevi">components.group_cash_card.label_admin</T>:<T defaultVal="Paghi">components.group_cash_card.label_member</T>}</Typography>
            <Box><Typography variant="subtitle1" style={{lineHeight:'24px', display:'inline-block'}} color="gradient"><span style={{fontSize: '24px'}}>{amount}€</span>/mese</Typography></Box>
        </ListItem>
    </ChipCard>
}

export default withStyles(styles, {useTheme: true})(GroupCashCard);
