import React from "react";
import {renderRoutes} from "react-router-config";
import RoutesContext from "./context";

const lang = ":locale([a-z]{2}-[A-Z]{2})?";

class RoutesProvider extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {children, routes, ...other} = this.props;
        return (
            <RoutesContext.Provider value={routes}>
                {
                    children
                }
            </RoutesContext.Provider>
        )
    }
}

export default RoutesProvider;

export function Routes(props) {
    const {routes, ...other} = props;
    return renderRoutes(routes.map((route) => ({
        ...route,
        render: (props) => React.createElement(route.component, {...props, ...route.page})
    })), other)
}


export const withRoutes = (Component) => {
    return ({ref, ...props}) => {
        return (
            <RoutesContext.Consumer>
                {(routes) => {
                    return <Component ref={ref} routes={routes} {...props}/>
                }}
            </RoutesContext.Consumer>
        )
    }
}