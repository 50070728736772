import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "../../base/Typography/Typography";
import clsx from "clsx";
import capitalize from "@material-ui/core/utils/capitalize";
import {Skeleton} from "@material-ui/lab";
import useTheme from "@material-ui/core/styles/useTheme";


const styles = (theme) => {
    const cardWidth = 296 - theme.spacing(6)
    return {
        root: (props) => ({
            cursor: props.onClick ? "pointer" : "initial",
            width: cardWidth,
            //height: 96 - theme.spacing(4),
            padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
            borderRadius: theme.spacing(2),
            position: 'relative',
            transition: "box-shadow 0.3s ease-out",
            boxShadow: theme.shadows[5],
            //boxShadow: "-10px 10px 24px rgba(172, 172, 172, 0.08), 10px -10px 24px rgba(172, 172, 172, 0.08), 10px 10px 24px rgba(172, 172, 172, 0.08)"
            "&$hover": {
                transition: "box-shadow 0.3s ease-out, transform 0.3s ease-out",
                boxShadow: theme.shadows[6],
            },
            "&$clicked": {
                transition: "box-shadow 0.1s ease-out, transform 0.1s ease-out !important",
                transform: "scale(98%)",
                boxShadow: theme.shadows[7] + "!important",
            }

        }),
        disabled: {
            opacity: "0.5"
        },
        hover: () => ({}),
        clicked: () => ({}),
        withHeader: {
            paddingTop: theme.spacing(3.5)
        },
        header: {
            position: 'absolute',
            top: -theme.spacing(3),
            borderRadius: 8,
            height: theme.spacing(4.5),
            padding: `0 ${theme.spacing(1)}px`,
        },
        headerPrimary: {
            backgroundColor: theme.palette.primary.main,
            '& h4': {
                color: theme.palette.primary.contrastText,
            }
        },
        headerSecondary: {
            backgroundColor: theme.palette.secondary.main,
            '& h4': {
                color: theme.palette.secondary.contrastText,
            }
        },
        headerError: {
            backgroundColor: theme.palette.error.main,
            '& h4': {
                color: theme.palette.error.contrastText,
            }
        },
        loader: {
            borderRadius: '16px',
            width: cardWidth,
        },
        headerLoader: {
            marginTop: theme.spacing(3)
        }
    }
};



const ChipCard = ({children, classes, header, headerColor='primary', isLoading, defaultSize=1, onClick, disabled, className, ...props}) => {
    if (header) {
        props.mt = (props.mt||0) + 3
    }

    const [hover, setHover] = React.useState(false);
    const [clicked, setClicked] = React.useState(false);


    const theme = useTheme()
    if (isLoading) {
        const calcHeight = 64 + theme.spacing(2) + (!!header ? theme.spacing(3.5) : theme.spacing(2))
        return <Skeleton className={clsx({[classes.headerLoader] : !!header})} height={defaultSize*calcHeight} variant="rect" classes={{root: classes.loader}}/>
    }

    const handleEnter = e => {
        setHover(true);
    };

    const handleLeave = e => {
        setHover(false);
    };

    const handleClick = onClick ? (e) => {
        setClicked(true)
        setTimeout(() => {
            setClicked(false)
            setTimeout(() => onClick(e), 50)
        }, 100)
    } : null


    return <Box className={clsx(disabled ? classes.disabled : null, className)} {...props}>
        <Paper onMouseEnter={handleEnter} onMouseLeave={handleLeave} onClick={handleClick}
                className={clsx(classes.root, onClick && hover ? classes.hover : null, onClick && clicked ? classes.clicked : null)}>
        {header ? <div className={clsx([classes.header, classes[`header${capitalize(headerColor)}`]])}>
            <Typography color={"white"} variant={'h3'}>{header}</Typography>
        </div>: null}
        {children}
        </Paper>
    </Box>
}

export default withStyles(styles, {useTheme: true})(ChipCard);
