import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from "../../base/Typography/Typography";
import { ListItem, ListItemSecondaryAction } from "@material-ui/core";

const styles = theme => {
  return {
    container: {
      padding: 0
    },
    title: {
      fontSize: '16px',
      lineHeight: '27px'
    },
    subtitle: {
      fontWeight: 300,
      fontSize: '14px',
      lineHeight: '17px',
      letterSpacing: '0.05em'
    },
    rightContent: {
      right: 0,
      fontWeight: 300,
      fontSize: '14px',
      lineHeight: '17px',
      letterSpacing: '0.05em'
    }
  };
};

function CheckoutListItem(props) {
  const {
    children,
    classes,
    title = null,
    subtitle = null,
    rightContent = null,
    ...other
  } = props;
  return <ListItem classes={{
    root: classes.container
  }} {...other} componentname="checkout-list-item">
        <div className={classes.left}>
            {title ? <Typography classes={{
        root: classes.title
      }} color="textSecondary" variant={'h4'}>
                    {title}
                </Typography> : null}
            {subtitle ? <Typography classes={{
        root: classes.subtitle
      }} variant={'body1'}>
                    {subtitle}
                </Typography> : null}
        </div>
        {rightContent ? <ListItemSecondaryAction classes={{
      root: classes.rightContent
    }}>
                {rightContent}
            </ListItemSecondaryAction> : null}
    </ListItem>;
}

export default withStyles(styles, {
  name: "CheckoutListItem"
})(CheckoutListItem);
