import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { Button, capitalize } from '@material-ui/core'; // We can inject some CSS into the DOM.

const styles = theme => {
  const borderRadius = theme.overrides?.MuiButton?.borderRadius || '100px'; // N.B. Le proprietà borderColor e backgroundColor non sono scambiate, le assegnazioni come background: borderColor sono intenzionali

  const outlined = (borderColor, backgroundColor) => {
    return {
      "&:not(.Mui-disabled)": {
        background: borderColor
      },
      '&:hover': {
        color: backgroundColor,
        background: borderColor
      },
      '&:hover svg': {
        fill: backgroundColor
      },
      '&:hover .btn__text-gradient': {
        '-webkit-text-fill-color': backgroundColor
      },
      '&:after': {
        background: backgroundColor
      },
      '&.Mui-disabled:after': {
        background: 'none'
      }
    };
  };

  const labelOutlined = (textColor, backgroundColor) => {
    return {
      background: textColor,
      '&:hover': {
        background: backgroundColor
      }
    };
  };

  const contained = (textColor, backgroundColor) => {
    return {
      color: textColor,
      "&:not(.Mui-disabled)": {
        background: backgroundColor
      },
      "&:hover": {
        background: backgroundColor
      }
    };
  };

  return {
    root: {
      borderRadius: borderRadius,
      transition: 'all 250ms ease-in-out !important',
      textTransform: 'uppercase',
      fontSize: '13px',
      fontWeight: 600,
      letterSpacing: '.05em',
      '&:after': {
        borderRadius: borderRadius
      },
      '&.Mui-disabled svg': {
        fill: theme.palette.action.disabled
      }
    },
    contained: contained(theme.palette.common.white, theme.palette.default_gradient || theme.palette.primary.main),
    containedGradient: contained(theme.palette.common.white, theme.palette.default_gradient || theme.palette.primary.main),
    containedPrimary: contained(theme.palette.common.white, theme.palette.primary.main),
    containedSecondary: contained(theme.palette.common.white, theme.palette.secondary.main),
    containedAccent: contained(theme.palette.common.white, theme.palette.accent.main),
    errorContained: contained(theme.palette.common.white, theme.palette.error.main),
    outlinedGradient: outlined(theme.palette.default_gradient || theme.palette.primary.main, theme.palette.common.white),
    outlinedPrimary: outlined(theme.palette.primary.main, theme.palette.common.white),
    outlinedSecondary: outlined(theme.palette.secondary.main, theme.palette.common.white),
    outlinedAccent: outlined(theme.palette.accent.main, theme.palette.common.white),
    errorOutlined: outlined(theme.palette.error.main, theme.palette.common.white),
    textGradient: labelOutlined(theme.palette.default_gradient || theme.palette.primary.main, theme.palette.common.white),
    errorTextGradient: labelOutlined(theme.palette.error.main, theme.palette.common.white),
    textPrimary: labelOutlined(theme.palette.primary.main, theme.palette.common.white),
    textSecondary: labelOutlined(theme.palette.secondary.main, theme.palette.common.white),
    textAccent: labelOutlined(theme.palette.accent.main, theme.palette.common.white),
    textDisabled: {//background: theme.palette.text.disabled,
    },
    sizeLarge: {
      padding: '13px',
      lineHeight: '25px',
      height: '48px',
      minWidth: '20%'
    },
    sizeMedium: {
      padding: '4px 13px'
    },
    sizeSmall: {
      padding: '0px 13px',
      fontSize: '12px!important',
      minWidth: '40px'
    },
    textSmall: {
      fontSize: '12px!important'
    },
    fullWidth: {
      width: '100%'
    },
    fixedWidth: {
      width: '232px'
    }
  };
};

function TButton(props) {
  const {
    classes,
    className,
    children,
    error,
    color = 'gradient',
    size = 'medium',
    variant = 'contained',
    fullWidth = false,
    fixedWidth = false,
    ...other
  } = props;
  const useClasses = ['btn'];
  const outlineLabelClasses = props.disabled ? [classes.textDisabled] : ["btn__text-gradient", error ? classes.errorTextGradient : classes[`text${capitalize(color)}`]];

  if (size === 'small') {
    outlineLabelClasses.push(classes.textSmall);
  }

  switch (variant) {
    case 'outlined':
      useClasses.push('btn--outlined');
      break;

    case 'contained':
      useClasses.push('btn--gradient');
      break;

    default:
      useClasses.push('btn--gradient');
      break;
  }

  if (className) {
    useClasses.push(className);
  }

  if (fullWidth) {
    useClasses.push(classes.fullWidth);
  }

  if (fixedWidth) {
    useClasses.push(classes.fixedWidth);
  }

  return <Button disableElevation={true} size={size} variant={variant} classes={{
    root: classes.root,
    outlined: error ? classes.errorOutlined : classes[`outlined${capitalize(color)}`],
    contained: error ? classes.errorContained : classes[`contained${capitalize(color)}`],
    sizeSmall: classes.sizeSmall,
    //sizeMedium: classes.sizeMedium,
    sizeLarge: classes.sizeLarge
  }} className={clsx(...useClasses)} {...other} componentname="t-button">
            {variant === 'outlined' ? <span className={clsx(...outlineLabelClasses)}>{props.children}</span> : props.children}
        </Button>;
}

TButton.propTypes = Button.propTypes;
export default withStyles(styles, {
  useTheme: true
})(TButton);