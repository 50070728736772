import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T, withI18n} from "@ticketag/i18nlib";
import ChipCard from "@ticketag/ticketag-uilib/src/components/complex/ChipCard/ChipCard";
import ListFormItem from "@ticketag/ticketag-uilib/src/components/base/ListItem/ListFormItem";
import Link from "@ticketag/ticketag-uilib/src/components/base/Link/Link";
import {EditCredentialsForm} from "../EditCredentialsForm/EditCredentialsForm";
import TextButton from "@ticketag/ticketag-uilib/src/components/base/Button/TextButton";
import {Skeleton} from "@material-ui/lab";
import {AlertIcon, Icon} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";
import Box from "@material-ui/core/Box";
import {withRouter} from "react-router-dom";
import Typography from  "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";


const styles = (theme) => {
    return {
        root: {},
        loader: {
            marginTop: theme.spacing(3),
            borderRadius: '16px'
        }
    }
};

export const GroupCredentialsCard = ({data, schema, modals, isAdmin=false, onChange, serviceName, disabled, isLoading, defaultSize=1, classes, ...props}) => {
    const onEdit = (k) => {
        const schemaProp = schema.properties[k]
        modals.Form(schemaProp.title, <EditCredentialsForm serviceName={serviceName} oldValue={data[k]} propName={k} schemaProp={schemaProp}/>, {size: 'sm'}).then((data) => onChange(k, data))
    }
    const items =
        schema ?
        Object.keys(schema.properties).map(k => {
        const schemaProp = schema.properties[k]
        return <ListFormItem
                    tooltipText={<T defaultVal={"Copiato!"}>global.clipboard.copied</T>}
                    key={k}
                    hiddenValue={k === 'password'}
                    disabled={disabled}
                    icon={
                        <Icon style={{fill: "transparent"}} size={"medium"} color={disabled ? 'disabled': 'secondary'} >
                            { schemaProp.icon }
                        </Icon>
                    } label={schemaProp.title}
                    action={
                        isAdmin ?
                        <TextButton disabled={disabled} onClick={() => onEdit(k)} variant="secondary">Modifica</TextButton>
                        : null
                    } value={data[k]} />
        }) : <Box p={2} pb={3}>
                <Typography variant={"body1"} style={{hyphens: "none"}}>
                    <T defaultVal={"Questo gruppo non ha credenziali."}>pages.group_details.no_credentails_message</T>
                </Typography>
    </Box>
    /*
        <ListFormItem icon="mail" label="Mail" action={<Link color="secondary">Modifica</Link>} value="it@ticketag.it" />
        <ListFormItem icon="lock" label="Password" action={<Link color="secondary">Modifica</Link>} value="*********" />
    */


    return <ChipCard isLoading={isLoading} header={'Credenziali'} headerColor="error" {...props}>
        {items}
        { !isAdmin ?
            <Box display={"flex"} alignItems={"center"} pb={3}>
                <AlertIcon color="error" style={{width: "30px", height: "30px"}}/>
                <span style={{width: "8px"}}/>
                <TextButton onClick={
                    () => {props.history.push(props.i18n.buildLocalizedUrl("groups", props.match.params.groupUuid, "dispute"))}
                } size="large" variant="error"><T defaultVal="Segnala un problema di accesso">pages.group_details.go_to_dispute</T></TextButton>
            </Box>
            : null
        }
    </ChipCard>
}

export default withStyles(styles, {useTheme: true})(withRouter(withI18n(GroupCredentialsCard)));
