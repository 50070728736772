import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T, withI18n} from "@ticketag/i18nlib";
import {withApi} from "../../hoc/Api/Provider";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box/Box";
import {withModals} from "@ticketag/ticketag-uilib/src/components/hoc/Modals/Provider";
import {withRouter} from "react-router-dom";
import ComboBox from "@ticketag/ticketag-uilib/src/components/base/Select/ComboBox";
import NextButton from "../../components/NextButton/NextButton";
import Title from "../../components/Title/Title";
import Scroll from "../../components/Scroll/Scoll";
import TextFooter from "../../components/TextFooter/TextFooter";
import Help from "../../components/Help/Help";
import Subtitle from "../../components/Subtitle/Subtitle";
import {withRedirect} from "../../components/ErrorMessage/ErrorMessage";
import {pages} from "../../constants";
import Page from "../../components/Page/Page";


const styles = (theme) => {
    return {
        root: {

        },
        nextButton: {
            width: "80px",
            height: "80px",
            borderRadius: "50%",
            marginBottom: "40px"
        },
        endSection: {
            height: "100%",
            "& > *": {
                height: "100%",
                alignItems: "flex-end"
            }
        }
    }
};

class GroupSize extends Component {

    constructor(props) {//
        super(props);
        this.state = {
            size: this.props.group.size
        }
    }

    handleNext() {
        this.props.group.updateGroup({size: this.state.size}).then(() => {
            this.props.history.push(this.props.i18n.buildLocalizedUrl("groups", this.props.group.uuid, "create", "credentials"))
        })
    }

    setGroupSize(size) {
        this.setState({size: size})
    }

    renderContent() {
        const {group} = this.props;
        const maxSize = group.service.maxSize
        const options = [...Array(maxSize).keys()].map((n) => ({value: n + 1, label: `${n + 1}`}))
        return (
            <Grid container justify={"center"}>
                <Grid container item xs={12} justify={"center"} alignItems={"center"} className={this.props.classes.root} spacing={2}>
                    <Grid item xs={12}>
                        <Box display={"flex"} justifyContent={"center"} px={2}>
                            <ComboBox value={this.state.size}
                                      size={"big"} variant={"shadowed"} shape={"rounded"} label={"posti disponibili"} options={options}
                                      onChange={(e, val) => this.setGroupSize(val)}/>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>

        )
    }

    render() {
        const {size} = this.state
        const {group, auth, i18n, history} = this.props;
        const {
            seo,
            title,
            subtitle,
            footer,
            helpTitle,
            layout,
            ...other
        } = this.props;
        return (
            <Page layout={{
                ...layout,
                onGoBack: () => this.props.onGoBack(),
                onClose: () => this.props.onClose()
            }} seo={seo}>
                <Title>{title}</Title>
                <Subtitle>{subtitle}</Subtitle>
                <Scroll>
                    {this.renderContent()}
                </Scroll>
                <NextButton onClick={() => this.handleNext()} disabled={size === 0}/>
                <TextFooter
                    helpTitle={helpTitle}
                    help={
                        <Help id={"group_size"}>
                            {"Posti disponibili"}
                        </Help>
                    }>
                    {footer}
                </TextFooter>
            </Page>
        )
    }
}

export default withStyles(styles, {useTheme: true})(withRouter(withModals(withApi(withI18n(withModals(GroupSize))))));
