import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { ExpandMoreIcon } from "../Icon/Icon";

const styles = theme => {
  return {
    root: {
      '&.Mui-expanded': {
        minHeight: 0
      }
    },
    content: {
      cursor: "pointer",
      color: theme.palette.common.black,
      '&.Mui-expanded': {
        margin: '12px 0'
      },
      fontSize: "16px",
      fontWeight: "700"
    }
  };
};

function TAccordionSummary(props) {
  const {
    children,
    ...other
  } = props;
  return <AccordionSummary expandIcon={<ExpandMoreIcon color={"black"} />} {...other} componentname="t-accordion-summary">{children}</AccordionSummary>;
}

TAccordionSummary.propTypes = AccordionSummary.propTypes;
export default withStyles(styles, {
  name: "MuiAccordionSummary"
})(TAccordionSummary);
