import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {Box} from "@material-ui/core";
import clsx from "clsx";
import Controller from "./Controller";
//import {T} from "@ticketag/i18nlib";

const styles = (theme) => {
    return {
        root: {},
        grid: {
            marginTop: 0,
            marginBottom: 0
        },
        helperTextPositionAbsolute: {
            "& .MuiFormHelperText-root": {
                transform: "translateY(20px)",
                position: "absolute",
                bottom: "0"
            }
        }
    }
};

class Form extends Component {
    #formFields;
    #extraValues;

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        }

        this.#formFields= {}
        this.#extraValues = {}
        this.errors={}

        this.isValid = false
        this.isDirty = false
        this.isTouched = false

        this.values= props.defaultValues ? {...props.defaultValues} : {}
    }

    componentDidMount() {
        if (this.props.formRef) {
            this.props.formRef(this)
        }
        Promise.all(this.getFormFields().map(({name, field}) => {
            return field.validate()
        })).then(results => {
            return this.validate(results);
        })
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        /*if (prevProps.form !== this.props.form && this.props.formRef) {
            this.props.formRef(this)
        }*/

    }

    get formState() {
        return {
            isValid: this.isValid,
            isDirty: this.isDirty,
            isTouched: this.isTouched,
            errors: {}
        }
    }

    get fields() {

    }

    submit() {
        return this.form.submit()
    }

    onSubmit(e) {
        if (this.props.onSubmit) {
            this.props.onSubmit(e, this)
        }
    }

    registerField(name, ref) {
        this.#formFields[name] = ref
    }

    getFormFields() {
        return Object.keys(this.#formFields).map(k => ({name: k, field: this.#formFields[k]}))
    }

    trigger(fieldName) {
        this.isDirty = true
        this.isTouched = true
        const formFields = this.getFormFields().filter(({name, field}) => !fieldName || name === fieldName)
        return Promise.all(formFields.map(({name, field}) => {
            return field.trigger()
        })).then(results => {
            return this.validate(results);
        })
    }

    validate(results) {
        let isValid = true
        let isDirty = this.isDirty
        let isTouched = this.isTouched
        results.forEach(({state, name}) => {
            isValid &= state.isValid
            isDirty |= state.isDirty
            isTouched |= state.isTouched
            this.errors[name] = state.error
        })

        const errFields = this.getFormFields().map(({name}) => this.errors[name]).filter(hasErr => hasErr)
        this.isValid = errFields.length === 0
        this.isDirty = !!isDirty
        this.isTouched = !!isTouched
        this.props.onValidate ? this.props.onValidate(this.formState) : null
        return {
            isValid: this.isValid,
            isDirty: this.isDirty,
            isTouched: this.isTouched,
        }
    }

    setValue(fieldName, value) {
        if (!this.#formFields[fieldName]) {
            this.#extraValues[fieldName] = value
            return
        }
        this.#formFields[fieldName].value = value
    }

    register(field) {

    }

    getValues(name) {
        const values = {...this.props.defaultValues}
        for (const fieldName in this.#formFields) {
            values[fieldName] = this.#formFields[fieldName]?.value
        }
        for (const extraVal in this.#extraValues) {
            values[extraVal] = this.#extraValues[extraVal]
        }
        return name? values[name] : values
    }

    renderFormControl(props, ctrl) {
        const T = this.props.T||(({children, ...props}) => children)
        const showError = !props.isValid && props.isTouched && this.isDirty
        const showMessage = showError && props.error?.message
        return React.createElement(ctrl.type, {
            ...ctrl.props,
            onBlur: (evt, value) => {
                ctrl.props.onBlur ? ctrl.props.onBlur(evt, value) : null
                props.onBlur(evt, value)
            },
            onChange: (evt, value) => {
                this.setValue(ctrl.props.name, value)
                props.onChange(evt, value)
                this.props.onChange ? this.props.onChange(this.getValues(), this.formState) : null
                ctrl.props.onChange ? ctrl.props.onChange(evt, value) : null
                this.trigger(ctrl.props.name).then(({isValid}) => {
                    this.props.onChange ? this.props.onChange(this.getValues(), this.formState) : null
                })
            },
            onFocus: (evt) => {
                props.onFocus(evt)
                ctrl.props.onFocus ? ctrl.props.onFocus(evt) : null
            },
            //value: this.props.defaultValues[props.name],
            defaultValue: (this.props.defaultValues ? this.props.defaultValues[ctrl.props.name] : undefined) || ctrl.props.defaultValue,
            error: showError,
            disabled: typeof this.props.disabled !== 'undefined' ? this.props.disabled : ctrl.props.disabled,
            helperText: showMessage ? (typeof props.error.message === 'object' ? <T key={props.error.message.key} defaultVal={props.error.message.defaultVal}>{props.error.message.key}</T> : props.error.message) : props.helperText,
        })
    }

    renderController(c) {
        if (!c.props?.name) {
            return c
        }
        const {box = {}, grid = {}, ...props} = c.props
        const gridProps = {xs: 12, md: 6, ...grid}
        const boxProps = {my:3, ...box}

        return <Grid key={props.name} item {...gridProps}>
            <Box {...boxProps} className={clsx(boxProps.className, this.props.helperTextPosition === "absolute" ? this.props.classes.helperTextPositionAbsolute : null)}>
                <Controller
                    name={props.name}
                    control={c}
                    form={this}
                    validationMode={'onChange'}
                    ref={r => this.registerField(props.name, r)}
                    defaultValue={this.props.defaultValues ? this.props.defaultValues[props.name] : undefined}
                    rules={props.rules||{}}
                    render={(props) => this.renderFormControl(props, c)}/>
            </Box>
        </Grid>
    }

    render() {
        const children = Array.isArray(this.props.children) ? this.props.children : [this.props.children]
        const flatChildren = []
        const formGridProps = {spacing: 0, ...this.props.grid || {}}

        children.forEach(c => {
            if (c.props?.name) {
                flatChildren.push(this.renderController(c))
            } else if (Array.isArray(c)) {
                flatChildren.push(...(c.map(item => this.renderController(item))))
            } else {
                flatChildren.push(c)
            }
        })

        const {method, action} = this.props
        return <form ref={this.props.htmlFormRef} method={method} action={action} onLoad={(e) => {this.props.onLoad ? this.props.onLoad(e, this):null}} onSubmit={(e) => {this.onSubmit(e)}} disabled={this.props.disabled} className={clsx(this.props.classes.root)}>
            <Grid classes={{root: this.props.classes.grid}} container {...formGridProps}>
                {flatChildren}
                {this.props.formButtons ?
                    <Grid xs={12} item>
                        {this.props.formButtons}
                    </Grid>: null}
            </Grid>
        </form>
    }
}

const WithForm = (Component) => {
    let counter = 0
    return ({form, defaultValues, ...props}) => {
        //form = form||useForm() //{defaultValues} form={form}
        return <Component defaultValues={defaultValues} {...props}/>
    }
}

export default withStyles(styles, {useTheme: true})(Form);
