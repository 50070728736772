import React, {Component} from 'react';
import CookieContext from './context';
import {T, withI18n} from "@ticketag/i18nlib";
import {withApi} from "../Api/Provider";
import {WebapiprotoPageName as PAGE_NAMES} from "@ticketag/diveedi-client/dist/model/WebapiprotoPageName";
import PasswordTextField from "@ticketag/ticketag-uilib/src/components/base/TextField/PasswordTextField";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import Checkbox from "@ticketag/ticketag-uilib/src/components/base/Checkbox/Checkbox";
import Form from "@ticketag/ticketag-uilib/src/components/complex/Form/Form";
import {withModals} from "@ticketag/ticketag-uilib/src/components/hoc/Modals/Provider";
import withStyles from "@material-ui/core/styles/withStyles";
import Box from "@material-ui/core/Box";
import {Icon} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";
import IconButton from "@material-ui/core/IconButton";
import CheckboxGroup from "@ticketag/ticketag-uilib/src/components/base/Checkbox/CheckboxGroup";
import Dialog from "@ticketag/ticketag-uilib/src/components/hoc/Modals/Dialog"
import Button from "@ticketag/ticketag-uilib/src/components/base/Button/Button"

import {DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import AuthContext from "../Auth/context";
import moment from "moment";


const styles = (theme) => {
    return {
        title: {
            "& h3": {
                marginTop: "4px",
                fontSize: "24px",
                fontWeight: "600",
            },
            paddingTop: "32px",
            paddingBottom: "16px"
        },
        content: {
            paddingBottom: theme.spacing(2),
            paddingLeft:   theme.spacing(5),
            paddingRight:  theme.spacing(5),
            [theme.breakpoints.up('sm')]: {
                maxWidth:  '512px',
                alignSelf: 'center',
                margin:    'auto'
            },
        },
        text: {
            textAlign: "justify",
            marginBottom: theme.spacing(4),
            fontSize: "14px"
        },
        actions:        {
            display:                        'flex',
            justifyContent:                 'center',
            paddingTop:                     theme.spacing(3),
            paddingBottom:                  theme.spacing(5),
            paddingLeft:                    theme.spacing(4),
            paddingRight:                   theme.spacing(4),
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column-reverse',
                paddingBottom: theme.spacing(2),
            },
        },
        buttons:        {
            minWidth:                       '160px',
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
                margin:        theme.spacing(1),
            },
        },
        collapse: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(3),
        }
    }
}

const CookieSvg = ({width = 156, height = 156}) => <svg width={width} height={height} viewBox="0 0 156 155" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M117.139 6.91684C119.052 6.91684 120.603 5.36846 120.603 3.45842C120.603 1.54839 119.052 0 117.139 0C115.226 0 113.676 1.54839 113.676 3.45842C113.676 5.36846 115.226 6.91684 117.139 6.91684Z" fill="#D1A144"/>
    <path d="M117.139 34.27C119.052 34.27 120.603 32.7216 120.603 30.8116C120.603 28.9015 119.052 27.3531 117.139 27.3531C115.226 27.3531 113.676 28.9015 113.676 30.8116C113.676 32.7216 115.226 34.27 117.139 34.27Z" fill="#D1A144"/>
    <path d="M151.777 41.1866C153.69 41.1866 155.241 39.6382 155.241 37.7282C155.241 35.8182 153.69 34.2698 151.777 34.2698C149.864 34.2698 148.313 35.8182 148.313 37.7282C148.313 39.6382 149.864 41.1866 151.777 41.1866Z" fill="#D1A144"/>
    <path d="M146.082 52.8684C144.636 50.4863 139.583 50.5478 139.583 50.5478C134.983 57.3721 123.925 50.123 123.925 50.123C115.712 53.7917 110.701 42.1005 110.701 42.1005C99.6498 40.4505 101.357 28.8621 101.357 28.8621C95.859 27.7599 97.4017 17.8348 97.4017 17.8348C97.4017 17.8348 100.453 15.3364 98.9512 13.5088C97.9968 12.3469 97.9375 9.01222 98.0209 6.73577C90.8461 4.55718 83.3876 3.45268 75.8884 3.45827C33.9764 3.45827 0 37.382 0 79.2291C0 121.076 33.9764 155 75.8884 155C117.8 155 151.777 121.076 151.777 79.2291C151.786 70.1379 150.153 61.1196 146.955 52.6073C146.566 53.0462 146.264 53.1669 146.082 52.8684Z" fill="#D1A144"/>
    <path opacity="0.1" d="M146.082 52.8684C144.636 50.4863 139.583 50.5478 139.583 50.5478C134.983 57.3721 123.925 50.123 123.925 50.123C115.712 53.7917 110.701 42.1005 110.701 42.1005C99.6498 40.4505 101.357 28.8621 101.357 28.8621C95.859 27.7599 97.4017 17.8348 97.4017 17.8348C97.4017 17.8348 100.453 15.3364 98.9512 13.5088C97.9968 12.3469 97.9375 9.01222 98.0209 6.73577C90.8461 4.55718 83.3876 3.45268 75.8884 3.45827C33.9764 3.45827 0 37.382 0 79.2291C0 121.076 33.9764 155 75.8884 155C117.8 155 151.777 121.076 151.777 79.2291C151.786 70.1379 150.153 61.1196 146.955 52.6073C146.566 53.0462 146.264 53.1669 146.082 52.8684Z" fill="black"/>
    <path d="M141.585 52.5694C140.209 50.304 135.404 50.3624 135.404 50.3624C131.029 56.8527 120.512 49.9585 120.512 49.9585C112.701 53.4476 107.936 42.3286 107.936 42.3286C97.4254 40.7595 99.0492 29.7383 99.0492 29.7383C93.8202 28.6901 95.2874 19.2508 95.2874 19.2508C95.2874 19.2508 98.1888 16.8747 96.761 15.1366C95.8533 14.0315 95.797 10.8601 95.8762 8.6951C89.0527 6.62315 81.9592 5.5727 74.8271 5.57802C34.9668 5.57802 2.65356 37.8412 2.65356 77.6398C2.65356 117.438 34.9668 149.702 74.8271 149.702C114.687 149.702 147.001 117.438 147.001 77.6398C147.009 68.9936 145.456 60.4167 142.415 52.3212C142.045 52.7386 141.757 52.8533 141.585 52.5694Z" fill="#D1A144"/>
    <path d="M66.2097 48.1784C66.2097 48.1784 53.7638 40.2705 41.8835 48.1784C37.7255 50.9461 35.6464 54.8902 34.7005 58.8481C33.0185 65.8853 34.8631 73.3042 39.3607 78.9766L66.237 112.873C67.1587 114.054 68.3946 114.952 69.8034 115.465C71.2122 115.977 72.737 116.084 74.2035 115.772C74.2617 115.759 74.3202 115.745 74.3792 115.731C75.4412 115.47 76.4385 114.995 77.3096 114.334C78.1807 113.674 78.9071 112.843 79.4441 111.891L98.1355 79.1181C101.593 73.1498 103.011 66.2195 102.173 59.3754C101.297 52.6354 98.0553 45.5306 88.8387 43.0947C88.8387 43.0947 78.0899 38.576 66.2097 48.1784Z" fill="#885B05"/>
    <path opacity="0.1" d="M66.2097 48.1784C66.2097 48.1784 53.7638 40.2705 41.8835 48.1784C37.7255 50.9461 35.6464 54.8902 34.7005 58.8481C33.0185 65.8853 34.8631 73.3042 39.3607 78.9766L66.237 112.873C67.1587 114.054 68.3946 114.952 69.8034 115.465C71.2122 115.977 72.737 116.084 74.2035 115.772C74.2617 115.759 74.3202 115.745 74.3792 115.731C75.4412 115.47 76.4385 114.995 77.3096 114.334C78.1807 113.674 78.9071 112.843 79.4441 111.891L98.1355 79.1181C101.593 73.1498 103.011 66.2195 102.173 59.3754C101.297 52.6354 98.0553 45.5306 88.8387 43.0947C88.8387 43.0947 78.0899 38.576 66.2097 48.1784Z" fill="black"/>
    <path d="M66.336 50.0864C66.336 50.0864 54.6608 42.6683 43.5164 50.0864C39.6158 52.6828 37.6655 56.3826 36.7782 60.0953C35.2004 66.6967 36.9307 73.6562 41.1498 78.9773L66.3616 110.775C67.2262 111.882 68.3855 112.725 69.7071 113.206C71.0286 113.686 72.459 113.786 73.8347 113.494C73.8893 113.481 73.9442 113.469 73.9995 113.455C74.9958 113.211 75.9313 112.765 76.7484 112.145C77.5655 111.526 78.247 110.746 78.7507 109.853L96.2846 79.11C99.5284 73.5113 100.858 67.0102 100.072 60.59C99.2501 54.2674 96.2093 47.6027 87.5635 45.3176C87.5635 45.3176 77.4804 41.0787 66.336 50.0864Z" fill="#885B05"/>
    <path opacity="0.1" d="M66.79 62.8064C66.79 62.8064 61.1098 59.1973 55.6877 62.8064C54.0041 63.944 52.8287 65.6899 52.4094 67.676C52.0464 69.2683 52.0491 70.9219 52.4175 72.5129C52.7859 74.1039 53.5102 75.591 54.5363 76.8626C54.5363 76.8626 69.0787 93.9597 70.4384 93.6557C70.4649 93.6498 70.4917 93.6436 70.5186 93.6371C71.4983 93.4022 81.3608 76.9272 81.3608 76.9272C82.939 74.2033 83.5859 71.0403 83.2037 67.9167C82.8037 64.8406 81.3242 61.598 77.1178 60.4863C77.1178 60.4863 72.2121 58.4239 66.79 62.8064Z" fill="black"/>
    <path opacity="0.1" d="M31.3113 89.8267L32.9034 92.4761L36.0872 97.7747L37.1487 101.484L35.026 104.663L37.6793 111.021L38.7408 114.73L36.6181 116.85L40.8635 120.029" fill="black"/>
    <path opacity="0.1" d="M109.322 66.5125V70.7515L106.668 77.6398L105.076 81.3488L107.73 86.1176L108.791 89.2968L113.036 90.8864L114.628 93.5359V99.8941" fill="black"/>
    <path opacity="0.1" d="M136.387 70.7515L132.142 73.4008C132.142 73.4008 130.019 74.4605 130.019 74.9904V79.2293" fill="black"/>
    <path opacity="0.1" d="M117.282 55.915L118.344 61.2137L117.282 64.923" fill="black"/>
    <path opacity="0.1" d="M121.528 103.603L118.343 108.372L114.629 111.551V113.67L116.751 116.32V119.499L115.159 122.678" fill="black"/>
    <path opacity="0.1" d="M91.2787 13.526H85.9719L81.7262 17.235L74.8273 20.944L71.6432 23.5935V24.6533L64.7443 28.3623L62.6216 32.0713" fill="black"/>
    <path opacity="0.1" d="M33.9639 22.5334L29.1877 32.071L27.5956 35.7801L23.8808 40.5489C23.8808 40.5489 21.2274 45.3177 20.6967 45.8476C20.166 46.3774 16.4512 47.4372 16.4512 47.4372L21.7581 52.7358" fill="black"/>
    <path opacity="0.1" d="M12.7369 59.0942L11.6754 65.9825L12.7369 70.2215V77.6396L9.55273 91.416L12.7369 95.125L16.4516 99.8939" fill="black"/>
    <path opacity="0.1" d="M20.6968 62.2738L23.8809 63.8634L26.0036 70.7517L30.2493 69.6918L32.3719 73.4011" fill="black"/>
    <path opacity="0.1" d="M79.072 131.156L87.563 130.096L91.2778 126.387H96.5846" fill="black"/>
    <path opacity="0.1" d="M27.5957 120.029L30.7799 123.738L36.0867 125.327L39.8018 126.387L40.863 132.216" fill="black"/>
    <path opacity="0.1" d="M90.2168 102.014L93.9319 99.3641L95.5239 95.125L98.7081 94.5952L100.3 89.2966" fill="black"/>
</svg>

class CookieProvider extends Component {
    constructor(props) {
        super(props);
        this.form = null
        this.state = {
            cookieData: {},
            cookieValues: {},
            open: false,
            edit: false,
        }
    }

    isStandalone() {
        return (window.matchMedia('(display-mode: standalone)').matches) || window.navigator?.standalone || document.referrer?.includes('android-app://');
    }

    componentDidMount() {
        if (!this.isStandalone()) {
            const cookies = this.getCookies()
            if (!cookies) {
                this.getCookiePage(this.props.i18n.selectedLocale()).
                then(() => {
                    this.setState({open: true})
                })
            } else {
                this.setCookies({...cookies.values})
            }
        }
    }

    getCookiePage(locale) {
        return this.props.api.GetPage(PAGE_NAMES.cookieConsent, {lang: locale}).
        then((data) => {
            this.defaultCookieValues = data.cards.reduce((acc, elt) => {
                acc[elt.key] = elt.checked
                return acc
            }, {})
            this.setState({
                cookieData: data,
                cookieValues: this.defaultCookieValues,
                isLoaded:true
            })
            console.log(this.state)
        })
    }

    getCookies() {
        return JSON.parse(localStorage.getItem('CookieConsent'))
    }

    setCookies(cookieValues) {
        const now = moment(Date.now()).format()
        const prevCookies = this.getCookies()
        localStorage.setItem('CookieConsent', JSON.stringify({
            createdAt: prevCookies?.createdAt || now,
            updatedAt: now,
            values: {...cookieValues?.values, ...cookieValues}
        }))
    }

    acceptCookies(cookieValues) {
        Promise.resolve(this.setCookies(cookieValues)).then(() => {
            this.setState({open: false})
        })
    }

    render() {
        return (
            <>
                <CookieContext.Provider value={
                    {
                        getCookies : this.getCookies,
                        setCookies : this.setCookies,
                    }
                }>
                    {this.props.children}
                </CookieContext.Provider>
                <Dialog open={this.state.open} size={"md"} className={this.props.classes.dialog} fullScreen={this.state.edit}>
                    <DialogTitle disableTypography={true} className={this.props.classes.title}>
                        {this.state.edit ?
                            <Box display={"flex"} justifyContent={"center"}>
                                <CookieSvg width={42} height={42}/>
                                <span style={{width: "12px"}}/>
                                <Typography variant={"h3"} color={"textSecondary"} align={"center"}><T
                                    defaultVal={"I nostri cookie"}>modals.cookie.title</T></Typography>
                            </Box>
                            :
                            <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                                <CookieSvg/>
                                <Typography variant={"h3"} color={"textSecondary"} align={"center"}><T
                                    defaultVal={"I nostri cookie"}>modals.cookie.title</T></Typography>
                            </Box>
                        }
                    </DialogTitle>
                    <DialogContent className={this.props.classes.content}>
                        {
                            this.state.edit ?
                                <Box pb={4}>
                                    <CheckboxGroup classes={{collapse: this.props.classes.collapse}} items={this.state.cookieData.cards.map((elt) => ({...elt, expanded: true}))} onChange={(e, val) => {
                                        const cookieValues = this.state.cookieValues
                                        cookieValues[e.target.name] = val
                                        this.setState({...this.state, cookieValues: cookieValues})
                                    }}/>
                                </Box>
                                :
                                <Typography variant={"body2"} color={"textPrimary"} className={this.props.classes.text} align={"center"}>
                                    <T defaultVal={`Utilizziamo dei cookie al fine di fornire i nostri servizi online. In particolare, utilizziamo cookie analiti e tecnici. Non utilizziamo cookie marketing per profilarti. Cliccando su accetta tutti accetti l’uso di tutti i nostri cooki. Cliccando su gestici puoi scegliere se disabilitarne alcuni.`}>modals.cookie.text</T>
                                </Typography>

                        }
                    </DialogContent>
                    <DialogActions  classes={{root: this.props.classes.actions}}>
                        {this.state.edit ?
                            <>
                                <Button className={this.props.classes.buttons} onClick={() => this.acceptCookies(this.state.cookieValues)}>Salva preferenze</Button>
                                <Button variant={"outlined"} color={"primary"} onClick={() => this.acceptCookies(this.defaultCookieValues)} className={this.props.classes.buttons}>Accetta tutti</Button>
                            </>
                            :
                            <>
                                <Button className={this.props.classes.buttons} onClick={() => this.acceptCookies(this.defaultCookieValues)}>Accetta tutti</Button>
                                <Button variant={"outlined"} color={"primary"} onClick={() => this.setState({edit: true})} className={this.props.classes.buttons}>Gestisci</Button>
                            </>
                        }
                    </DialogActions>
                </Dialog>
            </>
        )
    }
}

export const withCookies = (Component) => {
    return ({ref, ...props}) => (<CookieContext.Consumer>
        {(cookies) => {
            return <Component ref={ref} cookies={cookies} {...props}/>
        }}
    </CookieContext.Consumer>)
}

export default withStyles(styles, {})(withI18n(withApi(withModals(CookieProvider))));
