import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import { Icon } from "../../base/Icon/Icon";
import Typography from "../../base/Typography/Typography";
import { Box, capitalize } from "@material-ui/core";
import clsx from "clsx";
import BaseActionCard from "../BaseActionCard/BaseActionCard";
import List from "../../base/List/List";

const styles = theme => {
  return {
    root: {
      paddingTop: theme.spacing(2),
      position: 'relative'
    },
    title: {
      fontSize: "28px",
      fontWeight: "500",
      marginBottom: theme.spacing(5)
    },
    header: {
      fontSize: "20px",
      fontWeight: "500",
      marginBottom: theme.spacing(1)
    },
    listBox: {
      alignSelf: 'flex-start'
    },
    list: {},
    footer: {
      position: 'absolute',
      bottom: theme.spacing(3)
    }
  };
};

function ListCard({
  header,
  title,
  onClick,
  classes,
  children,
  items = [],
  footer = null,
  ...props
}) {
  return <BaseActionCard classes={{
    root: classes.root
  }} onClick={onClick} {...props} componentname="list-card">
        <Typography classes={{
      root: classes.header
    }} color={'secondary'} variant={'h4'}>{header}</Typography>
        <Typography classes={{
      root: classes.title
    }} color={'textSecondary'} variant={'h3'}>{title}</Typography>
        <Box classes={{
      root: classes.listBox
    }}>
            <List classes={{
        root: classes.list
      }}>
                {items.map((item, i) => <Typography key={i} variant={'h6'}>{item}</Typography>)}
            </List>
        </Box>
        {footer ? <Box classes={{
      root: classes.footer
    }}>{footer}</Box> : null}
    </BaseActionCard>;
}

export default withStyles(styles, {
  name: "ListCard"
})(ListCard);
