/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';
import {WebapiprotoTransactionType} from './WebapiprotoTransactionType';

/**
 * The WebapiprotoTransaction model module.
 * @module model/WebapiprotoTransaction
 * @version v0.4
 */
export class WebapiprotoTransaction {
  /**
   * Constructs a new <code>WebapiprotoTransaction</code>.
   * @alias module:model/WebapiprotoTransaction
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>WebapiprotoTransaction</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/WebapiprotoTransaction} obj Optional instance to populate.
   * @return {module:model/WebapiprotoTransaction} The populated <code>WebapiprotoTransaction</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new WebapiprotoTransaction();
      if (data.hasOwnProperty('amount'))
        obj.amount = ApiClient.convertToType(data['amount'], 'Number');
      if (data.hasOwnProperty('date'))
        obj._date = ApiClient.convertToType(data['date'], 'Date');
      if (data.hasOwnProperty('from_uuid'))
        obj.fromUuid = ApiClient.convertToType(data['from_uuid'], 'String');
      if (data.hasOwnProperty('from_name'))
        obj.fromName = ApiClient.convertToType(data['from_name'], 'String');
      if (data.hasOwnProperty('to_uuid'))
        obj.toUuid = ApiClient.convertToType(data['to_uuid'], 'String');
      if (data.hasOwnProperty('to_name'))
        obj.toName = ApiClient.convertToType(data['to_name'], 'String');
      if (data.hasOwnProperty('account_date'))
        obj.accountDate = ApiClient.convertToType(data['account_date'], 'Date');
      if (data.hasOwnProperty('state'))
        obj.state = ApiClient.convertToType(data['state'], 'String');
      if (data.hasOwnProperty('type'))
        obj.type = WebapiprotoTransactionType.constructFromObject(data['type']);
      if (data.hasOwnProperty('service_name'))
        obj.serviceName = ApiClient.convertToType(data['service_name'], 'String');
      if (data.hasOwnProperty('payment_method'))
        obj.paymentMethod = ApiClient.convertToType(data['payment_method'], 'String');
      if (data.hasOwnProperty('fee'))
        obj.fee = ApiClient.convertToType(data['fee'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {Number} amount
 */
WebapiprotoTransaction.prototype.amount = undefined;

/**
 * @member {Date} _date
 */
WebapiprotoTransaction.prototype._date = undefined;

/**
 * @member {String} fromUuid
 */
WebapiprotoTransaction.prototype.fromUuid = undefined;

/**
 * @member {String} fromName
 */
WebapiprotoTransaction.prototype.fromName = undefined;

/**
 * @member {String} toUuid
 */
WebapiprotoTransaction.prototype.toUuid = undefined;

/**
 * @member {String} toName
 */
WebapiprotoTransaction.prototype.toName = undefined;

/**
 * @member {Date} accountDate
 */
WebapiprotoTransaction.prototype.accountDate = undefined;

/**
 * @member {String} state
 */
WebapiprotoTransaction.prototype.state = undefined;

/**
 * @member {module:model/WebapiprotoTransactionType} type
 */
WebapiprotoTransaction.prototype.type = undefined;

/**
 * @member {String} serviceName
 */
WebapiprotoTransaction.prototype.serviceName = undefined;

/**
 * @member {String} paymentMethod
 */
WebapiprotoTransaction.prototype.paymentMethod = undefined;

/**
 * @member {Number} fee
 */
WebapiprotoTransaction.prototype.fee = undefined;


