import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import ListItem from "./ListItem";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Link from "../Link/Link";
import TextButton from "../Button/TextButton";
import {Icon} from "../Icon/Icon";
import Typography from "../Typography/Typography";
import clsx from "clsx";
import Clipboard from "../Clipboard/Clipboard";
import ToggleVisibility from "../TextField/utils/ToggleVisibility";


const styles = (theme) => {
    return {
        root: {
            maxWidth: '100%',
        },
        clipboard: {
            textAlign: 'center'
        },
        clipboardDisabled: {
            cursor: 'not-allowed'
        },
        actionBox: {
            cursor: 'pointer'
        },
        valueText: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            flexShrink: 3
        },
        valuePassword: {
          maxWidth: "calc(100% - 40px)"
        },
        valueContainer: {
            minWidth: 0,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        copyText: {
            display: "block",
            fontWeight: "500"
        },
        toggleVisibility: {
            padding: 0,
            marginRight: "0",
            float: "right"
        }
    };
}

export const ListFormItem = ({label, action, value, labelColor = 'textSecondary', icon, hiddenValue=false, onClick, classes, disabled, tooltipText, ...props}) => {
    const {actionBox, valueText, valueContainer, clipboard, copyText, clipboardDisabled, ...otherClasses} = classes
    const [showPassword, setShowPassword] = React.useState(hiddenValue);
    const handleClickShowPassword = (prop) => {
        setShowPassword(!showPassword);
    };
    const prependBtn = <Box classes={{root: actionBox}} display="flex" flexDirection="column" alignItems="center">
        <Clipboard tooltipText={tooltipText} className={clsx(clipboard, {[clipboardDisabled]: disabled})} component="div" data-clipboard-text={value}>
            <Box>
                {typeof icon === 'string' ? <Icon color={disabled ? 'disabled': 'secondary'} size="medium">{icon}</Icon> : icon}
                <Typography color={disabled ? 'disabled': 'secondary'} className={copyText} variant={'caption'}>COPIA</Typography>
            </Box>
        </Clipboard>
    </Box>
    return <ListItem prepend={prependBtn} classes={otherClasses} {...props}>
        <Box>
            <Box display="flex" alignItems="center">
                <Typography variant="h6" color="textSecondary">{label}</Typography>
                {action ? <><Divider style={{margin: '0px 8px'}} flexItem={true} orientation="vertical"/>
                    <TextButton disabled={disabled} onClick={onClick} color="secondary">{action}</TextButton></> : null}
            </Box>
            <Box classes={{root:valueContainer}} className={hiddenValue ? classes.valuePassword : null}>
                <Typography classes={{root:valueText}} variant="caption">{showPassword ? '••••••••' : value}</Typography>
                {
                    hiddenValue ? <ToggleVisibility className={classes.toggleVisibility} onClick={handleClickShowPassword}/> : null
                }
            </Box>
        </Box>
    </ListItem>
}

export default withStyles(styles, {useTheme: true})(ListFormItem);
