

export const pages = {
    GROUPS: "groups",
    HOMEPAGE: "homepage",
    FAQ: "faq",
    PRIVACY_POLICY: "privacy_policy",
    TERMS_AND_CONDITIONS: "terms_and_conditions",
    SUGGESTIONS: "suggestions",
    ERROR: "error",
    WELCOME: "welcome",
    PROFILE: "profile",
    WALLET: "wallet",
    CHAT: "chat",
    INVITE: "invite",
    SERVICES: "services",
    JOIN_CREATE_GROUP: "join_create_group",
    SELECT_SERVICE_FAMILY: "select_service_family",
    SELECT_SERVICE_TYPE: "select_service_type",
    SELECT_JOIN_SERVICE_TYPE: "select_join_service_type",
    SELECT_CREATE_SERVICE_TYPE: "select_create_service_type",
    SELECT_JOIN_GROUP: "select_join_group",
    PAYMENT: "join_payment",
    JOIN_GROUP: "join_group",
    JOIN_SUMMARY: "join_summary",
    JOIN_PROCESSING: "join_processing",
    JOIN_SUCCESS: "join_success",
    JOIN_FAILED: "join_failed",
    CREATE_GROUP: "create_group",
    CREATE_VISIBILITY: "create_visibility",
    CREATE_SIZE: "create_size",
    CREATE_CREDENTIALS: "create_credentials",
    CREATE_SUMMARY: "create_summary",
    CREATE_SUCCESS: "create_success",
    GROUP_DETAILS: "group_details",
    GROUP_SETTINGS: "group_settings",
    GROUP_SHARE: "group_share",
    GROUP_DISPUTE: "group_dispute",
}