/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';
import {WebapiprotoUserProfile} from './WebapiprotoUserProfile';

/**
 * The WebapiprotoUserProfiles model module.
 * @module model/WebapiprotoUserProfiles
 * @version v0.4
 */
export class WebapiprotoUserProfiles {
  /**
   * Constructs a new <code>WebapiprotoUserProfiles</code>.
   * @alias module:model/WebapiprotoUserProfiles
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>WebapiprotoUserProfiles</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/WebapiprotoUserProfiles} obj Optional instance to populate.
   * @return {module:model/WebapiprotoUserProfiles} The populated <code>WebapiprotoUserProfiles</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new WebapiprotoUserProfiles();
      if (data.hasOwnProperty('count'))
        obj.count = ApiClient.convertToType(data['count'], 'Number');
      if (data.hasOwnProperty('profiles'))
        obj.profiles = ApiClient.convertToType(data['profiles'], [WebapiprotoUserProfile]);
    }
    return obj;
  }
}

/**
 * @member {Number} count
 */
WebapiprotoUserProfiles.prototype.count = undefined;

/**
 * @member {Array.<module:model/WebapiprotoUserProfile>} profiles
 */
WebapiprotoUserProfiles.prototype.profiles = undefined;


