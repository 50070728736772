import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { SvgIcon } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import TextField from "./TextField";
import { CheckIcon, CloseIcon, CopyIcon } from "../Icon/Icon";
import Clipboard from "../Clipboard/Clipboard";
import ToggleVisibility from "./utils/ToggleVisibility"; // We can inject some CSS into the DOM.

const styles = theme => ({});

const Visibility = props => {
  return <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M 20.1608,11.261306 C 18.3317,8.4472354 15.3065,6.7587943 11.92966,6.7587943 c -3.3768898,0 -6.3316598,1.6884411 -8.1608047,4.5025117 -0.281407,0.42211 -0.281407,1.05528 0,1.47739 1.829145,2.81407 4.854275,4.50251 8.2311547,4.50251 3.30649,0 6.40199,-1.68844 8.23119,-4.50251 0.1407,-0.21106 0.211,-0.49246 0.211,-0.77387 0,-0.28141 -0.0703,-0.49246 -0.2814,-0.70352 z m -8.16079,4.64322 c -2.8844198,0 -5.4874398,-1.40704 -7.0351698,-3.86935 1.54773,-2.3919607 4.2211,-3.8693507 7.0351698,-3.8693507 2.81409,0 5.48739,1.40704 7.03519,3.8693507 -1.5478,2.39196 -4.2211,3.86935 -7.03519,3.86935 z m 0,-6.6834206 c -1.54774,0 -2.8140698,1.2663296 -2.8140698,2.8140706 0,1.54774 1.2663298,2.81407 2.8140698,2.81407 1.54774,0 2.81409,-1.26633 2.81409,-2.81407 0,-1.547741 -1.26635,-2.8140707 -2.81409,-2.8140706 z m 0,4.2211106 c -0.77387,0 -1.40703,-0.63317 -1.40703,-1.40704 0,-0.77387 0.63316,-1.407031 1.40703,-1.407031 0.77387,0 1.40704,0.633161 1.40704,1.407031 0,0.77387 -0.63317,1.40704 -1.40704,1.40704 z" />
    </SvgIcon>;
};

const VisibilityOff = props => {
  return <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M 18.828108,17.9096 6.1648268,5.2462549 c -0.14071,-0.1407032 -0.35176,-0.211055 -0.49247,-0.211055 -0.21105,0 -0.35176,0.070352 -0.49246,0.211055 -0.1407,0.140704 -0.21105,0.281407 -0.21105,0.492463 0,0.211055 0.07035,0.351762 0.21105,0.492462 l 1.8995,1.8995 c -1.33669,0.77387 -2.46231,1.8994901 -3.306535,3.1658301 -0.2814068,0.49246 -0.2814068,1.05527 0,1.47738 1.829145,2.81411 4.854275,4.43221 8.2311562,4.43221 1.26633,0 2.46229,-0.2111 3.58799,-0.7036 l 2.2512,2.2513 c 0.1407,0.1407 0.2814,0.211 0.4925,0.211 0.211,0 0.3517,-0.0703 0.4924,-0.211 0.1407,-0.1407 0.2111,-0.2814 0.2111,-0.4925 0,-0.211 -0.0704,-0.211 -0.2111,-0.3517 z m -6.82409,-2.0402 c -2.8844212,0 -5.4874412,-1.40707 -7.0351712,-3.86938 0.77386,-1.19598 1.89949,-2.2512501 3.16582,-2.8844201 l 1.47739,1.4773901 c -0.28141,0.49246 -0.42211,0.91457 -0.42211,1.47739 0,1.54773 1.2663312,2.81407 2.8140712,2.81407 0.49246,0 0.98493,-0.14071 1.40704,-0.42212 l 1.05525,1.05527 c -0.7738,0.2814 -1.61807,0.3518 -2.46229,0.3518 z m -1.47739,-3.79902 c 0,-0.14071 0,-0.28141 0.07036,-0.42212 l 1.75879,1.7588 c -0.07035,0.07035 -0.21105,0.07035 -0.35176,0.07035 -0.77387,0 -1.47739,-0.63317 -1.47739,-1.40703 z m 9.63818,0.70351 c -0.6331,0.98493 -1.407,1.7588 -2.3216,2.46231 -0.1407,0.0704 -0.2814,0.1407 -0.4221,0.1407 -0.2111,0 -0.4221,-0.0703 -0.5628,-0.2814 -0.1407,-0.1407 -0.1407,-0.35176 -0.1407,-0.49246 0,-0.21106 0.1407,-0.35176 0.2814,-0.42211 0.7739,-0.56282 1.4774,-1.33669 1.9698,-2.11055 -1.5477,-2.3919601 -4.2211,-3.8693501 -7.03514,-3.8693501 -0.49246,0 -0.98492,0.07035 -1.47739,0.1407001 -0.35176,0.07035 -0.7035212,-0.2110501 -0.7738712,-0.5628101 0,-0.35176 0.2110599,-0.70352 0.6331712,-0.77387 3.93973,-0.70352 7.80903,1.0552801 9.91963,4.2914601 0.2814,0.49246 0.2814,1.05527 -0.0704,1.47738 z" />
    </SvgIcon>;
};

function TPasswordTextField(props) {
  const {
    classes,
    passwordVisible = false,
    showCopy = false,
    copyTooltip = '',
    onChange = null,
    onBlur = null,
    ...other
  } = props;
  const [showPassword, setShowPassword] = React.useState(passwordVisible);
  const [currentValue, setCurrentValue] = React.useState(props.value || props.defaultValue || null);

  const handleClickShowPassword = prop => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return <TextField InputProps={{
    endAdornment: <InputAdornment position={"end"}>
                                  {showCopy ? <Clipboard tooltipText={copyTooltip} component="div" data-clipboard-text={currentValue}><IconButton aria-label="copy" edge="end">
                                          <CopyIcon />
                                      </IconButton></Clipboard> : <></>}
                                  {!passwordVisible ? <ToggleVisibility aria-label="toggle visibility" edge="end" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} /> : null}
                              </InputAdornment>
  }} type={showPassword ? 'text' : 'password'} classes={{
    root: classes.root
  }} {...other} onChange={e => {
    setCurrentValue(e.target.value);
    onChange ? onChange(e, e.target.value) : null;
  }} onBlur={e => {
    setCurrentValue(e.target.value);
    onBlur ? onBlur(e, e.target.value) : null;
  }} componentname="t-password-text-field" />;
}

TPasswordTextField.propTypes = TextField.propTypes;
export default withStyles(styles, {
  useTheme: true
})(TPasswordTextField);