import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import { Icon } from "../../base/Icon/Icon";
import Typography from "../../base/Typography/Typography";
import { Box, capitalize } from "@material-ui/core";
import clsx from "clsx";
import BaseActionCard from "../BaseActionCard/BaseActionCard";

const styles = theme => {
  return {
    sideLeft: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0
    },
    sideRight: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0
    },
    iconContainer: {
      height: 120 - theme.spacing(4),
      marginBottom: theme.spacing(4.5),
      marginTop: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'center',
      width: '100%'
    },
    subtitle: {
      lineHeight: '17px',
      fontSize: "14px",
      display: 'inline-block',
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5)
    },
    title: {
      fontSize: "28px",
      fontWeight: "500"
    },
    header: {
      marginTop: -theme.spacing(2)
    },
    subtitleBox: {
      height: '36px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center'
    }
  };
};

function ActionCard(props) {
  const {
    children,
    icon,
    variant,
    classes,
    onClick,
    header = null,
    title = null,
    subtitle = null,
    titleVariant = 'h4',
    ...other
  } = props;
  return <BaseActionCard onClick={onClick} {...other} componentname="action-card">
        {header ? typeof header === "string" ? <Typography classes={{
      root: classes.header
    }} color={'secondary'} variant={'h4'}>{header}</Typography> : header : null}
        {icon ? <div className={classes.iconContainer}>{typeof icon === 'string' ? <Icon size={'large'}>{icon}</Icon> : icon}</div> : null}
        <Typography classes={{
      root: classes.title
    }} color={'textSecondary'} variant={titleVariant}>{title}</Typography>
        {subtitle ? <Box classes={{
      root: classes.subtitleBox
    }} mx={1} mt={2} mb={3}>
            <Typography classes={{
        root: classes.subtitle
      }} variant={'subtitle1'}>{subtitle}</Typography>
        </Box> : children}
    </BaseActionCard>;
}

export default withStyles(styles, {
  name: "ActionCard"
})(ActionCard);
