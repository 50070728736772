import React from "react";
import {Helmet} from "react-helmet";
import {withI18n} from "@ticketag/i18nlib";
import {withPage} from "../../hoc/Page/Provider";


const getValue = () => {

}

function seoTitle(props) {
    const {children, i18n, page, args = {}} = props;
    const [value, setValue] = React.useState()
    React.useEffect(() => {
        i18n.getFormattedValue(`seo.${page}.title`, children, args).then((value) => {
            setValue(value)
        })
    }, [])
    return <Helmet><title>{value}</title></Helmet>
}

export const SeoTitle = withPage(withI18n(seoTitle))

function seoDescription(props) {
    const {children, i18n, page, args = {}} = props;
    const [value, setValue] = React.useState()
    React.useEffect(() => {
        i18n.getFormattedValue(`seo.${page}.description`, children, args).then((value) => {
            setValue(value)
        })
    }, [])
    return <Helmet><meta name={"description"} content={value}/></Helmet>

}

export const SeoDescription = withPage(withI18n(seoDescription))

function seoIndex(props) {
    const {
        value = true
    } = props;
    if (!value) {
        return <meta name={"robots"} content={"noindex"}/>
    }
    return null
}

export const SeoIndex = seoIndex;



class Seo extends React.Component {
    constructor(props) {
        super(props);
    }

    renderTags() {
        const {title, description, index} = this.props;
        if (title || description || index) {
            return (
                <>
                    <SeoTitle>{title}</SeoTitle>
                    <SeoDescription>{description}</SeoDescription>
                    <SeoIndex value={index}/>
                </>
            )
        }
        return null
    }
    render() {
        const {children, title, description} = this.props;
        return (
            <React.Fragment>
                {this.renderTags()}
                {children}
            </React.Fragment>
        )
    }
}

export default withI18n(Seo)