/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * The WebapiprotoGroupAccount model module.
 * @module model/WebapiprotoGroupAccount
 * @version v0.4
 */
export class WebapiprotoGroupAccount {
  /**
   * Constructs a new <code>WebapiprotoGroupAccount</code>.
   * @alias module:model/WebapiprotoGroupAccount
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>WebapiprotoGroupAccount</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/WebapiprotoGroupAccount} obj Optional instance to populate.
   * @return {module:model/WebapiprotoGroupAccount} The populated <code>WebapiprotoGroupAccount</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new WebapiprotoGroupAccount();
      if (data.hasOwnProperty('uuid'))
        obj.uuid = ApiClient.convertToType(data['uuid'], 'String');
      if (data.hasOwnProperty('schema'))
        obj.schema = ApiClient.convertToType(data['schema'], 'Blob');
      if (data.hasOwnProperty('data'))
        obj.data = ApiClient.convertToType(data['data'], 'Blob');
    }
    return obj;
  }
}

/**
 * @member {String} uuid
 */
WebapiprotoGroupAccount.prototype.uuid = undefined;

/**
 * @member {Blob} schema
 */
WebapiprotoGroupAccount.prototype.schema = undefined;

/**
 * @member {Blob} data
 */
WebapiprotoGroupAccount.prototype.data = undefined;


