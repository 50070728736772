import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T, withI18n} from "@ticketag/i18nlib";
import Progress from "@ticketag/ticketag-uilib/src/components/base/Progress/Progress";
import {withApi} from "../../hoc/Api/Provider";
import AuthContext from "../../hoc/Auth/context";
import NewGroupHeader from "../../components/NewGroupHeader/NewGroupHeader";
import Grid from "@material-ui/core/Grid";
import NewGroupPage from "../../components/NewGroupPage/NewGroupPage";
import Typography  from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import Box from "@material-ui/core/Box";
import Button from "@ticketag/ticketag-uilib/src/components/base/Button/Button";
import {Icon} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";
import JSForm from "../../components/JSForm/JSForm";
import PartialLoader from "../../components/PartialLoader/PartialLoader";
import {withErrorModal, withRedirect} from "../../components/ErrorMessage/ErrorMessage";
import {withModals} from "../../../../../ticketag-uilib/src/components/hoc/Modals/Provider";
import TmpCard from "@ticketag/ticketag-uilib/src/components/complex/TmpCard/TmpCard";
import Checkbox from "@ticketag/ticketag-uilib/src/components/base/Checkbox/Checkbox";
import * as PropTypes from "prop-types";
import {UndoCreateGroup} from "../../hoc/Modals/Modals";
import Layout from "../../hoc/Layout/Layout";
import Page from "../../components/Page/Page";
import Title from "../../components/Title/Title";
import Scroll from "../../components/Scroll/Scoll";
import NextButton from "../../components/NextButton/NextButton";
import {pages} from "../../constants";



const styles = (theme) => {
    return {
        root: {

        },
        button: {
            width: "230px",
        },
        endSection: {
            height: "100%",
            "& > *": {
                height: "100%",
                alignItems: "flex-end"
            }
        }
    }
};

const defaultSecurityInfo = `La sicurezza è importante: per creare il gruppo, entra nel tuo account e modifica la tua password personale con quella generata automaticamente che ti abbiamo fornito qui sopra!`

function CreateTerms(props) {
    const {terms, onChange, ...other} = props;
    const [values, setValues] = React.useState(terms.reduce((acc, elt,i) => {
        acc[elt] = false
        return acc
    }, {}))
    const handleChange = (t) => (e, val) => {
        setValues((state) => Object.assign(state, {[t]: val}))
        onChange && onChange(Object.keys(values).reduce((acc, elt, i) => acc && values[elt], true))
    }
    return <Box display={"flex"} my={4} justifyContent={"center"}>
        { terms.map((t, i) => (
            <Checkbox key={`consent-${t.split('.')[2]}`} label={<T useMarkdown defaultVal={t}>{t}</T>}
                      onChange={handleChange(t)}
            />))
        }
    </Box>;
}

class CreateSummary extends Component {
    static contextType = AuthContext

    constructor(props) {
        super(props);
        this.state = {
            loaded:false,
            isValid: false,
        }
    }

    createGroup() {
        this.props.group.completeGroup().then((result) => {
            this.props.history.push(this.props.i18n.buildLocalizedUrl("groups", this.props.group.uuid, "create", "success"))
        })
    }

    handleTermChange(isValid) {
        this.setState({isValid: isValid})
    }

    renderFooter() {
        const disabled = !this.state.isValid
        return (
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"} py={3} px={10}>
                <Button fullWidth className={this.props.classes.button} variant={"contained"} color={"gradient"} onClick={() => this.createGroup()} disabled={disabled}>
                    <T defaultVal={"Crea gruppo"}>pages.create_summary.create_group_button</T>
                </Button>
            </Box>
        )
    }


    renderContent() {
        const {group} = this.props;
        const serviceFamily = group.serviceFamily
        const serviceType = group.serviceType
        return (
            <Grid container justify={"center"} alignItems={"center"} spacing={2}>
                <Grid item xs={12}>
                    <Box display={"flex"} justifyContent={"center"}>
                        <TmpCard showArrow={false}>
                            <Box p={3} display={"flex"} alignItems={"center"} width={"100%"}>
                                <Box display={"flex"} alignItems={"center"} flexBasis={"66.66666%"}>
                                    <Typography color={"textSecondary"} style={{
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        textTransform: "uppercase"
                                    }}>
                                        <T defaultVal={"Servizio"}>pages.create_summary.service</T>
                                    </Typography>
                                </Box>
                                <Box display={"flex"} alignItems={"center"}>
                                    <Typography style={{
                                        fontSize: "16px",
                                        fontWeight: "600",
                                        color: "#3D3582",
                                        whiteSpace: "nowrap"
                                    }}>
                                        {serviceFamily.name.toUpperCase()}
                                    </Typography>
                                </Box>
                            </Box>
                        </TmpCard>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box display={"flex"} justifyContent={"center"}>
                        <TmpCard showArrow={false}>
                            <Box p={3} display={"flex"} alignItems={"center"} width={"100%"}>
                                <Box display={"flex"} alignItems={"center"} flexBasis={"66.66666%"}>
                                    <Typography color={"textSecondary"} style={{
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        textTransform: "uppercase",
                                        whiteSpace: "nowrap"
                                    }}>
                                        <T defaultVal={"Visitibilità"}>pages.create_summary.visibility</T>
                                    </Typography>
                                </Box>
                                <Box display={"flex"} alignItems={"center"}>
                                    <Typography style={{
                                        fontSize: "16px",
                                        fontWeight: "600",
                                        color: "#3D3582",
                                        textTransform: "uppercase",
                                        whiteSpace: "nowrap"
                                    }}>
                                        {
                                            group.scope === "public" ?
                                                <T defaultVal={"pubblico"}
                                                   key={"scope_public"}>pages.create_summary.scope_public</T>
                                                :
                                                <T defaultVal={"privato"}
                                                   key={"scope_private"}>pages.create_summary.scope_private</T>
                                        }
                                    </Typography>
                                </Box>
                            </Box>
                        </TmpCard>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box display={"flex"} justifyContent={"center"}>
                        <TmpCard showArrow={false}>
                            <Box p={3} display={"flex"} alignItems={"center"} width={"100%"}>
                                <Box display={"flex"} alignItems={"center"} flexBasis={"66.66666%"}>
                                    <Typography color={"textSecondary"} style={{
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        textTransform: "uppercase",
                                        whiteSpace: "nowrap"
                                    }}>
                                        <T defaultVal={"Posti disponibili"}>pages.create_summary.size</T>
                                    </Typography>
                                </Box>
                                <Box display={"flex"} alignItems={"center"} justifyContent={"center"}
                                     flexBasis={"33.33333%"}>
                                    <Typography style={{
                                        fontSize: "24px",
                                        fontWeight: "600",
                                        color: "#49586F",
                                        textTransform: "uppercase"
                                    }}>
                                        {group.size}
                                    </Typography>
                                </Box>
                            </Box>
                        </TmpCard>
                    </Box>
                </Grid>
                <Grid item xs={12} style={{marginTop: "8px"}}>
                    <Typography variant={"body1"} color={"textSecondary"}
                                style={{paddingLeft: "48px", fontWeight: "600", fontSize: "16px"}}>
                        <T defaultVal={"Riceverai..."}>pages.create_summary.you_will_receive</T>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box display={"flex"} justifyContent={"center"}>
                        <TmpCard showArrow={false}>
                            <Box p={3} display={"flex"} alignItems={"center"} width={"100%"}>
                                <Box display={"flex"}>
                                    <svg width="28" height="27" viewBox="0 0 28 27" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14.2 0C11.5893 0 9.03721 0.774166 6.86648 2.2246C4.69575 3.67504 3.00387 5.73659 2.0048 8.14858C1.00572 10.5606 0.744315 13.2146 1.25364 15.7752C1.76297 18.3357 3.02014 20.6878 4.8662 22.5338C6.71225 24.3799 9.06426 25.637 11.6248 26.1464C14.1854 26.6557 16.8394 26.3943 19.2514 25.3952C21.6634 24.3961 23.725 22.7043 25.1754 20.5335C26.6258 18.3628 27.4 15.8107 27.4 13.2C27.3974 9.69995 26.0058 6.34402 23.5309 3.86912C21.056 1.39421 17.7001 0.00264854 14.2 0ZM14.2 25C11.8662 25 9.58478 24.3079 7.64428 23.0113C5.70378 21.7147 4.19134 19.8718 3.29823 17.7157C2.40511 15.5595 2.17143 13.1869 2.62674 10.8979C3.08205 8.60896 4.20589 6.5064 5.85615 4.85614C7.50641 3.20588 9.60896 2.08204 11.8979 1.62673C14.1869 1.17143 16.5595 1.40511 18.7157 2.29822C20.8718 3.19134 22.7147 4.70377 24.0113 6.64427C25.3079 8.58477 26 10.8662 26 13.2C25.9974 16.3287 24.7533 19.3286 22.5409 21.5409C20.3286 23.7533 17.3287 24.9974 14.2 25Z"
                                            fill="#191414"/>
                                        <path
                                            d="M17.6501 14.0001C17.178 13.4394 16.5086 13.0815 15.7801 13.0001L14.8801 12.8201L15.2001 8.47009L16.9901 8.54009H17.6901L17.7901 7.54009H17.6901C16.867 7.39886 16.0349 7.31533 15.2001 7.29009L15.3001 5.59009H14.4701L14.3501 7.27009H14.1001C13.4281 7.24838 12.756 7.31222 12.1001 7.46009C11.8502 7.52242 11.6152 7.63417 11.4091 7.78874C11.203 7.94331 11.03 8.13758 10.9001 8.36009C10.5928 8.98007 10.4515 9.66918 10.4901 10.3601C10.447 11.1353 10.6387 11.9055 11.0401 12.5701C11.5012 13.1234 12.1554 13.4809 12.8701 13.5701L13.8701 13.7901L13.5901 18.2001C12.6601 18.2001 11.7601 18.1501 10.8901 18.1001H10.7801L10.6801 19.2001H10.7801C11.6846 19.3352 12.5961 19.4187 13.5101 19.4501L13.3701 21.4501H14.2001L14.3401 19.4501C15.0804 19.4703 15.8201 19.3929 16.5401 19.2201C16.7991 19.1542 17.0423 19.0371 17.2553 18.8756C17.4683 18.7142 17.6467 18.5117 17.7801 18.2801C18.1048 17.6372 18.2499 16.9186 18.2001 16.2001C18.2424 15.4281 18.0508 14.6613 17.6501 14.0001ZM14.3101 8.47009L14.0401 12.6001L13.3601 12.4501C12.915 12.3995 12.5031 12.19 12.2001 11.8601C11.9306 11.4344 11.8077 10.9322 11.8501 10.4301C11.8193 9.96303 11.9052 9.49565 12.1001 9.07009C12.1723 8.94293 12.2715 8.83316 12.3907 8.7485C12.51 8.66384 12.6463 8.60635 12.7901 8.58009C13.2922 8.49573 13.8011 8.4589 14.3101 8.47009ZM15.9301 18.0701C15.4383 18.1829 14.9347 18.2366 14.4301 18.2301L14.7301 14.0401L15.3301 14.1701C15.6543 14.2176 15.9665 14.3262 16.2501 14.4901C16.4726 14.6239 16.6395 14.8333 16.7201 15.0801C16.8308 15.4428 16.8815 15.8211 16.8701 16.2001C16.9002 16.6457 16.8212 17.0919 16.6401 17.5001C16.571 17.6415 16.4715 17.7659 16.3488 17.8644C16.2261 17.9629 16.0831 18.0331 15.9301 18.0701Z"
                                            fill="#191414"/>
                                        <path
                                            d="M0.499999 26C0.499999 26.2761 0.723857 26.5 0.999999 26.5L5.5 26.5C5.77614 26.5 6 26.2761 6 26C6 25.7239 5.77614 25.5 5.5 25.5H1.5L1.5 21.5C1.5 21.2239 1.27614 21 0.999999 21C0.723857 21 0.499999 21.2239 0.499999 21.5L0.499999 26ZM4.64645 21.6464L0.646446 25.6464L1.35355 26.3536L5.35355 22.3536L4.64645 21.6464Z"
                                            fill="black"/>
                                    </svg>
                                </Box>
                                <Box display={"flex"} alignItems={"center"} justifyContent={"center"} pl={1} mt={0.5}>
                                    <Typography color={"gradient"}>
                                        <span style={{fontSize: "28px", fontWeight: "700"}}>
                                        {this.props.i18n.formatPrice(serviceType.bestPrice)}
                                        </span>
                                        &nbsp;
                                        <span style={{fontSize: "14px", fontWeight: "600",  whiteSpace: "nowrap"}}>
                                            <T defaultVal={" €/mese per partecipante"}>pages.create_summary.price_for_partecipant</T>
                                        </span>
                                    </Typography>
                                </Box>
                            </Box>
                        </TmpCard>
                    </Box>
                </Grid>
            </Grid>
        )
    }

    render() {
        const {group} = this.props;
        const serviceType = group.serviceType
        const {isValid} = this.state;
        const createTerms = serviceType.data?.createTerms || []
        const {serviceFamily} = group
        const {auth, i18n, history} = this.props;
        const {seo, layout, title, ...other} = this.props;
        return (
            <Page  seo={seo}
                  layout={{
                      ...layout,
                      onGoBack: () => this.props.onGoBack(),
                      onClose:  () => this.props.onClose()
                  }}>
                <Title>{title}</Title>
                <Scroll>
                    {this.renderContent()}
                </Scroll>
                {
                    createTerms.length ?
                        <Box flexGrow={1} px={3} maxWidth={{xs: "100%", sm: "444px"}} margin={"auto"}>
                            <CreateTerms terms={createTerms} onChange={(isValid) => this.handleTermChange(isValid)}/>
                        </Box>
                        : null
                }
                <NextButton flexGrow={1} onClick={() => this.createGroup()} disabled={createTerms.length === 0 ? false : !isValid}>
                    <T defaultVal={"Crea gruppo"}>{`pages.create_summary.next_button_text`}</T>
                </NextButton>
            </Page>
        )
    }
}

export default withStyles(styles, {useTheme: true})(withApi(withI18n(withModals(CreateSummary))));
