import {withStyles} from '@material-ui/core/styles';
import React from "react";
import {withPage} from "../../hoc/Page/Provider";
import {withI18n} from "@ticketag/i18nlib";
import Seo from "../../pages/Seo/Seo";
import {withLayout} from "../../hoc/Layout/Layout";
import {withGlobalLoader} from "../../hoc/GlobalLoader/Provider";

const styles = (theme) => {
    return {}
}
class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {isLoading: true}
    }

    componentDidMount() {
        console.log("PAGE MOUNTED", this.props.name)
        this.setLayout()
        this.setState({isLoading: false})
        this.setLoading(false)
    }

    componentWillUnmount() {
        console.log("PAGE UNMOUNTED", this.props.name)
        this.setLoading(true)
    }

    componentDidUpdate() {
        this.setLayout()
        this.setLoading(false)
    }

    setLayout() {
        const {layout, setLayout} = this.props;
        if (layout) {
            setLayout(layout)
        }
    }

    setLoading(defaultValue) {
        const {
            isLoading : loading = defaultValue,
        } = this.props;
        loading ? this.props.loader.Show() : this.props.loader.Hide()
    }

    render() {
        const {children, seo} = this.props;
        //
        if (this.state.isLoading) {
            return null
        }
        if (seo) {
            return <Seo {...seo}>{children}</Seo>
        }
        return children
    }
}

export default withStyles(styles)(withGlobalLoader(withI18n(withLayout(Page))))