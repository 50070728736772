/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * The WebapiprotoBanner model module.
 * @module model/WebapiprotoBanner
 * @version v0.4
 */
export class WebapiprotoBanner {
  /**
   * Constructs a new <code>WebapiprotoBanner</code>.
   * @alias module:model/WebapiprotoBanner
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>WebapiprotoBanner</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/WebapiprotoBanner} obj Optional instance to populate.
   * @return {module:model/WebapiprotoBanner} The populated <code>WebapiprotoBanner</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new WebapiprotoBanner();
      if (data.hasOwnProperty('uuid'))
        obj.uuid = ApiClient.convertToType(data['uuid'], 'String');
      if (data.hasOwnProperty('text'))
        obj.text = ApiClient.convertToType(data['text'], 'String');
      if (data.hasOwnProperty('severity'))
        obj.severity = ApiClient.convertToType(data['severity'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} uuid
 */
WebapiprotoBanner.prototype.uuid = undefined;

/**
 * @member {String} text
 */
WebapiprotoBanner.prototype.text = undefined;

/**
 * @member {String} severity
 */
WebapiprotoBanner.prototype.severity = undefined;


