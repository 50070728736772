import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import  {withRouter, Link as RouterLink} from "react-router-dom";
import {Icon} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Data from "../../../data";
import {withI18n} from "@ticketag/i18nlib";
import Tab from "@ticketag/ticketag-uilib/src/components/base/Tabs/Tab";
import {withChat} from "../../../hoc/Chat/Provider";
import {Badge} from "@material-ui/core";

const styles = (theme) => {
    return {
        icon: {
            width: "30px!important",
            height: "30px!important",
        },
        bottomNavigation: {
            position: "fixed",
            width: "100%",
            bottom: "0",
            left: "0",
            height: "70px",
            zIndex: "1000",
            boxShadow: "0px 2px 6px #FFFFFF, 0px 1px 15px rgba(0, 0, 0, 0.15), inset 0px 1px 4px #F3F2F2",
            "&  .MuiBottomNavigationAction-root:first-child": {
                marginLeft: "20px",
            },
            "&  .MuiBottomNavigationAction-root:last-child": {
                marginRight: "20px",
            },
            "& .MuiBottomNavigationAction-root": {
                color: theme.palette.text.primary,
                padding: 0,
            },
            "& .MuiBottomNavigationAction-root.Mui-selected": {
                color: theme.palette.text.secondary,
            },
            "& .MuiBottomNavigationAction-root.Mui-selected::after": {
                content: "''",
                position: "absolute",
                bottom: "10px",
                left: "calc(50% - 2px)",
                background: "url(\"data:image/svg+xml,%3Csvg width='4' height='4' viewBox='0 0 4 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='2' cy='2' r='2' fill='%23141414'/%3E%3C/svg%3E%0A\") no-repeat",
                width: "100%",
                height: `4px`
            },
            "& .MuiBottomNavigationAction-label.Mui-selected": {
                fontSize: "0.75rem",
                fontWeight: "500"
            }
        },
    }
}

function TBottomNavigation(props) {
    const {
        id,
        i18n,
        location,
        actions = Data.DefaultLayout.bottomNavigation,
        classes,
        chatApi,
        ...other
    } = props;
    const unreadCount = chatApi.GetUnreadCount()
    const value = Object.keys(actions).find(
        (key) => ((new RegExp("^/[a-z]{2}-[A-Z]{2}" + actions[key].href)).test(location.pathname)))
    return (
        <BottomNavigation
            value={value}
            classes={{root: classes.bottomNavigation}}
            showLabels
            id={id}
            {...other}
        >
            <BottomNavigationAction
                disableRipple component={RouterLink}
                icon={<Icon color={value === "services" ? "textSecondary" : "textPrimary"} classes={{root: classes.icon}}>mobile_play</Icon>}
                to={i18n.localizedUrl(actions["services"].href)}
                value={"services"}
            />
            <BottomNavigationAction
                disableRipple component={RouterLink}
                icon={<Icon color={value === "groups" ? "textSecondary" : "textPrimary"} classes={{root: classes.icon}}>groups</Icon>}
                to={i18n.localizedUrl(actions["groups"].href)}
                value={"groups"}
            />
            <BottomNavigationAction
                disableRipple component={RouterLink}
                icon={<Icon color={value === "wallet" ? "textSecondary" : "textPrimary"} classes={{root: classes.icon}}>wallet</Icon>}
                to={i18n.localizedUrl(actions["wallet"].href)}
                value={"wallet"}
            />
            <BottomNavigationAction
                disableRipple component={RouterLink}
                icon={
                    unreadCount > 0 ?
                        <Badge badgeContent={chatApi.GetUnreadCount()} color={"error"}>
                            <Icon color={value === "messages" ? "textSecondary" : "textPrimary"} classes={{root: classes.icon}}>messages</Icon>
                        </Badge> :
                        <Icon color={value === "messages" ? "textSecondary" : "textPrimary"} classes={{root: classes.icon}}>messages</Icon>
                }
                to={i18n.localizedUrl(actions["messages"].href)}
                value={"messages"}
            />
        </BottomNavigation>
    );
}

export default withStyles(styles, {name: ""})(withChat(withI18n(withRouter(TBottomNavigation))))
