import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, useTheme } from "@material-ui/core";
import clsx from "clsx";
import capitalize from "@material-ui/core/utils/capitalize";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Link from "../Link/Link";
import Skeleton from "@material-ui/lab/Skeleton";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import AvatarIcon from "../Avatar/AvatarIcon";
import Typography from "../Typography/Typography";
import Hidden from "@material-ui/core/Hidden";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const styles = theme => {
  const getTypography = variant => ({
    fontSize: theme.overrides.MuiTypography[variant].fontSize,
    fontWeight: theme.overrides.MuiTypography[variant].fontWeight,
    lineHeight: theme.overrides.MuiTypography[variant].lineHeight,
    letterSpacing: theme.overrides.MuiTypography[variant].letterSpacing
  });

  return {
    root: {
      width: "calc(100%-32px)",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      [theme.breakpoints.up("sm")]: {
        justifyContent: "flex-start"
      }
    },
    variantDefault: { ...getTypography("h2"),
      [theme.breakpoints.up("sm")]: getTypography("h1")
    },
    titleContainer: {
      display: "table",
      "& .headerAvatar": {
        display: "flex",
        textAlign: "center",
        justifyContent: "center"
      },
      "& .headerTitle": {
        display: "inline"
      },
      "& .headerLabel": {
        display: "table-cell",
        textAlign: "right",
        verticalAlign: "bottom",
        height: "24px",
        float: "right"
      }
    },
    labelPositionRight: {
      [theme.breakpoints.up("sm")]: {
        display: "flex",
        alignItems: "center",
        "& .headerLabel": {
          height: "auto",
          display: "initial"
        }
      }
    }
  };
};

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return keys.reduce((output, key) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const matches = useMediaQuery(theme.breakpoints.up(key));
    return !output && matches ? key : output;
  }, null) || 'xs';
}

function THeader(props) {
  const {
    isLoading,
    wrap = false,
    children,
    avatar,
    align: alignProp = 'left',
    breadcrumbs,
    classes,
    links,
    contrast,
    linkUrl,
    label,
    labelPosition = "right",
    variant = "default",
    avatarHeight = 38,
    ...other
  } = props;
  const avatarWidth = avatarHeight;
  let align = alignProp;

  if (typeof alignProp === "object") {
    const theme = useTheme();
    const reverseBreakpoints = [...theme.breakpoints.keys].reverse();
    const key = reverseBreakpoints.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches && alignProp[key] ? key : output;
    }, null) || 'xs';
    align = align[key];
  }

  return <Box className={classes.root} {...other} componentname="t-header">
    {breadcrumbs ? <Box pb={2}>{breadcrumbs}</Box> : null}
    <Box display={"flex"} alignItems={"center"} justifyContent={align === "center" ? align : "flex-start"} ml={-2}>
      <Box className={clsx(classes.titleContainer, labelPosition === "right" ? classes.labelPositionRight : null)}>
        <Box display={"table-row"}>
          <Box mr={wrap ? 2 : 0} display={"flex"} alignItems={wrap ? "center" : "flex-start"} flexWrap={wrap ? "wrap" : "no-wrap"} justifyContent={wrap ? "center" : "flex-start"}>
            {avatar && isLoading ? <Box flexGrow={1} ml={2} className={"headerAvatar"} align={"center"}>
              <Skeleton variant="circle" height={avatarHeight} width={avatarWidth} classes={{
                root: classes.icon
              }} />
            </Box> : avatar ? typeof avatar === "string" ? <Box flexGrow={1} ml={2} className={"headerAvatar"} align={"center"} justifyContent={"center"}>
              <img style={{
                paddingTop: "8px",
                width: `${avatarWidth}px`,
                height: `${avatarHeight}px`
              }} className={classes.icon} alt={"avatar-header"} src={avatar} />
            </Box> : <Box flexGrow={1} ml={2} className={"headerAvatar"}>{avatar}</Box> : null}
            {isLoading ? <Box ml={2} className={"headerTitle"}>
              <Typography align={"center"} variant={variant !== "default" ? variant : "h1"} className={clsx({
                [classes.variantDefault]: variant === "default"
              })}>   <Hidden xsUp>
                <Skeleton variant={"text"} width={120} />
              </Hidden>
                <Hidden smDown>
                  <Skeleton variant={"text"} width={320} />
                </Hidden>
              </Typography>
            </Box> : <Box ml={2} className={clsx("headerTitle", classes.headerTitle)}>
              <Typography color={contrast ? "white" : "textSecondary"} align={align ? align : wrap ? "center" : "left"} variant={variant !== "default" ? variant : "h1"} className={clsx({
                [classes.variantDefault]: variant === "default"
              })}>{children}
              </Typography>
            </Box>}
          </Box>
        </Box>
        {label && isLoading ? <Box pl={2} mt={1} className={"headerLabel"}>
          <Typography variant={"button"}><Skeleton width={100} /></Typography>
        </Box> : label ? <Box pl={2} mt={1} className={"headerLabel"}>
          <Typography variant={"button"} color={"secondary"}>{label}</Typography>
        </Box> : null}
      </Box>
    </Box>
  </Box>;
}

export default withStyles(styles, {})(THeader);
