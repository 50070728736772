import React, {useState} from 'react';
import clsx from 'clsx';
import {withStyles} from '@material-ui/core/styles';
import {Checkbox, FormControlLabel} from '@material-ui/core';
import {CheckboxCheckedIcon, CheckboxIcon} from "../Icon/Icon";
import capitalize from "@material-ui/core/utils/capitalize";

// We can inject some CSS into the DOM.
const styles = (theme) => {
    return {
        root: {
            fontWeight: 600,
            letterSpacing: '.05em'
        },
        checkedReadonly: {
            cursor: 'not-allowed',
            "&::after": {
                content: "''",
                width: "20px",
                backgroundColor: "rgba(0, 0, 0, 0.05)",
                position: "absolute",
                height: "20px",
                boxSizing: "border-box",
                borderRadius: "4px",
                margin: "2px"
            }
        },
        label: {
            cursor: 'default',
            fontSize: "14px"
        },
        colorTextPrimary: {
            color: theme.palette.text.primary,
        },
        colorTextSecondary: {
            color: theme.palette.text.secondary,
        }
    }
};

function TCheckbox(props) {
    const {
        color = 'textPrimary',
        formLabelProps = {},
        className, classes, label, theme, onChange, onBlur, helperText, error, defaultValue, readonly = false,
    ...other
    } = props;
    const useClasses = ['checkbox']
    const [checked, setChecked] = useState(!!defaultValue)
    if (className) {
        useClasses.push(className)
    }
    const changeHandler = onChange ?
        (e) => {setChecked(e.target.checked); onChange(e, e.target.checked)} :
        (e) => {setChecked(e.target.checked)}
    const blurHandler = onBlur ? (e) => {onBlur(e, e.target.checked)} : null
    if (readonly) {
        other.onClick = (e) => {e.preventDefault()}
    }
    const checkbox = <Checkbox onBlur={readonly ? null : blurHandler}
                               onChange={readonly ? null : changeHandler}
                               checkedIcon={<CheckboxCheckedIcon
                                   color={readonly ? "disabled" : "gradient"}/>}
                               icon={<CheckboxIcon color={"disabled"}/>}
                               classes={{root:classes.root, checked: readonly ? classes.checkedReadonly : classes.checked}}
                               className={clsx(...useClasses)}
                               checked={checked}
                               value={checked}
                               {...other}/>
    if (label) {
        return <FormControlLabel
            className={classes[`color${capitalize(color)}`]}
            classes={{label: classes.label}} control={checkbox} label={label} error={error ? helperText : ''} />
    }
    return checkbox;
}

TCheckbox.propTypes = Checkbox.propTypes;

export default withStyles(styles, {withTheme: true})(TCheckbox);
