import React, {useState, useRef} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import {T} from "@ticketag/i18nlib";
import Form from "@ticketag/ticketag-uilib/src/components/complex/Form/Form";
import ComboBox from "@ticketag/ticketag-uilib/src/components/base/Select/ComboBox";

const styles = (theme) => {
    return {
        root: {},
        mobileComboBox: {
            maxWidth: "180px"
        },
        tableFilterTitle: {
            fontSize: "16px",
            lineHeight: "28px",
            fontWeight: "600",
            textTransform: "none",
            marginBottom: "10px"
        },
    }
};

const FiltersModal = ({transaction, transactionTypes, serviceOptions, dataOptions,  i18n, classes, defaultValues, onSubmit, ...props}) => {
    const form = useRef();

    const onFormSubmit = (e) => {
        e.preventDefault();
        form.current.trigger().then((isValid) => {
            if (isValid && onSubmit) {
                onSubmit(form.current.getValues());
            }
        });
    }

    return <Form T={T}
            formRef={(f) => {
                form.current = f;
                formRef ? formRef(f) : null
            }}
            defaultValues={defaultValues}
            onSubmit={(e) => onFormSubmit(e)}
            onBlur={() => {}}
            onChange={() => {}}
            {...props}>
        <ComboBox
            fullWidth
            options={transactionTypes}
            variant={'shadowed'}
            classes={{root: classes.mobileComboBox}}
            getOptionLabel={(option) => option.label}
            label={<Typography classes={{root: classes.tableFilterTitle}} color="textSecondary">
                <T defaultVal={'Tipo di movimento'}>pages.wallet.type_filter_title</T>
            </Typography>}
            grid={{xs: 12, md: 12, lg: 12}}
            box={{
                display: 'flex',
                justifyContent: 'center',
            }}
            name="type"
        />
        <ComboBox
            fullWidth
            options={serviceOptions}
            variant={'shadowed'}
            getOptionLabel={(option) => option.label}
            classes={{root: classes.mobileComboBox}}
            label={<Typography classes={{root: classes.tableFilterTitle}} color="textSecondary">
                <T defaultVal={'Servizio'}>pages.wallet.service_filter_title</T>
            </Typography>}
            grid={{xs: 12, md: 12, lg: 12}}
            box={{
                display: 'flex',
                justifyContent: 'center',
            }}
            name="service"
        />
        <ComboBox
            fullWidth
            options={dataOptions}
            variant={'shadowed'}
            getOptionLabel={(option) => option.label}
            classes={{root: classes.mobileComboBox}}
            label={<Typography classes={{root: classes.tableFilterTitle}} color="textSecondary">
                <T defaultVal={'Data'}>pages.wallet.date_filter_title</T>
            </Typography>}
            grid={{xs: 12, md: 12, lg: 12}}
            box={{
                display: 'flex',
                justifyContent: 'center',
            }}
            name="date"
        />
    </Form>;
};

export default withStyles(styles, {useTheme: true})(FiltersModal);
