import React from 'react'
import ReactDOM from 'react-dom'
import App from './js/App';

ReactDOM.render(
    <App />,
  document.getElementById('root'),
);

if('serviceWorker' in navigator){
    let publicUrl = process.env.PUBLIC_URL || ""
    window.addEventListener('load', function(){
        navigator.serviceWorker.register(`${publicUrl}/serviceWorker.js`,{
            scope: "/"
        }).then(resp=>{
            console.log("service worker registered successfully",resp)
        }).catch(err=>{
            console.log("error registering serviceworker",err)
        })
    })
}

window.deferredPrompt = null;

window.addEventListener('beforeinstallprompt', (e) => {
    console.log(e)
    e.preventDefault();
    window.deferredPrompt = e;
    return false;
});
