import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T} from "@ticketag/i18nlib";
import {useForm} from "react-hook-form";
import Box from "@material-ui/core/Box";
import TextField from "@ticketag/ticketag-uilib/src/components/base/TextField/TextField";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import Form from "@ticketag/ticketag-uilib/src/components/complex/Form/Form";


const styles = (theme) => {
    return {
        root: {},
        textField: {
            "& input": {
                textAlign: "center"
            }
            //width: '220px',
            //marginTop: '32px'
        },
    }
};

export const NameForm = ({classes, ...props}) => {
    //onBlur={() => {props.onChange(form)}} onChange={()=> {props.onChange(form)}} o
    return (<Form T={T} {...props}>
        <TextField classes={{root: classes.textField}}
                   grid={{xs: 12, md: 12}}
                   fullWidth
                   name="firstName"
                   rules={{required: {value: true, message: {key: 'components.name_form.insert_firstname', defaultVal: 'Inserisci il tuo nome'} }}}
                   label={<T defaultVal="Nome">pages.welcome.firstName_label</T>}/>
        <TextField classes={{root: classes.textField}}
                   grid={{xs: 12, md: 12}}
                   fullWidth
                   rules={{required: {value: true, message: {key: 'components.name_form.insert_lastname', defaultVal: 'Inserisci il tuo cognome'} }}}
                   name="lastName"
                   label={<T defaultVal="Cognome">pages.welcome.lastName_label</T>}/>
        <Box pt={4} pb={1} m={0} width={"100%"}>
            <Typography variant="subtitle1" color={"textPrimary"} align={"center"}>
                <T defaultVal="*Campi obbligatori">pages.welcome.required_fields</T>
            </Typography>
        </Box>
    </Form>)
}

export default withStyles(styles, {useTheme: true})(NameForm);
