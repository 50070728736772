import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T, withI18n} from "@ticketag/i18nlib";
import Progress from "@ticketag/ticketag-uilib/src/components/base/Progress/Progress";
import {withApi} from "../../hoc/Api/Provider";
import AuthContext from "../../hoc/Auth/context";
import Grid from "@material-ui/core/Grid";
import NewGroupHeader from "../../components/NewGroupHeader/NewGroupHeader";
import {Typography} from "@material-ui/core";
import IconButton from "@ticketag/ticketag-uilib/src/components/base/Button/IconButton";
import Box from "@material-ui/core/Box";
import GroupAdminCard from "../../components/GroupAdminCard/GroupAdminCard";
import GroupCredentialsCard from "../../components/GroupCredentialsCard/GroupCredentialsCard";
import GroupCashCard from "../../components/GroupCashCard/GroupCashCard";
import GroupMembersCard from "../../components/GroupMembersCard/GroupMembersCard";
import withWidth from "@material-ui/core/withWidth";
import {withModals} from "@ticketag/ticketag-uilib/src/components/hoc/Modals/Provider";
import {withErrorModal, withRedirect} from "../../components/ErrorMessage/ErrorMessage";
import {ConfirmKickUser, ConfirmLeaveGroup} from "../../hoc/Modals/Modals";
import {Skeleton} from "@material-ui/lab";
import ChipCard from "@ticketag/ticketag-uilib/src/components/complex/ChipCard/ChipCard";
import GroupAlert from "../../components/GroupAlert/GroupAlert";
import Header from "@ticketag/ticketag-uilib/src/components/base/Header/Header";
import TextButton from "@ticketag/ticketag-uilib/src/components/base/Button/TextButton";
import Breadcrumbs from "@ticketag/ticketag-uilib/src/components/base/Breadcrumbs/Breadcrumbs";
import {Link as RouterLink} from "react-router-dom";
import Hidden from "@material-ui/core/Hidden";
import Layout from "../../hoc/Layout/Layout";
import Page from "../../components/Page/Page";
import Navbar from "../../components/Navbar/Navbar";
import NavTitle from "../../components/Navbar/NavTitle/NavTitle";
import {pages} from "../../constants";
import {SeoDescription, SeoTitle} from "../Seo/Seo";
import Title from "../../components/Title/Title";


const styles = (theme) => {
    return {
        root: {
            paddingBottom: theme.spacing(20)
        }
    }
};

class GroupDetails extends Component {
    static contextType = AuthContext

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            disabled: false,
            group: null
        }
    }

    componentDidMount() {
        this.loadData()
    }

    setDisabled() {
        this.setState({disabled: true})
    }

    get groupUuid() {
        return this.props.match.params.groupUuid
    }

    get pageTitle() {
        return <T defaultVal="Il tuo gruppo {{.args.Group}}" useTemplate args={{Group: this.state.serviceFamily.name}}>global.links.group_template</T>
    }

    get billingCycle() {
        return <T capitalize
                  defaultVal={this.state.group.serviceDetails.billingCycle}>{`global.periods.${this.state.group.serviceDetails.billingCycle}`}</T>
    }

    goToGroupSettings() {
        this.props.history.push(this.props.i18n.buildLocalizedUrl('groups', this.state.group.uuid, 'settings'))
    }

    onBannerClose(bannerUuid) {
        if (bannerUuid !== "") {
            withErrorModal(this.props.modals, this.props.api.groupApi.closeGroupBanner(this.state.group.uuid, {bannerUuid}).then(resp => {
                const group = {...this.state.group, banners: this.state.group.banners.filter(b => b.uuid !== bannerUuid)}
                this.setState({
                    group: group
                })
            }))
        }
    }

    onCredentialsChange(key, {value, form}) {

        this.setDisabled()
        const body = {...this.state.credentials.data}
        body[key] = value[key]
        withErrorModal(this.props.modals, this.props.api.groupApi.setGroupAccount(this.state.group.uuid, {
            data: btoa(String.fromCharCode(...new TextEncoder("utf-8").encode(JSON.stringify(body)) ) ) //JSON.stringify(body)
        }).then((resp) => {
            this.props.api.GetGroupAccount(this.groupUuid).then(credentials => {
                this.setState({
                    credentials: credentials,
                })
            })
        }))
    }

    kickUser(uuid, userInfo) {
        const membersCount = this.state.group.members.length - 1
        const groupAmount = !membersCount || !this.state.group.amount ? 0 : `€ ${this.props.i18n.formatPrice(this.state.group.amount / membersCount)}`
        ConfirmKickUser(this.props.modals, userInfo, this.billingCycle, groupAmount, this.state.group).then(val => {
            if (!val) {
                return
            }
            withErrorModal(this.props.modals, this.props.api.KickUser(this.state.group.uuid, uuid).then((resp) => {
                this.loadData()
                this.props.modals.OkOnly(
                    <T capitalize defaultVal="operazione completata">modals.user_kicked.title</T>,
                    <T capitalize defaultVal="Utente espulso con successo">modals.user_kicked.text</T>,
                    {}).finally(() => {
                    this.setState({isSaving: false})
                })
            }))
        }).catch()
    }

    unkickUser(uuid, userInfo) {
        withErrorModal(this.props.modals, this.props.api.UnkickUser(this.state.group.uuid, uuid).then((resp) => {
                this.loadData()
                this.props.modals.OkOnly(
                    <T capitalize defaultVal="operazione completata">modals.user_unkicked.title</T>,
                    <T capitalize defaultVal="Utente riammesso nel gruppo">modals.user_unkicked.text</T>,
                    {}).finally(() => {
                    this.setState({isSaving: false})
                })
            }))
    }

    leaveGroup(userInfo) {
        userInfo.startDate = this.state.group.startDate
        userInfo.renewalDate = this.state.group.currentPeriodEnd
        const formattedAmount = `€ ${this.props.i18n.formatPrice(this.state.group.amount)}`
        ConfirmLeaveGroup(
            this.props.modals,
            userInfo,
            this.state.groupAdmin.name,
            this.state.group,
            this.billingCycle,
            formattedAmount
        ).then(val => {
            if (!val) {
                return
            }
            this.setState({isLoaded: false})
            withErrorModal(this.props.modals,this.props.api.groupApi.leaveGroup(this.state.group.uuid, {confirm: true}).then((resp) => {
                this.props.history.push(this.props.i18n.buildLocalizedUrl('groups'))
            }))
        })
        /**/
    }


    loadData() {
        return withRedirect(this.props.history, this.props.api.groupApi.groupDetails(this.groupUuid).then((group) => {
            let credentials = null;
            if (group.hasCredentials) {
                this.props.api.GetGroupAccount(this.groupUuid).then((credentials) => {
                    this.setGroupData(group, credentials)
                })
            } else {
                this.setGroupData(group, null)
            }
        }))
    }

    setGroupData(group, credentials) {
        const userInfo = this.context.getUserInfo()
        const serviceFamily = this.props.group.serviceFamily
        this.setState({
            isLoaded: true,
            group: {
                ...group,
                banners: (
                    group.state === 'disputed' && group.isAdmin ?
                        [{
                            uuid: "",
                            severity: "warning",
                            key: "group_disputed",
                            text: <T
                                defaultVal={"Il gruppo è in fase di disputa.\nPer favore, assicurati che i dati del gruppo che hai inserito siano corretti"}>pages.group_details.disputed_group_banner</T>
                        }] :
                        group.state === 'closing' ?
                            [{
                                uuid: "",
                                severity: "error",
                                key: "group_closing",
                                text: <T
                                    defaultVal={"Il gruppo è in fase di chiusura.\nNon potrai più accedere a questa pagina quando il periodo di sottoscrizione di ogni partecipante sarà concluso"}>pages.group_details.closing_group_banner</T>
                            }] : []).concat(...group.banners),
            },
            disabled: group.state === 'closing',
            serviceFamily: serviceFamily,
            groupAdmin: group.members.find(m => m.isAdmin),
            members: group.members.filter(m => !m.isAdmin).map(m => {
                m.isMe = m.uuid === userInfo.uuid;
                return m
            }),
            credentials: credentials,
            schema: credentials?.schema,
        })
    }

    withSkeleton(skeleton, content) {
        if (!this.state.isLoaded) {
            return skeleton
        }
        return content
    }

    renderContent() {
        const hasBanners = this.state.group?.banners && this.state.group.banners.length > 0
        const spacings = {
            header: hasBanners ? 5 : 10//
        }

        const cardProps = {}
        if (this.props.width === 'xs') {
            spacings.header = 5
            Object.assign(cardProps, {display: 'flex', justifyContent: 'center'})
        }

        spacings.subtitle = spacings.header / 2

        if (!this.state.isLoaded) {
            return <Grid container>
                <Grid classes={{root: this.props.classes.root}} xs={12} item>
                    <Hidden xsDown>
                        <NewGroupHeader showBreadcrumbs={false} mb={spacings.header} isLoading/>
                    </Hidden>
                    <Box mb={spacings.subtitle} display="flex" alignItems="center"><Typography variant="h3"><Skeleton width={120}/></Typography></Box>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <ChipCard isLoading/>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <ChipCard isLoading defaultSize={2} header={true}/>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <ChipCard isLoading defaultSize={1} header={true}/>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <ChipCard isLoading defaultSize={3} header={true}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        }

        return <Grid container>
            <Grid classes={{root: this.props.classes.root}} xs={12} item>
                <Hidden smDown>
                    <Header
                        mb={spacings.header}
                        avatar={this.state.serviceFamily.logo}
                        breadcrumbs={
                            <Breadcrumbs
                                linksComponent={RouterLink}
                                links={
                                    [
                                        {url: this.props.i18n.buildLocalizedUrl('groups'), title: <T defaultVal="Gruppi">global.links.groups</T>}
                                    ]
                                }>{this.pageTitle}</Breadcrumbs>
                        }
                    >{this.pageTitle}</Header>
                </Hidden>
                {hasBanners ? <Box mb={3}>
                    {this.state.group.banners.map((banner) =>
                        <GroupAlert onClose={banner.uuid !== "" ? () => this.onBannerClose(banner.uuid) : null} mb={3} key={banner.uuid} banner={banner}/>)
                    }
                </Box>: null}
                <Box mb={spacings.subtitle} display="flex" alignItems="center"><Typography variant="h2">
                    {this.withSkeleton(<Skeleton width={120}/> ,<T defaultVal="Generali">pages.group_details.subtitle</T>)}
                </Typography>
                    {this.state.group.isAdmin && !this.state.disabled ?
                        this.withSkeleton(<Skeleton variant="circle" width={32} height={32}/> ,
                            <IconButton onClick={() => this.goToGroupSettings()} width={27} height={27} mb={-1} disabled={this.state.disabled} size="large" color="secondary">cog</IconButton>)
                        : null}
                </Box>
                <Grid container spacing={6}>
                    <Grid item xs={12}><GroupAdminCard {...cardProps} mb={2} user={this.state.groupAdmin}/></Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <GroupCredentialsCard schema={this.state.credentials?.schema}
                                              modals={this.props.modals}
                                              serviceName={this.state.group.serviceName}
                                              isAdmin={this.state.group.isAdmin}
                                              onChange={(key, formData) => this.onCredentialsChange(key, formData)}
                                              data={this.state.credentials?.data} {...cardProps}/></Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <GroupCashCard
                            amount={this.props.i18n.formatPrice(this.state.group.amount)}
                            isAdmin={this.state.group.isAdmin} {...cardProps}/>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <GroupMembersCard groupScope={this.state.group.scope}
                                          numMembers={this.state.group.members.length - 1}
                                          maxMembers={this.state.group.size}
                                          isAdmin={this.state.group.isAdmin}
                                          disabled={this.state.disabled}
                                          onKick={(uuid, userInfo) => this.kickUser(uuid, userInfo)}
                                          onUnkick={(uuid, userInfo) => this.unkickUser(uuid, userInfo)}
                                          onLeave={(userInfo) => this.leaveGroup(userInfo)}
                                          members={this.state.members} {...cardProps}
                                          inviteUrl={ this.state.group.state === "disputed" ? null : this.props.i18n.localizedUrl(`/groups/${this.state.group.uuid}/invite`)}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    }

    renderNavbar() {
        const serviceFamily = this.props.group.serviceFamily
        const isLoading = !this.state.isLoaded
        if (isLoading) {
            return <Navbar logo/>
        }
        return <Navbar
            useTemplate
            args={{Name: serviceFamily.name }}
            logo={serviceFamily.logo}
            title={this.props.layout.title}
        />
    }

    render() {
        const serviceFamily = this.props.group.serviceFamily
        const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1)
        const {
            seo,
            layout,
        } = this.props;
        return (
            <Page layout={{...layout, navbar: this.renderNavbar()}}>
                <SeoTitle args={{Name: capitalize(serviceFamily.name)}}>{seo.title}</SeoTitle>
                <SeoDescription args={{Name: capitalize(serviceFamily.name)}}>{seo.description}</SeoDescription>
                {this.renderContent()}
            </Page>
        )
    }

}

export default withStyles(styles, {useTheme: true})(withApi(withI18n(withWidth()(withModals(GroupDetails)))));
