/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * The WebapiprotoCard model module.
 * @module model/WebapiprotoCard
 * @version v0.4
 */
export class WebapiprotoCard {
  /**
   * Constructs a new <code>WebapiprotoCard</code>.
   * @alias module:model/WebapiprotoCard
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>WebapiprotoCard</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/WebapiprotoCard} obj Optional instance to populate.
   * @return {module:model/WebapiprotoCard} The populated <code>WebapiprotoCard</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new WebapiprotoCard();
      if (data.hasOwnProperty('key'))
        obj.key = ApiClient.convertToType(data['key'], 'String');
      if (data.hasOwnProperty('title'))
        obj.title = ApiClient.convertToType(data['title'], 'String');
      if (data.hasOwnProperty('content'))
        obj.content = ApiClient.convertToType(data['content'], 'String');
      if (data.hasOwnProperty('required'))
        obj.required = ApiClient.convertToType(data['required'], 'Boolean');
      if (data.hasOwnProperty('checked'))
        obj.checked = ApiClient.convertToType(data['checked'], 'Boolean');
      if (data.hasOwnProperty('priority'))
        obj.priority = ApiClient.convertToType(data['priority'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {String} key
 */
WebapiprotoCard.prototype.key = undefined;

/**
 * @member {String} title
 */
WebapiprotoCard.prototype.title = undefined;

/**
 * @member {String} content
 */
WebapiprotoCard.prototype.content = undefined;

/**
 * @member {Boolean} required
 */
WebapiprotoCard.prototype.required = undefined;

/**
 * @member {Boolean} checked
 */
WebapiprotoCard.prototype.checked = undefined;

/**
 * @member {Number} priority
 */
WebapiprotoCard.prototype.priority = undefined;


