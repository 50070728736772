/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * Enum class WebapiprotoPageName.
 * @enum {String}
 * @readonly
 */
const WebapiprotoPageName = {
  /**
   * value: "faq"
   * @const
   */
  faq: "faq",

  /**
   * value: "privacyPolicy"
   * @const
   */
  privacyPolicy: "privacyPolicy",

  /**
   * value: "termsAndConditions"
   * @const
   */
  termsAndConditions: "termsAndConditions",

  /**
   * value: "cookieConsent"
   * @const
   */
  cookieConsent: "cookieConsent",

  /**
   * value: "homePage"
   * @const
   */
  homePage: "homePage",

  /**
   * value: "plp"
   * @const
   */
  plp: "plp",

  /**
   * Returns a <code>WebapiprotoPageName</code> enum value from a JavaScript object name.
   * @param {Object} data The plain JavaScript object containing the name of the enum value.
   * @return {module:model/WebapiprotoPageName} The enum <code>WebapiprotoPageName</code> value.
   */
  constructFromObject: function(object) {
    return object;
  }
};

export {WebapiprotoPageName};
