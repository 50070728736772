import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import useTheme from "@material-ui/core/styles/useTheme";
import {capitalize, Fade} from "@material-ui/core";
import {ExpandMoreIcon} from "../Icon/Icon";
import Measure from "react-measure";
import useControlled from "@material-ui/core/utils/useControlled";

const useStyles = makeStyles((theme) => {
    return {
        root: ({height, scaling, spacing, chevronWidth, gutters}) => ({
            position: "relative",
        }),
        overflow: ({cols, scaling, gutters, chevronWidth}) => ({
            width: `calc(${100}% + ${2* (gutters + chevronWidth)}px)`,
            transform: `translateX(-${gutters + chevronWidth}px)`,
        }),
        chevronPositionOutset: ({spacing, chevronWidth}) => ({
            "& $wrapper": {
                width: `calc(100% + ${- chevronWidth * 2}px)`,
                display: "block",
                margin: "auto",
            },
            "& $leftChevron": {
                left: `${ -chevronWidth}px`,
            },
            "& $rightChevron": {
                right: `${ -chevronWidth}px`,
            }
        }),
        wrapper: ({height, spacing, chevronWidth}) => ({
            position: "relative",
        }),
        carouselWrapper: ({spacing}) => ({
            width: `calc(100% + ${theme.spacing(spacing)}px)`,
            margin: `${-theme.spacing(spacing) / 2}px`,
            overflowX: "hidden",
        }),
        sliderItemsWrapper: ({spacing, height, scaling, gutters, duration, easing}) => ({
            display: "flex",
            flexWrap: "nowrap",
            alignItems: "center",
            transition: `transform ${duration}s ${easing}`,
            height: `${height * scaling + 2*gutters + theme.spacing(spacing)}px`,
        }),
        sliderItem: ({spacing, duration, easing, fade}) => ({
            flexShrink: "0",
            padding: `${theme.spacing(spacing) / 2}px`,
            transition: `width ${duration}s ${easing}, height ${duration}s ${easing}, margin-left ${duration}s ${easing}, margin-right ${duration}s ${easing}, opacity ${duration}s ${easing}`
            ,
        }),
        chevron: ({chevronWidth}) => ({
            position: "absolute",
            height: "100%",
            width: `${Math.abs(chevronWidth) + 1}px`,
            cursor: "pointer",
            display: "flex",
            top: "0",
            alignItems: "center",
            justifyContent: "center",
            "& svg": {
                width: `${Math.abs(chevronWidth)}px`,
                height: `${Math.abs(chevronWidth)}px`,
            }
        }),
        leftChevron: ({spacing, chevronWidth}) => ({
            left: `${- theme.spacing(spacing)}px`,
            "& svg": {
                transform: "rotate(90deg)"
            }
        }),
        rightChevron: ({spacing, chevronWidth}) => ({
            right: `${ - theme.spacing(spacing)}px`,
            "& svg": {
                transform: "rotate(-90deg)"
            }
        }),
        disabled: {
            transition: "none!important",
            "& > *": {
                transition: "none!important"
            },
        },
        fade: () => ({

        }),
        itemHidden: {
            opacity: "0"
        },
        itemVisible: {
            opacity: "1"
        },
    }
})

function ComponentItem(props) {
    const {
        children,
        width,
        height,
        scaling,
        parentRef,
        translateX,
        classes,
        onEnter,
        onLeave,
        className,
        duration,
        easing,
        disabled,
        ...other
    } = props;
    const child = React.Children.only(children);
    const [hover, setHover] = React.useState(false)
    const handleEnter = (e) => {
        if (!disabled) {
            setHover(true)
            parentRef.current.style.setProperty("transform", `translateX(${translateX + width * (1 - scaling) / 2}px)`)
        }
        onEnter && onEnter(e)
    }
    const handleLeave = (e) => {
        if (!disabled) {
            setHover(false)
            parentRef.current.style.setProperty("transform", `translateX(${translateX}px)`)
        }
        onLeave && onLeave(e)

    }
    return <Box onMouseEnter={handleEnter} onMouseLeave={handleLeave} className={clsx(classes.sliderItem, className)}
                width={hover ? width * scaling : width} height={hover ? height * scaling : height} {...other}>
        {
            React.cloneElement(child, {
                width: "100%",
                height: "100%",
                //classes: classes,
            })
        }
    </Box>;
}

function CarouselWrapper(props) {
    const {
        items,
        active,
        classes,
        measureRef,
        containerWidth,
        containerHeight,
        cols,
        spacing,
        scaling,
        scroll,
        height,
        justify,
        chevronWidth,
        chevronPosition,
        onReceiveProps,
        duration,
        easing,
        gutters,
        onScrollLeft,
        onScrollRight,
        onLoad,
        disabled,
        leftChevron,
        rightChevron,
        fade,
        ...other

    } = props;

    const theme = useTheme()
    const sliderRef = React.useRef()

    const [loaded, setLoaded] = React.useState(false)
    const initialRender = React.useRef(true)

    React.useEffect(() => {
        if (!initialRender.current) {
            onLoad()
        }
        if (initialRender && containerWidth) {
            initialRender.current = false
            setLoaded(true)
        }
    })

    const renderItems = (itemWidth, translateX) => items.map((elt, index) => {
        const isVisible = index >= active && index < active + cols
        const isFirst = index === active
        const isLast = index === Math.min(items.length - 1, active + cols - 1)
        const actualGutter = gutters + itemWidth * (scaling - 1) / 2
        return (
            <ComponentItem
                key={elt.key}
                className={clsx({
                    [classes.fade]: fade,
                    [classes.itemVisible]: isVisible,
                    [classes.itemHidden]: !isVisible,

                })} style={{
                margin: `0px ${isLast ? actualGutter : 0}px 0px ${isFirst ? actualGutter : 0}px`}
            }
                width={itemWidth}
                parentRef={sliderRef} translateX={translateX} spacing={spacing} classes={classes} height={height}
                scaling={scaling} duration={duration} easing={easing} disabled={disabled}
            >{elt}
            </ComponentItem>
        )
    })
    const itemWidth = (containerWidth - (cols - 1) * theme.spacing(spacing) - 2 * gutters - 1) / (cols - 1 + scaling)
    let translateX = - active * (itemWidth + theme.spacing(spacing))
    if (items.length < cols) {
        switch (justify) {
            case "center":
                translateX = (containerWidth - itemWidth * items.length - theme.spacing(spacing) * (items.length -1) +  itemWidth * (1 - scaling)) / 2 - gutters
                break;
            case "right":
                translateX = (containerWidth - itemWidth * items.length - theme.spacing(spacing) * (items.length -1) +  itemWidth * (1 - scaling)) - gutters * 2
                break;
            case "left":
            default:
                translateX = 0
        }
    }
    return (
        <Box ref={measureRef} className={classes.wrapper}>
            <Box className={classes.carouselWrapper}>
                <Box ref={sliderRef} className={clsx({
                    [classes.sliderItemsWrapper]: true,
                    [classes.disabled]: disabled,
                })}
                     style={{transform: `translateX(${translateX}px)`}}>
                    {containerWidth ? renderItems(itemWidth, translateX) : null}
                </Box>
            </Box>
            { leftChevron  }
            { rightChevron }
        </Box>
    )
}

const Component = ((props) => {
    const {
        disabled : disabledProp = false,
        cols = 5,
        spacing = 2,
        scaling = 1.2,
        scroll = 1,
        height = 273,
        justify = "center",
        chevronWidth = 60,
        chevronPosition = "outset",
        onLoad,
        duration = 0.75,
        easing = "ease-in-out",
        gutters = 40,
        children,
        overflow = true,
        fade = true,
        onScrollLeft,
        onScrollRight,
        active : activeProp,
        ...other

    } = props;


    const [active, setActive] = React.useState(0);
    const [disabled, setDisabled] = React.useState(true)

    React.useLayoutEffect(() => {
        //setDisabled(true)
    }, [children])

    const items = React.Children.toArray(children)
    const classes = useStyles({count: items, cols, height, spacing, scaling, chevronWidth, duration, easing, gutters, fade})

    const handleScrollLeft = (e) => {
        const prevIndex = Math.max(0, active - scroll)
        setActive(prevIndex)
        onScrollLeft(e, prevIndex)
    }
    const handleScrollRight = (e) => {
        const nextIndex = Math.min(active + scroll, items.length - cols)
        setActive(nextIndex)
        onScrollRight(e, nextIndex)
    }

    const carouselProps = {
        cols: cols,
        spacing: spacing,
        scaling: scaling,
        scroll: scroll,
        height : height,
        justify: justify,
        chevronWidth: chevronWidth,
        chevronPosition : chevronPosition,
        duration: duration,
        easing: easing,
        gutters: gutters,
        fade: fade,
    }

    const renderWrapper = ({measureRef, contentRect}) => (
        <CarouselWrapper
            disabled={disabled}
            onLoad={() => disabled ? setDisabled(false) : null}
            active={active}
            classes={classes}
            measureRef={measureRef}
            containerWidth={contentRect.bounds.width || 0}
            containerHeight={contentRect.bounds.width || 0}
            items={items}
            leftChevron={
                <Fade in={!disabledProp && active > 0} timeout={duration * 1000 + 1}>
                    <Box className={clsx(classes.chevron, classes.leftChevron)} onClick={handleScrollLeft}>
                        <ExpandMoreIcon color={"textSecondary"}/>
                    </Box>
                </Fade>
            }
            rightChevron={
                <Fade in={!disabledProp && active < items.length - cols} timeout={duration * 1000 + 1}>
                    <Box className={clsx(classes.chevron, classes.rightChevron)} onClick={handleScrollRight}>
                        <ExpandMoreIcon color={"textSecondary"}/>
                    </Box>
                </Fade>
            }
            {...carouselProps}
        />)

    return (
        <Box className={clsx({
            [classes.root]: true,
            [classes[`chevronPosition${capitalize(chevronPosition)}`]]: chevronPosition,
            [classes[`overflow`]]: overflow,
            [classes[`fade`]]: fade,

        })} {...other}>
            <Measure bounds margin={false} whitelist={['width', 'height']}>
                {renderWrapper}
            </Measure>
        </Box>
    )

});


export default Component;