import withStyles from "@material-ui/core/styles/withStyles";
import {withModals} from "@ticketag/ticketag-uilib/src/components/hoc/Modals/Provider";
import {T} from "@ticketag/i18nlib";
import React, {Component} from "react";
import {withRouter} from 'react-router-dom';
import Dialog from "@ticketag/ticketag-uilib/src/components/hoc/Modals/Dialog"
import {DialogActions, DialogContent, DialogTitle, LinearProgress} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import CheckboxGroup from "@ticketag/ticketag-uilib/src/components/base/Checkbox/CheckboxGroup";
import Button from "@ticketag/ticketag-uilib/src/components/base/Button/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import {CloseIcon} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";
import Hidden from "@material-ui/core/Hidden";
import {lighten} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/List";
import {withAuth} from "../../hoc/Auth/Provider";
import moment from "moment";
import AppInstallerContext from "./context";
import CookieContext from "../../hoc/Cookie/context";


const styles = (theme) => {
    return {
        title: {
            "& h3": {
                marginTop: "4px",
                fontSize: "24px",
                fontWeight: "600",
            },
            paddingTop: "32px",
            paddingBottom: "16px"
        },
        content: {
            paddingBottom: theme.spacing(2),
            paddingLeft:   theme.spacing(5),
            paddingRight:  theme.spacing(5),
            [theme.breakpoints.up('sm')]: {
                maxWidth:  '512px',
                alignSelf: 'center',
                margin:    'auto'
            },
        },
        text: {
            hypens: "none",
            textAlign: "center",
            marginBottom: theme.spacing(4),
            fontSize: "14px",
            "& b": {
                fontWeight: "400"
            }
        },
        actions:        {
            display:                        'flex',
            justifyContent:                 'center',
            paddingTop:                     theme.spacing(3),
            paddingBottom:                  theme.spacing(5),
            paddingLeft:                    theme.spacing(4),
            paddingRight:                   theme.spacing(4),
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column-reverse',
                paddingBottom: theme.spacing(2),
            },
        },
        buttons:        {
            minWidth:                       '160px',
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
                margin:        theme.spacing(1),
            },
        },
        progress: {
            width: '100%',
            margin: theme.spacing(1),
            marginBottom: theme.spacing(2) + 5,
        },
        bottomBanner: {
            position: "fixed",
            bottom: "-200px",
            zIndex: 4,
            backgroundColor: theme.palette.secondary.light, //lighten(theme.palette.accent.main, 0.3),
            width: "100%",
            height: "160px",
            display: "flex",
            paddingTop: "8px",
            transition: "bottom 0.7s ease-in-out"
        },
        iOsInstallList: {
            "& b": {
                fontWeight: "600",
            },
            "& li": {
                marginBottom: "16px",
                fontSize: "14px",
                fontWeight: "300",
                lineHeight: "20px",
                letterSpacing: "0.05em"
            }
        },
        installButton: {
            padding: "6px 32px",
            marginRight: "8px"
        },
    }
}

const InstallSvg = () => {
    return (
        <svg width="766" height="668" viewBox="0 0 766 668" fill="none" xmlns="http://www.w3.org/2000/svg" style={{width: "156px", height: "156px"}}>
            <g clipPath="url(#clip0)">
                <path d="M713.461 666.985V594.645C713.461 594.645 741.653 645.931 713.461 666.985Z" fill="#F1F1F1"/>
                <path d="M715.204 666.972L661.914 618.051C661.914 618.051 718.759 631.966 715.204 666.972Z" fill="#F1F1F1"/>
                <path d="M270.217 252.781H267.239V171.211C267.239 158.69 262.265 146.681 253.412 137.828C244.558 128.974 232.55 124 220.029 124H47.2108C41.011 124 34.8719 125.221 29.144 127.594C23.4162 129.966 18.2117 133.444 13.8278 137.828C9.44383 142.212 5.96631 147.416 3.59373 153.144C1.22116 158.872 1.05058e-05 165.011 0 171.211V618.712C-1.06086e-05 631.233 4.97395 643.241 13.8277 652.095C22.6814 660.949 34.6896 665.923 47.2106 665.923H220.028C232.55 665.923 244.558 660.949 253.412 652.095C262.265 643.242 267.239 631.233 267.239 618.712V310.844H270.217V252.781Z" fill="#3F3D56"/>
                <path d="M221.934 136.283H199.376C200.413 138.826 200.809 141.586 200.529 144.318C200.248 147.051 199.3 149.672 197.768 151.952C196.236 154.232 194.167 156.1 191.743 157.391C189.319 158.683 186.614 159.359 183.867 159.359H84.8619C82.1151 159.359 79.4105 158.683 76.9863 157.391C74.5622 156.1 72.4929 154.232 70.9608 151.952C69.4286 149.672 68.4806 147.051 68.2004 144.318C67.9201 141.586 68.3161 138.826 69.3535 136.283H48.2838C43.6539 136.283 39.0693 137.195 34.7918 138.966C30.5143 140.738 26.6276 143.335 23.3537 146.609C20.0799 149.883 17.4829 153.77 15.7111 158.047C13.9393 162.325 13.0273 166.909 13.0273 171.539V618.384C13.0273 623.014 13.9393 627.599 15.7111 631.876C17.4829 636.154 20.0798 640.04 23.3537 643.314C26.6275 646.588 30.5142 649.185 34.7917 650.957C39.0692 652.728 43.6538 653.64 48.2837 653.64H221.934C231.285 653.64 240.252 649.926 246.864 643.314C253.476 636.702 257.19 627.735 257.19 618.384V618.384V171.539C257.19 166.909 256.279 162.325 254.507 158.047C252.735 153.77 250.138 149.883 246.864 146.609C243.59 143.335 239.704 140.738 235.426 138.966C231.149 137.195 226.564 136.283 221.934 136.283V136.283Z" fill="url(#paint0_linear)"/>
                <path d="M135.11 469.68C181.749 469.68 219.556 431.872 219.556 385.234C219.556 338.595 181.749 300.788 135.11 300.788C88.4719 300.788 50.6641 338.595 50.6641 385.234C50.6641 431.872 88.4719 469.68 135.11 469.68Z" fill="#3D3582"/>
                <path d="M160.13 391.004L139.13 412.634C138.673 413.094 138.127 413.457 137.525 413.699C136.923 413.942 136.279 414.059 135.63 414.044H135.56C134.91 414.058 134.264 413.94 133.661 413.698C133.057 413.456 132.509 413.094 132.05 412.634L111.06 391.004C110.99 390.924 110.91 390.854 110.84 390.784C110.002 389.885 109.555 388.691 109.596 387.463C109.638 386.236 110.164 385.074 111.06 384.234C112.019 383.333 113.285 382.831 114.6 382.831C115.916 382.831 117.182 383.333 118.14 384.234L130.58 397.234V363.714C130.638 362.423 131.192 361.204 132.126 360.311C133.06 359.417 134.303 358.919 135.595 358.919C136.888 358.919 138.13 359.417 139.064 360.311C139.998 361.204 140.552 362.423 140.61 363.714V397.234L153.04 384.234C154.001 383.333 155.268 382.831 156.585 382.831C157.902 382.831 159.17 383.333 160.13 384.234C160.2 384.304 160.27 384.374 160.35 384.454C161.186 385.354 161.631 386.547 161.59 387.774C161.549 389.001 161.024 390.162 160.13 391.004V391.004Z" fill="white"/>
                <path d="M590.256 653.281L577.996 653.28L572.164 605.992L590.258 605.993L590.256 653.281Z" fill="#DCA0A9"/>
                <path d="M593.386 665.165L553.855 665.164V664.664C553.856 660.583 555.477 656.67 558.362 653.784C561.248 650.899 565.161 649.278 569.242 649.277H569.243L593.387 649.278L593.386 665.165Z" fill="#2F2E41"/>
                <path d="M497.121 652.877L485.277 649.71L491.857 602.52L509.337 607.194L497.121 652.877Z" fill="#DCA0A9"/>
                <path d="M497.072 665.165L458.883 654.953L459.012 654.47C460.066 650.528 462.643 647.166 466.176 645.124C469.709 643.082 473.908 642.526 477.851 643.58L477.851 643.58L501.176 649.818L497.072 665.165Z" fill="#2F2E41"/>
                <path d="M597.31 388.269L607.579 400.199L594.298 642.642H565.93L551.627 456.091L507.281 647.645L477.789 640.412L504.605 397.113L597.31 388.269Z" fill="#C9C9C9"/>
                <path d="M511.462 231.744L539.707 217.577L583.144 218.34L620.524 237.468L599.257 343.681L608.444 399.061H608.444C572.936 408.116 535.761 408.422 500.109 399.952L499.824 399.885C499.824 399.885 520.938 324.968 511.95 302.106L511.462 231.744Z" fill="#3D3582"/>
                <path d="M593.176 175.136C593.145 166.659 589.751 158.54 583.74 152.563C577.73 146.585 569.592 143.236 561.115 143.252C552.638 143.268 544.513 146.646 538.524 152.646C532.535 158.646 529.172 166.777 529.172 175.254C529.172 183.732 532.535 191.863 538.524 197.862C544.513 203.862 552.638 207.241 561.115 207.257C569.592 207.272 577.73 203.924 583.74 197.946C589.751 191.968 593.145 183.85 593.176 175.373C593.177 175.294 593.177 175.215 593.176 175.136Z" fill="#DCA0A9"/>
                <path d="M546.843 156.85C548.5 155.29 550.684 154.409 552.96 154.382C556.379 154.271 561.502 155.485 563.682 158.265C565.434 160.499 565.518 163.572 565.525 166.411L565.543 174.247C565.549 176.565 565.577 178.983 566.63 181.048C567.684 183.113 570.101 184.682 572.31 183.978C574.93 183.142 576.082 179.66 578.76 179.034C580.771 178.564 582.837 180.033 583.716 181.902C584.458 183.839 584.704 185.931 584.431 187.988C584.178 192.091 578.293 193.719 577.46 197.744C576.978 200.072 575.291 204.717 577.46 203.744C587.46 202.744 591.85 196.903 596.426 191.405L606.749 179.004C607.928 177.773 608.834 176.307 609.408 174.701C609.664 173.577 609.755 172.421 609.68 171.271C609.624 168.188 609.54 165.107 609.429 162.026C609.332 159.335 608.655 155.939 606.019 155.386C604.648 155.098 602.833 155.6 602.109 154.401C601.837 153.8 601.793 153.121 601.985 152.49C602.567 149.413 603.549 146.196 602.536 143.233C601.009 138.765 595.751 136.932 591.105 136.089C586.459 135.246 581.244 134.52 578.327 130.807C577.547 129.574 576.7 128.384 575.791 127.242C574.453 126.026 572.81 125.196 571.037 124.844C565.269 123.384 559.177 123.925 553.756 126.377C551.509 127.403 549.252 128.784 546.786 128.628C544.225 128.465 542.038 126.663 539.51 126.225C535.426 125.516 531.522 128.576 529.51 132.199C527.016 136.689 526.787 142.837 530.31 146.574C532.067 148.438 534.689 149.719 535.419 152.175C535.716 153.175 535.647 154.251 535.91 155.261C536.216 156.265 536.8 157.163 537.594 157.85C538.388 158.537 539.361 158.986 540.399 159.144C542.896 159.551 545.008 158.407 546.843 156.85Z" fill="#2F2E41"/>
                <path d="M507.562 309.185C508.17 310.746 509.113 312.155 510.324 313.313C511.536 314.471 512.986 315.349 514.574 315.885C516.161 316.422 517.847 316.604 519.512 316.418C521.178 316.233 522.782 315.684 524.213 314.812L581.566 345.13L583.423 331.158L527.694 298.296C526.068 295.99 523.654 294.36 520.907 293.715C518.16 293.071 515.273 293.456 512.791 294.798C510.31 296.141 508.407 298.346 507.443 300.998C506.48 303.649 506.522 306.562 507.562 309.185V309.185Z" fill="#DCA0A9"/>
                <path d="M583.993 311.6C583.058 312.991 581.83 314.16 580.395 315.024C578.959 315.889 577.352 316.428 575.685 316.604C574.019 316.781 572.334 316.589 570.75 316.044C569.166 315.498 567.72 314.612 566.515 313.448L503.918 330.482L504.463 312.744L566.732 296.57C568.823 294.676 571.536 293.614 574.357 293.586C577.178 293.558 579.911 294.566 582.039 296.418C584.166 298.271 585.54 300.84 585.901 303.638C586.261 306.436 585.582 309.269 583.993 311.6V311.6Z" fill="#DCA0A9"/>
                <path d="M608.318 238.232L620.523 237.468C620.523 237.468 634.813 256.323 626.887 276.784C626.887 276.784 628.26 350.282 596.611 347.263C564.961 344.244 554.961 344.244 554.961 344.244L564.461 317.744L585.714 311.182C585.714 311.182 579.164 282.288 591.562 270.266L608.318 238.232Z" fill="#3D3582"/>
                <path d="M520.186 237.587L518.461 228.744C518.461 228.744 493.021 228.147 487.991 266.695C487.991 266.695 465.114 324.388 487.537 331.816C509.961 339.244 534.626 331.816 534.626 331.816L532.768 306.374L508.095 301.339C508.095 301.339 520.841 284.849 513.901 270.547L520.186 237.587Z" fill="#3D3582"/>
                <path d="M764.602 667.744H383.602C383.336 667.744 383.082 667.639 382.894 667.451C382.707 667.264 382.602 667.009 382.602 666.744C382.602 666.479 382.707 666.225 382.894 666.037C383.082 665.85 383.336 665.744 383.602 665.744H764.602C764.867 665.744 765.121 665.85 765.309 666.037C765.496 666.225 765.602 666.479 765.602 666.744C765.602 667.009 765.496 667.264 765.309 667.451C765.121 667.639 764.867 667.744 764.602 667.744Z" fill="#CBCBCB"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear" x1="13.0273" y1="394.962" x2="257.19" y2="394.962" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4DBFB3"/>
                    <stop offset="1" stopColor="#C183FF"/>
                </linearGradient>
                <clipPath id="clip0">
                    <rect width="765.6" height="667.744" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

const IOsAddToHomeScreenIcon = ({className}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" style={{ marginLeft: "2px", marginBottom: "-3px", width: "14px", height: "14px", fill: "#49586F"}}>
            <path
                id="path2"
                d="M 20.727273,0 H 3.272727 C 1.464545,0 0,1.464545 0,3.272727 V 20.727273 C 0,22.535455 1.464545,24 3.272727,24 H 20.727273 C 22.535455,24 24,22.535455 24,20.727273 V 3.272727 C 24,1.464545 22.535455,0 20.727273,0 Z m 1.090909,20.727273 c 0,0.602727 -0.488182,1.090909 -1.090909,1.090909 H 3.272727 C 2.67,21.818182 2.181818,21.33 2.181818,20.727273 V 3.272727 C 2.181818,2.67 2.67,2.181818 3.272727,2.181818 h 17.454546 c 0.602727,0 1.090909,0.488182 1.090909,1.090909 z" />
            <path
                id="path4"
                d="M 16.363636,10.909091 H 13.090909 V 7.636364 a 1.0909091,1.0909091 0 0 0 -2.181818,0 v 3.272727 H 7.636364 a 1.0909091,1.0909091 0 0 0 0,2.181818 h 3.272727 v 3.272727 a 1.0909091,1.0909091 0 0 0 2.181818,0 v -3.272727 h 3.272727 a 1.0909091,1.0909091 0 0 0 0,-2.181818 z" />
        </svg>
    )
}

const IOsShareIcon = ({className}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  style={{marginBottom: "-2px", width: "16px", height: "16px", fill: "#49586F"}}>
            <path
                id="path2"
                d="m 12.283852,0.03085633 c 0.1687,0.0446 0.4619,0.31272 1.9912,1.84721997 1.7331,1.7331 1.7975,1.80265 1.8573,2.04583 0.1788,0.77459 -0.5461,1.35572 -1.2664,1.01802 -0.1192,-0.0595 -0.5314,-0.4271 -1.0427,-0.94357 -0.4619,-0.46677 -0.8591,-0.84905 -0.8789,-0.84905 -0.02,0 -0.045,2.6517 -0.05,5.89432 l -0.015,5.8992097 -0.1141,0.19861 c -0.1739,0.30293 -0.4121,0.44178 -0.7499,0.44692 -0.3624,0 -0.6406,-0.16873 -0.7944,-0.4817 l -0.1043,-0.21844 v -5.8992097 -5.89921 l -0.9335,0.94357 c -0.8789997,0.89385 -0.9435997,0.94846 -1.1818997,1.00307 -0.457,0.10433 -0.8789,-0.0992 -1.0677,-0.52137 -0.1043,-0.22849 -0.1144,-0.36758 -0.04,-0.64554 0.045,-0.16897 0.3328,-0.4768 1.7728,-1.92657 0.9484997,-0.94845997 1.7876997,-1.76786997 1.8718997,-1.82247997 0.1937,-0.11437 0.4967,-0.15404 0.745,-0.0896 z" />
            <path
                id="path4"
                d="m 19.856452,6.0990363 c 0.5363,0.1489 0.9186,0.45697 1.137,0.92374 l 0.1291,0.27305 v 7.6472497 7.64725 l -0.1142,0.2831 c -0.144,0.35754 -0.5412,0.79957 -0.874,0.9732 l -0.2532,0.1342 -7.7812,0.0149 c -7.7564997,0.01 -7.7811997,0.01 -8.0542997,-0.0894 -0.3178,-0.11927 -0.7151,-0.45673 -0.8887,-0.75966 -0.3029,-0.51134 -0.288,-0.0397 -0.273,-8.32732 l 0.015,-7.5725597 0.1193,-0.22358 c 0.1836,-0.34751 0.3029,-0.48661 0.571,-0.67027 0.447,-0.30294 0.6554,-0.32767 2.5721,-0.31273 l 1.7033,0.0149 0.2334,0.1342 c 0.3822,0.22848 0.5314,0.66537 0.3624,1.06773 -0.1043,0.24832 -0.2087,0.35754 -0.4469,0.46676 -0.1788,0.0845 -0.3475,0.0943 -1.6486,0.0943 h -1.4501 l -0.1391,0.11901 -0.1391,0.11927 -0.015,6.8327397 -0.01,6.83763 0.1192,0.11927 0.1242,0.12416 h 7.1456997 c 6.9669,0 7.1506,0 7.2647,-0.0943 l 0.1193,-0.0943 v -6.86262 -6.8626097 l -0.1391,-0.11927 -0.1391,-0.11901 h -1.4302 -1.4252 l -0.2731,-0.1391 c -0.72,-0.36244 -0.6306,-1.32585 0.1489,-1.58911 0.2437,-0.0855 3.4265,-0.0754 3.7295,0.009 z" />
        </svg>
    )
}


class AppInstaller extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            bannerDismissed: false,
            open: false,
            installing: false,
            installed: false,
        }
        this.bannerRef = React.createRef()
        this.hideBanner = this.hideBanner.bind(this)
        this.showBanner = this.showBanner.bind(this)
    }

    componentDidMount() {
        if(this.isStandalone() && this.props.isAuthenticated && "Notification" in window) {
            console.log("permission",Notification.permission)
            Notification.requestPermission().then((permission)=>{
                if (permission ==="granted"){
                    navigator.serviceWorker.ready.then(reg=>{
                        const applicationServerKey = document.querySelector("[name~=APPLICATION_SERVER_KEY][content]")?.content || process.env.APPLICATION_SERVER_KEY //<meta name="STRIPE_KEY" content="pk_test_...." />
                        reg.pushManager.subscribe({
                            userVisibleOnly: true,
                            applicationServerKey:applicationServerKey,
                        }).then(sub=>{
                            const BaseUrl = new URL(window.location.href)
                            const localizedBaseUrl = `${BaseUrl.protocol}//${BaseUrl.hostname}/${this.props.i18n.selectedLocale()}/`
                            this.props.api.RegisterNotificationDevice(sub.toJSON(),localizedBaseUrl).then(resp=>{
                                console.log("api response",resp)
                            })
                        })
                    })
                }
            })
        }
        setTimeout(() => {
                this.showBanner()
                window.onscroll = (function() {
                    if (window.pageYOffset > 0) {
                        this.hideBanner()
                    } else {
                        this.showBanner()
                    }
                }).bind(this)
                window.addEventListener('appinstalled', (e) => {
                    this.appInstalled = true;
                });
        }, 1000)
    }

    dismissBanner() {
        this.setCookie()
        this.setState({bannerDismissed: true})
        this.hideBanner()
    }

    isStandalone() {
        return (window.matchMedia('(display-mode: standalone)').matches) || window.navigator?.standalone || document.referrer?.includes('android-app://');
    }

    shouldShowBanner() {
        if (!this.props.showBanner) {
            return false;
        }
        if (this.state.bannerDismissed) {
            return false;
        }
        if (!this.props.isMobile || this.isStandalone()) {
            return false;
        }
        const cookie = this.getCookie()
        if (cookie) {
            const appBannerDismissedAt = moment(cookie.value.appBannerDismissedAt)
            if (!!appBannerDismissedAt && moment(Date.now()).diff(appBannerDismissedAt, 'h')  < 1) {
                return false
            }
        }
        if (typeof this.routeMatch === "undefined") {
            this.routeMatch = false
            for (const regex of [
                /\/error\/[0-9]*$/g,
                /\/welcome$/g,
                /create\/.+$/g,
                /complete$/g,
                /\/join\/(payment|success|fail|processing)$/g,
            ]) {
                if (this.props.location.pathname.match(regex)) {
                    this.routeMatch = true
                    return false
                }
            }
        }
        if (this.routeMatch) {
            return false
        }
        return (this.isAndroid() && window.deferredPrompt) || this.isIOs()
    }

    isAndroid() {
        const ua = window.navigator.userAgent;
        return !!ua.match(/Chrome/i);
    }

    isIOs() {
        const ua = window.navigator.userAgent;
        const isIPad = !!ua.match(/iPad/i);
        const isIPhone = !!ua.match(/iPhone/i);
        return isIPad || isIPhone;
    }

    hideBanner() {
        if (this.bannerRef.current) {
            this.bannerRef.current.style.bottom = "-200px"
        }
    }
    showBanner() {
        if (this.shouldShowBanner()) {
            this.bannerRef.current.style.bottom = this.props.auth.isAuthenticated() ? "0px" : "-70px"
        }
    }

    setCookie() {
        localStorage.setItem('AppBannerDismissedAt', JSON.stringify({value: moment(Date.now()).format()}))
    }

    getCookie() {
        return JSON.parse(localStorage.getItem('AppBannerDismissedAt'))
    }

    installApp() {
        if (this.isAndroid()) {
            window.location.href = "https://play.google.com/store/apps/details?id=com.diveedi.www.twa"
        } else {
            if (this.isIOs()) {
                this.setState({open: true})
            } else {
                this.setState({open: true, installing: true})
                if (window.deferredPrompt) {
                    const promise = new Promise((resolve, reject) => {
                        window.deferredPrompt.userChoice.then(function (choiceResult) {
                            console.log(choiceResult)
                            if (choiceResult.outcome === 'dismissed') {
                                reject(choiceResult)
                            } else {
                                resolve(choiceResult)
                            }
                        })
                    })
                    window.deferredPrompt.prompt();
                    Promise.resolve(promise).
                    catch(() => {
                        this.setState({open: false})
                        this.hideBanner()
                    }).
                    then(() => {
                        setTimeout(() => {
                            if (!this.appInstalled) {
                                this.setState({open: false})
                            } else {
                                this.setState({installing: false, installed: true})
                                this.dismissBanner()
                            }
                        },  10000)
                        window.deferredPrompt = null;
                    })
                }
            }

        }
    }


    render() {
        const {children , ...other} = this.props;

        const renderTitle = () => {
            if (this.state.installed) {
                return <T key={"installed_title"} defaultVal={"Fatto!"}>modals.install_app.app_installed_title</T>
            }
            return <T defaultVal={"Installa l'app"}>modals.install_app.default_install_title</T>
        }

        const renderContent = () => {
            if (this.isIOs()) {
                return (
                    <React.Fragment>
                        <Typography variant={"body2"} color={"textPrimary"} className={this.props.classes.text} align={"center"}>
                            Per installare l'app, clicca sull' icona <b>Condividi  <IOsShareIcon/></b>, e seleziona dal menu <b>Aggiungi alla schermata Home <IOsAddToHomeScreenIcon/></b>
                        </Typography>
                    </React.Fragment>

                )
            } else if (this.isAndroid()) {
                if (this.state.installing) {
                    return (
                        <Box>
                            <Typography variant={"body2"} color={"textPrimary"} className={this.props.classes.text} align={"center"}>
                                <T key={"installing_content"} defaultVal={`Installazione dell'app in corso...`}>modals.install_app.android_installing_text</T>
                            </Typography>
                        </Box>
                    )
                } else if (this.state.installed) {
                    return (
                        <Box>
                            <Typography variant={"body2"} color={"textPrimary"} className={this.props.classes.text} align={"center"}>
                                <T key={"installed_content"} defaultVal={`App installata con successo!`}>modals.install_app.android_installed_text</T>
                            </Typography>
                        </Box>
                    )
                }
                return (
                    <Typography variant={"body2"} color={"textPrimary"} className={this.props.classes.text} align={"center"}>
                        <T defaultVal={`Desideri aggiungere l'app di Diveedi alla schermata Home del tuo telefono?`}>modals.install_app.android_install_text</T>
                    </Typography>
                )
            }
        }

        const renderButtons = () => {
            if (this.isIOs()) {
                return (
                    <Button className={this.props.classes.buttons} onClick={() => this.setState({open: false})}>
                        <T defaultVal={"Chiudi"}>globals.action.close</T>
                    </Button>
                )
            } else if (this.isAndroid()) {
                if (this.state.installing) {
                    return <LinearProgress color={'secondary'} classes={{root: this.props.classes.progress}}/>
                } else if (this.state.installed) {
                    return (
                        <React.Fragment>
                            <Button className={this.props.classes.buttons}  variant={"outlined"} color={"primary"} onClick={() => this.setState({open: false})}>
                                <T defaultVal={"Continua sul sito"}>modals.install_app.continue_on_site</T>
                            </Button>
                            <Button className={this.props.classes.buttons} onClick={() => {
                                window.open("/groups/")
                                this.setState({open: false})
                            }}>
                                <T defaultVal={"Vai all'app"}>modals.install_app.go_to_app</T>
                            </Button>
                        </React.Fragment>
                    )
                }
                return (
                    <React.Fragment>
                        <Button className={this.props.classes.buttons}  variant={"outlined"} color={"primary"} onClick={() => this.setState({open: false})}>
                            <T defaultVal={"Chiudi"}>globals.action.close</T>
                        </Button>
                        <Button className={this.props.classes.buttons} onClick={() => this.installApp()}>
                            <T defaultVal={"Installa l'applicazione"}>modals.install_app.install_action</T>
                        </Button>
                    </React.Fragment>
                )
            }
        }

        return (
            <React.Fragment>
                <AppInstallerContext.Provider
                    value={{
                        deferredPrompt: () => window.deferredPrompt,
                        isMobile: () => this.props.isMobile,
                        isIOs: () => this.isIOs(),
                        isAndroid: () => this.isAndroid(),
                        isStandalone: () => this.isStandalone(),
                        installApp : () => this.installApp(),
                    }}>{children}
                </AppInstallerContext.Provider>
                        <Box className={this.props.classes.bottomBanner} ref={this.bannerRef}>
                            <Box  p={1} height={"60px"} width={"100%"} display={"flex"} alignItems={"center"}>
                                <CloseIcon color={"white"} onClick={() => this.dismissBanner()}/>
                                <Box flexGrow={"2"} px={2}>
                                    <Typography variant={"body1"} bold color={"white"}><T defaultVal={"Installa l'app"}>components.app_installer_banner.title</T></Typography>
                                    <Typography variant={"caption"} color={"white"}>
                                        <T defaultVal={"Ottieni la nostra app gratuita. Non occuperà spazio sul tuo telefono."}>components.app_installer_banner.text</T>
                                    </Typography>
                                </Box>
                                <Button onClick={() => this.installApp()} className={this.props.classes.installButton} color={"secondary"}>
                                    <T defaultVal={"Installa"}>components.app_installer_banner.button_text</T>
                                </Button>
                            </Box>
                        </Box>
                <Dialog open={this.state.open} size={"md"} className={this.props.classes.dialog}>
                    <DialogTitle disableTypography={true} className={this.props.classes.title}>
                        <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                            <InstallSvg/>
                            <Typography variant={"h3"} color={"textSecondary"} align={"center"}>
                                { renderTitle() }
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent className={this.props.classes.content}>
                        {renderContent()}
                    </DialogContent>
                    <DialogActions  classes={{root: this.props.classes.actions}}>
                        {renderButtons()}
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }

}

const withWidth = (Component) => {
    return function (props) {
        const theme = useTheme()
        const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
        return (
            <Component isMobile={isMobile} {...props}/>
        )
    }
}


export const withAppInstaller = (Component) => {
    return ({ref, ...props}) => (<AppInstallerContext.Consumer>
        {(appInstaller) => {
            return <Component ref={ref} appInstaller={appInstaller} {...props}/>
        }}
    </AppInstallerContext.Consumer>)
}

export default withStyles(styles)(withWidth(withAuth(withRouter(AppInstaller))))