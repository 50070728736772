import withStyles from "@material-ui/core/styles/withStyles";
import {Card, CardContent, CardHeader, CardMedia} from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import Typography from "../../base/Typography/Typography";
import {RightArrowIcon, SelectArrowIcon} from "../../base/Icon/Icon";

const styles = (theme) => {
    return {
        root: {
            width: "312px",
            height: "80px",
            borderRadius: "16px",
            display: 'flex',
        },
        title: {
            textTransform: "uppercase",
            display: "inline",
            fontSize: "18px",
            fontWeight: "600",
            lineHeight: "28px",
            letterSpacing: "0.01em"
        },
        subtitle: {
            fontSize: "16px",
            fontWeight: "5080",
            lineHeight: "22px",
            letterSpacing: "0.01em"
        },
        media: {
            width: "73px",
            height: "80px",
        },
        arrow: {
            width: "28px",
            height: "28px",
            transform: "rotate(270deg)"
        }
    }
}

function TTmpCard(props) {
    const {children, classes, className, title, titleColor = "#3D3582", media, subtitle, showArrow = true, onClick, ...other} = props;
    return (
        <Card elevation={5} className={clsx(classes.root, className)} style={onClick ? {cursor: "pointer"} : null} onClick={onClick} {...other}>
            {media ?
                (
                    typeof media === "string" ?
                        <CardMedia className={classes.media} image={media}/> :
                        typeof media === "object" ? media : null
                ) : null
            }
            {
                title ?
                    <Box display={"flex"} flexGrow={1} {...(subtitle ? {} : {px: 2})}>

                            {subtitle ? <CardContent>
                                <Typography className={classes.title} style={{color: titleColor}}>{title}</Typography>
                                <Typography className={classes.subtitle}>{subtitle}</Typography>
                            </CardContent> : <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                <Typography className={classes.title} style={{color: titleColor}}>{title}</Typography>
                            </Box>
                            }

                    </Box>
                    :
                    null
            }
            { children }
            {
                showArrow ?
                    <Box display={"flex"} jutifyConten={"center"} alignItems={"center"} pr={2}>
                        <SelectArrowIcon className={classes.arrow} color={"accent"}/>
                    </Box>
                    :
                    null
            }
        </Card>
    )
}

export default withStyles(styles)(TTmpCard)