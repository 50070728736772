import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { T } from "@ticketag/i18nlib";
import Box from "@material-ui/core/Box";
import TextField from "@ticketag/ticketag-uilib/src/components/base/TextField/TextField";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import ComboBox from "@ticketag/ticketag-uilib/src/components/base/Select/ComboBox";
import Grid from "@material-ui/core/Grid";
import Button from "@ticketag/ticketag-uilib/src/components/base/Button/Button";
import Form from "@ticketag/ticketag-uilib/src/components/complex/Form/Form";
import Checkbox from "@ticketag/ticketag-uilib/src/components/base/Checkbox/Checkbox";

const styles = theme => {
  return {
    root: {}
  };
};

export const NewGroupDataForm = ({
  formRef = null,
  onBlur,
  defaultValues = {},
  createTerms = [],
  classes,
  visibilities,
  serviceDetails,
  editMode = false,
  disabled,
  ...props
}) => {
  const [selected, setSelected] = React.useState(defaultValues.billingCycle);
  const [visibility, setVisibility] = React.useState(null);
  const maxSize = serviceDetails.find(elt => elt.uuid === selected)?.maxSize;

  function getVisibilityComboBox() {
    return <ComboBox popover={'pages.create_group.visibility_popover'} fullWidth label={<T defaultVal={"visibilità"}>pages.create_group.visibility</T>} options={visibilities.map(elt => {
      return {
        value: elt,
        label: <T key={elt} capitalize defaultVal={elt}>{`groups.scope.${elt}`}</T>
      };
    })} defaultValue={defaultValues.scope || ''} rules={{
      required: true
    }} name="scope" variant={'outlined'} disabled={disabled} getOptionLabel={option => option.label} box={{
      mb: 0
    }} />;
  }

  function getBillingCycleComboBox() {
    return <ComboBox popover={'pages.create_group.billing_cycle_popover'} name="billingCycle" fullWidth defaultValue={defaultValues.billingCycle || ''} rules={{
      required: true
    }} variant={"outlined"} label={<T defaultVal={"Tipo di sottoscrizione"}>pages.create_group.billingCycle</T>} options={serviceDetails} disabled={disabled || editMode} getOptionValue={option => option.uuid} getOptionLabel={option => <T capitalize key={option.billingCycle} defaultVal={option.billingCycle}>{`global.periods.${option.billingCycle}`}</T>} box={{
      mb: 0,
      mt: {
        xs: 0,
        md: 3
      }
    }} />;
  }

  function getPriceTypeComboBox() {
    return <ComboBox fullWidth name="priceType" defaultValue={visibility === "public" ? "NUM MAX" : ""} disabled={disabled || !editMode && (!selected || !visibility)} variant={"outlined"} popover={'pages.create_group.price_type_popover'} label={<T defaultVal={"Suddivisione quote"}>pages.create_group.priceType</T>} rules={{
      required: true
    }} options={visibility === "public" ? [{
      value: "NUM MAX",
      label: "NUM MAX"
    }] : serviceDetails.find(elt => elt.uuid === selected)?.privatePriceTypes.map(elt => ({
      value: elt,
      label: elt
    }))} />;
  }

  function getSizeComboBox() {
    return <ComboBox fullWidth disabled={disabled || !editMode && (!selected || !visibility)} variant={"outlined"} popover={'pages.create_group.price_type_popover'} label={"Posti disponibili"} name="size" box={{
      mb: 0,
      mt: {
        xs: 0,
        md: 3
      }    }} rules={{
      required: true
    }} defaultValue={defaultValues.size || ''} options={new Array(maxSize).fill().map((_, i) => ({
      value: i + 1,
      label: i + 1
    }))} />;
  }

  return <Form formRef={formRef} T={T} onChange={(vals, formState) => {
    setVisibility(vals.scope);
    setSelected(vals.billingCycle);
    props.onChange ? props.onChange(vals, formState) : null;
  }} onValidate={formState => props.onValidate ? props.onValidate(formState) : null} grid={{
    spacing: 4
  }} defaultValues={defaultValues} disabled={disabled} componentname="new-group-data-form">
        {getVisibilityComboBox()}
        {
      /*getPriceTypeComboBox()*/
    }
        {getSizeComboBox()}
        {createTerms.map((t, i) => //
    <Checkbox key={`consent-${t.split('.')[2]}`} label={<T useMarkdown defaultVal={t}>{t}</T>} box={{
      m: -1,
      mt: i === 0 ? 7 : -1,
      mb: i === createTerms.length - 1 ? 7 : -1
    }} grid={{
      md: 12
    }} name={`consent-${t.split('.')[2]}`} rules={{
      required: true
    }} />)}
    </Form>;
};
export default withStyles(styles, {
  useTheme: true
})(NewGroupDataForm);