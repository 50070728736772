import React, {Component} from 'react';
import AuthContext from './context';
import {ApiClient, UserApi} from "@ticketag/diveedi-client";
import FullscreenProgress from "../../components/FullscreenProgress/FullscreenProgress";
import {withI18n} from "@ticketag/i18nlib";

//import * as WebSharingWebApi from "@ticketag/diveedi-client";

class AuthProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            isLoading: true,
            userInfo: null,
            logoutUrl: this.props.logoutUrl || '/auth/logout',
            loginUrl: this.props.loginUrl || '/auth/login',
        }
        const apiClient = this.props.apiClient || new ApiClient();
        this.userApi = new UserApi(apiClient)
    }

    get loginUrl() {
        const currentUrl = window.location.pathname;
        return `${this.state.loginUrl}?from=${currentUrl||'/'}&lang=${this.props.i18n.selectedLocale()}`
    }
    get signUpUrl() {
        const currentUrl = window.location.pathname;
        return `${this.state.loginUrl}?from=${currentUrl||'/'}&lang=${this.props.i18n.selectedLocale()}&login_hint=register`
    }
    signUrlWithMail(from=true, register = false, email = "") {
        const currentUrl = window.location.pathname;
        return `${this.state.loginUrl}?lang=${this.props.i18n.selectedLocale()}${from ? "&from=" + currentUrl : ""}${email ? "&email=" + email : ""}${register ? "&login_hint=register" : ""}`
    }
    componentDidMount() {
        this.setState({
            isLoading: true,
        }, () => this.loadUserInfo().catch((err) => {}))
    }

    loadUserInfo() {
        return this.userApi.getUserInfo().then((data) => {
            localStorage.removeItem('redirect-count')
            this.setState({
                isLoading: false,
                isAuthenticated: true,
                userInfo: data,
            })
        }).catch((err) => {
            this.setState({isLoading: false, isAuthenticated: false})
            throw err
        })
    }
    getUserInfo() {
        return this.state.userInfo
    }
    refreshUserInfo() {
        return this.loadUserInfo()
    }
    isAuthenticated() {
        return this.state.isAuthenticated
    }
    isAdmin() {
        if (this.state.isLoading || !this.state.userInfo) {
            return false
        }
        const role = this.state.userInfo.role
        return role === 'super_admin'
    }

    isLoading() {
        return this.state.isLoading
    }

    render() {

        return (
            <AuthContext.Provider value={{
                isLoading: () => this.isLoading(),
                getUserInfo: () => this.getUserInfo(),
                refreshUserInfo: () => this.refreshUserInfo(),
                isAuthenticated: () => this.isAuthenticated(),
                isAdmin: () => this.isAdmin(),
                signUrlWithMail: (from, register, email) => this.signUrlWithMail(from, register, email),
                logoutUrl: this.state.logoutUrl,
                loginUrl: this.loginUrl,
                signUpUrl: this.signUpUrl
            }}>
                {this.props.children}
            </AuthContext.Provider>
        )
    }
}

export const withAuth = (Component) => {
    return ({ref, ...props}) => (<AuthContext.Consumer>
        {(api) => {
            return <Component ref={ref} auth={api} {...props}/>
        }}
    </AuthContext.Consumer>)
}

export default withI18n(AuthProvider);
