import {withStyles} from "@material-ui/core/styles";
import React from "react"
import NewGroupHeader from "../../components/NewGroupHeader/NewGroupHeader";
import Box from "@material-ui/core/Box";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import TextButton from "@ticketag/ticketag-uilib/src/components/base/Button/TextButton";
import {T, withI18n} from "@ticketag/i18nlib";
import NewGroupDataForm from "../../components/NewGroupDataForm/NewGroupDataForm";
import Button from "@ticketag/ticketag-uilib/src/components/base/Button/Button";
import Grid from "@material-ui/core/Grid";
import Progress from "@ticketag/ticketag-uilib/src/components/base/Progress/Progress";
import Data from "../../data";
import {withApi} from "../../hoc/Api/Provider";
import {Link as RouterLink, withRouter} from 'react-router-dom';
import {withErrorModal, withRedirect} from "../../components/ErrorMessage/ErrorMessage";
import CardsContainer from "../../components/CardsContainer/CardsContainer";
import ActionCard from "@ticketag/ticketag-uilib/src/components/complex/ActionCard/ActionCard";
import {CreateGroup, JoinGroup, MessagesIcon} from "../../components/Icons/Icons";
import {ExitIcon} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";
import Form from "@ticketag/ticketag-uilib/src/components/complex/Form/Form";
import PasswordTextField from "@ticketag/ticketag-uilib/src/components/base/TextField/PasswordTextField";
import TextField from "@ticketag/ticketag-uilib/src/components/base/TextField/TextField";
import Alert from "@ticketag/ticketag-uilib/src/components/base/Alert/Alert";
import Header from "@ticketag/ticketag-uilib/src/components/base/Header/Header";
import Breadcrumbs from "@ticketag/ticketag-uilib/src/components/base/Breadcrumbs/Breadcrumbs";
import {withModals} from "@ticketag/ticketag-uilib/src/components/hoc/Modals/Provider";
import ConfirmForm from "../../components/ConfirmForm/ConfirmForm";
import Hidden from "@material-ui/core/Hidden";
import Layout from "../../hoc/Layout/Layout";
import Navbar from "../../components/Navbar/Navbar";
import NavTitle from "../../components/Navbar/NavTitle/NavTitle";
import Page from "../../components/Page/Page";
import {pages} from "../../constants";
import {SeoDescription, SeoTitle} from "../Seo/Seo";

const styles = (theme) => {
    return {
        root: {},
        subtitle: {
            marginBottom: "40px"
            /*
            fontSize: "20px",
            fontWeight: "300",
            */
        },
        messagesIcon: {
            width: "120px",
            height: "120px",
            marginBottom: "-30px"
        },
        exitIcon: {
            width: "90px",
            height: "90px",
            marginBottom: "-10px",
            marginLeft: "20px"
        },
        cardTitle: {
            margin: "0 20px"
        },
        undo: {
            [theme.breakpoints.down('sm')]: {
                position: "absolute",
                marginRight: "48px",
                marginTop: "-42px",
                right: 0
            }
        },
        alert: {
            marginTop: "24px"
        }
    }
}

class Dispute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            refund: false,
            serviceFamily: null,
            formValid: false,
        }
    }

    componentDidMount() {
        this.loadData()
    }
    loadData() {
        if (this.state.refund) {
            return setTimeout(() => this.setState({isLoading: false}), 1000)
        }
        if (this.state.group && this.state.serviceFamily) {
            return setTimeout(() => this.setState({isLoading: false}), 1000)
        }
        return withRedirect(this.props.history, Promise.all([
                this.props.api.groupApi.groupDetails(this.props.match.params.groupUuid),
            ]).then(([group]) => {
                return this.props.api.GetServiceFamily(group.serviceFamilyUuid).then(serviceFamily => {
                    this.setState({
                        isLoading: false,
                        group: group,
                        serviceFamily: serviceFamily,
                    })
                })

            })
        )
    }
    reportAndGoToHelpChat() {
        if (!this.state.error) {
            this.props.api.groupApi.reportWrongCredentials(this.props.match.params.groupUuid,{})
                .then(() => {
                    this.props.history.push(this.props.i18n.buildLocalizedUrl(`/chat/${this.props.match.params.groupUuid}`))
                })
                .catch((err) => {
                this.setState({error: err})
            })
        }
    }

    onClickUndo() {
        if (this.state.refund) {
            this.setState({refund: false, isLoading: true})
            this.loadData()
        } else {
            this.props.history.goBack()
        }
    }
    submitForm(e, form) {
            this.props.api.groupApi.createPaymentDispute(this.props.match.params.groupUuid,{}).
            then((ok) => {
                this.props.history.push(this.props.i18n.buildLocalizedUrl("/chat/help"))
            }).catch((err) => {
                this.setState({error: err})
            })
    }
    renderContent() {
        const {
            data = Data.Dispute,
            classes,
            ...other
        } = this.props;
        const inputRef=React.createRef()
        return (
            <Grid container justify={"center"}>
                <Grid xs={12} md={8} item>

                    <Hidden xsDown>
                        <Header
                            avatar={this.state.serviceFamily.logo}
                            label={
                                <TextButton className={this.state.refund ? classes.undo : null} onClick={() => this.onClickUndo()} size="large" variant="secondary">{data.undo}</TextButton>
                            }
                            breadcrumbs={
                                <Breadcrumbs
                                    linksComponent={RouterLink}
                                    links={
                                        [
                                            {url: this.props.i18n.buildLocalizedUrl('groups'), title: <T defaultVal="Gruppi">global.links.groups</T>},
                                            {url: this.groupDetailsUrl, title: <T defaultVal="Il tuo gruppo {{.args.Group}}" useTemplate args={{Group: this.state.serviceFamily.name}}>global.links.group_template</T>},
                                        ]
                                    }>{this.pageTitle}</Breadcrumbs>
                            }
                        >{this.state.refund ? data.refund.title : data.dispute.title}</Header>
                    </Hidden>
                    <Hidden smUp>
                        <Header
                            label={
                                <TextButton className={this.state.refund ? classes.undo : null} onClick={() => this.onClickUndo()} size="large" variant="secondary">{data.undo}</TextButton>
                            }
                        >{this.state.refund ? data.refund.title : data.dispute.title}</Header>
                    </Hidden>
                    {this.state.error ?
                        <Alert className={classes.alert} noIcon color={"error"}>{this.formatError()}</Alert>
                        : null}
                    <Box mt={4} mb={4}>
                        <Box>
                            <Typography align={"justify"} variant="body2" whiteSpace={"preLine"} color={"textPrimary"} className={classes.subtitle}>
                                {this.state.refund ? data.refund.subtitle : data.dispute.subtitle}
                            </Typography>
                        </Box>
                    </Box>
                    {
                        this.state.refund ?
                            <Grid container item justify={"center"}>
                                <Grid item xs={8}>
                                    <ConfirmForm fullWidth variant="outlined"
                                                 confirmKey={<T defaultVal={"conferma"}>global.hidden.confirm_create_dispute</T>}
                                                 label={<T defaultVal="Conferma">modals.confirm_create_dispute.confirm_label</T>}
                                                 onChange={(e, val) => {
                                                     this.setState({formValid: val})
                                                 }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box pt={4} display={"flex"} justifyContent={"center"}>
                                        <Button disabled={!this.state.formValid} onClick={() => this.submitForm()} variant={"outlined"} error={true}>{data.refund.leave}</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            :
                            <CardsContainer box={{pb:12}} spacing={9}>
                                <Grid item>
                                    <ActionCard
                                        title={<Typography className={classes.cardTitle} align={"center"} variant={"h5"} color={"textSecondary"}>{data.dispute.refund.title}</Typography>}
                                        onClick={() => this.reportAndGoToHelpChat()}
                                        icon={<MessagesIcon color={"gradient"} className={classes.messagesIcon}/>}/>
                                </Grid>
                                <Grid item>
                                    <ActionCard
                                        title={<Typography className={classes.cardTitle} align={"center"}variant={"h5"} color={"error"}>{data.dispute.help.title}</Typography>}
                                        onClick={() => { this.setState({refund: true, isLoading: true, error: null}); this.loadData()}}
                                        icon={<ExitIcon color={"error"} className={classes.exitIcon}/>}/>
                                </Grid>
                            </CardsContainer>
                    }
                </Grid>
            </Grid>
        );
    }

    formatError() {
        const code = this.state.error?.response?.body?.string_code
        const message = this.state.error?.response?.body?.message
        if (code) {
            return <T defaultVal={message}>{code}</T>
        }
        return <T>{"errors.generic.generic"}</T>
    }

    renderNavbar() {
        const serviceFamily = this.props.group.serviceFamily
        const isLoading = !this.state.isLoaded
        if (isLoading) {
            return <Navbar logo/>
        }
        return <Navbar
            useTemplate
            args={{Name: serviceFamily.name }}
            logo={serviceFamily.logo}
            title={this.props.layout.title}
        />
    }

    render() {
        const isLoading = this.state.isLoading
        if (isLoading) {
            return null
        }
        const serviceFamily = this.props.group.serviceFamily
        const {group, auth, i18n, history} = this.props;
        const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1)
        const {
            seo,
            layout,
            ...other
        } = this.props;
        return (
            <Page layout={{...layout, navbar: this.renderNavbar()}} {...other}>
                <SeoTitle args={{Name: capitalize(serviceFamily.name)}}>{seo.title}</SeoTitle>
                <SeoDescription args={{Name: capitalize(serviceFamily.name)}}>{seo.description}</SeoDescription>
                {this.renderContent()}
            </Page>
        )
    }
}

export default withStyles(styles, {})(withI18n(withApi(withRouter(withModals(Dispute)))))