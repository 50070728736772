import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T, I18nContext} from "@ticketag/i18nlib";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import {
    CogIcon,
    ExitIcon,
    FlagIcon,
    LanguageIcon,
    LanguageSyncIcon, NarrowArrowIcon
} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";
import AuthContext from "../../hoc/Auth/context";
import ToggleButton from "@ticketag/ticketag-uilib/src/components/base/Button/ToggleButton";
import Box from "@material-ui/core/Box";
import {GroupsIcon, MessagesIcon, WalletIcon} from "../Icons/Icons";
import {withRouter} from 'react-router-dom'
import Hidden from "@material-ui/core/Hidden";
import Divider from "@material-ui/core/Divider";
import AvatarIcon, {AvatarMissingIcon} from "@ticketag/ticketag-uilib/src/components/base/Avatar/AvatarIcon";
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/List"
import ListItemText from "@material-ui/core/ListItemText";

import {Link as RouterLink, Redirect as RouterRedirect} from "react-router-dom";

import Link from "@ticketag/ticketag-uilib/src/components/base/Link/Link";
import clsx from "clsx";
import Data from "../../data";
import {ListItemIcon, ListSubheader} from "@material-ui/core";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import BottomNavigation from "./BottomNavigation/BottomNavigation";
import Drawer from "./Drawer/Drawer";
import Tabs from "./Tabs/Tabs";
import Menu from "./Menu/Menu";
import Button from "@ticketag/ticketag-uilib/src/components/base/Button/Button";
import IconButton from "@ticketag/ticketag-uilib/src/components/base/Button/IconButton";
import MenuItem from "@ticketag/ticketag-uilib/src/components/base/Menu/MenuItem";
import TextButton from "@ticketag/ticketag-uilib/src/components/base/Button/TextButton";
import capitalize from "@material-ui/core/utils/capitalize";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import NavTitle from "./NavTitle/NavTitle";
import AppLogo from "../AppLogo/AppLogo";
import {withLayout} from "../../hoc/Layout/Layout";

// We can inject some CSS into the DOM.
const styles = (theme) => {
    return {
        root:             {
            height: "100px",
            [theme.breakpoints.up("sm")]: {
                height: "130px",
            },
            marginBottom: "18px"
        },
        toolbar: {
            display: "flex",
            height: "100%",
            justifyContent: "center",
            padding: "0 32px",
            [theme.breakpoints.up("sm")]: {
                padding: "0 64px",
            },
            "&::before": {
                position: "absolute",
                content: "''",
                top: "0",
                left: "0",
                right: "0",
                bottom: "42px",
                //background: "linear-gradient(180deg, #565555 0%, rgba(196, 196, 196, 0) 100%)",
                opacity: "0.15",
                zIndex: "-1"
            },
        },
        logoBox:          {
            height:     "48px",
            display:    "flex",
            alignItems: "center",
            flexGrow:   "1",
            "& img":    {
                height: "40px",
            }
        },
        logo:             {
            cursor: "pointer",
        },
        divider:          {
            color: "#49586F",
            opacity: "0.5",
            height: "34px",
            margin: "0 64px",
            border: "1px solid rgba(73, 88, 111, 0.5)"
        },
        positionDefault:  {},
        positionAbsolute: {},
        drawerSubtitle: {
            marginBottom: "16px"
        },
        drawerSection: {
            marginBottom: "32px",
            "& span": {
                fontSize: "20px"
            }
        },
        list: {
            padding: "32px 0"
        },
        listItem: {
            cursor: "pointer",
            padding: "8px 0px",
            fontSize: "20px",
            fontWeight: "300",
            lineHeight: "24px",
            letterSpacing: "0.01em",
            color: theme.palette.text.primary,
            display: "flex",
            alignItems: "center"
        },
        listItemIcon: {
            minWidth: "32px"
        },
        menuDivider: {
            marginBottom: "10px",
            marginTop: "4px",
        },
        menuItem: {
            "&:hover": {
                backgroundColor: "transparent"
            },
            "& svg": {
                marginTop: "3px",
                marginRight: theme.spacing(0.5)
            },
            padding: "0 0 16px 0",
        },
        menuError: {
            color: theme.palette.error.main
        },
        header: {
            position: "relative",
            padding: "0 32px",
            width: "calc(100% - 64px)",
            minHeight: "100px",
            [theme.breakpoints.up("sm")]: {
                padding: "0 64px",
                width: "calc(100% - 128px)",
                height: "131px",
            },
            display: "flex",
            alignItems: "center",
            top: "0",
        },
        closeIcon: {
            width: "48px",
            height:"48px",
        },
        goBackButton: {
            cursor: "pointer",
            fontSize: "bold",
            "& svg": {
                marginTop: "4px",
                width: "14px",
                height: "14px",
                marginRight: "8px",
                transform: "rotate(180deg)"
            },
        },

    }
};


function ModalNavbar(props) {
    const {children, classes, onGoBack, onClose} = props;
    return (
        <>
            <Box display={"flex"} className={classes.goBackButton} onClick={onGoBack}>
                {
                    onGoBack ?
                        <>
                            <NarrowArrowIcon color={"error"}/>
                            <Typography semiBold variant={"body1"} color={"error"}>
                                <T defaultVal={"Indietro"}>global.actions.go_back</T>
                            </Typography>
                        </>
                        :
                        null
                }
            </Box>
            <Box display={"flex"} flexGrow={1}>{children}</Box>
            {
                onClose ?
                    <IconButton width={48} height={48} className={classes.closeIcon} color={"textPrimary"} onClick={onClose}>close</IconButton>
                    :
                    null
            }

        </>
    )
}


const withI18n = (Component) => {
    return (props) => (<I18nContext.Consumer>
        {(i18n) => {
            return <Component i18n={i18n} {...props}/>
        }}
    </I18nContext.Consumer>)
}

//<TButton>TButton</TButton>


class Navbar extends Component {
    static contextType = AuthContext
    links = ["/chat", "/groups", "/wallet"]

    constructor(props) {
        super(props);
        this.state = {
            menuOpened: false,
            tEditMode:  this.props.i18n.editMode,
            isSyncing:  false
        }
    }

    toggleTranslationEditMode(value) {
        const newValue = !this.state.tEditMode
        this.setState({
            tEditMode: newValue
        }, () => {
            this.props.i18n.toggleEditMode(newValue)
        })

    }

    syncTranslations() {
        if (this.state.isSyncing) {
            return null
        }
        this.setState({
            isSyncing: true
        }, () => {
            this.props.i18n.syncValues().then(() => {
                this.setState({isSyncing: false})
            })
        })

    }

    renderAdminMenu() {
        if (!this.context.isAdmin()) {
            return null
        }
        const {classes} = this.props;
        return [
            <Divider key="adminmenu-divider" className={classes.menuDivider}/>,
            <MenuItem key="edit-mode"
                      className={clsx(classes.menuItem, {[classes.menuError]: this.state.tEditMode})}
                      component={"div"}
                      onClick={() => this.toggleTranslationEditMode(!this.state.tEditMode)}>
                {this.state.tEditMode ? <ExitIcon color="error"/> : <LanguageIcon color="textPrimary"/>}
                {this.state.tEditMode ? 'Disattiva edit mode' : 'Attiva edit mode'}
            </MenuItem>,
            <MenuItem key="sync-translations"
                      className={classes.menuItem}
                      component={"div"}
                      onClick={() => this.syncTranslations()}
            ><LanguageSyncIcon color={this.state.isSyncing ? 'gradient' : 'textPrimary'}/> Sincronizza traduzioni</MenuItem>,
            <MenuItem key="admin-link"
                      className={classes.menuItem}
                      component={"div"}
                      onClick={() => window.open('/admin/ui/')}
            ><CogIcon color={'textPrimary'}/> Admin</MenuItem>
        ]
    }

    renderAdminDrawer() {
        if (!this.context.isAdmin()) {
            return null
        }
        const {classes} = this.props
        const exitImpersonationUrl = `/auth/impersonate/exit?redirect=${window.location.pathname}`
        return <List className={classes.list} disablePadding={true} key={'admin-drawer-container'}>
            <ListSubheader disableGutters disableSticky={true} className={classes.drawerSubtitle}>
                <Typography color={"textSecondary"} variant={"h4"}>Admin</Typography>
                <Divider/>
            </ListSubheader>

            <ListItem className={classes.listItem} key="sync-translations" component={"div"} disablePadding={true} onClick={() => window.open('/admin/ui/')}>
                <ListItemIcon  className={classes.listItemIcon}><CogIcon color={'textPrimary'}/></ListItemIcon>
                <ListItemText>Admin</ListItemText>
            </ListItem>
            <ListItem className={classes.listItem} key="edit-mode" component={"div"} disablePadding={true} onClick={() => this.toggleTranslationEditMode(!this.state.tEditMode)}>
                <ListItemIcon className={classes.listItemIcon}>
                    {this.state.tEditMode ? <ExitIcon classes={{root: classes.textIcon}} color="error"/> : <LanguageIcon classes={{root: classes.textIcon}} color="textPrimary"/>}
                </ListItemIcon>
                <ListItemText style={{color: this.state.tEditMode ? "#FF5485" : null}}>
                    {this.state.tEditMode ? 'Disattiva edit mode' : 'Attiva edit mode'}
                </ListItemText>
            </ListItem>
            <ListItem className={classes.listItem} key="sync-translations" component={"div"} disablePadding={true} onClick={() => this.syncTranslations()}>
                <ListItemIcon className={classes.listItemIcon}><LanguageSyncIcon classes={{root: classes.textIcon}} color={this.state.isSyncing ? 'gradient' : 'textPrimary'}/></ListItemIcon>
                <ListItemText>Sincronizza traduzioni</ListItemText>
            </ListItem>
        </List>
    }


    renderChildren() {
        const {
            classes,
            logo,
            isMobile,
            children = <AppLogo/>,
            title,
            T, multiline , useMarkdown, useTemplate, args,
        } = this.props;
        const content = title || children
        if (typeof content === "string") {
            if (isMobile) {
                return (
                    <NavTitle
                        key={content}
                        T={T} multitline={multiline} useMarkdown={useMarkdown} useTemplate={useTemplate} args={args}
                        avatar={logo}>
                        {content}
                    </NavTitle>
                )
            }
            return <AppLogo/>
        }
        if (logo && typeof logo === "boolean") {
            return <AppLogo/>
        }
        return content
    }

    render() {
        const positionCls = `position${capitalize(this.props.position || 'default')}`;
        return <AppBar classes={{root: this.props.classes.root}} className={clsx(this.props.classes[positionCls])} color={'transparent'}
                       position={this.props.position || "relative"}
                       elevation={0}>
            <Toolbar disableGutters={true} className={this.props.classes.toolbar}>
                {
                    this.props.modal ?
                        <ModalNavbar onGoBack={this.props.onGoBack} onClose={this.props.onClose} classes={this.props.classes}/>
                        :
                        <>
                            <Box display={"flex"} alignItems={"center"} flexGrow={1}>
                                { this.renderChildren() }
                            </Box>
                            <Hidden xsDown>
                                <Menu>
                                    { this.renderAdminMenu() }
                                </Menu>
                            </Hidden>
                            <Hidden smUp>
                                <Drawer>
                                    { this.renderAdminDrawer() }
                                </Drawer>
                            </Hidden>
                        </>

                }
            </Toolbar>
        </AppBar>
    }
}

const withWidth = (Component) => {
    return function (props) {
        const theme = useTheme()
        const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
        return (
            <Component isMobile={isMobile} {...props}/>
        )
    }
}

export default withStyles(styles, {useTheme: true})(withI18n(withWidth(withRouter(Navbar))));
