/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';
import {WebapiprotoIdDocument} from './WebapiprotoIdDocument';

/**
 * The WebapiprotoPayoutProfile model module.
 * @module model/WebapiprotoPayoutProfile
 * @version v0.4
 */
export class WebapiprotoPayoutProfile {
  /**
   * Constructs a new <code>WebapiprotoPayoutProfile</code>.
   * @alias module:model/WebapiprotoPayoutProfile
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>WebapiprotoPayoutProfile</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/WebapiprotoPayoutProfile} obj Optional instance to populate.
   * @return {module:model/WebapiprotoPayoutProfile} The populated <code>WebapiprotoPayoutProfile</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new WebapiprotoPayoutProfile();
      if (data.hasOwnProperty('uuid'))
        obj.uuid = ApiClient.convertToType(data['uuid'], 'String');
      if (data.hasOwnProperty('user_uuid'))
        obj.userUuid = ApiClient.convertToType(data['user_uuid'], 'String');
      if (data.hasOwnProperty('state'))
        obj.state = ApiClient.convertToType(data['state'], 'String');
      if (data.hasOwnProperty('first_name'))
        obj.firstName = ApiClient.convertToType(data['first_name'], 'String');
      if (data.hasOwnProperty('last_name'))
        obj.lastName = ApiClient.convertToType(data['last_name'], 'String');
      if (data.hasOwnProperty('address'))
        obj.address = ApiClient.convertToType(data['address'], 'String');
      if (data.hasOwnProperty('city'))
        obj.city = ApiClient.convertToType(data['city'], 'String');
      if (data.hasOwnProperty('zip_code'))
        obj.zipCode = ApiClient.convertToType(data['zip_code'], 'String');
      if (data.hasOwnProperty('province'))
        obj.province = ApiClient.convertToType(data['province'], 'String');
      if (data.hasOwnProperty('country'))
        obj.country = ApiClient.convertToType(data['country'], 'String');
      if (data.hasOwnProperty('cf'))
        obj.cf = ApiClient.convertToType(data['cf'], 'String');
      if (data.hasOwnProperty('birthday'))
        obj.birthday = ApiClient.convertToType(data['birthday'], 'Date');
      if (data.hasOwnProperty('phone'))
        obj.phone = ApiClient.convertToType(data['phone'], 'String');
      if (data.hasOwnProperty('vat_number'))
        obj.vatNumber = ApiClient.convertToType(data['vat_number'], 'String');
      if (data.hasOwnProperty('iban'))
        obj.iban = ApiClient.convertToType(data['iban'], 'String');
      if (data.hasOwnProperty('payouts_enabled'))
        obj.payoutsEnabled = ApiClient.convertToType(data['payouts_enabled'], 'Boolean');
      if (data.hasOwnProperty('documents'))
        obj.documents = ApiClient.convertToType(data['documents'], [WebapiprotoIdDocument]);
      if (data.hasOwnProperty('email'))
        obj.email = ApiClient.convertToType(data['email'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} uuid
 */
WebapiprotoPayoutProfile.prototype.uuid = undefined;

/**
 * @member {String} userUuid
 */
WebapiprotoPayoutProfile.prototype.userUuid = undefined;

/**
 * @member {String} state
 */
WebapiprotoPayoutProfile.prototype.state = undefined;

/**
 * @member {String} firstName
 */
WebapiprotoPayoutProfile.prototype.firstName = undefined;

/**
 * @member {String} lastName
 */
WebapiprotoPayoutProfile.prototype.lastName = undefined;

/**
 * @member {String} address
 */
WebapiprotoPayoutProfile.prototype.address = undefined;

/**
 * @member {String} city
 */
WebapiprotoPayoutProfile.prototype.city = undefined;

/**
 * @member {String} zipCode
 */
WebapiprotoPayoutProfile.prototype.zipCode = undefined;

/**
 * @member {String} province
 */
WebapiprotoPayoutProfile.prototype.province = undefined;

/**
 * @member {String} country
 */
WebapiprotoPayoutProfile.prototype.country = undefined;

/**
 * @member {String} cf
 */
WebapiprotoPayoutProfile.prototype.cf = undefined;

/**
 * @member {Date} birthday
 */
WebapiprotoPayoutProfile.prototype.birthday = undefined;

/**
 * @member {String} phone
 */
WebapiprotoPayoutProfile.prototype.phone = undefined;

/**
 * @member {String} vatNumber
 */
WebapiprotoPayoutProfile.prototype.vatNumber = undefined;

/**
 * @member {String} iban
 */
WebapiprotoPayoutProfile.prototype.iban = undefined;

/**
 * @member {Boolean} payoutsEnabled
 */
WebapiprotoPayoutProfile.prototype.payoutsEnabled = undefined;

/**
 * @member {Array.<module:model/WebapiprotoIdDocument>} documents
 */
WebapiprotoPayoutProfile.prototype.documents = undefined;

/**
 * @member {String} email
 */
WebapiprotoPayoutProfile.prototype.email = undefined;


