import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {I18nContext, T, withI18n} from "@ticketag/i18nlib";
import Progress from "@ticketag/ticketag-uilib/src/components/base/Progress/Progress";
import {withApi} from "../../hoc/Api/Provider";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Tabs from "@ticketag/ticketag-uilib/src/components/base/Tabs/Tabs";
import Tab from "@ticketag/ticketag-uilib/src/components/base/Tabs/Tab";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import ImageCard from "@ticketag/ticketag-uilib/src/components/complex/ImageCard/ImageCard";
import { useHistory } from "react-router-dom";
import AuthContext from "../../hoc/Auth/context";
import CardsContainer from "../../components/CardsContainer/CardsContainer";
import {withRedirect} from "../../components/ErrorMessage/ErrorMessage";
import {Skeleton} from "@material-ui/lab";
import {
    CheckCircleIcon,
    AlertIcon,
    NarrowArrowIcon,
    PlusIcon, SelectArrowIcon
} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";
import TextButton from "@ticketag/ticketag-uilib/src/components/base/Button/TextButton";
import Header from "@ticketag/ticketag-uilib/src/components/base/Header/Header";
import AvatarIcon from "@ticketag/ticketag-uilib/src/components/base/Avatar/AvatarIcon";
import ChipCard from "@ticketag/ticketag-uilib/src/components/complex/ChipCard/ChipCard";
import ListItem from "@ticketag/ticketag-uilib/src/components/base/ListItem/ListItem";
import Hidden from "@material-ui/core/Hidden";
import clsx from "clsx";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {Badge, Menu, MenuItem} from "@material-ui/core";
import Layout from "../../hoc/Layout/Layout";
import Navbar from "../../components/Navbar/Navbar";
import NavTitle from "../../components/Navbar/NavTitle/NavTitle";
import Title from "../../components/Title/Title";
import Page from "../../components/Page/Page";
import {pages} from "../../constants";


const styles = (theme) => {
    return {
        root: {},
        container: {
            //marginLeft: "-32px",
        },
        tabs: {
            "& .MuiTab-root": {
                padding: "0",
                fontWeight: "400",
                fontSize: "18px",
                marginRight: "28px",
                letterSpacing: "0",
                minHeight: "30px",
                [theme.breakpoints.down('sm')]: {
                    marginRight: "18px",
                    marginLeft: "0px",
                }
            },
            "& .MuiTab-root:first-child": {
                marginLeft: "0",
            },
            "& .MuiTab-root.Mui-selected": {
                fontWeight: "500",
            },
        },
        card: {
            width: "calc(100% - 32px)",
        },
        cardTitle: {
            textTransform: "capitalize"
        },
        cardSubtitle: {
            fontSize: "14px",
            fontWeight: "600",
            letterSpacing: "0.01em",
            lineHeight: "24px",
            textTransform: "uppercase"
        },
        avatarIcon: {
            width: "65px",
            height: "65px"
        },
        arrowIcon: {
            width: "16px",
            height: "16px"
        },
        skeleton: {
            borderRadius: "16px",
        },
        emptyGroups: {
            width: "320px",
            height: "80px",
            backgroundColor: "#3D3582",
            cursor: "pointer",
            borderRadius: "16px",
            boxShadow: "-10px 10px 24px rgba(172, 172, 172, 0.08), 10px -10px 24px rgba(172, 172, 172, 0.08), 10px 10px 24px rgba(172, 172, 172, 0.08)",
            "& h5": {
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "20px"
            },
            "& svg": {
                marginTop: "6px",
                marginLeft: "12px",
                width: "16px",
                height: "16px",
            },
        },
        actionCard: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "16px",
            boxShadow: "-10px 10px 24px rgba(172, 172, 172, 0.08), 10px -10px 24px rgba(172, 172, 172, 0.08), 10px 10px 24px rgba(172, 172, 172, 0.08)",
            maxWidth: "300px",
            height: "100px",
            cursor: "pointer",
            "&$hover": {
                transition: "box-shadow 0.3s ease-out, transform 0.3s ease-out",
                boxShadow: theme.shadows[6],
            },
            "&$clicked": {
                transition: "box-shadow 0.1s ease-out, transform 0.1s ease-out !important",
                transform: "scale(0.98)",
                boxShadow: theme.shadows[7] + "!important",
            },
            [theme.breakpoints.down("sm")]: {
                flexDirection: "column"
            },
            "& h3": {
                fontSize: "18px",
                fontWeight: "600",
                lineHeight: "22px",
                letterSpacing: "normal",
                [theme.breakpoints.down("sm")]: {
                    fontSize: "16px",
                    textAlign: "center"
                }
            },
            "& svg": {
                width: "20px",
                height: "20px",
                padding: "8px",
            }
        },
        createGroupCard: {
            background: "linear-gradient(316.28deg, rgba(255, 255, 255, 0.2) 15.07%, rgba(255, 255, 255, 0) 93.35%), #6E3790",
            "& svg": {
                //marginLeft: "-20px"
            }
        },
        joinGroupCard: {
            background: "linear-gradient(316.28deg, rgba(255, 255, 255, 0.2) 15.07%, rgba(255, 255, 255, 0) 93.35%), #191446",
            "& svg": {
                marginBottom: "-4px"
            },
            [theme.breakpoints.down("sm")]: {
                flexDirection: "column-reverse"
            },
        },
        yourGroupsTitleContainer: {
            paddingBottom: theme.spacing(4),
            paddingTop: theme.spacing(6),
            [theme.breakpoints.up("sm")]: {
                paddingTop: theme.spacing(10)
            }
        },
        yourGroupsTitle: {
            [theme.breakpoints.down("sm")]: {
                ...theme.overrides.MuiTypography.h5
            }
        },
        selectContainer: {
            paddingLeft: "16px",
            cursor: "pointer"
        },
        selectArrow: {
            marginTop: "4px",
            marginLeft: "4px",
            width: "28px",
            height: "28px"

        },
        selectMenuPaper: {
            minWidth: "240px"
        },
        selectMenuItem: {
            fontSize: "18px"
        },
    }
};

const defaultSubtitle = "Per vedere i dettagli di un gruppo già creato cliccaci sopra. Per vedere i dettagli di un gruppo già creato cliccaci sopra. Per vedere i dettagli di un gruppo già creato cliccaci sopra. Per vedere i dettagli di un gruppo già creato cliccaci sopra."
const defaultEmptySubtitle = "Qui verranno mostrati i gruppi a cui partecipi. Al momento non prtecipi a nessun gruppo. Condividi o ottieni un servizio per cominciare a utilizzare l'applicazione."



function Select(props) {
    const {classes, options, onChange, color, ...other} = props;
    const [value, setValue] = React.useState(0)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = (event) => {
        setAnchorEl(null);
    }
    const handleSelect = (index) => () => {
        setAnchorEl(false)
        setValue(index)
        onChange(index)
    }
    const selectedOption = options[value]
    return (
        <Box>
            <Box  height={22} display={"flex"} alignItems={"center"} className={classes.selectContainer} onClick={handleOpen}>
                <Typography key={selectedOption.key} className={classes.selectTitle} variant={"h5"} color={color}>
                    <T defaultVal={selectedOption.key}>{`${selectedOption.title}`}</T>
                </Typography>
                <SelectArrowIcon className={classes.selectArrow} color={color}/>
            </Box>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                classes={{paper: classes.selectMenuPaper}}
            >
                { options.map((elt, index) => (
                    <MenuItem
                        classes={{root: classes.selectMenuItem}}
                        key={index} onClick={handleSelect(index)}>
                        <T defaultVal={elt.key}>{elt.title}</T>
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    )

}

class Groups extends Component {
    static contextType = I18nContext

    constructor(props) {
        super(props);
        this.tabFilters = [(g) => g.isOwned || g.isJoined, (g) => g.isOwned, (g) => g.isJoined]
        this.state = {
            isLoaded: false,
            selectedTab: 0,
            tabFilter: this.tabFilters[0],
            serviceFamilies: null,
            ownedFamilies: [],
            joinedFamilies: [],
        }

        //
    }

    componentDidMount() {
        withRedirect(this.props.history, Promise.all([this.loadServiceFamilies(true), this.loadGroups()]).then(([serviceFamilies, groups]) => {
            const ownedFamilies =  Array.from(new Set(groups.owned.map(g => g.serviceFamilyUuid)))
            const joinedFamilies =  Array.from(new Set(groups.joined.map(g => g.serviceFamilyUuid)))
            const allGroups = groups.owned.concat(groups.joined)
            serviceFamilies = serviceFamilies.map(f => {
                f.isOwned = ownedFamilies.indexOf(f.uuid) !== -1
                f.isJoined = joinedFamilies.indexOf(f.uuid) !== -1
                const group = allGroups.find(g => g.serviceFamilyUuid === f.uuid)
                if (group) {
                    if (f.isOwned && group.state==='draft') {
                        f.url = this.context.buildLocalizedUrl('groups', group.uuid, 'create', 'visibility')
                    } else {
                        f.url = this.context.buildLocalizedUrl('groups', group.uuid, 'details')
                    }
                }
                f.group = group
                return f
            })

            const mapServiceFamily = (g) => {g.serviceFamily = serviceFamilies.find(f => f.uuid === g.serviceFamilyUuid); return g}

            this.setState({
                isLoaded: true,
                serviceFamilies: serviceFamilies,
                ownedFamilies: ownedFamilies,
                joinedFamilies: joinedFamilies,
                ownedGroups: groups.owned.map(mapServiceFamily),
                joinedGroups: groups.joined.map(mapServiceFamily)
            })
        }))
    }

    onTabSelected(newValue) {
        this.setState({selectedTab: newValue, tabFilter: this.tabFilters[newValue]})
    }

    loadServiceFamilies(noCache) {
        return this.props.api.GetServiceFamilies(noCache)
    }

    loadGroups() {
        return this.props.api.userApi.getUserGroups()
    }


    goToSuggestions() {
        this.props.history.push(this.context.buildLocalizedUrl('suggestions'))
    }

    renderTab() {
        const families = this.state.serviceFamilies.filter(this.tabFilters[this.state.selectedTab])
        const isIncomplete = (group) => group.state === "draft"
        console.log(families)
        return families.map(f =>
            <Grid key={f.uuid} xs={12} md={6} lg={4} item>
                <ChipCard
                    mr={{xs: 0, md: 1}}
                    classes={{root: this.props.classes.card}}
                    onClick={() => {
                        this.props.history.push(f.url || this.context.localizedUrl(`/${f.name.toLowerCase()}`))
                    }}
                >
                    <Box display={"flex"} alignItems={"center"} flexDirection={"row-reverse"}>
                        <NarrowArrowIcon className={this.props.classes.arrowIcon} color={isIncomplete(f.group) ? "disabled" : "secondary"}/>
                        <Box flexGrow={1}>
                            <ListItem prepend={<img className={this.props.classes.avatarIcon} src={f.logo}/>}>
                                <Box display={"flex"} alignItems={"center"}>
                                    <Box display={"flex"} justifyContent={"center"} flexDirection={"column"} flexGrow={1}>
                                        <Typography variant="h4" className={this.props.classes.cardTitle}
                                                    color={"textSecondary"}>{f.name.toLowerCase()}</Typography>
                                        <Typography color={isIncomplete(f.group) ? "disabled" : f.isOwned ? "gradient" : "accent"}
                                                    className={this.props.classes.cardSubtitle}
                                                    variant="caption">
                                            {
                                                isIncomplete(f.group) ?
                                                    <T defaultVal={"Incompleto"}>pages.groups.incomplete_group</T> :
                                                    (

                                                        f.isOwned ? <T defaultVal={"Sei l'admin"}>pages.dashboard.you_are_admin</T> :
                                                            f.isJoined ? <T defaultVal={"Sei joiner"}>pages.dashboard.you_are_joiner</T> :
                                                                ""
                                                    )
                                            }
                                        </Typography>
                                    </Box>
                                    {
                                        f.group.banners > 0 ?
                                            <Badge badgeContent={1} color={"error"} style={{marginRight: "24px"}}/>
                                            : null
                                    }
                                </Box>
                            </ListItem>
                        </Box>
                    </Box>
                </ChipCard>
            </Grid>
        )
    }

    renderContent() {
        if (!this.state.isLoaded) {
            return <Grid container justify={"center"}>
                <Grid item xs={12}>
                    <Grid item container spacing={this.props.isMobile ? 2: 5}>
                        <Grid item xs={6} md={4}>
                            <Skeleton style={{height: "100px", maxHeight: "300px"}} variant={"rect"}/>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Skeleton style={{height: "100px", maxHeight: "300px"}} variant={"rect"}/>
                        </Grid>
                    </Grid>
                    <Box className={this.props.classes.yourGroupsTitleContainer} display={"flex"}>
                        <Typography className={this.props.classes.yourGroupsTitle} variant={"h3"}><Skeleton width={120}/></Typography>
                        <Hidden smUp>
                            <Skeleton/>
                        </Hidden>
                    </Box>
                    <Box pb={{xs: 4, md: 6}}>
                        <Typography color={'textPrimary'} variant={'body2'}><Skeleton height={56} variant="rect"/></Typography>
                    </Box>
                    <Box pb={5}>
                        <Tabs value={false}>
                            <Tab disabled label={<Skeleton width={80}/>}/>
                            <Tab disabled label={<Skeleton width={80}/>}/>
                            <Tab disabled label={<Skeleton width={80}/>}/>
                        </Tabs>
                    </Box>
                    <CardsContainer justifyContent="flex-start" spacing={4}>
                        <Grid item xs={12} md={6} lg={4}>
                            <Skeleton variant={"rect"} width={"100%"} height={96} className={this.props.classes.skeleton}/>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Skeleton variant={"rect"} width={"100%"} height={96} className={this.props.classes.skeleton}/>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Skeleton variant={"rect"} width={"100%"} height={96} className={this.props.classes.skeleton}/>
                        </Grid>
                    </CardsContainer>
                    <Box py={10}/>
                </Grid>
            </Grid>
        }
        const hasGroups = this.state.joinedGroups.length > 0 || this.state.ownedGroups.length > 0
        return <Grid container justify={"center"}>
            <Grid item xs={12} key="groups">
                <Grid item container spacing={this.props.isMobile ? 2: 5}>
                    {hasGroups ?
                        null :
                        <Grid item>
                            <Box pb={{xs: 4, md: 6}}>
                                <Typography color={"textPrimary"} variant={"body2"}><T defaultVal={defaultEmptySubtitle}>pages.dashboard.empty_subtitle</T></Typography>
                            </Box>
                        </Grid>
                    }
                    <Grid item xs={6} md={4}>
                        <Box className={clsx(this.props.classes.actionCard, this.props.classes.joinGroupCard)}
                             onClick={() => this.props.history.push(this.props.i18n.buildLocalizedUrl("services"))}
                        >
                            <Typography  variant={"h3"} color={"white"}><T defaultVal={"Partecipa a un gruppo"}>pages.dashboard.join_group_card_title</T></Typography>
                            <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.8321 8.70711C14.2226 8.31658 14.2226 7.68342 13.8321 7.29289L7.46815 0.928932C7.07762 0.538408 6.44446 0.538408 6.05393 0.928932C5.66341 1.31946 5.66341 1.95262 6.05393 2.34315L11.7108 8L6.05393 13.6569C5.66341 14.0474 5.66341 14.6805 6.05393 15.0711C6.44446 15.4616 7.07762 15.4616 7.46815 15.0711L13.8321 8.70711ZM1.75 9L13.125 9V7L1.75 7L1.75 9Z" fill="#FCFCFC"/>
                                <rect x="0.4375" y="4.4375" width="0.875" height="7.125" rx="0.4375" fill="#FCFCFC" stroke="#FCFCFC" stroke-width="0.875"/>
                            </svg>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Box className={clsx(this.props.classes.actionCard, this.props.classes.createGroupCard)}
                             onClick={() => this.props.history.push(this.props.i18n.buildLocalizedUrl("groups", "create"))}
                        >
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.8784 9.24243C13.2968 9.24243 13.636 9.5816 13.636 10C13.636 10.4184 13.2968 10.7576 12.8784 10.7576H10.7302V12.9058C10.7302 13.3093 10.4031 13.6364 9.99964 13.6364C9.59619 13.6364 9.26912 13.3093 9.26912 12.9058V10.7576H7.12086C6.70246 10.7576 6.36328 10.4184 6.36328 10C6.36328 9.5816 6.70246 9.24243 7.12086 9.24243H9.26912V7.10019C9.26912 6.69341 9.59889 6.36364 10.0057 6.36364C10.4141 6.36364 10.7445 6.69594 10.7422 7.10435L10.7302 9.24243H12.8784Z" fill="white"/>
                                <circle cx="10" cy="10" r="9.25" stroke="#FCFCFC" stroke-width="1.5"/>
                            </svg>
                            <Typography  variant={"h3"} color={"white"}><T defaultVal={"Crea un gruppo"}>pages.dashboard.create_group_card_title</T></Typography>
                        </Box>
                    </Grid>
                </Grid>
                { hasGroups ?
                    <Box className={this.props.classes.yourGroupsTitleContainer} display={"flex"}>
                        <Typography className={this.props.classes.yourGroupsTitle} variant={"h3"}><T defaultVal={"I tuoi gruppi"}>pages.dashboard.your_groups_title</T></Typography>
                        <Hidden smUp>
                            <Select color={"gradient"} classes={this.props.classes}
                                    options={[
                                        {key: "pages.dashboard.select_all_groups", title: "Tutti"},
                                        {key: "pages.dashboard.tab_my_groups", title: "Creati da me"},
                                        {key: "pages.dashboard.tab_subscribed_groups", title: "A cui partecipo"},
                                    ]} onChange={(val) => this.onTabSelected(val)}/>
                        </Hidden>
                    </Box>
                    : null
                }
                <Box pb={{xs: 4, md: 6}}>
                    {
                        hasGroups ?
                            <Typography color={"textPrimary"} variant={"body2"}><T defaultVal={defaultSubtitle}>pages.dashboard.subtitle</T></Typography> :
                            null
                    }
                </Box>
                { hasGroups ?
                    <>
                        <Hidden xsDown>
                            <Box pb={5}>
                                <Tabs
                                    className={this.props.classes.tabs}
                                    value={this.state.selectedTab}
                                    onChange={(e, newValue) => {
                                        this.onTabSelected(newValue)
                                    }}>
                                    <Tab classes={{root: this.props.classes.tab}}
                                         label={<T defaultVal={'Tutti'}>pages.dashboard.tab_all_groups</T>}/>
                                    <Tab classes={{root: this.props.classes.tab}}
                                         disabled={this.state.ownedFamilies.length === 0}
                                         label={<T defaultVal={'Creati da me'}>pages.dashboard.tab_my_groups</T>}/>
                                    <Tab classes={{root: this.props.classes.tab}}
                                         disabled={this.state.joinedFamilies.length === 0}
                                         label={<T defaultVal={'A cui partecipo'}>pages.dashboard.tab_subscribed_groups</T>}/>
                                </Tabs>
                            </Box>
                        </Hidden>
                        <CardsContainer justifyContent="flex-start" spacing={4}>
                            {this.renderTab()}
                        </CardsContainer>
                    </>
                    : null
                }
                <Box mt={8} mb={{xs: 10, md: 16}}>
                </Box>
            </Grid>
        </Grid>
    }

    render() {
        const {
            title,
            ...other
        } = this.props;
        const isLoading = !this.state.isLoaded
        return (
            <Page {...other}>
                    <Hidden xsDown>
                        <Title isLoading={isLoading}>{title}</Title>
                    </Hidden>
                    {
                        this.renderContent()
                    }
            </Page>
        )
    }

}


const withWidth = (Component) => {
    return function (props) {
        const theme = useTheme()
        const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
        return (
            <Component isMobile={isMobile} {...props}/>
        )
    }
}

export default withStyles(styles, {useTheme: true})(withWidth(withI18n(withApi(Groups))));
