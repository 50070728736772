/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';
import {WebapiprotoBanner} from './WebapiprotoBanner';
import {WebapiprotoGroupMember} from './WebapiprotoGroupMember';
import {WebapiprotoServiceDetail} from './WebapiprotoServiceDetail';

/**
 * The WebapiprotoGroupDetail model module.
 * @module model/WebapiprotoGroupDetail
 * @version v0.4
 */
export class WebapiprotoGroupDetail {
  /**
   * Constructs a new <code>WebapiprotoGroupDetail</code>.
   * @alias module:model/WebapiprotoGroupDetail
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>WebapiprotoGroupDetail</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/WebapiprotoGroupDetail} obj Optional instance to populate.
   * @return {module:model/WebapiprotoGroupDetail} The populated <code>WebapiprotoGroupDetail</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new WebapiprotoGroupDetail();
      if (data.hasOwnProperty('uuid'))
        obj.uuid = ApiClient.convertToType(data['uuid'], 'String');
      if (data.hasOwnProperty('size'))
        obj.size = ApiClient.convertToType(data['size'], 'Number');
      if (data.hasOwnProperty('scope'))
        obj.scope = ApiClient.convertToType(data['scope'], 'String');
      if (data.hasOwnProperty('service_name'))
        obj.serviceName = ApiClient.convertToType(data['service_name'], 'String');
      if (data.hasOwnProperty('schema'))
        obj.schema = ApiClient.convertToType(data['schema'], 'Blob');
      if (data.hasOwnProperty('is_admin'))
        obj.isAdmin = ApiClient.convertToType(data['is_admin'], 'Boolean');
      if (data.hasOwnProperty('amount'))
        obj.amount = ApiClient.convertToType(data['amount'], 'Number');
      if (data.hasOwnProperty('members'))
        obj.members = ApiClient.convertToType(data['members'], [WebapiprotoGroupMember]);
      if (data.hasOwnProperty('service_family_uuid'))
        obj.serviceFamilyUuid = ApiClient.convertToType(data['service_family_uuid'], 'String');
      if (data.hasOwnProperty('start_date'))
        obj.startDate = ApiClient.convertToType(data['start_date'], 'Date');
      if (data.hasOwnProperty('current_period_end'))
        obj.currentPeriodEnd = ApiClient.convertToType(data['current_period_end'], 'Date');
      if (data.hasOwnProperty('service_details'))
        obj.serviceDetails = WebapiprotoServiceDetail.constructFromObject(data['service_details']);
      if (data.hasOwnProperty('banners'))
        obj.banners = ApiClient.convertToType(data['banners'], [WebapiprotoBanner]);
      if (data.hasOwnProperty('state'))
        obj.state = ApiClient.convertToType(data['state'], 'String');
      if (data.hasOwnProperty('closing_date'))
        obj.closingDate = ApiClient.convertToType(data['closing_date'], 'Date');
      if (data.hasOwnProperty('has_credentials'))
        obj.hasCredentials = ApiClient.convertToType(data['has_credentials'], 'Boolean');
      if (data.hasOwnProperty('needs_credentials'))
        obj.needsCredentials = ApiClient.convertToType(data['needs_credentials'], 'Boolean');
    }
    return obj;
  }
}

/**
 * @member {String} uuid
 */
WebapiprotoGroupDetail.prototype.uuid = undefined;

/**
 * @member {Number} size
 */
WebapiprotoGroupDetail.prototype.size = undefined;

/**
 * @member {String} scope
 */
WebapiprotoGroupDetail.prototype.scope = undefined;

/**
 * @member {String} serviceName
 */
WebapiprotoGroupDetail.prototype.serviceName = undefined;

/**
 * @member {Blob} schema
 */
WebapiprotoGroupDetail.prototype.schema = undefined;

/**
 * @member {Boolean} isAdmin
 */
WebapiprotoGroupDetail.prototype.isAdmin = undefined;

/**
 * @member {Number} amount
 */
WebapiprotoGroupDetail.prototype.amount = undefined;

/**
 * @member {Array.<module:model/WebapiprotoGroupMember>} members
 */
WebapiprotoGroupDetail.prototype.members = undefined;

/**
 * @member {String} serviceFamilyUuid
 */
WebapiprotoGroupDetail.prototype.serviceFamilyUuid = undefined;

/**
 * @member {Date} startDate
 */
WebapiprotoGroupDetail.prototype.startDate = undefined;

/**
 * @member {Date} currentPeriodEnd
 */
WebapiprotoGroupDetail.prototype.currentPeriodEnd = undefined;

/**
 * @member {module:model/WebapiprotoServiceDetail} serviceDetails
 */
WebapiprotoGroupDetail.prototype.serviceDetails = undefined;

/**
 * @member {Array.<module:model/WebapiprotoBanner>} banners
 */
WebapiprotoGroupDetail.prototype.banners = undefined;

/**
 * @member {String} state
 */
WebapiprotoGroupDetail.prototype.state = undefined;

/**
 * @member {Date} closingDate
 */
WebapiprotoGroupDetail.prototype.closingDate = undefined;

/**
 * @member {Boolean} hasCredentials
 */
WebapiprotoGroupDetail.prototype.hasCredentials = undefined;

/**
 * @member {Boolean} needsCredentials
 */
WebapiprotoGroupDetail.prototype.needsCredentials = undefined;


