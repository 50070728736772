import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T, withI18n} from "@ticketag/i18nlib";
import Progress from "@ticketag/ticketag-uilib/src/components/base/Progress/Progress";
import {withApi} from "../../hoc/Api/Provider";
import AuthContext from "../../hoc/Auth/context";
import Grid from "@material-ui/core/Grid";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import Box from "@material-ui/core/Box";
import Button from "@ticketag/ticketag-uilib/src/components/base/Button/Button";
import {withRedirect} from "../../components/ErrorMessage/ErrorMessage";
import Layout from "../../hoc/Layout/Layout";
import Page from "../../components/Page/Page";
import {withGlobalLoader} from "../../hoc/GlobalLoader/Provider";
import {pages} from "../../constants";


const defaultSubtitleText = 'Complimenti, il tuo ordine è stato processato correttamente. Puoi trovare il servizio acquistato nel tuo profilo oppure effettuare un altro acquisto.'

const MAX_RELOAD_COUNT = 3

const styles = (theme) => {
    return {
        root: {
            height: '45vh',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column'
        },
        title: {
            textAlign: 'center',
            '& strong': {
                fontWeight: '600'
            }
        },
        subtitle: {
            textAlign: 'center',
        }
    }
};

class JoinGroupResult extends Component {

    static contextType = AuthContext

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            joinRequestUuid: null,
            result: null
        }
        this._isMounted=false
        this.timeoutHandler = null
        this.reloadCount = 0
    }
    componentDidMount() {
        const location = this.props.history.location
        this.joinRequestUuid = (new URLSearchParams(location.search)).get("join_request_uuid")
        this._isMounted=true
        this.reloadCount = 0
        this.props.loader.Hide()
        this.setState({
            joinRequestUuid: this.joinRequestUuid,
            result: this.props.result
        })
        const listenerId = this.props.api.listenEvent('user.subscription_setup_completed', this.joinRequestUuid, (evt) => {
            if (!this._isMounted) {
                this.props.api.removeListener(listenerId)
            }
            this.props.history.push(this.props.i18n.buildLocalizedUrl('groups', this.props.match.params.groupUuid, 'join', 'success'))
        }, false)
        this.loadData()
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.result === 'processing' && prevState.result !== this.props.result) {
            this.loadData()
        }
    }

    componentWillUnmount() {
        this._isMounted=false
    }

    getTitle() {
        if (this.props.result==='success') {
            return <T capitalize useMarkdown defaultVal="Checkout **completato!**">pages.join_group_result.checkout_completed</T>
        }
        return <T capitalize useMarkdown defaultVal="Checkout fallito :(">pages.join_group_result.checkout_failed</T>
    }
    getSubtitle() {
        if (this.props.result==='success') {
            return <T capitalize useMarkdown defaultVal={defaultSubtitleText}>pages.join_group_result.checkout_completed_subtitle</T>
        }
        return <T capitalize useMarkdown defaultVal="Checkout fallito :(">pages.join_group_result.checkout_failed</T>
    }
    getAction() {
        if (this.props.result==='success') {
            return <Button onClick={() => this.goToGroup()} variant="outlined" fixedWidth><T defaultVal="vai al gruppo">pages.join_group_result.go_to_group</T></Button>
        }
        return <Button onClick={() => this.goBack()} variant="outlined" error fixedWidth><T defaultVal="indietro">global.actions.back</T></Button>
    }


    goToGroup() {
        this.props.history.push(this.props.i18n.buildLocalizedUrl('/groups', this.state.joinRequest.groupUuid, 'details'))
    }

    goBack() {
        this.props.history.push(this.props.i18n.buildLocalizedUrl('groups', this.props.match.params.groupUuid, 'join', 'payment'))
        //this.props.history.goBack()
    }

    isSuccess(joinRequest) {
        return joinRequest.state === 'active' || joinRequest.state === 'trialing'
    }

    loadData() {
        return withRedirect(this.props.history, this.props.api.joinApi.getGroupJoinRequest(this.props.match.params.groupUuid).then((data) => {
            this.setState({
                joinRequest: data,
                isLoaded: true,
                result: this.props.result
            }, () => {
                if (this.isSuccess(data)) {
                    this.props.history.push(this.props.i18n.buildLocalizedUrl('groups', this.props.match.params.groupUuid, 'join', 'success'))
                } else if (this.reloadCount < MAX_RELOAD_COUNT) {
                    this.reloadCount++
                    setTimeout(() => {
                        this.loadData()
                    }, this.props.api.defaultEventTimeout)
                } else {
                    this.props.history.push(this.props.i18n.buildLocalizedUrl('groups', this.props.match.params.groupUuid, 'join', 'fail') + `?join_request_uuid=${this.joinRequestUuid}`)
                }
            })

        }))
    }

    useGrid(content) {
        return <Grid item xs={12} md={8} lg={7} classes={{root: this.props.classes.root}}>{content}</Grid>
    }

    renderContent() {
        if (!this._isMounted) {
            return <></>
        }
        if (!this.state.isLoaded || this.state.result === 'processing') {
            return <Grid container justify={"center"}><Grid item xs={12} md={8} lg={7} classes={{root: this.props.classes.root}}>
                <Typography classes={{root: this.props.classes.title}} color="textSecondary" variant="h1">
                    <T capitalize useMarkdown defaultVal="stiamo processando il tuo **ordine**...">pages.join_group_result.in_progress_title</T>
                </Typography>
            </Grid></Grid>
        }
        return <Grid container justify={"center"}>
            <Grid item xs={12} md={8} lg={7} classes={{root: this.props.classes.root}}>
                <Box>
                    <Typography classes={{root: this.props.classes.title}} color="textSecondary" variant="h1">
                        {this.getTitle()}
                    </Typography>
                </Box>
                <Box mt={5} mb={6}>
                    <Typography classes={{root: this.props.classes.subtitle}} color="textPrimary" variant="h5">
                        {this.getSubtitle()}
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="center">
                    {this.getAction()}
                </Box>
            </Grid>
        </Grid>
    }

    render() {
        const {i18n, history} = this.props;
        const {
            seo, layout,
            ...other
        } = this.props;
        return <Page layout={layout} seo={seo}>
            {
                this.renderContent()
            }
        </Page>
    }
}

export default withStyles(styles, {useTheme: true})(withGlobalLoader(withApi(withI18n(JoinGroupResult))));
