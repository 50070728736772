import React, {Component} from 'react';
import Container from "@material-ui/core/Container";
import {withStyles} from "@material-ui/core/styles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from "clsx";


const styles = (theme) => {
    return {
        root: {},
        maxWidthXl: {
            maxWidth: 984 + theme.spacing(6)
        },
        maxWidthLg: {
            maxWidth: 984 + theme.spacing(6)
        },
        maxWidthMd: {
            maxWidth: 672 + theme.spacing(6)
        },
        maxWidthXs: {
            overflowX: 'hidden',
            overflowY: 'hidden',
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4)
        },
        fullWidth : {
            padding: 0
        },
        /*
        modalLayout: {
            [theme.breakpoints.up("sm")]: {
                height: "50vh",
                overflow: "scroll",
                paddingTop: "24px",
                paddingBottom: "24px",
                transform: "translateY(-56px)",
                width: "calc(100% + 48px)",
                margin: "0 -24px"
            }
        }
         */
    }
};

export const useWidth = (theme) => {
    const keys = [...theme.breakpoints.keys].reverse();
    return (
        keys.reduce((output, key) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const matches = useMediaQuery(theme.breakpoints.up(key));
            return !output && matches ? key : output;
        }, null) || 'xs'
    );
}

const container = ({children, classes, fullWidth, modalLayout, maxWidth, className, ...props}) => {
    const theme = useTheme()
    const width = useWidth(theme);
    const {fullWidth:fullWidthCls, ...otherClasses} = classes
    return (
        <Container classes={otherClasses} className={clsx({
            [fullWidthCls]: fullWidth,
            [classes.modalLayout]: modalLayout,
        }, className)} maxWidth={fullWidth ? null : maxWidth || width} {...props}>
            {children}
        </Container>
    )
};

export default withStyles(styles, {useTheme: true})(container);
