import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T, withI18n} from "@ticketag/i18nlib";
import Progress from "@ticketag/ticketag-uilib/src/components/base/Progress/Progress";
import {withApi} from "../../hoc/Api/Provider";
import AuthContext from "../../hoc/Auth/context";
import Box from "@material-ui/core/Box/Box";
import Grid from "@material-ui/core/Grid";
import withWidth from "@material-ui/core/withWidth";
import Button from "@ticketag/ticketag-uilib/src/components/base/Button/Button";
import {withModals} from "@ticketag/ticketag-uilib/src/components/hoc/Modals/Provider";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import Title from "../../components/Title/Title";
import TextFooter from "../../components/TextFooter/TextFooter";
import Layout from "../../hoc/Layout/Layout";
import Help from "../../components/Help/Help";
import {pages} from "../../constants";
import {withRouter} from "react-router-dom"
import Page from "../../components/Page/Page";
import {SeoDescription, SeoTitle} from "../Seo/Seo";
import Scroll from "../../components/Scroll/Scoll";
import {withRedirect} from "../../components/ErrorMessage/ErrorMessage";
import {withGlobalLoader} from "../../hoc/GlobalLoader/Provider";

const styles = (theme) => {
    return {
        root: {},
        card: {
            width: "250px",
            height: "280px",
            boxShadow: "-17px 17px 34px rgba(172, 172, 172, 0.1), 17px -17px 34px rgba(172, 172, 172, 0.1), -17px -17px 34px rgba(255, 255, 255, 0.8), 17px 17px 20px rgba(172, 172, 172, 0.25)",
            borderRadius: "16px",
        },
        avatar: {
            width: "50px",
            height: "50px",
        },
        button: {
            width: "230px",
        }
    }
};


class JoinGroup extends Component {
    static contextType = AuthContext

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
        }
    }

    componentDidMount() {
        this.loadData()
    }

    confirmGroupJoin() {
        const groupUuid = this.props.match.params.groupUuid
        withRedirect(this.props.history, this.props.confirmJoinGroup().then(data => {
                this.props.history.push(this.props.i18n.buildLocalizedUrl('groups', groupUuid, 'join', 'processing') + `?join_request_uuid=${this.props.joinRequest.uuid}`)
            }).catch((err) => {
                console.error(err)
                throw err
        }))
    }


    loadData() {
        const {joinRequest, serviceFamily} = this.props
        this.setState({
            isLoaded: true,
            joinRequest: joinRequest,
            serviceFamily: serviceFamily,
        })
    }

    renderFooter() {
        return (
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"} py={3} px={10}>
                <Button fullWidth variant={"contained"} color={"gradient"} onClick={() => this.confirmGroupJoin()}>
                    <T defaultVal={"Completa l'ordine"}>pages.join_summary.complete_order_button</T>
                </Button>
            </Box>

        )
    }
    //TODO: larghezza stepper
    renderContent() {
        if (!this.state.isLoaded) {
            return <Progress/>
        }
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} my={0.5}>
                        <Box className={this.props.classes.card}>
                            <Box p={4} height={216}>
                                <Box display={"flex"}>
                                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} mr={2}>
                                        <img src={this.state.serviceFamily.logo} className={this.props.classes.avatar}/>
                                    </Box>
                                    <Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
                                        <Typography color={"textSecondary"} style={{fontSize: "24px", fontWeight: "600", lineHeight: "28px"}}>{this.state.serviceFamily.name}</Typography>
                                        <Typography color={"textSecondary"} style={{fontSize: "16px", fontWeight: "300", lineHeight: "20px"}}>
                                            <T defaultVal={"di"}>globals.conjunctions.of</T>&nbsp;{this.state.joinRequest.ownerName}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box display={"flex"} pt={4} flexDirection={"column"}>
                                    <Typography color={"textPrimary"} style={{display: "inline-flex", width: "100%", paddingBottom: "16px", fontSize: "14px", fontWeight: "300", lineHeight: "24px" }}>
                                        <span style={{flexGrow: "1"}}><T defaultVal={"Contributo spese"}>pages.join_summary.amount</T></span>
                                        <span>€&nbsp;{ this.props.i18n.formatPrice(this.state.joinRequest.amount)}</span>
                                    </Typography>
                                    <Typography color={"textPrimary"} style={{display: "inline-flex", width: "100%", paddingBottom: "16px", fontSize: "14px", fontWeight: "300", lineHeight: "24px" }}>
                                        <span style={{flexGrow: "1"}}><T defaultVal={"Spese di gestione"}>pages.join_summary.fee</T></span>
                                        <span>€&nbsp;{ this.props.i18n.formatPrice(this.state.joinRequest.fee)}</span>
                                    </Typography>
                                    <Typography color={"textSecondary"} style={{display: "inline-flex", width: "100%", paddingTop: "16px", fontSize: "16px", fontWeight: "600", lineHeight: "28px" }}>
                                        <span style={{flexGrow: "1"}}><T defaultVal={"Totale"}>pages.join_summary.total_amount</T></span>
                                        <span>€&nbsp;{ this.props.i18n.formatPrice(this.state.joinRequest.totalAmount)}</span>
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        )
    }

    render() {
        const {history, i18n} = this.props;
        const isLoading = !this.state.isLoaded
        const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1)
        const serviceFamily = this.props.serviceFamily
        const {
            seo,
            title,
            helpTitle,
            footer,
            layout,
            ...other
        } = this.props;
        return <Page layout={layout} {...other}>
            {
                isLoading ?
                    null
                    :
                    <>
                        <SeoTitle>{seo.title}</SeoTitle>
                        <SeoDescription args={{Name: capitalize(serviceFamily.name)}}>{seo.description}</SeoDescription>
                        <Title>{title}</Title>
                        <Scroll>
                            {this.renderContent()}
                        </Scroll>
                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"} flexGrow={1}>
                            <Button className={this.props.classes.button} fullWidth variant={"contained"} color={"gradient"} onClick={() => this.confirmGroupJoin()}>
                                <T defaultVal={"Completa l'ordine"}>pages.join_summary.complete_order_button</T>
                            </Button>
                        </Box>
                        <TextFooter
                            helpTitle={helpTitle}
                            help={
                                <Help id={"summary"}>
                                    {`Riepilogo`}
                                </Help>
                            }>
                            {footer}
                        </TextFooter>
                    </>
            }
        </Page>

    }

}

export default withStyles(styles, {useTheme: true})(withRouter(withGlobalLoader(withApi(withI18n(withWidth()(withModals(JoinGroup)))))));
