import {capitalize, Dialog, Slide} from "@material-ui/core";
import React from "react";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import withStyles from "@material-ui/core/styles/withStyles";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";


const useStyles = makeStyles((theme) => {
    return {
        root: {
          transition: "max-height .3s ease",
        },
        fullScreenSm: {
            left: "0px!important",
            right: "0px!important",
            bottom: "0px!important",
            top: "auto!important",
            height: "auto"
        },
        fullScreenMd: {
            left: "0px!important",
            right: "0px!important",
            bottom: "0px!important",
            top: "auto!important",
            height: "auto"
        },
        fullScreenLg: {
            left: "0px!important",
            right: "0px!important",
            bottom: "0px!important",
            top: "auto!important",
            height: "100%",
        }
    }
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TDialog(props) {
    const {size = "lg", className, fullWidth = true,  fullScreen = false, transitionDuration = 700, ...other} = props;
    const theme = useTheme()
    const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
    const classes = useStyles()
    return (
        <Dialog
            size={size}
            maxWidth={size}
            fullWidth={fullWidth}
            classes={{root:classes.root, paperWidthLg: classes.paperWidthLg}}
            className={
                clsx({
                    [classes.root]: true,
                    [classes[`size${capitalize(size)}`]]: true,
                    [classes[fullScreen? "fullScreenLg" : `fullScreen${capitalize(size)}`]]: isMobile,
                }, className)
            }
            {...(isMobile ? {
                TransitionComponent: Transition,
                fullScreen: true,
                transitionDuration: transitionDuration,
            } : {})} {...other}/>
    )
}

export default TDialog;