/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';
import {WebapiprotoGroupCard} from './WebapiprotoGroupCard';

/**
 * The WebapiprotoGroups model module.
 * @module model/WebapiprotoGroups
 * @version v0.4
 */
export class WebapiprotoGroups {
  /**
   * Constructs a new <code>WebapiprotoGroups</code>.
   * @alias module:model/WebapiprotoGroups
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>WebapiprotoGroups</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/WebapiprotoGroups} obj Optional instance to populate.
   * @return {module:model/WebapiprotoGroups} The populated <code>WebapiprotoGroups</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new WebapiprotoGroups();
      if (data.hasOwnProperty('count'))
        obj.count = ApiClient.convertToType(data['count'], 'Number');
      if (data.hasOwnProperty('groups'))
        obj.groups = ApiClient.convertToType(data['groups'], [WebapiprotoGroupCard]);
    }
    return obj;
  }
}

/**
 * @member {Number} count
 */
WebapiprotoGroups.prototype.count = undefined;

/**
 * @member {Array.<module:model/WebapiprotoGroupCard>} groups
 */
WebapiprotoGroups.prototype.groups = undefined;


