/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from "../ApiClient";
import {WebapiprotoGroups} from '../model/WebapiprotoGroups';
import {WebapiprotoServiceDetails} from '../model/WebapiprotoServiceDetails';
import {WebapiprotoServiceFamilies} from '../model/WebapiprotoServiceFamilies';
import {WebapiprotoServices} from '../model/WebapiprotoServices';

/**
* Service service.
* @module api/ServiceApi
* @version v0.4
*/
export class ServiceApi {

    /**
    * Constructs a new ServiceApi. 
    * @alias module:api/ServiceApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Restituisce informazioni relative a tutti i servizi per una determinata service family
     * Restituisce informazioni relative a tutti i servizi per una determinata service family
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoServiceDetails} and HTTP response
     */
    getFamilyServiceDetailsWithHttpInfo(uuid) {
      let postBody = null;

      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling getFamilyServiceDetails");
      }


      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoServiceDetails;

      return this.apiClient.callApi(
        '/api/v1/family/{uuid}/service_details', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Restituisce informazioni relative a tutti i servizi per una determinata service family
     * Restituisce informazioni relative a tutti i servizi per una determinata service family
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoServiceDetails}
     */
    getFamilyServiceDetails(uuid) {
      return this.getFamilyServiceDetailsWithHttpInfo(uuid)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Elenca i servizi disponibili per una data categoria
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoServices} and HTTP response
     */
    getFamilyServicesWithHttpInfo(uuid) {
      let postBody = null;

      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling getFamilyServices");
      }


      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoServices;

      return this.apiClient.callApi(
        '/api/v1/family/{uuid}/services', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Elenca i servizi disponibili per una data categoria
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoServices}
     */
    getFamilyServices(uuid) {
      return this.getFamilyServicesWithHttpInfo(uuid)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Restituisce informazioni relative al dato tipo di servizio
     * Restituisce informazioni relative al dato tipo di servizio
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoServiceDetails} and HTTP response
     */
    getServiceWithHttpInfo(uuid) {
      let postBody = null;

      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling getService");
      }


      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoServiceDetails;

      return this.apiClient.callApi(
        '/api/v1/service/{uuid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Restituisce informazioni relative al dato tipo di servizio
     * Restituisce informazioni relative al dato tipo di servizio
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoServiceDetails}
     */
    getService(uuid) {
      return this.getServiceWithHttpInfo(uuid)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Elenca le categorie di servizi disponibili alla condivisione
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.tags 
     * @param {Boolean} opts._public 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoServiceFamilies} and HTTP response
     */
    getServiceFamiliesWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
        'tags': this.apiClient.buildCollectionParam(opts['tags'], 'multi'),
        'public': opts['_public']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoServiceFamilies;

      return this.apiClient.callApi(
        '/api/v1/family', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Elenca le categorie di servizi disponibili alla condivisione
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.tags 
     * @param {Boolean} opts._public 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoServiceFamilies}
     */
    getServiceFamilies(opts) {
      return this.getServiceFamiliesWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Restituisce i gruppi disponibili all'acquisto per il dato servizio
     * Restituisce i gruppi disponibili all'acquisto per il dato servizio
     * @param {String} uuid 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.limit 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoGroups} and HTTP response
     */
    getServiceGroupsWithHttpInfo(uuid, opts) {
      opts = opts || {};
      let postBody = null;

      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling getServiceGroups");
      }


      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
        'limit': opts['limit']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoGroups;

      return this.apiClient.callApi(
        '/api/v1/service/{uuid}/groups', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Restituisce i gruppi disponibili all'acquisto per il dato servizio
     * Restituisce i gruppi disponibili all'acquisto per il dato servizio
     * @param {String} uuid 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.limit 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoGroups}
     */
    getServiceGroups(uuid, opts) {
      return this.getServiceGroupsWithHttpInfo(uuid, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Elenca tutti i servizi disponibili alla condivisione
     * Elenca i servizi disponibili alla condivisione
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoServices} and HTTP response
     */
    getServicesWithHttpInfo() {
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoServices;

      return this.apiClient.callApi(
        '/api/v1/services', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Elenca tutti i servizi disponibili alla condivisione
     * Elenca i servizi disponibili alla condivisione
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoServices}
     */
    getServices() {
      return this.getServicesWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
