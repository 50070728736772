import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { T } from "@ticketag/i18nlib";
import capitalize from "@material-ui/core/utils/capitalize";
import clsx from "clsx";

const styles = theme => {
  return {
    root: {
      width: "52px",
      height: "52px"
    },
    sizeSm: {
      width: "36px",
      height: "36px"
    },
    sizeMd: {},
    sizeLg: {
      width: "36px",
      height: "36px"
    }
  };
};

export const Logo = ({
  src,
  classes,
  size = 'md',
  ...props
}) => {
  const useClasses = clsx(classes.root, {
    [classes.sm]: size === 'sm',
    [classes.md]: size === 'md',
    [classes.lg]: size === 'lg'
  });
  return <img className={useClasses} src={src} alt={"logo"} componentname="logo" />;
};
export default withStyles(styles, {
  useTheme: true
})(Logo);