import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import  {withRouter, Link as RouterLink} from "react-router-dom";
import {ExitIcon, Icon, MenuIcon} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Data from "../../../data";
import {T, withI18n} from "@ticketag/i18nlib";
import Tabs from "@ticketag/ticketag-uilib/src/components/base/Tabs/Tabs";
import Tab from "@ticketag/ticketag-uilib/src/components/base/Tabs/Tab";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Box from "@material-ui/core/Box";
import AvatarIcon, {AvatarMissingIcon} from "@ticketag/ticketag-uilib/src/components/base/Avatar/AvatarIcon";
import List from "@material-ui/core/List";
import {ListItemIcon, ListSubheader} from "@material-ui/core";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import clsx from "clsx";
import Link from "@ticketag/ticketag-uilib/src/components/base/Link/Link";
import MenuItem from "../../../../../../ticketag-uilib/src/components/base/Menu/MenuItem";
import {ArrowDownIcon} from "../../Icons/Icons";
import {withAppInstaller} from "../../AppInstaller/AppInstaller";
import {useTabContext} from "@material-ui/lab";
import {withAuth} from "../../../hoc/Auth/Provider";

const styles = (theme) => {
    return {
        avatar: {
            width: "74px",
            height: "74px",
        },
        drawer: {
            width: "70%",
            padding: "0 24px",
            //maxHeight: "calc(100vh - 48px)"
        },
        drawerHeader: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "36px",
            marginBottom: "20px"
        },
        drawerTitle: {
            fontSize: "24px",
            fontWeight: "600",
            lineHeight:"30px",
            color: theme.palette.text.secondary
        },
        drawerAvatar: {
            width: "52px",
            height: "52px",
            cursor: "auto"
        },
        drawerCloseIcon: {
            position: "absolute",
            right: "0px",
            top: "10px",
            "& svg": {
                width: "56px !important",
                height: "56px !important",
            },
        },
        drawerSubtitle: {
            padding: "0",
            color: theme.palette.text.secondary,
            " & h4": {
                textAlign: "right",
                fontSize: "16px",
                fontWeight: "600"
            }
        },
        drawerSection: {
            marginBottom: "32px",
            "& span": {
                fontSize: "20px"
            }
        },
        drawerLogout: {
            textDecoration: "none",
            color: theme.palette.error.main,
            display: "block",
            textAlign: "center",
            "& a": {
                fontSize: "16px"
            }
        },
        drawerLink: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            cursor: "pointer",
            textDecoration: "none",
            fontSize: "20px",
            color: theme.palette.text.primary,
            "&:hover span": {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
            }
        },
        installAppButton: {
            cursor: "pointer",
            color: theme.palette.accent.main + "!important",
        },
        list: {
            padding: "32px 0",
        },
        listItemIcon: {
            minWidth: "32px"
        },
        listItem: {
            padding: "16px 32px",
            fontSize: "20px",
            fontWeight: "300",
            lineHeight: "24px",
            letterSpacing: "0.01em",
            color: theme.palette.text.primary,
            display: "flex",
            alignItems: "center"
        },
        actionsBox: {
            marginTop: "60px",
          marginBottom: "42px",
          textAlign: "center",
            "& a": {
              fontSize: "24px"
            },
            "& span": {
              fontSize: "24px",
                margin: "0 8px 0 8px"
            }
        },
        adminActionsBox: {
            paddingBottom: theme.spacing(8),
        },
        backdrop: {
            zIndex: "2000",
        },
        textIcon: {
            marginBottom: '-4px'
        }
    }
}

const drawerData = [
    {
      key:  "global.drawer.profile",
      title: "Account",
      href: "/profile",
    },
    {
        key: "global.drawer.faq",
        title: "Domande frequenti",
        href: "/faq",
    },
    {
        key: "global.drawer.help_chat",
        title: "Chat assistenza",
        href: "/chat/help",
    },
]

function TDrawer(props) {
    const {
        children,
        appInstaller,
        i18n,
        auth,
        location,
        classes,
        adminMenu,
        ...other
    } = props;
    const [open, setOpen] = React.useState(false)

    const isAuthenticated = auth.isAuthenticated()
    const userInfo = auth.getUserInfo()

    const shouldShowInstallApp = !appInstaller.isStandalone() &&
        ((appInstaller.isAndroid() && appInstaller.deferredPrompt()) || appInstaller.isIOs())
    const installApp = () => appInstaller.installApp()

    const logoutUrl = auth.logoutUrl
    const signupUrl = auth.loginUrl
    const signinUrl = auth.signUpUrl

    const data =  isAuthenticated ?
        Data.DefaultLayout.drawer.logged :
        Data.DefaultLayout.drawer.unlogged

    const formatName = (fullName) => {
        const [firstName, lastName] = fullName.split(" ")
        return `${firstName} ${lastName[0]}.`
    }
    if (userInfo && !userInfo.complete) {
        return null
    }
    return (
        <React.Fragment>
            <IconButton disableRipple={true} color="inherit" aria-label="open drawer" edge="start"
                        onClick={() => {setOpen(true)}}>
                <MenuIcon/>
            </IconButton>
            <SwipeableDrawer
                variant="temporary"
                anchor="right"
                role={"presentation"}
                open={open}
                modalProps={{hideBackdrop: false, onClose: () => setOpen(false)}}
                PaperProps={{className: classes.drawer}}
                onOpen={(e) => setOpen(true)}
                onClose={(e) => setOpen(false)}
            >
                <Box className={classes.drawerHeader}>
                    <IconButton classes={{root: classes.drawerCloseIcon}} onClick={() => setOpen(false)}>
                        <Icon color={"error"}>close</Icon>
                    </IconButton>
                    {
                        userInfo ?
                            <AvatarIcon
                                clickable={true}
                                className={classes.avatar}
                                size={"medium"} src={userInfo?.avatarUuid}
                                alt={"avatar"}
                            />
                            :
                            <AvatarMissingIcon
                                className={classes.avatar}/>
                    }
                    <h2 className={classes.drawerTitle}>{data.title} {userInfo ? formatName(userInfo.fullName) : null}</h2>
                </Box>
                <Divider/>
                <List className={classes.list} disablePadding={true}>
                    {
                        drawerData.map((elt) => (
                            <ListItem key={elt.key} className={classes.listItem}
                                      disableGutters={true}
                                      onClick={() => setOpen(false)}
                                      component={RouterLink} to={i18n.buildLocalizedUrl(elt.href)}>
                                <ListItemText disableTypography={true}><T defaultVal={elt.title}>{elt.key}</T></ListItemText>
                            </ListItem>
                        ))
                    }
                    <ListItem className={classes.listItem}
                              disableGutters={true}
                              onClick={() => setOpen(false)}
                              component={"a"} href={"https://help.diveedi.com/"}>
                        <ListItemText disableTypography={true}><T defaultVal={"Centro assistenza"}>global.drawer.help_site</T></ListItemText>
                    </ListItem>
                    {
                        shouldShowInstallApp ?
                            <ListItem className={clsx(classes.installAppButton, classes.listItem)}
                                      disableGutters={true}
                                      onClick={() => installApp()}
                            >
                                <ListItemText disableTypography={true}><T defaultVal="Installa l'app">components.drawer.install_app</T></ListItemText>
                            </ListItem> :
                            null
                    }

                </List>
                {children}
                { userInfo?.impersonated ? <ListItem className={classes.drawerLink}
                                                     key="exit-impersonation"
                                                     component={"div"}
                                                     disablePadding={true}
                                                     onClick={() => {window.location.href = `/auth/impersonate/exit?redirect=${window.location.pathname}`}}>
                        <ListItemText>
                            <Box display="flex" alignItems="center">
                                <Box mr={1}><ExitIcon color={'error'}/></Box><Typography color={"textSecondary"} variant={"h5"}> Exit impersonation</Typography>
                            </Box>
                        </ListItemText>
                    </ListItem>

                    : null}
                <Divider/>
                <Box className={clsx(classes.actionsBox, {[classes.adminActionsBox] : !!children})}>
                    {
                        userInfo ?
                            <Link
                                underline={"none"} color={"error"}
                                href={logoutUrl}>
                                    {data?.actions?.logout?.title}
                            </Link>
                            :
                            <React.Fragment>
                                <Link underline={"none"} color={"secondary"} href={signinUrl}>
                                    {data?.actions?.signIn?.title}
                                </Link>
                                <span>o</span>
                                <Link underline={"none"} color={"secondary"} href={signupUrl}>
                                    {data?.actions?.signUp?.title}
                                </Link>
                            </React.Fragment>
                    }
                </Box>
            </SwipeableDrawer>
        </React.Fragment>
    )
}

export default withStyles(styles, {name: ""})(withI18n(withAuth(withAppInstaller(withRouter(TDrawer)))))
