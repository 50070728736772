import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import { Icon } from "../../base/Icon/Icon";
import Typography from "../../base/Typography/Typography";
import { Box, capitalize } from "@material-ui/core";
import clsx from "clsx";

const styles = theme => {
  return {
    root: {
      width: 250 - theme.spacing(4),
      minHeight: 280 - theme.spacing(3),
      borderRadius: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      cursor: 'pointer',
      boxShadow: theme.shadows[8],
      transition: "box-shadow 0.3s ease-in-out",
      "&$hover": {
        transition: "box-shadow 0.3s ease-out, transform 0.3s ease-out",
        boxShadow: theme.shadows[9]
      },
      "&$clicked": {
        transition: "box-shadow 0.1s ease-out, transform 0.1s ease-out !important",
        transform: "translateY(-2px)",
        boxShadow: theme.shadows[10] + "!important"
      }
    },
    hover: {
    },
    clicked: {
    },
    sideLeft: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0
    },
    sideRight: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0
    }
  };
};

function BaseActionCard({
  onClick,
  side = null,
  children,
  classes,
  clickDisabled = false,
  hoverDisabled = false,
  ...props
}) {
  const useClasses = [classes.root];
  if (side) {
    useClasses.push(classes['side' + capitalize(side)]);
  }

  const [hover, setHover] = React.useState(false)
  const [clicked, setClicked] = React.useState(false)

  const handleEnter = e => {
    setHover(true);
  };

  const handleLeave = e => {
    setHover(false);
  };

  const handleClick = onClick ? (e) => {
    setClicked(true)
    setTimeout(() => {
      setClicked(false)
      setTimeout(() => onClick(e), 50)
    }, 100)
  } : null

  return <Paper
      classes={classes}
      className={clsx(useClasses,
          hover && !hoverDisabled ? classes.hover : null,
          clicked && !clickDisabled ? classes.clicked : null
      )}
      onClick={handleClick} {...props}
      componentname="base-action-card"
      onMouseEnter={handleEnter} onMouseLeave={handleLeave}
  >{children}</Paper>;
}

export default withStyles(styles, {
  name: "BaseActionCard"
})(BaseActionCard);
