import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {I18nContext, T, withI18n} from "@ticketag/i18nlib";
import Progress from "@ticketag/ticketag-uilib/src/components/base/Progress/Progress";
import {withApi} from "../../hoc/Api/Provider";
import AuthContext from "../../hoc/Auth/context";
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from "@material-ui/core/Breadcrumbs/Breadcrumbs";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import Box from "@material-ui/core/Box";
import {Icon} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";
import Link from "@ticketag/ticketag-uilib/src/components/base/Link/Link";
import {Link as RouterLink} from "react-router-dom";
import ActionCard from "@ticketag/ticketag-uilib/src/components/complex/ActionCard/ActionCard";
import {JoinGroup, CreateGroup} from "../../components/Icons/Icons";
import NewGroupHeader from "../../components/NewGroupHeader/NewGroupHeader";
import CardsContainer from "../../components/CardsContainer/CardsContainer";
import {withAuth} from "../../hoc/Auth/Provider";
import {withRouter} from "react-router-dom";
import Hidden from "@material-ui/core/Hidden";
import TmpCard from "@ticketag/ticketag-uilib/src/components/complex/TmpCard/TmpCard";
import Layout from "../../hoc/Layout/Layout";
import Navbar from "../../components/Navbar/Navbar";
import NavTitle from "../../components/Navbar/NavTitle/NavTitle";
import Title from "../../components/Title/Title";
import Scroll from "../../components/Scroll/Scoll";
import TextFooter from "../../components/TextFooter/TextFooter";
import Help from "../../components/Help/Help";
import {withGlobalLoader} from "../../hoc/GlobalLoader/Provider";
import {pages} from "../../constants";
import Page from "../../components/Page/Page";
import {SeoIndex, SeoDescription, SeoTitle} from "../Seo/Seo";


const styles = (theme) => {
    return {
        root: {}
    }
};

class JoinCreateGroup extends Component {
    static contextType = I18nContext

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.loader.Hide()
    }

    get joinUrl() {
        return this.context.buildLocalizedUrl("services", this.props.serviceFamily.slug, 'join')
    }

    get createUrl() {
        return this.context.buildLocalizedUrl("services", this.props.serviceFamily.slug, 'create')
    }

    renderContent() {
        const serviceName = this.props.serviceFamily.name.toLowerCase()
        const createSubtitle = <T defaultVal={`Se hai un account ${this.props.serviceFamily.name} e vuoi risparmiare`}>{`pages.join_create_group.share_service_${serviceName}`}</T>
        const joinSubtitle = <span><T defaultVal={`Entra in un gruppo per ottenere ${this.props.serviceFamily.name}`}>{`pages.join_create_group.create_service_${serviceName}`}</T></span>
        return (
            <Grid container justify={"center"}  spacing={4} direction={"column"} alignItems={"center"}>
                <Grid item xs={12}>
                    <TmpCard
                        onClick={() => {this.props.history.push(this.createUrl)}}>
                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} p={2}>
                            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} width={50} height={50}>
                                {CreateGroup()}
                            </Box>
                        </Box>
                        <Box display={"flex"} flexDirection={"column"} flexGrow={1} p={2} pt={1.5}>
                            <Typography color={"textSecondary"} style={{textTransform: "uppercase",fontSize: "22px", fontWeight: "600", lineHeight: "26px", paddingBottom: "2px"}}>
                                <T defaultVal={"Vendi"}>pages.join_create_group.create</T>
                            </Typography>
                            <Typography color={"textPrimary"} style={{fontSize: "12px", fontWeight: "300", lineHeight: "14px"}}>
                                {createSubtitle}
                            </Typography>
                        </Box>
                    </TmpCard>
                </Grid>
                <Grid item xs={12}>
                    <TmpCard
                        onClick={() => {this.props.history.push(this.joinUrl)}}>
                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} p={2}>
                            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} width={50} height={50}>
                                {JoinGroup()}
                            </Box>
                        </Box>
                        <Box display={"flex"} flexDirection={"column"} flexGrow={1} p={2} pt={1.5}>
                            <Typography color={"textSecondary"} style={{textTransform: "uppercase",fontSize: "22px", fontWeight: "600", lineHeight: "26px", paddingBottom: "2px"}}>
                                <T defaultVal={"Acquista"}>pages.join_create_group.join</T>
                            </Typography>
                            <Typography color={"textPrimary"} style={{fontSize: "12px", fontWeight: "300", lineHeight: "14px"}}>
                                {joinSubtitle}
                            </Typography>
                        </Box>
                    </TmpCard>
                </Grid>
            </Grid>
        )
    }

    render() {
        const {auth, history, i18n} = this.props;
        const serviceFamily = this.props.serviceFamily
        const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1)
        const {
            seo,
            layout,
            title,
            helpTitle,
            footer,
            ...other
        } = this.props;
        return <Page seo={seo} layout={{
            ...layout,
            onGoBack: auth.isAuthenticated() ?
                () => history.replace(i18n.buildLocalizedUrl("/groups"))
                :
                () => history.replace(i18n.buildLocalizedUrl("/"))
            ,
            onClose: auth.isAuthenticated() ?
                () => history.replace(i18n.buildLocalizedUrl("/groups"))
                :
                () => history.replace(i18n.buildLocalizedUrl("/"))
        }} {...other}>
            <SeoTitle args={{Name: capitalize(serviceFamily.name)}}>{seo.title}</SeoTitle>
            <SeoDescription args={{Name: capitalize(serviceFamily.name)}}>{seo.description}</SeoDescription>
            <SeoIndex value={seo.index}/>
            <Title>{title}</Title>
            <Scroll>
                {this.renderContent()}
            </Scroll>
            <TextFooter
                helpTitle={helpTitle}
                help={
                    <>
                        <Help id={"create"}
                              title={`Vendi`}
                              args={{Name: serviceFamily.name}}
                        >{`Se hai un account {{ .args.Name }} e vuoi risparmiare`}
                        </Help>
                        <Help id={"join"}
                              title={`Acquista`}
                              args={{Name: serviceFamily.name}}
                        >
                            {`Entra in un gruppo per ottenere {{ .args.Name }}`}
                        </Help>
                    </>
                }>
                {footer}
            </TextFooter>
        </Page>

    }
}

export default withStyles(styles, {useTheme: true})(withRouter(withGlobalLoader(withAuth(withApi(withI18n(JoinCreateGroup))))));
