/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from "../ApiClient";
import {WebapiprotoGenericConfirm} from '../model/WebapiprotoGenericConfirm';
import {WebapiprotoJoinGroupRequestData} from '../model/WebapiprotoJoinGroupRequestData';
import {WebapiprotoJoinRequest} from '../model/WebapiprotoJoinRequest';

/**
* Join service.
* @module api/JoinApi
* @version v0.4
*/
export class JoinApi {

    /**
    * Constructs a new JoinApi. 
    * @alias module:api/JoinApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Conferma la richiesta di unione ad un gruppo
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoGenericConfirm} and HTTP response
     */
    confirmGroupJoinWithHttpInfo(uuid) {
      let postBody = null;

      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling confirmGroupJoin");
      }


      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoGenericConfirm;

      return this.apiClient.callApi(
        '/api/v1/join_request/{uuid}/confirm', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Conferma la richiesta di unione ad un gruppo
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoGenericConfirm}
     */
    confirmGroupJoin(uuid) {
      return this.confirmGroupJoinWithHttpInfo(uuid)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Elimina una richiesta di partecipazione
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoGenericConfirm} and HTTP response
     */
    deleteJoinRequestWithHttpInfo(uuid) {
      let postBody = null;

      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling deleteJoinRequest");
      }


      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoGenericConfirm;

      return this.apiClient.callApi(
        '/api/v1/join_request/{uuid}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Elimina una richiesta di partecipazione
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoGenericConfirm}
     */
    deleteJoinRequest(uuid) {
      return this.deleteJoinRequestWithHttpInfo(uuid)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Ottiene una richiesta di partecipazione
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoJoinRequest} and HTTP response
     */
    getGroupJoinRequestWithHttpInfo(uuid) {
      let postBody = null;

      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling getGroupJoinRequest");
      }


      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoJoinRequest;

      return this.apiClient.callApi(
        '/api/v1/group/{uuid}/join_request', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Ottiene una richiesta di partecipazione
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoJoinRequest}
     */
    getGroupJoinRequest(uuid) {
      return this.getGroupJoinRequestWithHttpInfo(uuid)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Ottiene una richiesta di partecipazione
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoJoinRequest} and HTTP response
     */
    getJoinRequestWithHttpInfo(uuid) {
      let postBody = null;

      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling getJoinRequest");
      }


      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoJoinRequest;

      return this.apiClient.callApi(
        '/api/v1/join_request/{uuid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Ottiene una richiesta di partecipazione
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoJoinRequest}
     */
    getJoinRequest(uuid) {
      return this.getJoinRequestWithHttpInfo(uuid)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Richiesta di partecipazione ad un gruppo
     * @param {module:model/WebapiprotoJoinGroupRequestData} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoJoinRequest} and HTTP response
     */
    joinGroupWithHttpInfo(body) {
      let postBody = body;

      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling joinGroup");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoJoinRequest;

      return this.apiClient.callApi(
        '/api/v1/join_request/create', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Richiesta di partecipazione ad un gruppo
     * @param {module:model/WebapiprotoJoinGroupRequestData} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoJoinRequest}
     */
    joinGroup(body) {
      return this.joinGroupWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
