import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Link from "@material-ui/core/Link";
import capitalize from "@material-ui/core/utils/capitalize";
import clsx from "clsx";
import Typography from "../Typography/Typography";
import {Skeleton} from "@material-ui/lab";



// We can inject some CSS into the DOM.
const styles =(theme) => ({
    root: {
        cursor: "pointer",
        //fontSize: "14px"
    },
    disabled: {
      cursor: 'default',
      textDecoration: 'none'
    },
    sizeSmall: {
        fontSize: "12px"
    },
    sizeMedium: {
        fontSize: "14px"
    },
    sizeLarge: {
        fontSize: "18px"
    },
    fullWidth: {
        display: "block",
      width: "100%"
    },
    colorGradient: {
        '-webkit-text-fill-color': 'transparent',
        '-webkit-background-clip': 'text',
        background: theme.palette.default_gradient
    },
    colorContrast: {
        color: theme.palette.primary.contrastText
    },
    colorPrimary: {
        color: theme.palette.primary.main
    },
    colorSecondary: {
        color: theme.palette.secondary.main
    },
    colorError: {
        color: theme.palette.error.main
    }
});

const materialColors = ["initial","inherit","primary","secondary","textPrimary","textSecondary","error"]

const TLink = React.forwardRef(function TLink(props, ref) {
    const { skeletonWidth = 100,
        isLoading, classes, children, className, size, color, fullWidth, variant="button", disabled, onClick, ...other } = props;
    const useClasses = [classes.root];
    useClasses.push(fullWidth ? classes.fullWidth : null)
    useClasses.push(classes[size && `size${capitalize(size)}`])
    useClasses.push(!isLoading ? classes[color && `color${capitalize(color)}`] : null)
    useClasses.push(className)
    if (disabled) {
        return <Typography classes={{root: classes.disabled}} variant={variant} color="disabled" {...other}>
            {
                isLoading ? <Skeleton variant={"text"} width={skeletonWidth}/> : children
            }
        </Typography>
    }
    return (
        <Link
            color={color && materialColors.indexOf(materialColors) !== -1 ? color : "inherit"}
            variant={variant} onClick={onClick} className={clsx(useClasses)} ref={ref} {...other}
        >{
            isLoading ? <Skeleton variant={"text"} width={skeletonWidth}/> : children
        }
        </Link>
    );
});

TLink.propTypes = Link.propTypes

export default withStyles(styles, {name: "MuiLink", useTheme:true})(TLink);
