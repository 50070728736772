import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import AuthContext from "../../hoc/Auth/context";
import {I18nContext, T} from "@ticketag/i18nlib";
import BillingDataSelector from "../../components/BillingDataSelector/BillingDataSelector";
import {ConfirmDeleteBillingData} from "../../hoc/Modals/Modals";

import {withErrorModal} from "../../components/ErrorMessage/ErrorMessage";

const styles = (theme) => {
    return {
        root: {}
    }
};

class BillingData extends Component {
    static contextType = I18nContext

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            billingProfiles: props.billingProfiles.sort((a,b) => a.isMain - b.isMain).reverse(),
        };
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        if (!this.state.billingProfiles) {
            this.loadUserProfiles();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadUserProfiles() {
        let profiles = [];

        this.setState({
            isLoading: true
        });

        this.props.api.userApi.getUserProfiles().then((billingProfiles) => {
            profiles = billingProfiles.profiles.sort((a,b) => a.isMain - b.isMain).reverse();
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            this.setState({
                billingProfiles: profiles,
                isLoading: false,
            });
        });
    }

    createProfileMethod(editProfile, form) {
        this.setState({
            isLoading: true
        });

        let profiles = [...this.state.billingProfiles];

        withErrorModal(this.props.modals, this.props.api.userApi.updateUserProfile({
            ...editProfile,
            ...form
        }).then((newBillingProfile) => {
            if (editProfile.uuid) {
                profiles = profiles.map(
                    (profile) => (profile.uuid === newBillingProfile.uuid)
                    ?
                    ({
                        ...profile,
                        ...newBillingProfile,
                    })
                    :
                    ({...profile})
                );
            } else {
                profiles.push(newBillingProfile);
            }
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            this.setState({
                billingProfiles: profiles.sort((a,b) => a.isMain - b.isMain).reverse(),
                isLoading: false,
            }, () => {
                this.emitOnChange();
            });
        }));
    }

    emitOnChange() {
        if (this.props.onChange) {
            this.props.onChange(this.state.billingProfiles, this.state.billingProfiles.find(p => p.isMain));
        }
    }

    setDefaultProfileMethodState(uuid, cb) {
        return this.setState({
            billingProfiles: this.state.billingProfiles.map(p => {
                p.uuid === uuid ? p.isMain = true : p.isMain = false
                return p;
            }).sort((a,b) => a.isMain - b.isMain).reverse()
        }, cb);
    }

    setDefaultProfileMethod(uuid, onComplete) {
        withErrorModal(this.props.modals, this.props.api.SetMainProfile(uuid).then(resp => {
            return this.setDefaultProfileMethodState(resp.uuid, () => {
                onComplete();
                this.emitOnChange();
            });
        }));
    }

    deleteProfileMethod(uuid) {
        ConfirmDeleteBillingData(this.props.modals).then((ok) => {
            if (ok) {
                this.setState({
                    isLoading: true
                });

                withErrorModal(this.props.modals, this.props.api.userApi.deleteProfile(uuid).then((result) => {
                    let billingProfiles = [...this.state.billingProfiles];

                    if (result.ok) {
                        billingProfiles = billingProfiles.filter(profile => profile.uuid !== uuid);
                    }

                    this.setState({
                        billingProfiles,
                        isLoading: false
                    }, () => {
                        this.emitOnChange();
                    });
                }));
            }
        });
    }

    render() {
        if (!this.props.isLoaded) {
            return <React.Fragment/>;
        }

        const { smallVariant = false, inlineVariant = false } = this.props;

        return <BillingDataSelector profiles={this.state.billingProfiles}
                                    modals={this.props.modals}
                                    i18n={this.context}
                                    smallVariant={smallVariant}
                                    inlineVariant={inlineVariant}
                                    verticalLayout={this.props.verticalLayout}
                                    onCreate={(profile, form) => this.createProfileMethod(profile, form)}
                                    onSelect={(p, onComplete) => this.setDefaultProfileMethod(p.uuid, onComplete)}
                                    onDelete={(uuid) => this.deleteProfileMethod(uuid)}/>;
    }
}

export default withStyles(styles, {useTheme: true})(BillingData);
