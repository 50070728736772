import React from "react";
import {renderRoutes} from "react-router-config";
import {withApi} from "../../hoc/Api/Provider";
import {withI18n} from "@ticketag/i18nlib";
import {withModals} from "@ticketag/ticketag-uilib/src/components/hoc/Modals/Provider";
import { matchPath } from "react-router";
import Layout from "../../hoc/Layout/Layout";
import {withGlobalLoader} from "../../hoc/GlobalLoader/Provider";
import {withLastLocation} from "react-router-last-location";
import {UndoCreateGroup} from "../../hoc/Modals/Modals";
import {withRedirect} from "../../components/ErrorMessage/ErrorMessage";
import {Routes} from "../../hoc/Routes/Provider";

export const styles = (theme) => {
    return {
        root: {}
    }
}

class CreateGroupSwitch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
        }
        this.closeGroup = this.closeGroup.bind(this)
    }

    componentDidMount() {
        console.log("CREATE GROUP SWITCH")
        this.loadData()
    }

    componentWillUnmount() {
        console.log("UNMOUNT CREATE GROUP SWITCH")
    }

    loadData() {
        const group = this.props.group
        Promise.all([
            this.props.api.GetServiceType(group.serviceDetails.serviceTypeUuid),
            this.props.api.serviceApi.getFamilyServices(group.serviceFamilyUuid)
        ]).then(([serviceTypes, services]) => {
            const service = serviceTypes.details.find((elt) => elt.serviceTypeUuid === group.serviceDetails.serviceTypeUuid)
            const serviceType = services.services.find((elt) => elt.uuid === group.serviceDetails.serviceTypeUuid)
            this.setState({group: group, service: service, serviceType: serviceType, isLoading: false})
        })
    }

    updateGroup(data) {
        const {api, match} = this.props;
        this.setLoading(true)
        const scope = data.scope || this.state.group.scope
        const size = data.size || this.state.group.size
        return api.groupApi.updateGroup(match.params.groupUuid, {
            scope: scope, size: size,
        }).
        then((group) => {
            this.setState({
                group: Object.assign(group, {
                    scope: scope,
                    size: size,
                    credentials: this.state.group.credentials,
                    serviceFamily: this.state.group.serviceFamily,
                    schema: this.state.group.schema,
                })
            })
            this.setLoading(false)
        })
    }

    updateCredentials(data) {
        const {api, match} = this.props;
        this.setLoading(true)
        return api.groupApi.setGroupAccount(match.params.groupUuid, {
            data:btoa(String.fromCharCode(...new TextEncoder("utf-8").encode(JSON.stringify(data))))
        }).then(resp => {
            this.setState({
                group: {...this.state.group, credentials: data},
            })
            this.setLoading(false)
        })
    }

    completeGroup() {
        const {history, match, api} = this.props;
        this.setLoading(true)
        return withRedirect(history, api.groupApi.completeGroup(match.params.groupUuid).then((group) => {
            this.setState({
                group: {
                    ...group,
                    credentials: this.state.group.credentials,
                    serviceFamily: this.state.group.serviceFamily,
                    schema: this.state.group.schema,
                },
            })
            this.shouldCloseGroup = false
            this.setLoading(false)
        }))
    }

    closeGroup() {
        const {api, match} = this.props;
        this.setLoading(true)
        return api.groupApi.closeGroup(match.params.groupUuid, {}).
        catch((err) => console.log(err))
    }

    setLoading(loading) {
        this.setState({isLoading : loading})
        loading ? this.props.loader.Show() : this.props.loader.Hide()
    }

    handleClose() {
        UndoCreateGroup(this.props.modals)
            .then(() => {
                this.props.history.push(this.props.i18n.buildLocalizedUrl("groups"))
            }).catch((ok) => {
            if (ok) {
                this.setLoading(true)
                this.closeGroup().finally(() => {
                    this.props.history.push(this.props.i18n.buildLocalizedUrl("groups"))
                })
            }
        })
    }

    render() {
        const {route, ...other} = this.props;

        return  this.state.isLoading ?
                    null :
            <Routes
                routes={route.routes}
                group={{
                    ...this.state.group,
                    serviceType: this.state.serviceType,
                    service: this.state.service,
                    updateGroup: (data) => this.updateGroup(data),
                    updateCredentials: (data) => this.updateCredentials(data),
                    completeGroup: () => this.completeGroup(),
                    closeGroup: () => this.closeGroup()
                }}
                onGoBack={ () => this.props.history.goBack() }
                onClose={() => this.handleClose()}
            />

    }
}

export default withGlobalLoader(withLastLocation(withGlobalLoader(withModals(withApi(withI18n(CreateGroupSwitch))))))