/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * The WebapiprotoBannerUuid model module.
 * @module model/WebapiprotoBannerUuid
 * @version v0.4
 */
export class WebapiprotoBannerUuid {
  /**
   * Constructs a new <code>WebapiprotoBannerUuid</code>.
   * @alias module:model/WebapiprotoBannerUuid
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>WebapiprotoBannerUuid</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/WebapiprotoBannerUuid} obj Optional instance to populate.
   * @return {module:model/WebapiprotoBannerUuid} The populated <code>WebapiprotoBannerUuid</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new WebapiprotoBannerUuid();
      if (data.hasOwnProperty('banner_uuid'))
        obj.bannerUuid = ApiClient.convertToType(data['banner_uuid'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} bannerUuid
 */
WebapiprotoBannerUuid.prototype.bannerUuid = undefined;


