import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import {T} from "@ticketag/i18nlib";
import {withGlobalLoader} from "../GlobalLoader/Provider";

const getErrData = (error, errorInfo) => {
    return {
        title: 'errors.generic.generic',
        message: error.message,
        stack: error.stack,
        debugMessage: errorInfo?.componentStack
    }
}

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(D) {
        return {hasError: true, error: getErrData(D)};
    }



    componentDidCatch(error, errorInfo) {
        console.error(error)
        console.log('Component stack', errorInfo.componentStack)
        this.props.loader.Hide()
        this.setState({hasError: true, error: getErrData(error, errorInfo)})
    }

    render() {
        if (this.state.hasError) {
            return <Grid container justify={"center"}>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                        <ErrorMessage
                            stack={this.state.error.stack}
                            debugMessage={this.state.error.debugMessage}
                            title={<T>{this.state.error.title}</T>}
                            message={this.state.error.message}
                        />
                    </Box>
                </Grid>
            </Grid>;
        }
        return this.props.children;
    }
}

export default withGlobalLoader(ErrorBoundary)