import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T, withI18n} from "@ticketag/i18nlib";
import Progress from "@ticketag/ticketag-uilib/src/components/base/Progress/Progress";
import TmpCard from "@ticketag/ticketag-uilib/src/components/complex/TmpCard/TmpCard";
import {withApi} from "../../hoc/Api/Provider";
import NewGroupHeader from "../../components/NewGroupHeader/NewGroupHeader";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box/Box";
import Stepper from "@ticketag/ticketag-uilib/src/components/base/Stepper/Stepper";
import Step from "@ticketag/ticketag-uilib/src/components/base/Stepper/Step";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import Divider from "@material-ui/core/Divider";
import NewGroupDataForm from "../../components/NewGroupDataForm/NewGroupDataForm";
import Card from "@material-ui/core/Card";
import NameForm from "../../components/NameForm/NameForm";
import Button from "@ticketag/ticketag-uilib/src/components/base/Button/Button";
import NewGroupCard from "@ticketag/ticketag-uilib/src/components/complex/NewGroupCard/NewGroupCard";
import NewGroupList from "@ticketag/ticketag-uilib/src/components/complex/NewGroupCard/NewGroupList";
import NewGroupPage from "../../components/NewGroupPage/NewGroupPage";
import {withModals} from "@ticketag/ticketag-uilib/src/components/hoc/Modals/Provider";
import {withErrorModal, withRedirect} from "../../components/ErrorMessage/ErrorMessage";
import {withRouter} from "react-router-dom";
import TextField from "@ticketag/ticketag-uilib/src/components/base/TextField/TextField";
import {Select} from "@material-ui/core";
import ComboBox from "@ticketag/ticketag-uilib/src/components/base/Select/ComboBox";
import {UndoCreateGroup} from "../../hoc/Modals/Modals";
import Layout from "../../hoc/Layout/Layout";
import Page from "../../hoc/Page/Provider";
import Title from "../../components/Title/Title";
import Scroll from "../../components/Scroll/Scoll";
import TextFooter from "../../components/TextFooter/TextFooter";
import Help from "../../components/Help/Help";


const styles = (theme) => {
    return {
        root: {

        },
        nextButton: {
            width: "80px",
            height: "80px",
            borderRadius: "50%",
            marginBottom: "40px"
        },
        endSection: {
            height: "100%",
            "& > *": {
                height: "100%",
                alignItems: "flex-end"
            }
        }
    }
};

class CreateGroup extends Component {

    constructor(props) {//
        super(props);
        this.state = {
            formValues: {},
            loaded:false,
            step: 1,
            visibility: null,
            size: 0,
        }
        this.nextButtonRef = React.createRef()
    }
    componentDidMount() {
        withRedirect(this.props.history, this.props.api.groupApi.groupDetails(this.props.match.params.groupUuid).then((group) => {
            Promise.all([
                this.props.api.GetServiceFamily(group.serviceFamilyUuid),
                this.props.api.GetServiceType(group.serviceDetails.serviceTypeUuid),
                this.props.api.serviceApi.getFamilyServices(group.serviceFamilyUuid)
            ]).then(([serviceFamily, serviceTypes, services]) => {
                console.log(group, serviceFamily,serviceTypes, services)
                const service = serviceTypes.details.find((elt) => elt.serviceTypeUuid === group.serviceDetails.serviceTypeUuid)
                const serviceType = services.services.find((elt) => elt.uuid === group.serviceDetails.serviceTypeUuid)
                this.setState({loaded: true, group: group, serviceFamily: serviceFamily, serviceType: serviceType, service: service})
            })
        }))

    }

    createGroup() {
        withErrorModal(this.props.modals, this.props.api.groupApi.updateGroup(this.state.group.uuid, {
            scope: this.state.visibility, size: this.state.size,
        }).then(resp => {
            this.props.history.push(this.props.i18n.buildLocalizedUrl('groups', resp.uuid, 'create', 'credentials'))
        }))
    }

    renderStep() {
        switch (this.state.step) {
            case 1:
                return this.renderVisibilityStep()
            case 2:
                return this.renderGroupSizeStep()
            default:
                return <></>
        }
    }

    renderScope(scope) {
        switch (scope) {
            case "public":
                return (
                    <Box display={"flex"} justifyContent={"center"}>
                        <TmpCard title={"pubblico"} subtitle={"Altri utenti del sito"} media={`${process.env.PUBLIC_URL||""}/img/services/scopes/scope_public.png`} onClick={() => this.setVisibility("public")}/>
                    </Box>
                )
            case "private":
                return (
                    <Box display={"flex"} justifyContent={"center"}>
                    <TmpCard title={"privato"} subtitle={"Solo su invito"} media={`${process.env.PUBLIC_URL||""}/img/services/scopes/scope_private.png`} onClick={() => this.setVisibility("private")}/>
                    </Box>
                )
        }
    }

    setVisibility(visibility) {
        this.setState({visibility: visibility, step: 2})
    }

    setGroupSize(size) {
        this.setState({size: size})
    }


    handleGoBack() {
        withRedirect(this.props.history, this.props.api.groupApi.closeGroup(this.props.match.params.groupUuid, {}).then(() => {
            this.props.history.goBack()
        }))
    }

    handleClose() {
        UndoCreateGroup(this.props.modals)
            .catch((ok) => {
                if (ok) {
                    withRedirect(this.props.history, this.props.api.groupApi.closeGroup(this.props.match.params.groupUuid, {}).then(() => {
                        this.props.history.push(this.props.i18n.buildLocalizedUrl("groups"))
                    }))
                }
            })
            .then(() => {
                this.props.history.push(this.props.i18n.buildLocalizedUrl("groups"))
        })
    }

    renderFooter(enabled) {
        return (
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
                <Button ref={this.nextButtonRef} variant={"contained"} color={"gradient"} className={this.props.classes.nextButton} onClick={() => this.createGroup()} disabled={!enabled}>
                    <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M30.6316 16.8585C31.4127 16.0775 31.4127 14.8112 30.6316 14.0301L17.9037 1.3022C17.1226 0.521152 15.8563 0.521152 15.0753 1.3022C14.2942 2.08325 14.2942 3.34958 15.0753 4.13063L26.389 15.4443L15.0753 26.758C14.2942 27.5391 14.2942 28.8054 15.0753 29.5865C15.8563 30.3675 17.1226 30.3675 17.9037 29.5865L30.6316 16.8585ZM0.772949 17.4443H29.2174V13.4443H0.772949V17.4443Z" fill="#FCFCFC"/>
                    </svg>
                </Button>
                    {/*
                <Typography color={"textPrimary"} className={this.props.classes.subtitle} align={"center"} variant={"body1"} style={{
                    fontSize: "18px",
                    fontWeight: "400",
                    lineHeight: "30px",
                    letterSpacing: "0.01em",
                    marginBottom: "24px"
                }}>
                     <T key={"visibility_footer"} defaultVal={"Per maggiori informazioni sui piani clicca qui."}>pages.create_group.visibility_footer</T>
                </Typography>
                    */}
            </Box>
        )
    }

    renderVisibilityStep() {
        return (
            <Grid container item xs={12} justify={"center"} alignItems={"center"} spacing={2} className={this.props.classes.root}>
                {
                    this.state.serviceType.scopesAvailable.map((scope) => <Grid item xs={12} key={scope}>{this.renderScope(scope)}</Grid>)
                }
            </Grid>
        )
    }

    renderGroupSizeStep() {
        const service = this.state.service
        const maxSize = service.maxSize
        const options = [...Array(maxSize).keys()].map((n) => ({value: n + 1, label: `${n + 1}`}))
        return (
            <Grid container item xs={12} justify={"center"} alignItems={"center"} className={this.props.classes.root} spacing={2}>
                <Grid item xs={12}>
                    <Box display={"flex"} justifyContent={"center"} px={2}>
                        <ComboBox size={"big"} variant={"shadowed"} shape={"rounded"} label={"posti disponibili"} options={options} onChange={(e, val) => this.setGroupSize(val)}/>
                    </Box>
                </Grid>
            </Grid>
        )
    }

    renderTitle() {
        if (this.state.step === 2) {
            return <T key={"group_size_title"} defaultVal={"Posti disponibili"}>pages.create_group.group_size_title</T>
        }
        return <T key={"visibility_title"} defaultVal={"Visibilità"}>pages.create_group.visibility_title</T>
    }

    renderSubtitle() {
        if (this.state.step === 2) {
            return <T key={"group_size_subtitle"} defaultVal={"Segna il numero di persone, oltre a te che possono utilizzare il servizio."}>pages.create_group.group_size_subtitle</T>
        }
        return <T key={"visibility_subtitle"} defaultVal={"Chi può vedere il tuo gruppo?"}>pages.create_group.visibility_subtitle</T>
    }

    renderContent() {
        if (!this.state.loaded) {
            return <Progress/>
        }
        return this.renderStep()
    }

    render() {
        const layout = this.props.route.layout
        const isLoading = !this.state.loaded
        return (
            <Layout modal isLoading={isLoading}>
                {
                    isLoading ? null :
                        <>
                            <Title>{`Visibitità`}</Title>
                            <Scroll>
                                {this.renderContent()}
                            </Scroll>
                            <TextFooter
                                helpTitle={`Visibilità`}
                                help={
                                    <Help id={"summary"}>
                                        {`Riepilogo`}
                                    </Help>
                                }>
                                {`[Che garanzie ho nel darvi i dati?]({{ .args.Help}})`}
                            </TextFooter>
                        </>
                }
            </Layout>
        )
    }
}

export default withStyles(styles, {useTheme: true})(withRouter(withModals(withApi(withI18n(withModals(CreateGroup))))));
