import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from "../Typography/Typography";
import clsx from "clsx";
import Field from "../Field/Field";
import Button from "@material-ui/core/Button";
import TextField from "../TextField/TextField";
import PhoneInput from "../PhoneInput/PhoneInput";
import Form from "../../complex/Form/Form";
import Box from "@material-ui/core/Box";
import TextButton from "../Button/TextButton";

const styles = theme => {
  return {
    root: {
      "& .MuiFormLabel-root": {
        textTransform: "none",
        color: theme.palette.text.primary,
        fontSize: "16px",
        fontWeight: "600",
        lineHeight: "32px"
      },
      "& .MuiFormLabel-root.Mui-focused": {
        color: theme.palette.text.secondary
      },
      "& .MuiFormControl-root.Mui-error label": {
        color: theme.palette.error.main
      },
      "& .MuiFormControl-root.Mui-error label.Mui-focused": {
        color: theme.palette.text.secondary
      },
      "& .MuiFormControl-root.Mui-focused.Mui-error label": {
        color: theme.palette.text.primary
      }
    },
    actions: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      "& label": {
        padding: "8px"
      }
    },
    undo: {
      color: theme.palette.error.main
    },
    submit: {
      color: theme.palette.secondary.main,
      ".MuiFormControl-root.Mui-error + &": {
        color: theme.palette.error.main
      }
    },
    error: {
      color: theme.palette.error.main
    }
  };
};

function TFormField({
  onChange = val => {},
  ...props
}) {
  const {
    T,
    children,
    icon,
    classes,
    component,
    onClick,
    color = 'secondary',
    variant = 'button',
    label = '',
    renderValue,
    rules,
    inputComponentProps = {
      grid: {
        xs: 12,
        md: 12
      },
      box: {
        my: 0
      }
    },
    defaultValue = null,
    helperText = null,
    cancelText = 'cancel',
    editText = 'edit',
    name,
    isLoading,
    ...other
  } = props;
  const [open, setOpen] = React.useState(false);
  const [focused, setFocused] = React.useState(false);
  const [inputValue, setInputValue] = React.useState(defaultValue);
  const [form, setForm] = React.useState(null);
  const [isValid, setIsValid] = React.useState(null);
  let inputComponent = component;

  if (component == null) {
    inputComponent = props => <TextField variant={"standard"} color={"secondary"} {...props} />;
  }

  let inputRef = React.useRef();

  const triggerHandler = () => {
    setFocused(false);
    return form.trigger().then(({
      isValid
    }) => {
      setIsValid(isValid);
    });
  };

  return <div className={clsx(classes.root, isValid ? null : classes.error)} componentname="t-form-field">
        {!open ? <Field className={clsx({
      [classes.hidden]: open
    })} color={color} label={label} value={renderValue ? renderValue(inputValue) : inputValue} variant={variant} helperText={helperText} isLoading={isLoading} onClick={function () {
      if (onClick) {
        onClick();
        return;
      }

      setTimeout(() => inputRef.current.focus(), 0);
      setFocused(true);
      setOpen(true);
    }} /> : <div>
            <Form T={T} onSubmit={evt => {
        evt.preventDefault();
      }} defaultValues={{
        [name]: inputValue
      }} formRef={f => {
        if (f) {
          setForm(f);
        }
      }} grid={{
        xs: 12,
        md: 12
      }}>
                {React.createElement(inputComponent, {
          inputRef: inputRef,
          focused: focused,
          value: inputValue,
          onChange: (evt, val) => {
            setTimeout(() => inputRef.current.focus(), 0);
            setInputValue(val);
          },
          onBlur: triggerHandler,
          onFocus: () => {
            setFocused(true);
          },
          label: label,
          rules: rules,
          name: name,
          ...inputComponentProps
        })}
                <Box ml={-1} mt={3} className={classes.actions}>
                    <TextButton className={classes.undo} color={"error"} onClick={() => {
            setInputValue(defaultValue);
            setFocused(false);
            setOpen(false);
          }}>Annulla</TextButton>
                    <TextButton className={clsx(!focused && isValid === false ? classes.error : null, classes.submit)} onClick={() => triggerHandler().then(() => {
            if (isValid) {
              setOpen(false);
              setFocused(false);
              onChange(inputValue);
            }
          })}>Salva</TextButton>
                </Box>
            </Form>
        </div>}
    </div>;
}

export default withStyles(styles, {
  name: ""
})(TFormField);
