
// wsrpc.proto

// Code generated by protoc-gen-gotemplate. DO NOT EDIT.
// Browser mode: false


import * as wsrpc from './wsrpc_pb'
import {WebSocketClient} from '@ticketag/wsrpc-client/src/ws';


//wsrpc.eventBus.EmptyMsg
/**
* @typedef {Object} MessageEmptyMsg
* @property {eventBus.EmptyMsg} body this is description.
* @property {number} id ID messaggio.
* @property {string} methodName Nome metodo.
* @property {MsgFlags} flags Flag messaggio.
*/

//wsrpc.eventBus.Event
/**
* @typedef {Object} MessageEvent
* @property {eventBus.Event} body this is description.
* @property {number} id ID messaggio.
* @property {string} methodName Nome metodo.
* @property {MsgFlags} flags Flag messaggio.
*/


export class EventListenerClient {
        constructor(protocol, url, options={}, client) {
                this._client = client || new WebSocketClient(protocol, url, options)
                this._protocol = this._client.protocol;
                this._url = this._client.url;

                //ListenEvents (EventListener.ListenEvents)
                this._client.registerMessageDecoder("EventListener.ListenEvents",wsrpc.eventBus.Event); //.eventBus.Event
                this._client.registerMessageEncoder("EventListener.ListenEvents",wsrpc.eventBus.EmptyMsg); //name:"EmptyMsg"

                this._client.registerServerStream("EventListener.ListenEvents");

        }

        /**
        * Constructs a new EmptyMsg.
        * @memberof EventListenerClient
        * @classdesc Represents a EmptyMsg.
        * @implements eventBus.IEmptyMsg
        * @constructor
        * @param {eventBus.IEmptyMsg=} [properties] Properties to set
        */
        NewEmptyMsg(properties){
                return wsrpc.eventBus.EmptyMsg(properties)
        }

        /**
        * Constructs a new Event.
        * @memberof EventListenerClient
        * @classdesc Represents a Event.
        * @implements eventBus.IEvent
        * @constructor
        * @param {eventBus.IEvent=} [properties] Properties to set
        */
        NewEvent(properties){
                return wsrpc.eventBus.Event(properties)
        }


        // ----------------------------------------------

        /**
        * Send a message.
        * @memberof EventListenerClient
        * @param {eventBus.IEmptyMsg=} [inputVal] Properties to set
        * @return {Promise<MessageEvent>}
        */
        SendListenEvents(inputVal){
                return this._client.send("EventListener.ListenEvents",inputVal, null).promise;
        }

        OnListenEvents(func){
                this._client.addMessageHandler("EventListener.ListenEvents",func);
        }


}

export default {EventListenerClient, }
