import withStyles from "@material-ui/core/styles/withStyles";
import Header from "@ticketag/ticketag-uilib/src/components/base/Header/Header";
import {T} from "@ticketag/i18nlib";
import {withPage} from "../../hoc/Page/Provider";
import React from "react";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import Box from "@material-ui/core/Box";
import {withLayout} from "../../hoc/Layout/Layout";
import clsx from "clsx";
import {Skeleton} from "@material-ui/lab";

const defaultSubtitlePadding = 7

const styles = (theme) => {
    return {
        root: {

        },
        modal: {
            fontSize: "18px"
        },
    }
}

function TSubtitle(props) {
    const {
        children,
        layout,
        isLoading,
        skeletonWidth = 420,
        classes, T : tComponent,
        multiline = true, useMarkdown = true, useTemplate = true, args,
        align : alignProp,
        page,
        pages,
        subtitle,
        ...other
    } = props
    const align = alignProp || (layout.centered ? "center" : "left")
    const pageData = pages.getPageData(page)
    const subtitleData = pageData.subtitle || children
    const renderSubtitle = (data) => page && typeof data === "string" ?
        <T multiline={multiline} useTemplate={useTemplate} args={args} defaultVal={children}>{`pages.${page}.subtitle`}</T>
        : tComponent || data
    return (
        <Box pb={defaultSubtitlePadding}>
            <Typography
                className={clsx(classes.root, layout.modal ? classes.modal : null)}
                color={"textPrimary"} align={align} variant={"body2"} {...other}>
                {
                    isLoading ?
                        <Skeleton width={skeletonWidth} style={{margin: align === "center" ? "auto" : null }}/> :
                        renderSubtitle(subtitleData)
                }
            </Typography>
        </Box>
    )
}

export default withStyles(styles)(withLayout(withPage(TSubtitle)))