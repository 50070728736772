import DefaultLayout from "../../layouts/DefaultLayout/DefaultLayout";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import AppInstaller from "../../components/AppInstaller/AppInstaller";
import ErrorBoundary from "../Errors/ErrorBoundary";
import ModalLayout from "../../layouts/ModalLayout/ModalLayout";
import clsx from "clsx";
import LayoutContext from "./context";
import Box from "@material-ui/core/Box";
import Navbar from "../../components/Navbar/Navbar";
import * as PropTypes from "prop-types";
import {NarrowArrowIcon} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import {T} from "@ticketag/i18nlib";
import IconButton from "@ticketag/ticketag-uilib/src/components/base/Button/IconButton";
import Container from "@ticketag/ticketag-uilib/src/components/base/Container/Container";
import {withAuth} from "../Auth/Provider";
import {Hidden} from "@material-ui/core";
import Footer from "../../components/Footer/Footer";
import BottomNavigation from "../../components/Navbar/BottomNavigation/BottomNavigation";


const styles = (theme) => {
    return {
        root: {
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            "& main": {
                flexGrow: 1
            }
        },
        default: {
            "& main": {
                marginBottom: "50px",
                minHeight: "50vh"
            }
        },
        modal: {
            "& main": {
                [theme.breakpoints.up("sm")]: {
                    position: "absolute",
                    width: "100%",
                    top: "0",
                    height: "calc(100vh - 192px)",
                    margin: "96px 0",
                    display: "flex",
                }
            }
        },
        modalContainer: {
            display: "flex",
            flexDirection: "column",
            minHeight: "calc(100vh - 100px)"
        },
    }
}

class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.value = {
            getLayout: () => this.getLayout(),
            setLayout: (layout) => this.setLayout(layout)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        window.scrollTo(0, 0)
    }

    setLayout(layout) {
        const {
            fullWidth = false,
            modal = false,
            centered = layout.modal,
            onGoBack,
            onClose,
            title,
            logo,
            navbar
        } =layout
        this.setState({
            fullWidth: fullWidth,
            modal: modal,
            centered: centered,
            title: title,
            logo: logo,
            navbar: navbar,
            onGoBack: onGoBack,
            onClose: onClose,
        })
    }

    getLayout() {
        return this.state
    }

    render() {
        const {auth, children, classes, className} = this.props;
        const {
            isLoading,
            modal,
            centered,
            fullWidth,
            navbar,
            logo,
            title,
            onGoBack,
            onClose,
            ...other
        } = this.state;
        const userInfo = auth.getUserInfo()
        const isAuthenticated = auth.isAuthenticated()
        return (
            <LayoutContext.Provider value={this.value}>
                <AppInstaller showBanner={!modal}>
                    <Box className={clsx(classes.root, modal ? classes.modal : classes.default, className)}>
                        {
                            navbar || <Navbar modal={modal} onGoBack={onGoBack} onClose={onClose} logo={logo} title={title}/>
                        }
                        <main className={classes.main}>
                            <Container className={modal ? classes.modalContainer : null} fullWidth={fullWidth}>
                            <ErrorBoundary>
                                {
                                    children
                                }
                            </ErrorBoundary>
                            </Container>
                        </main>
                        {
                            modal?
                                null
                                :
                                isAuthenticated ?
                                    <>
                                        <Hidden smDown>
                                            <Footer/>
                                        </Hidden>
                                        <Hidden smUp>
                                            {
                                                isAuthenticated && userInfo.complete ?
                                                    <BottomNavigation id={"bottom-navigation"}/> : null
                                            }
                                        </Hidden>
                                    </>
                                    :
                                    <Footer/>
                        }
                    </Box>
                </AppInstaller>
            </LayoutContext.Provider>
        )
    }

}

export const withLayout = (Component) => {
    return ({ref, ...props}) => {
        return (
            <LayoutContext.Consumer>
                {({getLayout, setLayout}) => {
                    const layout = getLayout()
                    return <Component ref={ref} layout={layout} setLayout={setLayout} {...props}/>
                }}
            </LayoutContext.Consumer>
        )
    }
}

export default withAuth(withStyles(styles)(Layout));