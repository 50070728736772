import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CheckoutCard from "../CheckoutCard/CheckoutCard";

const styles = theme => {
  return {
    footer: {
      marginTop: 8
    },
    button: {
      marginTop: 0
    }
  };
};

function NewGroupCard(props) {
  const {
    children,
    icon,
    classes,
    onClick,
    header = null,
    title = null,
    ...other
  } = props;
  return <CheckoutCard title={title} header={header} icon={icon} onClick={onClick} classes={{ ...classes
  }} {...other} componentname="new-group-card">
            {children}
    </CheckoutCard>;
}

export default withStyles(styles, {
  name: "NewGroupCard"
})(NewGroupCard);
