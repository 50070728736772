import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from "clsx";
import { CardActionArea, CardHeader } from "@material-ui/core";
import TextField from "../../base/TextField/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "../../base/Typography/Typography";
import Box from "@material-ui/core/Box";
import { Icon, MoreVertIcon, SendIcon } from "../../base/Icon/Icon";
import IconButton from "@material-ui/core/IconButton";
import Menu from "../../base/Menu/Menu";
import InputAdornment from "@material-ui/core/InputAdornment";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import Dialog from "../../hoc/Modals/Dialog"; // We can inject some CSS into the DOM.

const styles = theme => ({
  root: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      boxShadow: "0px 0px 11px rgba(0, 0, 0, 0.08), 5px 5px 16px rgba(172, 172, 172, 0.1)",
      borderRadius: "16px"
    },
  },
  mobile: {
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "100%",
    "& .MuiCardContent-root": {
      height: "calc(100% - 180px)"
    }
  },
  cardHeader: {
    padding: "0",
    display: "flex",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)"
  },
  cardHeaderContent: {
    display: "flex",
    alignItems: "center",
    minWidth: "0",
    padding: "12px 6px",
    [theme.breakpoints.up("sm")]: {
      padding: "12px 32px"
    },
    "& > h3": {
      paddingBottom: "8px"
    },
    "& > img": {
      paddingRight: "16px"
    }
  },
  cardContent: {
    paddingTop: "16px",
    paddingBottom: "8px",
    overflowX: "hidden",
    overflowY: "scroll",
    padding: "0",
    [theme.breakpoints.up("sm")]: {
      height: "420px"
    },
    "& > h6": {
      textAlign: "center",
      fontSize: "16px",
      fontWeight: "700"
    },
    "& > div:not(:last-child)": {
      marginBottom: "20px"
    }
  },
  title: {
    fontSize: "24px",
    fontWeight: "600",
    flexGrow: "2"
  },
  avatar: {
    height: "60px!important",
    width: "60px!important"
  },
  backIcon: {
    height: "20px!important",
    width: "20px!important"
  },
  bottomTextField: {
    position: "fixed",
    bottom: "0",
    left: "0",
    width: "calc(100% - 32px)",
    backgroundColor: "white",
    boxShadow: "0px 2px 6px #FFFFFF, 0px 1px 15px rgba(0, 0, 0, 0.15), inset 0px 1px 4px #F3F2F2"
  },
  skeleton: {
    transform: "none"
  }
});

function ChatMenu(props) {
  const {
    onSelect = () => {},
    actions = {},
    rotated = false,
    ...other
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return <React.Fragment>
            <IconButton aria-label="settings" style={rotated ? {
      transform: "rotate(90deg)"
    } : null} onClick={handleClick}>
                <MoreVertIcon color={"secondary"} />
            </IconButton>
            <Menu keepMounted anchorEl={anchorEl} getContentAnchorEl={null} anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }} transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }} open={Boolean(anchorEl)} onClose={handleClose}>
                {actions}
            </Menu>
        </React.Fragment>;
}

function TChatScreen(props) {
  const {
    classes,
    isLoading,
    children,
    avatar,
    title,
    className,
    menuActions,
    fromAdmin = null,
    onSelectMenu = () => {},
    onClose = () => {},
    onSubmit = () => {},
    ...other
  } = props;

  const [open, setOpen] = React.useState(true)
  const theme = useTheme();
  const mobile = !useMediaQuery(theme.breakpoints.up('sm'));
  const inputRef = React.createRef();

  const onTextFieldKeyUp = evt => {
    if (evt.keyCode === 13 && !fromAdmin) {
      const value = inputRef.current.value;

      if (value) {
        onSubmit(evt, value);
        inputRef.current.value = "";
      }
    }
  };

  const handleSendClick = evt => {
    const value = inputRef.current.value;

    if (value) {
      onSubmit(evt, value);
      inputRef.current.value = "";
    }
  };

  const handleCloseClick = evt => {
    setOpen(false)
    onClose(evt);
  };

  const handleSelectMenu = evt => {
    onSelectMenu(evt);
  };

  const chatHeader = content => {
    return <CardHeader disableTypography classes={{
      root: classes.cardHeader,
      content: classes.cardHeaderContent
    }} title={content} />;
  };

  if (isLoading) {
    if (mobile) {
      return <div/>
    }
    return <Skeleton className={clsx(classes.root, mobile ? classes.mobile : null, classes.skeleton)} height={580} />;
  }

  const Component = mobile ?
      (props) => <Dialog transitionDuration={0} className={classes.mobile} open={open} size={"md"} {...props}/>
      :
      (props) => <Card className={clsx(classes.root, mobile ? classes.mobile : null)} {...props}/>

  return <Component componentname="t-chat-screen">
            {chatHeader(
                mobile ?
                <React.Fragment>
                  <IconButton onClick={handleCloseClick}>
                    <Icon classes={{
                      root: classes.backIcon
                    }}>back</Icon>
                  </IconButton>
                  {avatar}
                  <Typography className={classes.title} variant={"h3"}>{title}</Typography>
                  {menuActions ? <ChatMenu rotated actions={menuActions} onSelect={handleSelectMenu} /> : null}
                </React.Fragment>
                :
                <React.Fragment>
                  <Typography className={classes.title} variant={"h3"}>{title}</Typography>
                  {menuActions ? <ChatMenu actions={menuActions} /> : null}
                </React.Fragment>
            )}
            <CardContent id={"chat-content"} classes={{
      root: classes.cardContent
    }}>{children}</CardContent>
            <Box className={mobile ? classes.bottomTextField : null} p={2}>
                <TextField fullWidth inputRef={inputRef} variant={"outlined"} color={"secondary"} shape={"rounded"} InputProps={{
        endAdornment: <InputAdornment position="end">
                                <IconButton onClick={handleSendClick}>
                                    <SendIcon style={{fill: "transparent"}}/>
                                </IconButton>
                            </InputAdornment>
      }} inputProps={{
        onKeyUp: onTextFieldKeyUp
      }} multiline={fromAdmin != null}/>
            </Box>
  </Component>;
}

export default withStyles(styles, {
  useTheme: true
})(TChatScreen);
