import React, {Component} from 'react';
import {withI18n} from "@ticketag/i18nlib";
import {withApi} from "../Api/Provider";
import {withRouter} from "react-router-dom";
import {MetaTags} from "react-meta-tags";
import SeoContext from "./context";
import {Helmet} from "react-helmet";
import {withRoutes} from "../Routes/Provider";

const pages = [
    {
        regex:"/[a-z]{2}-[A-Z]{2}/services$",
        name:"services"
    },
    {
        regex:"/[a-z]{2}-[A-Z]{2}/groups$",
        name:"groups"
    },
    {
        regex:"/[a-z]{2}-[A-Z]{2}/chat",
        name:"chat"
    },
    {
        regex:"/[a-z]{2}-[A-Z]{2}/wallet$",
        name:"wallet"
    },
    {
        regex:"/[a-z]{2}-[A-Z]{2}/$",
        name:"homepage"
    },
    {
        regex:"/[a-z]{2}-[A-Z]{2}/terms-conditions$",
        name:"terms_conditions"
    },
    {
        regex:"/[a-z]{2}-[A-Z]{2}/privacy-policy$",
        name:"privacy_policy"
    },
    {
        regex:"/[a-z]{2}-[A-Z]{2}/faq$",
        name:"faq"
    },
    {
        regex:"/[a-z]{2}-[A-Z]{2}/welcome",
        name:"welcome"
    },
    {
        regex: "/[a-z]{2}-[A-Z]{2}/invite/(.)+",
        name: "invite"
    },
    {
        regex: "/[a-z]{2}-[A-Z]{2}/profile",
        name: "profile"
    },
    {
        regex:"/[a-z]{2}-[A-Z]{2}/services/(.)+/create",
        name:"create_service"
    },
    {
        regex:"/[a-z]{2}-[A-Z]{2}/groups/(.)+/create$",
        name:"setup_service"
    },
    {
        regex: "/[a-z]{2}-[A-Z]{2}/groups/(.)+/create/credentials",
        name: "credentials"
    },
    {
        regex: "/[a-z]{2}-[A-Z]{2}/groups/(.)+/create/summary",
        name: "complete_group"
    },
    {
        regex: "/[a-z]{2}-[A-Z]{2}/groups/(.)+/create/success",
        name: "complete_group_success"
    },
    {
        regex:"/[a-z]{2}-[A-Z]{2}/services/(.)+/join$",
        name:"join_service"
    },
    {
        regex:"/[a-z]{2}-[A-Z]{2}/services/(.)+/join/(.)+",
        name:"join_choose"
    },
    {
        regex: "/[a-z]{2}-[A-Z]{2}/groups/(.)+/join/payment$",
        name: "join_payment"
    },
    {
        regex: "/[a-z]{2}-[A-Z]{2}/groups/(.)+/join/summary",
        name: "complete_join"
    },
    {
        regex: "/[a-z]{2}-[A-Z]{2}/groups/(.)+/join/success",
        name: "join_success"
    },
    {
        regex: "/[a-z]{2}-[A-Z]{2}/groups/(.)+/join/processing",
        name: "join_processing"
    },
    {
        regex: "/[a-z]{2}-[A-Z]{2}/groups/(.)+/join/fail",
        name: "join_failed"
    },
    {
        regex:"/[a-z]{2}-[A-Z]{2}/services/[^\/]+$",
        name:"join_create"
    },
    {
        regex:"/[a-z]{2}-[A-Z]{2}/groups/(.)+/details",
        name: "group_details"
    }
]

const data = {
    default:{
        title:"diveedi",
        description:"diveedi",
        index:false
    },
    homepage:{
        title:"Diveedi Home",
        description:"",
        index:true
    },
    services:{
        title:"diveedi services",
        description:"diveedi",
        index:true
    },
    groups:{
        title:"Gruppi",
        description:"I tuoi gruppi",
        index:false
    },
    wallet:{
        title:"Wallet",
        description:"Wallet",
        index:false
    },
    chat:{
        title:"Chat",
        description:"Chat",
        index:false
    },
    terms_conditions:{
        title:"Termini e condizioni",
        description:"Termini e condizioni",
        index:false
    },
    privacy_policy:{
        title:"Privacy Policy",
        description:"Privacy Policy",
        index:false
    },
    faq:{
        title:"FAQ | diveedi",
        description:"Domande frequenti",
        index:true
    },
    welcome:{
        title:"Welcome",
        description:"Welcome page",
        index:false
    },
    invite:{
        title:"Sei stato invitato",
        description:"sei stato invitato ad entrare nel gruppo",
        index:false
    },
    profile:{
        title:"Account",
        description:"account",
        index:false
    },
    create_service:{
        title:"crea il tuo gruppo",
        description:"crea il tuo gruppo",
        index:true
    },
    setup_service:{
        title:"crea il tuo gruppo",
        description:"crea il tuo gruppo",
        index:false
    },
    credentials:{
        title:"credenziali",
        description:"inserisci le credenziali del gruppo",
        index:false
    },
    complete_group:{
        title:"riepilogo",
        description:"crea il tuo gruppo",
        index:false
    },
    complete_group_success:{
        title: "gruppo creato con successo",
        description: "creazione avventua",
        index:false
    },
    join_service:{
        title:"seleziona service type",
        description:"seleziona service type",
        index:true,
    },
    join_payment:{
        title: "pagamento",
        description:"pagamento",
        index:false
    },
    complete_join:{
        title:"riepilogo",
        description:"crea il tuo gruppo",
        index:false
    },
    join_success:{
        title:"success",
        description:"success",
        index:false
    },
    join_processing:{
        title:"processing",
        description:"processing",
        index:false
    },
    join_failed:{
        title:"fail",
        description:"fail",
        index:false
    },
    join_create:{
        title:"join or create",
        description:"join or create a group"
    },
    join_choose:{
        title:"Seleziona il gruppo",
        description:"Seleziona il gruppo nel quale vorresti entrare"
    },
    group_details:{
        title: "Dettaglio gruppo",
        description: "Qui trovi tutte le informazioni riguardo il tuo gruppo"
    }
}

const flattenRoutes = (routes) => {
    const flattenFn = (r) => {
        let flattenRoutes = [];
        r.forEach((route) => {
            flattenRoutes.push(route)
            flattenRoutes.push(...flattenFn(route.routes || []))
        })
        return flattenRoutes
    }
    return flattenFn(routes)
}

class SeoProvider extends Component {
    constructor(props) {
        super(props);
        this.data=data.default;
        this.page="default";
        this.values="";
        this.state={
            loaded:false,
            values: {}
        }
    }
    componentDidMount() {
        const {i18n} = this.props;
        const routes = flattenRoutes(this.props.routes).filter((route) => !!route.name)
        const seo = routes.reduce((acc, route) => {

        }, {})

        this.setState({routes: routes})


        const promises = routes.map((route) => {
            const name = typeof route.name === "function" ?
                route.name({match: this.props.match}) : route.name
            console.log(`seo.${name}.title`)
            i18n.getValue(`seo.${name}.title`).then((resp) => {
                values.push(resp)
            })
        })
        Promise.all(promises).then(() => {
            console.log(values)
        })
    }

    renderTitle() {
        return
    }

    getPage(){
        const {location} = this.props
        pages.forEach(p =>{
            const reg = new RegExp(p.regex);
            if (reg.test(location.pathname)){
                this.data=data[p.name];
                this.page=p.name;
            }
        })
    }
    getValues(){
        const {i18n} =this.props
        let values={};
        values["index"]=this.data.index;
        let promises=[i18n.getValue(`seo.${this.page}.title`,this.data.title).then(resp => {
            values.title=resp;
            return resp
        }),i18n.getValue(`seo.${this.page}.description`,this.data.description).then(resp => {
            values.description=resp;
            return resp
        })
        ]
        values.opengraph=[]
        if (this.data.opengraph!==undefined){
            this.data.opengraph.forEach((tag)=>{
                promises.push(i18n.getValue(`seo.${this.page}.og_${tag.name}`,tag.value).then(resp =>{
                    values.opengraph.push({name:tag.name,value:resp})
                }))
            })
        }
        Promise.all(promises).then(()=>{
            this.setState({loaded: true, values: values})
        })
    }
    isIOs() {
        const ua = window.navigator.userAgent;
        const isIPad = !!ua.match(/iPad/i);
        const isIPhone = !!ua.match(/iPhone/i);
        return isIPad || isIPhone;
    }
    renderTags(){
        return (this.state.loaded?
            <Helmet>
            <title>{this.state.values.title}</title>
            <meta name="description" content={this.state.values.description} />
            {this.state.values.index?null:(<meta name={"robots"} content={"noindex"}/>)}
            {
                this.state.values.opengraph.map((val)=>{
                    return (<meta property={`og:${val.name}`} content={val.value}/>)
                })
            }
            {
                this.isIOs() ?
                    <>
                        <meta name="apple-mobile-web-app-status-bar-style" content="black"/>
                        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                    </> : null

            }
        </Helmet>:
            null
        )
    }
    render(){
        return(
            <SeoContext.Provider value={{
            }}>
                {this.renderTags()}
                {this.props.children}
            </SeoContext.Provider>)
    }
}

export const withSeo = (Component) => {
    return ({ref, ...props}) => (<SeoContext.Consumer>
        {(seo)=>{
            return <Component ref={ref} seo={seo} {...props}/>
        }
        }
    </SeoContext.Consumer>)
}

export default withRoutes(withRouter(withI18n(withApi(SeoProvider))));
