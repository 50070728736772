import React from "react";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import {T} from "@ticketag/i18nlib";
import ImageCard from "@ticketag/ticketag-uilib/src/components/complex/ImageCard/ImageCard";
import withStyles from "@material-ui/core/styles/withStyles";
import {VideoIcon, MusicIcon, BookIcon, DocumentIcon, GamingIcon, FitnessIcon, SportIcon, GiftIcon} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";
import Box from "@material-ui/core/Box";
import Carousel from "@ticketag/ticketag-uilib/src/components/complex/Carousel/Carousel";
import {Skeleton} from "@material-ui/lab";
import * as PropTypes from "prop-types";
import clsx from "clsx";


const styles = (theme) => {
    return {
        root: {
            marginBottom: "48px",
            [theme.breakpoints.up('sm')]: {
                fontSize: "80px",
            },
        },
        title: {
            fontSize: "24px",
            [theme.breakpoints.up('sm')]: {
                fontSize: "40px",
                lineHeight: "48px"
            },
        },
        icon: {
            marginRight: "16px",
            fontSize: "24px",
            [theme.breakpoints.up('sm')]: {
                fontSize: "40px"
            },
        },
        carousel: {
            [theme.breakpoints.down("sm")]: {
                height: "236px"
            },
            marginLeft: "-30px",
            "& > ul": {
                paddingLeft: "40px"
            }
        },
        row: {
            height: "240px",
            margin: "-32px 0",
            [theme.breakpoints.up("sm")]: {
                marginLeft: "8px"
            }
        },
        gridItem: {
            flex: "1 0 23.3333333%",
            height: "160px",
            transition: "flex 0.3s ease, max-width 0.3s ease, height .3s ease",
        },
        hover: {
            height: "192px",
            maxWidth: "30%",
            flexBasis: "30%",
        },
        skeleton: {
            borderRadius: "16px"
        }
        /*
        card: {
            boxShadow: "-10px 10px 30px rgba(172, 172, 172, 0.1)," +
                " 10px -10px 35px rgba(172, 172, 172, 0.1)," +
                " -10px -10px 35px rgba(255, 255, 255, 0.9)," +
                " 0px 10px 30px rgba(0, 0, 0, 0.25)"
        }
         */
    }
}

const icons = {
    "video": VideoIcon,
    "music": MusicIcon,
    "reading": BookIcon,
    "gaming": GamingIcon,
    "fitness": FitnessIcon,
    "sport": SportIcon,
    "productivity": DocumentIcon,
    "membership": GiftIcon,
}


function ServiceSectionItem(props) {
    const [hover, setHover] = React.useState(false)
    const handleMouseEnter = () => {
        setHover(true)
    }
    const handleMouseLeave = () => {
        setHover(false)
    }
    return <Grid item xs={4} lg={3} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
                 className={clsx(props.classes.gridItem, hover ? props.classes.hover : null)}>
        <ImageCard shadow={"desktop"} className={props.classes.card} logo={props.elt.whiteLogo} img={props.elt.cardImage}
                   onClick={props.onClick}/>
    </Grid>;
}

ServiceSectionItem.propTypes = {
    classes: PropTypes.any,
    elt: PropTypes.any,
    onClick: PropTypes.func
};

function TServiceSection(props) {
    const {
        classes,
        isLoading,
        api,
        i18n,
        history,
        tag,
        ...other
    } = props;
    const [services, setServices] = React.useState(null)
    const theme = useTheme();
    const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const renderRow = (row, i) => {
        return row.map((elt) => (
                <ServiceSectionItem key={i} classes={classes} elt={elt} onClick={handleClick(elt)}/>
        ))
    }

    function getSections(rows, cols) {
        return [...Array(rows).keys()].reduce((acc, i) => {
            return [...acc, [...services].slice(i * cols, Math.min((i + 1) * cols, services.length))]
        }, []);
    }

    const renderSections = () => {
        const cols = isDesktop ? 4 : 3
        const rows = Math.ceil(services.length / cols)
        const sections = getSections(rows, cols)
        return sections.map((row, i) => (
            <Grid item key={i} container spacing={1} alignItems={"center"} className={classes.row}>
                {renderRow(row)}
            </Grid>)
        )
    }

    React.useEffect(() => {
        if (!isLoading) {
            api.GetServiceFamilies(true, {tags: [tag.key],  _public: true}).
            then((services) => {
                setServices(services)
            })
        }
    }, []);
    const Icon = tag ? icons[tag.key] : null
    const handleClick = (elt) => (evt) => {
        history.push(i18n.buildLocalizedUrl("services", elt.slug, "join"))
    }
    const loaded = !isLoading && services !== null
    return (
        <Grid item container xs={12} className={classes.root}>
            <Grid item xs={12}>
                <Box mb={{xs: 3, md: 4}} display={"flex"} alignItems={"center"}>
                    {   loaded ?
                        (Icon ? <Icon className={classes.icon} color={"textSecondary"}/> : null) :
                        <Skeleton variant={"circle"} width={isMobile ? 28 : 48} className={classes.icon}/>
                    }
                    <Typography isLoading={!loaded} variant={"h4"} className={classes.title} component={"h2"}>
                        {tag ?
                            <T defaultVal={tag.title}>{`pages.services.${tag.key}_section_title`}</T> : null
                        }
                    </Typography>
                </Box>
            </Grid>
            <Grid item container xs={12} spacing={2}>
                {
                    isMobile ?
                        <Box display={"flex"} alignItems={"center"}>
                        <Carousel
                            className={classes.carousel}
                            scaling={1.2}
                            isLoading={!loaded}
                            spacing={8}
                            buttonWidth={0}
                            height={120}
                            cols={3.2}
                            fluid
                        >
                            {services ? services.map((elt, i) =>
                                <ImageCard shadow={"mobile"} className={classes.card}  logo={elt.whiteLogo} img={elt.cardImage} onClick={handleClick(elt)}/>
                            ) : null
                            }
                        </Carousel>
                        </Box>
                        :
                        !loaded ?
                            [...Array(isDesktop ? 4 : 3).keys()].map((i) => (
                                <Grid item key={i} xs={4} lg={3} className={classes.gridItem}>
                                    <Skeleton className={classes.skeleton} variant={"rect"} width={"100%"} height={160}/>
                                </Grid>
                            )) :
                            renderSections()
                }
            </Grid>
        </Grid>
    );
}

export default withStyles(styles)(TServiceSection)
