/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * The WebapiprotoServiceData model module.
 * @module model/WebapiprotoServiceData
 * @version v0.4
 */
export class WebapiprotoServiceData {
  /**
   * Constructs a new <code>WebapiprotoServiceData</code>.
   * @alias module:model/WebapiprotoServiceData
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>WebapiprotoServiceData</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/WebapiprotoServiceData} obj Optional instance to populate.
   * @return {module:model/WebapiprotoServiceData} The populated <code>WebapiprotoServiceData</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new WebapiprotoServiceData();
      if (data.hasOwnProperty('relation_type'))
        obj.relationType = ApiClient.convertToType(data['relation_type'], 'String');
      if (data.hasOwnProperty('join_attributes'))
        obj.joinAttributes = ApiClient.convertToType(data['join_attributes'], ['String']);
      if (data.hasOwnProperty('join_terms'))
        obj.joinTerms = ApiClient.convertToType(data['join_terms'], ['String']);
      if (data.hasOwnProperty('create_attributes'))
        obj.createAttributes = ApiClient.convertToType(data['create_attributes'], ['String']);
      if (data.hasOwnProperty('create_terms'))
        obj.createTerms = ApiClient.convertToType(data['create_terms'], ['String']);
    }
    return obj;
  }
}

/**
 * @member {String} relationType
 */
WebapiprotoServiceData.prototype.relationType = undefined;

/**
 * @member {Array.<String>} joinAttributes
 */
WebapiprotoServiceData.prototype.joinAttributes = undefined;

/**
 * @member {Array.<String>} joinTerms
 */
WebapiprotoServiceData.prototype.joinTerms = undefined;

/**
 * @member {Array.<String>} createAttributes
 */
WebapiprotoServiceData.prototype.createAttributes = undefined;

/**
 * @member {Array.<String>} createTerms
 */
WebapiprotoServiceData.prototype.createTerms = undefined;


