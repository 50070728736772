import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T, withI18n} from "@ticketag/i18nlib";
import Progress from "@ticketag/ticketag-uilib/src/components/base/Progress/Progress";
import {withApi} from "../../hoc/Api/Provider";
import AuthContext from "../../hoc/Auth/context";
import Grid from "@material-ui/core/Grid";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import Form from "@ticketag/ticketag-uilib/src/components/complex/Form/Form";

import Box from "@material-ui/core/Box";
import Link from "@ticketag/ticketag-uilib/src/components/base/Link/Link";
import TextField from "@ticketag/ticketag-uilib/src/components/base/TextField/TextField";
import {Divider} from "@material-ui/core";
import {withRouter, Link as RouterLink} from "react-router-dom";
import Logo from "../../components/Logo/Logo";

import Button from "@ticketag/ticketag-uilib/src/components/base/Button/Button";
import {WebapiprotoInviteType as INVITE_TYPE} from "@ticketag/diveedi-client/src/model/WebapiprotoInviteType";
import TextButton from "@ticketag/ticketag-uilib/src/components/base/Button/TextButton";
//import copy from "copy-to-clipboard";
import Tooltip from "@material-ui/core/Tooltip";
import clsx from "clsx";
import {withRedirect} from "../ErrorMessage/ErrorMessage";
import {Icon, SendIcon} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";
import {Skeleton} from "@material-ui/lab";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Alert from "@ticketag/ticketag-uilib/src/components/base/Alert/Alert"
import Header from "@ticketag/ticketag-uilib/src/components/base/Header/Header";
import Breadcrumbs from "@ticketag/ticketag-uilib/src/components/base/Breadcrumbs/Breadcrumbs";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Layout from "../../hoc/Layout/Layout";
import Navbar from "../Navbar/Navbar";
import NavTitle from "../Navbar/NavTitle/NavTitle";
import Title from "../Title/Title";
import {pages} from "../../constants";
import Page from "../Page/Page";

const styles = (theme) => {
    return {
        root: {},
        contentBox: {
            width: '325px'
        },
        title: {
            fontWeight: "600",
            fontSize: "42px"
        },
        headerIcon: {
            height: '50px',
            marginRight: '8px'
        },
        subtitle: {
            fontWeight: "300",
            fontSize: "16px"
        },
        avatar: {
            width: "60px",
            height: "60px",
            borderRadius: "50%"
        },
        goToGroup: {
            lineHeight: "24px",
            fontSize: "18px",
            marginTop: "16px",
            //textDecoration: "none!important",
            color: theme.palette.secondary.main + "!important",
            "& svg": {
                opacity: "0.8",
                marginBottom: "-2px",
                marginLeft: "8px"
            }
        },
        goToGroupLink: {
            display: "flex",
            [theme.breakpoints.down("sm")]: {
                width: "100%",
                justifyContent: "center",
                "& a": {
                    marginRight: "8px"
                }

            },
            [theme.breakpoints.up("sm")]: {
                flexDirection: "column",
                alignItems: "flex-end",
                "&:hover a": {
                    textDecoration: "underline"
                },
                "&:hover::after": {
                    content: "''",
                    borderBottom: `1px solid ${theme.palette.secondary.main}`,
                    position: "absolute",
                    marginTop: "40px",
                    width: "24px"
                }
            },
        },
        dividerBox: {
            display: "flex",
            alignItems: "center",
            margin: "40px 0",
            [theme.breakpoints.up("sm")]: {
                margin: "40px 0",
            },
            "& p": {
                color: "rgba(73, 88, 111, 0.8)",
                margin: "0 12px"
            },
            "& hr": {
                backgroundColor: "#E0E0E0",
                flexGrow: "2"
            }
        },
        copyLink: {
            fontWeight: "400"
        }
    }
};

class InvitePage extends Component {
    static contextType = AuthContext

    constructor(props) {
        super(props);
        this.state = {
            isLoaded:false,
            hasClipboard: false
        }
    }
    componentDidMount() {
        this.loadData()
    }

    loadTranslations(){
        return Promise.all([
            this.props.i18n.getValue("pages.invite.share_title","Invito"),
            this.props.i18n.getValue("pages.invite.share_text","Sei stato invitato a partecipare ad un gruppo su diveedi!")
        ]).then(([shareTitle,shareText])=>{
            return {shareTitle:shareTitle,shareText:shareText}
        })
    }
    loadData() {
        withRedirect(this.props.history, Promise.all([this.loadInvitationToken(),this.loadTranslations()]).then(([token,translations]) => {
                this.setState({
                    isLoaded: true,
                    family: this.props.group.serviceFamily,
                    group: this.props.group,
                    inviteToken: token.token,
                    shareTranslations:translations
                })
        }))
    }

    loadGroupDetails() {
        return this.props.api.GetGroupDetails(this.props.match.params.groupUuid)
    }
    loadInvitationToken() {
        return this.props.api.groupApi.createInvitationToken(this.props.match.params.groupUuid, INVITE_TYPE.link, {
            baseUrl: window.location.hostname,
        })
    }

    onSendEmail(evt) {
        evt.preventDefault()
        this.form.trigger().then((val) => {
            if (this.form.isValid) {
                const mail = this.form.getValues("mail")
                this.setState({alert: null})
                this.props.api.groupApi.createInvitationToken(this.props.match.params.groupUuid, INVITE_TYPE.mail, {
                    baseUrl: window.location.hostname,
                    mailAddress: mail
                }).then(() => {
                    this.setState({alert: {success: true, text: "mail inviata con successo"}})
                }).catch(() => {
                    this.setState({alert: {success: false, text: "errore!"}})
                })
            }
        })
    }

    onCopyLink(evt) {
        evt.preventDefault();
        if (navigator.canShare && navigator.canShare({url:evt.currentTarget.href})){
            navigator.share(
                {
                    url:evt.currentTarget.href,
                    title:this.state.shareTranslations.shareTitle,
                    text:this.state.shareTranslations.shareText
                }
            )
        }else{
            navigator.clipboard.writeText(evt.currentTarget.href).then(() => {
                this.setState({hasClipboard: true})
                setTimeout(() => {
                    this.setState({hasClipboard: false})
                }, 2000)
            })
        }
    }

    renderSkeleton() {
        return (
            <Grid container justify={"center"}>
                <Grid item xs={12} md={4}>
                    <Box pt={8} display="flex" justifyContent={'flex-start'}>
                        <Box mr={3}>
                            <Skeleton width={90} height={110}/>
                        </Box>
                        <Box display="flex" flexDirection="column" justifyContent="space-around">
                            <Skeleton height={20} width={80}/>
                            <Skeleton height={40} width={120}/>
                        </Box>
                    </Box>
                    <Box>
                        <Skeleton height={2}/>
                    </Box>
                    <Box display="flex" pb={12} justifyContent={'flex-start'}>
                        <Box mr={3}>
                            <Skeleton width={90} height={110}/>
                        </Box>
                        <Box display="flex" flexDirection="column" justifyContent="space-around">
                            <Skeleton height={30} width={100}/>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        )
    }

    renderContent() {
        if (!this.state.isLoaded) {
            return this.renderSkeleton()
        }
        const {
            classes,
            title = 'Invita un amico!',
            modal,
            ...other
        } = this.props;
        const inputRef = React.createRef()
        const group = this.state.group
        const family = this.state.family
        const groupDetailsUrl = this.props.i18n.buildLocalizedUrl('groups', group.uuid, 'details')
        const inviteUrl = this.props.i18n.buildLocalizedUrl('invite', this.state.inviteToken)
        const canShare = navigator.canShare && navigator.canShare({url:inviteUrl})
        return <Grid container justify={"center"}>
            <Grid item xs={12} md={4}>
                { this.state.alert ? <Alert severity={this.state.alert.success ? "success" : "error"}>{this.state.alert.text}</Alert> : null}
                <Box pt={4} display="flex" justifyContent={'flex-start'} classes={{root: this.props.classes.contentBox}}>
                    {/*{serviceName ? <Icon classes={{root: classes.icon}} size='large'>{serviceName}</Icon> : null}*/}
                    <Box mr={3}>
                        <img src={`${process.env.PUBLIC_URL||""}/img/icons/invite/invite_friend.svg`}/>
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="space-around">
                        <Typography color="gradient" variant="h6" semiBold><T defaultVal="Invita un amico!">pages.invite.invite_friend</T></Typography>
                        <Form
                            formRef={(f) => {this.form = f}}
                            fullWidth
                            onSubmit = {(evt) => {this.onSendEmail(evt)}}>
                            <TextField
                                inputRef={inputRef}
                                grid={{md: 12}}
                                rules={{
                                    email: {value: true, message: "inserisci una mail valida"},
                                }}
                                variant={"outlined"} color={"secondary"} shape={"rounded"}
                                name={"mail"}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><IconButton onClick={(evt) => this.onSendEmail(evt)}><SendIcon style={{fill: "transparent"}} color={"secondary"}/></IconButton></InputAdornment>
                                }}

                                placeholder="Invita qualcuno..."
                            />
                        </Form>
                    </Box>
                </Box>
                <Box className={classes.dividerBox}>
                    <Divider/><Typography><T defaultVal={"Oppure"}>global.word.either</T></Typography><Divider/>
                </Box>
                <Box display="flex" pb={12} justifyContent={'flex-start'} classes={{root: this.props.classes.contentBox}}>
                    {/*{serviceName ? <Icon classes={{root: classes.icon}} size='large'>{serviceName}</Icon> : null}*/}
                    <Box mr={3}>
                        <img src={`${process.env.PUBLIC_URL||""}/img/icons/invite/copy_link.svg`}/>
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="space-around">
                        <Tooltip title={<T defaultVal={"Copiato!"}>global.clipboard.copied</T>} open={this.state.hasClipboard} enterDelay={500} leaveDelay={200} placement={"right"} className={classes.tooltip}>
                            <Link href={inviteUrl} className={classes.copyLink} onClick={(evt) => this.onCopyLink(evt)} color="secondary" size="large" variant="h4">
                                {
                                    canShare?
                                        <T defaultVal={"Condividi"}>pages.invite.share</T>
                                        :
                                        <T defaultVal={"Copia il link"}>pages.invite.copy</T>
                                }
                            </Link>
                        </Tooltip>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    }

    renderNavbar() {
        const serviceFamily = this.props.group.serviceFamily
        const isLoading = !this.state.isLoaded
        if (isLoading) {
            return <Navbar logo/>
        }
        return <Navbar
            useTemplate
            args={{Name: serviceFamily.name }}
            logo={serviceFamily.logo}
            title={this.props.layout.title}
        />
    }

    render() {
        const isLoading = !this.state.isLoaded
        const serviceFamily = this.state.family
        const groupDetailsUrl = this.props.i18n.buildLocalizedUrl('groups', this.props.group.uuid, 'details')
        const {seo, layout, title, ...other} = this.props;
        return (
            <Page layout={{
                ...layout,
                onclose: () => this.props.history.push(this.props.i18n.buildLocalizedUrl("groups")),
            }} seo={seo}>
                <Title
                    wrap={true}
                    align={"center"}
                    labelPosition={"bottom"}
                    label={
                        <Link component={RouterLink} to={groupDetailsUrl} color="secondary">
                            <T defaultVal={"Vai al Gruppo"}>pages.share.go_to_group</T><br/>
                            <Icon color={"secondary"}>right_arrow</Icon>
                        </Link>
                    }
                    isLoading={isLoading} avatar={isLoading || this.props.isMobile ? null : serviceFamily.logo}
                >{title}</Title>
                {this.renderContent()}
            </Page>
        )
    }
}

const withWidth = (Component) => {
    return function (props) {
        const theme = useTheme()
        const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
        return (
            <Component isMobile={isMobile} {...props}/>
        )
    }
}

export default withStyles(styles, {useTheme: true})(withWidth(withApi(withI18n(withRouter(InvitePage)))));
