import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T} from "@ticketag/i18nlib";
import Form from "@ticketag/ticketag-uilib/src/components/complex/Form/Form";
import TextField from "@ticketag/ticketag-uilib/src/components/base/TextField/TextField";
import Checkbox from "@ticketag/ticketag-uilib/src/components/base/Checkbox/Checkbox";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import Grid from "@material-ui/core/Grid";
import {Box} from "@material-ui/core";
import {snakeCase} from "snake-case";


const styles = (theme) => {
    return {
        root: {}
    }
};

export const EditCredentialsForm = ({schemaProp, serviceName, propName, oldValue, ...props}) => {
    const oldLabel = `components.edit_credentials_form.old_${propName}` //<>Nuovo {schemaProp.title}</>
    const newLabel = `components.edit_credentials_form.new_${propName}` //<>Nuovo {schemaProp.title}</>
    const helperTextLabel = `components.edit_credentials_form.error_${propName}` //<>Nuovo {schemaProp.title}</>

    const oldPropName = propName + '-old'
    const defaultValues= {}
    defaultValues[oldPropName] = oldValue

    const fieldRules = {
        required: {value: true, message: {key: helperTextLabel}}
    }
    const consentLabel = `services.${snakeCase(serviceName)}.change_${propName}_consent`
    return <Form defaultValues={defaultValues} T={T} {...props}>
        <TextField fullWidth grid={{md: 12}} disabled={true} variant="outlined"
                   defaultValue={oldValue}
                   label={<T defaultVal={`Vecchio ${schemaProp.title}`}>{oldLabel}</T>} name={oldPropName}/>
        <TextField fullWidth grid={{md: 12}} variant="outlined"
                   rules={fieldRules}
                   label={<T defaultVal={`Nuovo ${schemaProp.title}`}>{newLabel}</T>} name={propName}/>
        <Grid item xs={12}>
            <Box mt={3}>
                <Typography variant="h5" upperCase color="error">{<T
                    defaultVal="attenzione">components.edit_credentials_form.warning</T>}</Typography>
            </Box>
        </Grid>
        <Checkbox box={{mt: 2, mb: 0}} grid={{md: 12}} name="accept" rules={{required: true}}
                  label={<T>{consentLabel}</T>}/>
    </Form>
}

export default withStyles(styles, {useTheme: true})(EditCredentialsForm);
