import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CheckoutListItem from "../CheckoutCard/CheckoutListItem";
import { List } from "@material-ui/core";

const styles = theme => {
  return {
    container: {
      padding: 0,
      marginTop: '32px',
      marginBottom: '0'
    },
    listItem: {
      marginBottom: '32px'
    }
  };
};

function NewGroupList(props) {
  const {
    children,
    classes,
    items = [],
    ...other
  } = props;
  return <List classes={{
    root: classes.container
  }} {...other} componentname="new-group-list">
        {items.map((item, index) => <CheckoutListItem key={index} classes={{
      container: classes.listItem
    }} title={item.title} subtitle={item.subtitle} rightContent={item.rightContent} />)}
        {children}
    </List>;
}

export default withStyles(styles, {
  name: "NewGroupList"
})(NewGroupList);
