/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';
import {WebapiprotoPaymentMethod} from './WebapiprotoPaymentMethod';
import {WebapiprotoPayoutProfile} from './WebapiprotoPayoutProfile';
import {WebapiprotoUserName} from './WebapiprotoUserName';
import {WebapiprotoUserProfile} from './WebapiprotoUserProfile';

/**
 * The WebapiprotoUserPreferences model module.
 * @module model/WebapiprotoUserPreferences
 * @version v0.4
 */
export class WebapiprotoUserPreferences {
  /**
   * Constructs a new <code>WebapiprotoUserPreferences</code>.
   * @alias module:model/WebapiprotoUserPreferences
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>WebapiprotoUserPreferences</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/WebapiprotoUserPreferences} obj Optional instance to populate.
   * @return {module:model/WebapiprotoUserPreferences} The populated <code>WebapiprotoUserPreferences</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new WebapiprotoUserPreferences();
      if (data.hasOwnProperty('name'))
        obj.name = WebapiprotoUserName.constructFromObject(data['name']);
      if (data.hasOwnProperty('default_profile'))
        obj.defaultProfile = WebapiprotoUserProfile.constructFromObject(data['default_profile']);
      if (data.hasOwnProperty('default_payment_method'))
        obj.defaultPaymentMethod = WebapiprotoPaymentMethod.constructFromObject(data['default_payment_method']);
      if (data.hasOwnProperty('payout_profile'))
        obj.payoutProfile = WebapiprotoPayoutProfile.constructFromObject(data['payout_profile']);
      if (data.hasOwnProperty('contact_email'))
        obj.contactEmail = ApiClient.convertToType(data['contact_email'], 'String');
      if (data.hasOwnProperty('access_email'))
        obj.accessEmail = ApiClient.convertToType(data['access_email'], 'String');
      if (data.hasOwnProperty('contact_language'))
        obj.contactLanguage = ApiClient.convertToType(data['contact_language'], 'String');
      if (data.hasOwnProperty('phone_number'))
        obj.phoneNumber = ApiClient.convertToType(data['phone_number'], 'String');
      if (data.hasOwnProperty('unlocked'))
        obj.unlocked = ApiClient.convertToType(data['unlocked'], 'Boolean');
    }
    return obj;
  }
}

/**
 * @member {module:model/WebapiprotoUserName} name
 */
WebapiprotoUserPreferences.prototype.name = undefined;

/**
 * @member {module:model/WebapiprotoUserProfile} defaultProfile
 */
WebapiprotoUserPreferences.prototype.defaultProfile = undefined;

/**
 * @member {module:model/WebapiprotoPaymentMethod} defaultPaymentMethod
 */
WebapiprotoUserPreferences.prototype.defaultPaymentMethod = undefined;

/**
 * @member {module:model/WebapiprotoPayoutProfile} payoutProfile
 */
WebapiprotoUserPreferences.prototype.payoutProfile = undefined;

/**
 * @member {String} contactEmail
 */
WebapiprotoUserPreferences.prototype.contactEmail = undefined;

/**
 * @member {String} accessEmail
 */
WebapiprotoUserPreferences.prototype.accessEmail = undefined;

/**
 * @member {String} contactLanguage
 */
WebapiprotoUserPreferences.prototype.contactLanguage = undefined;

/**
 * @member {String} phoneNumber
 */
WebapiprotoUserPreferences.prototype.phoneNumber = undefined;

/**
 * @member {Boolean} unlocked
 */
WebapiprotoUserPreferences.prototype.unlocked = undefined;


