
import React from "react";
import Box from "@material-ui/core/Box";
import withStyles from "@material-ui/core/styles/withStyles";
import Container from "@ticketag/ticketag-uilib/src/components/base/Container/Container";


const styles = (theme) => {
    return {
        root: {
            flexGrow: 1,
            padding: "0",
            overflow: "initial",
            [theme.breakpoints.up("sm")]: {
                padding: "16px 0",
                overflowX: "hidden",
                //overflowY: "scroll",
            }
        }

    }
}

function Scroll(props) {
    const {children, classes,  ...other} = props
    return (

                <Container className={classes.root} maxWidth={"xs"}>
                        {children}
                </Container>
    )
}

export default withStyles(styles)(Scroll)