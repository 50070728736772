import React, {Component} from 'react';
import ModalContext from "./context";
import ModalContainer from "./ModalContainer";
//import * as WebSharingWebApi from "@ticketag/diveedi-client";


export class ModalProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openModal: null
        }
    }
    componentDidMount() {

    }

    render() {
        const provider = {
            OkOnly: (title, text, options={}) => this.modals.openOkOnly(title, text, options),
            YesNo: (title, text, options={}) => this.modals.openYesNo(title, text, options),
            YesNoConfirm: (title, text, options={}) => this.modals.openYesNoConfirm(title, text, options),
            Form: (title, form, options={}) => this.modals.openForm(title, form, options),
            Stepper: (title, steps, options={}) => this.modals.openStepper(title, steps, options),
        }
        return (
            <>
                <ModalContext.Provider value={provider}>
                    {this.props.children}
                </ModalContext.Provider>
                <ModalContainer defaultText={this.props.defaultText} ref={r => {this.modals = r} } provider={provider} />
            </>
        )
    }
}

export const withModals = (Component) => {
    return ({ref, ...props}) => (<ModalContext.Consumer>
        {(modals) => {
            return <Component ref={ref} modals={modals} {...props}/>
        }}
    </ModalContext.Consumer>)
}

export default ModalProvider;
