import React, {Component} from 'react';
import GlobalLoaderContext from './context';
import Progress from "@ticketag/ticketag-uilib/src/components/base/Progress/Progress";
import withStyles from "@material-ui/core/styles/withStyles";
import Box from "@material-ui/core/Box";

import clsx from "clsx";
import ChatContext from "../Chat/context";


const styles = (theme) => {
    return {
        loader: {
            zIndex: 1000,
            marginTop: "-60px",
            [theme.breakpoints.up("xs")]: {
                marginTop: "-120px"
            },
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)"
        },
        hidden: {
            display: "none"
        }
    }
}

class GlobalLoader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hidden: false,
        }
        this.hideLoader = this.hideLoader.bind(this)
        this.showLoader = this.showLoader.bind(this)
    }

    hideLoader() {
        if (!this.state.hidden) {
            this.setState({hidden: true})
        }

    }
    showLoader() {
        if (this.state.hidden) {
            this.setState({hidden: false})
        }
    }

    render() {
        return (
            <>
                <GlobalLoaderContext.Provider value={{
                        Hide : this.hideLoader,
                        Show : this.showLoader,
                        hidden: this.state.hidden,
                    }}>
                    {this.props.children}
                    <Box className={clsx(this.props.classes.loader, this.state.hidden ? this.props.classes.hidden : null)}>
                        <Progress/>
                    </Box>
                </GlobalLoaderContext.Provider>
            </>
        )
    }
}

export const withGlobalLoader = (Component) => {
    return ({ref, ...props}) => (<GlobalLoaderContext.Consumer>
        {(loader) => {
            return <Component ref={ref} loader={loader} {...props}/>
        }}
    </GlobalLoaderContext.Consumer>)
}

export default withStyles(styles, {})(GlobalLoader);
