
import React from "react"
import {renderRoutes} from "react-router-config";
import LocalizedSwitch from "../LocalizedSwitch/LocalizedSwitch";
import {withAuth} from "../../hoc/Auth/Provider";
import {I18nProvider, withI18n} from "@ticketag/i18nlib";
import {withRouter} from "react-router-dom"
import ChatProvider, {withChat} from "../../hoc/Chat/Provider";
import AuthSwitch from "../AuthSwitch/AuthSwitch";
import WelcomeSwitch from "../WelcomeSwitch/WelcomeSwitch";
import PageProvider from "../../hoc/Page/Provider";
import SeoProvider from "../../hoc/Seo/Provider";
import ModalProvider from "@ticketag/ticketag-uilib/src/components/hoc/Modals/Provider";
import CookieProvider from "../../hoc/Cookie/Provider";
import {LastLocationProvider} from "react-router-last-location";
import Layout from "../../hoc/Layout/Layout";
import {Routes} from "../../hoc/Routes/Provider";

class RootSwitch extends React.Component {
    constructor(props) {
        super(props);
    }

    prova() {
        const {route} = this.props
        const routes = route.routes
    }

    isLoading() {
        return this.props.auth.isLoading() || this.props.i18n.isLoading() || this.props.chatApi.isLoading()
    }

    render() {
        if (this.isLoading()) {
            return null
        }
        const {route} = this.props;
        return (
                <PageProvider>
                    <Layout>
                        <LastLocationProvider>
                            <ModalProvider>
                                <CookieProvider>
                                    <LocalizedSwitch>
                                        <AuthSwitch>
                                            <WelcomeSwitch>
                                                <Routes routes={route.routes}/>
                                            </WelcomeSwitch>
                                        </AuthSwitch>
                                    </LocalizedSwitch>
                                </CookieProvider>
                            </ModalProvider>
                        </LastLocationProvider>
                    </Layout>
                </PageProvider>
        )
    }
}

export default withChat(withAuth(withI18n(withRouter(RootSwitch))))