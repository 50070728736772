import React, {Component} from 'react';

class DragDropUpload extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dragging: false
        };

        this.dropRef = React.createRef();

        this.handleDrag = this.handleDrag.bind(this);
        this.handleDragIn = this.handleDragIn.bind(this);
        this.handleDragOut = this.handleDragOut.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
    }

    handleDrag(e) {
        e.preventDefault();
        e.stopPropagation();
    }

    handleDragIn (e) {
        e.preventDefault();
        e.stopPropagation();

        if (e.dataTransfer.items && e.dataTransfer.items.length > 0 && !this.state.dragging) {
            this.setState({dragging: true});
        }
    }

    handleDragOut (e) {
        e.preventDefault();
        e.stopPropagation();

        if (this.state.dragging) {
            this.setState({dragging: false})
        }
    }

    handleDrop (e) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({dragging: false});
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.props.handleDrop(e.dataTransfer.files[0]);
            e.dataTransfer.clearData();
        }
    }

    componentDidMount() {
        let div = this.dropRef.current;
        div.addEventListener('dragenter', this.handleDragIn);
        div.addEventListener('dragleave', this.handleDragOut);
        div.addEventListener('dragover', this.handleDrag);
        div.addEventListener('drop', this.handleDrop);
    }

    componentWillUnmount() {
        let div = this.dropRef.current;
        div.removeEventListener('dragenter', this.handleDragIn);
        div.removeEventListener('dragleave', this.handleDragOut);
        div.removeEventListener('dragover', this.handleDrag);
        div.removeEventListener('drop', this.handleDrop);
    }

    render() {
        return (
            <div onClick={() => this.props.onClick()}
                className={`${this.props.customClass} ${this.state.dragging ? "dragging" : null}`} ref={this.dropRef}>
                {this.props.children}
            </div>
        );
    }
}

export default DragDropUpload;