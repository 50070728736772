
import {withI18n} from "@ticketag/i18nlib";
import React from "react"
import {withRouter, Route, Switch, Redirect} from "react-router-dom"

class LocalizedSwitch extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {children, i18n, location} = this.props;
        const selectedLocale = i18n.selectedLocale()
        const url = location.pathname
        return (
            <Switch>
                <Route path={"/:locale([a-z]{2}-[A-Z]{2})"}>
                    {children}
                </Route>
                <Redirect to={`/${selectedLocale}${url}`}/>
            </Switch>
        )
    }

}

export default withRouter(withI18n(LocalizedSwitch))