import React, {Component} from "react";
import Dialog from "./Dialog"
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {withStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "../../base/Button/Button";
import IconButton from "../../base/Button/IconButton";
import capitalize from "@material-ui/core/utils/capitalize";
import Checkbox from "../../base/Checkbox/Checkbox";
import Typography from "../../base/Typography/Typography";
import Stepper from "../../base/Stepper/Stepper";
import Step from "../../base/Stepper/Step";
import Progress from "../../base/Progress/Progress";
import LinearProgress from "@material-ui/core/LinearProgress";
import {Rules as FormRules} from "../../complex/Form/Controller";
import clsx from "clsx";
import {Slide} from "@material-ui/core";

const MODALS_DELAY_TIME = 1000

const styles = (theme) => {
    return {
        root:           {},
        title:          {
            paddingTop:    theme.spacing(4),
            paddingBottom: theme.spacing(4),
            lineHeight:    '24px',
            fontSize:      '20px',
            color:         theme.palette.text.secondary,
            fontWeight:    600,
            textAlign:     'center'
        },
        content:        {
            paddingBottom: theme.spacing(2),
            paddingLeft:   theme.spacing(5),
            paddingRight:  theme.spacing(5),
        },
        fullWidthSm: {
            [theme.breakpoints.up("sm")]: {
                width: "100%"
            }
        },
        fullWidthMd: {
            [theme.breakpoints.up("md")]: {
                width: "100%"
            }
        },
        fullWidthLg: {
            [theme.breakpoints.up("lg")]: {
                width: "100%"
            }
        },
        actions:        {
            display:                        'flex',
            justifyContent:                 'center',
            paddingTop:                     theme.spacing(3),
            paddingBottom:                  theme.spacing(5),
            paddingLeft:                    theme.spacing(4),
            paddingRight:                   theme.spacing(4),
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column-reverse',
                paddingBottom: theme.spacing(2),
            },
        },
        buttons:        {
            minWidth:                       '160px',
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
                margin:        theme.spacing(1),
            },
        },
        closeContainer: {
            position: 'absolute',
            top:      0,
            right:    0,
        },
        paperWidthLg:   {
            maxWidth: '952px',
        },
        contentLg:      {
            [theme.breakpoints.up('sm')]: {
                maxWidth: '712px',
                margin:   'auto'
            },
        },
        sizeMd:         {},
        contentMd:      {
            [theme.breakpoints.up('sm')]: {
                maxWidth:  '512px',
                alignSelf: 'center',
                margin:    'auto'
            },
        },
        progress: {
            width: '100%',
            margin: theme.spacing(1),
            marginBottom: theme.spacing(2) + 5,
        }
    }
};

class ModalContainer extends Component {
    constructor(props) {
        super(props);
        this.counter = 0

        this.getDefaultState.bind(this)
        this.openOkOnly.bind(this)
        this.openYesNo.bind(this)
        this.openForm.bind(this)

        this.handleExit.bind(this)
        this.handleResolve.bind(this)
        this.handleReject.bind(this)

        this.state = this.getDefaultState()
        this.promise = null

        this.text = {
            okOnly:  {
                ok: 'Ok'//<T defaultVal="Ok">modals.ok_only.ok</T>
            },
            yesNo:   {
                cancel:  'Annulla',//<T defaultVal="Annulla">modals.yes_no.cancel</T>,
                confirm: 'Conferma',//<T defaultVal="Conferma">modals.yes_no.confirm</T>
            },
            form:    {},
            stepper: {
                continue: 'Continua'
            },
            ...props.defaultText
        }


    }

    handleExit() {
        this.counter++
        this.setState(this.getDefaultState(), () => {
            setTimeout(() => {
                this.callback()

                this.promise = null
                this.resolve = null
                this.reject = null
                this.callback = null
            }, MODALS_DELAY_TIME)
        })
    }

    handleResolve(data) {
        // Modale chiusa prima di impostare lo stato di default per evitare quirk grafici
        this.callback = () => this.resolve(data)
        this.setState({open: false})
    }

    handleReject(data) {
        this.callback = () => this.reject(data)
        this.setState({open: false})
    }

    getDefaultState() {
        return {
            open:              false,
            loaded:            false,
            title:             null,
            button:            null,
            onClose:           null,
            size:              'md',
            clickOutside:      false,
            counter:           this.counter,
            formValid:         false,
            disableTypography: false,
            closeBtnProps:     {},
            fullWidth:         false,
            fullScreen:        false,
            closeable:         true,
            errors: []
        }
    }

    openOkOnly(title, text, {variant = 'standard', size = 'sm', ...options}) {
        this.promise = new Promise((resolve, reject) => {
            this.resolve = resolve
            this.reject = reject
        })
        this.setState({
            title:   title,
            button:  () => <Button classes={{root: this.props.classes.buttons}} onClick={() => this.handleResolve(true)}
                                   error={variant === 'error'}
                                   color={'gradient'} variant="outlined" autoFocus>{options.confirmText || this.text.okOnly.ok }</Button>,
            onClose: () => this.handleResolve(false),
            content: (state) => options.disableTypography ? text :
                <Typography align={"center"} variant={'subtitle1'}>{text}</Typography>,
            loaded:  true,
            open:    true,
            size:    size,
            ...options
        })
        return this.promise
    }

    openYesNo(title, text, {
        variantNo = 'outlined',
        variantYes = 'outlined',
        colorNo = 'error',
        colorYes = 'gradient',
        variant = 'standard',
        size = 'sm',
        confirmText,
        cancelText,
        hideConfirm = false,
        beforeSubmit ,
        fullWidth = false,
        ...options
    }) {
        this.promise = new Promise((resolve, reject) => {
            this.resolve = resolve
            this.reject = reject
        })
        this.setState({
            title:   title,
            button:  (state, handleResolve, handleReject) => <>
                <Button classes={{root: this.props.classes.buttons}} onClick={() => handleReject(true)}
                        error={colorNo === "error"} color={colorNo}
                        variant={variantNo } autoFocus>{cancelText || this.text.yesNo.cancel}</Button>
                {
                    !hideConfirm
                        ?
                        <Button classes={{root: this.props.classes.buttons}} onClick={() => {
                            if (beforeSubmit) {
                                new Promise((resolve) => {beforeSubmit(); resolve()}).then(() => handleResolve(true))
                            } else {
                                handleResolve(true)
                            }
                        }}
                                color={colorYes} variant={variantYes}
                                autoFocus>{confirmText || this.text.yesNo.confirm}</Button>
                        :
                        null
                }
            </>,
            onClose: () => this.handleReject(false),
            content: (state) => options.disableTypography ? text :
                <Typography variant={'subtitle1'}>{text}</Typography>,
            loaded:  true,
            open:    true,
            size:    size,
            ...options
        })
        return this.promise
    }

    openYesNoConfirm(title, text, {
        button,
        checkboxLabel,
        confirmComponentFn,
        showWarning,
        confirmBoxProps = {},
        confirmText,
        cancelText,
        reverseButtons,
        rules,
        disableTypography,
        fullScreen = false,
        ...options
    }) {
        const validate = (val) => {
            let isValid = true
            for (const ruleName in FormRules) {
                let rule = rules[ruleName]
                if (rule) {
                    const match = FormRules[ruleName]
                    if (!match(val, rule)) {
                        isValid = false
                        break
                    }
                }
            }
            return isValid
        }
        const onChange = (e, val) => {
            this.setState({formValid: rules ? validate(val) : !!val, confirmValue: val})
        }
        return this.openYesNo(
            title,
            <Box mb={{xs: 2, md: 4}}>
                {disableTypography ? text : <Typography letterSpacing={"normal"} variant={'body2'} align="justify">{text}</Typography>}
                <Box {...confirmBoxProps}>
                    {showWarning ? <Box mb={{xs: 1, sm: 2}}><Typography color="error" variant="h4"
                                                                        upperCase>Attenzione</Typography></Box> : null}
                    {confirmComponentFn ? confirmComponentFn({onChange: onChange}) :
                        <Checkbox onChange={onChange} label={checkboxLabel}/>}
                </Box>
            </Box>, {
                ...options,
                fullScreen: fullScreen,
                disableTypography: true,
                button:            (state, handleResolve, handleReject) => <>
                    <Button classes={{root: this.props.classes.buttons}} onClick={() => handleReject(true)}
                            error={!reverseButtons}
                            variant="outlined" autoFocus>{cancelText || this.text.yesNo.cancel}</Button>
                    <Button classes={{root: this.props.classes.buttons}}
                            onClick={() => handleResolve({confirm: state.confirmValue})}
                            disabled={!this.state.formValid} error={!!reverseButtons}
                            color="gradient" variant="outlined"
                            autoFocus>{confirmText || this.text.yesNo.confirm}</Button>
                </>
            })
    }

    openForm(title, form, {
        variant = 'standard',
        size = 'md',
        scroll = 'body',
        submitText = this.text.yesNo.confirm,
        cancelText = this.text.yesNo.cancel,
        // submitHandler,
        beforeSubmit = null,
        submitProps = {},
        ...options
    }) {
        this.promise = new Promise((resolve, reject) => {
            this.resolve = resolve
            this.reject = reject
        })
        this.promise.setDisabled = (disabled) => {
            this.setState({disabled: disabled})
            return this.promise
        }
        //const FormComponent = form
        let formRef = null

        const formContent = React.createElement(form.type, {
            ...form.props,
            formRef:       (r) => {
                formRef = r
                if (r?.formState.isValid && r.formState.isValid !== this.state.formValid) {
                    this.setState({formValid: r.formState.isValid})
                }
            },
            disabled:      this.state.disabled || form.props.disabled,
            defaultValues: form.props.defaultValues,
            // submitHandler: (h) => submitHandler = h,
            onValidate: ({isValid, ...other}) => {
                if (isValid !== this.state.formValid) {
                    this.setState({formValid: isValid})
                }
            },
        })

        this.setState({
            title:    title,
            button:   (state) => state.saving ? <LinearProgress color={'secondary'} classes={{root: this.props.classes.progress}}/> :<>
                <Button classes={{root: this.props.classes.buttons}} onClick={() => this.handleReject(true)} error
                        {...submitProps}
                        disabled={state.disabled}
                        variant="outlined" autoFocus>{cancelText}</Button>
                <Button classes={{root: this.props.classes.buttons}} disabled={state.disabled || !state.formValid}
                        onClick={() => {
                            if (beforeSubmit) {
                                this.setState({disabled: true, saving: true, errors: []})
                                Promise.resolve(beforeSubmit({form: formRef, value: formRef.getValues()}, (formData) => {
                                    this.setState({disabled: false}, () => {
                                        this.handleResolve(formData)
                                    })
                                })).catch(err => {
                                    const message = err?.message|| `Si è verificato un errore durante l'inserimento del metodo di pagamento`
                                    this.setState({disabled: false, saving: false, errors: [ message ]})
                                })
                            } else {
                                this.handleResolve({form: formRef, value: formRef.getValues()})
                            }
                            //console.log('submitHandler', submitHandler)
                            //this.handleResolve(true)
                        }}
                        color="gradient" variant="outlined" autoFocus>{submitText}</Button>
            </>,
            onClose:  () => this.handleReject(false),
            content:  (state) => formContent,
            loaded:   true,
            open:     true,
            size:     size,
            disabled: form.props.disabled,
            saving:   false,
            ...options
        })
        return this.promise
    }

    openStepper(title, steps, {
        variant = 'standard',
        size = 'md',
        scroll = 'body',
        submitText = this.text.yesNo.confirm,
        continueText = this.text.stepper.continue,
        cancelText = this.text.yesNo.cancel,
        // submitHandler,
        submitProps = {},
        currentStep = 0,
        onContinue,
        ...options
    }) {
        this.promise = new Promise((resolve, reject) => {
            this.resolve = resolve
            this.reject = reject
        })
        this.promise.setDisabled = (disabled) => {
            this.setState({disabled: disabled})
            return this.promise
        }
        //const FormComponent = form
        let formRef = null

        const formContent = (form, state) => React.createElement(form.type, {
            ...form.props,
            formRef:       (r) => formRef = r,
            disabled:      this.state.disabled || form.props.disabled,
            defaultValues: state.formDefaults || form.props.defaultValues,
            // submitHandler: (h) => submitHandler = h,
            onValidate: ({isValid}) => {
                if (isValid !== this.state.formValid) {
                    this.setState({formValid: isValid})
                }
            },
        })

        this.setState({
            title:        title,
            button:       (state) => <>
                <Button classes={{root: this.props.classes.buttons}}
                        onClick={() => this.handleReject({button: true, error: null})} error
                        {...submitProps}
                        disabled={state.disabled}
                        variant="outlined" autoFocus>{cancelText}</Button>
                <Button classes={{root: this.props.classes.buttons}} disabled={state.disabled || !state.formValid}
                        key={`btn-step-${state.currentStep}`}
                        onClick={() => {
                            onContinue({step: this.state.currentStep, data: formRef.getValues()}).then((resp) => {
                                const nextStep = this.state.currentStep + 1
                                const isEnd = nextStep >= steps.length
                                if (isEnd) {
                                    const promise = this.promise
                                    this.handleResolve({form: formRef, value: formRef.getValues()})
                                    return promise
                                }
                                this.setState({
                                    currentStep:  this.state.currentStep + 1,
                                    submitText:   state.currentStep === steps.length - 1 ? submitText : continueText,
                                    formValid:    false,
                                    formDefaults: resp,
                                }, () => {
                                    if (resp) {
                                        for (const k in resp) {
                                            formRef.setValue(k, resp[k])
                                        }
                                    }
                                })
                            }).catch(e => {
                                this.handleReject({button: true, error: e})
                            })
                        }}
                        color="gradient" variant="outlined"
                        autoFocus>{state.currentStep >= (steps.length - 1) ? submitText : continueText}</Button>
            </>,
            onClose:      () => this.handleReject({button: false, error: null}),
            content:      (state) => <>
                <Stepper>
                    {steps.map((step, i) => <Step label={i + 1}
                                                  completed={state.currentStep > i}
                                                  active={state.currentStep === i}
                    />)}
                </Stepper>
                {state.currentStep >= (steps.length) ?
                    <Progress/> : formContent(steps[state.currentStep || currentStep], state)}
            </>,
            currentStep:  currentStep,
            loaded:       true,
            open:         true,
            size:         size,
            submitText:   submitText,
            continueText: continueText,
            //disabled: form.props.disabled,
            ...options
        })
        return this.promise

    }

    render() {
        if (!this.state.loaded) {
            return null
        }
        const contentSizeClass = clsx(
            this.props.classes[`content${capitalize(this.state.size)}`],
            this.state.fullWidth ? this.props.classes[`fullWidth${capitalize(this.state.size)}`] : null
        )
        return <Dialog size={this.state.size} scroll={this.state.scroll} open={this.state.open}
                       onClose={this.state.clickOutside ? this.state.onClose : null}
                       onExited={() => this.handleExit()} maxWidth={this.state.size} fullWidth={true} fullScreen={this.state.fullScreen}>
            { this.state.closeable ?
                <Box classes={{root: this.props.classes.closeContainer}}>
                    <IconButton color="disabled" disabled={this.state.disabled} onClick={this.state.onClose}
                                size='large' {...this.state.closeBtnProps}>close</IconButton>
                </Box> : null
            }
            <DialogTitle style={this.state.styles?.title || {}} disableTypography
                         classes={{root: this.props.classes.title}}>
                {this.state.title}
            </DialogTitle>
            <DialogContent key={this.state.counter} className={contentSizeClass}
                           classes={{root: this.props.classes.content}}>
                {this.state.content(this.state)}
                {this.state.errors.map((val, idx) => {
                    return <Typography color="error" variant="h4" key={idx}>{val} </Typography>
                })}
            </DialogContent>
            <DialogActions classes={{root: this.props.classes.actions}}>
                {this.state.button(this.state, (val) => this.handleResolve(val), (val) => this.handleReject(val))}
            </DialogActions>
        </Dialog>
    }
}

export default withStyles(styles, {useTheme: true, withTheme: true})(ModalContainer)
