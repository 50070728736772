import React from "react";
import {renderRoutes} from "react-router-config";
import {withApi} from "../../hoc/Api/Provider";
import {withI18n} from "@ticketag/i18nlib";
import {withAuth} from "../../hoc/Auth/Provider";
import {withRedirect} from "../../components/ErrorMessage/ErrorMessage";
import {withGlobalLoader} from "../../hoc/GlobalLoader/Provider";
import {Routes} from "../../hoc/Routes/Provider";

class ServiceFamilySwitch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
        }
    }
    componentDidMount() {
        this.props.loader.Show()
        withRedirect(this.props.history, Promise.all([
            this.getUserGroups(),
            this.props.api.GetServiceFamilyBySlug(this.props.match.params.serviceFamily)
        ]).then(([groups, serviceFamily]) => {
            const userGroup = [
                ...groups.owned.map((group) => {
                    group.type = "admin";
                    return group
                }),
                ...groups.joined.map((group) => {
                    group.type = "joiner";
                    return group
                })
            ].find((elt) => elt.serviceFamilyUuid === serviceFamily.uuid)
            if (userGroup) {
                switch (userGroup.state) {
                    case "draft":
                        this.props.history.replace(this.props.i18n.buildLocalizedUrl("groups", userGroup.uuid, "create", "visibility"))
                        break;
                    default:
                        this.props.history.replace(this.props.i18n.buildLocalizedUrl("groups", userGroup.uuid, "details"))
                }
                return;
            }
            this.loadServiceTypes(serviceFamily).then((services) => {
                this.setState({loaded: true, serviceFamily: serviceFamily, services: services})
            })
        }))
    }

    loadServiceTypes(serviceFamily) {
        return withRedirect(this.props.history, Promise.all([
            this.props.api.serviceApi.getFamilyServices(serviceFamily.uuid),
            this.props.api.serviceApi.getFamilyServiceDetails(serviceFamily.uuid)
        ]).then(([resp, details]) => {
            const services = resp.services.map(s => {
                const detail = details.details.find(sd => sd.serviceTypeUuid === s.uuid)
                s.monthlyPrice = detail?.totalPrice
                return s
            })
            return services
        }))
    }

    getUserGroups() {
        if (this.props.auth.isAuthenticated()) {
            return this.props.api.userApi.getUserGroups()
        }
        return Promise.resolve({
            owned: [],
            joined: [],
        })
    }

    render() {
        const isLoading = !this.state.loaded
        const route = this.props.route
        return isLoading ?
            null
            :
            <Routes routes={route.routes} serviceFamily={this.state.serviceFamily} services={this.state.services}/>

    }
}

export default withI18n(withApi(withAuth(withGlobalLoader(ServiceFamilySwitch))))