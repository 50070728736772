/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from "../ApiClient";
import {WebapiprotoGenericConfirm} from '../model/WebapiprotoGenericConfirm';
import {WebapiprotoNotificationDevice} from '../model/WebapiprotoNotificationDevice';
import {WebapiprotoPage} from '../model/WebapiprotoPage';
import {WebapiprotoPassword} from '../model/WebapiprotoPassword';
import {WebapiprotoServiceSuggestion} from '../model/WebapiprotoServiceSuggestion';

/**
* System service.
* @module api/SystemApi
* @version v0.4
*/
export class SystemApi {

    /**
    * Constructs a new SystemApi. 
    * @alias module:api/SystemApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Ottiene il contenuto della pagina 
     * @param {module:model/String} name 
     * @param {Object} opts Optional parameters
     * @param {String} opts.lang 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoPage} and HTTP response
     */
    getPageWithHttpInfo(name, opts) {
      opts = opts || {};
      let postBody = null;

      // verify the required parameter 'name' is set
      if (name === undefined || name === null) {
        throw new Error("Missing the required parameter 'name' when calling getPage");
      }


      let pathParams = {
        'name': name
      };
      let queryParams = {
        'lang': opts['lang']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoPage;

      return this.apiClient.callApi(
        '/api/v1/page/{name}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Ottiene il contenuto della pagina 
     * @param {module:model/String} name 
     * @param {Object} opts Optional parameters
     * @param {String} opts.lang 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoPage}
     */
    getPage(name, opts) {
      return this.getPageWithHttpInfo(name, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Ottiene una password per il dato service type
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoPassword} and HTTP response
     */
    getPasswordWithHttpInfo(uuid) {
      let postBody = null;

      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling getPassword");
      }


      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoPassword;

      return this.apiClient.callApi(
        '/api/v1/password/{uuid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Ottiene una password per il dato service type
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoPassword}
     */
    getPassword(uuid) {
      return this.getPasswordWithHttpInfo(uuid)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Registra un device per la ricezione di notifiche
     * @param {module:model/WebapiprotoNotificationDevice} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoGenericConfirm} and HTTP response
     */
    registerNotificationDeviceWithHttpInfo(body) {
      let postBody = body;

      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling registerNotificationDevice");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoGenericConfirm;

      return this.apiClient.callApi(
        '/api/v1/notifications/register', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Registra un device per la ricezione di notifiche
     * @param {module:model/WebapiprotoNotificationDevice} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoGenericConfirm}
     */
    registerNotificationDevice(body) {
      return this.registerNotificationDeviceWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Inserisce un suggerimento da parte dell'utente
     * @param {module:model/WebapiprotoServiceSuggestion} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoGenericConfirm} and HTTP response
     */
    suggestServiceWithHttpInfo(body) {
      let postBody = body;

      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling suggestService");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoGenericConfirm;

      return this.apiClient.callApi(
        '/api/v1/suggest', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Inserisce un suggerimento da parte dell'utente
     * @param {module:model/WebapiprotoServiceSuggestion} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoGenericConfirm}
     */
    suggestService(body) {
      return this.suggestServiceWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
