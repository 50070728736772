import React, {useEffect, useRef, useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T} from "@ticketag/i18nlib";
import Box from "@material-ui/core/Box";
import ResponsiveCard from "@ticketag/ticketag-uilib/src/components/complex/ResponsiveCard/ResponsiveCard";
import IconButton from "@ticketag/ticketag-uilib/src/components/base/Button/IconButton";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import RadioGroup from "@ticketag/ticketag-uilib/src/components/base/Radio/RadioGroup";
import {Icon} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";
import TextButton from "@ticketag/ticketag-uilib/src/components/base/Button/TextButton";
import PaymentMethodForm from "../PaymentMethodForm/PaymentMethodForm";
import Button from "@ticketag/ticketag-uilib/src/components/base/Button/Button";
import NextButton from "../NextButton/NextButton";


const styles = (theme) => {
    return {
        root: {},
        cardBrandIcon: {
            marginRight: theme.spacing(1),
            "& + *": {
                display: "inline"
            }
        },
        centered: {
            justifyContent: "center",
        },
        nextButton: {
            width: "80px",
            height: "80px",
            borderRadius: "50%",
            marginBottom: "40px"
        },
    }
};


const PaymentMethodSelector = ({onChange, centered, blocked, children, paymentMethods, onSubmit, inlineVariant, formRef, onSelect,insertError, onCreate, onDelete = null, classes, modals, stripe, narrowCards=false, ...props}) => {
    const [editMode, setEditMode] = useState(insertError!==null)
    const [disabled, setDisabled] = useState(props.disabled || false)
    const [isSaving, setIsSaving] = useState(false);
    const [error,setError] = useState(insertError)
    const errorRef = useRef()
    useEffect(()=>{
        if(insertError){
            setError(insertError)
            setEditMode(true)
            errorRef.current.scrollIntoView()
        }
    },[insertError])
    const selectHandler = (p) => {
        if (!editMode) {
            return
        }
        setDisabled(true)
        return onSelect(p, () => {
            setEditMode(false)
            setDisabled(false)
        })
    }

    const deleteHandler = (p) => {
        setDisabled(true)
        return onDelete(p, () => {
            setEditMode(false)
            setDisabled(false)
        })
    }

    const onBeforeSubmit = (formData, cb) => {
        setIsSaving(true)
        return onCreate(stripe, formData.value, cb)
    }

    const openFormModal = () => {
        setDisabled(true)
        let formRef = null
        // onSubmit={/*(stripe, form) => onCreate(stripe, data)*/} disabled={formDisabled}
        return modals.Form(<T defaultVal="Nuovo metodo di pagamento">components.payment_method_form.title</T>, <PaymentMethodForm stripe={stripe}/>,
            {
                size: 'sm',
                fullScreen: true,
                submitText: <T defaultVal="Aggiungi metodo di pagamento">components.payment_method_form.add_payment_method</T>,
                beforeSubmit: onBeforeSubmit
            })
            .then(data => data)
            .catch()
            .finally(() => {
                console.log('modal closed')
                setDisabled(false)
                setIsSaving(false)
                setEditMode(false)
            })
    }

    const isPaymentMethodComplete = (elt) => elt.name && elt.last4 && elt.expMonth && elt.expYear && elt.brand
    const defaultPaymentMethod = paymentMethods.find((elt) => elt.isDefault && !elt.pending && isPaymentMethodComplete(elt)) || null
    const validatedPaymentMethods = paymentMethods.map((elt) => {
        if (paymentMethods.length === 1 && !defaultPaymentMethod) {
            elt.pending = true
        }
        if (!isPaymentMethodComplete(elt)) {
            elt.pending = true
        }
        return elt
    })
    const pendingPaymentMethods = validatedPaymentMethods.filter((elt) => elt.pending)
    const showingPaymentMethods = editMode ? validatedPaymentMethods : [...(defaultPaymentMethod ? [defaultPaymentMethod] : []), ...pendingPaymentMethods]
    return <>
                <Box hidden={!error} style={!error?{margin:0}:undefined} ref={errorRef} display="flex" mb={6} mr={{xs: 0, md: 3, lg: 8}}>
                    <Typography color="error" variant="h4">{error}</Typography>
                </Box>
                <RadioGroup row classes={{root: centered ? classes.centered : null}}>
                    {showingPaymentMethods.map((p) => (
                        <Box key={p.uuid} mb={2} mr={centered ? 0 : 3}>
                            <ResponsiveCard title={p.pending ? <T defaultVal={"Verifica in corso..."}>component.payment_method_selector.pending_title</T> : p.name} horizontal={false} selected={p.isDefault}
                                            narrow={narrowCards}
                                            syncing={p.pending}
                                            disabled={disabled}
                                            onSelect={() => selectHandler(p)}
                                            action={onDelete ?
                                                <Box px={1} mb={-2}>
                                                    <IconButton width={20} height={20}  onClick={() => deleteHandler(p)} color={'error'}>trash_icon</IconButton>
                                                </Box>: null}>
                                { p.pending ?
                                    <Typography variant={'subtitle1'}>
                                        <T defaultVal={"Carta in fase di convalida. Aspetta qualche minuto..."}>component.payment_method_selector.pending_subtitle</T>
                                    </Typography> :
                                    <>
                                        <Typography variant={'subtitle1'}>{p.exp}</Typography>

                                        <Box px={0} style={{flexGrow: 1}}><Icon classes={{root: classes.cardBrandIcon}}>{p.brand}</Icon><Typography
                                            variant={'subtitle1'}>**** **** **** {p.last4}</Typography></Box>
                                        <Typography variant={'subtitle1'}>
                                            <T defaultVal={'subtitle1'}>common.credit_card.expiry_short</T>. {p.expMonth.toString().padStart(2, '0')}/{p.expYear}
                                        </Typography>
                                    </>
                                }
                            </ResponsiveCard>
                        </Box>
                    ))}
                </RadioGroup>
        {
            !disabled && pendingPaymentMethods.length === 0?
                <Box pt={1} display="flex" justifyContent={centered ? "center" : "flex-start"}>
                    {editMode || paymentMethods.length === 0 ?
                        <TextButton onClick={openFormModal} variant={'secondary'}>+ Aggiungi Metodo di pagamento</TextButton> :
                        <TextButton variant={'secondary'} onClick={() => setEditMode(true)}>
                            <T defaultVal="Cambia metodo di pagamento">components.payment_method_selector.change_payment_method</T></TextButton>}
                </Box> :
                <Box pt={1}/>
        }
        {children}
        {
                    inlineVariant ?
                        <NextButton onClick={onSubmit} disabled={blocked || disabled || pendingPaymentMethods.length > 0}/>
                        : null
        }
    </>
}

export default withStyles(styles, {useTheme: true})(PaymentMethodSelector);
