/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';
import {WebapiprotoDocTypes} from './WebapiprotoDocTypes';

/**
 * The WebapiprotoIdDocument model module.
 * @module model/WebapiprotoIdDocument
 * @version v0.4
 */
export class WebapiprotoIdDocument {
  /**
   * Constructs a new <code>WebapiprotoIdDocument</code>.
   * @alias module:model/WebapiprotoIdDocument
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>WebapiprotoIdDocument</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/WebapiprotoIdDocument} obj Optional instance to populate.
   * @return {module:model/WebapiprotoIdDocument} The populated <code>WebapiprotoIdDocument</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new WebapiprotoIdDocument();
      if (data.hasOwnProperty('uuid'))
        obj.uuid = ApiClient.convertToType(data['uuid'], 'String');
      if (data.hasOwnProperty('image'))
        obj.image = ApiClient.convertToType(data['image'], 'String');
      if (data.hasOwnProperty('rear'))
        obj.rear = ApiClient.convertToType(data['rear'], 'Boolean');
      if (data.hasOwnProperty('front_img'))
        obj.frontImg = ApiClient.convertToType(data['front_img'], 'String');
      if (data.hasOwnProperty('rear_img'))
        obj.rearImg = ApiClient.convertToType(data['rear_img'], 'String');
      if (data.hasOwnProperty('type'))
        obj.type = WebapiprotoDocTypes.constructFromObject(data['type']);
      if (data.hasOwnProperty('type_name'))
        obj.typeName = ApiClient.convertToType(data['type_name'], 'String');
      if (data.hasOwnProperty('doc_number'))
        obj.docNumber = ApiClient.convertToType(data['doc_number'], 'String');
      if (data.hasOwnProperty('expire_date'))
        obj.expireDate = ApiClient.convertToType(data['expire_date'], 'Date');
      if (data.hasOwnProperty('user_profile_uuid'))
        obj.userProfileUuid = ApiClient.convertToType(data['user_profile_uuid'], 'String');
      if (data.hasOwnProperty('is_complete'))
        obj.isComplete = ApiClient.convertToType(data['is_complete'], 'Boolean');
      if (data.hasOwnProperty('user_uuid'))
        obj.userUuid = ApiClient.convertToType(data['user_uuid'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} uuid
 */
WebapiprotoIdDocument.prototype.uuid = undefined;

/**
 * @member {String} image
 */
WebapiprotoIdDocument.prototype.image = undefined;

/**
 * @member {Boolean} rear
 */
WebapiprotoIdDocument.prototype.rear = undefined;

/**
 * @member {String} frontImg
 */
WebapiprotoIdDocument.prototype.frontImg = undefined;

/**
 * @member {String} rearImg
 */
WebapiprotoIdDocument.prototype.rearImg = undefined;

/**
 * @member {module:model/WebapiprotoDocTypes} type
 */
WebapiprotoIdDocument.prototype.type = undefined;

/**
 * @member {String} typeName
 */
WebapiprotoIdDocument.prototype.typeName = undefined;

/**
 * @member {String} docNumber
 */
WebapiprotoIdDocument.prototype.docNumber = undefined;

/**
 * @member {Date} expireDate
 */
WebapiprotoIdDocument.prototype.expireDate = undefined;

/**
 * @member {String} userProfileUuid
 */
WebapiprotoIdDocument.prototype.userProfileUuid = undefined;

/**
 * @member {Boolean} isComplete
 */
WebapiprotoIdDocument.prototype.isComplete = undefined;

/**
 * @member {String} userUuid
 */
WebapiprotoIdDocument.prototype.userUuid = undefined;


