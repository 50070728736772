import {matchRoutes} from "react-router-config";
import {withAuth} from "../../hoc/Auth/Provider";
import {withI18n} from "@ticketag/i18nlib";
import {withRouter} from "react-router-dom"
import React from "react";
import {withRoutes} from "../../hoc/Routes/Provider";

class AuthSwitch extends React.Component {
    constructor(props) {
        super(props);
    }

    isPrivateRoute() {
        const {routes, location} = this.props;
        const matchRoute = [...matchRoutes(routes, location.pathname)]
        return matchRoute.reduce((acc, {route}) => acc && !!route.private, false);
    }

    redirectToLogin() {
        const {auth, i18n} = this.props;
        const currentUrl = window.location.pathname;
        localStorage.setItem('redirect-count', 1)
        window.location.href = `${auth.loginUrl}?from=${currentUrl||'/'}&lang=${i18n.selectedLocale()}`
    }

    render() {
        const {children, auth, i18n, location} = this.props;
        if (this.isPrivateRoute() && !auth.isAuthenticated()) {
            return this.redirectToLogin()
        }
        return children
    }

}

export default withAuth(withI18n(withRoutes(withRouter(AuthSwitch))))