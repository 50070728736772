import React from "react";
import GridListTile from "@material-ui/core/GridListTile";
import clsx from "clsx";
import Box from "@material-ui/core/Box";

export default function CarouselItem(props) {
    const {
        children,
        style,
        totCols,
        disabled,
        onMouseEnter = (e) => {
        },
        onMouseLeave = (e) => {
        },
        scaling,
        spacing,
        visible,
        fluid,
        height,
        ...other
    } = props
    const [hover, setHover] = React.useState(false)
    const onEnter = (e) => {
        setHover(true)
        onMouseEnter(e)
    }
    const onLeave = (e) => {
        setHover(false)
        onMouseLeave(e)
    }
    return <GridListTile
        classes={{tile: props.classes.prova3}}
        className={clsx(props.classes.tile, hover ? props.classes.hover : null, visible ? props.classes.visible : null)}
        onMouseEnter={onEnter}
        onTouchStart={onEnter}
        onMouseLeave={onLeave}
        onTouchEnd={onLeave}
        style={{
            ...(hover && !disabled ? {...style, width: `${100 / totCols * scaling}%`, height: style.height * scaling} : style),
            ...(!visible ? {width: 0, padding: 0} : null)
        }}
    >
            {children}
    </GridListTile>
        ;
}