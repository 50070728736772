import withStyles from "@material-ui/core/styles/withStyles";
import {withPage} from "../../hoc/Page/Provider";
import Header from "@ticketag/ticketag-uilib/src/components/base/Header/Header";
import {T} from "@ticketag/i18nlib";
import React from "react"
import {withLayout} from "../../hoc/Layout/Layout";
import clsx from "clsx";

const styles = (theme) => {
    return {
        root: {},
        modal: {
//            fontSize: "18px"
        }
    }
}

const defaultTitlePadding = 6

function TTitle(props) {
    const {
        children,
        layout,
        classes,
        T : tComponent,
        multiline , useMarkdown, useTemplate, args,
        align : alignProp,
        page,
        pages,
        ...other
    } = props
    const align = alignProp || (layout.centered ? "center" : "left")
    const pageData = pages.getPageData(page)
    console.log(layout)
    const titleData = pageData.title || children
    const renderTitle = (data) => page && typeof data === "string" ?
            <T multiline={multiline} useTemplate={useTemplate} args={args} defaultVal={data}>{`pages.${page}.title`}</T>
            : tComponent || data
    return (
        <Header className={clsx(classes.root, layout.modal ? classes.modal : null)} pb={defaultTitlePadding} align={align} {...other}>
            {renderTitle(titleData)}
        </Header>
    )
}

export default withStyles(styles)(withLayout(withPage(TTitle)))
