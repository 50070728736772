import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import capitalize from "@material-ui/core/utils/capitalize";
import TextField from "@material-ui/core/TextField";
import { Skeleton } from "@material-ui/lab";

const styles = theme => {
  return {
    root: {
      "& strong": {
        fontWeight: 600
      },
      "& a": {
        color: "inherit"
      }
    },
    bold: {
      fontWeight: theme.typography.fontWeightBold
    },
    semiBold: {
      fontWeight: theme.typography.fontWeightSemiBold
    },
    upperCase: {
      textTransform: 'uppercase'
    },
    colorGradient: {
      background: theme.palette.default_gradient,
      "-webkit-background-clip": "text",
      "-webkit-text-fill-color": "transparent"
    },
    colorSecondary: {
      color: theme.palette.secondary.main
    },
    colorPrimary: {
      color: theme.palette.primary.main
    },
    colorTextSecondary: {
      color: theme.palette.text.secondary
    },
    colorTextPrimary: {
      color: theme.palette.text.primary
    },
    colorParagraph: {
      color: theme.palette.text.paragraph || theme.palette.text.primary
    },
    colorError: {
      color: theme.palette.error.primary
    },
    colorWhite: {
      color: theme.palette.common.white
    },
    colorAccent: {
      color: theme.palette.accent?.main || theme.palette.secondary.main
    },
    colorDisabled: {
      color: theme.palette.text.disabled
    },
    whiteSpaceNormal: {
      whiteSpace: "normal"
    },
    whiteSpacePre: {
      whiteSpace: "pre"
    },
    whiteSpacePreLine: {
      whiteSpace: "pre-line"
    },
    letterSpacingNormal: {
      letterSpacing: "normal"
    },
    letterSpacingWide: {
      letterSpacing: "0.05em"
    },
    letterSpacingWider: {
      letterSpacing: "0.1em"
    }
  };
};

const defaultColors = ["initial", "inherit", "primary", "secondary", "textPrimary", "textSecondary", "error"];

function TTypography(props) {
  const {
    children,
    classes,
    className,
    letterSpacing,
    isLoading = false,
    skeletonWidth = 160,
    color = 'inherit',
    bold = false,
    semiBold = false,
    upperCase = false,
    whiteSpace = "inherit",
    ...other
  } = props;
  return <Typography color={defaultColors.indexOf(color) !== -1 ? color : undefined} className={clsx(letterSpacing ? classes[`letterSpacing${capitalize(letterSpacing)}`] : null, !isLoading ? classes[`color${capitalize(color)}`] : null, classes[`whiteSpace${capitalize(whiteSpace)}`], className, {
    [classes.bold]: bold,
    [classes.semiBold]: semiBold,
    [classes.upperCase]: upperCase
  })} classes={{
    root: classes.root
  }} {...other} componentname="t-typography">{isLoading ? <Skeleton variant={"text"} width={skeletonWidth} /> : children}
        </Typography>;
}

export default withStyles(styles, {})(TTypography);
