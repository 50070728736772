import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { T, withI18n } from "@ticketag/i18nlib";
import Form from "@ticketag/ticketag-uilib/src/components/complex/Form/Form";
import TextField from "@ticketag/ticketag-uilib/src/components/base/TextField/TextField";
import ComboBox from "@ticketag/ticketag-uilib/src/components/base/Select/ComboBox";
import { WebapiprotoDocTypes as DOC_TYPES } from "@ticketag/diveedi-client/src/model/WebapiprotoDocTypes";
import FileUploader from "@ticketag/ticketag-uilib/src/components/base/FileUploader/FileUploader";
import ExpirePicker from "@ticketag/ticketag-uilib/src/components/base/DateTimeInput/ExpirePicker";
import Button from "@ticketag/ticketag-uilib/src/components/base/Button/Button";
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { withModals } from "@ticketag/ticketag-uilib/src/components/hoc/Modals/Provider";
import { ERR_FILE_TOO_LARGE, ERR_FILE_NOT_VALID } from "@ticketag/ticketag-uilib/src/components/base/FileUploader/FileUploader";
import { FileTooLarge, InvalidFile } from "../../hoc/Modals/Modals";

const styles = theme => {
  return {
    root: {}
  };
};

const allowedDocTypes = [DOC_TYPES.ID, DOC_TYPES.PASSPORT];
export const getDocTypes = () => {
  return Object.keys(DOC_TYPES).filter(k => typeof DOC_TYPES[k] === 'string' && allowedDocTypes.indexOf(DOC_TYPES[k]) !== -1).map(k => ({
    value: DOC_TYPES[k],
    label: `enums.doc_types.${DOC_TYPES[k].toLowerCase()}`
  }));
};

const DocumentForm = props => {
  const docTypes = getDocTypes();
  const fileBox = {
    display: 'flex',
    justifyContent: 'center',
    my: 0
  };
  const inputBox = {
    my: 0
  };

  const onUploadError = err => {
    switch (err) {
      case ERR_FILE_TOO_LARGE:
        FileTooLarge(props.modals);
        break;

      default:
        InvalidFile(props.modals);
    }
  };

  return <Form T={T} onChange={() => {}} grid={{
    spacing: 7
  }} {...props} componentname="document-form">
        <Grid xs={12}>
            <Box mt={2}>
                <Typography align="center" variant="body1" color="textPrimary">
                    <T defaultVal="Devi caricare sia il fronte che il retro di un documento d’identità valido in formato jpg.">components.document_form.caption</T>
                </Typography>
            </Box>
        </Grid>
        <ComboBox grid={{
      md: 4
    }} showErrors box={inputBox} variant={'outlined'} label={"Tipo documento"} name="type" options={docTypes} getOptionLabel={option => <T key={option.label}>{option.label}</T>} rules={{
      required: true,
      validate: {
        match: (value, params, form) => value && allowedDocTypes.indexOf(value) !== -1,
        message: {
          key: 'components.document_form.select_type',
          defaultVal: 'Seleziona il tipo di documento'
        }
      }
    }} />

        <TextField grid={{
      md: 4
    }} box={inputBox} showErrors variant={'outlined'} label={"Numero documento"} name="docNumber" rules={{
      required: true
    }} />
        <ExpirePicker grid={{
      md: 4
    }} showErrors box={inputBox} variant={'outlined'} label={"Scadenza"} name="expireDate" rules={{
      required: true,
      min: {
        value: new Date(),
        type: 'date',
        message: {
          key: 'components.document_form.insert_valid_expiry',
          defaultVal: 'Inserisci una data di scadenza valida'
        }
      }
    }} />
        <FileUploader onError={onUploadError} box={fileBox} label="Carica il fronte del documento" name="frontImg" rules={{
      required: true
    }} />
        <FileUploader onError={onUploadError} box={fileBox} label="Carica il retro del documento" name="rearImg" rules={{
      required: true
    }} />
    </Form>;
};

export default withStyles(styles, {
  useTheme: true
})(DocumentForm);