
import React from 'react';
import Typography from "../../base/Typography/Typography";
import ChatBaloon from "./ChatBaloon";
import withStyles from "@material-ui/core/styles/withStyles";
import ReactMarkdown from 'react-markdown'
import clsx from "clsx";

const styles = (theme) => {
    return {
        eventMessage: {
            width: "calc(100% - 192px)",
            margin: "20px 48px",
            padding: "24px 48px",
            boxShadow: "-3px 3px 12px rgba(172, 172, 172, 0.08), 2px -2px 7px rgba(172, 172, 172, 0.08), 2px 2px 10px rgba(172, 172, 172, 0.08)",
            borderRadius: "3px"
        },
        markdown: {
            "& p": {
                margin: "0"
            }
        }
    }
}

// Crea una mappa le cui chiavi sono nel formato "${anno}${mese}" e i cui
// valori sono oggetti contenenti i valori ${giorno}: ${array di messaggi per il dato giorno}.
// La mappa così ottenuta viene trasformata in una lista di componenti react corrispondenti a
// messsaggi o header di date
function TChatContent(props) {
    const {
        classes,
        messages,
        translations,
        groupMembers,
        ...other
    } = props;
    function formatMonth(year, month, days) {
        return Object.entries(days).reduce((acc, elt) => {
            const [day, messages] = elt;
            return acc.concat(formatDays(year, month, day, messages))
        }, [])
    }
    function formatDays(year, month, day, messages) {
        return [
            <Typography gutterBottom variant={"h6"} color={"textSecondary"}>
                {day} {translations?.monthNames ? translations.monthNames[month]: month} {year}
            </Typography>
        ].concat(
            messages.map((message) => formatMessage(message))
        )
    }
    function formatMessage(message) {
        switch (message.ContentType) {
            case "text":
                return (
                    <ChatBaloon
                        position={message.IsSender ? "right" : "left"}
                        color={message.IsSender ? "secondary" : "primary"}
                        title={message.IsSender ? "Tu" : message.UserName} subtitle={
                        message.ReadedBy && message.ReadedBy.length > 0 ?
                            <React.Fragment>
                                { translations?.readBy ? <span style={{fontWeight: "bold"}}>{translations.readBy}: </span> : null }
                                { groupMembers ?
                                    message.ReadedBy.map((uuid) => groupMembers.find((member) => member.uuid === uuid)?.name || "") :
                                    message.ReadedBy.join(", ") }
                            </React.Fragment>
                            : undefined
                    }><div className={classes.markdown}><ReactMarkdown>{message.Content}</ReactMarkdown></div>
                    </ChatBaloon>
                )
            case "groupEventMessage":
            case "userEventMessage":
            default:
                return <div className={clsx(classes.eventMessage, classes.markdown)}><ReactMarkdown>{message.Content}</ReactMarkdown></div>
        }
    }
    const data = {};
    for (let message of messages) {
        const date = new Date(message.SentAt.seconds*10**3)
        const month = `${date.getFullYear()}${date.getMonth().toString().padStart(2,'0')}`
        const day = date.getDate()
        const monthData = data[month] || {}
        monthData[day] = [...monthData[day] || [], message]
        data[month] = {...data[month], ...monthData}
    }
    return Object.entries(data).reduce((acc, elt) => {
        const [month, days] = elt;
        return acc.concat(formatMonth(
            parseInt(month.substr(0, 4)),
            parseInt(month.substr(4, 5)),
            days
        ))
    }, [])
}


export default withStyles(styles, {})(TChatContent)