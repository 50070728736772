import ChipCard from "../ChipCard/ChipCard";
import AvatarIcon from "../../base/Avatar/AvatarIcon";
import Typography from "../../base/Typography/Typography";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import ListItem from "../../base/ListItem/ListItem";
import { Icon, RegisterIcon } from "../../base/Icon/Icon";
import Netflix from "../../../resources/icons/services/netflix.svg";
import Spotify from "../../../resources/icons/services/spotify.svg";
import Disney from "../../../resources/icons/services/disney+.svg";
import clsx from "clsx";
import { Badge } from "@material-ui/core";
import { useChatTab } from "./ChatTab";
import createChainedFunction from "@material-ui/core/utils/createChainedFunction";

const styles = theme => {
  return {
    root: {
      padding: "10px 12px",
      width: "181px"
    },
    variantLarge: {
      [theme.breakpoints.up("md")]: {
        minWidth: "312px"
      }
    },
    selected: {
      width: "181px",
      margin: "1px",
      position: "relative",
      background: "white",
      backgroundClip: "padding-box",
      alignItems: "center",
      border: "2px",
      borderRadius: "16px",
      "&::before": {
        content: "''",
        position: "absolute",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        zIndex: "-1",
        margin: "-2px",
        borderRadius: "inherit",
        background: theme.palette.default_gradient
      }
    },
    header: {
      marginBottom: "4px",
      "& h5": {
        fontSize: "16px",
        display: "inline",
        fontWeight: 500
      },
      "& h6": {
        fontWeight: "300",
        fontSize: "11px",
        paddingTop: theme.spacing(1),
        [theme.breakpoints.up("md")]: {
          display: "inline",
          paddingLeft: theme.spacing(1)
        }
      }
    },
    icon: {
      marginRight: "12px"
    },
    message: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      paddingTop: "6px",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    messageBadge: {
      transform: "scale(1) translate(-30%, -30%)",
      height: "18px"
    }
  };
};

export const NetflixIcon = RegisterIcon("netflix", Netflix);
export const SpotifyIcon = RegisterIcon("spotify", Spotify);
export const DisneyIcon = RegisterIcon("disney+", Disney);

function TChipCard(props) {
  const {
    isLoading: isLoadingProp,
    variant: variantProp,
    classes,
    className,
    avatar,
    title,
    label,
    subtitle,
    serviceName,
    unreadMessages,
    selected: selectedProp,
    onChange: onChangeProp,
    ...other
  } = props;
  const chatTab = useChatTab();
  let variant = variantProp || chatTab && chatTab.variant;
  let isLoading = isLoadingProp || chatTab && chatTab.isLoading;
  let selected = selectedProp;

  const onChange = evt => {
    chatTab && chatTab.onChange(evt, serviceName);
  };

  if (chatTab) {
    if (typeof selected === 'undefined') {
      selected = chatTab.value === serviceName;
    }
  }

  return <ChipCard classes={{
    root: clsx(classes.root, variant === "large" ? classes.variantLarge : null, selected ? classes.selected : null),
    skeleton: clsx(classes.root, isLoading ? classes.skeleton : null)
  }} onClick={onChange} isLoading={isLoading} {...other} componentname="t-chip-card">
                {!isLoading ? <ListItem classes={{
      prepend: classes.icon
    }} prepend={<Badge color={"error"} badgeContent={unreadMessages} anchorOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }} classes={{
      badge: classes.messageBadge
    }}>
                                    {avatar || <Icon size='large'>{serviceName}</Icon>}
                                </Badge>}>
                            <div className={classes.header}>
                                <Typography color={"textSecondary"} variant="h5">{title}</Typography>
                                {variant === "large" ? <Typography color={"textPrimary"} variant="h6">{label}</Typography> : null}
                            </div>
                            {variant === "large" ? <Typography className={classes.message} noWrap={true} color={"textPrimary"} variant="caption">{subtitle}</Typography> : <Typography color={"textPrimary"} noWrap={true} variant="caption">{subtitle}</Typography>}
                        </ListItem> : null}
            </ChipCard>;
}

export default withStyles(styles, {
  useTheme: true
})(TChipCard);
