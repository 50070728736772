import React, {Component} from "react";
import ChatScreen from "@ticketag/ticketag-uilib/src/components/complex/Chat/ChatScreen";
import Data from "../../data";
import {withAuth} from "../../hoc/Auth/Provider";
import {T, withI18n} from "@ticketag/i18nlib";
import {withApi} from "../../hoc/Api/Provider";
import ChatContent from "@ticketag/ticketag-uilib/src/components/complex/Chat/ChatContent";
import Box from "@material-ui/core/Box";



class TChat extends Component {

    constructor(props) {
        super(props);
        this.chat = props.chat;
        this.state = {
            isLoaded: false,
            groupDetails: {},
            messages: this.chat.messages || [],
        }
        this.onSubmit = this.onSubmit.bind(this)
        this.chatContentRef = React.createRef()
    }

    componentDidMount() {
        this.openChat()

    }

    componentWillUnmount() {
        this.closeChat()
    }

    openChat() {
        return this.chat.open(resp => {
            this.setState({
                ...this.state,
                messages: resp.messages,
            })
            this.scrollChat()
        }).then((resp) => {
            this.setState({
                ...this.state,
                groupDetails: resp.groupDetails,
                isLoaded: true,
            })
            this.scrollChat()
        })
    }
    scrollChat() {
        const chatContent = document.getElementById('chat-content');
        if (chatContent) {
            chatContent.scrollTop = chatContent.scrollHeight;
        }
    }
    closeChat() {
        this.chat.close()
    }

    onSubmit(evt, value) {
        this.chat.send(value);
        console.log(this.chatContentRef)
//        this.props.onSubmit & this.props.onSubmit(evt)
    }

    render() {
        const {
            auth,
            data = Data.Chat,
            onClose,
            onSubmit = () => {
            },
            ...other
        } = this.props;
        if (this.props.isLoading || !this.state.isLoaded) {
            return <ChatScreen isLoading/>
        }
        const groupMembers = this.state.groupDetails?.members
        return (
            <ChatScreen
                onClose={(evt) => {
                    this.closeChat();
                    onClose && onClose(evt)
                }}
                onSubmit={(evt, value) => {
                    this.chat.send(value);
                    onSubmit(evt)
                }}
                {...other}
            >
                <ChatContent
                    messages={this.chat.messages}
                    groupMembers={groupMembers}
                    translations={{
                        you: data.baloon.you,
                        readBy: data.baloon.readBy,
                        monthNames: [
                            "january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"
                        ].map(
                            (month) => <T defaultVal={month}>{`global.months.${month}`}</T>
                        ),
                    }}
                />
            </ChatScreen>
        );
    }

}

export default withApi(withI18n(withAuth(TChat)));
