import {T, withI18n} from "@ticketag/i18nlib";
import TextField from "@ticketag/ticketag-uilib/src/components/base/TextField/TextField";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Box from "@material-ui/core/Box";
import FormField from "@ticketag/ticketag-uilib/src/components/base/Field/FormField";
import Grid from "@material-ui/core/Grid";
import {fade} from "@material-ui/core/styles";

const styles = (theme) => {
    return {
        container: {
            borderRadius: "16px",
            backgroundColor: fade(theme.palette.primary.light, 0.3)
        }
    }
}

function TConfirmForm(props) {
    const {
        i18n,
        classes,
        confirmKey = <T defaultVal={"conferma"}>global.hidden.confirm</T>,
        onChange,
        ...other
    } = props;
    const [key,setKey] = React.useState(null)
    React.useEffect(() => {
        i18n.getValue(confirmKey.props.children, confirmKey.props.defaultVal).then((val) => {
            setKey(val)
        })
    },[])
    return (
        <Box>
            {
                i18n.editMode && key !== null ?
                    <Box className={classes.container} p={4} pt={2} mb={4}>
                        <FormField T={T}
                                   name={"confirmKey"}
                                   label={<T defaultVal={"Chiave"}>global.hidden.key</T>}
                                   helperText={<T defaultVal={'Modifica'}>global.hidden.edit</T>}
                                   defaultValue={key}
                                   onChange={(val) => {
                                       i18n.setValue(confirmKey.props.children, val)
                                       setKey(val)
                                   }}
                        />
                    </Box> :
                    null
            }
            <TextField
                onChange={(e, val)=> {
                    onChange(e, val.toLowerCase() === key.toLowerCase())
                }}
                variant="outlined"
                label={<T defaultVal="Conferma">modals.confirm_close_group.confirm_label</T>}
                {...other}
            />
        </Box>
    )
}

export default withStyles(styles)(withI18n(TConfirmForm))
