import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T, withI18n} from "@ticketag/i18nlib";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import Button from "@ticketag/ticketag-uilib/src/components/base/Button/Button";
import BaseActionCard from "@ticketag/ticketag-uilib/src/components/complex/BaseActionCard/BaseActionCard";
import Box from "@material-ui/core/Box/Box";
import { useHistory } from "react-router-dom";



const styles = (theme) => {
    return {
        root: {
            justifyContent: 'space-between',
            cursor: 'initial'
        },
        textContainer: {
            paddingBottom: theme.spacing(3),
            width: `calc(100% - ${theme.spacing(4)}px)`
        },
        logo: {
            maxWidth: '100%',
            height: '56px',
            marginBottom: `${theme.spacing(2)}px`
        }
    }
};

const SelectGroupCard = ({classes, group, onClick, serviceTypeLogo, ...props}) => {
    const history = useHistory();
    // /groups/:groupUuid/join/billing
    return <BaseActionCard clickDisabled classes={{root: classes.root}} {...props}>
        <img className={classes.logo} src={serviceTypeLogo}/>
        <Box px={2} classes={{root: classes.textContainer}} display="flex" flexDirection="column" alignItems="flex-start">
            <Box pb={1}><Typography variant={'h4'}>di <b>{group.ownerName}</b></Typography></Box>
            <Box pb={1.5}><Typography variant={'caption'}>PAESE: <b>Italia</b></Typography></Box>
            <Box pb={3} display="flex" alignItems="flex-end"><Typography variant={'h2'} color={'gradient'}>{(group.price/100).toFixed(2)}</Typography> <Typography variant={'caption'} color={'gradient'}>€/mese</Typography></Box>
            <Button color={'gradient'} variant="outlined" fullWidth onClick={onClick}>Entra</Button>
        </Box>
    </BaseActionCard>
}

export default withStyles(styles, {useTheme: true})(withI18n(SelectGroupCard));
