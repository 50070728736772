import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { T } from "@ticketag/i18nlib";
import Box from "@material-ui/core/Box/Box";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import Button from "@ticketag/ticketag-uilib/src/components/base/Button/Button";

const styles = theme => {
  return {
    root: {
      position: "relative",
      minHeight: "720px",
      height: '100vh',
      //"calc(100vh - 148px)",
      [theme.breakpoints.down('md')]: {
        height: "auto"
      }
    },
    backgroundImage: {
      top: "0",
      width: "48vw",
      height: "100%",
      maxHeight: "900px",
      right: "100%",
      zIndex: "-1",
      position: "absolute",
      background: `url('${process.env.PUBLIC_URL || ""}/img/homepage/grafica_home_03.svg') no-repeat center`,
      backgroundSize: "contain",
      transform: "translateX(70px)",
      [theme.breakpoints.down('lg')]: {
        width: "40vw"
      },
      [theme.breakpoints.down('md')]: {
        position: "absolute",
        transform: "none",
        width: "335px",
        height: "300px",
        left: "0",
        right: "unset",
        top: "calc(100% + 30px)"
      }
    },
    backgroundImageLeft: {
      top: "150px",
      width: "52vw",
      height: "100%",
      maxHeight: "560px",
      left: "-26vw",
      zIndex: "-2",
      position: "absolute",
      background: `url('${process.env.PUBLIC_URL || ""}/img/homepage/grafica_home_03_luna.svg') no-repeat center`,
      backgroundSize: "contain",
      [theme.breakpoints.down('lg')]: {
        width: "40vw"
      },
      [theme.breakpoints.down('md')]: {
        position: "absolute",
        right: "0",
        left: "unset",
        top: "440px",
        width: "300px",
        height: "300px",
        transform: "scaleX(-1) translateX(-30%)",
        maxWidth: "320px"
      }
    },
    boxRoot: {
      position: "relative",
      height: "100%",
      maxWidth: "612px",
      marginRight: "8.333vw",
      marginLeft: "auto",
      [theme.breakpoints.down('md')]: {
        margin: "0",
        padding: "0 25px"
      }
    },
    title: {
      fontWeight: "500",
      fontSize: "40px",
      lineHeight: "48px",
      [theme.breakpoints.down('md')]: {
        fontSize: "30px",
        lineHeight: "29px"
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: "24px",
        lineHeight: "29px"
      }
    },
    titleNumber: {
      fontWeight: "500",
      fontSize: "100px",
      lineHeight: "120px",
      marginLeft: theme.spacing(4),
      [theme.breakpoints.down('md')]: {
        fontSize: "50px",
        marginLeft: theme.spacing(2)
      },
      [theme.breakpoints.down('md')]: {
        fontSize: "60px",
        lineHeight: "72px",
        marginLeft: theme.spacing(3)
      }
    },
    subtitle: {
      fontWeight: "300",
      fontSize: "18px",
      marginTop: theme.spacing(2),
      letterSpacing: "0.05em"
    },
    obtainButton: {
      visibility: "hidden", // nascosto in attesa di destinazione
      width: "128px",
      marginTop: theme.spacing(2)
    }
  };
};

const defaultTitle = classes => {
  return <Box display="flex" alignItems="center">
        <Typography whiteSpace={"preLine"} classes={{
      root: classes.title
    }} variant={'h2'} color="accent">
            <T multiline defaultVal={"TRE SERVIZI AL PREZZO DI UNO"}>pages.homepage.promotion_section_title</T>
        </Typography>
        <Typography classes={{
      root: classes.titleNumber
    }} variant={'h1'} component={"h2"} color="accent">
            <T defaultVal={"3x1"}>pages.homepage.promotion_section_title_number</T>
        </Typography>
    </Box>;
};

const defaultSubtitle = classes => {
  return <Typography whiteSpace={"preLine"} classes={{
    root: classes.subtitle
  }} variant={'h4'} color="accent">
        <T multiline defaultVal={"Partecipa ad un gruppo per Netflix, Spotify e Disney + su Diveedi"}>pages.homepage.promotion_section_subtitle</T>
    </Typography>;
};

export const PromotionSection = ({
  classes = {},
  title = defaultTitle(classes),
  subtitle = defaultSubtitle(classes),
  ...props
}) => {
  return <Box {...props} classes={{
    root: classes.root
  }} componentname="promotion-section">
        <Box classes={{
      root: classes.boxRoot
    }} display="flex" flexDirection="column" justifyContent={{
      sm: "center"
    }}>
            {title}
            {subtitle}
            <Button classes={{
        root: classes.obtainButton
      }} href="#" variant={"contained"} color="accent"><T defaultVal="Ottieni">forms.buttons.obtain</T></Button>
            <div className={classes.backgroundImage}></div>
        </Box>
        <div className={classes.backgroundImageLeft}></div>
    </Box>;
};
export default withStyles(styles, {
  useTheme: true
})(PromotionSection);
