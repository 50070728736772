/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from "../ApiClient";
import {WebapiprotoAvatar} from '../model/WebapiprotoAvatar';
import {WebapiprotoAvatars} from '../model/WebapiprotoAvatars';
import {WebapiprotoEmptyMsg} from '../model/WebapiprotoEmptyMsg';
import {WebapiprotoGenericConfirm} from '../model/WebapiprotoGenericConfirm';
import {WebapiprotoIdDocument} from '../model/WebapiprotoIdDocument';
import {WebapiprotoPaymentMethods} from '../model/WebapiprotoPaymentMethods';
import {WebapiprotoSetupPaymentMethodMsg} from '../model/WebapiprotoSetupPaymentMethodMsg';
import {WebapiprotoUnlockMsg} from '../model/WebapiprotoUnlockMsg';
import {WebapiprotoUpdatePasswordRequest} from '../model/WebapiprotoUpdatePasswordRequest';
import {WebapiprotoUserGroups} from '../model/WebapiprotoUserGroups';
import {WebapiprotoUserInfo} from '../model/WebapiprotoUserInfo';
import {WebapiprotoUserPreferences} from '../model/WebapiprotoUserPreferences';
import {WebapiprotoUserPreferencesUpdateMsg} from '../model/WebapiprotoUserPreferencesUpdateMsg';
import {WebapiprotoUserProfile} from '../model/WebapiprotoUserProfile';
import {WebapiprotoUserProfiles} from '../model/WebapiprotoUserProfiles';

/**
* User service.
* @module api/UserApi
* @version v0.4
*/
export class UserApi {

    /**
    * Constructs a new UserApi. 
    * @alias module:api/UserApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Crea un nuovo metodo di pagamento per l'utente corrente
     * Ottiene client secret per aggiungere un metodo di pagamento
     * @param {module:model/WebapiprotoEmptyMsg} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoSetupPaymentMethodMsg} and HTTP response
     */
    createPaymentMethodWithHttpInfo(body) {
      let postBody = body;

      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling createPaymentMethod");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoSetupPaymentMethodMsg;

      return this.apiClient.callApi(
        '/api/v1/payment_method', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Crea un nuovo metodo di pagamento per l'utente corrente
     * Ottiene client secret per aggiungere un metodo di pagamento
     * @param {module:model/WebapiprotoEmptyMsg} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoSetupPaymentMethodMsg}
     */
    createPaymentMethod(body) {
      return this.createPaymentMethodWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Elimina account utente
     * Elimina account utente
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoGenericConfirm} and HTTP response
     */
    deleteAccountWithHttpInfo() {
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoGenericConfirm;

      return this.apiClient.callApi(
        '/api/v1/user/delete', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Elimina account utente
     * Elimina account utente
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoGenericConfirm}
     */
    deleteAccount() {
      return this.deleteAccountWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Elimina un metodo di pagamento
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoGenericConfirm} and HTTP response
     */
    deletePaymentMethodWithHttpInfo(uuid) {
      let postBody = null;

      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling deletePaymentMethod");
      }


      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoGenericConfirm;

      return this.apiClient.callApi(
        '/api/v1/payment_method/{uuid}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Elimina un metodo di pagamento
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoGenericConfirm}
     */
    deletePaymentMethod(uuid) {
      return this.deletePaymentMethodWithHttpInfo(uuid)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Imposta profilo di fatturazione come principale Elimina profilo di fatturazione
     * Elimina profilo di fatturazione
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoGenericConfirm} and HTTP response
     */
    deleteProfileWithHttpInfo(uuid) {
      let postBody = null;

      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling deleteProfile");
      }


      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoGenericConfirm;

      return this.apiClient.callApi(
        '/api/v1/user/profile/{uuid}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Imposta profilo di fatturazione come principale Elimina profilo di fatturazione
     * Elimina profilo di fatturazione
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoGenericConfirm}
     */
    deleteProfile(uuid) {
      return this.deleteProfileWithHttpInfo(uuid)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Ottiene tutti gli avatar disponibili
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoAvatar} and HTTP response
     */
    getAvatarWithHttpInfo(uuid) {
      let postBody = null;

      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling getAvatar");
      }


      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoAvatar;

      return this.apiClient.callApi(
        '/api/v1/avatar/{uuid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Ottiene tutti gli avatar disponibili
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoAvatar}
     */
    getAvatar(uuid) {
      return this.getAvatarWithHttpInfo(uuid)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Ottiene tutti gli avatar disponibili
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoAvatars} and HTTP response
     */
    getAvatarsWithHttpInfo() {
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoAvatars;

      return this.apiClient.callApi(
        '/api/v1/avatar', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Ottiene tutti gli avatar disponibili
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoAvatars}
     */
    getAvatars() {
      return this.getAvatarsWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Ottiene i dettagli del documento selezionato
     * @param {String} idDocumentUuid 
     * @param {Object} opts Optional parameters
     * @param {String} opts.userUuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoIdDocument} and HTTP response
     */
    getIdDocumentWithHttpInfo(idDocumentUuid, opts) {
      opts = opts || {};
      let postBody = null;

      // verify the required parameter 'idDocumentUuid' is set
      if (idDocumentUuid === undefined || idDocumentUuid === null) {
        throw new Error("Missing the required parameter 'idDocumentUuid' when calling getIdDocument");
      }


      let pathParams = {
        'id_document_uuid': idDocumentUuid
      };
      let queryParams = {
        'user_uuid': opts['userUuid']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoIdDocument;

      return this.apiClient.callApi(
        '/api/v1/user/document/{id_document_uuid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Ottiene i dettagli del documento selezionato
     * @param {String} idDocumentUuid 
     * @param {Object} opts Optional parameters
     * @param {String} opts.userUuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoIdDocument}
     */
    getIdDocument(idDocumentUuid, opts) {
      return this.getIdDocumentWithHttpInfo(idDocumentUuid, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Ottiene i metodi di pagamento di un utente
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoPaymentMethods} and HTTP response
     */
    getPaymentMethodsWithHttpInfo() {
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoPaymentMethods;

      return this.apiClient.callApi(
        '/api/v1/payment_method', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Ottiene i metodi di pagamento di un utente
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoPaymentMethods}
     */
    getPaymentMethods() {
      return this.getPaymentMethodsWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Ottiene i gruppi a cui l'utente partecipa
     * Ottiene i gruppi a cui l'utente partecipa
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoUserGroups} and HTTP response
     */
    getUserGroupsWithHttpInfo() {
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoUserGroups;

      return this.apiClient.callApi(
        '/api/v1/user/groups', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Ottiene i gruppi a cui l'utente partecipa
     * Ottiene i gruppi a cui l'utente partecipa
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoUserGroups}
     */
    getUserGroups() {
      return this.getUserGroupsWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Informazioni base utente
     * Informazioni base utente
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoUserInfo} and HTTP response
     */
    getUserInfoWithHttpInfo() {
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoUserInfo;

      return this.apiClient.callApi(
        '/api/v1/user', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Informazioni base utente
     * Informazioni base utente
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoUserInfo}
     */
    getUserInfo() {
      return this.getUserInfoWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Ottiene le impostazioni dell'account dell'utente corrente
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoUserPreferences} and HTTP response
     */
    getUserPreferencesWithHttpInfo() {
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoUserPreferences;

      return this.apiClient.callApi(
        '/api/v1/user/preferences', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Ottiene le impostazioni dell'account dell'utente corrente
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoUserPreferences}
     */
    getUserPreferences() {
      return this.getUserPreferencesWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Ottiene i profili di fatturazione per l'utente corrente
     * Ottiene i profili di fatturazione per l'utente corrente
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoUserProfiles} and HTTP response
     */
    getUserProfilesWithHttpInfo() {
      let postBody = null;


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoUserProfiles;

      return this.apiClient.callApi(
        '/api/v1/user/profiles', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Ottiene i profili di fatturazione per l'utente corrente
     * Ottiene i profili di fatturazione per l'utente corrente
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoUserProfiles}
     */
    getUserProfiles() {
      return this.getUserProfilesWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Sblocca il profilo di un utente per successive modifiche
     * @param {module:model/WebapiprotoUnlockMsg} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoGenericConfirm} and HTTP response
     */
    unlockUserWithHttpInfo(body) {
      let postBody = body;

      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling unlockUser");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoGenericConfirm;

      return this.apiClient.callApi(
        '/api/v1/user/unlock', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Sblocca il profilo di un utente per successive modifiche
     * @param {module:model/WebapiprotoUnlockMsg} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoGenericConfirm}
     */
    unlockUser(body) {
      return this.unlockUserWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Aggiorna i dettagli del documento selezionato
     * @param {module:model/WebapiprotoIdDocument} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoIdDocument} and HTTP response
     */
    updateIdDocumentWithHttpInfo(body) {
      let postBody = body;

      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling updateIdDocument");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoIdDocument;

      return this.apiClient.callApi(
        '/api/v1/user/document', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Aggiorna i dettagli del documento selezionato
     * @param {module:model/WebapiprotoIdDocument} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoIdDocument}
     */
    updateIdDocument(body) {
      return this.updateIdDocumentWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Modifica password
     * Modifica password
     * @param {module:model/WebapiprotoUpdatePasswordRequest} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoGenericConfirm} and HTTP response
     */
    updatePasswordWithHttpInfo(body) {
      let postBody = body;

      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling updatePassword");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoGenericConfirm;

      return this.apiClient.callApi(
        '/api/v1/user/password', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Modifica password
     * Modifica password
     * @param {module:model/WebapiprotoUpdatePasswordRequest} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoGenericConfirm}
     */
    updatePassword(body) {
      return this.updatePasswordWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Aggiorna le informazioni base dell'utente corrente
     * @param {module:model/WebapiprotoUserPreferencesUpdateMsg} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoUserPreferences} and HTTP response
     */
    updateUserPreferencesWithHttpInfo(body) {
      let postBody = body;

      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling updateUserPreferences");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoUserPreferences;

      return this.apiClient.callApi(
        '/api/v1/user/preferences', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Aggiorna le informazioni base dell'utente corrente
     * @param {module:model/WebapiprotoUserPreferencesUpdateMsg} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoUserPreferences}
     */
    updateUserPreferences(body) {
      return this.updateUserPreferencesWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Modifica Profilo di fatturazione
     * Modifica Profilo di fatturazione
     * @param {module:model/WebapiprotoUserProfile} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoUserProfile} and HTTP response
     */
    updateUserProfileWithHttpInfo(body) {
      let postBody = body;

      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling updateUserProfile");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoUserProfile;

      return this.apiClient.callApi(
        '/api/v1/user/profile', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Modifica Profilo di fatturazione
     * Modifica Profilo di fatturazione
     * @param {module:model/WebapiprotoUserProfile} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoUserProfile}
     */
    updateUserProfile(body) {
      return this.updateUserProfileWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
