import React from "react";
import {withRedirect} from "../../components/ErrorMessage/ErrorMessage";
import {withI18n} from "@ticketag/i18nlib";
import {withApi} from "../../hoc/Api/Provider";
import {Redirect, withRouter} from "react-router-dom"
import Progress from "@ticketag/ticketag-uilib/src/components/base/Progress/Progress";
import Layout from "../../hoc/Layout/Layout";
import {withGlobalLoader} from "../../hoc/GlobalLoader/Provider";


class createGroupAfterLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            group: null,
            serviceFamily: null,
            serviceType: null,
            service: null,
        }
    }
    componentDidMount() {
        this.props.loader.Show()
        withRedirect(this.props.history, this.props.api.GetServiceFamilyBySlug(this.props.match.params.serviceFamily).then((family) => {
            this.props.api.GetServiceTypeBySlug(family.uuid, this.props.match.params.serviceType).then((serviceType) => {
                this.props.api.serviceApi.getService(serviceType.uuid).then((service) => {
                    const uuid = service.details[0]?.uuid
                    this.props.api.groupApi.createServiceGroup(uuid, {}).then((group) => {
                        this.setState({
                            loaded: true,
                            group: group,
                            serviceType: serviceType,
                            serviceFamily: family,
                            service: service,
                        })
                    })
                })
            })
        }))
    }
/*
        withRedirect(this.props.history, this.props.api.serviceApi.getService(serviceType).then(service => {
            const uuid = service.details[0]?.uuid
            this.props.api.groupApi.createServiceGroup(uuid, {}).then((group) => {
                this.props.history.push(this.props.i18n.buildLocalizedUrl("groups", group.uuid, "create"))
            })
        }))
    }
*/
    nextUrl(groupUuid) {
        return this.props.i18n.buildLocalizedUrl("groups", groupUuid, "create", "visibility")
    }

    render() {
        const isLoading = !this.state.loaded
        return isLoading ?
            null
            :
            <Redirect to={this.nextUrl(this.state.group.uuid)}/>
    }
}

const CreateGroupAfterLogin = withI18n(withApi(withGlobalLoader(withRouter(createGroupAfterLogin))))

export default CreateGroupAfterLogin;