/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from "../ApiClient";
import {WebapiprotoBannerUuid} from '../model/WebapiprotoBannerUuid';
import {WebapiprotoCloseGroupConfirm} from '../model/WebapiprotoCloseGroupConfirm';
import {WebapiprotoCreateDisputeData} from '../model/WebapiprotoCreateDisputeData';
import {WebapiprotoGenericConfirm} from '../model/WebapiprotoGenericConfirm';
import {WebapiprotoGroupAccount} from '../model/WebapiprotoGroupAccount';
import {WebapiprotoGroupCard} from '../model/WebapiprotoGroupCard';
import {WebapiprotoGroupDetail} from '../model/WebapiprotoGroupDetail';
import {WebapiprotoGroupDetailsData} from '../model/WebapiprotoGroupDetailsData';
import {WebapiprotoInvitationToken} from '../model/WebapiprotoInvitationToken';
import {WebapiprotoJoinGroupRequestData} from '../model/WebapiprotoJoinGroupRequestData';
import {WebapiprotoJoinRequest} from '../model/WebapiprotoJoinRequest';
import {WebapiprotoKickUserData} from '../model/WebapiprotoKickUserData';
import {WebapiprotoLeaveGroupConfirm} from '../model/WebapiprotoLeaveGroupConfirm';
import {WebapiprotoSetAccountData} from '../model/WebapiprotoSetAccountData';

/**
* Group service.
* @module api/GroupApi
* @version v0.4
*/
export class GroupApi {

    /**
    * Constructs a new GroupApi. 
    * @alias module:api/GroupApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * Richiesta di verificare il token d'invito
     * Richiesta di verificare il token d'invito
     * @param {module:model/WebapiprotoInvitationToken} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoGroupCard} and HTTP response
     */
    checkInvitationTokenWithHttpInfo(body) {
      let postBody = body;

      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling checkInvitationToken");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoGroupCard;

      return this.apiClient.callApi(
        '/api/v1/group/invitation', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Richiesta di verificare il token d'invito
     * Richiesta di verificare il token d'invito
     * @param {module:model/WebapiprotoInvitationToken} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoGroupCard}
     */
    checkInvitationToken(body) {
      return this.checkInvitationTokenWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Chiude un gruppo (disponibile solo per group admin)
     * Chiude un gruppo (disponibile solo per group admin)
     * @param {String} uuid Group uuid
     * @param {module:model/WebapiprotoCloseGroupConfirm} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoGenericConfirm} and HTTP response
     */
    closeGroupWithHttpInfo(uuid, body) {
      let postBody = body;

      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling closeGroup");
      }

      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling closeGroup");
      }


      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoGenericConfirm;

      return this.apiClient.callApi(
        '/api/v1/group/{uuid}/close', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Chiude un gruppo (disponibile solo per group admin)
     * Chiude un gruppo (disponibile solo per group admin)
     * @param {String} uuid Group uuid
     * @param {module:model/WebapiprotoCloseGroupConfirm} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoGenericConfirm}
     */
    closeGroup(uuid, body) {
      return this.closeGroupWithHttpInfo(uuid, body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Chiude il banner indicato
     * @param {String} uuid 
     * @param {module:model/WebapiprotoBannerUuid} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoGroupDetail} and HTTP response
     */
    closeGroupBannerWithHttpInfo(uuid, body) {
      let postBody = body;

      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling closeGroupBanner");
      }

      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling closeGroupBanner");
      }


      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoGroupDetail;

      return this.apiClient.callApi(
        '/api/v1/group/{uuid}/banner/close', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Chiude il banner indicato
     * @param {String} uuid 
     * @param {module:model/WebapiprotoBannerUuid} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoGroupDetail}
     */
    closeGroupBanner(uuid, body) {
      return this.closeGroupBannerWithHttpInfo(uuid, body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Completa il flusso di creazione del gruppo
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoGroupDetail} and HTTP response
     */
    completeGroupWithHttpInfo(uuid) {
      let postBody = null;

      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling completeGroup");
      }


      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoGroupDetail;

      return this.apiClient.callApi(
        '/api/v1/group/{uuid}/complete', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Completa il flusso di creazione del gruppo
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoGroupDetail}
     */
    completeGroup(uuid) {
      return this.completeGroupWithHttpInfo(uuid)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Conferma la richiesta di unione ad un gruppo
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoGenericConfirm} and HTTP response
     */
    confirmGroupJoinWithHttpInfo(uuid) {
      let postBody = null;

      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling confirmGroupJoin");
      }


      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoGenericConfirm;

      return this.apiClient.callApi(
        '/api/v1/join_request/{uuid}/confirm', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Conferma la richiesta di unione ad un gruppo
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoGenericConfirm}
     */
    confirmGroupJoin(uuid) {
      return this.confirmGroupJoinWithHttpInfo(uuid)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * RIchiesta di creare un token d'invito al gruppo
     * Richiesta dell'utente di generate un token d'invito al gruppo
     * @param {String} groupUuid 
     * @param {module:model/String} inviteType 
     * @param {Object} opts Optional parameters
     * @param {String} opts.mailAddress 
     * @param {String} opts.baseUrl 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoInvitationToken} and HTTP response
     */
    createInvitationTokenWithHttpInfo(groupUuid, inviteType, opts) {
      opts = opts || {};
      let postBody = null;

      // verify the required parameter 'groupUuid' is set
      if (groupUuid === undefined || groupUuid === null) {
        throw new Error("Missing the required parameter 'groupUuid' when calling createInvitationToken");
      }

      // verify the required parameter 'inviteType' is set
      if (inviteType === undefined || inviteType === null) {
        throw new Error("Missing the required parameter 'inviteType' when calling createInvitationToken");
      }


      let pathParams = {
        'groupUuid': groupUuid,
        'inviteType': inviteType
      };
      let queryParams = {
        'mail_address': opts['mailAddress'],
        'base_url': opts['baseUrl']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoInvitationToken;

      return this.apiClient.callApi(
        '/api/v1/group/{groupUuid}/invite/{inviteType}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * RIchiesta di creare un token d'invito al gruppo
     * Richiesta dell'utente di generate un token d'invito al gruppo
     * @param {String} groupUuid 
     * @param {module:model/String} inviteType 
     * @param {Object} opts Optional parameters
     * @param {String} opts.mailAddress 
     * @param {String} opts.baseUrl 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoInvitationToken}
     */
    createInvitationToken(groupUuid, inviteType, opts) {
      return this.createInvitationTokenWithHttpInfo(groupUuid, inviteType, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Apre una contestazione di pagamento
     * Apre una richiesta di contestazione
     * @param {String} uuid 
     * @param {module:model/WebapiprotoCreateDisputeData} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoGenericConfirm} and HTTP response
     */
    createPaymentDisputeWithHttpInfo(uuid, body) {
      let postBody = body;

      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling createPaymentDispute");
      }

      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling createPaymentDispute");
      }


      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoGenericConfirm;

      return this.apiClient.callApi(
        '/api/v1/group/{uuid}/dispute', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Apre una contestazione di pagamento
     * Apre una richiesta di contestazione
     * @param {String} uuid 
     * @param {module:model/WebapiprotoCreateDisputeData} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoGenericConfirm}
     */
    createPaymentDispute(uuid, body) {
      return this.createPaymentDisputeWithHttpInfo(uuid, body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Crea un nuovo gruppo
     * Creazione di un nuovo gruppo
     * @param {String} serviceUuid Service Uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoGroupDetail} and HTTP response
     */
    createServiceGroupWithHttpInfo(serviceUuid) {
      let postBody = null;

      // verify the required parameter 'serviceUuid' is set
      if (serviceUuid === undefined || serviceUuid === null) {
        throw new Error("Missing the required parameter 'serviceUuid' when calling createServiceGroup");
      }


      let pathParams = {
        'service_uuid': serviceUuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoGroupDetail;

      return this.apiClient.callApi(
        '/api/v1/service/{service_uuid}/groups', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Crea un nuovo gruppo
     * Creazione di un nuovo gruppo
     * @param {String} serviceUuid Service Uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoGroupDetail}
     */
    createServiceGroup(serviceUuid) {
      return this.createServiceGroupWithHttpInfo(serviceUuid)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Elimina una richiesta di partecipazione
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoGenericConfirm} and HTTP response
     */
    deleteJoinRequestWithHttpInfo(uuid) {
      let postBody = null;

      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling deleteJoinRequest");
      }


      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoGenericConfirm;

      return this.apiClient.callApi(
        '/api/v1/join_request/{uuid}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Elimina una richiesta di partecipazione
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoGenericConfirm}
     */
    deleteJoinRequest(uuid) {
      return this.deleteJoinRequestWithHttpInfo(uuid)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Ottiene le credenziali di accesso di un gruppo
     * Ottiene le credenziali di accesso di un gruppo
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoGroupAccount} and HTTP response
     */
    getGroupAccountWithHttpInfo(uuid) {
      let postBody = null;

      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling getGroupAccount");
      }


      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoGroupAccount;

      return this.apiClient.callApi(
        '/api/v1/group/{uuid}/account', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Ottiene le credenziali di accesso di un gruppo
     * Ottiene le credenziali di accesso di un gruppo
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoGroupAccount}
     */
    getGroupAccount(uuid) {
      return this.getGroupAccountWithHttpInfo(uuid)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Ottiene una richiesta di partecipazione
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoJoinRequest} and HTTP response
     */
    getGroupJoinRequestWithHttpInfo(uuid) {
      let postBody = null;

      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling getGroupJoinRequest");
      }


      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoJoinRequest;

      return this.apiClient.callApi(
        '/api/v1/group/{uuid}/join_request', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Ottiene una richiesta di partecipazione
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoJoinRequest}
     */
    getGroupJoinRequest(uuid) {
      return this.getGroupJoinRequestWithHttpInfo(uuid)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Ottiene una richiesta di partecipazione
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoJoinRequest} and HTTP response
     */
    getJoinRequestWithHttpInfo(uuid) {
      let postBody = null;

      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling getJoinRequest");
      }


      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoJoinRequest;

      return this.apiClient.callApi(
        '/api/v1/join_request/{uuid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Ottiene una richiesta di partecipazione
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoJoinRequest}
     */
    getJoinRequest(uuid) {
      return this.getJoinRequestWithHttpInfo(uuid)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Ottiene i dettagli di un gruppo a cui l'utente partecipa o ne è amministratore
     * Ottiene i dettagli di un gruppo a cui l'utente partecipa o ne è amministratore
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoGroupDetail} and HTTP response
     */
    groupDetailsWithHttpInfo(uuid) {
      let postBody = null;

      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling groupDetails");
      }


      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoGroupDetail;

      return this.apiClient.callApi(
        '/api/v1/group/{uuid}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Ottiene i dettagli di un gruppo a cui l'utente partecipa o ne è amministratore
     * Ottiene i dettagli di un gruppo a cui l'utente partecipa o ne è amministratore
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoGroupDetail}
     */
    groupDetails(uuid) {
      return this.groupDetailsWithHttpInfo(uuid)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Richiesta di partecipazione ad un gruppo
     * @param {module:model/WebapiprotoJoinGroupRequestData} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoJoinRequest} and HTTP response
     */
    joinGroupWithHttpInfo(body) {
      let postBody = body;

      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling joinGroup");
      }


      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoJoinRequest;

      return this.apiClient.callApi(
        '/api/v1/join_request/create', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Richiesta di partecipazione ad un gruppo
     * @param {module:model/WebapiprotoJoinGroupRequestData} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoJoinRequest}
     */
    joinGroup(body) {
      return this.joinGroupWithHttpInfo(body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Elimina un utente da un gruppo
     * Elimina un utente da un gruppo (disponibile solo per group admin)
     * @param {String} uuid Group uuid
     * @param {module:model/WebapiprotoKickUserData} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoGenericConfirm} and HTTP response
     */
    kickUserWithHttpInfo(uuid, body) {
      let postBody = body;

      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling kickUser");
      }

      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling kickUser");
      }


      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoGenericConfirm;

      return this.apiClient.callApi(
        '/api/v1/group/{uuid}/kick', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Elimina un utente da un gruppo
     * Elimina un utente da un gruppo (disponibile solo per group admin)
     * @param {String} uuid Group uuid
     * @param {module:model/WebapiprotoKickUserData} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoGenericConfirm}
     */
    kickUser(uuid, body) {
      return this.kickUserWithHttpInfo(uuid, body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Richiesta dell'utente di lasciare un dato gruppo
     * Richiesta dell'utente di lasciare un dato gruppo
     * @param {String} uuid Group Uuid
     * @param {module:model/WebapiprotoLeaveGroupConfirm} body Leave data
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoGenericConfirm} and HTTP response
     */
    leaveGroupWithHttpInfo(uuid, body) {
      let postBody = body;

      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling leaveGroup");
      }

      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling leaveGroup");
      }


      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoGenericConfirm;

      return this.apiClient.callApi(
        '/api/v1/group/{uuid}/leave', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Richiesta dell'utente di lasciare un dato gruppo
     * Richiesta dell'utente di lasciare un dato gruppo
     * @param {String} uuid Group Uuid
     * @param {module:model/WebapiprotoLeaveGroupConfirm} body Leave data
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoGenericConfirm}
     */
    leaveGroup(uuid, body) {
      return this.leaveGroupWithHttpInfo(uuid, body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Apre una richiesta di contestazione
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoGenericConfirm} and HTTP response
     */
    reportWrongCredentialsWithHttpInfo(uuid) {
      let postBody = null;

      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling reportWrongCredentials");
      }


      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoGenericConfirm;

      return this.apiClient.callApi(
        '/api/v1/group/{uuid}/report', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Apre una richiesta di contestazione
     * @param {String} uuid 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoGenericConfirm}
     */
    reportWrongCredentials(uuid) {
      return this.reportWrongCredentialsWithHttpInfo(uuid)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Modifica le credenziali di accesso di un gruppo
     * Modifica le credenziali di accesso di un gruppo (Disponibile solo per group admin)
     * @param {String} uuid Group uuid
     * @param {module:model/WebapiprotoSetAccountData} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoGenericConfirm} and HTTP response
     */
    setGroupAccountWithHttpInfo(uuid, body) {
      let postBody = body;

      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling setGroupAccount");
      }

      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling setGroupAccount");
      }


      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoGenericConfirm;

      return this.apiClient.callApi(
        '/api/v1/group/{uuid}/account', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Modifica le credenziali di accesso di un gruppo
     * Modifica le credenziali di accesso di un gruppo (Disponibile solo per group admin)
     * @param {String} uuid Group uuid
     * @param {module:model/WebapiprotoSetAccountData} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoGenericConfirm}
     */
    setGroupAccount(uuid, body) {
      return this.setGroupAccountWithHttpInfo(uuid, body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Elimina un utente da un gruppo (disponibile solo per group admin)
     * @param {String} uuid Group uuid
     * @param {module:model/WebapiprotoKickUserData} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoGenericConfirm} and HTTP response
     */
    unKickUserWithHttpInfo(uuid, body) {
      let postBody = body;

      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling unKickUser");
      }

      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling unKickUser");
      }


      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoGenericConfirm;

      return this.apiClient.callApi(
        '/api/v1/group/{uuid}/unkick', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Elimina un utente da un gruppo (disponibile solo per group admin)
     * @param {String} uuid Group uuid
     * @param {module:model/WebapiprotoKickUserData} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoGenericConfirm}
     */
    unKickUser(uuid, body) {
      return this.unKickUserWithHttpInfo(uuid, body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Aggiorna i dettagli di un gruppo esistente (disponibile solo per utenti admin)
     * Aggiorna i dettagli di un gruppo esistente (disponibile solo per group admin)
     * @param {String} uuid 
     * @param {module:model/WebapiprotoGroupDetailsData} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/WebapiprotoGroupDetail} and HTTP response
     */
    updateGroupWithHttpInfo(uuid, body) {
      let postBody = body;

      // verify the required parameter 'uuid' is set
      if (uuid === undefined || uuid === null) {
        throw new Error("Missing the required parameter 'uuid' when calling updateGroup");
      }

      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling updateGroup");
      }


      let pathParams = {
        'uuid': uuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = WebapiprotoGroupDetail;

      return this.apiClient.callApi(
        '/api/v1/group/{uuid}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * Aggiorna i dettagli di un gruppo esistente (disponibile solo per utenti admin)
     * Aggiorna i dettagli di un gruppo esistente (disponibile solo per group admin)
     * @param {String} uuid 
     * @param {module:model/WebapiprotoGroupDetailsData} body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/WebapiprotoGroupDetail}
     */
    updateGroup(uuid, body) {
      return this.updateGroupWithHttpInfo(uuid, body)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
