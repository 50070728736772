// We can inject some CSS into the DOM.
import {withStyles} from "@material-ui/core/styles";
import React from "react";
import Avatar from "@material-ui/core/Avatar";
import capitalize from "@material-ui/core/utils/capitalize";
import clsx from "clsx";
import MuiAlert from '@material-ui/lab/Alert';
import {Breadcrumbs} from "@material-ui/core";
import {Icon} from "../Icon/Icon";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import Link from "../Link/Link";
import Typography from "../Typography/Typography";



const styles = (theme) => {
    return {
        root: {
          "& a": {
              fontWeight: "500",
              color: theme.palette.text.primary
          },
          "& p": {
              fontWeight: "500",
              color: theme.palette.text.secondary
          }
        },
        separator: {
            margin: "0 0px -4px 0px"
        },
        icon: {
            //transform: "rotate(270deg)"
        }
    }
};

function TBreadcrumbs(props) {
    const {
        children,
        isLoading,
        classes,
        separator,
        linksComponent,
        links = [],
        ...other
    } = props;
    return (
        <Box>
            <Breadcrumbs
                classes={{
                    root: classes.root,
                    separator: classes.separator
                }}
                separator={separator ? separator :
                    !isLoading ? <Icon className={classes.icon}>breadcrumbs_arrow</Icon> :
                        <Box px={1}><Skeleton variant={"text"} width={16} height={16}/></Box>
                }
                {...other}
            >
                {
                    links.map(link =>
                        <Link variant={"h5"} component={linksComponent} isLoading={isLoading} key={`link-${link.title}`} color="inherit" to={link.url}>{link.title}</Link>)
                }
                <Typography variant={"h5"} isLoading={isLoading}>
                    {children}
                </Typography>
            </Breadcrumbs>
        </Box>
    )
}

export default withStyles(styles, {useTheme: true})(TBreadcrumbs);
