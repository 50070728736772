import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import AvatarIcon, {AvatarMissingIcon} from "@ticketag/ticketag-uilib/src/components/base/Avatar/AvatarIcon";
import Menu from "@ticketag/ticketag-uilib/src/components/base/Menu/Menu";
import MenuItem from "@ticketag/ticketag-uilib/src/components/base/Menu/MenuItem";
import {Link as RouterLink} from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import clsx from "clsx";
import {useLocation} from 'react-router-dom'
import {withI18n} from "@ticketag/i18nlib";
import {ExitIcon} from "../../../../../../ticketag-uilib/src/components/base/Icon/Icon";
import Data from "../../../data";
import {withAuth} from "../../../hoc/Auth/Provider";
import Tabs from "../Tabs/Tabs";
import Box from "@material-ui/core/Box";

const styles = (theme) => {
    return {
        avatar: {
            width: "75px",
            height: "75px",
        },
        divider:          {
            color: "#49586F",
            opacity: "0.5",
            height: "34px",
            margin: "0 64px",
            width: "2px",
            backgroundColor: "rgba(73, 88, 111, 0.5)"
        },
        menu: {
            marginTop: "12px",
            padding: "16px 36px 16px 24px",
        },
        menuDivider: {
            marginBottom: "10px",
            marginTop: "4px",
        },
        menuItem: {
            "&:hover": {
                backgroundColor: "transparent"
            },
            "& svg": {
              marginTop: "3px",
              marginRight: theme.spacing(0.5)
            },
            padding: "0 0 16px 0",
        },
        menuLogout: {
            paddingBottom: "0px",
            textAlign: "center",
            display: "block",
            color: theme.palette.error.main,
        },
        menuError: {
            color: theme.palette.error.main
        }
    }
}

function TMenu(props) {
    const {
        avatar,
        auth,
        classes,
        i18n,
        children,
        ...other
    } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const currentUrl = useLocation().pathname;

    const isAuthenticated = auth.isAuthenticated()
    const userInfo = auth.getUserInfo()

    const logoutUrl = auth.logoutUrl
    const signupUrl = auth.loginUrl
    const signinUrl = auth.signUpUrl

    const menuData =  isAuthenticated ?
        Data.DefaultLayout.menu.logged :
        Data.DefaultLayout.menu.unlogged
    return (
            <Box display={"flex"} alignItems={"center"}>
                {
                    userInfo && userInfo.avatarUuid ?
                        <>
                        <Tabs/>
                        <Divider className={classes.divider} orientation={"vertical"}/>
                        <AvatarIcon
                            clickable={true}
                            className={classes.avatar}
                            size={"medium"} src={userInfo?.avatarUuid}
                            alt={"avatar"}
                            onClick={userInfo.complete ? (event) => setAnchorEl(event.currentTarget) : null}
                        />
                        </>
                        :
                        <AvatarMissingIcon
                            onClick={(event) => setAnchorEl(event.currentTarget)}
                            className={classes.avatar}/>
                }
                <Menu
                    id="simple-menu"
                    keepMounted
                    anchorEl={anchorEl}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={(e) => setAnchorEl(null)}
                    classes={{paper: classes.menu}}
                >
                    {   menuData.links ?

                                Object.entries(menuData.links).map(([key, val]) => (
                                    <MenuItem
                                        component={RouterLink} to={i18n.buildLocalizedUrl(val.href)}
                                        onClick={() => setAnchorEl(null)}
                                        className={classes?.menuItem}
                                        key={key}
                                    >{val.title}</MenuItem>
                                ))
                             : null
                    }
                    { children }
                    { userInfo?.impersonated ? <MenuItem key="exit-impersonation-link"
                                                         className={classes.menuItem}
                                                         component={"div"}
                                                         onClick={() => {window.location.href = `/auth/impersonate/exit?redirect=${window.location.pathname}`}}
                    ><ExitIcon color={'error'}/> Exit impersonation</MenuItem> : null}
                    {menuData.links ? <Divider className={classes.menuDivider}/> : null }
                    {   menuData?.actions?.logout ?
                        <MenuItem
                            className={clsx(classes.menuItem, classes.menuLogout)}
                            component={"a"}
                            href={logoutUrl}
                        >{menuData.actions.logout.title}</MenuItem>
                        : null
                    }
                    {   menuData?.actions?.signUp ?
                        <MenuItem
                            className={clsx(classes.menuItem)}
                            component={"a"}
                            href={`${signupUrl}`}
                        >{menuData.actions.signUp.title}</MenuItem>
                        : null
                    }
                    {   menuData?.actions?.signIn ?
                        <MenuItem
                            className={clsx(classes.menuItem)}
                            component={"a"}
                            href={`${signinUrl}`}
                        >{menuData.actions.signIn.title}</MenuItem>
                        : null
                    }
                </Menu>
            </Box>
    );
}

export default withStyles(styles,{})(withAuth(withI18n(TMenu)))
