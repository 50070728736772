/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';
import {WebapiprotoJoinedGroupCard} from './WebapiprotoJoinedGroupCard';
import {WebapiprotoOwnedGroupCard} from './WebapiprotoOwnedGroupCard';

/**
 * The WebapiprotoUserGroups model module.
 * @module model/WebapiprotoUserGroups
 * @version v0.4
 */
export class WebapiprotoUserGroups {
  /**
   * Constructs a new <code>WebapiprotoUserGroups</code>.
   * @alias module:model/WebapiprotoUserGroups
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>WebapiprotoUserGroups</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/WebapiprotoUserGroups} obj Optional instance to populate.
   * @return {module:model/WebapiprotoUserGroups} The populated <code>WebapiprotoUserGroups</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new WebapiprotoUserGroups();
      if (data.hasOwnProperty('count'))
        obj.count = ApiClient.convertToType(data['count'], 'Number');
      if (data.hasOwnProperty('owned'))
        obj.owned = ApiClient.convertToType(data['owned'], [WebapiprotoOwnedGroupCard]);
      if (data.hasOwnProperty('joined'))
        obj.joined = ApiClient.convertToType(data['joined'], [WebapiprotoJoinedGroupCard]);
    }
    return obj;
  }
}

/**
 * @member {Number} count
 */
WebapiprotoUserGroups.prototype.count = undefined;

/**
 * @member {Array.<module:model/WebapiprotoOwnedGroupCard>} owned
 */
WebapiprotoUserGroups.prototype.owned = undefined;

/**
 * @member {Array.<module:model/WebapiprotoJoinedGroupCard>} joined
 */
WebapiprotoUserGroups.prototype.joined = undefined;


