import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {I18nContext, T, withI18n} from "@ticketag/i18nlib";
import Progress from "@ticketag/ticketag-uilib/src/components/base/Progress/Progress";
import {withApi} from "../../hoc/Api/Provider";
import AuthContext from "../../hoc/Auth/context";
import Grid from "@material-ui/core/Grid";
import NewGroupHeader from "../../components/NewGroupHeader/NewGroupHeader";
import ActionCard from "@ticketag/ticketag-uilib/src/components/complex/ActionCard/ActionCard";
import {CreateGroup, JoinGroup} from "../../components/Icons/Icons";
import List from "@ticketag/ticketag-uilib/src/components/base/List/List";
import Box from "@material-ui/core/Box";
import ListCard from "@ticketag/ticketag-uilib/src/components/complex/ListActionCard/ListActionCard";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import CardsContainer from "../../components/CardsContainer/CardsContainer";
import {withAuth} from "../../hoc/Auth/Provider";
import {withRouter} from "react-router-dom";
import TmpCard from "@ticketag/ticketag-uilib/src/components/complex/TmpCard/TmpCard";
import {withRedirect} from "../../components/ErrorMessage/ErrorMessage";
import Layout from "../../hoc/Layout/Layout";
import Page from "../../components/Page/Page";
import TextFooter from "../../components/TextFooter/TextFooter";
import Help from "../../components/Help/Help";
import Title from "../../components/Title/Title";
import Scroll from "../../components/Scroll/Scoll";
import Subtitle from "../../components/Subtitle/Subtitle";
import {withGlobalLoader} from "../../hoc/GlobalLoader/Provider";
import {pages} from "../../constants";
import {SeoDescription, SeoIndex, SeoTitle} from "../Seo/Seo";


const styles = (theme) => {
    return {
        root: {}
    }
};

const ALLOWED_ACTIONS = ['join', 'create']

class SelectGroupType extends Component {
    static contextType = I18nContext

    constructor(props) {
        super(props);
        this.action = this.props.action
        this.state = {
            isLoaded:true,
            serviceFamily: null,
            services: this.props.services.filter(s => this.action === 'join' ? s.scopesAvailable.indexOf('public') !== -1 : true)
        }
        this.createService = this.createService.bind(this)
    }


    get serviceName() {
        return this.props.serviceFamily.name.toLowerCase()
    }

    get slug() {
        return this.props.serviceFamily.slug
    }

    get linkTitle() {
        return this.action === 'join' ? <T defaultVal="Partecipa">pages.select_group_type.join_group</T> : <T defaultVal="Crea Gruppo">global.links.create_group</T>
    }

    nextUrl(serviceType) {
        return this.context.buildLocalizedUrl("services", this.slug, this.action, serviceType)
    }

    getListItems(serviceType) {
        if (this.action === 'join') {
            return serviceType.data.joinAttributes.map(a => <T>{a}</T>)
        }
        const scopesAvailable = serviceType.scopesAvailable.map((s, i) => <React.Fragment key={i}>{i > 0? <T defaultVal=' o '>golbal.conjunctions.or</T>: ''} <b><T defaultVal={s}>{`groups.scope.${s}`}</T></b></React.Fragment>)
        return [
            <><T defaultVal={'Costa'}>pages.select_group_type.price_cost</T> <b>{this.props.i18n.formatPrice(serviceType.monthlyPrice)}€</b> <T defaultVal={'al mese'}>pages.select_group_type.price_period</T></>,
            <><T defaultVal={'Gruppo'}>pages.select_group_type.group</T> {scopesAvailable}</>,
            ...serviceType.data.createAttributes.map(a => <T>{a}</T>)
        ]
    }

    loadServiceTypes(uuid) {
        return this.props.api.serviceApi.getFamilyServices(uuid)
    }

    createService(serviceType) {
        withRedirect(this.props.history, this.props.api.serviceApi.getService(serviceType).then(service => {
            const uuid = service.details[0]?.uuid
            this.props.api.groupApi.createServiceGroup(uuid, {}).then((group) => {
                this.props.history.push(this.props.i18n.buildLocalizedUrl("groups", group.uuid, "create", "visibility"))
            })
        }))
    }

    goToNextUrl(serviceType) {

        if (this.props.auth.isAuthenticated()) {
            if (this.props.match.params.action === "create") {
                this.createService(serviceType.uuid)
            } else {
                this.props.history.push(this.nextUrl(serviceType.slug))
            }
        } else {
            window.location.href = `${this.props.auth.signUrlWithMail(false)}?lang=${this.props.i18n.selectedLocale()}&from=${encodeURI(this.nextUrl(serviceType.slug))}`
        }

    }

    renderContent() {
        return (
            <Grid container justify={"center"} alignItems={"center"} spacing={2} className={this.props.classes.root}>
                {
                    this.state.services.map((s) => (
                        <Grid item xs={12} key={s.uuid}>
                            <Box display={"flex"} justifyContent={"center"}>
                                <TmpCard onClick={() => this.goToNextUrl(s)} title={s.description}>
                                    <Box display={"flex"} flexGrow={2} justifyContent={"center"} alignItems={"center"}>
                                        <Typography color={"gradient"}>
                                            <span style={{fontSize: "24px", fontWeight: 700}}>{
                                                this.props.action === "create" ?
                                                    this.props.i18n.formatPrice(s.monthlyPrice) :
                                                    this.props.i18n.formatPrice(s.bestPrice)
                                            }</span>
                                            &nbsp;&nbsp;
                                            <span style={{fontSize: "12px", fontWeight: 600, whiteSpace: "nowrap"}}>{"€/mese"}</span>
                                        </Typography>
                                    </Box>
                                </TmpCard>
                            </Box>
                        </Grid>
                    ))
                }
            </Grid>
        )
    }


    render() {
        const isLoading = !this.state.isLoaded
        if (isLoading) {
            return null
        }
        const {auth, history, i18n} = this.props;
        const serviceFamily = this.props.serviceFamily
        const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1)
        const {
            seo, layout, title, subtitle, helpTitle, footer, ...other
        } = this.props;
        return (
            <Page layout={{
                ...layout,
                onGoBack: () =>  history.replace(i18n.buildLocalizedUrl("/services", serviceFamily.slug)),
                onClose: auth.isAuthenticated() ?
                    () => history.replace(i18n.buildLocalizedUrl("/groups"))
                    :
                    () => history.replace(i18n.buildLocalizedUrl("/"))
            }} {...other}>
                <SeoTitle args={{Name: capitalize(serviceFamily.name)}}>{seo.title}</SeoTitle>
                <SeoDescription args={{Name: capitalize(serviceFamily.name)}}>{seo.description}</SeoDescription>
                <SeoIndex value={seo.index}/>
                <Title>{title}</Title>
                <Subtitle useTemplate args={{Name: capitalize(serviceFamily.name)}}>
                    {subtitle}
                </Subtitle>
                <Scroll>
                    {this.renderContent()}
                </Scroll>
                <TextFooter
                    helpTitle={helpTitle}
                    help={
                        this.state.services.map((s) => (
                            <Help key={s.name}
                                  title={s.slug} id={s.slug}
                            >{s.name.toLowerCase()}</Help>
                        ))}>
                    {footer}
                </TextFooter>
            </Page>
        )
    }

}

export default withStyles(styles, {useTheme: true})(withGlobalLoader(withRouter(withAuth(withApi(withI18n(SelectGroupType))))));
