import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from "clsx"; // We can inject some CSS into the DOM.

const styles = theme => ({
  root: {
    borderTopColor: theme.palette.primary.main,
    borderLeftColor: theme.palette.secondary.main,
    '& div': {
      borderTopColor: theme.palette.primary.main,
      borderLeftColor: theme.palette.secondary.main
    }
  }
});

function TProgress(props) {
  const {
    classes,
    children,
    className,
    ...other
  } = props;
  return <div className={`loader ${clsx(classes.root)}`} {...other} componentname="t-progress">
                    <div>
                        <div>
                            <div>
                                <div>
                                    <div>
                                        <div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>;
}

TProgress.propTypes = CircularProgress.propTypes;
export default withStyles(styles, {
  useTheme: true
})(TProgress);
