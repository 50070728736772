/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * The WebapiprotoUserProfile model module.
 * @module model/WebapiprotoUserProfile
 * @version v0.4
 */
export class WebapiprotoUserProfile {
  /**
   * Constructs a new <code>WebapiprotoUserProfile</code>.
   * @alias module:model/WebapiprotoUserProfile
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>WebapiprotoUserProfile</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/WebapiprotoUserProfile} obj Optional instance to populate.
   * @return {module:model/WebapiprotoUserProfile} The populated <code>WebapiprotoUserProfile</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new WebapiprotoUserProfile();
      if (data.hasOwnProperty('uuid'))
        obj.uuid = ApiClient.convertToType(data['uuid'], 'String');
      if (data.hasOwnProperty('user_uuid'))
        obj.userUuid = ApiClient.convertToType(data['user_uuid'], 'String');
      if (data.hasOwnProperty('first_name'))
        obj.firstName = ApiClient.convertToType(data['first_name'], 'String');
      if (data.hasOwnProperty('last_name'))
        obj.lastName = ApiClient.convertToType(data['last_name'], 'String');
      if (data.hasOwnProperty('cf'))
        obj.cf = ApiClient.convertToType(data['cf'], 'String');
      if (data.hasOwnProperty('address'))
        obj.address = ApiClient.convertToType(data['address'], 'String');
      if (data.hasOwnProperty('city'))
        obj.city = ApiClient.convertToType(data['city'], 'String');
      if (data.hasOwnProperty('country'))
        obj.country = ApiClient.convertToType(data['country'], 'String');
      if (data.hasOwnProperty('province'))
        obj.province = ApiClient.convertToType(data['province'], 'String');
      if (data.hasOwnProperty('zip_code'))
        obj.zipCode = ApiClient.convertToType(data['zip_code'], 'String');
      if (data.hasOwnProperty('phone'))
        obj.phone = ApiClient.convertToType(data['phone'], 'String');
      if (data.hasOwnProperty('is_main'))
        obj.isMain = ApiClient.convertToType(data['is_main'], 'Boolean');
      if (data.hasOwnProperty('is_empty'))
        obj.isEmpty = ApiClient.convertToType(data['is_empty'], 'Boolean');
      if (data.hasOwnProperty('birthday'))
        obj.birthday = ApiClient.convertToType(data['birthday'], 'Date');
    }
    return obj;
  }
}

/**
 * @member {String} uuid
 */
WebapiprotoUserProfile.prototype.uuid = undefined;

/**
 * @member {String} userUuid
 */
WebapiprotoUserProfile.prototype.userUuid = undefined;

/**
 * @member {String} firstName
 */
WebapiprotoUserProfile.prototype.firstName = undefined;

/**
 * @member {String} lastName
 */
WebapiprotoUserProfile.prototype.lastName = undefined;

/**
 * @member {String} cf
 */
WebapiprotoUserProfile.prototype.cf = undefined;

/**
 * @member {String} address
 */
WebapiprotoUserProfile.prototype.address = undefined;

/**
 * @member {String} city
 */
WebapiprotoUserProfile.prototype.city = undefined;

/**
 * @member {String} country
 */
WebapiprotoUserProfile.prototype.country = undefined;

/**
 * @member {String} province
 */
WebapiprotoUserProfile.prototype.province = undefined;

/**
 * @member {String} zipCode
 */
WebapiprotoUserProfile.prototype.zipCode = undefined;

/**
 * @member {String} phone
 */
WebapiprotoUserProfile.prototype.phone = undefined;

/**
 * @member {Boolean} isMain
 */
WebapiprotoUserProfile.prototype.isMain = undefined;

/**
 * @member {Boolean} isEmpty
 */
WebapiprotoUserProfile.prototype.isEmpty = undefined;

/**
 * @member {Date} birthday
 */
WebapiprotoUserProfile.prototype.birthday = undefined;


