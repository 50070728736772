// We can inject some CSS into the DOM.
import {withStyles} from "@material-ui/core/styles";
import React from "react";
import Avatar from "@material-ui/core/Avatar";
import capitalize from "@material-ui/core/utils/capitalize";
import clsx from "clsx";
import MuiAlert from '@material-ui/lab/Alert';



const styles = (theme) => {
    return {
        root: {
            borderRadius: theme.spacing(2)
        }
    }
};

export const Alert = ({noIcon, icon, ...props}) => {
    icon = noIcon ? <></> : icon
    return <MuiAlert icon={icon} elevation={6} variant="filled" {...props} />;
}

export default withStyles(styles, {useTheme: true})(Alert);
