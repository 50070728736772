import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T, withI18n} from "@ticketag/i18nlib";
import {withApi} from "../../hoc/Api/Provider";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import ProfileSection from "../../components/ProfileSection/ProfileSection";
import Field from "@ticketag/ticketag-uilib/src/components/base/Field/Field";
import FormField from "@ticketag/ticketag-uilib/src/components/base/Field/FormField";
import Button from "@ticketag/ticketag-uilib/src/components/base/Button/Button";
import Hidden from "@material-ui/core/Hidden/Hidden";
import withWidth from "@material-ui/core/withWidth";
import {withModals} from "@ticketag/ticketag-uilib/src/components/hoc/Modals/Provider";
import { withRouter } from 'react-router-dom'

import {
    ConfirmDeleteAccount,
    ConfirmDeleteBillingData,
    NotImplemented, PromptChangePassword
} from "../../hoc/Modals/Modals";
import PaymentMethods from "../../partials/PaymentMethods/PaymentMethods";
import {withErrorModal, withRedirect} from "../../components/ErrorMessage/ErrorMessage";
import Skeleton from '@material-ui/lab/Skeleton';
import BillingData from "../../partials/BillingData/BillingData";
import PayoutData from "../../components/PayoutData/PayoutData";
import Header from "@ticketag/ticketag-uilib/src/components/base/Header/Header";
import AvatarIcon from "@ticketag/ticketag-uilib/src/components/base/Avatar/AvatarIcon";
import PhoneInput from "@ticketag/ticketag-uilib/src/components/base/PhoneInput/PhoneInput";
import LanguageComboBox from "@ticketag/ticketag-uilib/src/components/base/Select/LanguageComboBox";
import {withAuth} from "../../hoc/Auth/Provider";
import Layout from "../../hoc/Layout/Layout";
import {withPage} from "../../hoc/Page/Provider";
import Title from "../../components/Title/Title";
import {pages} from "../../constants";
import Page from "../../components/Page/Page";


const styles = (theme) => {
    return {
        root: {},
        title: {
            marginTop: "12px",
            marginBottom: "60px"
        },
        avatar: {
            width: "75px",
            height: "75px",
            paddingRight: "8px"
        }
    }
};

class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userInfo:        null,
            userPreferences: null,
            userProfiles:    [],
            isLoaded:        false,
            isUpdating:      true,
            paymentMethods:  []
        }
        this._isMounted = false
        this.paymentMethods = null
        this.billingData = null
        this.paymentMethodSection=false
        const currentURL=new URL(window.location.href)
        if (currentURL.searchParams.has("paymentMethod")){
            this.paymentMethodSection=true
        }
    }

    componentDidMount() {
        this._isMounted = true
        this.loadData()
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    getFullName(profile) {
        if (!profile) {
            return null
        }
        return `${profile.name.firstName} ${profile.name.lastName}`
    }

    getFullAddress(profile) {
        return `${profile.address}, ${profile.zipCode} ${profile.city}`
    }

    onPayoutProfileUpdated() {
        this.setState({isLoaded: false})
        this.loadData()
    }

    updatePayoutProfile(step, data) {
        const updatedProfile = {...this.state.userPreferences.payoutProfile, ...data}
        switch (step) {
            case 0:
                return this.updateUserPreference('payoutProfile', updatedProfile).then(resp => {
                    return resp.payoutProfile
                }).catch(e => {

                })
            case 1:
                return this.updateUserPreference('payoutProfile', updatedProfile).then(userPreferences => {
                    return null
                }).catch(e => {

                })
            case 2:
                const body = {...this.state.userPreferences.payoutProfile.documents[0] || {userProfileUuid: this.state.userPreferences.payoutProfile.uuid}, ...data}
                return this.props.api.userApi.updateIdDocument(body)
        }
    }

    updatePassword(value) {
        const body = {...value}
        this.setState({isLoaded: false})
        return withErrorModal(this.props.modals, this.props.api.userApi.updatePassword(body).then((data) => {
            return data
        })).then(() => {
            this.setState({
                isLoaded:        true
            })
        })
    }

    updateUserPreference(key, value) {
        const body = {}
        if (key) {
            body[key] = value
        } else {
            Object.assign(body, value||{})
        }
        this.setState({isLoaded: false})
        return withErrorModal(this.props.modals, this.props.api.userApi.updateUserPreferences(body).then((data) => {
            this.setState({
                userPreferences: data,
                isLoaded:        true
            })
            return data
        }).catch((e) => {
            this.loadData()
            throw e
        }))
    }

    deleteAccount() {
        this.props.api.userApi.getUserGroups().then((groups) => {
          if (groups.count > 0) {
              this.props.modals.OkOnly(
                  <T defaultVal="Elimina account">modals.delete_account_disabled.title</T>,
                  <T defaultVal="Prima di eliminare il tuo account devi chiudere tutti i gruppi da te creati  e abbandonare tutti i gruppi ai quali partecipi.">modals.delete_account_disabled.text</T>,
                  {})
          }  else {
              ConfirmDeleteAccount(this.props.modals).then(() => {
                    withErrorModal(this.props.modals, this.props.api.userApi.deleteAccount().then(() => {
                        this.props.history.push(this.props.i18n.buildLocalizedUrl("/"))
                    }))
              })
          }
        })
    }

    editPassword() {
        PromptChangePassword(this.props.modals).then(({form, value}) => {
            this.updatePassword(value)
        })
    }

    deleteBillingData() {
        ConfirmDeleteBillingData(this.props.modals).then((ok) => {
            if (ok) {
                NotImplemented(this.props.modals)
            }
        })
    }

    loadUserPreferences() {
        return this.props.api.userApi.getUserPreferences()
    }

    loadUserProfiles() {
        return this.props.api.userApi.getUserProfiles()
    }

    loadIdDocuments() {
        const userPreferences = this.state.userPreferences
        return new Promise(((resolve, reject) => {
            Promise.all(userPreferences.payoutProfile.documents.map(doc => this.props.api.userApi.getIdDocument(doc.uuid,{}))).then(documentsDetails => {
                const documents = userPreferences.payoutProfile.documents
                documents.splice(0, userPreferences.payoutProfile.documents.length)
                documents.push(...documentsDetails)
                this.setState({
                    userPreferences: userPreferences
                }, () => {
                    resolve(this.state.userPreferences.payoutProfile)
                })

            }).catch((err) => reject(err))
        }))
    }

    loadData() {
        return withRedirect(this.props.history, Promise.all([
            this.loadUserPreferences(),
            this.loadUserProfiles()]).then(([userPref, userProfiles]) => {
            this.setState({
                userInfo:        this.props.auth.getUserInfo(),
                userPreferences: userPref,
                userProfiles:    userProfiles.profiles,
                isLoaded:        true,
                isUpdating:      false,
                fullName:        `${userPref.defaultProfile.firstName} ${userPref.defaultProfile.lastName}`
            })
        }))
    }

    renderSkeletonLoader() {
        return <Grid item xs={12}>
            <Box>
                <Typography color={'textSecondary'} variant={'h1'}>
                    <Skeleton width={200}/>
                </Typography>
            </Box>
            <ProfileSection key="profile_info" title={<Skeleton width={100}/>}>
                <Grid item xs={12} container alignItems={"flex-start"} spacing={2}>
                    <Grid xs={12} sm={6} md={4} item>
                        <Skeleton width={120}/>
                        <Skeleton width={150}/>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} item>
                        <Skeleton width={120}/>
                        <Skeleton width={200}/>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} item>
                        <Skeleton width={80}/>
                        <Skeleton width={100}/>
                        <Skeleton width={120}/>
                    </Grid>
                </Grid>
            </ProfileSection>
            <ProfileSection key="contact_preferences" title={<Skeleton width={150}/>}>
                <Grid item xs={12} container alignItems={"flex-start"} spacing={2}>
                    <Grid xs={12} sm={6} md={4} item>
                        <Skeleton width={40}/>
                        <Skeleton width={200}/>
                        <Skeleton width={120}/>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} item>
                        <Skeleton width={60}/>
                        <Skeleton width={100}/>
                        <Skeleton width={120}/>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} item>
                        <Skeleton width={40}/>
                        <Skeleton width={60}/>
                    </Grid>
                </Grid>
            </ProfileSection>
            <ProfileSection title={<Skeleton width={150}/>}>
                <Skeleton variant="rect" width={'100%'} height={40}/>
            </ProfileSection>
            <ProfileSection title={<Skeleton width={140}/>}>
                <Skeleton variant="rect" width={'100%'} height={40}/>
            </ProfileSection>
            <ProfileSection title={<Skeleton width={160}/>}>
                <Skeleton variant="rect" width={300} height={100}/>
                <Skeleton width={160}/>
            </ProfileSection>
            <ProfileSection title={<Skeleton width={150}/>} subtitle={<Skeleton/>}>
                <Skeleton variant="rect" width={150} height={32}/>
            </ProfileSection>
        </Grid>
    }

    renderContent() {
        const verticalLayout = ['xs', 'sm'].indexOf(this.props.width) !== -1
        return <Grid item xs={12}>
            <Box>
                <Header
                    classes={{headerTitle: this.props.classes.title}}
                    avatar={
                        <AvatarIcon
                            className={this.props.classes.avatar}
                            size={"medium"} src={this.state.userInfo.avatarUuid}
                            alt={"avatar"}
                        />
                    }>
                    <Hidden smDown>
                        <T defaultVal={'Ciao '}>pages.profile.greetings</T>
                    </Hidden>{
                        this.state.userInfo?.fullName.split(" ")[0]
                    }
                </Header>
            </Box>
            <ProfileSection pt={7} key="profile_info"
                            title={<T defaultVal={'Dati profilo'}>pages.profile.profile_info</T>}>
                <Grid item xs={12} container alignItems={"flex-start"} spacing={2}>
                    <Grid xs={12} sm={6} md={4} item>
                        <Field label={<T defaultVal={'Nome e cognome'}>pages.profile.full_name</T>}
                               value={this.getFullName(this.state.userPreferences)}/>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} item>
                        <Field label={<T defaultVal={'Mail di accesso'}>pages.profile.access_email</T>}
                               value={this.state.userPreferences.accessEmail}/>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} item>
                        <FormField T={T} label={<T defaultVal={'Password'}>global.auth.password</T>}
                                   helperText={<T defaultVal={'Modifica password'}>pages.profile.edit_password</T>}
                                   onClick={() => this.editPassword()}
                                   defaultValue={"••••••••••••••••"}/>
                    </Grid>
                </Grid>
            </ProfileSection>
            <ProfileSection key="contact_preferences"
                            title={<T defaultVal={'Preferenze di contatto'}>pages.profile.contact_preferences</T>}>
                <Grid item xs={12} container alignItems={"flex-start"} spacing={2}>
                    <Grid xs={12} sm={6} md={4} item>
                        <FormField T={T}
                                   name={"contactEmail"}
                                   rules={{email: true}}
                                   label={<T defaultVal={'Mail'}>global.auth.mail</T>}
                                   onChange={(val) => this.updateUserPreference('contactEmail', val)}
                                   helperText={<T defaultVal={'Modifica email'}>pages.profile.edit_email</T>}
                                   defaultValue={this.state.userPreferences.contactEmail}/>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} item>
                        <FormField T={T}
                                   name={"phoneNumber"}
                                   component={(props) => <PhoneInput variant={"underline"} {...props}/>}
                                   rules={{
                                       required: true,
                                       pattern: {
                                           value: new RegExp(/^\+[1-9]{1}[0-9]{0,2}.?[0-9]{4,14}$/, 'i'), message: {
                                               key: 'pages.profile.insert_valid_phone',
                                               defaultVal: 'Inserisci un telefono valido'
                                           }
                                       }
                                   }}
                                   label={<T defaultVal={'Telefono'}>pages.profile.phone</T>}
                                   onChange={(val) => {
                                       console.log(val);
                                       this.updateUserPreference('phoneNumber', val)
                                   }}
                                   helperText={<T defaultVal={'Modifica telefono'}>pages.profile.edit_phone</T>}
                                   defaultValue={this.state.userPreferences.phoneNumber || '-'}/>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} item>
                        <FormField T={T}
                                   name={"contactLocale"}
                                   renderValue={(val) => this.props.i18n.locales.filter((elt) => elt.uuid === val)[0]?.languageLabel}
                                   component={(props) =>
                                       <LanguageComboBox locales={this.props.i18n.locales} {...props}/>
                                   }
                                   rules={{required: true}}
                                   label={<T defaultVal={'Lingua'}>pages.profile.language</T>}
                                   onChange={(val) => this.updateUserPreference('contactLocale', val)}
                                   defaultValue={this.state.userPreferences.contactLanguage}
                                   helperText={<T defaultVal={'Modifica lingua'}>pages.profile.edit_language</T>}
                        />
                    </Grid>

                </Grid>
            </ProfileSection>
            <ProfileSection title={<T defaultVal={'Profilo di Fatturazione'}>pages.profile.invoice_profile</T>}>
                <BillingData
                    billingProfiles={this.state.userProfiles}
                    isLoaded={this.state.isLoaded}
                    api={this.props.api}
                    modals={this.props.modals}
                    i18n={this.props.i18n}
                    ref={(r) => this.billingData = r}
                    verticalLayout={verticalLayout}
                />
                {/* <BillingDataSelector
                    profiles={this.state.userProfiles}
                    verticalLayout={verticalLayout}
                    onDelete={() => this.deleteBillingData()}
                    onSelect={(p) => this.setMainProfile(p.uuid)}
                />
                <Box {...(this.state.userProfiles.length > 0 ? {display: 'flex', justifyContent: 'flex-end'} : {})}
                     pt={1}>
                    <TextButton variant={'secondary'}>+ Aggiungi Profilo di Fatturazione</TextButton>
                </Box> */}
            </ProfileSection>
            <ProfileSection title={<T defaultVal={'Dati di payout'}>pages.profile.payout_info</T>}>
                <PayoutData onContinue={({step, data}) => this.updatePayoutProfile(step, data)}
                            onComplete={() => this.onPayoutProfileUpdated()}
                            beforeOpen={() => this.loadIdDocuments()}
                            modals={this.props.modals} i18n={this.props.i18n} verticalLayout={verticalLayout}
                            payoutProfile={this.state.userPreferences.payoutProfile}/>
            </ProfileSection>
            <ProfileSection expand={this.paymentMethodSection}
                            title={<T defaultVal={'Metodo di pagamento'}>pages.profile.payment_method</T>}>
                <PaymentMethods isLoaded={this.state.isLoaded} api={this.props.api} modals={this.props.modals}
                                i18n={this.props.i18n} ref={(r) => this.paymentMethods = r}/>
            </ProfileSection>
            <Hidden smDown><Box mt={4}/></Hidden>
            <ProfileSection
                title={<T defaultVal={'Elimina l’account'}>pages.profile.delete_account</T>}
                subtitle={<T
                    defaultVal={'Cliccando su "elimina account" tutti i tuoi dati su Diveedi verranno definitivamente cancellati.'}>pages.profile.delete_account_help</T>}>
                <Box display={'block'}>
                    <Button variant={'outlined'} onClick={() => this.deleteAccount()} error>
                        <T defaultVal={'Elimina account'}>pages.profile.delete_account_action</T>
                    </Button>
                </Box>
            </ProfileSection>
            <Box mb={6}/>
        </Grid>;
    }

    render() {
        const {
            title,
            ...other
        } = this.props;
        //value={/*selectedLocale*/} onChange={(e) => {/*setLocale(e.target.value)*/}}
        return (
            <Page {...other}>
                {
                    !this.state.isLoaded ?
                        this.renderSkeletonLoader() :
                        this.renderContent()
                }
            </Page>
        )
    }


}

export default withAuth(withStyles(styles, {
    useTheme:  true,
    withTheme: true
})(withApi(withWidth()(withModals(withI18n(withRouter(Profile)))))));
