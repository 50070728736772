/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * The WebapiprotoJoinRequest model module.
 * @module model/WebapiprotoJoinRequest
 * @version v0.4
 */
export class WebapiprotoJoinRequest {
  /**
   * Constructs a new <code>WebapiprotoJoinRequest</code>.
   * @alias module:model/WebapiprotoJoinRequest
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>WebapiprotoJoinRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/WebapiprotoJoinRequest} obj Optional instance to populate.
   * @return {module:model/WebapiprotoJoinRequest} The populated <code>WebapiprotoJoinRequest</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new WebapiprotoJoinRequest();
      if (data.hasOwnProperty('uuid'))
        obj.uuid = ApiClient.convertToType(data['uuid'], 'String');
      if (data.hasOwnProperty('amount'))
        obj.amount = ApiClient.convertToType(data['amount'], 'Number');
      if (data.hasOwnProperty('fee'))
        obj.fee = ApiClient.convertToType(data['fee'], 'Number');
      if (data.hasOwnProperty('total_amount'))
        obj.totalAmount = ApiClient.convertToType(data['total_amount'], 'Number');
      if (data.hasOwnProperty('base_service_name'))
        obj.baseServiceName = ApiClient.convertToType(data['base_service_name'], 'String');
      if (data.hasOwnProperty('base_service_uuid'))
        obj.baseServiceUuid = ApiClient.convertToType(data['base_service_uuid'], 'String');
      if (data.hasOwnProperty('renewal_period'))
        obj.renewalPeriod = ApiClient.convertToType(data['renewal_period'], 'String');
      if (data.hasOwnProperty('service_family_uuid'))
        obj.serviceFamilyUuid = ApiClient.convertToType(data['service_family_uuid'], 'String');
      if (data.hasOwnProperty('group_uuid'))
        obj.groupUuid = ApiClient.convertToType(data['group_uuid'], 'String');
      if (data.hasOwnProperty('state'))
        obj.state = ApiClient.convertToType(data['state'], 'String');
      if (data.hasOwnProperty('owner_name'))
        obj.ownerName = ApiClient.convertToType(data['owner_name'], 'String');
      if (data.hasOwnProperty('has_credentials'))
        obj.hasCredentials = ApiClient.convertToType(data['has_credentials'], 'Boolean');
      if (data.hasOwnProperty('needs_credentials'))
        obj.needsCredentials = ApiClient.convertToType(data['needs_credentials'], 'Boolean');
    }
    return obj;
  }
}

/**
 * @member {String} uuid
 */
WebapiprotoJoinRequest.prototype.uuid = undefined;

/**
 * @member {Number} amount
 */
WebapiprotoJoinRequest.prototype.amount = undefined;

/**
 * @member {Number} fee
 */
WebapiprotoJoinRequest.prototype.fee = undefined;

/**
 * @member {Number} totalAmount
 */
WebapiprotoJoinRequest.prototype.totalAmount = undefined;

/**
 * @member {String} baseServiceName
 */
WebapiprotoJoinRequest.prototype.baseServiceName = undefined;

/**
 * @member {String} baseServiceUuid
 */
WebapiprotoJoinRequest.prototype.baseServiceUuid = undefined;

/**
 * @member {String} renewalPeriod
 */
WebapiprotoJoinRequest.prototype.renewalPeriod = undefined;

/**
 * @member {String} serviceFamilyUuid
 */
WebapiprotoJoinRequest.prototype.serviceFamilyUuid = undefined;

/**
 * @member {String} groupUuid
 */
WebapiprotoJoinRequest.prototype.groupUuid = undefined;

/**
 * @member {String} state
 */
WebapiprotoJoinRequest.prototype.state = undefined;

/**
 * @member {String} ownerName
 */
WebapiprotoJoinRequest.prototype.ownerName = undefined;

/**
 * @member {Boolean} hasCredentials
 */
WebapiprotoJoinRequest.prototype.hasCredentials = undefined;

/**
 * @member {Boolean} needsCredentials
 */
WebapiprotoJoinRequest.prototype.needsCredentials = undefined;


