import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { RadioGroup } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import capitalize from "@material-ui/core/utils/capitalize"; // We can inject some CSS into the DOM.

const styles = theme => ({
  root: {},
  colorPrimary: {
    "& .MuiRadioCheckedIcon-colorDefault": {
      fill: theme.palette.primary.main
    }
  },
  colorSecondary: {
    "& .MuiRadioCheckedIcon-colorDefault": {
      fill: theme.palette.secondary.main
    }
  },
  colorGradient: {
    "& .MuiRadioCheckedIcon-colorDefault": {
      fill: "url(#gradient)"
    }
  },
  error: {
    "& .MuiRadioCheckedIcon-colorDefault": {
      fill: theme.palette.error.main
    }
  },
  disabled: {
    "& .MuiRadioCheckedIcon-colorDefault": {
      fill: theme.palette.action.disabled
    }
  },
  label: {
    color: theme.palette.text.secondary,
    fontWeight: "600",
    fontSize: theme.spacing(1.5),
    marginBottom: theme.spacing(1)
  },
  helperText: {
    color: theme.palette.text.primary
  }
});

function TRadioGroup({
  classes,
  children,
  className,
  label,
  helperText,
  error,
  color,
  disabled,
  ...other
}) {
  const useClasses = [classes.root];
  useClasses.push(classes["color" + capitalize(color || "Default")]);

  if (className) {
    useClasses.push(className);
  }

  return <FormControl className={classes.root} disabled={disabled} componentname="t-radio-group">
            {label ? <FormLabel className={classes.label} component="legend">{label}</FormLabel> : ""}
            <RadioGroup color={color} className={clsx(useClasses)} {...other}>{children}</RadioGroup>
            {helperText ? <FormHelperText error={error} className={classes.helperText}>{helperText}</FormHelperText> : ""}
        </FormControl>;
}

TRadioGroup.propTypes = RadioGroup.propTypes;
export default withStyles(styles, {
  name: "MuiRadioGroup",
  useTheme: true
})(TRadioGroup);
