
import React from 'react';
import clsx from 'clsx';
import {withStyles} from '@material-ui/core/styles';
import {FormControlLabel, Radio} from '@material-ui/core';
import {RadioCheckedIcon, RadioIcon} from "../Icon/Icon";


// We can inject some CSS into the DOM.
const styles = (theme) => {
    return {
        root: {
            color: "inherit",
            fontWeight: 600,
            "&:hover": {
                background: "none"
            }
        },
        label: {
            fontSize: "12px",
            fontWeight: "500",
            color: theme.palette.text.primary,
        },
    }
};


function TRadioButton(props) {
    const {
        className,
        classes,
        label,
        icon,
        theme,
        color = 'default',
        checkedIcon = <RadioCheckedIcon color={props.disabled? "disabled" : color || "default"}/>,
        ...other
    } = props;
    const useClasses = ['radio']
    if (className) {
        useClasses.push(className)
    }
    const radio = <Radio color={color} checkedIcon={checkedIcon} icon={icon ? icon : <RadioIcon color={"disabled"}/>} classes={{root:classes.root, checked: classes.checked}} className={clsx(...useClasses)} {...other}/>
    if (props.label) {
        return <FormControlLabel classes={{label: classes.label}} control={radio} label={props.label} {...other}/>
    }
    return radio;
}

TRadioButton.propTypes = Radio.propTypes

export default withStyles(styles, {name: "MuiRadio", withTheme: true})(TRadioButton);
