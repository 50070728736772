import Box from "@material-ui/core/Box";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@ticketag/ticketag-uilib/src/components/base/Button/IconButton";
import {withRouter} from "react-router-dom";
import {T, withI18n} from "@ticketag/i18nlib";
import {NarrowArrowIcon} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@ticketag/ticketag-uilib/src/components/base/Container/Container";
import clsx from "clsx";
import {withLastLocation} from "react-router-last-location";



const defaultModalLayoutPadding = 12
const defaultModalLayoutMargin = 3

const styles = (theme) => {
    return {
        wrapper: {
            [theme.breakpoints.up("sm")]: {
                position: "absolute",
                width: "100%",
                top: "0",
                height: "calc(100vh - 192px)",
                margin: "96px 0",
                display: "flex",
            }
        },
        container: {
            display: "flex",
            flexDirection: "column",
            minHeight: "calc(100vh - 100px)"
        },
        header: {
            position: "relative",
            padding: "0 32px",
            width: "calc(100% - 64px)",
            minHeight: "100px",
            [theme.breakpoints.up("sm")]: {
                padding: "0 64px",
                width: "calc(100% - 128px)",
                height: "131px",
            },
            display: "flex",
            alignItems: "center",
            top: "0",
        },
        closeIcon: {
            width: "48px",
            height:"48px",
            position: "absolute",
            right: "32px",
            [theme.breakpoints.up("sm")]: {
                right: "64px"
            }
        },
        goBackButton: {
            cursor: "pointer",
            fontSize: "bold",
            "& svg": {
                marginTop: "4px",
                width: "14px",
                height: "14px",
                marginRight: "8px",
                transform: "rotate(180deg)"
            },
        },
    }
}


function TModalLayout(props) {
    const {children, classes, className, i18n, history, narrow, onGoBack, lastLocation, onClose, hideNavbar, ...other} = props;
    return (
        <>
            <header className={classes.header}>
                {
                    hideNavbar ?
                    null
                    :
                    <>
                        {
                            onGoBack  ?
                                <Box display={"flex"} className={classes.goBackButton} onClick={onGoBack}>
                                    <NarrowArrowIcon color={"error"}/>
                                    <Typography semiBold variant={"body1"} color={"error"}><T defaultVal={"Indietro"}>global.actions.go_back</T></Typography>
                                </Box>
                                :
                                null
                        }
                        {
                            onClose ?
                                <IconButton width={48} height={48} className={classes.closeIcon} color={"textPrimary"} onClick={onClose}>close</IconButton>
                                :
                                null
                        }
                    </>
                }
            </header>
            <main className={classes.wrapper}>
                <Container className={classes.container}>
                        {children}
                </Container>
            </main>
        </>

    )
}

export default withStyles(styles)(withRouter(withI18n(TModalLayout)))