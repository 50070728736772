import React from "react";
import PageContext from "./context";
import {withRouter, matchPath} from "react-router-dom"
import {matchRoutes} from "react-router-config";
import {withRoutes} from "../Routes/Provider";
import pageData from "../../pages"

class PageProvider extends React.Component {
    constructor(props) {
        super(props);
    }

    getPage() {
        const {page, route} = this.props;
        return this.matchRoute()
    }

    matchRoute() {
        const {location, match, routes} = this.props;
        const matchRoute = [...matchRoutes(routes, location.pathname)]
        const {route} = matchRoute.pop()
        return route && route.page && route.page.name
    }

    getPageData(pageName) {
        return pageData[pageName] || {}
    }

    render() {
        const {children} = this.props
        return (
            <PageContext.Provider value={{
                page: this.getPage(),
                pages: {
                    getPageData: (name) => this.getPageData(name)
                }
            }}>
                {children}
            </PageContext.Provider>
        )
    }


}

export default withRoutes(withRouter(PageProvider))

export const withPage = (Component) => {
    return ({ref, ...props}) => {
        return (
            <PageContext.Consumer>
                {({page, pages}) => {
                    return <Component ref={ref} page={page} pages={pages} {...props}/>
                }}
            </PageContext.Consumer>
        )
    }
}