
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@ticketag/ticketag-uilib/src/components/base/Button/Button";
import Box from "@material-ui/core/Box/Box";
import clsx from "clsx";

const styles = (theme) => {
    return {
        root: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            //position: "relative"
        },
        button: ({position}) => ({
            width: "80px",
            height: "80px",
            borderRadius: "50%",
            margin: "16px",
            [theme.breakpoints.up("sm")]: {
                position: position,
                //bottom: "100px"
            }
        }),
        text: {
            minWidth: "230px",
            margin: "16px",
        }
    }
}

function TNextButton(props) {
    const {classes, className,
        children,
        disabled,
        onClick,
        position = "absolute",  ...other
    } = props;
    return (
        <Box className={clsx(classes.root, className)} {...other}>
            <Button variant={"contained"} color={"gradient"} className={children ? classes.text : classes.button } disabled={disabled} onClick={disabled ? null : onClick}{...other}>
                {
                    children ?
                     children :
                        <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M30.6316 16.8585C31.4127 16.0775 31.4127 14.8112 30.6316 14.0301L17.9037 1.3022C17.1226 0.521152 15.8563 0.521152 15.0753 1.3022C14.2942 2.08325 14.2942 3.34958 15.0753 4.13063L26.389 15.4443L15.0753 26.758C14.2942 27.5391 14.2942 28.8054 15.0753 29.5865C15.8563 30.3675 17.1226 30.3675 17.9037 29.5865L30.6316 16.8585ZM0.772949 17.4443H29.2174V13.4443H0.772949V17.4443Z" fill="#FCFCFC"/>
                        </svg>
                }
            </Button>
        </Box>
    )
}

export default withStyles(styles)(TNextButton)