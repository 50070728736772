import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Typography from "../Typography/Typography";
import clsx from "clsx";
import {Box, capitalize} from "@material-ui/core";
import AvatarIcon, {getAllAvatars} from "../Avatar/AvatarIcon";
import Grid from "@material-ui/core/Grid";

const styles = () => {
    return {
        root: {
            //width: '100%'
        },
        iconBoxLarge: {
            width: '120px',
            height: '120px',
        },
        iconBoxMedium: {
            width: '60px',
            height: '60px',
        }
    }
}


function TAvatarSelector(props) {
    const {classes, onSelect = () => null, size='medium', defaultValue = null, ...other} = props;
    const [selected, setSelected] = React.useState(defaultValue);
    const avatars = getAllAvatars()
    const iconBoxClass = classes['iconBox' + capitalize(size)]
    return <Grid container justify="center" classes={{root: classes.root}} {...other}>
        {avatars.map(a => <Box classes={{root: iconBoxClass}} p={size==='medium'? 2.5 : 5}>
            <AvatarIcon size={size} src={a.name} selected={a.name === selected} onClick={() => {
                setSelected(a.name);
                onSelect(a.name)
            }} hover/>
        </Box>)}
    </Grid>;
}

export default withStyles(styles, {name: "AvatarSelector"})(TAvatarSelector);
