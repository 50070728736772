

import Messages from "../../../icons/messages.svg";
import Groups from "../../../icons/groups.svg";
import Wallet from "../../../icons/wallet.svg";
import Filter from "../../../icons/filter.svg";
import {RegisterIcon} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";

import Netflix from "../../../icons/services/netflix.svg";
import Spotify from "../../../icons/services/spotify.svg";
import Disney from "../../../icons/services/disney+.svg";

import CreateGroup from "../../../icons/create_group.svg";
import JoinGroup from "../../../icons/join_group.svg";

import MoneyIn from "../../../icons/money_in.svg";
import MoneyOut from "../../../icons/money_out.svg";
import Lens from "../../../icons/lens.svg";

import ArrowDown from "../../../icons/arrow_down.svg";
import PriceCut from "../../../icons/price_cut.svg";

import CloseChip from "../../../icons/close_chip.svg";

export const MessagesIcon = RegisterIcon("messages", Messages);
export const GroupsIcon = RegisterIcon("groups", Groups);
export const WalletIcon = RegisterIcon("wallet", Wallet);
export const MoneyInIcon = RegisterIcon("moneyin", MoneyIn);
export const MoneyOutIcon = RegisterIcon("moneyout", MoneyOut);
export const LensIcon = RegisterIcon("lens", Lens);
export const ArrowDownIcon = RegisterIcon("arrowdown", ArrowDown);
export const PriceCutIcon = RegisterIcon("pricecut", PriceCut);
export const FilterIcon = RegisterIcon("filter", Filter);
export const CloseChipIcon = RegisterIcon("closechip", CloseChip);

export const NetflixIcon = RegisterIcon("netflix", Netflix);
export const SpotifyIcon = RegisterIcon("spotify", Spotify);
export const DisneyIcon = RegisterIcon("disney+", Disney);

export {JoinGroup, CreateGroup};
