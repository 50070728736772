import React, {Component} from "react";
import {I18nContext, T, withI18n} from "@ticketag/i18nlib";
import {WebapiprotoPageName as PAGE_NAMES} from "@ticketag/diveedi-client/dist/model/WebapiprotoPageName";
import Box from "@material-ui/core/Box";
import {Skeleton} from "@material-ui/lab";
import Accordion from "@ticketag/ticketag-uilib/src/components/base/Accordion/Accordion";
import AccordionSummary from "@ticketag/ticketag-uilib/src/components/base/Accordion/AccordionSummary";
import AccordionDetails from "@ticketag/ticketag-uilib/src/components/base/Accordion/AccordionDetails";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import {withApi} from "../../hoc/Api/Provider";
import withStyles from "@material-ui/core/styles/withStyles";
import {withRouter} from "react-router-dom"
import {withPage} from "../../hoc/Page/Provider";

const styles = (theme) => {
    return {
        root: {
            marginBottom: "150px"
        },
        card: {
          backgroundColor: "transparent"
        },
        cardTitle: {
            fontSize: "24px",
            fontWeight: "600",
            LineHeight: "24px",
            marginTop: "16px",
            marginBottom: "22px",
            color: theme.palette.text.secondary
        },
        cardContent: {
            fontSize: "16px",
            fontWeight: "300",
            LineHeight: "26px",
            marginTop: "20px",
            marginBottom: "30px"
        },
        skeletonBox: {
            paddingTop: "16px",
            paddingBottom: "22px",
            "& span": {
                margin: "auto",
                height: "24px",
                borderRadius: "4px",
            },
        }
    }
};

const pageNames = {
    "privacy_policy" : PAGE_NAMES.privacyPolicy,
    "terms_and_conditions": PAGE_NAMES.termsAndConditions,
    "faq": PAGE_NAMES.faq
}

class ListPage extends Component {
    static contextType = I18nContext

    constructor(props) {
        super(props);
        this.state = {
            cards: null,
            isLoaded: false,
            expanded: null
        }
        this.onLocaleUpdate = this.onLocaleUpdate.bind(this)
    }

    componentDidMount() {
        this.onUnmount = this.context.onLocaleUpdate(this.onLocaleUpdate.bind(this))
        this.getPage(this.context.selectedLocale())
    }

    componentWillUnmount() {
        this.onUnmount()
    }

    onLocaleUpdate(locale) {
        this.props.onLocaleUpdate && this.props.onLocaleUpdate(locale)
        this.setState({isLoaded: false})
        this.getPage(locale)
    }

    getPage(locale) {
        const {page} = this.props
        this.props.api.systemApi.getPage(pageNames[page], {lang: locale}).then((data) => {
            console.log(data)
            this.props.onLoad && this.props.onLoad(data)
            this.setState({
                cards: data.cards,
                isLoaded: true
            })
        })
    }

    handleChange(i){
        return () => {
            this.setState({expanded: this.state.expanded === i ? null : i})
        }
    }

    renderCard(index, card) {
        const {page, accordion, classes} = this.props
        return accordion ?
            <Accordion classes={classes.card} key={index}
                       expanded={this.state.expanded === index} onChange={this.handleChange(index)}>
                <AccordionSummary classes={{content: classes.cardTitle}}>
                    <T defaultVal={card.title}>{`pages.${page}.${card.key}_title`}</T>
                </AccordionSummary>
                <AccordionDetails className={classes.cardContent}>
                    <Box width={"100%"}>
                        <Typography align={"justify"} whiteSpace={"preLine"} variant={"body2"}>
                            <T multiline useMarkdown defaultVal={card.content}>{`pages.${page}.${card.key}_content`}</T>
                        </Typography>
                    </Box>
                </AccordionDetails>
            </Accordion>
            :
            <Box key={index}>
                <Typography whiteSpace={"pre-line"} variant={"h6"} className={classes.cardTitle}>
                    <T defaultVal={card.title}>{`pages.${page}.${card.key}_title`}</T>
                </Typography>
                <Typography align={"justify"} whiteSpace={"preLine"} color={"textPrimary"} variant={"body2"} className={classes.cardContent}>
                    <T multiline useMarkdown defaultVal={card.content}>{`pages.${page}.${card.key}_content`}</T>
                </Typography>
            </Box>
    }

    renderSkeleton(index) {
        return <Box key={index}  className={this.props.classes.skeletonBox}>
            <Skeleton variant={"rect"}/>
        </Box>
    }

    render() {
        return <Box className={this.props.classes.root}>
            {
                !this.state.isLoaded ?
                    [...Array(this.props.short ? 5 : 8).keys()].map((i) => this.renderSkeleton(i))
                    :
                    this.state.cards
                        .filter((elt) => this.props.short ? elt.required : true)
                        .map((card, i) => this.renderCard(i, card))
            }
        </Box>
    }

}

export default withStyles(styles)(withPage(withApi(withI18n((withRouter(ListPage))))))