/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * Enum class WebapiprotoDocTypes.
 * @enum {String}
 * @readonly
 */
const WebapiprotoDocTypes = {
  /**
   * value: "UNKNOWN"
   * @const
   */
  UNKNOWN: "UNKNOWN",

  /**
   * value: "OTHER"
   * @const
   */
  OTHER: "OTHER",

  /**
   * value: "ID"
   * @const
   */
  ID: "ID",

  /**
   * value: "PASSPORT"
   * @const
   */
  PASSPORT: "PASSPORT",

  /**
   * value: "DRIVER_LICENCE"
   * @const
   */
  DRIVER_LICENCE: "DRIVER_LICENCE",

  /**
   * value: "CF"
   * @const
   */
  CF: "CF",

  /**
   * Returns a <code>WebapiprotoDocTypes</code> enum value from a JavaScript object name.
   * @param {Object} data The plain JavaScript object containing the name of the enum value.
   * @return {module:model/WebapiprotoDocTypes} The enum <code>WebapiprotoDocTypes</code> value.
   */
  constructFromObject: function(object) {
    return object;
  }
};

export {WebapiprotoDocTypes};
