import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { T } from "@ticketag/i18nlib";
import Form from "@ticketag/ticketag-uilib/src/components/complex/Form/Form";
import PasswordTextField from "@ticketag/ticketag-uilib/src/components/base/TextField/PasswordTextField";

const styles = theme => {
  return {
    root: {}
  };
};

const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)([a-zA-Z\d$&+,:;=?_@#|'<>.^*()%!-]+){8,}$/;
const passwordPatternError = 'La password deve contenere 8 caratteri di cui almeno una lettera minuscola, una maiuscola ed un numero. Per favore inserisci una password valida.';
const passwordNotMatchError = 'Le password non corrispondono';

const ChangePasswordForm = props => {
  return <Form T={T} {...props} componentname="change-password-form">
        <PasswordTextField grid={{
      md: 12
    }} box={{
      my: 2
    }} fullWidth name={"oldPassword"} variant={"outlined"} label={'Vecchia password'} rules={{
      required: {
        value: true
      }
    }} />
        <PasswordTextField grid={{
      md: 12
    }} box={{
      my: 2
    }} fullWidth name={"newPassword1"} variant={"outlined"} label={'Password'} rules={{
      required: {
        value: true
      },
      pattern: {
        value: new RegExp(passwordPattern, 'i'),
        message: {
          key: 'components.change_password_form.invalid_password',
          default: passwordPatternError
        }
      }
    }} />
        <PasswordTextField grid={{
      md: 12
    }} box={{
      my: 2
    }} fullWidth name={"newPassword2"} variant={"outlined"} label={"ripeti password"} rules={{
      required: {
        value: true
      },
      validate: {
        match: (value, params, form) => value === form.getValues('newPassword1'),
        message: {
          key: 'components.change_password_form.passwords_not_match',
          default: passwordNotMatchError
        }
      }
    }} />
    </Form>;
};

export default withStyles(styles, {
  useTheme: true
})(ChangePasswordForm);