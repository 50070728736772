import {pages} from "./constants";

const page = (name, data) => {
    return {[name]: {name: name, ...data}}
}

const pageData = {
    ...page(pages.HOMEPAGE,
        {
            seo: {
                title: "diveedi",
                description: "diveedi",
            },
            layout: {
                fullWidth: true,
                logo: true
            }
        }),
    ...page(pages.ERROR, {
        seo: {
            title: "Errore | Diveedi",
            description: "Si è verificato un errore" ,
        },
        layout: {
            fullWidth: false,
            logo: true
        }
    }),
    ...page(pages.FAQ, {
        title: "Domande frequenti",
        subtitle: "F.A.Q",
        seo: {
            title: "Faq | Diveedi",
            description: "Domande frequenti",
            index: true,
        },
        layout: {
            fullWidth: false,
            title: "Domande frequenti",
            centered: true,
        }
    }),
    ...page(pages.PRIVACY_POLICY, {
        seo: {
            title: "Privacy policy | Diveedi",
            description: "Le nostre privacy&cookies policies",
        },
        title:"Privacy policy",
        subtitle: "Le nostre privacy&cookies policies",
        layout: {
            fullWidth: false,
            title: "Privacy policy",
            centered: true,
        },
    }),
    ...page(pages.TERMS_AND_CONDITIONS, {
        seo: {
            title: "T&C | Diveedi",
            description: "Termini e condizioni di Diveedi",
        },
        title: "Termini e condizioni",
        subtitle: "Di seguito i termini e condizioni di diveedi",
        layout: {
            fullWidth: false,
            title: "Termini e condizioni",
            centered: true,
        },
    }),
    ...page(pages.WELCOME, {
        seo: {
            title: "Welcome | Diveedi",
            description: "Benvenuto su Diveedi",
        },
        layout: {
            fullWidth: false,
            title: "Benvenuto",
            centered: true,
        },
    }),
    ...page(pages.WALLET, {
        seo: {
            title: "Wallet | Diveedi",
            description: "Il tuo portafoglio su Diveedi",
        },
        title: "Portafoglio",
        layout: {
            fullWidth: false,
            title: "Portafoglio",
            centered: false,
        },
    }),
    ...page(pages.PROFILE, {
        seo: {
            title: "Profilo | Diveedi",
            description: "Il tuo profilo su Diveedi",
        },
        layout: {
            fullWidth: false,
            title: "Profilo",
        },
    }),
    ...page(pages.SERVICES, {
        seo: {
            title: "Servizi | Diveedi",
            description: "Scegli quale servizio condividere e decidi se creare un gruppo come admin oppure entrare come joiner",
        },
        title: "Servizi",
        layout: {
            title: "Servizi",
            centered: true,
            fullWidth: false
        },
    }),
    ...page(pages.SUGGESTIONS, {
        seo: {
            title: "Suggerimenti | Diveedi",
            description: "Quali servizi vorresti su Diveedi?",
        },
        page: pages.SUGGESTIONS,
        title: "Quali servizi vorresti su Diveedi?",
        subtitle: "Diveedi è in continua espansione, ma abbiamo bisogno del tuo aiuto per arricchire la nostra offerta: ci sono altri servizi che vorresti ottenere o condividere? Faccelo sapere ora!",
        layout: {
            title: "Servizi",
            centered: true,
        }
    }),
    ...page(pages.CHAT, {
        seo: {
            title: "Chat | Diveedi",
            description: "La tua chat su Diveedi",
        },
        layout: {
            fullWidth: false,
            title: "Chat",
        },
    }),
    ...page(pages.INVITE, {
        seo: {
            title: "Sei stato invitato | Diveedi",
            description: "Sei stato invitato su su Diveedi",
        },
        title: `Invito a un gruppo {{.args.Name}}`,
        subtitle: `{{.args.Owner}} ti ha invitato nel suo gruppo {{.args.Name}}`,
        layout: {
            center: true,
        },
    }),
    ...page(pages.GROUPS, {
        seo: {
            title: "Dashboard | Diveedi",
            description: "Dashboard | Diveedi" ,
        },
        title: "Dashboard",
        layout: {
            title: "Dashboard",
        },
    }),
    ...page(pages.SELECT_SERVICE_FAMILY, {
        seo: {
            title: "Crea gruppo | Diveedi",
            description: "Seleziona il servizio che vuoi condividere su Diveedi" ,
        },
        title: "Quale servizio?",
        layout: {
            modal: true,
        },
    }),
    ...page(pages.JOIN_CREATE_GROUP, {
        seo: {
            title: "Acquista o Condividi {{.args.Name}} | Diveedi",
            description: "Acquista o Condividi {{.args.Name}} su Diveedi",
            index: true,
        },
        title: "Cosa vuoi fare?",
        helpTitle: "Cosa vuoi fare?",
        footer: "Per maggiori informazioni [Clicca qui]({{ .args.Help}})",
        layout: {
            modal: true
        }
    }),
    ...page(pages.SELECT_CREATE_SERVICE_TYPE, {
        seo: {
            title: "Seleziona il piano {{.args.Name}} | Diveedi",
            description: "Condividi {{.args.Name}} su Diveedi"
        },
        title: "Tipologia",
        subtitle: "Quale piano {{.args.Name}} vuoi condividere?",
        helpTitle: "Tipologia",
        footer: "Per maggiori informazioni [Clicca qui]({{ .args.Help}})",
        layout: {
            modal: true
        }
    }),
    ...page(pages.SELECT_JOIN_SERVICE_TYPE, {
        seo: {
            title: "Seleziona il piano {{.args.Name}} | Diveedi",
            description: "Acquista {{.args.Name}} su Diveedi"
        },
        title: "Tipologia",
        subtitle: "A quale piano {{.args.Name}} vuoi partecipare?",
        helpTitle: "Tipologia",
        footer: "Per maggiori informazioni [Clicca qui]({{ .args.Help}})",
        layout: {
            modal: true
        }
    }),
    ...page(pages.SELECT_JOIN_GROUP, {
        seo: {
            title: "Seleziona il gruppo {{.args.Name}} | Diveedi",
            description: "Scegli il gruppo tuo {{.args.Name}} su Diveedi"
        },
        title: "Scegli un gruppo",
        layout: {
            modal: true
        }
    }),
    ...page(pages.PAYMENT, {
        seo: {
            title: "Checkout | Diveedi",
            description: "Acquista il tuo gruppo {{.args.Name}} su Diveedi" ,
        },
        title: "Checkout",
        helpTitle: "Checkout",
        footer:`[Che garanzie ho nel darvi i dati?]({{ .args.Help}})`,
        layout: {
            modal: true
        }
    }),
    ...page(pages.JOIN_SUMMARY, {
        seo: {
            title: "Riepilogo | Diveedi",
            description: "Acquista il tuo gruppo {{.args.Name}} su Diveedi" ,
        },
        title: "Riepilogo",
        helpTitle:  "Riepilogo",
        footer: `[Che garanzie ho nel darvi i dati?]({{ .args.Help}})`,
        layout: {
            modal: true
        }
    }),
    ...page(pages.JOIN_SUCCESS, {
        seo: {
            title: "Checkout completato | Diveedi",
            description: "Checkout completato"
        },
        layout: {
            modal: true
        }
    }),
    ...page(pages.JOIN_FAILED, {
        seo: {
            title: "Checkout fallito | Diveedi",
            description: "Checkout fallito"
        },
        layout: {
            modal: true
        }
    }),
    ...page(pages.JOIN_PROCESSING, {
        seo: {
            title: "In elaborazione... | Diveedi",
            description: "Checkout in fase di elaborazione"
        },
        layout: {
            modal: true
        }
    }),
    ...page(pages.GROUP_DETAILS, {
        seo: {
            title: "Il tuo gruppo {{.args.Name}} | Diveedi",
            description: "Il tuo gruppo {{.args.Name}}",
        },
        title: "Il tuo gruppo {{.args.Name}}",
        layout: {
            title: "`{{.args.Name}}`"
        }
    }),
    ...page(pages.GROUP_SHARE, {
        seo: {
            title: "Invita un amico | Diveedi",
            description: "Invita un amico su Diveedi"
        },
        title: "Invita un amico",
        layout: {
            title: "`{{.args.Name}}`",
        }
    }),
    ...page(pages.GROUP_SETTINGS, {
        seo: {
            title: "Impostazioni | Diveedi",
            description: "Imposta il tuo gruppo {{.args.Name}}",
        },
        title: "Impostazioni",
        subtitle: "Qui puoi modificare le impostazioni del tuo gruppo di condivisione",
        layout: {
            title: "{{.args.Name}}"
        }
    }),
    ...page(pages.GROUP_DISPUTE, {
        seo: {
            title: "Dispute | Diveedi",
            description: "Segnala un problema col tuo gruppo {{.args.Name}}",
        },
        layout: {
            title: "{{.args.Name}}"
        }
    }),
    ...page(pages.CREATE_VISIBILITY,  {
        seo: {
            title: "Crea gruppo : visibilità | Diveedi",
            description: "Decidi chi può vedere il tuo gruppo",
        },
        title: "Visibilità",
        subtitle: "Chi può vedere il tuo gruppo {{.args.Name}}?",
        footer: "[Maggiori informazioni]({{ .args.Help}})",
        helpTitle: "Visibilità",
        layout: {
            modal: true
        }
    }),
    ...page(pages.CREATE_SIZE, {
        seo: {
            title: "Crea gruppo : posti disponibili | Diveedi",
            description: "Decidi il numero di persone che può entrare nel tuo gruppo",
        },
        title: "Posti disponibili",
        subtitle: "Segna il numero di persone, oltre a te che possono utilizzare il servizio.",
        footer: "[Maggiori informazioni]({{ .args.Help}})",
        helpTitle: "Posti disponibili",
        layout: {
            modal: true
        }
    }),
    ...page(pages.CREATE_CREDENTIALS, {
        seo: {
            title: "Crea gruppo : credenziali | Diveedi",
            description: "Imposta le credenziali del tuo gruppo",
        },
        footer: "[Maggiori informazioni]({{ .args.Help}})",
        helpTitle: "Credenziali",
        layout: {
            modal: true
        }
    }),
    ...page(pages.CREATE_SUMMARY, {
        seo: {
            title: "Riepilogo | Diveedi",
            description: "Decidi chi può vedere il tuo gruppo",
        },
        title: "Riepilogo",
        layout: {
            modal: true
        }
    }),
    ...page(pages.CREATE_SUCCESS, {
        seo: {
            title: "Gruppo completato | Diveedi",
            description: "Gruppo creato con successo",
        },
        title: "Gruppo creato con successo",
        layout: {
            modal: true
        }
    })
}

function x() {
}

export default pageData