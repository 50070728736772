
import React, {useState} from 'react';
import clsx from 'clsx';
import {withStyles} from '@material-ui/core/styles';
import {Icon, SelectArrowIcon} from "../Icon/Icon";
import Box from "@material-ui/core/Box";
import Checkbox from "./Checkbox";
import Typography from "../Typography/Typography";
import {Collapse} from "@material-ui/core";
import IconButton from "../Button/IconButton";

// We can inject some CSS into the DOM.
const styles = (theme) => {
    return {
        root: {
            fontWeight: 500,
            fontSize: "14px",
            width: "100%",
            "& .MuiFormControlLabel-label": {
                fontWeight: "500",
                textTransform: "uppercase"
            }
        },
        icon: {
            transitionDuration: "0.3s",
            transitionProperty: "transform",
        },
        expanded: {
            transform: "rotate(180deg)"
        }
    }
};

function TCheckboxGroup(props) {
    const {
        form,
        classes,
         items,
        expandStrategy = 'normal',
        onChange = () => {},
        ...other
    } = props;
    const [expanded, setExpanded] = React.useState(items.map((elt) => elt.expanded || false));
    console.log(expanded)
    const handleChange = (panel) => (event) => {
        if (expandStrategy === "exclusive") {
            setExpanded(expanded.map((elt, i) => i === panel ? !elt : false));
        } else {
            setExpanded(expanded.map((elt, i) => i === panel ? !elt : elt));
        }
    };
    return (
        <Box width={"100%"}>
            {
                items.map((elt, i) => {
                    return (
                        <div key={elt.key} className={classes.root}>
                            <Box display={"flex"} alignItems={"center"}>
                                <div style={{display: "flex", flexGrow: 2}}>
                                    <Checkbox
                                        onChange={onChange}
                                        name={elt.key}
                                        color={"textSecondary"}
                                        label={elt.title}
                                        defaultValue={elt.checked}
                                        readonly={elt.required}
                                    />
                                </div>
                                <IconButton color={"textSecondary"}
                                            className={clsx(classes.icon, expanded[i] ? classes.expanded : null)}
                                            onClick={handleChange(i)}>
                                    select_arrow
                                </IconButton>
                            </Box>
                            <Collapse in={expanded[i]} timeout="auto" unmountOnExit>
                                <Box className={classes.collapse}>
                                    <Typography color={"textPrimary"} variant={"subtitle1"} align={"justify"}>{elt.content}</Typography>
                                </Box>
                            </Collapse>
                        </div>
                    )
                })
            }
        </Box>
    )
}

export default withStyles(styles, {withTheme: true})(TCheckboxGroup);
