import React, { useState } from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";
import { useTheme, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import capitalize from "@material-ui/core/utils/capitalize";
import { getIconSvg, Icon } from "../Icon/Icon";
import MissingIcon from "../../../resources/avatars/missing.svg";
import PlusIcon from "../../../resources/avatars/plus.svg";
export const avatarCircle = (src, color, gradientStart, gradientEnd, isOver = false, strokeWidth = 2) => <g>
    {src({
    color,
    isOver
  })}
    <circle r={61} cx={60} cy={60} strokeWidth={5} stroke={'white'} />
    <path d="M59.5329 118C91.8234 118 118 91.8233 118 59.5328C118 27.2423 91.8234 1.06567 59.5329 1.06567C27.2424 1.06567 1.06573 27.2423 1.06573 59.5328C1.06573 91.8233 27.2424 118 59.5329 118Z" stroke={isOver ? 'url(#paint0_linear)' : color} strokeWidth={strokeWidth} strokeMiterlimit="10" />
    <linearGradient id="paint0_linear" x1="116.972" y1="73.9318" x2="2.04551" y2="73.6117" gradientUnits="userSpaceOnUse">
        <stop stopColor={gradientStart} />
        <stop offset="1" stopColor={gradientEnd} />
    </linearGradient>
</g>;
export const avatarSelected = (src, gradientStart, gradientEnd, primaryColor, size = 120, strokeWidth = 2) => <g width={size} height={size}>
    {src({
    color: primaryColor
  })}
    <circle r={61} cx={60} cy={60} strokeWidth={5} stroke={'white'} />
    <path d="M47.5651 117.075C47.5465 117.302 47.5465 117.53 47.5651 117.757C48.2411 117.903 48.9171 118.039 49.5995 118.162C49.302 118.016 48.5451 117.634 47.5522 117.072L47.5651 117.075Z" fill="transparent" />
    <path d="M60.04 119.08C92.6469 119.08 119.08 92.6469 119.08 60.04C119.08 27.4331 92.6469 1 60.04 1C27.4331 1 1 27.4331 1 60.04C1 92.6469 27.4331 119.08 60.04 119.08Z" stroke="url(#paint0_linear)" strokeWidth={strokeWidth} strokeMiterlimit="10" />
    <path d="M102 33C110.284 33 117 26.2843 117 18C117 9.71573 110.284 3 102 3C93.7157 3 87 9.71573 87 18C87 26.2843 93.7157 33 102 33Z" fill={primaryColor} />
    <path d="M101.025 23.9998C100.828 24.001 100.632 23.9631 100.449 23.8885C100.266 23.8139 100.1 23.7039 99.96 23.5648L95.01 18.5998C94.7306 18.3188 94.5738 17.9386 94.5738 17.5423C94.5738 17.1461 94.7306 16.7659 95.01 16.4848C95.291 16.2055 95.6712 16.0487 96.0675 16.0487C96.4638 16.0487 96.8439 16.2055 97.125 16.4848L101.025 20.3848L109.095 12.3148C109.229 12.1578 109.395 12.0303 109.581 11.9403C109.767 11.8502 109.97 11.7996 110.176 11.7916C110.383 11.7837 110.589 11.8185 110.781 11.8939C110.974 11.9693 111.149 12.0837 111.295 12.2299C111.441 12.3761 111.556 12.5509 111.631 12.7434C111.706 12.9358 111.741 13.1418 111.733 13.3484C111.725 13.555 111.675 13.7577 111.585 13.9438C111.495 14.1299 111.367 14.2954 111.21 14.4298L102.09 23.5648C101.95 23.7039 101.784 23.8139 101.601 23.8885C101.418 23.9631 101.222 24.001 101.025 23.9998Z" fill="white" />
    <defs>
        <linearGradient id="paint0_linear" x1="116.972" y1="73.9318" x2="2.04551" y2="73.6117" gradientUnits="userSpaceOnUse">
            <stop stopColor={gradientStart} />
            <stop offset="1" stopColor={gradientEnd} />
        </linearGradient>
    </defs>
</g>;
const AvatarIcons = {};
export const registerAvatarIcon = (name, icon) => {
  switch (typeof icon) {
    case "string":
      const parser = new DOMParser();
      const doc = parser.parseFromString(icon, "image/svg+xml");
      const paths = doc.getElementsByTagName('path');
      const elements = Array.from(paths).map((p, i) => {
        const attrs = {};

        for (const attr of p.attributes) {
          attrs[attr.name] = attr.value;
        }

        return <path key={i} {...attrs} />;
      });

      AvatarIcons[name] = () => elements;

      break;

    case "function":
      AvatarIcons[name] = icon;
      break;

    default:
      AvatarIcons[name] = () => <icon />;

  }

  return AvatarIcons[name];
};
export const getAllAvatars = () => Object.keys(AvatarIcons).filter(k => !['missing', 'plus'].includes(k)).map(k => ({
  name: k,
  icon: AvatarIcons[k]
}));

const styles = theme => {
  return {
    root: {
      color: 'transparent'
    },
    fainted: {
      opacity: 0.25,
      '&:hover': {
        opacity: 1
      }
    },
    disabled: {
      opacity: 0.75,
      cursor: 'default',
      '&:hover': {
        opacity: 0.75
      }
    },
    clickable: {
      cursor: 'pointer'
    },
    sizeSmall: {
      height: '30px',
      width: '30px'
    },
    sizeMedium: {
      height: '60px',
      width: '60px'
    },
    sizeLarge: {
      height: '120px',
      width: '120px'
    }
  };
};

export const AvatarMissingIcon = registerAvatarIcon("missing", MissingIcon);
export const AvatarPlusIcon = registerAvatarIcon("plus", PlusIcon);

const AvatarIcon = ({
  theme = useTheme(),
  classes,
  className,
  color = 'default',
  disabled = false,
  hover = false,
  selected = false,
  clickable = true,
  icon = false,
  fainted = false,
  containerClasses,
  src,
  ...other
}) => {
  const useClasses = [];
  const [isOver, setIsOver] = useState(false);

  if (disabled) {
    color = 'disabled';
    hover = false;
    other.onClick = null;
  }

  let avatarComponent;

  if (icon) {
    const originalColor = color;

    avatarComponent = ({
      color,
      isOver = false
    }) => {
      if (src) {
        const icon = <Icon color={originalColor}>{src}</Icon>;
        return React.createElement(icon.type, { ...icon.props,
          x: 30,
          y: 30,
          height: 60,
          width: 60
        }); //isOver ? 'url(#paint0_linear)' : color
      }
    };
  } else {
    avatarComponent = AvatarIcons[src] ? ({
      color
    }) => AvatarIcons[src]({
      color
    }) : () => <path />;
  }

  let defaultColor = theme.palette.primary.main;
  let defaultGradientStart = theme.palette.default_gradient_start;
  let defaultGradientEnd = theme.palette.default_gradient_end;

  switch (color) {
    case "secondary":
      defaultColor = theme.palette.secondary.main;
      defaultGradientStart = defaultColor;
      defaultGradientEnd = defaultColor;
      break;

    case "error":
      defaultColor = theme.palette.error.main;
      break;

    case "disabled":
      defaultColor = theme.palette.text.disabled;
      break;
  }

  useClasses.push(classes["size" + capitalize(other.size || "medium")]);
  useClasses.push(className);

  if (clickable) {
    useClasses.push(classes.clickable);
  }

  return <SvgIcon onMouseOver={() => {
    hover ? setIsOver(true) : null;
  }} onMouseOut={() => {
    hover ? setIsOver(false) : null;
  }} viewBox={'0 0 120 120'} className={clsx(classes.root, ...useClasses, {
    [classes.fainted]: fainted,
    [classes.disabled]: disabled
  })} {...other} componentname="avatar-icon">
        {selected ? avatarSelected(avatarComponent, theme.palette.default_gradient_start, theme.palette.default_gradient_end, theme.palette.primary.main) : avatarCircle(avatarComponent, defaultColor, defaultGradientStart, defaultGradientEnd, isOver)}
    </SvgIcon>;
};

export default withStyles(styles, {
  useTheme: true
})(AvatarIcon);