import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T, withI18n} from "@ticketag/i18nlib";
import {withApi} from "../../hoc/Api/Provider";
import AuthContext from "../../hoc/Auth/context";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@ticketag/ticketag-uilib/src/components/base/Checkbox/Checkbox"
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography"
import Box from "@material-ui/core/Box";
import Button from "@ticketag/ticketag-uilib/src/components/base/Button/Button";
import JSForm from "../../components/JSForm/JSForm";
import {withErrorModal} from "../../components/ErrorMessage/ErrorMessage";
import {withModals} from "../../../../../ticketag-uilib/src/components/hoc/Modals/Provider";
import {UndoCreateGroup} from "../../hoc/Modals/Modals";
import NextButton from "../../components/NextButton/NextButton";
import Title from "../../components/Title/Title";
import Scroll from "../../components/Scroll/Scoll";
import TextFooter from "../../components/TextFooter/TextFooter";
import Help from "../../components/Help/Help";
import Subtitle from "../../components/Subtitle/Subtitle";
import Page from "../../components/Page/Page";
import {pages} from "../../constants";


const styles = (theme) => {
    return {
        root: {

        },
        nextButton: {
            width: "80px",
            height: "80px",
            borderRadius: "50%",
            marginBottom: "40px"
        },
        endSection: {
            height: "100%",
            "& > *": {
                height: "100%",
                alignItems: "flex-end"
            }
        },
        subtitle: {
            fontSize: "18px",
            fontWeight: "400",
            lineHeight: "30px",
            letterSpacing: "0.01em",
            marginBottom: "56px"
        },
        footer: {
            fontSize: "18px",
            fontWeight: "400",
            lineHeight: "30px",
            letterSpacing: "0.01em",
            marginBottom: "24px"
        },
        attention: {
            hypens: "none",
            textTransform: "uppercase",
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "20px",
            color: theme.palette.error.main,
            "& svg": {
                marginRight: "8px",
                marginBottom: "-2px",
            }
        },
        success: {
            hypens: "none",
            textTransform: "uppercase",
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "20px",
            color: "#57d9cb",
            "& svg": {
                opacity: "0.7",
                marginBottom: "-10px",
                width: "30px",
                height: "30px"
            }
        },
        checkbox: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& span": {
                fontWeight: "300",
                fontSize: "18px"
            }
        }
    }
};

const defaultSecurityInfo = `La sicurezza è importante: per creare il gruppo, entra nel tuo account e modifica la tua password personale con quella generata automaticamente che ti abbiamo fornito qui sopra!`

function AlertIcon() {
    return <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.8091 15.735L11.2193 0.857061C10.9648 0.41624 10.509 0.153076 10 0.153076C9.49098 0.153076 9.03516 0.41624 8.78066 0.857061L0.190898 15.735C-0.0636328 16.1758 -0.0636328 16.7021 0.190898 17.1429C0.445391 17.5837 0.901211 17.8469 1.41023 17.8469H18.5898C19.0988 17.8469 19.5546 17.5837 19.8091 17.1429C20.0636 16.7021 20.0636 16.1758 19.8091 15.735ZM18.793 16.5563C18.7677 16.6001 18.7076 16.6736 18.5898 16.6736H1.41023C1.29234 16.6736 1.23227 16.6001 1.20703 16.5563C1.18176 16.5125 1.14809 16.4237 1.20703 16.3216L9.79676 1.4437C9.8557 1.34163 9.94938 1.32636 9.99996 1.32636C10.0506 1.32636 10.1443 1.34159 10.2032 1.4437L18.793 16.3216C18.852 16.4237 18.8183 16.5125 18.793 16.5563Z"
            fill="#FF5485"/>
        <path d="M10.5868 5.48804H9.41345V11.7457H10.5868V5.48804Z" fill="#FF5485"/>
        <path
            d="M10.0003 14.4835C10.4323 14.4835 10.7825 14.1333 10.7825 13.7013C10.7825 13.2693 10.4323 12.9191 10.0003 12.9191C9.56824 12.9191 9.21802 13.2693 9.21802 13.7013C9.21802 14.1333 9.56824 14.4835 10.0003 14.4835Z"
            fill="#FF5485"/>
    </svg>;
}

class GroupCredentials extends Component {
    static contextType = AuthContext

    constructor(props) {
        super(props);
        this.state = {
            isLoaded:true,
            group: null,
            step: 1,
            isWaiting: false,
            formData: this.props.group.credentials,
            confirmData: null,
            isValid: !!this.props.group.credentials,
            confirm: null,
            isDirty: false,
        }
    }



    completeGroup() {
        this.setState({isWaiting: true})
        if (this.hasCredentials()) {
            withErrorModal(this.props.modals, this.props.api.groupApi.
            setGroupAccount(this.props.match.params.groupUuid, {
                data:  btoa(String.fromCharCode(...new TextEncoder("utf-8").encode(JSON.stringify(this.state.formData))))
            }).then(resp => {
                this.props.history.push(this.props.i18n.buildLocalizedUrl('groups', this.props.match.params.groupUuid, 'create', 'summary'))
            }))
        } else {
            this.props.history.push(this.props.i18n.buildLocalizedUrl('groups', this.props.match.params.groupUuid, 'create', 'summary'))
        }
    }


    hasCredentials() {
        return this.props.group.hasCredentials
    }

    goToStep(step) {
        this.setState({step: step})
    }

    renderTitle() {
        const step = this.state.step
        if (this.hasCredentials()) {
            if (step === 1) {
                return <T key={"group_credentials_title"} defaultVal={"Credenziali"}>pages.complete_group.title</T>
            }
            return <T key={"group_credentials_title_confirm_credentials"} defaultVal={"Conferma credenziali"}>pages.complete_group.title_confirm_credentials</T>
        }
        return (
            <T key={"group_credentials_title_no_credentials"} defaultVal={"Credenziali"}>pages.complete_group.title_no_credentials</T>
        )
    }

    renderSubtitle() {
        const step = this.state.step
        if (!this.hasCredentials()) {
            return <Box width={{xs: "100%", lg: "66.66666%"}} m={"auto"}>
                <Box ml={-2.5} mb={4} mt={2}>
                    <Typography align={"center"} className={this.props.classes.attention}>
                        {AlertIcon()}
                        &nbsp;
                        <T defaultVal={"Attenzione"}>pages.complete_group.attention</T>
                    </Typography>
                </Box>
                <Typography  color={"textPrimary"} className={this.props.classes.subtitle} align={"center"} variant={"body1"}>
                    <T key={"group_credentials_subtitle_no_credentials"}
                       useTemplate args={{Group: this.props.group.serviceFamily.name}}
                       defaultVal={"Questo gruppo non ha le credenziali, una volta che i partecipanti saranno entrati nel gruppo, dovrai invitarli dal pannello di amministrazione di {{.args.Group}}"}
                    >pages.complete_group.subtitle_no_credentials</T>
                </Typography>
            </Box>
        }
        if (step === 1) {
            return <T key={"group_credentials_subtitle"} defaultVal={"Le tue credenziali saranno criptate e salvate in modo sicuro."}>pages.complete_group.subtitle</T>
        }
        return <T key={"group_credentials_subtitle_confirm_credentials"} defaultVal={"Per favore conferma le tue credenziali per sicurezza."}>pages.complete_group.subtitle_confirm_credentials</T>
    }

    handleChange(formData, isValid) {
        const prevData = this.state.formData
        const checkEquals = (newData) => {
            return Object.keys(this.state.formData || {}).reduce((acc, key) => {
                return !!acc && newData[key] === prevData[key]
            }, true)
        }
        if (this.state.step === 1) {
            this.setState({formData: formData, isValid: isValid})
        } else {
            this.setState({ confirm: checkEquals(formData)})
        }
    }


    renderConfirmMessage() {
        if (this.state.confirm === null) {
            return null
        }
        if (!this.state.confirm) {
            return (
                <Typography align={"center"} className={this.props.classes.attention}>
                    { AlertIcon() }
                    &nbsp;
                    <T key={"credentials_no_matching"} defaultVal={"Le credenziali non coincidono. Per favore assicurati di immettere le credenziali corrette"}>pages.complete_group.credentials_no_matching</T>
                </Typography>
            )
        }
        return (
            <Typography align={"center"} className={this.props.classes.success}>
                <svg viewBox={"0 0 24 24"} fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.641 15.395l-3.336-3.336-1.136 1.128 4.472 4.472 9.6-9.6-1.128-1.128z" fill="#57d9cb"/>
                </svg>
                &nbsp;
                <T key={"credentials_matching"} defaultVal={"Le credenziali coincidono. Procedi per creare il tuo gruppo."}>pages.complete_group.credentials_matching</T>
            </Typography>

        )
    }

    handleConfirm(val) {
        this.setState({confirm: val})
    }

    handleNext() {
        if (this.hasCredentials()) {
            if (this.state.step === 2) {
                this.props.group.updateCredentials(this.state.formData).then(() => {
                    this.props.history.push(
                        this.props.i18n.buildLocalizedUrl("groups", this.props.group.uuid, "create", "summary")
                    )
                })
            } else {
                this.setState({step: 2, confirmData: this.state.formData})
            }
        } else {
            this.props.history.push(this.props.i18n.buildLocalizedUrl("groups", this.props.group.uuid, "create", "summary"))
        }
    }

    getSchema() {
        const schema = this.props.group.schema
        const confirmData = this.state.confirmData || this.props.group.credentials
        const step = this.state.step
        const setDefaultValues = (s) => {
            const oldProperties = schema.properties
            const newProperies = Object.keys(oldProperties).reduce((acc, key) => {
                acc[key] = {
                    ...oldProperties[key],
                    ...(confirmData[key] ? {default: confirmData[key]} : {})
                }
                return acc
            }, {})
            return {
                ...s,
                properties: newProperies
            }
        }
        return confirmData && step !== 2 ? setDefaultValues(schema) : schema
    }

    renderContent() {
        if (!this.state.isLoaded) {
            return null
        }
        if (!this.hasCredentials()) {
            return (
                    <Grid container justify={"center"} alignItems={"center"}>
                        <Grid item xs={12}>
                            <Box px={3} pt={2} pb={6} className={this.props.classes.checkbox}>
                                <Checkbox label={
                                    <T defaultVal={"Ho capito"}>pages.complete_group.confirm_no_credentials</T>
                                } onChange={(e, val) => this.handleConfirm( val)}/>
                            </Box>
                        </Grid>
                    </Grid>
            )
        }
        const schema = this.getSchema()
        const {group} = this.props;
        const serviceFamily = group.serviceFamily;
        const {step, isValid, isWaiting, confirm} = this.state
        const suffix = step === 2 ? "_confirm" : ""
        const disabled = step === 2 ? !(isValid && confirm) : !isValid
        return <Grid container justify={"center"}>
            <Grid item container justify={"center"} alignItems={"center"}>
                <JSForm
                    key={step}
                    size={"big"}
                    liveValidate={step === 1}
                    group={group}
                    serviceFamily={serviceFamily}
                    disabled={isWaiting}
                    schema={schema}
                    onFormChange={(formData, isValid) => this.handleChange(formData, isValid)}
                />
                {
                    this.state.step === 2 ?
                        <Box pt={4} pb={2}>
                            { this.renderConfirmMessage() }
                        </Box>
                        :
                        null
                }
            </Grid>
        </Grid>
    }

    render() {
        const {group, auth, i18n, history} = this.props;
        const {step, confirm, isValid} = this.state
        const hasCredentials = this.hasCredentials()
        const serviceFamily = this.props.group.serviceFamily
        const disabled =
            !hasCredentials ?
                !confirm :
                step === 2 ? !(isValid && confirm) : !isValid
        const {
            seo,
            title = this.renderTitle(),
            subtitle = this.renderSubtitle(),
            footer = "`[Che garanzie ho nel darvi i dati?]({{ .args.Help}})`",
            helpTitle = "Credenziali",
            layout,
            ...other
        } = this.props;
        return (
            <Page seo={seo} layout={{
                ...layout,
                onGoBack: step === 2 ?
                    () => this.setState({step: 1})
                    :
                    () => this.props.onGoBack()
                ,
                onClose:  () => this.props.onClose()
            }}>
                <Title>{this.renderTitle()}</Title>
                <Subtitle
                    args={{Name: serviceFamily}}
                >{this.renderSubtitle()}</Subtitle>
                <Scroll>
                    {this.renderContent()}
                </Scroll>
                <NextButton disabled={disabled} onClick={() => this.handleNext()}/>
                <TextFooter
                    helpTitle={`Credenziali`}
                    help={
                        <Help
                            id={`credentials`}
                            args={{Name:serviceFamily.name}}
                        >
                            {`Credenziali`}
                        </Help>
                    }>
                    {`[Che garanzie ho nel darvi i dati?]({{ .args.Help}})`}
                </TextFooter>
            </Page>
        )
    }
}

export default withStyles(styles, {useTheme: true})(withApi(withI18n(withModals(GroupCredentials))));
