import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import  {withRouter, Link as RouterLink} from "react-router-dom";
import {Icon} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Data from "../../../data";
import {withI18n} from "@ticketag/i18nlib";
import Hidden from "@material-ui/core/Hidden";
import {Badge, Tab, Tabs} from "@material-ui/core";
import {withChat} from "../../../hoc/Chat/Provider";
import clsx from "clsx";

const styles = (theme) => {
    return {
        tabs: {
            width: "auto",
            "& .MuiTab-root:last-child": {
                paddingRight: "0",
            },
        },
        tab: {
            lineHeight: "0px",
            minWidth: "0px",
            padding: "0 32px",
            minHeight: "72px",
            fontSize: "16px",
        },
        selected: {
            color: theme.palette.accent.main
        },
        icon: {
            width: "30px!important",
            height: "30px!important",
        },
        withBadge: {
            paddingLeft: "56px"
        },
        badge: {
            left: "-22px"
        }
    }
}

function TTabs(props) {
    const {
        i18n,
        location,
        actions = Data.DefaultLayout.appBar.tabs,
        classes,
        chatApi,
        ...other
    } = props;
    const unreadCount = chatApi.GetUnreadCount()
    const value = Object.keys(actions).find(
        (key) => ((new RegExp("^/[a-z]{2}-[A-Z]{2}" + actions[key].href)).test(location.pathname)))
    return (
        <Tabs
            TabIndicatorProps={{style: {display: "none"}}}
            value={value || false}
            classes={{root: classes.tabs}}>
            <Tab
                classes={{selected: classes.selected}}
                className={classes.tab}
                component={RouterLink}
                to={i18n.localizedUrl(actions["services"].href)}
                label={actions["services"].title}
                value={"services"}
            />
            <Tab
                classes={{selected: classes.selected}}
                className={classes.tab}
                component={RouterLink}
                to={i18n.localizedUrl(actions["groups"].href)}
                label={actions["groups"].title}
                value={"groups"}
            />
            <Tab
                classes={{selected: classes.selected}}
                className={classes.tab}
                component={RouterLink}
                to={i18n.localizedUrl(actions["wallet"].href)}
                label={actions["wallet"].title}
                value={"wallet"}
            />
            <Tab
                classes={{selected: classes.selected}}
                className={clsx(classes.tab, unreadCount > 0 ? classes.withBadge : null)}
                component={RouterLink}
                to={i18n.localizedUrl(actions["messages"].href)}
                label={
                   unreadCount > 0 ?
                        <Badge classes={{badge: classes.badge}} anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }} badgeContent={1} color={"error"}>{actions["messages"].title}</Badge>
                        :actions["messages"].title
                }
                value={"messages"}
            />
        </Tabs>
    );
}

export default withStyles(styles, {name: ""})(withChat(withI18n(withRouter(TTabs))))
