/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * The WebapiprotoUserInfo model module.
 * @module model/WebapiprotoUserInfo
 * @version v0.4
 */
export class WebapiprotoUserInfo {
  /**
   * Constructs a new <code>WebapiprotoUserInfo</code>.
   * @alias module:model/WebapiprotoUserInfo
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>WebapiprotoUserInfo</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/WebapiprotoUserInfo} obj Optional instance to populate.
   * @return {module:model/WebapiprotoUserInfo} The populated <code>WebapiprotoUserInfo</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new WebapiprotoUserInfo();
      if (data.hasOwnProperty('uuid'))
        obj.uuid = ApiClient.convertToType(data['uuid'], 'String');
      if (data.hasOwnProperty('email'))
        obj.email = ApiClient.convertToType(data['email'], 'String');
      if (data.hasOwnProperty('role'))
        obj.role = ApiClient.convertToType(data['role'], 'String');
      if (data.hasOwnProperty('avatar_uuid'))
        obj.avatarUuid = ApiClient.convertToType(data['avatar_uuid'], 'String');
      if (data.hasOwnProperty('complete'))
        obj.complete = ApiClient.convertToType(data['complete'], 'Boolean');
      if (data.hasOwnProperty('full_name'))
        obj.fullName = ApiClient.convertToType(data['full_name'], 'String');
      if (data.hasOwnProperty('impersonated'))
        obj.impersonated = ApiClient.convertToType(data['impersonated'], 'Boolean');
    }
    return obj;
  }
}

/**
 * @member {String} uuid
 */
WebapiprotoUserInfo.prototype.uuid = undefined;

/**
 * @member {String} email
 */
WebapiprotoUserInfo.prototype.email = undefined;

/**
 * @member {String} role
 */
WebapiprotoUserInfo.prototype.role = undefined;

/**
 * @member {String} avatarUuid
 */
WebapiprotoUserInfo.prototype.avatarUuid = undefined;

/**
 * @member {Boolean} complete
 */
WebapiprotoUserInfo.prototype.complete = undefined;

/**
 * @member {String} fullName
 */
WebapiprotoUserInfo.prototype.fullName = undefined;

/**
 * @member {Boolean} impersonated
 */
WebapiprotoUserInfo.prototype.impersonated = undefined;


