import React, {Component} from 'react';
import Hidden from "@material-ui/core/Hidden";
import Layout from "../../hoc/Layout/Layout";
import Title from "../../components/Title/Title";
import Subtitle from "../../components/Subtitle/Subtitle";
import ListPage from "../../partials/ListPage/ListPage";
import Navbar from "../../components/Navbar/Navbar";
import {pages} from "../../constants";
import Page from "../../components/Page/Page";
import {withPage} from "../../hoc/Page/Provider";
import {withGlobalLoader} from "../../hoc/GlobalLoader/Provider";


class Faq extends Component {
    constructor(props) {
        super(props);
        this.state = {isLoading: true}
    }

    componentDidMount() {
        this.props.loader.Hide()
    }

    onLoad() {
        this.setState({isLoading: false})
    }

    onLocaleUpdate() {
        this.setState({isLoading: true})
    }

    render() {
        const {isLoading} = this.state
        const {short, title, subtitle, ...other} = this.props;
        return (
            <Page {...other}>
                {
                    short ?
                        <>
                            <Title align={"center"} isLoading={isLoading}>{title}</Title>
                            <Subtitle align={"center"} isLoading={isLoading} semiBold>{subtitle}</Subtitle>
                        </>
                        :
                        <Hidden xsDown>
                            <Title align={"center"} isLoading={isLoading}>{title}</Title>
                            <Subtitle align={"center"} isLoading={isLoading} semiBold>{subtitle}</Subtitle>
                        </Hidden>
                }
                <ListPage
                    short={short}
                    page={"faq"}
                    onLoad={() => this.onLoad()}
                    onLocaleUpdate={() => this.onLocaleUpdate()}
                    accordion
                />
            </Page>
        )
    }
}

export default withGlobalLoader(Faq);
