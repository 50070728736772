import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Stepper from "@material-ui/core/Stepper";
import clsx from "clsx"; // We can inject some CSS into the DOM.

const styles = theme => ({
  root: {},
  noPadding: {
    padding: 0
  }
});

function TStepper({
  className,
  classes = {},
  children,
  noPadding = false,
  ...other
}) {
  const useClasses = ['stepper'];
  const {
    noPadding: noPaddingCls,
    ...otherClasses
  } = classes;

  if (className) {
    useClasses.push(className);
  }

  if (noPadding) {
    useClasses.push(noPaddingCls);
  }

  return <Stepper classes={otherClasses} className={clsx(...useClasses)} {...other} componentname="t-stepper">{children}</Stepper>;
} //TStepper.propTypes = Stepper.propTypes


export default withStyles(styles, {
  useTheme: true
})(TStepper);