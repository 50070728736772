import React from "react";
import {T} from "@ticketag/i18nlib";
import DefaultLayoutData from "../../src/data/default_layout.json";
import ChatData from "../../src/data/chat.json";
import InviteData from "../../src/data/invite.json";
import Dispute from "../../src/data/dispute.json";


export default class Data extends Object {
    static Chat = ImportData(ChatData);
    static Invite = ImportData(InviteData);
    static DefaultLayout = ImportData(DefaultLayoutData);
    static Dispute = ImportData(Dispute);
}

function ImportData(obj) {
    let newObj = {}
    for (const key in obj)
    {
        if (!obj.hasOwnProperty(key))
            continue;
        if (typeof obj[key] == "object" &&
            obj[key] !== null) {
            const [t, ...other] = Object.keys(obj[key])
            if (t === "T" && other.length === 0) {
                const [[val, defaultVal]] = Object.entries(obj[key]["T"])
                newObj[key] = React.createElement(T, {defaultVal: defaultVal}, val)
            } else {
                newObj[key] = ImportData(obj[key]);
            }
        } else {
            newObj[key] = obj[key]
        }
    }
    return newObj;
}