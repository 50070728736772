import {CssBaseline, ThemeProvider} from "@material-ui/core";
import React from "react";
import {createMuiTheme} from "@material-ui/core";

const TThemeProvider = (props) => {
    const serialTheme = JSON.parse(JSON.stringify(props.theme));
    const muTheme = createMuiTheme(serialTheme);
    return <ThemeProvider theme={muTheme}>{props.children}</ThemeProvider>;
}

export default TThemeProvider;