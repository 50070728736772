/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * The WebapiprotoGroupMember model module.
 * @module model/WebapiprotoGroupMember
 * @version v0.4
 */
export class WebapiprotoGroupMember {
  /**
   * Constructs a new <code>WebapiprotoGroupMember</code>.
   * @alias module:model/WebapiprotoGroupMember
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>WebapiprotoGroupMember</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/WebapiprotoGroupMember} obj Optional instance to populate.
   * @return {module:model/WebapiprotoGroupMember} The populated <code>WebapiprotoGroupMember</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new WebapiprotoGroupMember();
      if (data.hasOwnProperty('uuid'))
        obj.uuid = ApiClient.convertToType(data['uuid'], 'String');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('avatar_uuid'))
        obj.avatarUuid = ApiClient.convertToType(data['avatar_uuid'], 'String');
      if (data.hasOwnProperty('is_admin'))
        obj.isAdmin = ApiClient.convertToType(data['is_admin'], 'Boolean');
      if (data.hasOwnProperty('renewal_date'))
        obj.renewalDate = ApiClient.convertToType(data['renewal_date'], 'Date');
      if (data.hasOwnProperty('start_date'))
        obj.startDate = ApiClient.convertToType(data['start_date'], 'Date');
      if (data.hasOwnProperty('kicked'))
        obj.kicked = ApiClient.convertToType(data['kicked'], 'Boolean');
    }
    return obj;
  }
}

/**
 * @member {String} uuid
 */
WebapiprotoGroupMember.prototype.uuid = undefined;

/**
 * @member {String} name
 */
WebapiprotoGroupMember.prototype.name = undefined;

/**
 * @member {String} avatarUuid
 */
WebapiprotoGroupMember.prototype.avatarUuid = undefined;

/**
 * @member {Boolean} isAdmin
 */
WebapiprotoGroupMember.prototype.isAdmin = undefined;

/**
 * @member {Date} renewalDate
 */
WebapiprotoGroupMember.prototype.renewalDate = undefined;

/**
 * @member {Date} startDate
 */
WebapiprotoGroupMember.prototype.startDate = undefined;

/**
 * @member {Boolean} kicked
 */
WebapiprotoGroupMember.prototype.kicked = undefined;


