import React, {useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {capitalize, Select} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import {SelectArrowIcon} from "../Icon/Icon";
import FormControlContext from "@material-ui/core/FormControl/FormControlContext";
import TextField from "../TextField/TextField";

// We can inject some CSS into the DOM.
const styles = (theme) => {
    return {
        root: {
        },
        selectMenu: {
            position: "absolute",
            padding: "0!important",
            width: "calc(100% - 12px)",
            //todo: tema
            fontSize: "12px",
            //
            "&:focus": {
                backgroundColor: "transparent"
            }
        },
        nativeInput: {
            position: "relative",
            border: "none",
            color: "transparent!important",
            backgroundColor: "transparent",
            width: "calc(100% - 24px)!important",
            "&::placeholder": {
                fontFamily: theme.typography.fontFamily,
                fontWeight: "400",
                color: "currentColor",
                opacity: 0.42,
            }
        },
        colorContrast: {
            "& .MuiSelect-icon": {
                color: theme.palette.primary.contrastText,
                '-moz-transform': 'translateX(-16px)',
            }
        },
        icon: {
            color: "inherit",
            cursor: "pointer",
            transition: 'all .2s ease',
            transform: "rotate(0deg)"
        },
        iconOpen: {
            transform: "rotate(180deg)"
        },
        list: {
          display: "none"
        }
    }
};

function TSelect(props) {
    const {
        classes,
        getOptionLabel = (option) => option.label,
        getOptionValue = (option) => option.value,
        options = [],
        contrast,
        onChange = () => {},
        onBlur = () => {},
        defaultValue,
        renderValue,
        value,
        color,
        ...other} = props;
    const [focused, setFocused] = useState(false)
    const [currentValue, setCurrentValue] = useState(value ? value : defaultValue ? defaultValue : null)
    return (
        <TextField
            select
            color={color}
            value={currentValue}
            focused={focused}
            SelectProps={{
                MenuProps: {
                    classes:{list: classes.menu},
                },
                IconComponent: () => <SelectArrowIcon color={contrast ? "white" : "default"}/>,
                renderValue: renderValue ? renderValue : null,
                classes: {
                    selectMenu: classes.selectMenu,
                    nativeInput: classes.nativeInput,
                    icon: classes.icon,
                    iconOpen: classes.iconOpen,
                },
                onOpen: (evt) => {
                    setFocused(true)
                },
                onClose: (evt, target) => {
                    setFocused(false)
                },

        }}
            onBlur={(e) => {
                    onBlur(e, currentValue)
                    setFocused(false)
            }}
            onChange={ (e) => {
                setCurrentValue(e.target.value)
                onChange(e, e.target.value)
            }}
            classes={{
              root: color === "contrast" ? classes.colorContrast: classes.root, input: classes.selectInput
            }}
            {...other}>
            {options.map((o, i) =>
                <MenuItem key={i+1} value={getOptionValue(o)}>{getOptionLabel(o)}</MenuItem>)
            }
        </TextField>
    )
}

export default withStyles(styles, {useTheme: true})(TSelect);

