import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { T } from "@ticketag/i18nlib";
import Box from "@material-ui/core/Box";
import AvatarIcon from "@ticketag/ticketag-uilib/src/components/base/Avatar/AvatarIcon";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";

const styles = theme => {
  return {
    root: {},
    title: {
      fontSize: '16px'
    },
    subtitle: {
      fontSize: '14px'
    }
  };
};

export const AvatarListItem = ({
  avatarUuid,
  title,
  subtitle,
  classes,
  ...props
}) => {
  return <Box display="flex" alignItems="center" {...props} componentname="avatar-list-item">
        <AvatarIcon src={avatarUuid} />
        <Box ml={{
      xs: 2,
      sm: 4,
      md: 6
    }} display="flex" flexDirection="column">
            <Typography classes={{
        root: classes.title
      }} bold color="textSecondary" variant="h5">{title}</Typography>
            <Typography classes={{
        root: classes.subtitle
      }} variant="body1">{subtitle}</Typography>
        </Box>
    </Box>;
};
export default withStyles(styles, {
  useTheme: true
})(AvatarListItem);