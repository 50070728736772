/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * The WebapiprotoUserProfileUuid model module.
 * @module model/WebapiprotoUserProfileUuid
 * @version v0.4
 */
export class WebapiprotoUserProfileUuid {
  /**
   * Constructs a new <code>WebapiprotoUserProfileUuid</code>.
   * @alias module:model/WebapiprotoUserProfileUuid
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>WebapiprotoUserProfileUuid</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/WebapiprotoUserProfileUuid} obj Optional instance to populate.
   * @return {module:model/WebapiprotoUserProfileUuid} The populated <code>WebapiprotoUserProfileUuid</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new WebapiprotoUserProfileUuid();
      if (data.hasOwnProperty('uuid'))
        obj.uuid = ApiClient.convertToType(data['uuid'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} uuid
 */
WebapiprotoUserProfileUuid.prototype.uuid = undefined;


