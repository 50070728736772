import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { T } from "@ticketag/i18nlib";
import Box from "@material-ui/core/Box";
import IconButton from "@ticketag/ticketag-uilib/src/components/base/Button/IconButton";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import ResponsiveCard from "@ticketag/ticketag-uilib/src/components/complex/ResponsiveCard/ResponsiveCard";
import TextButton from "@ticketag/ticketag-uilib/src/components/base/Button/TextButton";
import BillingDataForm from "../BillingDataForm/BillingDataForm";
import IbanForm from "../IbanForm/IbanForm";
import DocumentForm, { getDocTypes } from "../DocumentForm/DocumentForm";
import { withErrorModal } from "../ErrorMessage/ErrorMessage";

const styles = theme => {
  return {
    root: {},
    addButton: {}
  };
};

const PayoutData = ({
  modals,
  i18n,
  payoutProfile,
  verticalLayout,
  onComplete,
  onContinue,
  beforeOpen,
  onDelete,
  classes,
  ...props
}) => {
  const document = payoutProfile.documents.length > 0 ? payoutProfile.documents[0] : null;

  const openEditModal = (defaultValues = payoutProfile) => {
    let formRef = null;
    const document = defaultValues.documents.length > 0 ? defaultValues.documents[0] : {
      userProfileUuid: defaultValues.uuid
    }; // onSubmit={/*(stripe, form) => onCreate(stripe, data)*/} disabled={formDisabled}

    return modals.Stepper(<T capitalize defaultVal="Dati di payout">modals.payout_data.title</T>, [<BillingDataForm i18n={i18n} defaultValues={defaultValues} />, <IbanForm defaultValues={defaultValues} />, <DocumentForm modals={modals} i18n={i18n} defaultValues={document} />], {
      size: 'lg',
      continueText: <T defaultVal="Continua">forms.buttons.continue</T>,
      submitText: <T defaultVal="Salva">forms.buttons.save</T>,
      onContinue: onContinue
    }).then(data => {
      onComplete(data);
    }).catch(({
      button,
      error
    }) => {
      if (error) {
        throw error;
      }
    });
  };

  const onEditClick = () => {
    if (beforeOpen) {
      return beforeOpen().then(defaultValues => {
        return openEditModal(defaultValues);
      });
    }

    return openEditModal(payoutProfile);
  };

  return <Box {...props} componentname="payout-data">
        {payoutProfile.firstName && payoutProfile.lastName ? <ResponsiveCard key={payoutProfile.uuid} title={`${payoutProfile.firstName} ${payoutProfile.lastName}`} horizontal={!verticalLayout} selected={true} action={<>
                                <Box px={1} mb={verticalLayout ? -2 : 0}>
                                    <IconButton width={20} height={20} color={'gradient'} onClick={() => withErrorModal(modals, onEditClick())}>edit</IconButton>
                                </Box>
                            </>}>
                <Typography variant={'subtitle1'}>IBAN: {payoutProfile.iban ? `**** **** **** ${payoutProfile.iban.substr(payoutProfile.iban.length - 4)}` : '-'}</Typography>
                <Typography variant={'subtitle1'}>{document ? <T defaultVal={document.typeName}>{`enums.doc_types.${document.type.toLowerCase()}`}</T> : null}</Typography>
            </ResponsiveCard> : <TextButton classes={{
      root: classes.addButton
    }} variant={'secondary'} onClick={() => withErrorModal(modals, onEditClick())}>
                <T defaultVal="+ Aggiungi profilo di payout">components.payout_data.add_payout_profile</T>
            </TextButton>}

        {
      /**/
    }
    </Box>;
};

export default withStyles(styles, {
  useTheme: true
})(PayoutData);