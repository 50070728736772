import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Button from './Button';
import { CloseIcon } from "../Icon/Icon"; // We can inject some CSS into the DOM.

const styles = theme => {
  return {
    root: {
      color: theme.palette.text.disabled,
      cursor: 'pointer',
      fontSize: '12px',
      fontWeight: 500,
      fontFamily: theme.typography.fontFamily,
      '&.active': {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: '5px 20px'
      }
    },
    primary: {
      '&.active': {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText
      }
    },
    secondary: {
      color: theme.palette.secondary.main,
      fontWeight: 400,
      '&.active': {
        background: theme.palette.secondary.main,
        color: theme.palette.primary.contrastText
      }
    },
    gradient: {
      //background: theme.palette.default_gradient || theme.palette.secondary.main,
      //background: '#F5F6F7',
      fontWeight: 600,
      '&.active': {
        background: theme.palette.default_gradient || theme.palette.secondary.main,
        '-webkit-text-fill-color': theme.palette.primary.contrastText + '!important',
        color: theme.palette.primary.contrastText,
        fontWeight: 500
      }
    },
    gradientText: {
      '-webkit-text-fill-color': 'transparent',
      '-webkit-background-clip': 'text',
      background: theme.palette.default_gradient || theme.palette.secondary.main,
      '&.active': {
        background: theme.palette.default_gradient || theme.palette.secondary.main,
        '-webkit-text-fill-color': theme.palette.primary.contrastText + '!important',
        color: theme.palette.primary.contrastText,
        fontWeight: 500
      }
    },
    sizeSmall: {},
    sizeLarge: {
      padding: '13px',
      lineHeight: '48px',
      fontSize: '18px',
      height: '48px',
      minWidth: '20%'
    }
  };
};

function ToggleButton(props) {
  const {
    classes,
    className,
    theme,
    ...other
  } = props;
  const useClasses = ['toggle-button', classes.root];
  const textClasses = ['toggle-button-text'];
  const variant = props.variant || 'default';

  const onClick = props.onClick || (() => {});

  let iconColor = theme.palette.primary.contrastText;

  if (!!props.value) {
    useClasses.push('active');
    textClasses.push('active');
  } //


  useClasses.push(className);

  switch (variant) {
    case 'primary':
      useClasses.push(classes.primary);
      break;

    case 'secondary':
      useClasses.push(classes.secondary);
      break;

    case 'gradient':
      useClasses.push(classes.gradient);
      textClasses.push(classes.gradientText);
      break;

    default:
  }

  switch (props.size) {
    case 'small':
      useClasses.push(classes.sizeSmall);
      break;

    case 'large':
      useClasses.push(classes.sizeLarge);
      break;

    default:
  } //<label className={clsx(...useClasses)}  {...other}></label>


  return <div className={clsx(...useClasses)} onClick={e => {
    onClick(e);
  }} {...other} componentname="toggle-button">
        <span className={clsx(...textClasses)}>{props.children}</span>
        {!!props.value ? <CloseIcon color={iconColor} size={'normal'} containerClasses={['close-icon']} /> : null}
    </div>;
}

ToggleButton.propTypes = Button.propTypes;
export default withStyles(styles, {
  withTheme: true
})(ToggleButton);
