import React, {Component} from 'react';
import '../sass/main.scss';
import {BrowserRouter} from 'react-router-dom';
import theme from "./theme";
import ThemeProvider from "@ticketag/ticketag-uilib/src/components/ThemeProvider";
import AuthProvider from "./hoc/Auth/Provider";
import {I18nProvider, localeFromUrl} from "@ticketag/i18nlib"
import {ApiClient} from "@ticketag/diveedi-client";
import ApiProvider, {withApi} from "./hoc/Api/Provider";
import ModalProvider from "@ticketag/ticketag-uilib/src/components/hoc/Modals/Provider";
import ChatProvider from "./hoc/Chat/Provider";
import FullscreenProgress from "./components/FullscreenProgress/FullscreenProgress";
import {WebSocketClient} from "@ticketag/wsrpc-client/src"
import CookieProvider from "./hoc/Cookie/Provider";
import VersionData from "../../src/data/version.json";
import GlobalLoader from "./hoc/GlobalLoader/Provider";
import {Routes} from "./hoc/Routes/Provider";
import Groups from "./pages/Groups/Groups";
import routes from "./routes";
import RoutesProvider from "./hoc/Routes/Provider";



const apiClient = new ApiClient();
apiClient.basePath = process.env.SERVER_URL === '/' ? window.location.origin : process.env.SERVER_URL
const i18nUrl = process.env.SERVER_URL === '/' ? `${window.location.host}/i18n` : `${(process.env.SERVER_URL.replace(/^https?:\/\//, '')) || `${window.location.host}/`}i18n`
const stripeKey = document.querySelector("[name~=STRIPE_KEY][content]")?.content || process.env.STRIPE_KEY //<meta name="STRIPE_KEY" content="pk_test_...." />
let i18nUrlParams = ''
if (process.env.HEADER_CONFIG && process.env.HEADER_CONFIG.Authorization) {
    apiClient.defaultHeaders = {
        Authorization: process.env.HEADER_CONFIG.Authorization
    }
    apiClient.basePath = process.env.SERVER_URL.replace(/\/$/, "")//
    i18nUrlParams = `?Authorization=${process.env.HEADER_CONFIG.Authorization}`
}

// TODO: impostare document.title su tutte le pagine
document.title = 'Diveedi'

console.log(`[v. ${VersionData.version}] Branch: ${VersionData.branch}, Commit: ${VersionData.rev}`)

const baseUrl = process.env.SERVER_URL === '/' ? window.location.host : apiClient.basePath.replace(/^https?:\/\//,'')
const wsProto = window.location.protocol === 'https:' ? 'wss' : 'ws'
const wsClient = new WebSocketClient(wsProto, `${baseUrl}/ws` +
    (process.env.HEADER_CONFIG.Authorization ? `?Authorization=${process.env.HEADER_CONFIG.Authorization}` : ''),
    {connectionTimeout:1500}
)

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: 0,
        };
        this.initialLocale = localeFromUrl(window.location.pathname) || navigator.language
    }

    render() {
        return <BrowserRouter basename="/">
            <ThemeProvider theme={theme}>
                <ApiProvider wsClient={wsClient} apiClient={apiClient} stripeKey={stripeKey}>
                    <I18nProvider wsClient={wsClient} defaultLocale={this.initialLocale} loader={<FullscreenProgress/>} skipLoading={false} baseUrl={i18nUrl} urlParams={i18nUrlParams}>
                            <ChatProvider url={`${baseUrl}/wsChat`}>
                                    <AuthProvider logoutUrl={`${process.env.SERVER_URL}auth/logout`} loginUrl={`${process.env.SERVER_URL}auth/login`} apiClient={apiClient}>
                                            <GlobalLoader>
                                                <RoutesProvider routes={routes}>
                                                    <Routes routes={routes}/>
                                                </RoutesProvider>
                                            </GlobalLoader>
                                    </AuthProvider>
                            </ChatProvider>
                    </I18nProvider>
                </ApiProvider>
            </ThemeProvider>
        </BrowserRouter>
    }
}


export default App;
