import React, { useState } from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { withStyles } from "@material-ui/core/styles";
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import clsx from "clsx";
import { capitalize } from "@material-ui/core"; // We can inject some CSS into the DOM.

const styles = theme => {
  return {
    root: {
      "& input": {
        width: "100%",
        margin: "0"
      },
      "& .MuiInput-underline::after": {
        borderColor: "currentColor"
      },
      "& .MuiInput-underline.Mui-focused::after": {
        borderColor: "currentColor"
      },
      "& label": {
        color: theme.palette.text.secondary
      },
      "& .MuiFormHelperText-root": {//color: theme.palette.text.primary
      }
    },
    error: {
      color: theme.palette.error.main,
      "& .MuiInput-underline": {
        borderColor: theme.palette.error.main
      },
      "& .MuiInput-underline::after": {
        borderColor: theme.palette.error.main
      },
      "& label": {
        color: theme.palette.error.main
      },
      "& .MuiFormHelperText-root": {
        color: theme.palette.error.main
      }
    },
    colorDefault: {
      color: theme.palette.text.primary
    },
    colorPrimary: {
      color: theme.palette.primary.main
    },
    colorSecondary: {
      color: theme.palette.secondary.main
    },
    variantStandard: {
      marginTop: "0px!important",
      marginBottom: "2px"
    },
    variantTransparent: {
      marginTop: "0px!important",
      marginBottom: "2px",
      paddingLeft: "0px!important"
    },
    variantOutlined: {
      color: "currentColor",
      borderRadius: "4px!important",
      transition: "color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
      border: "1px solid rgba(0, 0, 0, 0.23)",
      "&.Mui-disabled": {
        backgroundColor: "rgba(0, 0, 0, 0.05)"
      },
      "&.Mui-focused": {
        border: "1px solid currentColor!important"
      },
      "&.Mui-error": {
        borderColor: theme.palette.error.main + "!important"
      },
      "&.Mui-error.Mui-focused": {
        border: `1px solid ${theme.palette.text.primary}!important`
      }
    },
    shapeRounded: {
      "& .MuiInputBase-root": {
        borderRadius: "100px!important"
      },
      "& label": {
        marginLeft: "14px"
      },
      "& .MuiFormHelperText-root": {
        marginLeft: "14px"
      }
    },
    label: {
      position: "relative",
      transform: "none!important",
      textTransform: "uppercase",
      margin: "0",
      "&.Mui-focused": {
        color: theme.palette.text.secondary
      },
      "&.Mui-disabled": {
        color: theme.palette.text.disabled
      },
      // todo: dipendono dal tema
      fontSize: "14px",
      lineHeight: "30px",
      fontWeight: "500"
    },
    Input: {
      color: "currentColor",
      padding: "6px 12px"
    },
    input: {
      height: "auto",
      padding: "0!important",
      // todo: dipendono dal tema
      fontSize: "14px!important",
      lineHeight: "20px" //

    },
    helperText: {
      marginLeft: "0",
      // todo: dipendono dal tema
      fontSize: "14px",
      lineHeight: "20px",
      //
      margin: 0,
      minHeight: "20px",
      "&.Mui-disabled": {
        color: theme.palette.text.disabled
      },
      "&.Mui-focused": {
        color: theme.palette.text.primary
      }
    }
  };
};

function TBirthdayPicker({
  className,
  classes,
  color = "secondary",
  label,
  theme,
  variant = "outlined",
  required,
  error,
  helperText,
  defaultValue,
  onChange,
  onBlur,
  InputLabelProps,
  InputProps,
  FormHelperTextProps,
  ...other
}) {
  const {
    label: labelCls,
    error: errorCls,
    ...pickerClasses
  } = classes;
  const [selectedDate, handleDateChange] = useState(defaultValue);
  return <MuiPickersUtilsProvider utils={MomentUtils} componentname="t-birthday-picker">
            <KeyboardDatePicker disableFuture classes={{
      root: pickerClasses.root
    }} minDate={new Date(1900, 0, 1)} inputVariant={"outlined"} label={label} color={color} variant={variant} required={required} openTo="year" error={error} helperText={helperText || null} format="DD/MM/yyyy" value={selectedDate || null} views={["year", "month", "date"]} onChange={(date, value) => {
      handleDateChange(date, value);
      onChange(date?.toISOString(), date?.toISOString());
    }} onBlur={(date, value) => {
      onBlur(selectedDate?.toISOString(), selectedDate?.toISOString());
    }} InputLabelProps={{ ...{
        shrink: true,
        classes: {
          root: classes.label
        }
      },
      ...InputLabelProps
    }} InputProps={{ ...{
        renderSuffix: () => {},
        classes: {
          root: classes.Input,
          input: classes.input
        },
        className: clsx(classes[`variant${capitalize(variant)}`])
      },
      ...(variant === "transparent" && {
        disableUnderline: true
      }),
      ...InputProps
    }} FormHelperTextProps={{
      classes: {
        root: classes.helperText,
        error: classes.error
      },
      error: error,
      ...FormHelperTextProps
    }} {...other} />
        </MuiPickersUtilsProvider>;
}

TBirthdayPicker.propTypes = KeyboardDatePicker.propTypes;
export default withStyles(styles, {
  useTheme: true
})(TBirthdayPicker);
