/*
+-----------+-----------------------------------------+---------------------------+-------------+
|status_code|key                                      |default_text               |default_scope|
+-----------+-----------------------------------------+---------------------------+-------------+
|400        |errors.user.passwords_not_match          |Passwords not match        |user         |
|400        |errors.group.user_limit_exceeded         |Group user limit exceeded  |user         |
|400        |errors.group.same_user                   |Join your group error      |user         |
|400        |errors.membership.incomplete             |membership incomplete      |internal     |
|400        |errors.group.scope_not_allowed           |scope not allowed          |internal     |
|400        |errors.creds_manager.invalid_credentials |schema not found           |internal     |

|401        |errors.user.unauthenticated              |Unauthorized               |user         |
|401        |errors.user.disabled                     |Disabled                   |user         |

|403        |errors.user.unauthorized                 |user unauthorized          |user         |
|403        |errors.creds_manager.user_unauthorized   |user unauthorized          |internal     |

|404        |errors.db.not_found                      |Si é verificato un errore  |internal     |
|404        |errors.creds_manager.schema_not_found    |schema not found           |internal     |

|418        |errors.system.cannot_make_coffee         |i am a teapot              |internal     |

|500        |errors.db.generic                        |Db generic error           |internal     |
|500        |errors.user.profile_undeletable          |UserProfile not deletable  |user         |
|500        |errors.stripe.customer                   |Stripe Customer error      |internal     |
|500        |errors.stripe.setup_intent               |Stripe SetupIntentError    |internal     |
|500        |errors.user.locked                       |User Locked                |user         |
|500        |errors.language.parse                    |Language parse error       |internal     |
|500        |errors.phone_number.parse                |Phone number parse error   |internal     |
|500        |errors.locale.unavailable                |Locale Unavailable         |user         |
|500        |errors.db.save                           |Db save error              |internal     |
|500        |errors.db.query                          |Db query error             |internal     |
|500        |errors.group.locale_not_allowed          |Group locale notret allowed|user         |
|500        |errors.price.calc                        |Price calculate error      |internal     |
|500        |errors.group.generic_credentials         |generic credentials error  |internal     |
|500        |errors.state.generic                     |genereic state error       |internal     |
|500        |errors.stripe.product                    |stripe product error       |internal     |
|500        |errors.stripe.subscription               |stripe subscription error  |internal     |
|500        |errors.creds_manager.etcd_user_not_found |user not found             |internal     |
|500        |errors.creds_manager.etcd_group_not_found|user not found             |internal     |
|500        |errors.creds_manager.vault_token_creation|user not found             |internal     |
|500        |errors.creds_manager.vault_client        |user not found             |internal     |
|500        |errors.creds_manager.etcd_client         |etcd client generic error  |internal     |
+-----------+-----------------------------------------+---------------------------+-------------+

*/
export const defaultErrorTitle = 'errors.generic.generic'
export const defaultErrorMessage = 'errors.generic.generic'
export const defaultMessages = {
    400: {title: 'errors.generic.bad_request', message: 'errors.generic.bad_request_message'},
    401: {title: 'errors.user.unauthenticated', message: 'errors.user.unauthenticated_message'},
    403: {title: 'errors.user.unauthorized', message: 'errors.user.unauthorized_message'},
    404: {title: 'errors.generic.not_found', message: 'errors.generic.not_found_message'},
    418: {title: '', message: ''},
    500: {title: '', message: ''},
}
export const getDefaultMessage = (code) => {
    let defaultMsg = null
    if (code) {
        defaultMsg = defaultMessages[code] ? {...defaultMessages[code]} : null
    }

    return defaultMsg || {title: defaultErrorTitle, message: defaultErrorMessage}
}
