import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T} from "@ticketag/i18nlib";
//import JsonSchemaForm from "@rjsf/material-ui";
import {Theme as MaterialUITheme} from '@rjsf/material-ui';
import {withTheme} from '@rjsf/core';
import TextField from "@ticketag/ticketag-uilib/src/components/base/TextField/TextField";
import PasswordTextField from "@ticketag/ticketag-uilib/src/components/base/TextField/PasswordTextField";
import Box from "@material-ui/core/Box";
import TextButton from "@ticketag/ticketag-uilib/src/components/base/Button/TextButton";
import {withModals} from "@ticketag/ticketag-uilib/src/components/hoc/Modals/Provider";
import {withApi} from "../../hoc/Api/Provider";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import Grid from "@material-ui/core/Grid";
import {CopyIcon} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";
import IconButton from "@ticketag/ticketag-uilib/src/components/base/Button/IconButton";
import Button from "@ticketag/ticketag-uilib/src/components/base/Button/Button";
import SchemaField from "@rjsf/core/lib/components/fields/SchemaField";
import ApiContext from "../../hoc/Api/context";
import {Tooltip} from "@material-ui/core";
import ComboBox from "@ticketag/ticketag-uilib/src/components/base/Select/ComboBox";


const styles = (theme) => {
    return {
        root: {
            "& .MuiFormLabel-root.Mui-focused .MuiInputLabel-asterisk": {
                color: theme.palette.text.secondary
            }
        },
        modalTitle: {
            marginBottom: theme.spacing(-2),
            fontSize: "20px",
            lineHeight: "24px",
            fontWeight: "600"
        },
        modalText: {
            textAlign: "justify",
            marginBottom: theme.spacing(4),
            fontSize: "14px"
        },
        securePwdInput: {
            paddingBottom: "16px",
            "& input": {
                fontSize: "32px!important",
                fontWeight: "600",
                paddingBottom: "16px",
            },
            "&:focus": {
                 outline: "none"
            }
        },
        securePwdLabel: {
            position: "absolute",
            paddingTop: "4px",
            zIndex: 4,
        },
        reloadButton: {
            marginLeft: "-20px",
            marginRight: "4px"
        }
    }
};

const uiSchema = {
    "email": {
        "ui:autofocus": true,
        "ui:widget": "email"
    },
    "password": {
        "ui:widget": "password"
    }
}

const BaseWidget = (props) => {
    const {
        Component = TextField,
        id, value, defaultValue, uiSchema, readonly, rawErrors, autofocus, formData, formContext, onBlur, onFocus, onChange, placeholder, schema, options, label, size, ...other} = props;//
    const [dirty, setDirty] = React.useState(false)
    const _onChange = (e, val) => {
        setDirty(true)
        onChange(val);
    };
    const _onBlur = (e, val) => {
        onBlur(e, val);
    };
    const _onFocus = (e,val) => {
        onFocus(e, val);
    }
    const error = dirty && rawErrors && rawErrors.length > 0 ? rawErrors[0] : null
    const placeholderText = schema?.examples && schema.examples.length > 0 ? schema.examples[0] : ""
    return <Component
        size={"big"}
        label={label}
        id={id}
        error={!!error}
        onFocus={_onFocus}
        onChange={_onChange}
        onBlur={_onBlur}
        placeholder={placeholderText}
        variant={"shadowed"} shape={"rounded"}
        disabled={readonly}
        helperText={error ? <T>{error}</T> : ""}
        value={value}
        {...other}
    />
}


const SecurePwdSvg = ({width = 145, height = 136}) =>
    <svg width={width} height={height} viewBox="0 0 145 136" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M68.907 7.14919C61.4863 7.14919 55.4706 13.0687 55.4706 20.3707V64.6805C55.4706 66.654 53.8448 68.2539 51.8392 68.2539C49.8336 68.2539 48.2078 66.654 48.2078 64.6805V20.3707C48.2078 9.12161 57.4751 0.00244141 68.907 0.00244141C80.3388 0.00244141 89.6062 9.12161 89.6062 20.3707V22.1628C89.6062 24.1363 87.9803 25.7362 85.9747 25.7362C83.9692 25.7362 82.3433 24.1363 82.3433 22.1628V20.3707C82.3433 13.0686 76.3277 7.14919 68.907 7.14919Z" fill="url(#paint0_linear)"/>
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="48" y="0" width="42" height="69">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M68.8797 7.12525C61.5997 7.12525 55.698 13.0269 55.698 20.307V64.4835C55.698 66.4511 54.103 68.0462 52.1354 68.0462C50.1678 68.0462 48.5728 66.4511 48.5728 64.4835V20.307C48.5728 9.09174 57.6645 0 68.8797 0C80.0949 0 89.1867 9.09174 89.1867 20.307V22.0937C89.1867 24.0613 87.5916 25.6563 85.6241 25.6563C83.6565 25.6563 82.0614 24.0613 82.0614 22.0937V20.307C82.0614 13.0269 76.1598 7.12525 68.8797 7.12525Z" fill="#4D94FF"/>
        </mask>
        <g mask="url(#mask0)">
            <path d="M56.4166 13.1818C56.4166 6.34644 63.4447 0.726236 72.4434 0.0650926C73.4246 -0.00699377 74.2235 -0.797467 74.2235 -1.78126V-5.34389C74.2235 -6.32768 73.4238 -7.12968 72.4411 -7.08302C58.1214 -6.40321 46.7913 2.40981 46.7913 13.1818V57.3583C46.7913 59.3259 48.946 60.921 51.6039 60.921C54.2619 60.921 56.4166 59.3259 56.4166 57.3583V13.1818Z" fill="url(#paint1_linear)"/>
        </g>
        <path d="M105.27 77.2049C105.27 96.9789 89.2404 113.009 69.4664 113.009C49.6924 113.009 33.6624 96.9789 33.6624 77.2049C33.6624 57.4309 49.6924 41.4009 69.4664 41.4009C89.2404 41.4009 105.27 57.4309 105.27 77.2049Z" fill="url(#paint2_linear)"/>
        <mask id="mask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="33" y="40" width="72" height="73">
            <path d="M104.863 76.7749C104.863 96.5491 88.8325 112.579 69.0583 112.579C49.2841 112.579 33.2539 96.5491 33.2539 76.7749C33.2539 57.0006 49.2841 40.9705 69.0583 40.9705C88.8325 40.9705 104.863 57.0006 104.863 76.7749Z" fill="url(#paint3_linear)"/>
        </mask>
        <g mask="url(#mask1)">
            <path d="M106.287 75.7058C106.287 95.48 90.2573 111.51 70.4831 111.51C50.7089 111.51 34.6787 95.48 34.6787 75.7058C34.6787 55.9315 50.7089 39.9014 70.4831 39.9014C90.2573 39.9014 106.287 55.9315 106.287 75.7058Z" fill="#4383FF"/>
            <path d="M108.781 74.6369C108.781 94.4111 92.7512 110.441 72.977 110.441C53.2028 110.441 37.1726 94.4111 37.1726 74.6369C37.1726 54.8627 53.2028 38.8325 72.977 38.8325C92.7512 38.8325 108.781 54.8627 108.781 74.6369Z" fill="url(#paint4_linear)"/>
        </g>
        <path d="M75.2845 77.4566C75.2845 76.7909 75.6541 76.1905 76.1972 75.819C78.8861 73.9794 80.6552 70.8553 80.6552 67.3107C80.6552 61.6523 76.1467 57.0652 70.5853 57.0652C65.0238 57.0652 60.5154 61.6523 60.5154 67.3107C60.5154 70.69 62.1234 73.6872 64.6034 75.5534C65.1036 75.9299 65.4384 76.5064 65.4384 77.1392V94.4295C65.4384 95.4216 66.2294 96.2259 67.2051 96.2259H73.5179C74.4936 96.2259 75.2845 95.4216 75.2845 94.4295V77.4566Z" fill="url(#paint5_linear)"/>
        <g filter="url(#filter0_b)">
            <path d="M82.8931 78.8034C82.8931 74.8309 86.1134 71.6106 90.0858 71.6106H126.05C130.022 71.6106 133.242 74.8309 133.242 78.8034V114.767C133.242 118.74 130.022 121.96 126.05 121.96H90.0858C86.1134 121.96 82.8931 118.74 82.8931 114.767V78.8034Z" fill="#B9CDFF" fill-opacity="0.5"/>
        </g>
        <path d="M107.425 79.8474C107.688 79.3077 108.448 79.3077 108.711 79.8474L113.169 88.9968C113.273 89.2111 113.475 89.3597 113.708 89.3941L123.677 90.8612C124.265 90.9478 124.5 91.6798 124.074 92.0999L116.861 99.2217C116.692 99.3885 116.615 99.6289 116.655 99.8645L118.358 109.921C118.458 110.514 117.843 110.966 117.317 110.686L108.401 105.938C108.192 105.827 107.943 105.827 107.734 105.938L98.8181 110.686C98.2921 110.966 97.6774 110.514 97.7778 109.921L99.4806 99.8645C99.5205 99.6289 99.4434 99.3885 99.2744 99.2217L92.0613 92.0999C91.6358 91.6798 91.8706 90.9478 92.4586 90.8612L102.427 89.3941C102.66 89.3597 102.862 89.2111 102.967 88.9968L107.425 79.8474Z" fill="#4D7FFE"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M109.269 23.737C109.482 23.4194 110.01 23.4194 110.223 23.737L111.013 24.9155C111.039 24.9542 111.083 24.9817 111.133 24.9912L112.667 25.2799C113.081 25.3577 113.244 25.794 112.962 26.0681L111.916 27.0851C111.882 27.1186 111.865 27.1631 111.87 27.2077L112.028 28.5646C112.071 28.9302 111.644 29.1999 111.257 29.0517L109.82 28.5018C109.773 28.4837 109.719 28.4837 109.672 28.5018L108.235 29.0517C107.848 29.1999 107.421 28.9302 107.464 28.5646L107.622 27.2077C107.627 27.1631 107.61 27.1186 107.576 27.0851L106.53 26.0681C106.248 25.794 106.412 25.3577 106.825 25.2799L108.359 24.9912C108.41 24.9817 108.453 24.9542 108.479 24.9155L109.269 23.737ZM109.905 23.8979C109.834 23.792 109.658 23.792 109.587 23.8979L108.797 25.0764C108.719 25.1927 108.588 25.2752 108.437 25.3037L106.903 25.5923C106.765 25.6183 106.711 25.7637 106.805 25.8551L107.85 26.8721C107.954 26.9725 108.004 27.106 107.988 27.2399L107.83 28.5968C107.816 28.7187 107.958 28.8086 108.087 28.7592L109.523 28.2092C109.665 28.1549 109.827 28.1549 109.969 28.2092L111.405 28.7592C111.534 28.8086 111.676 28.7187 111.662 28.5968L111.504 27.2399C111.488 27.106 111.538 26.9725 111.642 26.8721L112.687 25.8551C112.781 25.7637 112.727 25.6183 112.589 25.5923L111.055 25.3037C110.904 25.2752 110.773 25.1927 110.695 25.0764L109.905 23.8979Z" fill="#6F6CFF"/>
        <path opacity="0.73011" fill-rule="evenodd" clip-rule="evenodd" d="M18.3385 108.494C18.7364 108.591 18.8814 109.082 18.6005 109.38L16.948 111.133C16.9137 111.17 16.8962 111.219 16.9 111.269L17.0789 113.671C17.1094 114.08 16.6879 114.369 16.3176 114.194L14.1393 113.164C14.0941 113.143 14.042 113.142 13.9957 113.161L11.7658 114.073C11.3867 114.229 10.9812 113.917 11.0333 113.511L11.3396 111.121C11.3459 111.071 11.3311 111.021 11.2988 110.983L9.74166 109.145C9.47697 108.832 9.64782 108.35 10.0503 108.274L12.4179 107.827C12.467 107.818 12.51 107.788 12.5363 107.745L13.8038 105.696C14.0193 105.348 14.5304 105.362 14.7271 105.721L15.884 107.834C15.9081 107.878 15.9494 107.91 15.998 107.922L18.3385 108.494ZM18.3412 109.135C18.4349 109.036 18.3865 108.873 18.2539 108.84L15.9134 108.268C15.7676 108.232 15.6436 108.137 15.5715 108.005L14.4146 105.892C14.349 105.772 14.1786 105.768 14.1068 105.884L12.8393 107.933C12.7604 108.06 12.6314 108.149 12.484 108.177L10.1164 108.624C9.98225 108.649 9.9253 108.81 10.0135 108.914L11.5707 110.753C11.6676 110.867 11.712 111.017 11.6929 111.166L11.3867 113.556C11.3693 113.692 11.5045 113.795 11.6308 113.744L13.8607 112.831C13.9996 112.774 14.1559 112.778 14.2916 112.842L16.4699 113.872C16.5933 113.931 16.7338 113.834 16.7237 113.698L16.5447 111.295C16.5335 111.145 16.5858 110.998 16.6887 110.889L18.3412 109.135Z" fill="#6F6CFF"/>
        <path opacity="0.902331" d="M142.526 65.6259C142.706 65.8311 142.598 66.1543 142.33 66.2097L139.05 66.8899C138.952 66.9102 138.867 66.9707 138.816 67.0567L137.104 69.9368C136.965 70.1715 136.624 70.1685 136.488 69.9314L134.828 67.0215C134.778 66.9346 134.694 66.8726 134.597 66.8506L131.329 66.1126C131.062 66.0525 130.96 65.7274 131.144 65.5254L133.398 63.0468C133.465 62.9728 133.498 62.8739 133.489 62.7743L133.181 59.4381C133.156 59.1662 133.433 58.9683 133.682 59.0806L136.736 60.4587C136.827 60.4998 136.932 60.5008 137.024 60.4612L140.101 59.1373C140.352 59.0294 140.626 59.2322 140.596 59.5036L140.229 62.8338C140.218 62.9333 140.25 63.0327 140.316 63.1079L142.526 65.6259Z" fill="#6F6CFF"/>
        <path opacity="0.370759" d="M3.42012 35.372C3.50242 35.1117 3.83496 35.0369 4.02082 35.2369L5.37795 36.6976C5.44603 36.7709 5.54178 36.8121 5.6418 36.8113L7.63557 36.7954C7.90861 36.7932 8.08251 37.0864 7.9497 37.3249L6.97989 39.067C6.93124 39.1544 6.92157 39.2582 6.95324 39.3531L7.58452 41.2444C7.67097 41.5034 7.4459 41.7593 7.17797 41.7068L5.22146 41.3227C5.12331 41.3035 5.02159 41.3264 4.94114 41.3858L3.33752 42.5706C3.11791 42.7329 2.80491 42.5979 2.77213 42.3269L2.53275 40.3474C2.52074 40.2481 2.46754 40.1585 2.38616 40.1003L0.76378 38.9413C0.541605 38.7826 0.573227 38.4432 0.820899 38.3283L2.62946 37.4889C2.72019 37.4468 2.78904 37.3685 2.81919 37.2731L3.42012 35.372Z" fill="#6F6CFF"/>
        <path opacity="0.806242" d="M24.2988 72.8618C24.1614 73.0977 23.8206 73.0977 23.6831 72.8618L21.9968 69.9667C21.9464 69.8803 21.8621 69.819 21.7643 69.7979L18.4898 69.0889C18.2229 69.0311 18.1176 68.7069 18.2995 68.5033L20.5318 66.0049C20.5984 65.9303 20.6307 65.8311 20.6206 65.7316L20.283 62.3983C20.2555 62.1266 20.5312 61.9263 20.7811 62.0364L23.847 63.3873C23.9386 63.4276 24.0428 63.4276 24.1344 63.3873L27.2002 62.0362C27.4501 61.9261 27.7259 62.1264 27.6984 62.3981L27.361 65.7314C27.3509 65.8309 27.3831 65.9301 27.4498 66.0047L29.6822 68.503C29.8641 68.7066 29.7588 69.0307 29.4919 69.0885L26.2174 69.7977C26.1197 69.8189 26.0353 69.8802 25.985 69.9666L24.2988 72.8618Z" fill="#6F6CFF"/>
        <path opacity="0.392959" d="M89.828 135.339C89.7098 135.585 89.37 135.612 89.2142 135.388L87.3029 132.636C87.2459 132.554 87.1569 132.5 87.0578 132.486L83.7372 132.04C83.4666 132.004 83.3358 131.689 83.501 131.472L85.5274 128.804C85.5879 128.724 85.6122 128.623 85.5942 128.524L84.9925 125.228C84.9435 124.96 85.2024 124.738 85.4602 124.828L88.6239 125.931C88.7184 125.964 88.8223 125.955 88.9104 125.908L91.859 124.317C92.0993 124.187 92.3902 124.365 92.3844 124.638L92.3132 127.988C92.3111 128.088 92.3511 128.184 92.4235 128.253L94.8475 130.566C95.0451 130.754 94.9659 131.086 94.7045 131.165L91.4968 132.132C91.401 132.161 91.3218 132.229 91.2785 132.319L89.828 135.339Z" fill="#6F6CFF"/>
        <defs>
            <filter id="filter0_b" x="77.8931" y="66.6106" width="60.3494" height="60.3494" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feGaussianBlur in="BackgroundImage" stdDeviation="2.5"/>
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape"/>
            </filter>
            <linearGradient id="paint0_linear" x1="83.7959" y1="35.3788" x2="44.288" y2="25.4335" gradientUnits="userSpaceOnUse">
                <stop stop-color="#93B0FF"/>
                <stop offset="1" stop-color="#5694FF"/>
            </linearGradient>
            <linearGradient id="paint1_linear" x1="49.6789" y1="49.6987" x2="56.832" y2="7.94712" gradientUnits="userSpaceOnUse">
                <stop stop-color="#70B5FF"/>
                <stop offset="1" stop-color="#ADCCFF"/>
            </linearGradient>
            <linearGradient id="paint2_linear" x1="69.4664" y1="41.4009" x2="75.1665" y2="117.818" gradientUnits="userSpaceOnUse">
                <stop stop-color="#759CFF"/>
                <stop offset="1" stop-color="#70BAFF"/>
            </linearGradient>
            <linearGradient id="paint3_linear" x1="69.0583" y1="40.9705" x2="69.0583" y2="112.579" gradientUnits="userSpaceOnUse">
                <stop stop-color="#AAB8FF"/>
                <stop offset="1" stop-color="#61A0FF"/>
            </linearGradient>
            <linearGradient id="paint4_linear" x1="72.977" y1="38.8325" x2="72.977" y2="110.441" gradientUnits="userSpaceOnUse">
                <stop stop-color="#AAB8FF"/>
                <stop offset="1" stop-color="#61A0FF"/>
            </linearGradient>
            <linearGradient id="paint5_linear" x1="60.5154" y1="79.833" x2="77.6486" y2="88.6959" gradientUnits="userSpaceOnUse">
                <stop stop-color="#0048FF"/>
                <stop offset="1" stop-color="#5081FD"/>
            </linearGradient>
        </defs>
    </svg>




const PasswordWidget = (props) => {
    const {api, modals, group, serviceFamily, confirm, classes, value, onChange, ...other} = props;
    const [generatedPwd, setGeneratedPwd] = React.useState(value)
    const openPwdModal = () => {
        const inputRef = React.createRef()
        const CopyToClipboard = () => {
            const [tooltipOpen, setTooltipOpen] = React.useState(false)
            return <Tooltip title={<T defaultVal={"Copiato!"}>global.clipboard.copied</T>} open={tooltipOpen} enterDelay={500} leaveDelay={200}  placement={"below"}>
                        <span onClick={(e) => {
                            setTooltipOpen(true)
                            setTimeout(() => {setTooltipOpen(false)}, 2000)
                            inputRef.current.select()
                            document.execCommand("copy");
                            inputRef.current.blur()
                        }}><IconButton color={"secondary"}>copy</IconButton></span>
            </Tooltip>
        }
        const GeneratePwd = () => {
            return <IconButton color={"secondary"} className={classes.reloadButton} onClick={() => {
                api.GeneratePassword(group.serviceDetails.serviceTypeUuid).
                then((pwd) => {
                    inputRef.current.value = pwd
                    inputRef.current.focus()
                })
            }} aria-label="copy" edge="end">reload</IconButton>
        }

        return api.GeneratePassword(group.serviceDetails.serviceTypeUuid).then((pwd) => {
            modals.YesNo(
                <div>
                    <SecurePwdSvg/>
                    <Typography color={"textSecondary"} className={classes.modalTitle} align={"center"}><T
                        defaultVal={"Password sicura"}>modals.securepwd.title</T></Typography>
                </div>,
                <>
                    <Typography variant={"body2"} color={"textPrimary"} className={classes.modalText} align={"center"}>
                        <T>{`modals.securepwd.${serviceFamily.slug}_text`}</T>
                    </Typography>
                    <Grid container justify={"center"}>
                        <Grid  item xs={12} md={8}>
                            <Box  mb={3}>
                                <TextField defaultValue={pwd} inputRef={inputRef} showCopy={true} id={"securePwd"} InputProps={{
                                    classes: {root: classes.securePwdInput},
                                    startAdornment: <GeneratePwd/>,
                                    endAdornment: <CopyToClipboard/>
                                }}/>
                            </Box>
                        </Grid>
                    </Grid>
                </>
                , {
                    size: 'md', fullScreen: true,
                    colorYes: "gradient", variantYes: "outlined", variantNo: "outlined",
                    cancelText: "Annulla", confirmText: "Salva password",
                    beforeSubmit: () => {
                        setGeneratedPwd(inputRef.current.value)
                        onChange(inputRef.current.value)
                    }
                })
        })
    }
    return (
        <Box display={"flex"} flexDirection={"column"} alignItems={"flex-end"} position={"relative"}>
            {
                confirm ?
                    null :
                    <TextButton color={"gradient"} className={classes.securePwdLabel} onClick={openPwdModal}>
                        <T defaultVal={"Suggerisci pwd sicura"}>pages.complete_group.generate_secure_password</T>
                    </TextButton>
            }
            <BaseWidget Component={PasswordTextField}  value={generatedPwd} onChange={(val) =>  {
                setGeneratedPwd(val)
                onChange(val)
            }} {...other}/>
        </Box>
    )

}

const GroupContext = React.createContext();

const withGroup = (Component) => {
    return ({ref, ...props}) => (
        <GroupContext.Consumer>
            {(value) => {return <Component ref={ref} group={value.group}  serviceFamily={value.serviceFamily} confirm={value.confirm} {...props}/>}}
        </GroupContext.Consumer>
    )
}

const theme = {
    ...MaterialUITheme,
    FieldTemplate: ({children: children}) => children,
    showErrorList: false,
    widgets: {
        BaseInput:  withStyles(styles, {})(withGroup(BaseWidget)),
        PasswordWidget: withStyles(styles, {})(withModals(withApi(withGroup(PasswordWidget)))),
    },
}

const ThemedForm = withTheme(theme)

const transformErrors = (serviceFamily) => (errors) => {
    return errors.map(error => {
        error.message = `components.js_form_${serviceFamily.slug}${error.property}_${error.name}_error`;
        return error
    });
}

export const JSForm = ({noHtml5Validate = true, liveValidate = true, confirm, children, classes, serviceFamily, group, onFormChange, onChange, schema, size, previousValues, ...props}) => {
    const formRef = React.useRef()
    const [formData, setFormData] = React.useState({})
    const [isValid, setIsValid] = React.useState(false)
    schema.title = ""
    return <Box className={classes.root}>
        <GroupContext.Provider value={{group: group, serviceFamily: serviceFamily, confirm: confirm}}>
            <Box display={"flex"} px={2}>
                <ThemedForm
                    liveValidate={liveValidate}
                    schema={schema}
                    ref={formRef}
                    size={size}
                    onChange={(data) => {
                        if (formRef.current) {
                            const {_, errors} = formRef.current.validate(data.formData, schema);
                            setFormData(data.formData)
                            setIsValid(errors.length === 0)
                            onFormChange(data.formData, errors.length === 0)
                        }
                    }}
                    uiSchema={uiSchema}
                    formData={formData}
                    transformErrors={transformErrors(serviceFamily)}
                    children={true}
                    {...props}>
                </ThemedForm>
            </Box>
        </GroupContext.Provider>
    </Box>
}


export default withStyles(styles, {useTheme: true})(JSForm);
