import { withStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import clsx from "clsx";
import capitalize from "@material-ui/core/utils/capitalize";
import Grid from "@material-ui/core/Grid";
import Typography from "../Typography/Typography";
import Link from "../Link/Link";
import Box from "@material-ui/core/Box";
import { AmazonIcon, FacebookIcon, Icon, InstagramIcon, PinterestIcon, TrashCanIcon, TwitterIcon, YoutubeIcon } from "../Icon/Icon";
import { SvgIcon } from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Divider from "@material-ui/core/Divider";
import Flag from "../Icon/Flag/Flag";
import Select from "../Select/ComboBox";
import Container from "../Container/Container";

const background = (theme, size) => {
  /*let svg = `<svg style="fill:url(#paint0_linear)" width="375" height="793" viewBox="0 0 375 793" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.05176e-05 792.837H375C375 792.837 375 184.795 375 13.9993C258.858 -2.64567 122.601 -4.39656 0 8.47347C0 156.536 3.05176e-05 792.837 3.05176e-05 792.837Z" /><defs><linearGradient id="paint0_linear" x1="-80.5" y1="245.5" x2="404.24" y2="147.811" gradientUnits="userSpaceOnUse"><stop stop-color="#B950DF"/><stop offset="1"  stop-color="#49A4A2"/></linearGradient></defs></svg>`
  switch (size) {
      case 'md':
          svg = `<svg style="fill:url(#paint0_linear)" width="769" height="501" viewBox="0 0 769 501" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 46.8402C0 139.271 0.000486052 447.54 0.000487198 501H769C769 501 769 107.795 769 48.5018C767.688 47.9249 766.353 47.3709 764.995 46.8401C603.986 -16.1118 172.758 -15.113 0 46.8402Z" fill="url(#paint0_linear)" fill-opacity="0.75"/><defs><linearGradient id="paint0_linear" x1="755.268" y1="309.441" x2="6.81677" y2="306.241" gradientUnits="userSpaceOnUse"><stop stop-color="#00ABA3"/><stop offset="1" stop-color="#B950DF"/></linearGradient></defs></svg>`
          break;
      case 'lg':
          svg = `<svg style="fill:url(#paint0_linear)" height="501" width="1440" viewBox="0 0 1440 501" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" ><path d="M 0,46.8754 C 0,139.376 9.10163e-4,447.876 9.12309e-4,501.376 H 1440 c 0,0 0,-393.5 0,-452.8377 -2.46,-0.5774 -4.96,-1.1319 -7.5,-1.663 C 1131,-16.1239 323.5,-15.1243 0,46.8754 Z"/><defs><linearGradient gradientUnits="userSpaceOnUse" y2="298.463" x2="12.8289" y1="309.673" x1="1414.29" id="paint0_linear"><stop stop-color="#00ABA3"/><stop stop-color="#B950DF" offset="1" /></linearGradient></defs></svg>`
          break
  }*/
  let svg = `<svg style="fill:url(#paint0_linear)" width="375" height="793" viewBox="0 0 375 793" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.05176e-05 792.837H375C375 792.837 375 184.795 375 13.9993C258.858 -2.64567 122.601 -4.39656 0 8.47347C0 156.536 3.05176e-05 792.837 3.05176e-05 792.837Z" />
    <defs>
    <linearGradient id="paint0_linear" x1="-80.5" y1="245.5" x2="404.24" y2="147.811" gradientUnits="userSpaceOnUse">
    <stop stop-color="${theme.footer.gradient_start_xs}"/><stop offset="1"  stop-color="${theme.footer.gradient_end_xs}"/></linearGradient>
    </defs></svg>`;

  switch (size) {
    case 'md':
      svg = `<svg style="fill:url(#paint1_linear)" width="769" height="501" viewBox="0 0 769 501" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 46.8402C0 139.271 0.000486052 447.54 0.000487198 501H769C769 501 769 107.795 769 48.5018C767.688 47.9249 766.353 47.3709 764.995 46.8401C603.986 -16.1118 172.758 -15.113 0 46.8402Z" fill="url(#paint0_linear)" fill-opacity="0.75"/><defs><linearGradient id="paint1_linear" x1="755.268" y1="309.441" x2="6.81677" y2="306.241" gradientUnits="userSpaceOnUse"><stop stop-color="${theme.footer.gradient_start_md}"/><stop offset="1" stop-color="${theme.footer.gradient_end_md}"/></linearGradient></defs></svg>`;
      break;

    case 'lg':
      svg = `<svg style="fill:url(#paint2_linear)" height="501" width="1440" viewBox="0 0 1440 501" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" ><path d="M 0,46.8754 C 0,139.376 9.10163e-4,447.876 9.12309e-4,501.376 H 1440 c 0,0 0,-393.5 0,-452.8377 -2.46,-0.5774 -4.96,-1.1319 -7.5,-1.663 C 1131,-16.1239 323.5,-15.1243 0,46.8754 Z"/><defs><linearGradient gradientUnits="userSpaceOnUse" y2="298.463" x2="12.8289" y1="309.673" x1="1414.29" id="paint2_linear"><stop stop-color="${theme.footer.gradient_end_lg}"/><stop stop-color="${theme.footer.gradient_start_lg}" offset="1" /></linearGradient></defs></svg>`;
      break;
  }

  return `url("data:image/svg+xml;utf8,${encodeURIComponent(svg)}") center 0 no-repeat`;
};

const styles = theme => {
  return {
    root: {
      color: "white",
      width: "100%",
      background: background(theme),
      backgroundSize: "cover",
      [theme.breakpoints.up('sm')]: {
        background: background(theme, 'sm'),
        backgroundSize: "cover"
      },
      [theme.breakpoints.up('lg')]: {
        background: background(theme, 'lg'),
        backgroundSize: "cover"
      },
      bottom: "0",
      left: "0"
    },
    fixed: {
      position: "fixed",
      left: 0,
      bottom: 0
    },
    container: {
      paddingTop: "52px",
      paddingBottom: "28px",
      [theme.breakpoints.up('md')]: {
        paddingBottom: "80px",
        paddingTop: "80px"
      }
    },
    colorPrimary: {
      backgroundSize: "100%",
      background: background(theme)
    },
    colorSecondary: {
      backgroundSize: "100%",
      background: background(theme)
    },
    logo: {
      maxWidth: "50px"
    },
    sectionTitle: {
      fontWeight: "600",
      fontSize: "18px",
      lineHeight: "22px",
      color: theme.palette.primary.contrastText,
      marginBottom: "15px",
      marginTop: "45px",
      [theme.breakpoints.up('md')]: {
        marginTop: 0
      }
    },
    footerBottom: {
      textAlign: "center",
      [theme.breakpoints.up('md')]: {
        textAlign: "left"
      }
    },
    socialLinks: {
      //marginTop: "20px",
      justifyContent: "center",
      "& a": {
        margin: "0 12px"
      },
      [theme.breakpoints.up('md')]: {
        justifyContent: "flex-end"
      }
    },
    link: {
      lineHeight: '23px',
      fontSize: "13px",
      fontWeight: "500",
      textTransform: "none"
    },
    credits: {
      fontWeight: 600,
      fontSize: '10px',
      letterSpacing: '.05em',
      lineHeight: '17px',
      textTransform: 'uppercase',
      color: theme.palette.primary.contrastText,
      marginBottom: '12px'
    },
    divider: {
      marginTop: "32px",
      marginBottom: "16px",
      backgroundColor: 'rgba(255, 255, 255, 0.12)',
      [theme.breakpoints.up('md')]: {
        marginTop: "58px",
        marginBottom: "16px"
      },
      [theme.breakpoints.up('lg')]: {
        marginTop: "40px",
        marginBottom: "16px"
      }
    },
    i18nRoot: {
      maxWidth: "120px"
    },
    i18nEntry: {
      cursor: "pointer",
      display: "inline-flex",
      alignItems: "center",
      verticalAlign: "middle",
      fontWeight: "500",
      maxHeight: "24px"
    },
    i18nSelect: {
      color: "white!important",
      fontWeight: "600"
    },
    i18nLabel: {},
    i18nIcon: {
      width: "18px",
      height: "12px",
      margin: "0 10px"
    }
  };
};

const locales = [{
  uuid: "it-IT",
  languageLabel: "Italiano",
  countryCode: "IT"
}, {
  uuid: "en-GB",
  languageLabel: "Inglese",
  countryCode: "GB"
}];

const I18n = props => {
  const {
    i18n,
    onChange,
    classes
  } = props;
  return <Select className={classes.root} defaultValue={i18n.selectedLocale()} onChange={(e, newVal) => {
    i18n.setLocale(newVal);
    onChange(e, newVal);
  }} options={i18n.locales} getOptionValue={option => option.uuid} getOptionLabel={option => {
    return <div className={classes.entry}>
                    <span className={classes.label}>{option.languageLabel}</span>
                    <Flag className={classes.icon} code={option.countryCode} />
                </div>;
  }} renderValue={val => {
    const locale = locales.filter(elt => elt.uuid === val)[0];
    return <span className={classes.select}>
                    € &nbsp;-<Flag className={classes.icon} code={locale.countryCode} />{`${locale.countryCode}`}&nbsp;
                </span>;
  }} variant={'transparent'} contrast />;
};


function FooterLink(props) {
  const {i18n, url,  linkComponent = 'a', children, ...other} = props;
  let href = null;
  try {
    href = (new URL(url).href)
  } catch (e) {}
  let component = href ? 'a' : linkComponent
  return (
      <Link component={component}
            {...(href ? {href: href} : {to: i18n ? i18n.localizedUrl(url) : url})}
            {...other}
      >
        { children }
      </Link>
  )
}

function TFooter(props) {
  const {
    onSelectLocale = () => {},
    color = 'gradient',
    children,
    classes,
    className,
    fixed,
    credits = null,
    linkComponent = null,
    logo,
    sections = {},
    socialLinks,
    i18n,
    ...other
  } = props;
  return <footer className={clsx(classes.root, {
    [classes[`color${capitalize(color)}`]]: color !== 'inherit',
    [classes.fixed]: fixed
  })} {...other} componentname="t-footer">
            <Container>
                <Grid container className={classes.container} direction={"column"}>
                    <Grid container item>
                        <Grid container alignItems={"flex-start"} item md={3} xs={12}>
                            {logo ? <Grid item xs={6} sm={4} md={12}><img className={classes.logo} src={logo} alt={"footer_logo"} /></Grid> : null}
                            {i18n ? <Grid item xs={6} sm={4} md={12}>
                                <I18n onChange={onSelectLocale} i18n={i18n} classes={{
                root: classes.i18nRoot,
                entry: classes.i18nEntry,
                select: classes.i18nSelect,
                label: classes.i18nLabel,
                icon: classes.i18nIcon
              }} />
                            </Grid> : null}
                        </Grid>
                        {sections ? Object.entries(sections).map(([key, section]) => <Grid item key={`${key}`} xs={12} sm={4} md={3}>
                                <Typography className={classes.sectionTitle} variant="h4" gutterBottom>{section.title}</Typography>
                                <Box display="flex" flexDirection="column">
                                    {Object.entries(section.links).map(([k, v]) => <div key={k}>
                                            <FooterLink i18n={i18n} className={classes.link}
                                                        url={v.href} color={'contrast'} linkComponent={linkComponent}
                                            >{v.title}</FooterLink>
                                        </div>)}
                                </Box>
                            </Grid>) : null}
                    </Grid>
                    <Divider className={classes.divider} variant={"fullWidth"} />
                    <Grid container item className={classes.footerBottom} justify={'center'}>
                        <Grid item xs={12} md={6}>
                            <Typography className={classes.credits} variant="body1" component="p">{credits}</Typography>
                        </Grid>
                        <Grid item container xs={12} md={6} className={classes.socialLinks}>
                            {socialLinks ? Object.entries(socialLinks).map(([icon, link]) =>
                                <FooterLink
                                    key={`social_link_${icon}`} url={link}
                                    linkComponent={linkComponent}
                                ><Icon color={"white"}>{icon}</Icon>
                                </FooterLink>) : null}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </footer>;
}

export default withStyles(styles, {
  name: "MuiFooter"
})(TFooter);
