import React, { useRef, useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { T } from "@ticketag/i18nlib";
import Box from "@material-ui/core/Box/Box";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import { Icon } from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";

const styles = theme => {
  return {
    root: {
      position: "relative",
      height: "auto"
    },
    showMore: {
      fontSize: "24px",
      lineHeight: "29px",
      fontWeight: "300",
      textAlign: "center",
      [theme.breakpoints.down('sm')]: {
        width: "100%",
        marginTop: theme.spacing(6)
      },
      "& > span": {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        color: "inherit",
        textDecoration: "none",
        cursor: "pointer"
      }
    },
    arrow: {
      height: "24px",
      marginBottom: "10px",
      transform: "rotateZ(180deg)"
    }
  };
};

const defaultShowMore = classes => {
  const showMore = useRef(null);
  const [opacity, setOpacity] = useState(1);
  const [scrollTop, setScrollTop] = useState(0);
  useEffect(() => {
    const onScroll = e => {
      var viewportOffset = showMore.current.getBoundingClientRect();
      setOpacity(1 - (viewportOffset.top - 400) / 250);
      setScrollTop(e.target.documentElement.scrollTop);
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  const onLinkClick = event => {
    event.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return <Typography classes={{
    root: classes.showMore
  }} variant={'h5'} color="accent" style={{
    opacity
  }}>
        <span onClick={onLinkClick} ref={showMore}>
            <Icon className={classes.arrow} style={{
        fontSize: 25
      }} color="accent">arrowdown</Icon>
            <T defaultVal={"Torna su"}>pages.homepage.go_up</T>
        </span>
    </Typography>;
};

export const GoUpSection = ({
  classes = {},
  showMore = defaultShowMore(classes),
  defaultValues = {},
  onValidate,
  onSubmit,
  ...props
}) => {
  return <Box {...props} classes={{
    root: classes.root
  }} componentname="go-up-section">
        <Box display="flex" flexDirection="column" justifyContent={{
      sm: "center"
    }}>
            {showMore}
        </Box>
    </Box>;
};
export default withStyles(styles, {
  useTheme: true
})(GoUpSection);