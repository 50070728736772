import React from 'react';
import clsx from 'clsx';
import {withStyles} from '@material-ui/core/styles';
import {Icon} from "../Icon/Icon";
import IconButton from "@material-ui/core/IconButton";
import capitalize from "@material-ui/core/utils/capitalize";
import Box from "@material-ui/core/Box";


// We can inject some CSS into the DOM.
const styles = (theme) => {
    return {
        root: {
            margin:0,
            padding: theme.spacing(1),
            cursor: 'pointer',
            background: 'none',
            '& svg': {
                width:'24px',
                height:'24px'
            },
            '&.MuiDisabled svg>path': {
                fill: theme.palette.text.disabled
            }
        },
        colorDisabled: {
            color: theme.palette.text.disabled
        },
        colorPrimary: {
            color: theme.palette.primary.main
        },
        colorSecondary: {
            color: theme.palette.secondary.main,
        },
        colorError: {
            color: theme.palette.error.main
        },
        colorGradient: {
            '&:not(.Mui-disabled) svg>path': {
                fill: 'url(#gradient)'
            }
        },
        sizeSmall: {
            '& svg': {
                width:'16px',
                height:'16px'
            }
        },
        sizeLarge: {
            padding: theme.spacing(1.5),
            '& svg': {
                width:'32px',
                height:'32px'
            }
        },
        sizeXl: {
            padding: theme.spacing(1.5),
            '& svg': {
                width:'52px',
                height:'52px'
            }
        },
        noPadding: {
            padding: 0,
        }
    }
};


function TIconButton({children, size='standard', color='initial', disabled=false, noPadding=false, width, height, onClick, ...props}) {
    const {classes, className, ...other} = props;
    const useClasses = [className, classes[`size${capitalize(size)}`], classes[`color${capitalize(disabled ? 'disabled': color)}`]]

    return (
            <IconButton onClick={onClick} disabled={disabled} className={clsx(...useClasses, {[classes.noPadding]: noPadding})} classes={{root:classes.root}} {...other}>
                <Icon width={width} height={height} color={disabled ? 'disabled': color} {...other}>{children}</Icon>
            </IconButton>
    );
}

TIconButton.propTypes = IconButton.propTypes

export default withStyles(styles, {useTheme: true, name: 'MuiIconButton'})(TIconButton);
