import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import RadioButton from "../../base/Radio/RadioButton";
import Typography from "../../base/Typography/Typography";
import { Icon } from "../../base/Icon/Icon";
import { responsive } from "../../../themes/diveedi";
import clsx from "clsx";
import Box from "@material-ui/core/Box";

const styles = theme => {
  return {
    root: {
      //width: '100%',
      borderRadius: '16px',
      '&-MuiDisabled': {
        opacity: '0.5'
      }
    },
    title: {
      paddingLeft: "8px"
    },
    rootH: {},
    onSelect: {},
    rootV: {
      width: '280px',
      height: '110px'
    },
    rootNarrow: {
      width: '220px'
    },
    containerH: {
      padding: "0 0 4px 0"
    },
    containerV: {
      minHeight: 110 - theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5)
    },
    radioButton: {
      marginBottom: "-4px"
    },
    section: {
      display: 'flex',
      alignItems: 'center'
    },
    titleSection: {
      '& h5': {
        marginLeft: theme.spacing(3)
      }
    },
    titleSectionV: {
      '& h5': {
        marginLeft: theme.spacing(1)
      }
    },
    contentSection: {
      justifyContent: 'space-between',
      '& h6': {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
      }
    },
    contentSectionV: {
      position: "relative",
      paddingLeft: "48px",
      flexDirection: 'column',
      alignItems: 'baseline'
    },
    endSection: {
      justifyContent: 'flex-end'
    },
    endSectionV: {
      alignItems: 'flex-end',
      justifyContent: 'flex-end'
    },
    syncing: {
      animation: "rotate linear 2.5s infinite"
    }
  };
};

export const ResponsiveCard = ({
  classes,
  horizontal = false,
  narrow = false,
  title,
    syncing,
  children,
  action,
  name,
  disabled = false,
  ...props
}) => {
  const useClasses = [horizontal ? classes.rootH : narrow ? classes.rootNarrow : classes.rootV];

  if (disabled) {
    useClasses.push(`${classes.root}-MuiDisabled`);
  }

  return <Paper className={clsx(useClasses)} classes={{
    root: classes.root
  }} elevation={5} componentname="responsive-card">
        <Grid classes={{
      root: horizontal ? classes.containerH : classes.containerV
    }} container>
            <Grid xs={horizontal ? 3 : 12} className={horizontal ? classes.titleSection : classes.titleSectionV} item classes={{
        root: classes.section
      }}>
              {
                syncing ?
                    <Box mx={1} mb={-1}>
                      <Icon className={classes.syncing}>sync</Icon>
                    </Box>
                    :
                <RadioButton className={classes.radioButton} onClick={disabled ? null : props.onSelect} disabled={disabled} checkedIcon={<Icon>check_circle</Icon>} checked={props.selected} />
              }
                <Typography color={'textPrimary'} className={classes.title} variant={'h6'}>{title}</Typography>
            </Grid>
            <Grid xs={horizontal ? 7 : 11} item className={horizontal ? classes.contentSection : classes.contentSectionV} classes={{
        root: classes.section
      }}>
                {children}
            </Grid>
            <Grid xs={horizontal ? 2 : 1} item className={horizontal ? classes.endSection : classes.endSectionV} classes={{
        root: classes.section
      }}>
                {disabled ? '' : action}
            </Grid>
        </Grid>
    </Paper>;
};
export default withStyles(styles, {
  useTheme: true
})(ResponsiveCard);