import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";

const styles = theme => {
  return {
    root: {
      height: 96 - theme.spacing(4),
      '&:not(:last-child)': {
        paddingBottom: theme.spacing(4)
      }
    },
    innerBox: {
      minWidth: 0
    },
    prepend: {
      alignSelf: 'center'
    },
    container: {
      alignSelf: 'center',
      minWidth: 0,
      overflow: "hidden"
    }
  };
};

export const ListItem = ({
  prepend,
  append,
  children,
  classes,
  className,
  onClick,
  isLoading,
  ...props
}) => {
  const alignChildren = Array.isArray(children) && children.length > 1 ? 'space-between' : 'center';
  return <Box classes={{
    root: classes.root
  }} className={className} display="flex" alignItems="center" onClick={onClick} {...props} componentname="list-item">
        <Box classes={{
      root: classes.innerBox
    }} display="flex" alignItems="center" flexGrow="1">
            {prepend ? <Box mr={3} display="flex" classes={{
        root: classes.prepend
      }}>{isLoading ? <Skeleton width={60} height={60} variant="circle" /> : prepend}</Box> : null}
            <Box classes={{
        root: classes.container
      }} display="flex" flexDirection="column" alignItems="stretch" flexGrow="1" justifyContent={alignChildren}>{children}</Box>
            {append ? <Box ml={3}>{append}</Box> : null}
        </Box>
    </Box>;
};
export default withStyles(styles, {
  useTheme: true
})(ListItem);