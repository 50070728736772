import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import { SelectArrowIcon, FirstPageIcon, KeyboardArrowLeftIcon, KeyboardArrowRightIcon, LastPageIcon } from "../Icon/Icon"; // We can inject some CSS into the DOM.

const styles = theme => ({
  root: {
    border: "none",
    "& .MuiTablePagination-select": {
      paddingRight: "24px!important"
    }
  },
  paginationRoot: {
    "& p": theme.typography.subtitle1,
    padding: 0,
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end"
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      justifyContent: "space-between"
    }
  },
  paginationSelectArrowIcon: {
    position: "absolute",
    right: "0",
    top: "50%",
    transform: "translateY(-50%)",
    fontSize: "30px"
  },
  paginationSelectRoot: {
    marginLeft: "30px",
    marginRight: "55px",
    padding: "0",
    [theme.breakpoints.down('sm')]: {
      margin: "0"
    }
  },
  paginationSelect: {
    padding: "6px 12px 6px 18px",
    paddingRight: "30px !important",
    textAlignLast: "left",
    textAlign: "left"
  },
  tableCell: {
    padding: 12,
    color: "#1D3557"
  },
  tableCellNoBorder: {
    padding: 12,
    border: "none"
  },
  tableFooter: {
    border: "none"
  },
  iconSelect: {
    display: "flex",
    width: "24px",
    height: "24px"
  },
  iconElement: {
    stroke: "url(#linearColors)"
  }
});

const useStyles1 = makeStyles(theme => ({
  root: {
    display: "flex",
    minWidth: "200px",
    justifyContent: "space-between",
    marginLeft: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      margin: "0",
      marginTop: theme.spacing(4),
      padding: "0 28px"
    }
  },
  paginationButton: {
    padding: 0,
    "& .MuiSvgIcon-root": {
      [theme.breakpoints.down('sm')]: {
        width: "32px",
        height: "32px"
      }
    }
  },
  paginationButtonDisabled: {
    opacity: 0.15,
    "& *": {
      fill: theme.palette.text.primary
    }
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const {
    count,
    page,
    rowsPerPage,
    onChangePage
  } = props;

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return <div className={classes.root}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page" classes={{
      root: classes.paginationButton,
      disabled: classes.paginationButtonDisabled
    }}>
                {theme.direction === 'rtl' ? <LastPageIcon color="gradient"></LastPageIcon> : <FirstPageIcon color="gradient"></FirstPageIcon>}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} classes={{
      root: classes.paginationButton,
      disabled: classes.paginationButtonDisabled
    }} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRightIcon color="gradient"></KeyboardArrowRightIcon> : <KeyboardArrowLeftIcon color="gradient"></KeyboardArrowLeftIcon>}
            </IconButton>
            <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page" classes={{
      root: classes.paginationButton,
      disabled: classes.paginationButtonDisabled
    }}>
                {theme.direction === 'rtl' ? <KeyboardArrowLeftIcon color="gradient"></KeyboardArrowLeftIcon> : <KeyboardArrowRightIcon color="gradient"></KeyboardArrowRightIcon>}
            </IconButton>
            <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page" classes={{
      root: classes.paginationButton,
      disabled: classes.paginationButtonDisabled
    }}>
                {theme.direction === 'rtl' ? <FirstPageIcon color="gradient"></FirstPageIcon> : <LastPageIcon color="gradient"></LastPageIcon>}
            </IconButton>
        </div>;
}

function defaultLabelDisplayedRows({
  from,
  to,
  count
}) {
  return `${from}-${to} di ${count !== -1 ? count : `più di ${to}`}`;
}

function TTable(props) {
  const {
    classes,
    rows = [],
    columns = [],
    labelRowsPerPage = "Righe per pagina",
    labelDisplayedRows = defaultLabelDisplayedRows,
    onRowClick,
    emptyRow,
    ...other
  } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return <TableContainer componentname="t-table">
        <Table className={classes.table} aria-label="custom pagination table" {...other}>
            <TableHead>
                <TableRow>
                {columns.map(column => {
            if (!column.hide) {
              return <TableCell key={column.id} align={column.align} style={{
                minWidth: column.minWidth,
                maxWidth: column.maxWidth,
                width: column.width
              }} classes={{
                root: props.classes.tableCell
              }} colSpan={column.colSpan}>
                            {column.renderLabel ? column.renderLabel : column.label}
                        </TableCell>;
            }
          })}
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
          return <TableRow hover role="checkbox" tabIndex={-1} key={index} onClick={() => {
            if (onRowClick) {
              onRowClick(row, index);
            }
          }}>
                    {columns.map(column => {
              const value = row[column.id];

              if (column.renderCell) {
                return <TableCell classes={{
                  root: props.classes.tableCellNoBorder
                }} key={column.id} align={column.align}>
                                    {column.renderCell}
                                </TableCell>;
              }

              return <TableCell classes={{
                root: props.classes.tableCellNoBorder
              }} key={column.id} align={column.align}>
                                    {column.format ? column.format(value) : value}
                                </TableCell>;
            })}
                    </TableRow>;
        })}
                {rows.length === 0 && emptyRow ? <TableRow>
                        <TableCell classes={{
            root: props.classes.tableCellNoBorder
          }} colSpan="100%">
                            {emptyRow}
                        </TableCell>
                    </TableRow> : null}
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TablePagination rowsPerPageOptions={[5, 10, 25, {
            label: 'Tutte',
            value: rows.length
          }]} count={rows.length} rowsPerPage={rowsPerPage} page={page} classes={{
            select: props.classes.paginationSelect,
            selectRoot: props.classes.paginationSelectRoot,
            toolbar: props.classes.paginationRoot,
            root: props.classes.tableFooter
          }} SelectProps={{
            inputProps: {
              'aria-label': 'righe per pagina'
            },
            native: true,
            IconComponent: () => <SelectArrowIcon classes={{
              root: props.classes.paginationSelectArrowIcon
            }} color="gradient"></SelectArrowIcon>
          }} onChangePage={handleChangePage} onChangeRowsPerPage={handleChangeRowsPerPage} ActionsComponent={TablePaginationActions} labelRowsPerPage={labelRowsPerPage} labelDisplayedRows={labelDisplayedRows} />
                </TableRow>
            </TableFooter>
        </Table>
        </TableContainer>;
}

TTable.propTypes = DataGrid.propTypes;
export default withStyles(styles, {
  useTheme: true
})(TTable);
