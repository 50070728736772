/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';
import {WebapiprotoPayoutProfile} from './WebapiprotoPayoutProfile';
import {WebapiprotoUserName} from './WebapiprotoUserName';

/**
 * The WebapiprotoUserPreferencesUpdateMsg model module.
 * @module model/WebapiprotoUserPreferencesUpdateMsg
 * @version v0.4
 */
export class WebapiprotoUserPreferencesUpdateMsg {
  /**
   * Constructs a new <code>WebapiprotoUserPreferencesUpdateMsg</code>.
   * @alias module:model/WebapiprotoUserPreferencesUpdateMsg
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>WebapiprotoUserPreferencesUpdateMsg</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/WebapiprotoUserPreferencesUpdateMsg} obj Optional instance to populate.
   * @return {module:model/WebapiprotoUserPreferencesUpdateMsg} The populated <code>WebapiprotoUserPreferencesUpdateMsg</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new WebapiprotoUserPreferencesUpdateMsg();
      if (data.hasOwnProperty('name'))
        obj.name = WebapiprotoUserName.constructFromObject(data['name']);
      if (data.hasOwnProperty('contact_email'))
        obj.contactEmail = ApiClient.convertToType(data['contact_email'], 'String');
      if (data.hasOwnProperty('default_profile_uuid'))
        obj.defaultProfileUuid = ApiClient.convertToType(data['default_profile_uuid'], 'String');
      if (data.hasOwnProperty('default_payment_method_uuid'))
        obj.defaultPaymentMethodUuid = ApiClient.convertToType(data['default_payment_method_uuid'], 'String');
      if (data.hasOwnProperty('phone_number'))
        obj.phoneNumber = ApiClient.convertToType(data['phone_number'], 'String');
      if (data.hasOwnProperty('contact_locale'))
        obj.contactLocale = ApiClient.convertToType(data['contact_locale'], 'String');
      if (data.hasOwnProperty('avatar_uuid'))
        obj.avatarUuid = ApiClient.convertToType(data['avatar_uuid'], 'String');
      if (data.hasOwnProperty('payout_profile'))
        obj.payoutProfile = WebapiprotoPayoutProfile.constructFromObject(data['payout_profile']);
    }
    return obj;
  }
}

/**
 * @member {module:model/WebapiprotoUserName} name
 */
WebapiprotoUserPreferencesUpdateMsg.prototype.name = undefined;

/**
 * @member {String} contactEmail
 */
WebapiprotoUserPreferencesUpdateMsg.prototype.contactEmail = undefined;

/**
 * @member {String} defaultProfileUuid
 */
WebapiprotoUserPreferencesUpdateMsg.prototype.defaultProfileUuid = undefined;

/**
 * @member {String} defaultPaymentMethodUuid
 */
WebapiprotoUserPreferencesUpdateMsg.prototype.defaultPaymentMethodUuid = undefined;

/**
 * @member {String} phoneNumber
 */
WebapiprotoUserPreferencesUpdateMsg.prototype.phoneNumber = undefined;

/**
 * @member {String} contactLocale
 */
WebapiprotoUserPreferencesUpdateMsg.prototype.contactLocale = undefined;

/**
 * @member {String} avatarUuid
 */
WebapiprotoUserPreferencesUpdateMsg.prototype.avatarUuid = undefined;

/**
 * @member {module:model/WebapiprotoPayoutProfile} payoutProfile
 */
WebapiprotoUserPreferencesUpdateMsg.prototype.payoutProfile = undefined;


