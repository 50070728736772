import { createMuiTheme } from "@material-ui/core/styles";

import lightBlue from "@material-ui/core/colors/lightBlue";
import indigo from "@material-ui/core/colors/indigo";
import purple from "@material-ui/core/colors/purple";
import amber from "@material-ui/core/colors/amber";
import deepOrange from "@material-ui/core/colors/deepOrange";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import React from "react";

const variables = {
    heading_color: "#191414",
    body_color: "#49586F",
    font_family_exo2: `"Exo 2", sans-serif`
}

const breakpoints = createBreakpoints({
    keys:["xs", "sm", "md", "lg", "xl"],
    values: {
        xs: 0,
        sm: 576,
        md: 768,
        lg: 1024,
        xl: 1280
    }
})

function h(sm, md, lg) {
    return {
        fontSize: sm,
        [breakpoints.between('md', 'lg')]: {
            fontSize: md,
        },
        [breakpoints.up('lg')]: {
            fontSize: lg,
        },
    };
}

export function responsive(prop, sm, md, lg) {
    const ret = {}
    ret[prop] = sm
    ret[breakpoints.between('md', 'lg')] = {}
    ret[breakpoints.between('md', 'lg')][prop] = md
    ret[breakpoints.up('lg')] = {}
    ret[breakpoints.up('lg')][prop] = lg
    return {
        fontSize: sm,
        [breakpoints.between('md', 'lg')]: {
            fontSize: md,
        },
        [breakpoints.up('lg')]: {
            fontSize: lg,
        },
    };
}

const hypens = {
    "-webkit-hyphens": "auto",
    "-ms-hyphens": "auto",
    "hyphens": "auto",
}
const shadows = {
    variants: {
        none: "none",
        //
        mobile_service: "0px 2px 8px rgba(0, 0, 0, 0.4)",
        desktop_service: "-5px 5px 8px rgba(172, 172, 172, 0.1), 5px -5px 8px rgba(172, 172, 172, 0.1), -5px -5px 8px rgba(255, 255, 255, 0.7), 0px 10px 16px rgba(0, 0, 0, 0.25)",
        desktop_service_hover: "-5px 5px 16px rgba(172, 172, 172, 0.15), 5px -5px 16px rgba(172, 172, 172, 0.15), -5px -5px 16px rgba(255, 255, 255, 0.35), 0px 15px 40px rgba(0, 0, 0, 0.25)",
        desktop_service_clicked: "-5px 5px 8px rgba(172, 172, 172, 0.1), 5px -5px 8px rgba(172, 172, 172, 0.1), -5px -5px 8px rgba(255, 255, 255, 0.7), 0px 5px 8px rgba(0, 0, 0, 0.25)",
        chip_card: "-5px 5px 16px rgba(172, 172, 172, 0.05), 5px -5px 16px rgba(172, 172, 172, 0.05), 0px 5px 24px rgba(0, 0, 0, 0.05)",
        chip_card_hover: "-5px 5px 16px rgba(172, 172, 172, 0.05), 5px -5px 16px rgba(172, 172, 172, 0.05), 0px 15px 32px rgba(0, 0, 0, 0.065)",
        chip_card_clicked: "-5px 5px 16px rgba(172, 172, 172, 0.05), 5px -5px 16px rgba(172, 172, 172, 0.05), 0px 5px 8px rgba(0, 0, 0, 0.05)",
        desktop_card: "0px 8px 24px rgba(0, 0, 0, 0.1)",
        desktop_card_hover: "0px 16px 48px rgba(0, 0, 0, 0.1)",
        desktop_card_clicked: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        //
        contrast_mobile_service: "0px 2px 8px rgba(0, 0, 0, 0.4)",
        contrast_desktop_service: "-5px 5px 15px rgba(16, 13, 40, 0.1), 5px -5px 15px rgba(16, 13, 40, 0.1), 0px 10px 16px rgba(8, 7, 18, 0.5)",
        contrast_desktop_service_hover: "0px 0px 32px rgba(255, 255, 255, 0.25), -5px 5px 15px rgba(16, 13, 40, 0.1), 5px -5px 15px rgba(16, 13, 40, 0.1), 0px 15px 32px rgba(8, 7, 18, 0.25)",
        contrast_desktop_service_clicked: "-5px 5px 15px rgba(16, 13, 40, 0.1), 5px -5px 15px rgba(16, 13, 40, 0.1), 0px 5px 8px rgba(8, 7, 18, 0.5)",
    },
}



const typography = {
    variants: {
        title: { // h1
            fontSize: "42px",
            fontWeight: 600,
            lineHeight: "50px",
            letterSpacing: "initial",
        },
        titleMobile: { // h2
            fontSize: "36px",
            fontWeight: 600,
            lineHeight: "42px",
            letterSpacing: "initial",
        },
        cardTitle: { //h3
            fontSize: "26px",
            fontWeight: 600,
            lineHeight: "32px",
            letterSpacing: "0.01em",
        },
        subTitle: { //h4
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "24px",
            letterSpacing: "0.01em",
        },
        mediumMedium: { //h5
            fontSize: "18px",
            fontWeight: 500,
            lineHeight: "22px",
            letterSpacing: "initial",
        },
        miniSubtitle: { //h6
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "28px",
            letterSpacing: "initial",
        },
        buttonWrite: { // button
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "20px",
            letterSpacing: "initial",
            textTransform: "none",
        },
        mediumMainText: { // body1
            fontSize: "16px",
            fontWeight: 300,
            lineHeight: "20px",
            letterSpacing: "initial",
            hypens: "auto",
            ...hypens
        },
        lightMainText: { //body2
            fontSize: "16px",
            fontWeight: 300,
            lineHeight: "20px",
            letterSpacing: "0.05em",
            ...hypens
        },
        microTextMedium: {//overline
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "24px",
            letterSpacing: "0.01em",
            textTransform: "uppercase"
        },
        microText: {//subtitle1
            fontSize: "14px",
            fontWeight: 300,
            lineHeight: "24px",
            letterSpacing: "0.01em",
            ...hypens
        },
        miniMini: {//caption
            fontSize: "12px",
            fontWeight: 300,
            lineHeight: "20px",
            letterSpacing: "0.01em",
        },
    }
}

const theme = {
    default_gradient: `linear-gradient(to right, ${purple.A100} 0%,  ${purple.A400} 100%)`,
    default_gradient_start: purple.A100,
    default_gradient_end: purple.A400,
    themeName: 'diveedi',
    breakpoints: breakpoints,
    typography: {
        fontFamily:variables.font_family_exo2,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightSemiBold: 600,
        fontWeightBold: 700,
        variantMapping: {
            h1: 'h1',
            h2: 'h2',
            h3: 'h3',
            h4: 'h4',
            h5: 'h5',
            h6: 'h6',
            subtitle1: 'h6',
            subtitle2: 'h6',
            body1: 'p',
            body2: 'p'
        },
        button: {
            letterSpacing: "0.05em",
            lineHeight: "23px",
            fontSize: "14px"
        },
    },
    shadows: [
        shadows.variants["none"],                           //0
        shadows.variants["mobile_service"],                 //1
        shadows.variants["desktop_service"],                //2
        shadows.variants["desktop_service_hover"],          //3
        shadows.variants["desktop_service_clicked"],        //4
        shadows.variants["chip_card"],                      //5
        shadows.variants["chip_card_hover"],                //6
        shadows.variants["chip_card_clicked"],              //7
        shadows.variants["desktop_card"],                   //8
        shadows.variants["desktop_card_hover"],             //9
        shadows.variants["desktop_card_clicked"],           //10
        //
        shadows.variants["contrast_mobile_service"],            //11
        shadows.variants["contrast_desktop_service"],           //12
        shadows.variants["contrast_desktop_service_hover"],     //13
        shadows.variants["contrast_desktop_service_clicked"],   //14
    ],
    palette: {
        primary: {
            main: "#57D9CB",
            contrastText: "#FFFFFF"
        },
        secondary: {
            main: "#8B82FA",
        },
        accent: {
            main: "#C183FF",
        },
        error: {
            main: "#FF5485"
        },
        default_gradient: "linear-gradient(270.16deg, #4DBFB3 1.89%, #C183FF 98.95%)",
        default_gradient_reverse: variables.default_gradient_reverse,
        default_gradient_start: "#4DBFB3",
        default_gradient_end: "#C183FF",
        text: {
            primary: "#49586F",
            secondary: "#191414",
            paragraph: "#5E7891",
        }
    },
    status: {
        danger: "orange"
    },
    footer: {
        gradient_start_xs: '#B950DF',
        gradient_end_xs: '#49A4A2',
        gradient_start_md: '#B950DF',
        gradient_end_md: '#00ABA3',
        gradient_start_lg: '#B950DF',
        gradient_end_lg: '#00ABA3',
    }
}

theme.overrides = {
    MuiSkeleton: {
        rect: {
            borderRadius: "16px"
        }
    },
    MuiTypography: {
        h1: typography.variants["title"],
        h2: typography.variants["titleMobile"],
        h3: typography.variants["cardTitle"],
        h4: typography.variants["subTitle"],
        h5: typography.variants["mediumMedium"],
        h6: typography.variants["miniSubtitle"],
        button: typography.variants["buttonWrite"],
        body1: typography.variants["mediumMainText"],
        body2: typography.variants["lightMainText"],
        overline: typography.variants["microTextMedium"] ,
        subtitle1: typography.variants["microText"],
        caption: typography.variants["miniMini"]
    },
    MuiPickersToolbar:{
        toolbar: {
            background: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.main,
            color: variables.primary_b,
        }
    },
    MuiPickersToolbarText : {
        toolbarTxt: {
            color: variables.primary_b,
        },
        toolbarBtnSelected: {
            color: variables.primary_b,
        },
    },
    MuiPickersDay: {
        daySelected: {
            background: variables.primary_b,
            backgroundColor: variables.primary_b,
            color: '#FFFFFF'
        }
    },
    MuiFormControl: {
        root: {
            width: '100%'
        }
    },
    MuiInputBase: {
        root: {
            height: 'calc(1.5em + 0.5rem)',
            padding: '0.375rem 0.75rem',
        },
        marginDense: {
            height: 'auto'
        }
    },
    MuiOutlinedInput: {
        root: {
            "&.Mui-focused fieldset": {
                borderColor: variables.primary_b,
                borderWidth: "1px",
                "&.MuiOutlinedInput-notchedOutline": {
                    borderColor: variables.primary_b,
                    borderWidth: "1px",
                }
            }
        },
        input: {
            padding: 0
        }
    },
    MuiTouchRipple: {
        root: {
            display: "none"
        }
    },
    MuiLink: {
        root: {
            fontFamily:variables.font_family_exo2
        }
    },
    MuiStepConnector: {
        lineVertical: {
            //minHeight: '50px',
            //marginTop: '4px',
            //marginLeft: '3px',
        }
    },
    MuiPaper: {
        elevation3: {
            boxShadow: '-10px 10px 24px rgba(172, 172, 172, 0.08), 10px -10px 24px rgba(172, 172, 172, 0.08), 10px 10px 24px rgba(172, 172, 172, 0.08)'
        }
    }
}


export const diveediTheme = theme
export default createMuiTheme(theme);
