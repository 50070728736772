import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from "../../base/Typography/Typography";
import { Box, Divider, Button } from "@material-ui/core";
import BaseActionCard from "../BaseActionCard/BaseActionCard";
import { Icon } from "../../base/Icon/Icon";
import TextButton from "../../base/Button/TextButton";

const styles = theme => {
  return {
    root: {
      borderRadius: theme.spacing(2)
    },
    container: {
      padding: "32px 48px",
      cursor: "default",
      [theme.breakpoints.down('md')]: {
        padding: "32px 44px"
      }
    },
    elevation4: {
      boxShadow: 'none'
    },
    subtitle: {
      lineHeight: '20px',
      fontSize: 12,
      fontWeight: 300,
      textAlign: 'justify',
      letterSpacing: '0.01em'
    },
    title: {
      width: '100%',
      textAlign: 'left',
      textTransform: 'uppercase',
      marginTop: 0,
      fontWeight: 600,
      lineHeight: theme.spacing(4) + 'px',
      fontSize: '18px',
      marginBottom: theme.spacing(3)
    },
    iconContainer: {
      width: '60px',
      height: '60px',
      marginRight: theme.spacing(3),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginBottom: theme.spacing(4),
      width: '100%'
    },
    headerTitle: {
      fontWeight: 600,
      fontSize: '15px',
      lineHeight: '27px',
      textTransform: 'capitalize'
    },
    headerSubtitle: {
      fontWeight: 300,
      fontSize: '12px',
      lineHeight: '20px',
      whiteSpace: 'pre-line',
      [theme.breakpoints.down('md')]: {
        fontSize: '10px',
        lineHeight: '17px'
      }
    },
    headerWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'flex-start'
    },
    divider: {
      borderRadius: '14px',
      height: '1px'
    },
    body: {
      width: '100%'
    },
    footer: {
      marginTop: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column'
    },
    button: {
      marginTop: theme.spacing(5),
      fontWeight: 600
    }
  };
};

function CheckoutCard(props) {
  const {
    children,
    icon,
    classes,
    onClick,
    header = null,
    title = null,
    subtitle = null,
    buttonText = null,
    elevation = 4,
    buttonVariant = 'error',
    ...other
  } = props;
  return <BaseActionCard classes={{
    root: classes.container
  }} hoverDisabled="true" clickDisabled="true" {...other} componentname="checkout-card">
        {title ? <Typography classes={{
      root: classes.title
    }} variant={'h4'}>
                    {title}
                </Typography> : null}
        {header ? <Box classes={{
      root: classes.header
    }}>
                    {icon ? <div className={classes.iconContainer}>
                                {typeof icon === 'string' ? <Icon size={'large'}>{icon}</Icon> : icon}
                            </div> : null}
                    <div className={classes.headerWrapper}>
                        <Typography classes={{
          root: classes.headerTitle
        }} color="textSecondary" variant={'h5'}>
                            {header.title}
                        </Typography>
                        <Typography classes={{
          root: classes.headerSubtitle
        }} variant={'body1'}>
                            {header.subtitle}
                        </Typography>
                    </div>
                </Box> : null}
        <Divider className={classes.divider} flexItem={true} variant="fullWidth" />
        {children ? <Box classes={{
      root: classes.body
    }}>
                    {children}
                </Box> : null}
        {children && subtitle ? <Divider className={classes.divider} flexItem={true} variant="fullWidth" /> : null}
        <Box classes={{
      root: classes.footer
    }} display="flex" flexDirection="column" alignItems="center">
            {subtitle ? <Typography classes={{
        root: classes.subtitle
      }} variant={'body1'}>{subtitle} </Typography> : null}
            {buttonText ? <TextButton size="large" variant={buttonVariant} className={classes.button} onClick={onClick}>{buttonText}</TextButton> : null}
        </Box>

    </BaseActionCard>;
}

export default withStyles(styles, {
  name: "CheckoutCard"
})(CheckoutCard);
