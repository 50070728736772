import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import {getDefaultMessage} from "../../services/errors/defaults";
import {T} from "@ticketag/i18nlib";
import zIndex from "@material-ui/core/styles/zIndex";


const styles = (theme) => {
    return {
        root: {
            position: 'relative',
            maxWidth: '100%'
        },
        titleContainer: {
            position: 'relative',
            textAlign: 'center'
        },
        messageContainer: {
            fontSize: '20px'
        },
        backgroundContainer: {
            position: 'absolute',
            width: '100%',
            zIndex: -1,
        },
        background: {
            fontSize: '200px',
            [theme.breakpoints.down('xs')]: {
                fontSize: '150px',
            },
            lineHeight: 1,
            fontWeight: '600',
            fontFamily: 'Montserrat',
            zIndex: -1,
            opacity: 0.1,
            userSelect: 'none'
        },
        stack: {
            color: theme.palette.error.contrastText,
            padding: theme.spacing(2),
            borderRadius: theme.spacing(2),
            backgroundColor: theme.palette.error.main,
            overflowX:'auto'
        },
        debugMessage: {
            color: theme.palette.text.primary,
            padding: theme.spacing(2),
            borderRadius: theme.spacing(2),
            border: `1px solid ${theme.palette.action.disabled}`,
            overflowX:'auto'
        }
    }
};

const ErrorMessage = withStyles(styles, {useTheme: true})(({classes, title, message, code, statusCode = 404, ...props}) => {
    return <Box classes={{root: classes.root}}>
        <Box mb={{xs: 7, md: 7}} classes={{root: classes.titleContainer}} display="flex" alignItems="center"
             justifyContent="center">
            <Typography semiBold color="textSecondary" variant="h2">{title}</Typography>
            <Box classes={{root: classes.backgroundContainer}} display="flex" alignItems="center"
                 justifyContent="center">
                <Typography classes={{root: classes.background}} variant={"h1"}
                            color={"gradient"}>{statusCode}</Typography>
            </Box>
        </Box>
        <Box mb={6} classes={{root: classes.messageContainer}}>
            <Typography color="textPrimary" align={"center"} variant="h4">{code ? <b>[{code}]</b> : null} {message}</Typography>
        </Box>
        {props.debugMessage ? <Box mb={props.stack ? 2 : 6}>
            <pre className={classes.debugMessage}>{props.debugMessage}</pre>
        </Box> : null}
        {props.stack ? <Box mb={6}>
            <pre className={classes.stack}>{props.stack}</pre>
        </Box> : null}
    </Box>
})

const getErrorData = (err) => {
    const errorData = getDefaultMessage(err?.status)
    errorData.code = null
    errorData.statusCode = null

    if (err?.message) {
        errorData.message = err.message
    }
    if (err?.stack) {
        errorData.stack = err.stack
    }



    if (err?.response && err.response.body) {
        errorData.title = err.response.body.message
        errorData.message = err.response.body.string_code || err.response.body.message
        errorData.debugMessage = err.response.body.debug_message || null
        errorData.stack = err.response.body.stack || null
        errorData.code = err.response.body.code
    }
    if (err?.status) {
        errorData.statusCode = err.status
    }

    return errorData
}

export const withRedirect = (history, promise) => {
    const loginUrl = '/auth/login'
    return promise.catch((err) => {
        const errorData = getErrorData(err)
        if (err?.status) {
            if (err.status === 401 && !localStorage.getItem('redirect-count')) {
                localStorage.setItem('redirect-count', 1)
                //console.log('redirect')
                window.location.href = loginUrl
                return
            }

        }
        localStorage.setItem('error', JSON.stringify(errorData))
        history.push(`/error/${err.status||''}`)
    })
}

const errorModalStyles = (theme) => {
    return {
        background: {zIndex: 'auto'},
        backgroundContainer: {zIndex: 'auto'},
        titleContainer: {
            marginTop: theme.spacing(7)
        }
    }
}
const StyledModalErrorMessage = withStyles(errorModalStyles, {useTheme: true})(ErrorMessage)


export const withErrorModal = (modals, promise, options = {}) => {
    const loginUrl = '/auth/login'
    return promise.catch(err => {
        const errorData = getErrorData(err)
        const styledErrMsg = <StyledModalErrorMessage
            title={<T>{errorData.title}</T>}
            message={<T>{errorData.message}</T>}
            debugMessage={errorData.debugMessage ? <T>{errorData.debugMessage}</T> : null}
            stack={errorData.stack}
            code={errorData.code}
            statusCode={errorData.statusCode}
        />
        modals.OkOnly(
            <T>errors.generic.generic</T>,
            styledErrMsg,
            {
                variant: 'error', size: 'md', styles: {
                    title: {paddingBottom: 0}
                }
            }
        ).then(() => {
            throw err
        }).catch(() => {
            throw err
        })
    })
}

export default ErrorMessage;
