import {withStyles} from "@material-ui/core/styles";
import React from "react";
import MenuItem from "@material-ui/core/MenuItem";

const styles = (theme) => {
    return {
        root: {
            letterSpacing: "0.5px",
            fontSize: "16px",
            fontWeight: "300",
            padding: "6px 40px 6px 20px",
            "&.Mui-focusVisible": {
                backgroundColor: "transparent"
            }
        },
    }
}

const TMenuItem = React.forwardRef(function TMenuItem(props, ref) {
    const {
        classes,
        children,
        ...other
    } = props;
    return (
    <MenuItem ref={ref} classes={{root: classes.root}} {...other}>
        {props.children}
    </MenuItem>
    )
})

export default withStyles(styles, {name: "MuiMenuItem"})(TMenuItem)