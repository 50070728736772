import React, { useEffect, useRef, useState } from 'react';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { T } from "@ticketag/i18nlib";
import { CardCvcElement, CardElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { FormLabel } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@ticketag/ticketag-uilib/src/components/base/Button/Button";
import TextField from "@ticketag/ticketag-uilib/src/components/base/TextField/TextField";
import { useForm } from "react-hook-form";
import Form from "@ticketag/ticketag-uilib/src/components/complex/Form/Form";
import { CardCvc, CardExpiry, CardNumber } from "../Stripe/Stripe";
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => {
  return {
    root: {
      "&.Mui-error StripeElement": {}
    },
    inputs: {
      height: '16px',
      //maxWidth: '220px',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      cursor: 'text',
      "&.StripeElement--focus": {
        border: "1px solid currentColor!important"
      },
      "&.StripeElement--invalid": {
        borderColor: theme.palette.error.main + "!important"
      }
    },
    inputNarrow: {
      maxWidth: '114px'
    },
    sizeBig: {
      "& .StripeElement": {
        padding: "16px",
        borderRadius: "16px",
        boxShadow: "-10px 10px 24px rgba(172, 172, 172, 0.08), 10px -10px 24px rgba(172, 172, 172, 0.08), 10px 10px 24px rgba(172, 172, 172, 0.08)",
        border: "1px solid transparent",
      }
    },
    grid: {
      marginBottom: theme.spacing(4)
    },
    label: {
      fontWeight: "500",
      textTransform: 'uppercase'
    },
    formControl: {
      width: 'auto',
      //width: '195px',
      display: 'block'
    },
    inlineVariant: {
    },
    form: {
      padding: theme.spacing(0)
    },
    progress: {
      marginRight: theme.spacing(2)
    },
    inputBig: {
    }
  };
};

const errInvalidCard = <T defaultVal={"Il numero della tua carta non è valido"}>components.payment_method_form.invalid_credit_card</T>;

const PaymentMethodForm = ({
  classes,
  onChange, children,
  onSubmit,
  submitHandler,
    inlineVariant = false,
  isSaving = false,
  beforeSubmit = (vals, formState, cb) => cb(vals),
  onCancel,
  formRef,
    size = "default",
  ...props
}) => {
  const theme = useTheme();
  const stripe = useStripe();
  const elements = useElements();
  const form = React.useRef();
  const cardElements = {};

  const onStripeElemChange = (elem, name) => {
    cardElements[name] = cardElements[name] || elements.getElement(elem.elementType);
    elem = JSON.parse(JSON.stringify(elem)); //const errName = `${name}_err`

    if (!elem.complete) {// form.current.setValue(name, null, { shouldValidate: false, shouldDirty: true })
      // form.setError(errName, {type:'required', message: elem.error?.message||'Prova'} ) //, { shouldValidate:
      // true, shouldDirty: true }
    } else if (elem.error && elem.error.message) {// form.current.setValue(name, null, { shouldValidate: false, shouldDirty: true })
      // form.setError(errName, {type:'required', message: elem.error.message}) //, { shouldValidate: true,
      // shouldDirty: true }
    } else {
      // form.clearErrors(errName)
      // form.current.setValue(name, true)
      const cardElement = elements.getElement(CardNumberElement);
      form.current.setValue('cardElement', cardElement);
      form.current.setValue('stripe', stripe);
    }
    /*form.current.trigger(name).then((formState, formValues) => {
        console.log('trigger field', formState, formValues)
        onValidate ? onValidate(formState) : null
        onFormChange()
    })*/

  };

  const registerCardElement = (stripeElement, name) => {
    if (!stripe || !elements) {
      return;
    }

    cardElements[name] = elements.getElement(stripeElement);
  };
  /*register({ name: 'cardNumber', type: 'custom' }, {required:true})
  register({ name: 'cvc', type: 'custom' }, {required:true})
  register({ name: 'cardExpiry', type: 'custom' }, {required:true})
  register({ name: 'cardElement', type: 'custom' }, {})*/


  useEffect(() => {
    registerCardElement(CardNumberElement, 'cardNumber');
    registerCardElement(CardCvcElement, 'cvc');
    registerCardElement(CardExpiryElement, 'cardExpiry'); // form.current.register({ name: 'cardElement', type: 'custom' })
    // form.current.register({ name: 'stripe', type: 'custom' })
  }, []); // <-- empty array means 'run once'

  if (!stripe) {
    return <></>;
  } // beforeSubmit={(vals, formState, cb) => {cb(vals, formState) }}
  //
  //submitHandler={(cb) => handleSubmit(cb)}
  //formRef={(f) => form = f}


  return <>
    <Form grid={{
    classes: {
      container: inlineVariant ? classes.inlineVariant : classes.form
    },
    spacing: 2
  }} T={T} formRef={f => {
    form.current = f;
    if (formRef) {
      formRef(form.current);
    }
  }} onChange={onChange} {...props} componentname="payment-method-form">
        <TextField showErrors size={size} variant={size === "big" ? "shadowed" : 'outlined'} label={"nome"} name="firstName" rules={{
      required: true
    }} box={{
      my: 0
    }} />
        <TextField showErrors size={size} variant={size === "big" ? "shadowed" : 'outlined'} label={"cognome"} name="lastName" rules={{
      required: true
    }} box={{
      my: 0
    }} />
        <CardNumber tabindex={3} stripe={stripe} grid={{
      xs: 12,
      md: 12
    }} name="cardNumber" box={{
      my: 2
    }} onChange={elem => onStripeElemChange(elem, 'cardNumber')} rules={{
      required: {
        value: true,
        message: {
          key: 'components.payment_method_form.invalid_credit_card',
          defaultVal: 'Il numero della tua carta non è valido'
        }
      }
    }} label={<T defaultVal="numero di carta">components.payment_method_form.card_number</T>}
        className={size === "big" ? classes.sizeBig : null}
        />
        <CardExpiry stripe={stripe} grid={{
      xs: 6,
      md: 6
    }} box={{
      my: 2
    }} name="cardExpiry" tabindex={4} onChange={elem => onStripeElemChange(elem, 'cardExpiry')} rules={{
      required: {
        value: true,
        message: {
          key: 'components.payment_method_form.invalid_expiry',
          defaultVal: 'Inserisci una scadenza valida'
        }
      }
    }} label={<T defaultVal={"Scadenza"}>components.payment_method_form.card_expiry</T>}
                    className={size === "big" ? classes.sizeBig : null}
        />
        <CardCvc stripe={stripe} grid={{
      xs: 6,
      md: 6
    }} box={{
      my: 2,
      mb: 5
    }} name="cvc" onChange={elem => onStripeElemChange(elem, 'cvc')} tabindex={5} rules={{
      required: {
        value: true,
        message: {
          key: 'components.payment_method_form.invalid_cvc',
          defaultVal: 'Inserisci un cvc valido'
        }
      }
    }} label={<T defaultVal="cvv2">components.payment_method_form.card_security_code</T>}
                 className={size === "big" ? classes.sizeBig : null}
        />
        {isSaving ? <Grid item>
            <Box display="flex">
                <CircularProgress classes={{
          root: classes.progress
        }} color={'secondary'} size={24} />
                <Typography align={'center'} color={'secondary'} variant={'h5'}>
                    <T defaultVal="Verifica metodo di pagamento in corso">components.payment_method_form.check_in_progress</T>
                </Typography>
            </Box>
        </Grid> : <></>}

    </Form>
    {children}
    </>;
};

export default withStyles(styles, {
  useTheme: true
})(PaymentMethodForm);
