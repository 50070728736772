/*
 * NOISY creates a noisy background image. In my version it caches
 * the dataURL in localStorage.
 *
 * @autor Magnus Fredlundh { magnus.fredlundh.se }
 *
 * Credits to:
 * Wizcover's http://jsfiddle.net/SfzPc/14/
 * and http://jsfiddle.net/osserpse/6SZQY/
 */

const CONFIG = {
    xs: { alpha: .07},
    md: { alpha: .09, size: 250},
}

export class noisy {
    constructor() {
        this.oldBg = null
        this.noise = {};
        Object.keys(CONFIG).forEach(key => {
            this.noise[key] = /*localStorage[`noise-${key}`]||*/this.generateNoise(key, CONFIG[key])
        })

        const body = this.body
        const bodyStyle = body.currentStyle || window.getComputedStyle(body, false)
        this.oldBg = bodyStyle.backgroundImage.slice(4, -1).replace(/"/g, "")||'';
    }

    get body() {
        return document.getElementsByTagName('body')[0];
    }

    generateNoise(key= 'xs', options = {}) {
        let ch = 20
        const {
            alpha=.08,
            size=50,
        } = options
        const canvas = document.createElement('canvas'),
            context = canvas.getContext('2d')
        let canvasHeight = ch = canvas.height = size,
            canvasWidth = canvas.width = size;
        let num;

        //html.appendChild(canvas);

        // Drawing noise on the canvas.
        while (canvasWidth--) {
            while (canvasHeight--) {
                num = Math.floor(Math.random() * 255)
                context.fillStyle = "rgba(" + num + "," + num + "," + num + "," + alpha + ")";
                context.fillRect(canvasWidth, canvasHeight, 1, 1);
            }
            canvasHeight = ch; // Reset canvasHeight.
        }

        // Storing the data-url.
        localStorage['noise'] = canvas.toDataURL();
        return canvas.toDataURL();
    }

    showNoise (size='xs') {
        this.body.setAttribute('style', `background-image: url("${this.noise[size]}")`);
    }

    hideNoise () {
        this.body.setAttribute('style', `background-image: none`);
    }
}

export const Noisy = new noisy()

export default Noisy
