import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T, withI18n} from "@ticketag/i18nlib";
import TmpCard from "@ticketag/ticketag-uilib/src/components/complex/TmpCard/TmpCard";
import {withApi} from "../../hoc/Api/Provider";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box/Box";
import {withModals} from "@ticketag/ticketag-uilib/src/components/hoc/Modals/Provider";
import {withRouter} from "react-router-dom";
import Title from "../../components/Title/Title";
import Scroll from "../../components/Scroll/Scoll";
import TextFooter from "../../components/TextFooter/TextFooter";
import Help from "../../components/Help/Help";
import Subtitle from "../../components/Subtitle/Subtitle";
import {pages} from "../../constants";
import Page from "../../components/Page/Page";


const styles = (theme) => {
    return {
        root: {

        },
        nextButton: {
            width: "80px",
            height: "80px",
            borderRadius: "50%",
            marginBottom: "40px"
        },
        endSection: {
            height: "100%",
            "& > *": {
                height: "100%",
                alignItems: "flex-end"
            }
        }
    }
};

class GroupVisibility extends Component {

    constructor(props) {//
        super(props);
    }

    handleNext(visibility) {
        this.props.group.updateGroup({scope: visibility}).then(() => {
            this.props.history.push(this.props.i18n.buildLocalizedUrl("groups", this.props.group.uuid, "create", "size"))
        })
    }

    renderScope(scope) {
        switch (scope) {
            case "public":
                return (
                    <Box display={"flex"} justifyContent={"center"}>
                        <TmpCard title={"pubblico"}
                                 subtitle={"Altri utenti del sito"}
                                 media={`${process.env.PUBLIC_URL||""}/img/services/scopes/scope_public.png`}
                                 onClick={() => this.handleNext("public")}
                        />
                    </Box>
                )
            case "private":
                return (
                    <Box display={"flex"} justifyContent={"center"}>
                        <TmpCard
                            title={"privato"}
                            subtitle={"Solo su invito"}
                            media={`${process.env.PUBLIC_URL||""}/img/services/scopes/scope_private.png`}
                            onClick={() => this.handleNext("private")}
                        />
                    </Box>
                )
        }
    }

    renderContent() {
        const {group} = this.props;
        return (
            <Grid container justify={"center"} alignItems={"center"} spacing={2} className={this.props.classes.root}>
                {
                    group.serviceType.scopesAvailable.map((scope) => <Grid item xs={12} key={scope}>{this.renderScope(scope)}</Grid>)
                }
            </Grid>

        )
    }

    render() {
        const {group} = this.props;
        const {serviceFamily} = group
        const {auth, i18n, history} = this.props;
        const {
            seo, layout, page, title, subtitle, helpTitle, footer,
            ...other
        } = this.props;
        return (
            <Page page={page} seo={seo} layout={{
                ...layout,
                onClose: () => this.props.onClose()
            }}>
                <Title>{title}</Title>
                <Subtitle args={{Name: serviceFamily.name}}>{subtitle}</Subtitle>
                <Scroll>
                    {this.renderContent()}
                </Scroll>
                <TextFooter
                    helpTitle={helpTitle}
                    help={
                        group.serviceType.scopesAvailable.map((s) => (
                            <Help id={s}
                                  args={{Scope: s}}
                                  title={`Gruppo {{.args.Scope}}`}
                            >{`Gruppo {{.args.Scope}}`}</Help>
                        ))}>
                    {footer}
                </TextFooter>
        </Page>
        )
    }
}

export default withStyles(styles, {useTheme: true})(withRouter(withModals(withApi(withI18n(withModals(GroupVisibility))))));
