import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";;
import {withRouter} from "react-router-dom"
import {withI18n} from "@ticketag/i18nlib";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";

const styles = (theme) => {
    return {
        root: {
            cursor: "pointer",
            height: "40px",
        }
    }
}

function TAppLogo(props) {
    const {classes, i18n, history, onClick} = props;
    const handleClick = () => {
        history.push(i18n.buildLocalizedUrl("/"))
    }
    return (
            <img className={classes.root} src={`${process.env.PUBLIC_URL || ""}/img/logo.svg`} alt={"logo"}
                 onClick={handleClick}
            />
    )
}
export default withStyles(styles)(withI18n(withRouter((TAppLogo))))