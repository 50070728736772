import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T, withI18n} from "@ticketag/i18nlib";
import AuthContext from "../../hoc/Auth/context";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import Progress from "@ticketag/ticketag-uilib/src/components/base/Progress/Progress";
import Table from "@ticketag/ticketag-uilib/src/components/base/Table/Table";
import ComboBox from "@ticketag/ticketag-uilib/src/components/base/Select/ComboBox";

import { withErrorModal, withRedirect } from "../../components/ErrorMessage/ErrorMessage";

import {withApi} from "../../hoc/Api/Provider";
import withWidth from "@material-ui/core/withWidth";
import {withModals} from "@ticketag/ticketag-uilib/src/components/hoc/Modals/Provider";
import {Icon} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";
import Button from "@ticketag/ticketag-uilib/src/components/base/Button/Button";
import PayoutData from "../../components/PayoutData/PayoutData";
import TransactionDetails from "../../components/TransactionDetails/TransactionDetails";
import FiltersModal from "../../components/FiltersModal/FiltersModal";
import {Divider} from "@material-ui/core";
import IconButton from "@ticketag/ticketag-uilib/src/components/base/Button/IconButton";
import { Chip } from '@material-ui/core';
import {withRouter} from "react-router-dom";

import Skeleton from '@material-ui/lab/Skeleton';

import moment from "moment";
import transitions from '@material-ui/core/styles/transitions';
import Header from "@ticketag/ticketag-uilib/src/components/base/Header/Header";
import Hidden from "@material-ui/core/Hidden";
import Layout from "../../hoc/Layout/Layout";
import Navbar from "../../components/Navbar/Navbar";
import NavTitle from "../../components/Navbar/NavTitle/NavTitle";
import Title from "../../components/Title/Title";
import {pages} from "../../constants";
import Page from "../../components/Page/Page";

const styles = (theme) => {
    return {
        root: {
            marginBottom: theme.spacing(6)
        },
        title: {
            marginBottom: theme.spacing(7),
        },
        totalTitle: {
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "24px"
        },
        incomingTitle: {
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "24px",
            marginBottom: theme.spacing(4),
            [theme.breakpoints.down('sm')]: {
                marginBottom: "36px",
            },
        },
        incomingSubtitle: {
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "24px",
            paddingLeft: "20px",
            marginBottom: theme.spacing(4),
            [theme.breakpoints.up('md')]: {
                marginBottom: "35px",
                paddingLeft: "0"
            },
        },
        icon: {
            color: theme.palette.text.primary,
            fontSize: "37px",
            marginRight: theme.spacing(1)
        },
        availableAmount: {
            [theme.breakpoints.down('sm')]: {
                marginBottom: theme.spacing(7),
            },
        },
        availableAmountTitle: {
            marginBottom: theme.spacing(4),
            marginLeft: 0
        },
        availableAmountText: {
            display: "inline-block",
        },
        withDrawButton: {
            minHeight: "40px",
            maxWidth: "230px",
            marginTop: theme.spacing(4),
            [theme.breakpoints.up('md')]: {
                marginTop: theme.spacing(2),
            },
            [theme.breakpoints.up('lg')]: {
                marginTop: 0,
                marginLeft: theme.spacing(2)
            },
        },
        modalDots: {
            borderBottom: "2px dotted",
            borderColor: theme.palette.text.primary,
            flex: 1,
            minHeight: "12px"
        },
        separator: {
            width: "100%",
            maxWidth: "300px",
        },
        tableServiceName: {
            textTransform: "capitalize"
        },
        tableInfoBox: {
            whiteSpace: "pre-wrap"
        },
        tableAmount: {
            fontSize: "16px",
        },
        tableDate: {
            fontSize: "14px"
        },
        tableFilterColumn: {
            marginRight: "70px"
        },
        tableFilters: {
            marginBottom: "10px"
        },
        tableFilterTitle: {
            fontSize: "16px",
            lineHeight: "28px",
            fontWeight: "600",
            textTransform: "none",
            marginBottom: "10px"
        },
        mobileComboBox: {
            maxWidth: "180px"
        },
        filterText: {
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "19px"
        },
        filterButton: {
            background: "none !important",
            boxShadow: "none !important",
            padding: "0"
        },
        filterChips: {
            display: 'flex',
            flexWrap: 'wrap',
            marginBottom: theme.spacing(3),
            '& > *': {
                margin: theme.spacing(1),
                marginLeft: "0"
            },
        },
        filterChip: {
            background: "#FFFFFF",
            border: "0.3px solid rgba(224, 224, 224, 0.3)",
            boxShadow: "0px 2px 4px rgb(0 0 0 / 4%), inset 1px 1px 1px #ffffff, inset 0px 0px 2px rgb(172 172 172 / 35%)",
            borderRadius: "4px",
            fontWeight: "500",
            fontSize: "14px"
        },
        filterChipLabel: {
            padding: "0 15px"
        },
        filterChipIcon: {
            fontSize: "15px",
            marginRight: "11px !important"
        },
        emptyTableTitle: {
            textAlign: "center",
            margin: "16px 0"
        }
    }
};

class Wallet extends Component {
    static contextType = AuthContext

    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            availableAmount: 0,
            totalAmount: 0,
            commissions: 200,
            transactions: [],
            userPreferences: null,
            filterValues: {
                type: "ALL",
                service: "ALL",
                date: "ALL"
            }
        };

        this.openWithDrawModal = this.openWithDrawModal.bind(this);
        this.openDetailsModal = this.openDetailsModal.bind(this);
        this.openTableFiltersModal = this.openTableFiltersModal.bind(this);
        this.requirePayout = this.requirePayout.bind(this);
        this.updateFilterValues = this.updateFilterValues.bind(this);
    }

    componentDidMount() {
        console.log(this.props)
        this.loadData();
    }

    openDetailsModal(transaction) {
        const cancelText = <T defaultVal="chiudi">modals.wallet.close</T>;
        this.props.modals.YesNo('Dettaglio operazione', <TransactionDetails transaction={transaction} i18n={this.props.i18n}/>, {
            size: 'sm',
            disableTypography: true,
            hideConfirm: true,
            cancelText,
        });
    }

    openTableFiltersModal() {
        this.props.modals.Form(<T defaultVal={"Filtri"}>pages.wallet.filter_modal_title</T>, <FiltersModal transactionTypes={this.transactionTypes}
                                                                                                           serviceOptions={this.serviceOptions}
                                                                                                           dataOptions={this.dataOptions}
                                                                                                           i18n={this.props.i18n}
                                                                                                           defaultValues={this.state.filterValues}
        />, {
            size: 'xs',
            submitText: <T defaultVal="Salva filtri">modals.wallet.filter_reset</T>,
            cancelText: <T defaultVal="Resetta filtri">modals.wallet.filter_apply</T>,
        })
            .then(data => {
                if(data.value) {
                    this.setState({
                        filterValues: {...data.value}
                    });
                }
            })
            .catch(() => {
                this.setState({
                    filterValues: {
                        type: "ALL",
                        service: "ALL",
                        date: "ALL"
                    }
                });
            })
            .finally(() => {

            });
    }

    openWithDrawModal() {
        let cancelText = <T defaultVal="cancella">modals.wallet.cancel</T>;
        const confirmText = <T defaultVal="invia">modals.wallet.confirm</T>;
        let hideConfirm = false;
        const availableAmount = this.state.availableAmount;
        const formattedAvailableAmount = availableAmount / 100;
        let totalAvailableAmount = availableAmount;
        const commissions = this.state.commissions;

        if (formattedAvailableAmount < 5) {
            hideConfirm = true;
            cancelText = <T defaultVal="chiudi">modals.wallet.close</T>;
        }

        if (formattedAvailableAmount >= 5 && formattedAvailableAmount < 20) {
            totalAvailableAmount -= commissions;
        }

        this.props.modals.YesNo('Payout', <Box>
            {
                formattedAvailableAmount >= 5 && formattedAvailableAmount < 20
                    ?
                    <Box mb={2} display="flex" flexDirection="column" alignItems="flex-end">
                        <Box display="flex">
                            <Box mr={2}>
                                <Typography variant={'subtitle1'} color={"textPrimary"}>
                                    <T defaultVal={'Saldo'}>pages.wallet.modal_balance</T>
                                </Typography>
                            </Box>
                            <Typography variant={'subtitle1'} semiBold color={'secondary'}>
                                {this.props.i18n.formatPrice(availableAmount)}  €
                            </Typography>
                        </Box>
                        <Box display="flex">
                            <Box mr={2}>
                                <Typography variant={'subtitle1'} color={"textPrimary"}>
                                    <T defaultVal={'Commissioni'}>pages.wallet.modal_commissions</T>
                                </Typography>
                            </Box>
                            <Typography variant={"subtitle1"} semiBold color={'error'}>
                                -{this.props.i18n.formatPrice(commissions)}  €
                            </Typography>
                        </Box>
                    </Box>
                    :
                    null
            }
            <Box display="flex" alignItems="center">
                <Box mr={2}>
                    <Icon color={"textPrimary"} style={{fontSize: 34}}>wallet</Icon>
                </Box>
                <Typography color={'textSecondary'} variant={'h6'} semiBold>
                    <T defaultVal={'Saldo Disponibile'}>pages.wallet.modal_available_amount_title</T>
                </Typography>
                <Box ml={2} classes={{root: this.props.classes.modalDots}}></Box>
                <Box ml={2}>
                    <Typography color={'error'} variant={'h6'} semiBold>
                        € { this.props.i18n.formatPrice(totalAvailableAmount) }
                    </Typography>
                </Box>
            </Box>
            {
                formattedAvailableAmount < 5
                    ?
                    <Box my={4}>
                        <Typography letterSpacing={"normal"} variant={'body2'}>
                            <T defaultVal={'Non è possibile ritirare il saldo se il totale disponibile è **inferiore a {{.args.Amount}}€.**'}
                               useTemplate
                               useMarkdown
                               args={{
                                   Amount: 5
                               }}>pages.wallet.less_then_five</T>
                        </Typography>
                    </Box>
                    :
                    null
            }
            {
                formattedAvailableAmount >= 5 && formattedAvailableAmount < 20
                    ?
                    <Box mt={2} mb={6}>
                        <Typography letterSpacing={"normal"} variant={'body2'}>
                            <T defaultVal={'Se il saldo è **maggiore di {{.args.Amount}}€** non paghi le commissioni!'}
                               useTemplate
                               useMarkdown
                               args={{
                                   Amount: 20
                               }}>pages.wallet.more_then_five</T>
                        </Typography>
                        <Box mt={6} display="flex" justifyContent="center">
                            <Divider classes={{root: this.props.classes.separator}} horizontal/>
                        </Box>
                    </Box>
                    :
                    null
            }
            {
                formattedAvailableAmount > 5
                    ?
                    <>
                        <Box mt={formattedAvailableAmount < 20 ? 6 : 4} mb={3}>
                            <Typography letterSpacing={"normal"} variant={'body2'}>
                                <T defaultVal={'Riceverai il saldo per mezzo bonifico entro 7 giorni lavorativi sull’IBAN da te indicato.'}>pages.wallet.shiptime</T>
                            </Typography>
                        </Box>
                        <PayoutData onContinue={({step, data}) => this.updatePayoutProfile(step, data)}
                                    onComplete={() => this.onPayoutProfileUpdated()}
                                    beforeOpen={() => this.loadIdDocuments()}
                                    modals={this.props.modals} i18n={this.props.i18n} verticalLayout={true}
                                    payoutProfile={this.state.userPreferences.payoutProfile}/>
                    </>
                    :
                    null
            }
        </Box>, {
            size: 'md',
            disableTypography: true,
            hideConfirm,
            cancelText,
            confirmText
        })
            .then(data => {
                if (data) {
                    this.requirePayout();
                }
            })
            .catch()
            .finally(() => {

            });
    }

    requirePayout() {
        this.setState({
            isLoaded: false,
        });

        withErrorModal(this.props.modals, this.props.api.walletApi.requirePayout({uuid: this.state.userPreferences.payoutProfile.uuid})).then(() => {
            this.loadData();
        }).catch((error) => {
            this.setState({
                isLoaded: false,
            });
        });
    }

    loadIdDocuments() {
        const userPreferences = this.state.userPreferences
        return new Promise(((resolve, reject) => {
            Promise.all(userPreferences.payoutProfile.documents.map(doc => this.props.api.userApi.getIdDocument(doc.uuid,{}))).then(documentsDetails => {
                const documents = userPreferences.payoutProfile.documents
                documents.splice(0, userPreferences.payoutProfile.documents.length)
                documents.push(...documentsDetails)
                this.setState({
                    userPreferences: userPreferences
                }, () => {
                    resolve(this.state.userPreferences.payoutProfile)
                })

            }).catch((err) => reject(err))
        }))
    }

    updateUserPreference(key, value) {
        const body = {}
        body[key] = value
        this.setState({isLoaded: false})
        return this.props.api.userApi.updateUserPreferences(body).then((data) => {
            this.setState({
                userPreferences: data,
                isLoaded:        true
            })
            return data
        }).catch(err => {
            this.setState({
                isLoaded: true
            })
        })
    }

    updatePayoutProfile(step, data) {
        const updatedProfile = {...this.state.userPreferences.payoutProfile, ...data}
        switch (step) {
            case 0:
                return this.updateUserPreference('payoutProfile', updatedProfile).then(resp => {
                    return resp.payoutProfile
                })
            case 1:
                return this.updateUserPreference('payoutProfile', updatedProfile).then(userPreferences => {
                    return null
                })
            case 2:
                const body = {...this.state.userPreferences.payoutProfile.documents[0] || {userProfileUuid: this.state.userPreferences.payoutProfile.uuid}, ...data}
                return this.props.api.userApi.updateIdDocument(body)
        }
    }

    onPayoutProfileUpdated() {
        this.setState({isLoaded: false});
        this.loadData();
    }

    loadUserPreferences() {
        return this.props.api.userApi.getUserPreferences();
    }

    loadUserWallet() {
        return this.props.api.walletApi.getWalletInfo();
    }

    loadWalletTransactions() {
        return this.props.api.walletApi.getWalletTransactions().then(
            (data) => ({transactions: data.transactions.map((elt) => Object.assign(elt,{date: elt._date}))}));
    }

    loadData() {
        return withRedirect(this.props.history, Promise.all([
            this.loadUserPreferences(),
            this.loadUserWallet(),
            this.loadWalletTransactions()
        ]).then(([userPreferences, wallet, transactions]) => {
            this.setState({
                userPreferences,
                availableAmount: wallet.availableAmount,
                totalAmount: wallet.totalAmount,
                transactions: transactions.transactions,
                isLoaded: true,
            });
        }))
    }

    get columns() {
        const columns = [
            {
                id: "icon",
                renderLabel:
                    ['xs', 'sm', 'md'].indexOf(this.props.width) !== -1
                        ?
                        <Box display="flex" alignItems="center">
                            <Button classes={{root: this.props.classes.filterButton}} onClick={this.openTableFiltersModal}>
                                <Icon color="secondary" size={'medium'}>filter</Icon>
                                <Typography color="secondary" classes={{root: this.props.classes.filterText}}>
                                    <T defaultVal={'Filtra'}>pages.wallet.filter_button_label</T>
                                </Typography>
                            </Button>
                        </Box>
                        :
                        <></>
                ,
                colSpan: ['xs', 'sm', 'md'].indexOf(this.props.width) !== -1 ? 3 : undefined
            },
            {
                id: "info",
                label: "Info",
                hide: ['xs', 'sm', 'md'].indexOf(this.props.width) !== -1
            },
            {
                id: "amount",
                label: "Importo",
                hide: ['xs', 'sm', 'md'].indexOf(this.props.width) !== -1,
                format: (params) => {
                    return <Typography color="secondary" classes={{root: this.props.classes.tableAmount}}>
                        {this.props.i18n.formatPrice(params)} €
                    </Typography>;
                },
            }
        ];

        if (['xs', 'sm', 'md'].indexOf(this.props.width) === -1) {
            columns.splice(2, 0, {
                id: "data",
                label: "Data",
                align: "center"
            });
        }

        if (['xs', 'sm', 'md'].indexOf(this.props.width) === -1) {
            columns.push({
                id: "action",
            });
        }

        return columns;
    }

    get iconByType() {
        return (type) => {
            if (type === "PAYOUT") {
                return <Icon style={{fontSize: 26}}>wallet</Icon>;
            }

            if (type === "SELL") {
                return <Icon style={{fontSize: 26}}>moneyin</Icon>;
            }

            if (type === "PURCHASE") {
                return <Icon style={{fontSize: 26}}>moneyout</Icon>;
            }
        }
    }

    get rows() {
        return this.filteredTransactions.map(transaction => ({
            icon: this.iconByType(transaction.type),
            info: <Box>
                {
                    ['xs', 'sm', 'md'].indexOf(this.props.width) !== -1
                        ?
                        <Typography variant={'caption'}>
                            {moment(transaction.date).format("DD/MM/YYYY")}
                        </Typography>
                        :
                        null
                }
                <Typography classes={{root: this.props.classes.tableServiceName}} color={'textSecondary'} variant={'subtitle1'} semiBold>
                    {transaction.serviceName}
                </Typography>
                {
                    ['xs', 'sm', 'md'].indexOf(this.props.width) !== -1
                        ?
                        <Typography variant={'subtitle2'}>
                            {
                                transaction.type === "PAYOUT"
                                    ?
                                    <>
                                        <T defaultVal={'Inviato'}>pages.wallet.payout_table</T>
                                    </>
                                    :
                                    null
                            }
                            {
                                transaction.type === "SELL"
                                    ?
                                    <T defaultVal={'Quota ricevuta'}>pages.wallet.sell_table</T>
                                    :
                                    null
                            }
                            {
                                transaction.type === "PURCHASE"
                                    ?
                                    <T defaultVal={'Quota inviata'}>pages.wallet.purchase_table</T>
                                    :
                                    null
                            }
                        </Typography>
                        :
                        <Typography color="textPrimary" variant={'subtitle2'}>
                            {
                                transaction.type === "PAYOUT"
                                    ?
                                    <Box classes={{root: this.props.classes.tableInfoBox}} display="flex">
                                        <T defaultVal={'Inviato a'}>pages.wallet.payout_to_table</T>: <Typography color="textSecondary" variant={'subtitle2'}>{transaction.toName}</Typography>
                                    </Box>
                                    :
                                    null
                            }
                            {
                                transaction.type === "SELL"
                                    ?
                                    <Box classes={{root: this.props.classes.tableInfoBox}} display="flex">
                                        <T defaultVal={'Quota ricevuta da'}>pages.wallet.sell_from_table</T>: <Typography color="textSecondary" variant={'subtitle2'}>{transaction.fromName}</Typography>
                                    </Box>
                                    :
                                    null
                            }
                            {
                                transaction.type === "PURCHASE"
                                    ?
                                    <Box classes={{root: this.props.classes.tableInfoBox}} display="flex">
                                        <T defaultVal={'Quota inviata a'}>pages.wallet.purchase_to_table</T>: <Typography color="textSecondary" variant={'subtitle2'}>{transaction.toName}</Typography>
                                    </Box>
                                    :
                                    null
                            }
                        </Typography>
                }
            </Box>,
            data: <Typography classes={{root: this.props.classes.tableDate}} color="textPrimary">
                {moment(transaction.date).format("DD/MM/YYYY")}
            </Typography>,
            amount: transaction.amount,
            action: <Box display="flex" justifyContent="flex-end">
                <IconButton style={{fontSize: 26}} color={'gradient'} onClick={() => this.openDetailsModal(transaction)}>lens</IconButton>
            </Box>
        }));
    }

    get filteredTransactions() {
        return this.state.transactions.filter((transaction) => {
            let condition = true;

            if (this.state.filterValues.type !== "ALL") {
                condition = condition && transaction.type === this.state.filterValues.type;
            }

            if (this.state.filterValues.service !== "ALL") {
                condition = condition && transaction.serviceName === this.state.filterValues.service;
            }

            if (this.state.filterValues.date !== "ALL") {
                switch (this.state.filterValues.date) {
                    case "WEEK":
                        condition = condition && moment(transaction.date).isSame(new Date(), 'week');
                        break;
                    case "MONTH":
                        condition = condition && moment(transaction.date).isSame(new Date(), 'month');
                        condition = condition && moment(transaction.date).isSame(new Date(), 'year');
                        break;
                    case "YEAR":
                        condition = condition && moment(transaction.date).isSame(new Date(), 'year');
                        break;
                    default:
                        break;
                }
            }

            return condition;
        });
    }

    get transactionTypes() {
        const types = this.state.transactions.map((transition) => transition.type);
        const uniqueTypes = [...new Set(types)].map((type, index) => {
            let label = '';
            let labelNoIndex = '';

            switch (type) {
                case 'PAYOUT':
                    label = <T key={index} defaultVal={'Payout'}>pages.wallet.type_payout_option</T>;
                    labelNoIndex = <T defaultVal={'Payout'}>pages.wallet.type_payout_option</T>;
                    break;
                case 'SELL':
                    label = <T key={index} defaultVal={'Vendita'}>pages.wallet.type_sell_option</T>;
                    labelNoIndex = <T defaultVal={'Vendita'}>pages.wallet.type_sell_option</T>;
                    break;
                case 'PURCHASE':
                    label = <T key={index} defaultVal={'Acquisto'}>pages.wallet.type_purchase_option</T>;
                    labelNoIndex = <T defaultVal={'Acquisto'}>pages.wallet.type_purchase_option</T>;
                    break;
                default:
                    break;
            }

            return ({
                value: type,
                label,
                labelNoIndex
            });
        });

        return [
            {
                value: 'ALL',
                label: <T defaultVal={'Tutto'}>pages.wallet.type_all_option</T>,
                labelNoIndex: <T defaultVal={'Tutto'}>pages.wallet.type_all_option</T>,
            },
            ...uniqueTypes
        ];
    }

    get serviceOptions() {
        const services = this.state.transactions.map((transition) => transition.serviceName);
        const uniqueServices = [...new Set(services)].map((service) => {
            return ({
                value: service,
                label: service
            });
        });

        return [
            {
                value: 'ALL',
                label: <T defaultVal={'Tutto'}>pages.wallet.service_all_option</T>
            },
            ...uniqueServices
        ];
    }

    get dataOptions() {
        return [
            {
                value: 'ALL',
                label: <T key={1} defaultVal={'Tutto'}>pages.wallet.date_all_option</T>,
                labelNoIndex: <T defaultVal={'Tutto'}>pages.wallet.date_all_option</T>
            },
            {
                value: 'WEEK',
                label: <T key={2} defaultVal={'Ultima Settimana'}>pages.wallet.date_week_option</T>,
                labelNoIndex: <T defaultVal={'Ultima Settimana'}>pages.wallet.date_week_option</T>
            },
            {
                value: 'MONTH',
                label: <T key={3} defaultVal={'Ultimo Mese'}>pages.wallet.date_month_option</T>,
                labelNoIndex: <T defaultVal={'Ultimo Mese'}>pages.wallet.date_month_option</T>
            },
            {
                value: 'YEAR',
                label: <T key={4} defaultVal={'Ultimo Anno'}>pages.wallet.date_year_option</T>,
                labelNoIndex: <T defaultVal={'Ultimo Anno'}>pages.wallet.date_year_option</T>
            },
        ];
    }

    updateFilterValues(values) {
        this.setState({
            filterValues: {...values}
        });
    }

    renderContent() {
        if (!this.state.isLoaded) {
            return <Grid container className={this.props.classes.root}>
                <Grid item xs={12} md={6}>
                    <Box classes={{root: this.props.classes.availableAmountTitle}} justifyContent={{xs: "flex-start", md: "unset"}} display='flex' alignItems='center'>
                        <Box display={{xs: "none", md: "block"}}>
                            <Skeleton width={40} height={40} style={{marginRight: "8px"}}/>
                        </Box>
                        <Typography classes={{root: this.props.classes.totalTitle}} color={'textSecondary'} variant={'h3'} semiBold>
                            <Skeleton width={120}/>
                        </Typography>
                    </Box>
                    <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent={{xs: "flex-start", md: "unset"}}>
                        <Box display={{md: "none"}}>
                            <Skeleton width={40} height={40} style={{marginRight: "8px"}}/>
                        </Box>
                        <Typography variant="h2" color="gradient">
                            <Skeleton width={120} style={{marginRight: "16px"}}/>
                        </Typography>
                        <Box display={{xs: "none", md: "block"}}>
                            <Skeleton width={150} height={40}/>
                        </Box>
                    </Box>
                    <Box mt={{xs: 4, sm: 4}}>
                        <Typography variant="subtitle1">
                            <Skeleton width={"100%"}/>
                        </Typography>
                    </Box>
                    <Box mb={{xs: 7, md: 0}} display={{xs: "flex", md: "none"}} justifyContent={{xs: "flex-start", md: "unset"}}>
                        <Skeleton width={150} height={40}/>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box ml={{xs: 0, md: 7, lg: 13}}>
                        <Typography classes={{root: this.props.classes.incomingTitle}} color={'textSecondary'} variant={'h4'}>
                            <Skeleton width={150}/>
                        </Typography>
                        <Box>
                            <Typography variant={'h3'} classes={{root: this.props.classes.incomingSubtitle}}>
                                <Skeleton width={120}/>
                            </Typography>
                        </Box>
                        <Box mt={{xs: 2, md: 4}}>
                            <Typography variant={'subtitle1'}>
                                <Skeleton width={"100%"}/>
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box mt={{xs: 8, md: 10}} mb={{xs: 4}}>
                        <Typography style={{fontSize: 20}} variant={'h4'} semiBold>
                            <Skeleton width={120}/>
                        </Typography>
                    </Box>
                    {
                        ['xs', 'sm', 'md'].indexOf(this.props.width) === -1
                            ?
                            <Grid classes={{root: this.props.classes.tableFilters}} container>
                                <Grid item xs={2} classes={{root: this.props.classes.tableFilterColumn}}>
                                    <Skeleton width={120}/>
                                </Grid>
                                <Grid item xs={2} classes={{root: this.props.classes.tableFilterColumn}}>
                                    <Skeleton width={120}/>
                                </Grid>
                                <Grid item xs={2} classes={{root: this.props.classes.tableFilterColumn}}>
                                    <Skeleton width={120}/>
                                </Grid>
                            </Grid>
                            :
                            null
                    }
                    <Box>
                        <Skeleton width={"100%"}/>
                        <Skeleton width={"100%"}/>
                        <Skeleton width={"100%"}/>
                        <Skeleton width={"100%"}/>
                    </Box>
                </Grid>
            </Grid>
        }

        const labelType = this.transactionTypes.find(type => type.value === this.state.filterValues.type).labelNoIndex;
        const labelDate = this.dataOptions.find(type => type.value === this.state.filterValues.date).labelNoIndex;
        return <Grid container className={this.props.classes.root}>
            <Grid classes={{root: this.props.classes.availableAmount}} item xs={12} md={5}>
                <Box classes={{root: this.props.classes.availableAmountTitle}} justifyContent={{xs: "flex-start", md: "unset"}} display='flex' alignItems='center'>
                    {
                        this.props.width === "xs" || this.props.width === "sm" ?
                            null : <Icon color={"textPrimary"} classes={{root: this.props.classes.icon}}>wallet</Icon>
                    }
                    <Typography classes={{root: this.props.classes.totalTitle}} color={'textSecondary'} variant={'h3'} semiBold>
                        <T defaultVal={'Saldo Disponibile'}>pages.wallet.available_amount_title</T>
                    </Typography>
                </Box>
                <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent={{xs: "flex-start", md: "unset"}}>
                    <Box display={{md: "none"}}>
                        <Icon color={"textPrimary"} classes={{root: this.props.classes.icon}}>wallet</Icon>
                    </Box>
                    <Typography variant="h2" color="gradient" className={this.props.classes.availableAmountText}>
                        <svg
                            style={{display: "inline-block", width: "120px", height: "42px", fontSize: "36px"}}
                            focusable="false" viewBox="0 0 120 42">
                            <defs>
                                <linearGradient id="wallet_color_gradient" x1="0%" y1="0%" x2="100%" y2="0%" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#4DBFB3"></stop><stop offset="100%" stop-color="#C183FF"></stop></linearGradient>
                            </defs>
                            <g><text fill={"url(#wallet_color_gradient)"} x={0} y={36}>
                                € { this.props.i18n.formatPrice(this.state.availableAmount) }
                            </text></g>
                        </svg>
                    </Typography>
                    <Box display={{xs: "none", md: "block"}}>
                        <Button fixedWidth variant="outlined" color="gradient" classes={{root: this.props.classes.withDrawButton}} onClick={this.openWithDrawModal}>
                            <T defaultVal="Preleva">global.buttons.withdraw</T>
                        </Button>
                    </Box>
                </Box>
                <Box mt={{xs: 4, sm: 4}}>
                    <Typography variant="body2"  color={"textPrimary"}>
                        <T defaultVal="Questi sono i fondi disponibili per il prelievo.">pages.wallet.available_amount_subtitle</T>
                    </Typography>
                </Box>
                <Box display={{xs: "flex", md: "none"}} justifyContent={{xs: "flex-start", md: "unset"}}>
                    <Button fixedWidth variant="outlined" color="gradient" classes={{root: this.props.classes.withDrawButton}} onClick={this.openWithDrawModal}>
                        <T defaultVal="Preleva">global.buttons.withdraw</T>
                    </Button>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box ml={{xs: 0, md: 7, lg: 13}}>
                    <Typography classes={{root: this.props.classes.incomingTitle}} color={'textSecondary'} variant={'h4'}>
                        <T defaultVal={'Fondi in Arrivo'}>pages.wallet.incoming_amount_title</T>
                    </Typography>
                    <Box>
                        <Typography variant={'h3'} classes={{root: this.props.classes.incomingSubtitle}}>
                            € { this.props.i18n.formatPrice(this.state.totalAmount) }
                        </Typography>
                    </Box>
                    <Box mt={{xs: 2, md: 4}}>
                        <Typography variant={"body2"} color={"textPrimary"}>
                            <T defaultVal={'Questi fondi sono bloccati e saranno presto disponobili per il prelievo.'}>pages.wallet.incoming_amount_subtitle</T>
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box mt={{xs: 8, md: 10}} mb={{xs: 4}}>
                    <Typography style={{fontSize: 20}} variant={'h4'} semiBold>
                        <T defaultVal={'Elenco Movimenti'}>pages.wallet.transaction_title</T>
                    </Typography>
                </Box>
                {
                    ['xs', 'sm', 'md'].indexOf(this.props.width) === -1
                        ?
                        <Grid classes={{root: this.props.classes.tableFilters}} container>
                            <Grid item xs={2} classes={{root: this.props.classes.tableFilterColumn}}>
                                <ComboBox
                                    fullWidth
                                    options={this.transactionTypes}
                                    variant={'shadowed'}
                                    getOptionLabel={(option) => option.label}
                                    label={<Typography classes={{root: this.props.classes.tableFilterTitle}} color="textSecondary">
                                        <T defaultVal={'Tipo di movimento'}>pages.wallet.type_filter_title</T>
                                    </Typography>}
                                    defaultValue={this.state.filterValues.type}
                                    onChange={(_, value) => {
                                        this.updateFilterValues({
                                            ...this.state.filterValues,
                                            type: value
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2} classes={{root: this.props.classes.tableFilterColumn}}>
                                <ComboBox
                                    fullWidth
                                    options={this.serviceOptions}
                                    variant={'shadowed'}
                                    getOptionLabel={(option) => option.label}
                                    label={<Typography classes={{root: this.props.classes.tableFilterTitle}} color="textSecondary">
                                        <T defaultVal={'Servizio'}>pages.wallet.service_filter_title</T>
                                    </Typography>}
                                    value={this.state.filterValues.service}
                                    onChange={(_, value) => {
                                        this.updateFilterValues({
                                            ...this.state.filterValues,
                                            service: value
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2} classes={{root: this.props.classes.tableFilterColumn}}>
                                <ComboBox
                                    fullWidth
                                    options={this.dataOptions}
                                    variant={'shadowed'}
                                    getOptionLabel={(option) => option.label}
                                    label={<Typography classes={{root: this.props.classes.tableFilterTitle}} color="textSecondary">
                                        <T defaultVal={'Data'}>pages.wallet.date_filter_title</T>
                                    </Typography>}
                                    defaultValue={this.state.filterValues.date}
                                    onChange={(_, value) => {
                                        this.updateFilterValues({
                                            ...this.state.filterValues,
                                            date: value
                                        });
                                    }}
                                />
                            </Grid>
                        </Grid>
                        :
                        <>
                            {
                                this.state.filterValues.type !== "ALL" ||
                                this.state.filterValues.service !== "ALL" ||
                                this.state.filterValues.date !== "ALL"
                                    ?
                                    <Box classes={{root: this.props.classes.filterChips}}>
                                        {
                                            this.state.filterValues.type !== "ALL"
                                                ?
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    label={labelType}
                                                    classes={{root: this.props.classes.filterChip, label: this.props.classes.filterChipLabel}}
                                                    onDelete={() => {
                                                        this.updateFilterValues({
                                                            ...this.state.filterValues,
                                                            type: "ALL"
                                                        });
                                                    }}
                                                    deleteIcon={<Icon classes={{root: this.props.classes.filterChipIcon}}>closechip</Icon>}
                                                />
                                                :
                                                null
                                        }
                                        {
                                            this.state.filterValues.service !== "ALL"
                                                ?
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    label={this.state.filterValues.service}
                                                    classes={{root: this.props.classes.filterChip, label: this.props.classes.filterChipLabel}}
                                                    onDelete={() => {
                                                        this.updateFilterValues({
                                                            ...this.state.filterValues,
                                                            service: "ALL"
                                                        });
                                                    }}
                                                    deleteIcon={<Icon classes={{root: this.props.classes.filterChipIcon}}>closechip</Icon>}
                                                />
                                                :
                                                null
                                        }
                                        {
                                            this.state.filterValues.date !== "ALL"
                                                ?
                                                <Chip
                                                    variant="outlined"
                                                    size="medium"
                                                    label={labelDate}
                                                    classes={{root: this.props.classes.filterChip, label: this.props.classes.filterChipLabel}}
                                                    onDelete={() => {
                                                        this.updateFilterValues({
                                                            ...this.state.filterValues,
                                                            date: "ALL"
                                                        });
                                                    }}
                                                    deleteIcon={<Icon classes={{root: this.props.classes.filterChipIcon}}>closechip</Icon>}
                                                />
                                                :
                                                null
                                        }
                                    </Box>
                                    :
                                    null
                            }
                        </>
                }
                <Box>
                    <Table rows={this.rows} columns={this.columns} onRowClick={(_, index) => {
                        if (this.state.transactions.length > 0 && index < this.state.transactions.length) {
                            const transaction = this.state.transactions[index];
                            this.openDetailsModal(transaction);
                        }
                    }} emptyRow={<Typography classes={{root: this.props.classes.emptyTableTitle}} color="textSecondary">
                        <T defaultVal={'Non ci sono risultati'}>pages.wallet.table_no_results</T>
                    </Typography>}/>
                </Box>
            </Grid>
        </Grid>
    }

    render() {
        const {
            title,
            ...other
        } = this.props;
        return (
            <Page {...other}>
                <Hidden xsDown>
                    <Title isLoading={!this.state.isLoaded}>{title}</Title>
                </Hidden>
                {this.renderContent()}
            </Page>
        )
    }
}

export default withStyles(styles, {
    useTheme:  true,
    withTheme: true
})(withApi(withWidth()(withRouter(withModals(withI18n(Wallet))))));
