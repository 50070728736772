import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs'; // We can inject some CSS into the DOM.

const styles = theme => ({
  root: {},
  indicator: {
    transition: "none!important"
  }
});

function TTabs(props) {
  const {
    children,
    ref,
    ...other
  } = props;
  return <Tabs ref={ref} TabIndicatorProps={{
    style: {
      display: "none"
    }
  }} {...other} componentname="t-tabs">{children}</Tabs>;
}

TTabs.propTypes = Tabs.propTypes;
export default withStyles(styles, {
  useTheme: true
})(TTabs);