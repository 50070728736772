import React, {Component} from 'react';
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import {withStyles} from "@material-ui/core/styles";
import {withApi} from "../../hoc/Api/Provider";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {Button, Hidden} from "@material-ui/core";
import Container from "@ticketag/ticketag-uilib/src/components/base/Container/Container";
import {withAuth} from "../../hoc/Auth/Provider";
import {withI18n} from "@ticketag/i18nlib";
import Noisy from "../../services/noisy/noisy";
import {withRouter} from 'react-router-dom';
import Box from "@material-ui/core/Box";
import BottomNavigation from "../../components/Navbar/BottomNavigation/BottomNavigation";
import clsx from "clsx";

const styles = (theme) => {
    return {
        root: {},
        main: {
            marginBottom: "50px",
            minHeight: "50vh"
        }
    }

}

class DefaultLayout extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        const {
            children,
            classes,
            className,
            navbar,
            title,
            logo,
            fullWidth = false,
            auth,
        } = this.props;
        const userInfo = auth.getUserInfo()
        const isAuthenticated = auth.isAuthenticated()
        return (
            <>
                {
                    navbar || <Navbar logo={logo} title={title}/>
                }
                <main className={classes.main}>
                    <Container fullWidth={fullWidth}>
                        {
                            children
                        }
                    </Container>
                </main>
                {isAuthenticated ?
                    <>
                        <Hidden smDown>
                            <Footer/>
                        </Hidden>
                        <Hidden smUp>
                            {
                                isAuthenticated && userInfo.complete ?
                                    <BottomNavigation id={"bottom-navigation"}/> : null
                            }
                        </Hidden>
                    </>
                    :
                    <Footer/>
                }
            </>
        )
    }

}

const withNoise = (Component) => (props) => {
    const {
        showNoise,
        ...other
    } = props;
    const isMd = useMediaQuery(theme => theme.breakpoints.up('lg'));
    showNoise ? Noisy.showNoise(isMd ? 'md' : 'xs') : Noisy.hideNoise()
    return <Component {...other}/>
}


export default withStyles(styles, {useTheme: true, name: "DefaultLayout"})(withRouter(withNoise(withAuth(withApi(withI18n(DefaultLayout))))));
