import React from 'react';
import clsx from 'clsx';
import {withStyles} from '@material-ui/core/styles';
import Button from './Button';
import Typography from '../Typography/Typography';
import capitalize from "@material-ui/core/utils/capitalize";


// We can inject some CSS into the DOM.
const styles = (theme) => {
    return {
        root: {
            color: theme.palette.text.disabled,
            cursor: 'pointer',
            fontSize: '16px',
            fontWeight: 500,
            fontFamily: theme.typography.fontFamily
        },
        disabled: {
            pointerEvents: 'none',
            color: theme.palette.text.disabled,
            cursor: 'ban'
        },
        upperCase: {
            textTransform: 'uppercase'
        },
        colorDefault: {
            color: theme.palette.text.primary
        },
        colorPrimary: {
            color: theme.palette.primary.main
        },
        colorSecondary: {
            color: theme.palette.secondary.main
        },
        colorError: {
            color: theme.palette.error.main
        },
        colorGradient: {
            '-webkit-text-fill-color': 'transparent',
            '-webkit-background-clip': 'text',
            background: theme.palette.default_gradient || theme.palette.secondary.main,
            fontWeight: 600,
        },
        sizeDefault: {},
        sizeSmall: {
            fontSize: '10px'
        },
        sizeLarge: {
            fontSize: '16px',
            '& svg': {
                marginBottom: '-6px'
            }
        },
    }
};


function TextButton(props) {
    const {
        classes, className, onClick, upperCase = false, variant = 'default', disabled, color="default", size = 'default', children,
        ...other} = props;
    const useClasses = [classes.root]
    //
    useClasses.push(className)
    if (disabled) {
        useClasses.push(classes.disabled)
    } else {
        useClasses.push(classes[`color${capitalize(variant)}`])
        useClasses.push(classes[`color${capitalize(color)}`])
    }
    useClasses.push(classes[`size${capitalize(size)}`])
    return (
        <Typography
            component={"label"} variant={variant} onClick={disabled ? null : onClick}
            className={clsx(...useClasses, {[classes.upperCase]: upperCase})}  {...other}
        >{children}</Typography>
    );
}

TextButton.propTypes = Button.propTypes

export default withStyles(styles, {useTheme: true})(TextButton);
