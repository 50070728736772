
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AccordionDetails from "@material-ui/core/AccordionDetails";

const styles = (theme) =>  {
    return {
        root: {
            padding: "0 16px 10px",
            wordWrap: "break-word",
            lineHeight: "24px",
            fontSize: "14px",
            fontWeight: "500",
        }
    }
}

function TAccordionDetails(props) {
    const {children, ...other} = props;
    return (
        <AccordionDetails {...other}>{children}</AccordionDetails>
    );
}

TAccordionDetails.propTypes = AccordionDetails.propTypes
export default withStyles(styles, {name: "MuiAccordionDetails"})(TAccordionDetails);
