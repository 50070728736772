/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';
import {WebapiprotoBillingCycle} from './WebapiprotoBillingCycle';
import {WebapiprotoServiceData} from './WebapiprotoServiceData';

/**
 * The WebapiprotoService model module.
 * @module model/WebapiprotoService
 * @version v0.4
 */
export class WebapiprotoService {
  /**
   * Constructs a new <code>WebapiprotoService</code>.
   * @alias module:model/WebapiprotoService
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>WebapiprotoService</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/WebapiprotoService} obj Optional instance to populate.
   * @return {module:model/WebapiprotoService} The populated <code>WebapiprotoService</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new WebapiprotoService();
      if (data.hasOwnProperty('uuid'))
        obj.uuid = ApiClient.convertToType(data['uuid'], 'String');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('description'))
        obj.description = ApiClient.convertToType(data['description'], 'String');
      if (data.hasOwnProperty('slug'))
        obj.slug = ApiClient.convertToType(data['slug'], 'String');
      if (data.hasOwnProperty('scopes_available'))
        obj.scopesAvailable = ApiClient.convertToType(data['scopes_available'], ['String']);
      if (data.hasOwnProperty('data'))
        obj.data = WebapiprotoServiceData.constructFromObject(data['data']);
      if (data.hasOwnProperty('logo'))
        obj.logo = ApiClient.convertToType(data['logo'], 'String');
      if (data.hasOwnProperty('best_price'))
        obj.bestPrice = ApiClient.convertToType(data['best_price'], 'String');
      if (data.hasOwnProperty('billing_cycle'))
        obj.billingCycle = WebapiprotoBillingCycle.constructFromObject(data['billing_cycle']);
    }
    return obj;
  }
}

/**
 * @member {String} uuid
 */
WebapiprotoService.prototype.uuid = undefined;

/**
 * @member {String} name
 */
WebapiprotoService.prototype.name = undefined;

/**
 * @member {String} description
 */
WebapiprotoService.prototype.description = undefined;

/**
 * @member {String} slug
 */
WebapiprotoService.prototype.slug = undefined;

/**
 * @member {Array.<String>} scopesAvailable
 */
WebapiprotoService.prototype.scopesAvailable = undefined;

/**
 * @member {module:model/WebapiprotoServiceData} data
 */
WebapiprotoService.prototype.data = undefined;

/**
 * @member {String} logo
 */
WebapiprotoService.prototype.logo = undefined;

/**
 * @member {String} bestPrice
 */
WebapiprotoService.prototype.bestPrice = undefined;

/**
 * @member {module:model/WebapiprotoBillingCycle} billingCycle
 */
WebapiprotoService.prototype.billingCycle = undefined;


