/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';
import {WebapiprotoAvatar} from './WebapiprotoAvatar';

/**
 * The WebapiprotoAvatars model module.
 * @module model/WebapiprotoAvatars
 * @version v0.4
 */
export class WebapiprotoAvatars {
  /**
   * Constructs a new <code>WebapiprotoAvatars</code>.
   * @alias module:model/WebapiprotoAvatars
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>WebapiprotoAvatars</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/WebapiprotoAvatars} obj Optional instance to populate.
   * @return {module:model/WebapiprotoAvatars} The populated <code>WebapiprotoAvatars</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new WebapiprotoAvatars();
      if (data.hasOwnProperty('count'))
        obj.count = ApiClient.convertToType(data['count'], 'Number');
      if (data.hasOwnProperty('avatars'))
        obj.avatars = ApiClient.convertToType(data['avatars'], [WebapiprotoAvatar]);
    }
    return obj;
  }
}

/**
 * @member {Number} count
 */
WebapiprotoAvatars.prototype.count = undefined;

/**
 * @member {Array.<module:model/WebapiprotoAvatar>} avatars
 */
WebapiprotoAvatars.prototype.avatars = undefined;


