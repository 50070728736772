import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from "../Typography/Typography";
import clsx from "clsx";
import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";

const styles = theme => {
  return {
    root: {
      display: "flex",
      color: theme.palette.text.secondary
    },
    label: {
      fontWeight: "600",
      fontSize: "16px",
      color: theme.palette.text.primary,
      lineHeight: "32px"
    },
    variantButton: {
      fontSize: "16px",
      fontWeight: 500,
      cursor: "pointer"
    }
  };
};

function TField(props) {
  const {
    children,
    icon,
    variant,
    classes,
    className,
    onClick,
    color,
    isLoading,
    ...other
  } = props;
  return <div className={clsx(classes.root, className)} componentname="t-field">
            {icon}
            <div>
                <Typography variant={"h6"} className={clsx(classes.label)}>{isLoading ? <Skeleton width={140} /> : props.label}</Typography>
                <Typography style={{
        lineHeight: "1.1876em",
        minHeight: "1.876em"
      }} variant={"body1"}>{isLoading ? <Skeleton width={90} /> : props.value}</Typography>
                <Typography style={{
        marginTop: "3px"
      }} variant={"body2"} onClick={onClick} color={color} className={clsx({
        [classes.variantButton]: variant === "button"
      })}>{props.helperText ? isLoading ? <Skeleton width={110} /> : props.helperText : null}</Typography>
            </div>
        </div>;
}

export default withStyles(styles, {
  name: "MuiField"
})(TField);
