import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T, withI18n} from "@ticketag/i18nlib";
import AuthContext from "../../hoc/Auth/context";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import ChatTab from "@ticketag/ticketag-uilib/src/components/complex/Chat/ChatTab";
import ChipCard from "@ticketag/ticketag-uilib/src/components/complex/Chat/ChatChipCard";
import {withRouter} from 'react-router-dom'
import Hidden from "@material-ui/core/Hidden";
import BottomNavigation from "../../components/Navbar/BottomNavigation/BottomNavigation";
import Progress from "@ticketag/ticketag-uilib/src/components/base/Progress/Progress";
import {withApi} from "../../hoc/Api/Provider";
import {withChat} from "../../hoc/Chat/Provider";
import Data from "../../data";
import Chat from "../../components/Chat/Chat";
import MenuItem from "@ticketag/ticketag-uilib/src/components/base/Menu/MenuItem";
import {Link as RouterLink} from "react-router-dom";
import clsx from "clsx";
import {Skeleton} from "@material-ui/lab";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import {withAuth} from "../../hoc/Auth/Provider";
import {withRedirect} from "../../components/ErrorMessage/ErrorMessage";
import Layout from "../../hoc/Layout/Layout";
import Navbar from "../../components/Navbar/Navbar";
import NavTitle from "../../components/Navbar/NavTitle/NavTitle";
import {pages} from "../../constants";
import Page from "../../components/Page/Page";


const styles = (theme) => {
    return {
        title: {
            fontSize: "42px",
            fontWeight: "600",
            textAlign: "center"
        },
        titleBox: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        root: {
            display: "flex",
            justifyContent: "center",
            marginTop: "32px",
            marginBottom: "88px",
            width: "100%"
        },
        opened: {
            [theme.breakpoints.up("sm")]: {
                justifyContent: "flex-start",
            }
        }
    }
};

const GroupTypeCode = 0
const CustomerCareTypeCode = 1

class TChat extends Component {
    static contextType = AuthContext
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            openedChat: null
        }
    }
    componentDidMount() {
        withRedirect(this.props.history, Promise.all([this.props.chatApi.GetBaseInfos(), this.loadServiceFamilies(), this.loadGroups()]).
        then(([chats, serviceFamilies, groups]) => {
            const allGroups = [...groups.owned, ...groups.joined]
            this.helpChat = chats.find((elt) => elt.type === CustomerCareTypeCode)
            this.otherChats = chats.filter((elt) => elt.type === GroupTypeCode).
            map((chat) => {
                const group = allGroups.find((group) => group.uuid === chat.uuid)
                const serviceFamily = serviceFamilies.find((svc) => svc.uuid === group.serviceFamilyUuid)
                return Object.assign(chat, {group: group, serviceFamily: serviceFamily})
            })
            const groupUuid = this.props.match.params.groupUuid
            const openedChat = this.otherChats.find((elt) => elt.uuid === groupUuid) || (this.otherChats.length > 0 ? null : this.helpChat)
            this.setState({
                isLoaded: true,
                openedChat: openedChat ? openedChat : (this.props.match.params.groupUuid === "help" ? this.helpChat : null)
            })
        }).catch((err) => {
            console.log(err)
        }))
    }
    loadServiceFamilies() {
        return this.props.api.GetServiceFamilies()
    }
    loadGroups() {
        return this.props.api.userApi.getUserGroups()
    }
    onClose(e) {
        this.setState({openedChat: null});
        window.history.replaceState(null, '', this.props.i18n.localizedUrl("/chat"));
    }
    onTabSelected(e, value) {
        this.setState({isLoaded: false})
        if (value === "help") {
            this.setState({isLoaded: true, openedChat: this.helpChat})
            window.history.replaceState(null, '', this.props.i18n.localizedUrl("/chat"));
        } else {
            const openedChat = this.otherChats.find((elt) => elt.uuid === value)
            this.setState({isLoaded: true, openedChat: openedChat})
            window.history.replaceState(null, '', this.props.i18n.localizedUrl("/chat/" + value));
        }
    }

    renderContent() {
        const data = Data.Chat
        const classes = this.props.classes
        const openedChat = this.state.openedChat
        const selectedTab = openedChat ? (openedChat === this.helpChat ? "help" : openedChat.uuid) : null

        return (
            <React.Fragment>
                <div className={clsx(classes.root, openedChat? classes.opened : null )}>
                    <div className={classes.titleBox}>
                        {
                            this.getChatTab(selectedTab, data)
                        }
                    </div>
                    {openedChat ?
                        React.createElement(Chat, {
                            key: openedChat.uuid,
                            chat: openedChat,
                            title: openedChat === this.helpChat ? data.helpChat.title : openedChat.name,
                            avatar: <img style={{width: "60px", height: "60px"}} src={openedChat === this.helpChat ?
                                `${process.env.PUBLIC_URL || ""}${data.helpChat.logo}` : openedChat.serviceFamily.logo
                            } alt={"logo"}/>,
                            onClose: this.onClose.bind(this),
                            onOpen: this.onClose.bind(this),
                            menuActions: openedChat !== this.helpChat ? Object.entries({
                                goToGroup: {title: data.menu.goToGroup, href: this.props.i18n.localizedUrl(`/groups/${openedChat.uuid}/details`)},
                                goToHelp: {title: data.menu.goToHelp, href: this.props.i18n.localizedUrl("/chat/help")},
                            }).map(([key, val]) => (
                                <MenuItem key={key} component={RouterLink} to={val.href}>{val.title}</MenuItem>
                            )) : null,
                        }, null) : null
                    }
                </div>
                <Hidden smUp>
                    { !openedChat ? <BottomNavigation/> :  null}
                </Hidden>
            </React.Fragment>
        )
    }


    getChatTab(selectedTab, data) {
        return <React.Fragment>
            <Hidden xsDown>
                {
                    this.state.isLoaded ?
                        <Typography gutterBottom align={"center"} color={"textSecondary"} className={this.props.classes.title}
                                    variant={"h1"}>{Data.Chat.title}</Typography>
                        :   <Skeleton className={this.props.classes.title} height={60} width={100}/>
                }
            </Hidden>
            <ChatTab value={selectedTab} animated={!!selectedTab} variant={"small"} onChange={(e, newValue) => {
                this.onTabSelected(e, newValue)
            }}>
                {
                    this.state.isLoaded ?
                        this.otherChats.map((chat) => (
                            <ChipCard
                                unreadMessages={this.props.chatApi.GetUnreadCount(chat.uuid)}
                                key={chat.uuid}
                                title={chat.serviceFamily.name} subtitle={
                                chat.group.ownerName ?
                                    <span>{data.tabs.admin} : {chat.group.ownerName}</span> : data.tabs.youAreAdmin
                            }
                                avatar={<img style={{width: "52px", height: "52px"}} src={chat.serviceFamily.logo}
                                             alt={"logo"}/>}
                                serviceName={chat.uuid}
                            />
                        )) :
                        this.props.chatApi.chats.map((chat) => <ChipCard isLoading/>)
                }
                {
                    this.state.isLoaded ?
                        <ChipCard
                            unreadMessages={this.props.chatApi.GetUnreadCount(this.props.chatApi.helpChat().uuid)}
                            variant={"small"} title={data.helpChat.title}
                                  avatar={<img src={`${process.env.PUBLIC_URL || ""}${data.helpChat.logo}`} alt={"help_logo"}/>}
                                  subtitle={data.helpChat.subtitle}
                                  serviceName={"help"}/>
                        : null
                }
            </ChatTab>
        </React.Fragment>;
    }

    render() {
        const {
            ...other
        } = this.props;
        return (
            <Page {...other}>
                {this.renderContent()}
            </Page>
        )
    }
}


export default withStyles(styles, {useTheme: true})(withAuth(withChat(withI18n(withApi(withRouter(TChat))))));

