import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T, withI18n} from "@ticketag/i18nlib";
import Progress from "@ticketag/ticketag-uilib/src/components/base/Progress/Progress";
import {withApi} from "../../hoc/Api/Provider";
import AuthContext from "../../hoc/Auth/context";
import {withErrorModal, withRedirect} from "../../components/ErrorMessage/ErrorMessage";
import NewGroupHeader from "../../components/NewGroupHeader/NewGroupHeader";
import Grid from "@material-ui/core/Grid";
import {Link as RouterLink} from "react-router-dom";
import TextButton from "@ticketag/ticketag-uilib/src/components/base/Button/TextButton";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import Breadcrumbs from "@ticketag/ticketag-uilib/src/components/base/Breadcrumbs/Breadcrumbs";
import Box from "@material-ui/core/Box";
import {CloseGroupRequested, ConfirmCloseGroup, NotImplemented} from "../../hoc/Modals/Modals";
import {withModals} from "@ticketag/ticketag-uilib/src/components/hoc/Modals/Provider";
import Form from "@ticketag/ticketag-uilib/src/components/complex/Form/Form";
import ComboBox from "@ticketag/ticketag-uilib/src/components/base/Select/ComboBox";
import NewGroupDataForm from "../../components/NewGroupDataForm/NewGroupDataForm";
import Button from "@ticketag/ticketag-uilib/src/components/base/Button/Button";
import Alert from "@ticketag/ticketag-uilib/src/components/base/Alert/Alert";
import PasswordTextField from "@ticketag/ticketag-uilib/src/components/base/TextField/PasswordTextField";
import Header from "@ticketag/ticketag-uilib/src/components/base/Header/Header";
import {Icon} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";
import {Hidden} from "@material-ui/core";
import Layout from "../../hoc/Layout/Layout";
import Page from "../../components/Page/Page";
import Navbar from "../../components/Navbar/Navbar";
import NavTitle from "../../components/Navbar/NavTitle/NavTitle";
import {pages} from "../../constants";
import {SeoDescription, SeoTitle} from "../Seo/Seo";


const styles = (theme) => {
    return {
        root: {}
    }
};

class GroupSettings extends Component {
    static contextType = AuthContext

    constructor(props) {
        super(props);
        this.state = {
            isLoaded:         false,
            formValues:       {},
            serviceType:      null,
            service:          null,
            group:            null,
            showSuccessAlert: false,
        }
        this._isMounted = false
    }

    get groupUuid() {
        return this.props.match.params.groupUuid
    }

    get pageTitle() {
        return <T defaultVal="Impostazioni">pages.group_settings.title</T>
    }

    get groupDetailsUrl() {
        return this.props.i18n.buildLocalizedUrl('groups', this.state.group.uuid, 'details')
    }

    componentDidMount() {
        this._isMounted = true
        this.loadData()
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    stateFromGroup(group) {
        return {
            group:      group,
            formValues: {
                size:             group.size,
                billingCycle:     group.serviceDetails.uuid,
                scope:            group.scope,
                showSuccessAlert: true,
            }
        }
    }

    saveSettings() {
        this.form.trigger().then(isValid => {
            const body = this.form.getValues()
            this.setState({
                isLoaded: false,
                isSaving: true
            })
            withErrorModal(this.props.modals, this.props.api.groupApi.updateGroup(this.state.group.uuid, body).then(resp => {
                this.setState({
                    isLoaded: true,
                    ...this.stateFromGroup(resp)
                })
                this.props.modals.OkOnly(
                    <T capitalize defaultVal="salvataggio completato">modals.group_settings_updated.title</T>,
                    <T capitalize defaultVal="impostazioni salvate con successo">modals.group_settings_updated.text</T>,
                    {}).finally(() => {
                        this.setState({
                            isSaving: false
                        })
                        this.props.history.push(this.groupDetailsUrl)
                    })
            }))
        })
    }

    closeGroup() {
        const membersCount = this.state.group.members.length - 1
        ConfirmCloseGroup(this.props.modals).then(({confirm}) => {
            this.setState({isLoading: true})
            withErrorModal(this.props.modals,
                this.props.api.CloseGroup(this.state.group.uuid, confirm, membersCount).then((event) => {
                    if (event.eventId === 'group.close_requested') {
                        CloseGroupRequested(this.props.modals).then(() => {
                            this.props.history.push(this.groupDetailsUrl)
                        })
                    } else {
                        this.props.history.push(this.props.i18n.buildLocalizedUrl('groups'))
                    }

                })
            )
        })
    }

    loadData() {
        return withRedirect(this.props.history, Promise.all([
                this.props.api.groupApi.groupDetails(this.groupUuid),
            ]).then(([group]) => {
                Promise.all([
                    this.props.api.GetServiceFamily(group.serviceFamilyUuid),
                    this.props.api.serviceApi.getFamilyServices(group.serviceFamilyUuid)
                ]).then(([family, services]) => {
                    const serviceType = services.services.find((elt) => elt.uuid === group.serviceDetails.serviceTypeUuid)
                    console.log(serviceType)
                    this.setState({
                        isLoaded:      true,
                        serviceFamily: family,
                        serviceType: serviceType,
                        ...this.stateFromGroup(group)
                    })
                })
            })
        )
    }

    renderContent() {
        if (!this.state.isLoaded) {
            return <Grid container justify={"center"}><Progress/></Grid>
        }

        return <Grid container justify={"center"}>
            <Grid xs={12} md={8} item>
                <Hidden xsDown>
                    <Header
                        avatar={this.state.serviceFamily.logo}
                        label={
                            <TextButton onClick={() => this.closeGroup()} size="large" variant="error"><T defaultVal={"Chiudi gruppo"}>pages.group_settings.close_group_headr_button</T></TextButton>
                        }
                        breadcrumbs={
                            <Breadcrumbs
                                linksComponent={RouterLink}
                                links={
                                    [
                                        {url: this.props.i18n.buildLocalizedUrl('groups'), title: <T defaultVal="Gruppi">global.links.groups</T>},
                                        {url: this.groupDetailsUrl, title: <T defaultVal="Il tuo gruppo {{.args.Group}}" useTemplate args={{Group: this.state.serviceFamily.name}}>global.links.group_template</T>},
                                    ]
                                }>{this.pageTitle}</Breadcrumbs>
                        }
                    >{this.pageTitle}</Header>
                </Hidden>
                <Hidden smUp>
                    <Header
                        label={
                            <TextButton onClick={() => this.closeGroup()} size="large" variant="error"><T defaultVal={"Chiudi gruppo"}>pages.group_settings.close_group_headr_button</T></TextButton>
                        }
                    >{this.pageTitle}</Header>
                </Hidden>
                <Box mt={6} mb={4}>
                    <Typography variant="body2" color={"textPrimary"}>
                        <T defaultVal="Seleziona le impostazioni per il tuo gruppo di condivisione.">pages.group_settings.subtitle</T>
                    </Typography>
                </Box>

                <Box>
                    <NewGroupDataForm
                        disabled={this.state.isSaving}
                        visibilities={this.state.serviceType.scopesAvailable}
                        createTerms={[]}
                        serviceDetails={[this.state.group.serviceDetails]}
                        defaultValues={this.state.formValues}
                        editMode
                        onChange={(formValues, {isValid}) => { this.setState({formValid: isValid}) }}
                        formRef={(f) => this.form = f}
                    />
                    {/**/}
                </Box>
                <Box mt={7} mb={5} display="flex" justifyContent="center">
                    <Button disabled={this.state.isSaving} onClick={() => this.saveSettings()} fixedWidth
                            variant="outlined">
                        <T defaultVal="salva">global.actions.save</T>
                    </Button>
                </Box>
            </Grid>
        </Grid>
    }


    renderNavbar() {
        const serviceFamily = this.props.group.serviceFamily
        const isLoading = !this.state.isLoaded
        if (isLoading) {
            return <Navbar logo/>
        }
        return <Navbar
            useTemplate
            args={{Name: serviceFamily.name }}
            logo={serviceFamily.logo}
            title={this.props.layout.title}
        />
    }

    render() {
        const isLoading = !this.state.isLoaded
        const serviceFamily = this.props.group.serviceFamily
        const {group, auth, i18n, history} = this.props;
        const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1)
        const {
            seo,
            layout,
            ...other
        } = this.props;
        if (isLoading) {
            return null
        }
        return (
            <Page layout={{...layout, navbar: this.renderNavbar()}} {...other}>
                <SeoTitle args={{Name: capitalize(serviceFamily.name)}}>{seo.title}</SeoTitle>
                <SeoDescription args={{Name: capitalize(serviceFamily.name)}}>{seo.description}</SeoDescription>
                {this.renderContent()}
            </Page>
        )
    }
}

export default withStyles(styles, {useTheme: true})(withApi(withI18n(withModals(GroupSettings))));
