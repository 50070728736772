import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from "../Typography/Typography";
import clsx from "clsx";
import Field from "../Field/Field";
const listBullet = <svg width="24" height="24" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 24C9.62663 24 7.30655 23.2962 5.33316 21.9776C3.35977 20.6591 1.8217 18.7849 0.913451 16.5922C0.00519945 14.3995 -0.232441 11.9867 0.230582 9.65892C0.693604 7.33115 1.83649 5.19295 3.51472 3.51472C5.19295 1.83649 7.33115 0.693604 9.65892 0.230582C11.9867 -0.232441 14.3995 0.00519945 16.5922 0.913451C18.7849 1.8217 20.6591 3.35977 21.9776 5.33316C23.2962 7.30655 24 9.62663 24 12C24 15.1826 22.7357 18.2348 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24V24ZM12 2.18182C10.0581 2.18182 8.1599 2.75765 6.54531 3.83648C4.93072 4.91532 3.6723 6.44871 2.92919 8.24275C2.18607 10.0368 1.99164 12.0109 2.37048 13.9154C2.74931 15.82 3.6844 17.5694 5.0575 18.9425C6.4306 20.3156 8.18003 21.2507 10.0846 21.6295C11.9891 22.0084 13.9632 21.8139 15.7573 21.0708C17.5513 20.3277 19.0847 19.0693 20.1635 17.4547C21.2424 15.8401 21.8182 13.9419 21.8182 12C21.8182 9.39606 20.7838 6.89877 18.9425 5.0575C17.1012 3.21623 14.6039 2.18182 12 2.18182V2.18182Z" fill="url(#paint0_linear)" />
    <path d="M11.2907 16.3636C11.1472 16.3645 11.0049 16.3369 10.8719 16.2827C10.739 16.2284 10.6181 16.1484 10.5162 16.0473L6.90529 12.4364C6.70211 12.232 6.58807 11.9555 6.58807 11.6673C6.58807 11.3791 6.70211 11.1026 6.90529 10.8982C7.00671 10.7959 7.12736 10.7148 7.2603 10.6594C7.39324 10.604 7.53583 10.5755 7.67984 10.5755C7.82385 10.5755 7.96644 10.604 8.09938 10.6594C8.23231 10.7148 8.35297 10.7959 8.45438 10.8982L11.2907 13.7345L17.1598 7.86545C17.2576 7.75125 17.378 7.6585 17.5133 7.59302C17.6487 7.52754 17.7961 7.49075 17.9463 7.48494C18.0966 7.47914 18.2464 7.50446 18.3864 7.5593C18.5264 7.61415 18.6535 7.69734 18.7598 7.80365C18.8661 7.90997 18.9493 8.03711 19.0042 8.1771C19.059 8.31709 19.0843 8.46691 19.0785 8.61715C19.0727 8.76738 19.0359 8.9148 18.9704 9.05015C18.905 9.18549 18.8122 9.30584 18.698 9.40363L12.0653 16.0473C11.9634 16.1484 11.8425 16.2284 11.7096 16.2827C11.5766 16.3369 11.4343 16.3645 11.2907 16.3636Z" fill="url(#paint1_linear)" />
    <defs>
        <linearGradient id="paint0_linear" x1="23.5714" y1="14.8235" x2="0.212501" y2="14.7585" gradientUnits="userSpaceOnUse">
            <stop stopColor="#4DBFB3" />
            <stop offset="1" stopColor="#C183FF" />
        </linearGradient>
        <linearGradient id="paint1_linear" x1="18.8563" y1="12.9685" x2="6.69876" y2="12.9209" gradientUnits="userSpaceOnUse">
            <stop stopColor="#4DBFB3" />
            <stop offset="1" stopColor="#C183FF" />
        </linearGradient>
    </defs>
</svg>;

const styles = theme => {
  return {
    root: {
      listStyleType: 'none',
      paddingLeft: 0,
      margin: 0
    },
    bullet: {
      marginRight: theme.spacing(1)
    },
    listItem: {
      padding: "8px 4px",
      display: 'flex',
      alignItems: 'flex-start',
      "& h6": {
        fontSize: "12px",
        [theme.breakpoints.up("sm")]: {
          fontSize: "14px"
        },
        fontWeight: "300"
      },
      "& b": {
        color: theme.palette.text.secondary,
        fontWeight: "600"
      }
    }
  };
};

function TList(props) {
  const [open, setOpen] = React.useState(false);
  const {
    children,
    icon,
    variant,
    classes,
    onClick,
    ...other
  } = props;
  return <ul className={classes.root} componentname="t-list">
        {children.map((c, i) => <li key={i} className={classes.listItem}><span className={classes.bullet}>{listBullet}</span>{c}</li>)}
    </ul>;
}

export default withStyles(styles, {
  name: "TList"
})(TList);
