import React, {Component} from 'react';
import Hidden from "@material-ui/core/Hidden";
import Layout from "../../hoc/Layout/Layout";
import Title from "../../components/Title/Title";
import Subtitle from "../../components/Subtitle/Subtitle";
import ListPage from "../../partials/ListPage/ListPage";
import {pages} from "../../constants";
import Page from "../../components/Page/Page";


class PrivacyPolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {isLoading: true}
    }

    onLoad() {
        this.setState({isLoading: false})
    }

    onLocaleUpdate() {
        this.setState({isLoading: true})
    }

    render() {
        const {title, subtitle, ...other} = this.props;
        const {isLoading} = this.state
        return (
            <Page {...other}>
                <Hidden xsDown>
                    <Title isLoading={isLoading}>{title}</Title>
                    <Subtitle isLoading={isLoading} semiBold>{title}</Subtitle>
                </Hidden>
                <ListPage
                    page={"privacy_policy"}
                    onLoad={() => this.onLoad()}
                    onLocaleUpdate={() => this.onLocaleUpdate()}
                />
            </Page>
        )
    }
}

export default PrivacyPolicy;
