import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T} from "@ticketag/i18nlib";
import NewGroupHeader from "../NewGroupHeader/NewGroupHeader";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box/Box";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import Divider from "@material-ui/core/Divider";
import NewGroupDataForm from "../NewGroupDataForm/NewGroupDataForm";
import Button from "@ticketag/ticketag-uilib/src/components/base/Button/Button";
import Card from "@material-ui/core/Card";
import NewGroupList from "@ticketag/ticketag-uilib/src/components/complex/NewGroupCard/NewGroupList";
import NewGroupCard from "@ticketag/ticketag-uilib/src/components/complex/NewGroupCard/NewGroupCard";
import {withWidth} from "@material-ui/core";
import Step from "@ticketag/ticketag-uilib/src/components/base/Stepper/Step";
import Stepper from "@ticketag/ticketag-uilib/src/components/base/Stepper/Stepper";
import Hidden from "@material-ui/core/Hidden";


const styles = (theme) => {
    return {
        root: {},
        bodyContainer: {
            paddingRight: theme.spacing(9),
            [theme.breakpoints.down('md')]: {
                paddingRight: theme.spacing(2),
            }

        },
        cardContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            [theme.breakpoints.down('xs')]: {
                justifyContent: 'center',
                marginBottom: theme.spacing(10)
            }
        },
        divider: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(3)

        }
    }
};

const NewGroupPage = ({
                          linkTitle,
                          linkUrl,
                          pageTitle,
                          pageSubtitle,
                          serviceName,
                          step,
                          classes,
                          children,
                          width,
                          serviceFamily,
                          cardListItems,
                          buttonVariant = 'error',
                          buttonText,
                          onCardButtonClick,
                          ...props
                      }) => {

    const verticalLayout = ['xs', 'sm'].indexOf(width) !== -1

    const now = new Date()
    const nextMonth = new Date()
    nextMonth.setMonth(now.getMonth() + 1)
    const formatDate = (dt) => dt.toLocaleString('default', {month: 'short', year: 'numeric', day: 'numeric'})


    return <Grid item xs={12}>
        <NewGroupHeader
            mb={6}
            pageTitle={pageTitle}
            serviceLogo={serviceFamily?.logo}
            linkUrl={linkUrl}
            step={step}
            linkTitle={linkTitle}/>
        <Box mb={{xs: 4, md:6}}>
            <Stepper style={{maxWidth: '400px', margin: 'auto'}} noPadding={verticalLayout}
                     orientation={verticalLayout ? 'vertical' : 'horizontal'}>
                <Step label={1}
                      title={verticalLayout ? <T defaultVal="Dati del gruppo">pages.create_group.group_data</T> : null}
                      contrast={verticalLayout}
                      onClick={() => step > 1 ? this.goToBilling() : null}
                      active={step === 1} completed={step >= 1}>
                    {verticalLayout && step === 1 ? children : null}
                </Step>
                <Step label={2} title={verticalLayout ? <T defaultVal="Credenziali di accesso">pages.complete_group.subtitle</T> : null}
                      contrast={verticalLayout}
                      active={step === 2}
                      completed={step >= 2}>
                    {verticalLayout && step === 2 ? children : null}
                </Step>
            </Stepper>
        </Box>
        <Grid container>
            <Grid item xs={12} sm={6} lg={8} classes={{root: classes.bodyContainer}}>
                {verticalLayout ? null : <Box mb={1}>
                    <Typography color={'textSecondary'} variant={'h4'}>{step}. {pageSubtitle}</Typography>
                    <Divider className={classes.divider}/>
                </Box>}
                {verticalLayout ? <Box px={4} mb={7}><Divider/></Box> :
                    <Typography color={'textPrimary'} variant={'subtitle1'}>{children}</Typography>
                }
            </Grid>
            <Grid item xs={12} sm={6} lg={4} classes={{root: classes.cardContainer}}>
                <NewGroupCard
                    elevation={4}
                    title={'informazioni'}
                    header={{
                        title: serviceName,
                        subtitle: <><T defaultVal="Ricevi fino a">components.new_group_page.receive_up_to</T>
                            &nbsp;<Typography variant="span" color="gradient">12 € al mese!</Typography></>,
                    }}
                    icon={<img style={{width: '100%'}} alt="logo" src={serviceFamily?.logo}/>}
                    onClick={onCardButtonClick}
                    buttonVariant={buttonVariant}
                    buttonText={buttonText}>
                    <NewGroupList items={cardListItems}/>
                </NewGroupCard>
            </Grid>
        </Grid>
    </Grid>
}

export default withStyles(styles, {useTheme: true})(withWidth()(NewGroupPage));
