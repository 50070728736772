import React from 'react';
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import Skeleton from "@material-ui/lab/Skeleton";
import Box from "@material-ui/core/Box";
import {T} from "@ticketag/i18nlib";
import {withPage} from "../../../hoc/Page/Provider";

const styles = (theme) => {
    return {
        title: {
            fontSize: "28px",
            fontWeight: "600",
            lineHeight: "34px"
        },
        avatar: {
            marginRight: "16px",
            borderRadius: "50%",
            width: "36px!important",
            height: "36px!important"
        }
    }
}


function TNavTitle(props) {
    const {
        T : tComponent,
        multiline , useMarkdown, useTemplate, args,
        page,
        classes, avatar, children, ...other
    } = props;
    const title =
        page && typeof children === "string" ?
            <T multiline={multiline} useTemplate={useTemplate} args={args} defaultVal={children}>{`page.${page}.nav_title`}</T>
            : tComponent || children
    return (
        <Box className={classes.root} display={"flex"} flexGrow={1} alignItems={"center"}>
            {
                    typeof avatar === "string" ?
                        <img src={avatar} className={classes.avatar}/> :
                        avatar
            }
            <Typography component={"h1"} className={classes.title}>
                {title}
            </Typography>
        </Box>
    )
}

export default withStyles(styles)(withPage(TNavTitle))