import React from "react";
import {renderRoutes} from "react-router-config";
import {withApi} from "../../hoc/Api/Provider";
import {withI18n} from "@ticketag/i18nlib";
import {withModals} from "@ticketag/ticketag-uilib/src/components/hoc/Modals/Provider";
import {withRouter} from "react-router-dom"
import { matchPath } from "react-router";
import {withGlobalLoader} from "../../hoc/GlobalLoader/Provider";
import Layout from "../../hoc/Layout/Layout";
import {UndoCreateGroup} from "../../hoc/Modals/Modals";
import {withRedirect} from "../../components/ErrorMessage/ErrorMessage";
import {withLastLocation} from "react-router-last-location";
import {Routes} from "../../hoc/Routes/Provider";

export const styles = (theme) => {
    return {
        root: {}
    }
}

class joinGroupSwitch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
        }
        this.shouldDeleteJoinRequest = true
    }

    componentDidMount() {
        console.log("JOIN GROUP SWITCH")
        this.props.loader.Show()
        withRedirect(this.props.history, this.props.api.groupApi.getGroupJoinRequest(this.props.match.params.groupUuid).then((joinRequest) => {
            this.props.api.GetServiceFamily(joinRequest.serviceFamilyUuid).then((serviceFamily) => {
                this.setState({serviceFamily: serviceFamily, joinRequest: joinRequest, isLoading: false})
                console.log(joinRequest)
            })
        }))
    }


    updateJoinRequest() {
        this.setLoading(true)
        this.props.api.getJoinRequest(this.state.joinRequest.uuid).then((joinRequest) => {
            this.setState({joinRequest: joinRequest})
        })
    }

    deleteJoinRequest() {
        const {api} = this.props;
        this.setLoading(true)
        return api.groupApi.deleteJoinRequest(this.state.joinRequest.uuid).
        catch((err) => console.log(err))
    }

    setLoading(loading) {
        this.setState({isLoading: loading})
    }


    handleClose() {
        this.setLoading(true)
        this.deleteJoinRequest().finally(() => {
            this.props.history.push(this.props.i18n.buildLocalizedUrl("groups"))
        })
    }

    confirmJoinGroup() {
        const joinRequest = this.state.joinRequest
        this.setLoading(true)
        return this.props.api.joinApi.confirmGroupJoin(joinRequest.uuid).then(() => {
            this.setLoading(false)
        })
    }

    render() {
        const route = this.props.route
        return this.state.isLoading ?
            null :
            <Routes routes={route.routes}
                    joinRequest={this.state.joinRequest}
                    serviceFamily={this.state.serviceFamily}
                    deleteJoinRequest={() => this.deleteJoinRequest()}
                    confirmJoinGroup={() => this.confirmJoinGroup()}

            />

    }
}

export default withModals(withApi(withI18n(withGlobalLoader(withLastLocation(withRouter(joinGroupSwitch))))))