import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { T } from "@ticketag/i18nlib";
import Alert from "@ticketag/ticketag-uilib/src/components/base/Alert/Alert";
import Box from "@material-ui/core/Box";

const styles = theme => {
  return {
    root: {}
  };
};

export const GroupAlert = ({
  banner,
  onClose,
  ...props
}) => {
  return <Box {...props} componentname="group-alert">
    <Alert onClose={onClose} severity={banner.severity}>{banner.text}</Alert>
  </Box>;
};
export default withStyles(styles, {
  useTheme: true
})(GroupAlert);