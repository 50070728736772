import React, {Component} from 'react';
import {useTheme, withStyles} from '@material-ui/core/styles';
import {T, withI18n} from "@ticketag/i18nlib";
import Progress from "@ticketag/ticketag-uilib/src/components/base/Progress/Progress";
import {withApi} from "../../hoc/Api/Provider";
import NewGroupHeader from "../../components/NewGroupHeader/NewGroupHeader";
import Box from "@material-ui/core/Box/Box";
import Grid from "@material-ui/core/Grid";
import BaseActionCard from "@ticketag/ticketag-uilib/src/components/complex/BaseActionCard/BaseActionCard";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import ActionCard from "@ticketag/ticketag-uilib/src/components/complex/ActionCard/ActionCard";
import Button from "@ticketag/ticketag-uilib/src/components/base/Button/Button";
import SelectGroupCard from "../../components/SelectGroupCard/SelectGroupCard";
import CardsContainer from "../../components/CardsContainer/CardsContainer";
import ComboBox from "@ticketag/ticketag-uilib/src/components/base/Select/ComboBox";
import Flag from "@ticketag/ticketag-uilib/src/components/base/Icon/Flag/Flag";
import {Icon} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";
import AvatarIcon from "@ticketag/ticketag-uilib/src/components/base/Avatar/AvatarIcon";
import {withRouter} from 'react-router';
import Carousel from "@ticketag/ticketag-uilib/src/components/base/Carousel/Carousel";
import * as PropTypes from "prop-types";
import {Divider, Fade} from "@material-ui/core";
import TextButton from "@ticketag/ticketag-uilib/src/components/base/Button/TextButton";
import clsx from "clsx";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {withAuth} from "../../hoc/Auth/Provider";
import {withRedirect} from "../../components/ErrorMessage/ErrorMessage";
import Layout from "../../hoc/Layout/Layout";
import Page from "../../components/Page/Page";
import Title from "../../components/Title/Title";
import {pages} from "../../constants";
import {SeoDescription, SeoTitle} from "../Seo/Seo";



const styles = (theme) => {
    return {
        root: {},
        languageFilter: {
            maxWidth: '200px'
        },
        subtitle: {
            ...theme.overrides.MuiTypography.h6,
            [theme.breakpoints.up("lg")]: {
                ...theme.overrides.MuiTypography.h4,
            }
        },
        container: {
            position: "relative",
            overflowX: "hidden",
            overflowY: "visible",
        },
        aside: {
            display: "flex",
            alignItems: "center",
            position: "absolute",
            top: "0px",
            width: "66.66666%",
            [theme.breakpoints.down("lg")]: {
                width: "50%"
            },
            height: "calc(100% - 48px)",
            right: "0",
            transition: "transform 0.5s ease-in-out",
            transform: "translateX(0px)"
        },
        asideContent: {
            opacity: "1",
            transition: "opacity 0.25s ease-in-out",
        },
        asideOpen: {
            transform: "translateX(100%)",
            "& $asideContent": {
                transition: "opacity 0.25s ease-in-out",
                opacity: "0"
            }
        },
        divider: {
            marginLeft: "100px",
            marginRight: "120px",
            [theme.breakpoints.down("lg")]: {
                marginLeft: "50px",
                marginRight: "60px"
            },
        },
        asideText: {
            fontSize: "16px",
            color: theme.palette.text.primary,
            fontWeight: "600",
            lineHeight: "28px",
            textTransform: "uppercase",
            margin: "24px 22px",
            [theme.breakpoints.down("md")]: {
                margin: "10px"
            }
        },
        asideButton: {
            [theme.breakpoints.up("sm")]: {
                position: "absolute",
                bottom: "-48px",
                transform: "translateX(-252px)",
                width: "250px",
            },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            "& svg": {
                [theme.breakpoints.down("xs")]: {
                    display: "none",
                },
                width: "18px",
                height: "18px",
                marginBottom: "8px",
            },
            "& label": {
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "20px",
                textTransform: "uppercase"
            }
        },
        cardMedia: {
            borderRadius: "100%",
            width: "76px",
            height: "76px",
        },
        cardPriceNumber: {
            fontSize: "38px",
            fontWeight: "700",
            lineHeight: "46px"
        },
        cardPriceCurrency: {
            fontSize: "14px",
            fontWeight: "700",
            lineHeight: "18px"
        },
        grow: {
            transition: "height 0.5s ease-in-out"
        }
    }
};

function Card(props) {
    const {classes, className, children, media, title, subtitle, onClick, ...other} = props;
    return (
        <BaseActionCard  onClick={onClick}>
            <Box mb={2} display={"flex"} justifyContent={"center"}>
                <img className={classes.cardMedia} src={media} alt={"family_logo"}/>
            </Box>
            <Box width={"100%"} mb={1}>
                <Typography variant={"h5"} align={"center"} color={"textSecondary"}>
                    {title}
                </Typography>
            </Box>
            <Box flexGrow={1}>
                <Typography variant={"body2"} align={"center"} color={"textSecondary"}>
                    {subtitle}
                </Typography>
            </Box>
            <Box  mb={4} mx={2}>
                {children}
            </Box>
        </BaseActionCard>
    )
}

function SelectGroupList(props) {
    const {children, classes, serviceFamily, serviceLogo, groups, onSelect, ...other} = props;
    const [active, setActive] = React.useState(0)
    const [open, setOpen] = React.useState(false)
    const [visible, setVisible] = React.useState(false)

    const renderFn = children
    const theme = useTheme()
    const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
    const isTablet = !useMediaQuery(theme.breakpoints.up('lg')) && !isMobile;

    function renderMessages() {
        return <>
            <Box display={"flex"} alignItems={"center"}>
                <Icon color={"gradient"}>lock</Icon>
                <Typography className={classes.asideText}><T
                    defaultVal={"Ottieni subito le credenziali"}>pages.select_join_group.gain_credentials</T></Typography>
            </Box>
            <Box display={"flex"} alignItems={"center"}>
                <Icon color={"gradient"}>refund</Icon>
                <Typography className={classes.asideText}><T
                    defaultVal={"Rimborsabile al 100%"}>pages.select_join_group.total_refundable</T></Typography>
            </Box>
        </>;
    }


    function renderButton (open, callback = () => {})  {
        return open ?
            <Box className={classes.asideButton} onClick={() => {
                setVisible(false)
                setTimeout(() => setOpen(false), 500)
                callback()
            }}>
                <Icon color={"secondary"}>go_backward</Icon>
                <TextButton color={"secondary"}>
                    <T key={"show_less"} defaultVal={"Mostra meno"}>pages.select_join_group.show_less</T>
                </TextButton>
            </Box>
            :
            <Box className={classes.asideButton} onClick={() => {
                setOpen(true)
                setTimeout(() => setVisible(true), 500)
                callback()
            }}>
                <Icon color={"secondary"}>go_forward</Icon>
                <TextButton color={"secondary"}>
                    <T key={"show_more"} defaultVal={"Mostra altri"}>pages.select_join_group.show_more</T>
                </TextButton>
            </Box>
    }

    if (isMobile) {
        const showN = 2
        return <Grid container>
            <Grid item>
                <Box mb={6}>{ renderMessages() }</Box>
            </Grid>
            <Grid xs={12} item>
                <Box my={-8} py={6} mx={-4} px={4} className={classes.grow} height={(active + 1) * 322} overflow={"hidden"}>
                    {
                        groups.map((group, index) => (
                                <Box display={"flex"} width={"100%"} justifyContent={"center"}>
                                    <Fade in={index <= active } key={group.uuid} timeout={{enter: 1000, exit: 250}}>
                                        <Box py={2.5}>
                                            {renderFn(group)}
                                        </Box>
                                    </Fade>
                                </Box>
                            )
                        )
                    }
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} mt={4} mb={6}>
                    <Box py={2}>{ active + showN < groups.length - 1 ?
                        renderButton(false, () => setActive(active + showN)) : null
                    }</Box>
                    <Box py={2}>{ active > 0 ?
                        renderButton(true, () => setActive(active - showN)) : null
                    }</Box>
                </Box>
            </Grid>
        </Grid>
    }


    return <Box mt={4} mb={14} px={10} mx={{xs: 0, sm: -10, lg: 0}} className={classes.container}>
        <Carousel
            mb={6}
            cols={isTablet ? 2 : 3}
            scroll={3}
            gutters={isTablet ? 30 : 40}
            spacing={5}
            scaling={1}
            height={280}
            duration={0.5}
            overflow
            justify={open ? "center" : "left"}
            disabled={!visible}
            onScrollLeft={(e, val) => {
                setActive(val)
            }}
            onScrollRight={(e, val) => {
                setActive(val)
            }}
        >
            {
                groups && groups.length ? groups.map((group, index) => (
                    <Fade in={index === active || visible}>
                        <Box width={"100%"} height={"100%"} display={"flex"} justifyContent={"center"}>
                            {renderFn(group)}
                        </Box>
                    </Fade>)
                ) : null
            }
        </Carousel>
        <Box className={clsx(classes.aside, open ? classes.asideOpen : null)}>
            <Box display={"flex"} className={classes.asideContent} height={280}>
                <Divider className={classes.divider} orientation={"vertical"}/>
                <Box display={"flex"} height={"100%"} flexDirection={"column"} justifyContent={"center"}>
                    {renderMessages()}
                </Box>
            </Box>
            {
                groups.length > 1 ?
                    renderButton(open) : null
            }
        </Box>
    </Box>;
}


class SelectJoinGroup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded:false
        }
    }
    componentDidMount() {
        this.loadData()
    }

    get serviceName() {
        return this.state.serviceFamily.name.toLowerCase()
    }

    onGroupSelected(uuid) {
        if (this.props.auth.isAuthenticated()) {
            this.props.api.joinApi.joinGroup({uuid: uuid}).then(data => {
                this.props.history.push(this.props.i18n.buildLocalizedUrl('/groups', data.groupUuid, 'join', 'payment'))
            })
        } else {
            window.location.href = `${this.props.auth.signUrlWithMail(false)}?lang=${this.props.i18n.selectedLocale()}&from=${"/" + this.props.i18n.selectedLocale() + "/join/" + uuid + "/payment"}`
        }
    }

    loadData() {
        withRedirect(this.props.history, this.props.api.GetServiceFamilyBySlug(this.props.match.params.serviceFamily).then(data => {
            this.props.api.GetServiceTypeBySlug(data.uuid, this.props.match.params.serviceType).then(serviceType => {
                this.props.api.serviceApi.getServiceGroups(serviceType.uuid).then(resp => {
                    this.setState({
                        isLoaded: true,
                        serviceFamily: data,
                        serviceType: serviceType,
                        groups: resp.groups
                    })
                })
            })
        }))
    }

    goToCreate() {
        this.props.history.push(this.props.i18n.buildLocalizedUrl("services", this.state.serviceFamily.slug, "create", this.state.serviceType.slug))
    }

    renderSearchComboBox() {
        return null
        return <Box mb={4} classes={{root: this.props.classes.languageFilter}} display="flex" alignItems="center">
            <ComboBox label="Lingua" options={this.props.i18n.locales} getOptionValue={(l) => l.uuid}
                      disabled={true}
                      defaultValue={this.props.i18n.selectedLocale()}
                      getOptionLabel={(l) => <Box display="flex"
                                                  justifyContent="flexStart">{/*<Box mx={1}><Flag size="small" code={l.countryCode}/></Box>*/}
                          <div>{l.languageLabel}</div>
                      </Box>}
                      variant="transparent"/>
            <Box ml={2}><Button disabled variant="outlined" color="secondary" size="small"><Icon color="secondary" size="small">search</Icon></Button></Box>
        </Box>;
    }

    renderGroupCard(group) {
        return (
            <Card
                onClick={() => this.onGroupSelected(group.uuid)}
                classes={this.props.classes}
                key={group.uuid}
                group={group}
                media={this.state.serviceFamily.logo}
                title={this.state.serviceFamily.name}
                subtitle={<><T defaultVal={"di"}>global.conjunction.of</T>&nbsp;{group.ownerName}</>}
            >
                <Box display={"flex"} justifyContent={"center"}>
                    <Typography color={"gradient"}>
                        <span className={this.props.classes.cardPriceNumber}>{(group.price/100).toFixed(2)}</span>
                        &nbsp;&nbsp;
                        <span className={this.props.classes.cardPriceCurrency}><T defaultVal={"€/mese"}>pages.select_join_group.euro_per_month</T></span>
                    </Typography>
                </Box>
            </Card>
        )
    }

    renderAdminCard() {
        return (
            <Card
                onClick={() => this.goToCreate()}
                classes={this.props.classes}
                media={this.state.serviceFamily.logo}
                title={<T defaultVal={"Diventa admin"}>pages.select_join_group.become_admin</T>}
                subtitle={
                    <T useTemplate args={{Group: this.state.serviceFamily.name}} defaultVal={"Ottieni {{.args.Group}} e condividi le spese su Diveedi!"}>pages.select_join_group.card_message</T>
                }
            >
                <Box display={"flex"} justifyContent={"center"} px={1}>
                    <Button variant={"outlined"} color={"gradient"} fullWidth>
                        <T useTemplate args={{Group: this.state.serviceFamily.name}} defaultVal={"Vai a {{.args.Group}}"}>
                            pages.select_join_group.go_to
                        </T>
                    </Button>
                </Box>
            </Card>
        )
    }

    renderContent() {
        const {classes, ...other} = this.props;
        return <Grid container justify={"center"}>
            <Grid item xs={12}>
                {this.renderSearchComboBox()}
                {
                    this.state.groups.length === 0 ?
                        <Box mb={4}>
                            <Grid container justify={"center"}>
                                <Grid xs={12} lg={8} item>
                                    <Typography align={"center"} color={"textPrimary"} variant={"h4"} className={classes.subtitle}>
                                        <T useTemplate args={{Group: this.state.serviceFamily.name}} defaultVal={"Siamo spiacenti, al momento non ci sono gruppi disponibili per questo piano {{.args.Group}}..."}>
                                            pages.select_join_group.no_groups_found
                                        </T>
                                    </Typography>
                                    <Box display={"flex"} justifyContent={"center"} mb={{xs: 6, lg: 5}} mt={8}>
                                        { this.renderAdminCard() }
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        :
                        <SelectGroupList
                            classes={this.props.classes}
                            groups={this.state.groups}
                            serviceFamily={this.state.serviceFamily}
                            serviceLogo={this.state.serviceType.logo}
                            onSelect={(uuid) => this.onGroupSelected(uuid)}
                        >
                            {
                                (group) => this.renderGroupCard(group)
                            }
                        </SelectGroupList>
                }
            </Grid>
        </Grid>
    }

    render() {
        const isLoading = !this.state.isLoaded
        const {auth, history, i18n} = this.props;
        const serviceFamily = this.props.serviceFamily
        const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1)
        const {
            seo,
            page,
            title,
            layout,
            ...other
        } = this.props;
        if (isLoading) {
            return null
        }
        return (
            <Page page={page} layout={{
                ...layout,
                onGoBack: () => history.replace(i18n.buildLocalizedUrl("services", serviceFamily.slug, "join")),
                onClose: auth.isAuthenticated() ?
                    () => history.replace(i18n.buildLocalizedUrl("/groups"))
                    :
                    () => history.replace(i18n.buildLocalizedUrl("/"))
            }} seo={seo} {...other}>
                <SeoTitle args={{Name: capitalize(serviceFamily.name)}}>{seo.title}</SeoTitle>
                <SeoDescription args={{Name: capitalize(serviceFamily.name)}}>{seo.description}</SeoDescription>
                <Title>{title}</Title>
                {this.renderContent()}
            </Page>
        )
    }

}

export default withStyles(styles, {useTheme: true})(withAuth(withRouter(withI18n(withApi(withI18n(SelectJoinGroup))))));



