/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * Enum class WebapiprotoBillingCycle.
 * @enum {String}
 * @readonly
 */
const WebapiprotoBillingCycle = {
  /**
   * value: "none"
   * @const
   */
  none: "none",

  /**
   * value: "week"
   * @const
   */
  week: "week",

  /**
   * value: "month"
   * @const
   */
  month: "month",

  /**
   * value: "year"
   * @const
   */
  year: "year",

  /**
   * value: "day"
   * @const
   */
  day: "day",

  /**
   * Returns a <code>WebapiprotoBillingCycle</code> enum value from a JavaScript object name.
   * @param {Object} data The plain JavaScript object containing the name of the enum value.
   * @return {module:model/WebapiprotoBillingCycle} The enum <code>WebapiprotoBillingCycle</code> value.
   */
  constructFromObject: function(object) {
    return object;
  }
};

export {WebapiprotoBillingCycle};
