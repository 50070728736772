/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * The WebapiprotoWalletInfo model module.
 * @module model/WebapiprotoWalletInfo
 * @version v0.4
 */
export class WebapiprotoWalletInfo {
  /**
   * Constructs a new <code>WebapiprotoWalletInfo</code>.
   * @alias module:model/WebapiprotoWalletInfo
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>WebapiprotoWalletInfo</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/WebapiprotoWalletInfo} obj Optional instance to populate.
   * @return {module:model/WebapiprotoWalletInfo} The populated <code>WebapiprotoWalletInfo</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new WebapiprotoWalletInfo();
      if (data.hasOwnProperty('available_amount'))
        obj.availableAmount = ApiClient.convertToType(data['available_amount'], 'Number');
      if (data.hasOwnProperty('total_amount'))
        obj.totalAmount = ApiClient.convertToType(data['total_amount'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {Number} availableAmount
 */
WebapiprotoWalletInfo.prototype.availableAmount = undefined;

/**
 * @member {Number} totalAmount
 */
WebapiprotoWalletInfo.prototype.totalAmount = undefined;


