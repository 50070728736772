import {T} from "@ticketag/i18nlib";
import React from "react";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import AvatarIcon from "@ticketag/ticketag-uilib/src/components/base/Avatar/AvatarIcon";
import AvatarListItem from "../../components/AvatarListItem/AvatarListItem";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import SubscriptionInfoTable from "../../components/SubscriptionInfoTable/SubscriptionInfoTable";
import TextField from "@ticketag/ticketag-uilib/src/components/base/TextField/TextField";
import ChangePasswordForm from "../../components/ChangePasswordForm/ChangePasswordForm";
import FormField from "@ticketag/ticketag-uilib/src/components/base/Field/FormField";
import ConfirmForm from "../../components/ConfirmForm/ConfirmForm";


export const ConfirmDefaultPaymentMethod = (modals) => modals.YesNo(
    <T defaultVal="Rendi metodo di pagamento di default">modals.default_payment_method.title</T>,
    <T defaultVal="Da ora tutti i prossimi rinnovi dei gruppi ai quali partecipi saranno addebitati su questa carta.">modals.default_payment_method.text</T>,
    {})

export const ConfirmDeleteBillingData = (modals) => modals.YesNo(
    <T defaultVal="Elimina profilo di fatturazione">modals.delete_billing_data.title</T>,
    <T defaultVal="Sei sicuro di voler eliminare questo profilo di fatturazione?">modals.delete_billing_data.text</T>,
    {})

export const ConfirmDeletePaymentMethod = (modals) => modals.YesNo(
    <T defaultVal="Elimina metodo di pagamento">modals.delete_payment_method.title</T>,
    <T defaultVal="Sei sicuro di voler eliminare questo metodo di pagamento?">modals.delete_payment_method.text</T>,
    {})

const deleteAccountDisabledDefaultText = 'Prima di eliminare il tuo account devi chiudere tutti i gruppi da te creati  e abbandonare tutti i gruppi ai quali partecipi.'
export const DeleteAccountDisabled = (modals) => modals.OkOnly(
    <T defaultVal="Elimina account">modals.delete_account.title</T>,
    <T defaultVal={deleteAccountDisabledDefaultText}>modals.delete_account.text</T>,
    {})

export const NotImplemented = (modals) => modals.OkOnly(
    <T defaultVal="Non implementato">modals.not_implemented.title</T>,
    <T defaultVal="Funzione non ancora implementata">modals.not_implemented.text</T>,
    {variant: 'error'})

export const InvalidFile = (modals) => modals.OkOnly(
    <T defaultVal="Errore nel caricamento del file">modals.invalid_file.title</T>,
    <T defaultVal="Tipo di file non riconosciuto">modals.invalid_file.text</T>,
    {variant: 'error'})

export const FileTooLarge = (modals) =>  modals.OkOnly(
    <T defaultVal="Non implementato">modals.file_too_large.title</T>,
    <T defaultVal="Il file è troppo grande">modals.file_too_large.text</T>,
    {variant: 'error'})

const defaultKickUserText = `Puoi programmare la rimozione di **{{.args.Name}}** dal gruppo al termine del corrente periodo di fatturazione. Cliccando su RIMUOVI, verrà automaticamente espulso al termine della sottoscrizione.`
const defaultKickLabelText = 'Proseguendo espellerai definitivamennte **{{.args.Name}}** dal tuo gruppo **{{.args.ServiceName}}** il giorno del prossimo rinnovo.';

export const ConfirmKickUser = (modals, userInfo, renewalPeriod, formattedAmount, groupInfo) => {

    const templateArgs = {Name: userInfo.name, Amount: formattedAmount, ServiceName: groupInfo.serviceName}
    return modals.YesNoConfirm(
        <T defaultVal="Rimuovi dal gruppo">modals.kick_user.title</T>,
        <Box>
            <Typography variant="body1"><T defaultVal={defaultKickUserText} useTemplate useMarkdown
                                           args={templateArgs}>modals.kick_user.text</T></Typography>
            <AvatarListItem
                mt={{xs: 2, sm: 4}}
                mb={{xs: 2, sm: 3, md: 5}}
                avatarUuid={userInfo?.avatarUuid}
                title={userInfo.name}
                subtitle={<><T
                    defaultVal={'Membro da'}>modals.kick_user.member_from</T> {userInfo.startDate.toLocaleDateString()}</>}
            />
            <SubscriptionInfoTable
                mb={6}
                renewalPeriod={renewalPeriod}
                nextRenewal={userInfo.renewalDate.toLocaleDateString()}
                amount={formattedAmount}
            />
        </Box>,
        {
            size:              'md',
            scroll:            'body',
            fullScreen:         true,
            disableTypography: true,
            showWarning:       true,
            checkboxLabel:     <T defaultVal={defaultKickLabelText} useMarkdown useTemplate
                                  args={templateArgs}>modals.kick_user.confirm_text</T>
        })
}

const defaultLeaveGroupText = `Stai per abbandonare il gruppo  **{{.args.ServiceName}}**  di **{{.args.Admin}}**.`
const defaultLeaveGroupLabelText = 'Proseguendo abbandonerai definitivamennte il gruppo **{{.args.ServiceName}}** di **{{.args.Admin}}**. Avendo deciso di abbandonare subito, perderai la quota di **{{.args.Amount}}** relativa al periodo corrente.';
export const ConfirmLeaveGroup = (modals, userInfo, adminName, groupInfo, renewalPeriod, formattedAmount) => {
    const templateArgs = {
        Name:        userInfo.name,
        Amount:      formattedAmount,
        Admin:       adminName,
        ServiceName: groupInfo.serviceName
    }
    return modals.YesNoConfirm(
        <T defaultVal="Abbandona il gruppo">modals.leave_group.title</T>,
        <Box>
            <Typography variant="body1"><T defaultVal={defaultLeaveGroupText} useTemplate useMarkdown
                                           args={templateArgs}>modals.leave_group.text</T></Typography>
            <AvatarListItem
                mt={{xs: 2, sm: 4}}
                mb={{xs: 2, sm: 3, md: 5}}
                avatarUuid={userInfo?.avatarUuid}
                title={userInfo.name}
                subtitle={<><T
                    defaultVal={'Membro da'}>modals.leave_group.member_from</T> {userInfo.startDate.toLocaleDateString()}</>}
            />
            <SubscriptionInfoTable
                mb={6}
                renewalPeriod={renewalPeriod}
                nextRenewal={userInfo.renewalDate.toLocaleDateString()}
                amount={formattedAmount}
            />
        </Box>,
        {
            size:              'md',
            scroll:            'body',
            disableTypography: true,
            showWarning:       true,
            checkboxLabel:     <T defaultVal={defaultLeaveGroupLabelText} useMarkdown useTemplate
                                  args={templateArgs}>modals.leave_group.confirm_text</T>
        })
}
// TODO: unire ConfirmKickUser e ConfirmLeaveGroup

export const UndoCreateGroup = (modals) => modals.YesNo(
    <T defaultVal={"Cancella creazione"}>modals.undo_create_group.title</T>,
    <T defaultVal="Desideri cancellare questo gruppo o preferisci continuare in un secondo momento?">modals.undo_create_group.text</T>,
    {
        confirmText: <T defaultVal="Continuerò dopo">modals.undo_create_group.confirm_text</T>,
        cancelText: <T defaultVal="Cancella creazione">modals.undo_create_group.cancel_text</T>
    }
)


export const CancelCloseGroup = (modals) => modals.YesNo(
    <T defaultVal="Annulla chiudi gruppo">modals.cancel_close_group.title</T>,
    <T defaultVal="Confermi di voler annullare la chiusura di questo gruppo?">modals.cancel_close_group.text</T>,
    {
        confirmText: <T defaultVal="annulla chiusura">modals.cancel_close_group.confirm</T>
    }
)

const cancelKickUserText = 'Confermi di voler annullare la rimozione programmata di **{{.args.Name}}**? Cliccando su **ANNULLA** verrà automaticamente annullata l’uscita programmata per il **{{.args.Date}}**'
export const CancelKickUser = (modals, userName, formattedDate) => modals.YesNo(
    <T defaultVal="Annulla rimuovi dal gruppo">modals.cancel_kick_user.title</T>,
    <T defaultVal={cancelKickUserText} useMarkdown useTemplate
       args={{Name: userName, Date: formattedDate}}>modals.cancel_kick_user.text</T>,
    {
        confirmText: <T defaultVal="annulla">modals.cancel_kick_user.confirm</T>
    }
)

const confirmCloseGroupText = 'Confermi  di voler chiudere questo gruppo? Cliccando su CHIUDI GRUPPO, il gruppo verrà chiuso automaticamente non appena scadrà il periodo di sottoscrizione corrente di ogni membro del gruppo. Inserisci la password per confermare la tua identità.'
export const ConfirmCloseGroup = (modals) => modals.YesNoConfirm(
    <T defaultVal="Chiudi gruppo">modals.confirm_close_group.title</T>,
    <T defaultVal={confirmCloseGroupText}>modals.confirm_close_group.text</T>,
    {
        confirmComponentFn: (props) => <ConfirmForm
            variant="outlined" confirmKey={<T defaultVal={"conferma"}>global.hidden.confirm_close_group</T>}label={<T defaultVal="Conferma">modals.confirm_close_group.confirm_label</T>} {...props}/>,
        confirmBoxProps:    {mt: 6, mb: 4},
        reverseButtons:     true,
        confirmText:        <T defaultVal="Chiudi gruppo">modals.confirm_close_group.confirm</T>,
        size:               'md',
    })

const confirmDeleteAccountText = 'Confermi  di voler eliminare il tuo account? Questa è un’operazione irreversibile, tutti i dati salvati sul tuo profilo verrano elimati. Per continuare con l’eliminazione del tuo account, inserisci la tua passwrod per confermare la tua identità.'
export const ConfirmDeleteAccount = (modals) => modals.YesNoConfirm(
    <T defaultVal="Elimina account">modals.confirm_delete_account.title</T>,
    <T defaultVal={confirmDeleteAccountText}>modals.confirm_delete_account.text</T>,
    {
        confirmComponentFn: (props) => <ConfirmForm
            variant="outlined" confirmKey={<T defaultVal={"conferma"}>global.hidden.confirm_delete_account</T>} label={<T defaultVal="Conferma">modals.confirm_delete_account.confirm_label</T>} {...props}/>,
        confirmBoxProps:    {mt: 6, mb: 4},
        reverseButtons:     true,
        confirmText:        <T defaultVal="Elimina account">modals.confirm_delete_account.confirm</T>,
        size:               'md',
    })

export const CloseGroupRequested = (modals) => modals.OkOnly(
    <T defaultVal="Chiusura gruppo">modals.close_group_requested.title</T>,
    <T defaultVal="Richiesta di chiusura gruppo presa in carico con successo.">modals.close_group_requested.text</T>,
    {})


export const PromptChangePassword = (modals) => modals.Form(
    <T defaultVal="Cambio password">modals.change_password.title</T>, <ChangePasswordForm/>,
    {})


export const SendSuggestionComplete = (modals) => modals.OkOnly(
    <T defaultVal="Grazie per il tuo suggerimento">modals.send_suggestion_complete.title</T>,
    <T defaultVal="Ti invieremo una mail non appena il servizio sarà aggiunto">modals.send_suggestion_complete.text</T>,
    {})
