import React from "react";
import Clipboard from 'react-clipboard.js';
import Tooltip from "@material-ui/core/Tooltip";
import {withStyles} from "@material-ui/core";

const styles = (theme) => {
    return {}
}

class TClipboard extends React.Component {
    constructor(props) {
        super(props)
        this.setState({showTooltip: false})
    }
    handleClick(evt) {
        this.setState({showTooltip: true})
        setTimeout(() => {
            this.setState({showTooltip: false})
        }, 2000)
    }
    render() {
        const {
            onClick=() => {},
            tooltipText,
            tooltipPlacement = 'below',
            ...other
        } = this.props
        console.log(this.state)
        if (tooltipText) {
            return (
                <Tooltip title={tooltipText} open={this.state && this.state.showTooltip} enterDelay={500} leaveDelay={200} placement={tooltipPlacement}>
                    <Clipboard onClick={(evt) => {onClick(evt); this.handleClick(evt)}} {...other}/>
                </Tooltip>
            )
        }
        return <Clipboard onClick={onClick} {...other}/>
    }
}

export default withStyles(styles, {})(TClipboard)