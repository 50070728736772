
import {pages} from "./constants";
import React from "react";
import HomePage from "./pages/HomePage/HomePage";
import ErrorPage from "./pages/Error/Error"
import Faq from "./pages/Faq/Faq";
import Suggestions from "./pages/Suggestions/Suggestions";
import Welcome from "./pages/Welcome/Welcome";
import Wallet from "./pages/Wallet/Wallet";
import {T} from "@ticketag/i18nlib";
import Profile from "./pages/Profile/Profile";
import Chat from "./pages/Chat/Chat";
import Invite from "./pages/Invite/Invite";
import Groups from "./pages/Groups/Groups";
import JoinSummary from "./pages/JoinSummary/JoinSummary";
import JoinGroupResult from "./pages/JoinGroupResult/JoinGroupResult";
import CreateGroup from "./pages/CreateGroup/CreateGroup";
import CreateSummary from "./pages/CreateSummary/CreateSummary";
import InvitePage from "./components/InvitePage/InvitePage";
import GroupDetails from "./pages/GroupDetails/GroupDetails";
import GroupSettings from "./pages/GroupSettings/GroupSettings";
import Dispute from "./pages/Dispute/Dispute";
import Services from "./pages/Services/Services";
import SelectJoinGroup from "./pages/SelectJoinGroup/SelectJoinGroup";
import CreateGroupAfterLogin from "./partials/CreateGroupAfterLogin/CreateGroupAfterLogin";
import SelectGroupType from "./pages/SelectGroupType/SelectGroupType";
import JoinCreateGroup from "./pages/JoinCreateGroup/JoinCreateGroup";
import CompleteGroup from "./pages/CompleteGroup/CompleteGroup";
import NotFound from "./pages/NotFound/NotFound";
import RootSwitch from "./router/RootSwitch/RootSwitch";
import TermsConditions from "./pages/TermsConditions/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import ServiceFamilySwitch from "./router/ServiceFamilySwitch/ServiceFamilySwitch";
import JoinGroupSwitch from "./router/JoinGroupSwitch/JoinGroupSwitch";
import CreateGroupSwitch from "./router/CreateGroupSwitch/CreateGroupSwitch";
import GroupSwitch from "./router/GroupSwitch/GroupSwitch";
import GroupVisibility from "./pages/GroupVisibiltiy/GroupVisibility";
import GroupSize from "./pages/GroupSize/GroupSize";
import GroupCredentials from "./pages/GroupCredentials/GroupCredentials";
import {renderRoutes} from "react-router-config";
import {withRouter} from "react-router-dom";
import SelectServiceFamily from "./pages/SelectServiceFamily/SelectServiceFamily";
import Payment from "./pages/Payment/Payment";

const lang = ":locale([a-z]{2}-[A-Z]{2})?";

import pageData from "./pages"
import {Routes} from "./hoc/Routes/Provider";

const page = (name) => ({page: pageData[name]})

export default [{
    path: `*`,
    component: RootSwitch,
    routes:  [
        {
            path: `/${lang}`,
            exact: true,
            component: HomePage,
            ...page(pages.HOMEPAGE),
        },
        {
            component: ErrorPage,
            path: `/${lang}/error/:errorCode?`,
            ...page(pages.ERROR),
        },
        {
            path: `/${lang}/faq`,
            component: Faq,
            exact: true,
            ...page(pages.FAQ),
        },
        {
            path: `/${lang}/privacy-policy`,
            component: PrivacyPolicy,
            exact: true,
            ...page(pages.PRIVACY_POLICY),
        },
        {
            path: `/${lang}/terms-conditions`,
            component: TermsConditions,
            exact: true,
            ...page(pages.TERMS_AND_CONDITIONS),
        },
        {
            path: `/${lang}/welcome`,
            component: Welcome,
            private: true,
            exact: true,
            ...page(pages.WELCOME),
        },
        {
            path: `/${lang}/wallet`,
            private: true,
            exact: true,
            component: Wallet,
            ...page(pages.WALLET)
        },
        {
            path: `/${lang}/profile`,
            private: true,
            exact: true,
            component: Profile,
            ...page(pages.PROFILE),
        },
        {
            path: `/${lang}/chat/:groupUuid?`,
            private: true,
            component: Chat,
            ...page(pages.CHAT),
        },
        {
            path: `/${lang}/invite/:token/:action(join)?`,
            component: Invite,
            ...page(pages.INVITE)
        },
        {
            path: `/${lang}/services`,
            component: Services,
            exact: true,
            ...page(pages.SERVICES),
        },
        {
            path: `/${lang}/suggestions`,
            exact: true,
            component: Suggestions,
            ...page(pages.SUGGESTIONS)
        },
        /*************************
         * SERVICE FAMILY ROUTES *
         * ***********************/
        {
            path: `/${lang}/services/:serviceFamily`,
            component: ServiceFamilySwitch,
            routes: [
                {
                    path: `/${lang}/services/:serviceFamily`,
                    component: JoinCreateGroup,
                    exact: true,
                    ...page(pages.JOIN_CREATE_GROUP)
                },
                {
                    path: `/${lang}/services/:serviceFamily/:action(join|create)`,
                    exact: true,
                    component: withRouter(
                        ({route, match,...other}) => <Routes routes={route.routes} action={match.params.action} {...other}/>
                    ),
                    routes: [
                        {
                            path: `/${lang}/services/:serviceFamily/join`,
                            exact: true,
                            component: SelectGroupType,
                            ...page(pages.SELECT_JOIN_SERVICE_TYPE)
                        },
                        {
                            path: `/${lang}/services/:serviceFamily/create`,
                            exact: true,
                            component: SelectGroupType,
                            ...page(pages.SELECT_CREATE_SERVICE_TYPE)
                        },
                    ]
                },
                {
                    path: `/${lang}/services/:serviceFamily/join/:serviceType`,
                    private: true,
                    component: SelectJoinGroup,
                    ...page(pages.SELECT_JOIN_GROUP),

                },
                {
                    path: `/${lang}/services/:serviceFamily/create/:serviceType`,
                    private: true,
                    component: CreateGroupAfterLogin,
                },
            ]
        },
        /******************
         * PRIVATE ROUTES *
         * ****************/
        {
            path: `/${lang}/groups`,
            private: true,
            exact: true,
            component: Groups,
            ...page(pages.GROUPS),
        },
        {
            path: `/${lang}/groups/create`,
            exact: true,
            private: true,
            component: SelectServiceFamily,
            ...page(pages.SELECT_SERVICE_FAMILY),

        },
        /*********************
         * JOIN GROUP ROUTES *
         * ******************/
        {
            path: `/${lang}/groups/:groupUuid/join`,
            component: JoinGroupSwitch,
            routes: [
                {
                    path: `/${lang}/groups/:groupUuid/join/:step(payment)`,
                    private: true,
                    exact: true,
                    component: Payment,
                    ...page(pages.PAYMENT)
                },
                {
                    path: `/${lang}/groups/:groupUuid/join/summary`,
                    private: true,
                    component: JoinSummary,
                    ...page(pages.JOIN_SUMMARY)
                },
                {
                    path: `/${lang}/groups/:groupUuid/join/:result(success|fail|processing)`,
                    component: withRouter(
                        ({route, match,...other}) => <Routes routes={route.routes} result={match.params.result} {...other}/>
                    ),
                    routes: [
                        {
                            path: `/${lang}/groups/:groupUuid/join/success`,
                            exact: true,
                            private: true,
                            component: JoinGroupResult,
                            ...page(pages.JOIN_SUCCESS),
                        },
                        {
                            path: `/${lang}/groups/:groupUuid/join/fail`,
                            exact: true,
                            private: true,
                            component: JoinGroupResult,
                            ...page(pages.JOIN_FAILED)
                        },
                        {
                            path: `/${lang}/groups/:groupUuid/join/processing`,
                            exact: true,
                            private: true,
                            component: JoinGroupResult,
                            ...page(pages.JOIN_PROCESSING),
                        },
                    ]
                }
            ]
        },
        {
            path: `/${lang}/groups/:groupUuid`,
            component: GroupSwitch,
            routes: [

                /***********************
                 * CREATE GROUP ROUTES *
                 * *********************/
                {
                    path: `/${lang}/groups/:groupUuid/create`,
                    component: CreateGroupSwitch,
                    routes: [
                        {
                            path: `/${lang}/groups/:groupUuid/create/visibility`,
                            private: true,
                            exact: true,
                            component: GroupVisibility,
                            ...page(pages.CREATE_VISIBILITY),
                        },
                        {
                            path: `/${lang}/groups/:groupUuid/create/size`,
                            private: true,
                            exact: true,
                            component: GroupSize,
                            ...page(pages.CREATE_SIZE),
                        },
                        {
                            path: `/${lang}/groups/:groupUuid/create/credentials`,
                            private: true,
                            exact: true,
                            component: GroupCredentials,
                            ...page(pages.CREATE_CREDENTIALS),
                        },
                        {
                            path: `/${lang}/groups/:groupUuid/create/summary`,
                            component: CreateSummary,
                            exact: true,
                            private: true,
                            ...page(pages.CREATE_SUMMARY),
                        },
                        {
                            path: `/${lang}/groups/:groupUuid/create/success`,
                            exact: true,
                            private: true,
                            component:  InvitePage,
                            ...page(pages.CREATE_SUCCESS),
                        },
                    ]
                },
                /************************
                 * GENERAL GROUP ROUTES *
                 * **********************/
                {
                    path: `/${lang}/groups/:groupUuid/details`,
                    private: true,
                    exact: true,
                    component: GroupDetails,
                    ...page(pages.GROUP_DETAILS),
                },
                {
                    path: `/${lang}/groups/:groupUuid/invite`,
                    private: true,
                    exact: true,
                    component: InvitePage,
                    ...page(pages.GROUP_SHARE),
                },
                {
                    path: `/${lang}/groups/:groupUuid/settings`,
                    private: true,
                    exact: true,
                    component: GroupSettings,
                    ...page(pages.GROUP_SETTINGS),
                },
                {
                    path: `/${lang}/groups/:groupUuid/dispute`,
                    private: true,
                    exact: true,
                    component: Dispute,
                    ...page(pages.GROUP_DISPUTE),
                },
            ]
        },
        /******************
         * FALLBACK ROUTE *
         * ****************/
        {
            path: "*",
            component: NotFound,
        }
    ]
}];


