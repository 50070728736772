import React from "react";
import Box from "@material-ui/core/Box";
import {Fade} from "@material-ui/core";
import {ExpandMoreIcon} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";
import clsx from "clsx";
import Tab from "@ticketag/ticketag-uilib/src/components/base/Tabs/Tab";
import {Skeleton} from "@material-ui/lab";
import withStyles from "@material-ui/core/styles/withStyles";
import {createMuiTheme, useTheme} from "@material-ui/core/styles";
import ThemeProvider from "@ticketag/ticketag-uilib/src/components/ThemeProvider";
import {T} from "@ticketag/i18nlib";


const styles = (theme) => {
    return {
        title: {
            fontSize: "26px",
        },
        tabs: {
            display: "flex",
            overflowX: "hidden",
            transition: "width .3s ease"
        },
        tabLoading: {
            display: "flex",
            "& .MuiSkeleton-root": {
                margin: "0 20px"
            }
        },
        tab: {
            margin: "0",
            padding: "0 20px",
            width: "auto",
            fontSize: "26px",
            //color: theme.palette.text.secondary,
            "&.Mui-selected": {
                fontWeight: "600"
            }
        },
        tabButtonNext: {
            cursor: "pointer",
            transform: "rotate(270deg)"
        },
        tabButtonPrev: {
            cursor: "pointer",
            transform: "rotate(90deg)"
        },
        hidden: {
            display: "none",
        },
        showMoreIcon: {
            transitionDuration: "0.3s",
            transitionProperty: "transform",
            marginLeft: "16px",
            marginBottom: "-8px"
        },
        expanded: {
            transform: "rotate(180deg)"
        },
        mobile: {
            marginLeft: "-36px",
            marginTop: "-16px"
        },
        mobileTab: {
            fontSize: "16px",
            padding: 0,
            margin: "0 12px"
        },
    }
}


export function Tabs(props) {
    const {tags, i18n, classes, value, contrast, onChange, cols = 3, isLoading, mobile, ...other} = props;
    const ref = React.useRef()
    const [active, setActive] = React.useState(0)
    const [selected, setSelected] = React.useState(value)
    const handleChange = (e, val) => {
        setSelected(val)
        onChange(e, val)
    }
    const theme = useTheme()
    const getWidth = (active, offset = cols) => {
        if (ref.current) {
            return [...Array(offset).keys()].reduce((acc, index) => {
                const item = ref.current.children.item(active + index)
                return item ? acc + item.offsetWidth + (mobile ? 24 : 0) : acc
            }, 0)
        }
        return 0
    }
    React.useEffect(() => {
        ref.current.scrollLeft = 0
        ref.current.style.width = `${getWidth(active)}px`
    }, [isLoading])

    const scroll = (offset) => {
        ref.current.scroll({
            left: ref.current.scrollLeft + offset,
            behavior: "smooth"
        })
    }

    const prev = Math.max(active - cols, 0)
    const handleScrollLeft = () => {
        scroll(-getWidth(prev,active - prev))
        setActive(prev)
    };

    const next = Math.min(tags.length - cols, active + cols)
    const handleScrollRight = () => {
        scroll(getWidth(active, next - active))
        setActive(next)
    };

    return (
        <>
            <Box display={"flex"} alignItems={"center"}
                 className={clsx(mobile ? classes.mobile : null, isLoading ? classes.hidden : null)}>
                <Fade in={active > 0}>
                    <ExpandMoreIcon className={clsx(classes.tabButton, classes.tabButtonPrev)}
                                    onClick={handleScrollLeft} color={contrast ? "white" : "textPrimary"}
                    />
                </Fade>
                <Box>
                    <Box ref={ref} style={{width: `${getWidth(active)}px`, transition: "none"}} className={clsx(classes.tabs, mobile ? classes.mobileTabs : null)}>
                        {
                            contrast ?
                                <ThemeProvider theme={createMuiTheme({...theme, palette: { type: "dark"}})}>
                                    {
                                        tags.map(
                                            (tag, i) =>
                                                <Tab
                                                    contrast={contrast}
                                                    key={i} selected={selected === i} className={clsx(classes.tab, mobile ? classes.mobileTab : null)}
                                                    label={<T defaultVal={tag.title}>{`pages.homepage.${tag.key}`}</T>}
                                                    onClick={(e) => {
                                                        handleChange(e, i)
                                                    }}
                                                />
                                        )
                                    }
                                </ThemeProvider>
                                :
                                tags.map(
                                    (tag, i) =>
                                        <Tab id={"tag"} onClick={(e) => {
                                            handleChange(e, i)
                                        }}
                                             contrast={contrast}
                                             key={i} selected={selected === i} className={classes.tab}
                                            label={<T defaultVal={tag.title}>{`pages.homepage.${tag.key}`}</T>}
                                        />
                                )
                        }
                    </Box>
                </Box>
                <Fade in={active < tags.length - cols}>
                    <ExpandMoreIcon className={clsx(classes.tabButton, classes.tabButtonNext)}
                                    onClick={handleScrollRight} color={contrast ? "white" : "textPrimary"}
                    />
                </Fade>

            </Box>
            {
                isLoading ?
                    <Box className={classes.tabLoading}>
                        {
                            [...Array(cols).keys()].map((i) => <Skeleton key={i} width={mobile ? 48 : 120} height={mobile ? 24 : 36}/>)
                        }
                    </Box> : null
            }
        </>
    )
}

export default withStyles(styles)(Tabs)