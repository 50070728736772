import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {FormLabel} from "@material-ui/core";
import {T} from "@ticketag/i18nlib";
import {CardCvcElement, CardExpiryElement, CardNumberElement} from "@stripe/react-stripe-js";
import clsx from "clsx";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import useTheme from "@material-ui/core/styles/useTheme";


const styles = (theme) => {
    return {
        root:        {
        },
        label:       {
            textTransform: 'uppercase',
            fontSize:      '14px',
            transform:     'none',
            lineHeight:    '30px',
            width:         'max-content',
            "&.Mui-error + .StripeElement": {
                borderColor: theme.palette.error.main,
            },
            "&.Mui-focused": {
                color: theme.palette.text.secondary
            }
        },
        inputs:      {
            height:                     '16px',
            //maxWidth: '220px',
            border:                     '1px solid rgba(0, 0, 0, 0.23)',
            cursor:                     'text',
            "& input": {
                color: theme.palette.text.primary + "!important"
            },
            "& ~ .Mui-error": {
                borderColor: theme.palette.error.main
            },
            "&.StripeElement":          {
                width: 'auto!important',
                fontSize: '12px',
                padding: theme.spacing(1),
                transition: 'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
                borderRadius: '4px'
            },
            "&.StripeElement--focus":   {
                border: "1px solid currentColor!important",
            },
            "&.StripeElement--focus.StripeElement--invalid":   {
                border: "1px solid " + theme.palette.text.secondary + "!important",
            },
            "&.StripeElement--invalid": {
                borderColor: theme.palette.error.main + "!important"
            },
        },
        helperText:  {
            fontSize:   '14px',
            lineHeight: '20px',
            "&.Mui-focused": {
                color: theme.palette.text.primary
            }
        },
        inputNarrow: {
            maxWidth: '85px'
        }
    }
};

const stripeElementFactory = (elementName) => {
    return (({name, error, label, stripe, helperText, classes, onChange, onBlur, ...props}) => {
        const [focused, setFocused] = React.useState(false)
        const theme = useTheme()
        const ELEMENT_OPTIONS = {
            style: {
                base:    {
                    fontFamily: theme.typography.fontFamily,
                    fontSize:        '14px',
                    minWidth:        '220px',
                    color:           theme.palette.text.secondary,
                    cursor:          'text',
                    padding:         theme.spacing(1),
                    '::placeholder': {
                        color: '#aab7c4',
                    },
                },
                invalid: {
                    color: theme.palette.text.secondary,
                }
            },
        };
        const onStripeElemChange = (elem) => {
            elem = JSON.parse(JSON.stringify(elem))
            if (!elem.complete) {
                onChange(elem, null)
            } else if (elem.error && elem.error.message) {
                onChange(elem, null)
            } else {
                onChange(elem, true)
            }
        }
        const onStripeElemBlur = (elem) => {
            elem = JSON.parse(JSON.stringify(elem))
            if (!elem.complete) {
                onBlur(elem, null)
            } else if (elem.error && elem.error.message) {
                onBlur(elem, null)
            } else {
                onBlur(elem, true)
            }
            setFocused(false)
        }
        let stripeElement

        switch (elementName) {
            case 'cardExpiry':
                stripeElement = <CardExpiryElement className={clsx([classes.inputs, classes.inputNarrow])}
                                                   options={ELEMENT_OPTIONS}
                                                   onChange={(elem) => {onStripeElemChange(elem)}}
                                                   onFocus={(elem) => setFocused(true)}
                                                   onBlur={(elem) => {onStripeElemBlur(elem)}}
                                                   classes={{base: classes.input}} stripe={stripe}/>
                break
            case 'cvc':
                stripeElement = <CardCvcElement className={clsx([classes.inputs, classes.inputNarrow])}
                                                options={ELEMENT_OPTIONS}
                                                onChange={(elem) => {onStripeElemChange(elem)}}
                                                onBlur={(elem) => {onStripeElemBlur(elem)}}
                                                onFocus={(elem) => setFocused(true)}
                                                classes={{base: classes.input}} stripe={stripe}/>
                break
            case 'cardNumber':
                stripeElement = <CardNumberElement className={clsx([classes.inputs])}
                                                   options={ELEMENT_OPTIONS}
                                                   onChange={(elem) => {onStripeElemChange(elem)}}
                                                   onBlur={(elem) => {onStripeElemBlur(elem)}}
                                                   onFocus={(elem) => setFocused(true)}
                                                   classes={{base: classes.input}} stripe={stripe}/>
                break
            default:
                return <></>
        }

        return <FormControl classes={{root: classes.root}} focused={focused} name={name} {...props}>
            <FormLabel error={error} classes={{root: classes.label}}>{label}</FormLabel>
            {stripeElement}
            {helperText ?
                <FormHelperText classes={{root: classes.helperText}} error={error}>{helperText}</FormHelperText> : null}
        </FormControl>
    })

}


export const CardExpiry = withStyles(styles, {useTheme: true})(stripeElementFactory('cardExpiry'))
export const CardNumber = withStyles(styles, {useTheme: true})(stripeElementFactory('cardNumber'))
export const CardCvc = withStyles(styles, {useTheme: true})(stripeElementFactory('cvc'))
