import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T, withI18n} from "@ticketag/i18nlib";
import ChipCard from "@ticketag/ticketag-uilib/src/components/complex/ChipCard/ChipCard";
import ListItem from "@ticketag/ticketag-uilib/src/components/base/ListItem/ListItem";
import Box from "@material-ui/core/Box";
import ShowSize from "@ticketag/ticketag-uilib/src/components/base/ShowSize/ShowSize";
import Typography from "@material-ui/core/Typography";
import Link from "@ticketag/ticketag-uilib/src/components/base/Link/Link";
import IconButton from "@ticketag/ticketag-uilib/src/components/base/Button/IconButton";
import {Icon} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";
import AvatarIcon, {AvatarPlusIcon} from "@ticketag/ticketag-uilib/src/components/base/Avatar/AvatarIcon";
import {Link as RouterLink, useHistory} from 'react-router-dom';


const styles = (theme) => {
    return {
        inviteUrl: {
            cursor: "pointer",
            "& path": {
                strokeOpacity: "0.3"
            },
            "&:hover path": {
                strokeOpacity: "0.5"
            },
            "&:hover a": {
                textDecoration: "underline"
            },
        }
    }
};

export const GroupMembersCard = ({numMembers, maxMembers, groupScope, members=[], isAdmin=false, classes, onLeave, onKick=() => {}, onUnkick=() => {}, inviteUrl, disabled, ...props}) => {
    //
    const history = useHistory()
    return <ChipCard header={'Partecipanti'} {...props}>
        <ListItem append={
            <Box display="flex" flexDirection="column" alignItems="center">
                <ShowSize max={maxMembers} value={numMembers}/>
                <Typography variant="caption"><T defaultVal={groupScope}>{`enums.group_scopes.${groupScope}`}</T> <Icon mb={-0.25} size="small">info</Icon></Typography>
            </Box> }>
            <Typography color="textSecondary" variant="h6">Capienza gruppo</Typography>
        </ListItem>
        {members.map((m, i) => (
            <ListItem key={m.uuid} prepend={<AvatarIcon clickable={false} disabled={disabled} hover={!m.isMe} size={"medium"} src={m.avatarUuid||"1"}/>}
                      append={isAdmin && !disabled ?
                          (m.kicked ?
                                  <IconButton disabled={disabled} onClick={() => onUnkick(m.uuid, m)} noPadding disableRipple size="medium" color="primary">enter</IconButton> :
                                  <IconButton disabled={disabled} onClick={() => onKick(m.uuid, m)} noPadding size="large" color="error">close</IconButton>
                          ) :
                          (m.isMe ? <IconButton disabled={disabled} onClick={() => onLeave(m)} noPadding size="medium" color="error">exit</IconButton> : null)
                      }>
                <Typography color="textSecondary" variant="h6">{m.isMe ? <T defaultVal="Tu">global.pronouns.you</T> : m.name}</Typography>
            </ListItem>
        ))}
        {
            !disabled && inviteUrl ?
                <ListItem className={classes.inviteUrl} onClick={() => history.push(inviteUrl)} prepend={<AvatarPlusIcon disabled={disabled} size={"medium"} color="secondary" src="plus" hover fainted icon/>} >
                    <Link component={RouterLink} to={disabled ? '#' : inviteUrl} color={'secondary'}><T defaultVal="Invita un amico">components.group_members_card.invite_friend</T></Link>
                </ListItem>
                : <Box/>
        }
    </ChipCard>
}

export default withStyles(styles, {useTheme: true})(withI18n(GroupMembersCard));
