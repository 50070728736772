/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';
import {WebapiprotoServiceFamily} from './WebapiprotoServiceFamily';

/**
 * The WebapiprotoServiceFamilies model module.
 * @module model/WebapiprotoServiceFamilies
 * @version v0.4
 */
export class WebapiprotoServiceFamilies {
  /**
   * Constructs a new <code>WebapiprotoServiceFamilies</code>.
   * @alias module:model/WebapiprotoServiceFamilies
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>WebapiprotoServiceFamilies</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/WebapiprotoServiceFamilies} obj Optional instance to populate.
   * @return {module:model/WebapiprotoServiceFamilies} The populated <code>WebapiprotoServiceFamilies</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new WebapiprotoServiceFamilies();
      if (data.hasOwnProperty('count'))
        obj.count = ApiClient.convertToType(data['count'], 'Number');
      if (data.hasOwnProperty('families'))
        obj.families = ApiClient.convertToType(data['families'], [WebapiprotoServiceFamily]);
    }
    return obj;
  }
}

/**
 * @member {Number} count
 */
WebapiprotoServiceFamilies.prototype.count = undefined;

/**
 * @member {Array.<module:model/WebapiprotoServiceFamily>} families
 */
WebapiprotoServiceFamilies.prototype.families = undefined;


