import Box from "@material-ui/core/Box";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import capitalize from "@material-ui/core/utils/capitalize";
import {fade} from "@material-ui/core/styles/colorManipulator";


const styles = (theme) => {
  return {
    root: {
      zIndex: 4,
      position: "relative",
      borderRadius: "16px",
      transition: "box-shadow 0.3s ease-in-out, transform 0.3s ease-out",
    },
    '@keyframes wave': {
      '0%': {
        opacity: 1,
      },
      '50%': {
        opacity: 0.4,
      },
      '100%': {
        opacity: 1,
      },
    },
    loading: {
      backgroundColor: fade(theme.palette.text.primary, 0.11),
      "&::after": {
        //animation: '$wave 1.6s linear 0.5s infinite',
        //background: `linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent)`,
        borderRadius: "16px",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        content: "''",
        position: "absolute",
      }
    },
    contrast: {
      "&$shadowMobile": {
        boxShadow: theme.shadows[11]
      },
      "&$shadowDesktop": {
        boxShadow: theme.shadows[12]
      },
      "&$shadowDesktop$hover": {
        boxShadow: theme.shadows[13]
      },
      "&$shadowDesktop$clicked": {
        boxShadow: theme.shadows[14]
      },
    },
    shadowMobile: {
      boxShadow: theme.shadows[1],
    },
    shadowDesktop: {
      boxShadow: theme.shadows[2],
      "&$hover": {
        boxShadow: theme.shadows[3]
      },
      "&$clicked": {
        boxShadow: theme.shadows[4]
      },
    },
    hover: () => ({}),
    clicked: () => ({
      transition: "box-shadow 0.1s ease-out, transform 0.1s ease-out !important",
      transform: "translateY(-2px)",
    }),
    colorLayer: {
      position: "absolute",
      cursor: "pointer",
      width: "100%",
      height: "100%",
      borderRadius: "16px",
      opacity: "1",
      transition: "opacity .3s ease",
      zIndex: "1",
    },
    img: {
      color: "transparent",
      overflow: "hidden",
      borderRadius: "16px",
      width: "100%",
      height: "100%",
      objectFit: "cover",
      pointerEvents: "none",
      position: "relative",
      zIndex: "0",
    },
    colorLayerHover: {
      opacity: 0
    },
    logo: {
      width: "100%",
      pointerEvents: "none",
      zIndex: "2",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)"
    },
    footer: {
      overflow: "hidden",
      width: "100%",
      pointerEvents: "none",
      zIndex: "2",
      position: "absolute",
      bottom: "0",
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
    }
  };
};

function TImageCard(props) {
  const {
    children,
    classes,
    logo,
    contrast = false,
    color = 'transparent',
    img,
    onEnter: onMouseEnter,
    onLeave: onMouseLeave,
    onClick,
    shadow = "none",
    className,
    width = "100%",
    height =" 100%",
    ...other
  } = props;

  const [loaded, setLoaded] = React.useState(false);
  const [hover, setHover] = React.useState(false);
  const [clicked, setClicked] = React.useState(false);


  const handleLoad = () => {
    setLoaded(true)
  }

  const handleEnter = e => {
    setHover(true);
    onMouseEnter && onMouseEnter(e);
  };

  const handleLeave = e => {
    setHover(false);
    onMouseLeave && onMouseLeave(e);
  };

  const handleClick = onClick ? (e) => {
    setClicked(true)
    setTimeout(() => {
      setClicked(false)
      setTimeout(() => onClick(e), 50)
    }, 100)
  } : null

  return (
      <Box className={clsx({
        [classes.root]: true,
        [classes.hover]: hover,
        [classes.clicked]: clicked,
        [classes.contrast]: contrast,
        [classes.loading]: !loaded,
        [classes[`shadow${capitalize(shadow)}`]]: shadow,
      })} position={"relative"}
           width={width} height={height}
           onMouseEnter={handleEnter} onMouseLeave={handleLeave} onTouchStart={handleEnter} onTouchEnd={handleLeave}
           onClick={handleClick}
           componentname="t-image-card">
        <Box width={"100%"} height={"100%"}>
          <Box className={clsx(
              !loaded ? classes.loading : null,
              classes.colorLayer, hover ? classes.colorLayerHover : null
          )} style={{
            backgroundColor: color,
          }} />
          <img onLoad={handleLoad} alt={"service_image"} className={props.classes.img} src={img} />
          <img alt={"service_logo"} className={props.classes.logo} src={logo} />
          <Box className={classes.footer}>
            {children}
          </Box>
        </Box>
      </Box>
  );
}

export default withStyles(styles)(TImageCard);
