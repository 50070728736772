import {withStyles} from "@material-ui/core/styles";
import React from "react";
import Menu from "@material-ui/core/Menu";

const styles = (theme) => {
    return {
        paper: {
            borderRadius: "8px!important",
            border: "0.5px solid rgba(29, 15, 81, 0.12)",
            boxShadow: "-3px 3px 12px rgba(172, 172, 172, 0.08), 2px -2px 7px rgba(172, 172, 172, 0.08)"
        }
    }
}

export const TMenu = ((props) => {
    const {
        classes,
        children,
        ...other
    } = props;
    return <Menu classes={{list: classes.list, paper: classes.paper}} {...other}>
        {props.children}
    </Menu>
})

export default withStyles(styles, {})(TMenu)