import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { T } from "@ticketag/i18nlib";
import Box from "@material-ui/core/Box/Box";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import Button from "@ticketag/ticketag-uilib/src/components/base/Button/Button";

const styles = theme => {
  return {
    root: {
      position: "relative",
      minHeight: "550px",
      backgroundSize: "cover",
      height: '100vh',
      //"calc(100vh - 148px)",
      [theme.breakpoints.down('md')]: {
        height: "auto",
        minHeight: "1125px"
      }
    },
    backgroundImage: {
      top: "0",
      width: "81.944444444vw",
      height: "100%",
      right: "0",
      zIndex: "-1",
      position: "absolute",
      background: `url('${process.env.PUBLIC_URL || ""}/img/homepage/condividi.svg') no-repeat center right`,
      backgroundSize: "contain !important",
      [theme.breakpoints.down('lg')]: {
        background: `url('${process.env.PUBLIC_URL || ""}/img/homepage/condividi.svg') no-repeat top right`
      },
      [theme.breakpoints.down('md')]: {
        position: "absolute",
        top: "325px",
        width: "100vw",
        height: "775px",
        background: `url('${process.env.PUBLIC_URL || ""}/img/homepage/grafica_home_02_mobile.svg') no-repeat center right`,
        backgroundSize: "unset !important"
      }
    },
    backgroundImageLeft: {
      top: "0",
      width: "24vw",
      height: "calc(100% - 100px)",
      left: "0",
      zIndex: "-1",
      position: "absolute",
      background: `url('${process.env.PUBLIC_URL || ""}/img/homepage/grafica_home_02_part_02.svg') no-repeat center left`,
      backgroundSize: "contain !important",
      [theme.breakpoints.down('lg')]: {
        width: "20vw",
        background: `url('${process.env.PUBLIC_URL || ""}/img/homepage/grafica_home_02_part_02.svg') no-repeat center left`
      },
      [theme.breakpoints.down('md')]: {
        position: "relative",
        width: "100vw",
        marginTop: "20px",
        height: "630px",
        background: `url('${process.env.PUBLIC_URL || ""}/img/homepage/grafica_home_02_part_02.svg') no-repeat center left`,
        backgroundSize: "contain"
      }
    },
    boxRoot: {
      position: "relative",
      height: "100%",
      maxWidth: "600px",
      marginRight: "auto",
      marginLeft: "18.750vw",
      [theme.breakpoints.down('lg')]: {
        marginLeft: theme.spacing(22)
      },
      [theme.breakpoints.down('md')]: {
        margin: "0",
        padding: "0 25px"
      }
    },
    title: {
      fontWeight: "500",
      fontSize: "40px",
      lineHeight: "48px",
      [theme.breakpoints.down('md')]: {
        fontSize: "24px",
        lineHeight: "29px"
      }
    },
    subtitle: {
      maxWidth: "490px",
      fontWeight: "300",
      fontSize: "18px",
      marginTop: theme.spacing(2),
      letterSpacing: "0.05em",
    },
    shareButton: {
      visibility: "hidden", // nascosto in attesa di destinazione
      width: "128px",
      marginTop: theme.spacing(4)
    }
  };
};

const defaultTitle = classes => {
  return <Typography whiteSpace={"preLine"} classes={{
    root: classes.title
  }} variant={'h2'} color="accent" semiBold>
        <T multiline defaultVal={"CONDIVIDI E RISPARMIA"}>pages.homepage.share_section_title</T>
    </Typography>;
};

const defaultSubtitle = classes => {
  return <Typography whiteSpace={"preLine"} classes={{
    root: classes.subtitle
  }} variant={'h4'} color="accent">
        <T multiline defaultVal={"Crea un gruppo per condivere un servizio, invita i tuoi amici a parteciparvi o trovali su Diveedi, ogni mese riceverai da ciascuno una quota per l’utilizzo del tuo account!"}>pages.homepage.share_section_subtitle</T>
    </Typography>;
};

export const ShareSection = ({
  classes = {},
  title = defaultTitle(classes),
  subtitle = defaultSubtitle(classes),
  ...props
}) => {
  return <Box {...props} classes={{
    root: classes.root
  }} componentname="share-section">
        <Box classes={{
      root: classes.boxRoot
    }} display="flex" flexDirection="column" justifyContent={{
      sm: "center"
    }}>
            {title}
            {subtitle}
            <Button classes={{
        root: classes.shareButton
      }} href="#" variant={"contained"} color="accent"><T defaultVal="Condividi">forms.buttons.share</T></Button>
            {
        /* <div className={classes.backgroundImageTop}></div> */
      }
        </Box>
        <div className={classes.backgroundImage}></div>
        <div className={classes.backgroundImageLeft}></div>
    </Box>;
};
export default withStyles(styles, {
  useTheme: true
})(ShareSection);