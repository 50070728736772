import React, {useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Icon} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";
import Box from "@material-ui/core/Box";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import {T, withI18n} from "@ticketag/i18nlib";

import {withErrorModal} from "../../components/ErrorMessage/ErrorMessage";

import moment from "moment";
import transitions from '@material-ui/core/styles/transitions';
import {WebapiprotoTransactionType} from "@ticketag/diveedi-client/dist/model/WebapiprotoTransactionType";
import {WebapiprotoTransaction} from "@ticketag/diveedi-client/dist/model/WebapiprotoTransaction";

const styles = (theme) => {
    return {
        root:        {},
        spacedTitle: {
            marginRight: theme.spacing(1)
        }
    }
};

const iconByType = (type) => {
    if (type === "PAYOUT") {
        return <Icon style={{fontSize: 45}} color="textSecondary">wallet</Icon>;
    }

    if (type === "SELL") {
        return <Icon style={{fontSize: 45}} color="textSecondary">moneyin</Icon>;
    }

    if (type === "PURCHASE") {
        return <Icon style={{fontSize: 45}} color="textSecondary">moneyout</Icon>;
    }
}

const getStateTranslation = (transaction) => {
    const key = `transaction_states.${transaction.transaction.type?.toLowerCase()}.${transaction.transaction.state?.toLowerCase()}`
    return <T defaultVal={transaction.transaction.state}>{key}</T>
}

const getStateColor = (rawTransaction) => {
    let transaction = rawTransaction.transaction;

    const defaultColor = 'textPrimary'
    switch (transaction.type) {
        case WebapiprotoTransactionType.PURCHASE:
            switch (transaction.state){
                case 'valid':
                    return 'textSecondary'
                case 'refunded':
                    return 'error'
            }
            return defaultColor
        case WebapiprotoTransactionType.SELL:
            switch (transaction.state){
                case 'valid':
                    return 'textSecondary'
                case 'pending':
                    return 'textPrimary'
                case 'refunded':
                    return 'error'
            }
            return defaultColor
        case WebapiprotoTransactionType.PAYOUT:
            switch (transaction.state) {
                case 'draft':
                case 'amount_set':
                    return 'textSecondary'
                case 'in_payment':
                case 'transfered':
                    return 'disabled'
                case 'payout_completed':
                case 'payout_confirmed':
                    return 'primary'
                case 'pending_verification':
                case 'payout_failed':
                    return 'error'
            }
            return defaultColor
    }

    return defaultColor
}


const TransactionDetails = ({transaction, i18n, classes, ...props}) => {
    const [loadingDetails, setLoadingDetails] = useState(true);

    const getTransactionInfo = (transaction) => {
        switch (transaction.type) {
            case WebapiprotoTransactionType.PURCHASE:
                return <T defaultVal={'Pagamento inviato a **{{.args.Name}}.**'}
                          useTemplate
                          useMarkdown
                          args={{
                              Name: transaction.toName
                          }}>pages.wallet.modal_payout</T>
            case WebapiprotoTransactionType.SELL:
                return <T defaultVal={'Pagamento ricevuto da **{{.args.Name}}.**'}
                          useTemplate
                          useMarkdown
                          args={{
                              Name: transaction.fromName
                          }}>pages.wallet.modal_sell</T>
            case WebapiprotoTransactionType.PAYOUT:
                return <T defaultVal={'Pagamento inviato a **{{.args.Name}}.**'}
                          useTemplate
                          useMarkdown
                          args={{
                              Name: transaction.toName
                          }}>pages.wallet.modal_purchase</T>
        }
    }

    return <Box>
        <Box display="flex" alignItems="center" mb={7}>
            <Box display="flex" mr={2}>
                {iconByType(transaction.type)}
            </Box>
            <Box display="flex" flexDirection="column">
                <Typography color={'textSecondary'} variant={'h6'} semiBold>
                    {transaction.serviceName}
                </Typography>
                <Typography color={'textPrimary'} variant={'subtitle2'}>
                    {getTransactionInfo(transaction)}
                </Typography>
            </Box>
        </Box>
        <Box display="flex" justifyContent="space-between">
            <Box mr={2}>
                <Typography variant={'body1'}>
                    <T defaultVal="Importo">modals.wallet.modal_amount</T>
                </Typography>
                <Typography color={'textSecondary'} variant={'h6'} semiBold>
                    {i18n.formatPrice(transaction.amount)} €
                </Typography>
            </Box>

            <Box>
                <Typography variant={'body1'}>
                    <T defaultVal="Data Contabile">modals.wallet.modal_date</T>
                </Typography>
                <Typography color={'textSecondary'} variant={'h6'} semiBold>
                    {moment(transaction.date).format("DD/MM/YYYY")}
                </Typography>
            </Box>
        </Box>
        {
            transaction.type === "PURCHASE" && transaction.paymentMethod
                ?
                <Box mt={2} display="flex">
                    <Typography variant={'body1'} className={classes.spacedTitle}>
                        <T defaultVal="N° Carta: ">modals.wallet.modal_card_num</T>
                    </Typography>
                    <Typography color={'textSecondary'} variant={'body1'} semiBold>
                        **** **** **** {transaction.paymentMethod}
                    </Typography>
                </Box>
                :
                <Box mt={4}/>
        }
        {
            transaction.type === "PAYOUT"
                ?
                <Box mt={2}>
                    <Typography variant={'body1'}>
                        <T defaultVal="IBAN">modals.wallet.modal_card_num</T>
                    </Typography>
                    <Typography color={'textSecondary'} variant={'h5'} semiBold>
                        {transaction.paymentMethod}
                    </Typography>
                </Box>
                :
                <Box mt={4}/>
        }
        { transaction.type === "SELL" ? <Box mt={4}/> : null}
        {transaction.state ? <Box mt={6}>
            <Typography variant={'h5'}>
                <T defaultVal="Stato">modals.wallet.modal_status</T>
            </Typography>
            <Typography color={getStateColor({transaction})} variant={'button'} semiBold>
                {getStateTranslation({transaction})}
            </Typography>
        </Box> : null}
        {
            transaction.accountDate
                ?
                <Box mb={3}>
                    <Typography color={'textPrimary'} variant={'body1'}>
                        <T defaultVal={'Verrà trasferito definitivamente il {{.args.Date}}'}
                           useTemplate
                           useMarkdown
                           args={{
                               Date: moment(transaction.accountDate).format("DD/MM/YYYY")
                           }}>pages.wallet.modal_transfer_date</T>
                    </Typography>
                </Box>
                :
                null
        }
    </Box>;
};

export default withStyles(styles, {useTheme: true})(TransactionDetails);
