import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from "../TextField/TextField";
import Box from "@material-ui/core/Box";
import DragDropUpload from "./DragDropUpload";
import { fade } from '@material-ui/core/styles/colorManipulator';
import { ImageIcon, PlusCircleIcon } from "../Icon/Icon";
import { FormLabel } from "@material-ui/core";
import Resizer from 'react-image-file-resizer';

const styles = theme => {
  return {
    root: {
      width: 216 - theme.spacing(4),
      height: 216 - theme.spacing(4),
      borderRadius: '5px',
      backgroundColor: fade(theme.palette.action.disabled, 0.1),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: theme.spacing(2),
      cursor: 'pointer'
    },
    label: {
      textAlign: 'center',
      marginTop: theme.spacing(3),
      lineHeight: '170%',
      letterSpacing: '0.01em',
      fontWeight: '300'
    },
    img: {
      maxWidth: '100%',
      maxHeight: '100%',
      filter: 'blur(2px)'
    }
  };
};

const IMAGE_FORMAT = 'JPEG';
const MAX_IMAGE_HEIGHT = 1200;
const MAX_FILE_SIZE = 3500000;
export const ERR_FILE_TOO_LARGE = "File is too large";
export const ERR_FILE_NOT_VALID = "File is not valid";

const resizeAndConvertImage = function (file) {
  const reader = new FileReader();
  const image = new Image();

  const resizeAndConvert = (file, width, height, rotate, respFunc) => {
    Resizer.imageFileResizer(file, width, height, IMAGE_FORMAT, 80, rotate, respFunc, 'blob');
  };

  return new Promise(function (resolve, reject) {
    if (!file.type.match(/image.*/)) {
      reject(new Error(ERR_FILE_NOT_VALID));
    }

    reader.onload = function (e) {
      resolve(e.target.result);
    };

    image.onload = function () {
      try {
        let width = image.width;
        let height = image.height;
        let rotate = 0;

        if (width >= height) {
          if (width > MAX_IMAGE_HEIGHT) {
            width = MAX_IMAGE_HEIGHT;
            height = Math.ceil(height / width * MAX_IMAGE_HEIGHT);
          }
        } else {
          rotate = 90;

          if (width > MAX_IMAGE_HEIGHT) {
            width = Math.ceil(height * MAX_IMAGE_HEIGHT / width);
            height = MAX_IMAGE_HEIGHT;
          }
        }

        resizeAndConvert(file, width, height, rotate, blob => {
          if (!blob.size) {
            resject(new Error(ERR_FILE_NOT_VALID));
          }

          if (blob.size > MAX_FILE_SIZE) {
            reject(new Error(ERR_FILE_TOO_LARGE));
          }

          reader.readAsDataURL(blob);
        });
      } catch (e) {
        reject(new Error(ERR_FILE_NOT_VALID));
      }
    };

    image.src = URL.createObjectURL(file);
  });
};

export const FileUploader = ({
  label,
  onChange = () => {},
  onError = () => {},
  classes,
  defaultValue,
  ...props
}) => {
  const inputRef = React.useRef(null);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [currentImage, setCurrentImage] = React.useState(defaultValue);

  const handleChange = e => {
    setLoading(true);
    resizeAndConvertImage(e.currentTarget.files[0]).then(image => {
      setLoading(false);
      setCurrentImage(image);
      onChange(e, image);
    }).catch(err => {
      console.log(err);
      setLoading(false);
      onError(err.message);
    });
  };

  return <Box label={label} variant={"standard"} {...props} componentname="file-uploader">
        <DragDropUpload customClass={classes.root} handleDrop={e => handleChange(e)} onClick={() => inputRef.current.click()}>
            {currentImage ? <img className={classes.img} src={currentImage} /> : <>
                    <ImageIcon overlayIcon={<PlusCircleIcon color="primary" />} size="large" color="textSecondary" /><FormLabel classes={{
          root: classes.label
        }}>{label}</FormLabel>
                </>}
        </DragDropUpload>
        <input type="file" ref={uploader => inputRef.current = uploader} accept="image/jpeg, image/png" hidden onChange={handleChange} />
    </Box>;
};
export default withStyles(styles, {
  useTheme: true
})(FileUploader);