import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T, withI18n} from "@ticketag/i18nlib";
import {withApi} from "../../hoc/Api/Provider";
import AuthContext from "../../hoc/Auth/context";
import Box from "@material-ui/core/Box/Box";
import Grid from "@material-ui/core/Grid";
import withWidth from "@material-ui/core/withWidth";
import Checkbox from "@ticketag/ticketag-uilib/src/components/base/Checkbox/Checkbox";
import {withModals} from "@ticketag/ticketag-uilib/src/components/hoc/Modals/Provider";
import PaymentMethods from "../../partials/PaymentMethods/PaymentMethods";
import {withErrorModal, withRedirect} from "../../components/ErrorMessage/ErrorMessage";
import {Hidden} from "@material-ui/core";
import Layout from "../../hoc/Layout/Layout";
import Title from "../../components/Title/Title";
import Scroll from "../../components/Scroll/Scoll";
import TextFooter from "../../components/TextFooter/TextFooter";
import Help from "../../components/Help/Help";
import {pages} from "../../constants";
import {SeoDescription, SeoTitle} from "../Seo/Seo";
import Page from "../../components/Page/Page";

const styles = (theme) => {
    return {
        root: {},
        billingProfilesBox: {
            [theme.breakpoints.up('lg')]: {
                paddingRight: theme.spacing(10),
            },
        },
        billingProfilesEmpty: {
            [theme.breakpoints.up('lg')]: {
                paddingRight: theme.spacing(6),
            },
        },
        divider: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(3)
        },
        nextButton: {
            width: "80px",
            height: "80px",
            borderRadius: "50%",
            marginBottom: "40px"
        },
    }
};

const defaultSummarySubtitle = 'In questa fase non ti verrà addebitato alcun importo. Preleveremo il totale dal tuo conto al termine del primo mese di utilizzo del servizio.'

class Payment extends Component {
    static contextType = AuthContext

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            useSelectedBillingData: true,
            mainProfileUuid: null,
            tAndCChecked: false,
            paymentMethodValid: false,
            paymentMethodForm: null
        }
        // this.renderPaymentStep.bind(this)
        this.paymentMethods = []
    }

    componentDidMount() {
        //this.props.match.params.joinRequestUuid
        this.loadData()
    }


    loadData() {
        const{joinRequest, serviceFamily} = this.props;
        this.joinRequestUuid = joinRequest.uuid
        if (joinRequest.state === 'active') {
            this.props.history.push(this.props.i18n.buildLocalizedUrl('/groups', joinRequest.groupUuid, 'details'))
            throw {message: 'invalid state'}
        }
        const now = new Date()
        const nextMonth = new Date()
        nextMonth.setMonth(now.getMonth()+1)
        const formatDate = (dt) => dt.toLocaleString('default', { month: 'short', year: 'numeric', day: 'numeric' })
        joinRequest.renewalPeriodLabel = <><T defaultVal="Rinnovo">global.periods.renewal</T> <T defaultVal={joinRequest.renewalPeriod}>{`global.periods.${joinRequest.renewalPeriod}`}</T></>
        this.setState({
            isLoaded: true,
            formValid: this.paymentMethods.length > 0,
            groupData: joinRequest,
            monthRange: `${formatDate(now)} - ${formatDate(nextMonth)}`,
            serviceFamily: serviceFamily
        })
    }

    loadUserProfiles() {
        return this.props.api.userApi.getUserProfiles()
    }

    cancelJoinRequest() {
        withErrorModal(this.props.modals, this.props.api.joinApi.deleteJoinRequest(this.joinRequestUuid).then(resp => {
            this.props.history.push(this.props.i18n.buildLocalizedUrl('groups'))
        }))
    }

    confirmGroupJoin() {
        const groupUuid = this.props.match.params.groupUuid
        this.setLoading()
        this.props.api.joinApi.confirmGroupJoin(this.joinRequestUuid).then(data => {
            this.props.history.push(this.props.i18n.buildLocalizedUrl('groups', groupUuid, 'join', 'processing') + `?join_request_uuid=${this.joinRequestUuid}`)
        }).catch((err) => {
            this.props.history.push(this.props.i18n.buildLocalizedUrl('groups', groupUuid, 'join', 'fail'))
        })
    }

    goToSummary() {
        this.props.history.push(this.props.i18n.buildLocalizedUrl("groups", this.props.match.params.groupUuid, "join", "summary"))
    }


    //TODO: larghezza stepper
    renderContent() {
        if (!this.state.isLoaded) {
            return null
        }

        const verticalLayout = ['xs', 'sm'].indexOf(this.props.width) !== -1
        const isSmall = ['xs', 'sm', 'md'].indexOf(this.props.width) !== -1

        return (
                    <Box display={"flex"} justifyContent={"center"} flexGrow={1}>
                        <Box width={"312px"} display={"flex"} flexDirection={"column"} height={"100%"}>
                            <PaymentMethods
                                size={"big"}
                                centered={true}
                                disabled={!this.state.tAndCChecked}
                                onSubmit={() => this.goToSummary()}
                                isLoaded={this.state.isLoaded} api={this.props.api} modals={this.props.modals}
                                narrowCards={verticalLayout}
                                i18n={this.props.i18n} ref={(r) => this.paymentMethods = r}
                                onChange={(formValues,formState) => this.setState({formValid: formState.isValid})}
                                inlineVariant
                            >
                                <Box mt={5} mb={3}>
                                    <Checkbox onChange={(e) => {
                                        this.setState({tAndCChecked: e.target.checked})
                                    }} label={`Termini e condizioni ${this.state.groupData?.baseServiceName}`}/>
                                </Box>
                            </PaymentMethods>
                        </Box>
                    </Box>
        )
    }

    render() {
        const isLoading = !this.state.isLoaded
        const serviceFamily = this.props.serviceFamily
        const {auth, i18n, history} = this.props;
        const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1)
        const {
            seo,
            title,
            helpTitle,
            footer,
            layout,
            ...other
        } = this.props;
        return (
            <Page layout={{
                ...layout,
                onGoBack: () => this.props.deleteJoinRequest()
                    .finally(() => history.goBack()),
                onClose: () => this.props.deleteJoinRequest()
                    .finally(() => history.replace(i18n.buildLocalizedUrl("groups")))
            }} {...other}>
                <SeoTitle>{title}</SeoTitle>
                <SeoDescription args={{Name: capitalize(serviceFamily.name)}}> {seo.description}</SeoDescription>
                <Title>{title}</Title>
                <Scroll>
                    {
                        isLoading ?
                            null :
                            this.renderContent()
                    }
                </Scroll>
                <TextFooter
                    helpTitle={helpTitle}
                    help={
                        <Help id={"checkout"}>
                            {`checkout`}
                        </Help>
                    }>
                    {footer}
                </TextFooter>
            </Page>
        )
    }
}

export default withStyles(styles, {useTheme: true})(withApi(withI18n(withWidth()(withModals(Payment)))));
