import { withStyles } from "@material-ui/core";
import Select from "./ComboBox";
import Flag from "../Icon/Flag/Flag";
import React from "react";

const styles = theme => {
  return {
    root: {},
    entry: {
      cursor: "pointer",
      display: "inline-flex",
      alignItems: "center",
      verticalAlign: "middle",
      fontWeight: "500",
      maxHeight: "24px"
    },
    select: {
      color: theme.palette.text.secondary,
      fontWeight: "400"
    },
    label: {},
    icon: {
      width: "18px",
      height: "12px",
      margin: "0 10px"
    }
  };
};

function TLanguageComboBox(props) {
  const {
    locales,
    classes,
    ...other
  } = props;
  return <Select className={classes.root} options={locales} getOptionValue={option => option.uuid} getOptionLabel={option => {
    return <div key={option.countryCode} className={classes.entry}>
                    <Flag className={classes.icon} code={option.countryCode} />
                    <span className={classes.label}>{option.languageLabel}</span>
                </div>;
  }} renderValue={val => {
    const locale = locales.filter(elt => elt.uuid === val)[0];
    return <span key={locale.countryCode} className={classes.select}>
                    <Flag className={classes.icon} code={locale.countryCode} />{locale.languageLabel}
                </span>;
  }} {...other} componentname="t-language-combo-box" />;
}

export default withStyles(styles, {})(TLanguageComboBox);
