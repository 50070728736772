/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * The WebapiprotoUpdatePasswordRequest model module.
 * @module model/WebapiprotoUpdatePasswordRequest
 * @version v0.4
 */
export class WebapiprotoUpdatePasswordRequest {
  /**
   * Constructs a new <code>WebapiprotoUpdatePasswordRequest</code>.
   * @alias module:model/WebapiprotoUpdatePasswordRequest
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>WebapiprotoUpdatePasswordRequest</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/WebapiprotoUpdatePasswordRequest} obj Optional instance to populate.
   * @return {module:model/WebapiprotoUpdatePasswordRequest} The populated <code>WebapiprotoUpdatePasswordRequest</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new WebapiprotoUpdatePasswordRequest();
      if (data.hasOwnProperty('old_password'))
        obj.oldPassword = ApiClient.convertToType(data['old_password'], 'String');
      if (data.hasOwnProperty('new_password_1'))
        obj.newPassword1 = ApiClient.convertToType(data['new_password_1'], 'String');
      if (data.hasOwnProperty('new_password_2'))
        obj.newPassword2 = ApiClient.convertToType(data['new_password_2'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} oldPassword
 */
WebapiprotoUpdatePasswordRequest.prototype.oldPassword = undefined;

/**
 * @member {String} newPassword1
 */
WebapiprotoUpdatePasswordRequest.prototype.newPassword1 = undefined;

/**
 * @member {String} newPassword2
 */
WebapiprotoUpdatePasswordRequest.prototype.newPassword2 = undefined;


