import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T, withI18n} from "@ticketag/i18nlib";
import Progress from "@ticketag/ticketag-uilib/src/components/base/Progress/Progress";
import {withApi} from "../../hoc/Api/Provider";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import Button from "@ticketag/ticketag-uilib/src/components/base/Button/Button";
import TextField from "@ticketag/ticketag-uilib/src/components/base/TextField/TextField";
import Form from "@ticketag/ticketag-uilib/src/components/complex/Form/Form";
import {withModals} from "@ticketag/ticketag-uilib/src/components/hoc/Modals/Provider";
import {SendSuggestionComplete} from "../../hoc/Modals/Modals";
import Header from "@ticketag/ticketag-uilib/src/components/base/Header/Header";
import {withErrorModal} from "../../components/ErrorMessage/ErrorMessage";
import Layout from "../../hoc/Layout/Layout";
import Title from "../../components/Title/Title";
import Subtitle from "../../components/Subtitle/Subtitle";
import {pages} from "../../constants";
import Page from "../../components/Page/Page";
import Hidden from "@material-ui/core/Hidden";
import ListPage from "../../partials/ListPage/ListPage";



const styles = (theme) => {
    return {
        root: {},
        box: {

        },
        input: {
            maxWidth: '360px'
        },
        inputLabel: {
            "&:not(.Mui-disabled):not(.Mui-error)": {
                color: theme.palette.text.primary,
            }
        },
        form: {
            width: '100%'
        }
    }
};

const defaultSubtitle='Diveedi è in continua espansione, ma abbiamo bisogno del tuo aiuto per arricchire la nostra offerta: ci sono altri servizi che vorresti ottenere o condividere? Faccelo sapere ora!'

class Suggestions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            formValid:false,
            suggestion: "",
        }
        this._isMounted=false
    }
    componentDidMount() {
        this._isMounted=true
        this.setState({isLoaded: true})
    }

    componentWillUnmount() {
        this._isMounted=false
    }

    sendSuggestion() {
        this.form.trigger().then(({isValid}) => {
            if (isValid) {
                this.setState({isLoading: true})
                withErrorModal(
                    this.props.modals,
                    this.props.api.systemApi.suggestService(JSON.stringify({body: this.state.suggestion})).then(() => {
                        SendSuggestionComplete(this.props.modals).finally(() => {
                            this.props.history.goBack()
                        })
                }))
            }
        })
    }

    renderContent() {
        const defaultBoxProps = {display: 'flex', justifyContent: 'center'}
        const fieldRules = {
            required: {value: true, message: {key: 'pages.suggestions.insert_suggestion', defaultVal: 'Inserisci il nome di un servizio'}}
        }
        return <Grid container>
            <Grid item xs={12}>
                <Box mb={9} classes={{root: this.props.classes.box}} {...defaultBoxProps}>
                    <Form onValidate={({isValid}) => {
                        if (isValid !== this.state.formValid) {this.setState({formValid: isValid})}
                    }}
                          T={T} ref={(f) => this.form = f} classes={{root: this.props.classes.form}} grid={{justify: 'center'}}>
                        <TextField
                            onChange={(e, val) => this.setState({suggestion: val})}
                            name="suggestion"
                            rules={fieldRules}
                            box={defaultBoxProps}
                            classes={{root: this.props.classes.input, label: this.props.classes.inputLabel}}
                            variant="outlined"
                            label="I servizi che vorresti"/>
                    </Form>

                </Box>
                <Box mb={10} classes={{root: this.props.classes.box}} {...defaultBoxProps}>
                    <Button disabled={!this.state.formValid} onClick={() => this.sendSuggestion()} fixedWidth variant="outlined">Invia</Button>
                </Box>
            </Grid>
        </Grid>
    }

    render() {
        const {
            seo,
            layout,
            title,
            subtitle,
            ...other
        } = this.props;
        const isLoading = !this.state.isLoaded
        return (
            <Page seo={seo} layout={layout} {...other}>
                    <Title isLoading={isLoading}>{title}</Title>
                    <Subtitle isLoading={isLoading}>{subtitle}</Subtitle>
                {this.renderContent()}
            </Page>
        )
    }
}

export default withStyles(styles, {useTheme: true})(withModals(withApi(withI18n(withModals(Suggestions)))));
