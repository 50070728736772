import {Redirect} from "react-router-dom";
import {withI18n} from "@ticketag/i18nlib";
import React from "react";

function notFound(props) {
    return <Redirect to={props.i18n.buildLocalizedUrl("/error/404")}/>
}

const NotFound = withI18n(notFound)

export default withI18n(NotFound)