import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T} from "@ticketag/i18nlib";
import {Box} from "@material-ui/core";


const loaderSvg = <svg  className={'partial-loader'} width="115" height="110" viewBox="0 0 115 110" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.0175 46.8311H5.20355C2.32978 46.8311 0 49.1608 0 52.0346C0 54.9084 2.32978 57.2382 5.20355 57.2382H26.0175C28.8913 57.2382 31.2211 54.9084 31.2211 52.0346C31.2208 49.1608 28.8911 46.8311 26.0175 46.8311Z" fill="#A56FD9"/>
    <path d="M109.271 46.8311H88.4575C85.5837 46.8311 83.2539 49.1608 83.2539 52.0346C83.2539 54.9084 85.5837 57.2382 88.4575 57.2382H109.271C112.145 57.2382 114.475 54.9084 114.475 52.0346C114.475 49.1608 112.145 46.8311 109.271 46.8311Z" fill="#57D9CB"/>
    <path d="M31.484 70.4323L16.7663 85.1501C14.7341 87.1823 14.7341 90.4766 16.7663 92.5088C17.7823 93.5248 19.1142 94.0328 20.4459 94.0328C21.7776 94.0328 23.1095 93.5246 24.125 92.5088L38.8427 77.791C40.8749 75.7588 40.8749 72.4642 38.8427 70.4323C36.8105 68.4003 33.5157 68.4001 31.484 70.4323Z" fill="#C183FF"/>
    <path d="M57.2387 0C54.3649 0 52.0352 2.32978 52.0352 5.20355V15.6104C52.0352 18.4842 54.3649 20.814 57.2387 20.814C60.1125 20.814 62.4423 18.4842 62.4423 15.6104V5.20355C62.4423 2.32978 60.1125 0 57.2387 0Z" fill="#C8FFF9"/>
    <path d="M90.3512 11.5612L75.6335 26.2791C73.6013 28.3113 73.6013 31.6059 75.6335 33.6378C76.6495 34.6538 77.9814 35.162 79.3131 35.162C80.6448 35.162 81.9767 34.6538 82.9922 33.6378L97.7099 18.92C99.742 16.8878 99.742 13.5932 97.7099 11.5612C95.6781 9.52907 92.3829 9.52907 90.3512 11.5612Z" fill="#93FFF3"/>
    <path d="M57.2387 78.0527C54.3649 78.0527 52.0352 80.3825 52.0352 83.2563V104.07C52.0352 106.944 54.3649 109.274 57.2387 109.274C60.1125 109.274 62.4423 106.944 62.4423 104.07V83.2563C62.4423 80.3825 60.1125 78.0527 57.2387 78.0527Z" fill="#8B82FA"/>
    <path d="M82.9922 70.4323C80.9607 68.4002 77.6655 68.4002 75.6335 70.4323C73.6013 72.4641 73.6013 75.7591 75.6335 77.7911L90.3512 92.5089C91.3671 93.5249 92.699 94.0329 94.0307 94.0329C95.3622 94.0329 96.6943 93.5247 97.7099 92.5089C99.742 90.4767 99.742 87.1822 97.7099 85.1502L82.9922 70.4323Z" fill="#6FAAFF"/>
</svg>


const styles = (theme) => {
    return {
        root: {}
    }
};

export const PartialLoader = (props) => {

    return <Box>{loaderSvg}</Box>
}

export default withStyles(styles, {useTheme: true})(PartialLoader);
