import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
import Box from "@material-ui/core/Box";
import Carousel from "@ticketag/ticketag-uilib/src/components/complex/Carousel/Carousel";
import ImageCard from "@ticketag/ticketag-uilib/src/components/complex/ImageCard/ImageCard";
import withStyles from "@material-ui/core/styles/withStyles";
import Tabs, {TabDesktop} from "./Tabs";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import {T} from "@ticketag/i18nlib";
import {SelectArrowIcon} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";
import {Menu, MenuItem, ThemeProvider} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";


const styles = (theme) => {
    return {
        carouselMobile: {
            marginLeft: "-36px",
            [theme.breakpoints.down("sm")]: {
                height: "236px"
            },
            //marginBottom: "32px",
            "& > ul": {
                paddingLeft: "32px"

            }
        },
        carouselDesktop: {
            width: "100%",
            paddingBottom: "32px"
        },
        scrollButton: {
          marginTop: "8px"
        },
        scrollButtonLeft: {
            transform: "rotate(90deg)"
        },
        scrollButtonRight: {
            transform: "rotate(270deg)"
        },
        selectContainer: {
            cursor: "pointer"
        },
        selectTitle: {
            fontSize: "26px",
            fontWeight: "600",
            lineHeight: "32px"
        },
        selectArrow: {
            marginTop: "8px",
            marginLeft: "4px",
            width: "32px",
            height: "32px"

        },
        selectMenuPaper: {
            minWidth: "240px"
        },
        selectMenuItem: {
            fontSize: "20px"
        },
        priceMain: {
            display: "block",
            paddingBottom: "10px",
            fontSize: "18px",
            fontWeight: "600",
            lineHeight: "18px",
            letterSpacing: "0.05em",
            [theme.breakpoints.up('md')]: {
                fontSize: "32px",
                lineHeight: "32px",
                paddingBottom: "24px",
            }
        },
        priceOverline: {
            display: "block",
            fontSize: "10px",
            lineHiehgt: "12px",
            fontWeight: "500",
            letterSpacing: "0.05em",
            [theme.breakpoints.up('md')]: {
                fontSize: "12px",
                lineHeight: "14px",
            }
        }
    }
}


function Select(props) {
    const {classes, tags, onChange, color, ...other} = props;
    const [value, setValue] = React.useState(0)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = (event) => {
        setAnchorEl(null);
    }
    const handleSelect = (index) => () => {
        setAnchorEl(false)
        setValue(index)
        onChange(index)
    }
    if (tags && tags.length) {
        const selectedTag = tags[value]
        return (
            <Box>
                <Box  display={"flex"} alignItems={"center"} className={classes.selectContainer} onClick={handleOpen}>
                    <Typography key={selectedTag.key} className={classes.selectTitle} variant={"h4"} color={color}>
                        <T defaultVal={selectedTag.title}>{`pages.homepage.${selectedTag.key}`}</T>
                    </Typography>
                    <SelectArrowIcon className={classes.selectArrow} color={color}/>
                </Box>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    classes={{paper: classes.selectMenuPaper}}
                >
                    { tags.map((tag, index) => (
                        <MenuItem
                            classes={{root: classes.selectMenuItem}}
                            key={index} onClick={handleSelect(index)}><T defaultVal={tag.title}>{`pages.homepage.${tag.key}`}</T></MenuItem>
                    ))}
                </Menu>
            </Box>
        )
    }
    return (
        <Typography className={classes.selectTitle}>
            <Skeleton width={160}/>
        </Typography>
    )
}

function TMainSection(props) {
    const {
        classes,
        api,
        contrast,
        isLoading: isLoadingProp,
        history,
        withMobileTab,
        i18n,
        tags,
        ...other
    } = props;
    const theme = useTheme();
    const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    const [selected, setSelected] = React.useState(0)
    const [services, setServices] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    let currentTag = selected
    React.useEffect(() => {
        if (!isLoadingProp) {
            api.GetServiceFamilies(true, {tags: [tags[selected]?.key], _public: true}).then((res) => {
                setServices(res)
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [isLoadingProp, selected]);
    const key = React.useMemo(() => selected, [services])
    const handleChange = (e, val) => {
        setSelected(val)
    }
    const handleClick = (elt) => (evt) => {
        if (!withMobileTab) { // è la plp
            history.push(i18n.buildLocalizedUrl("services", elt.slug, "join"))
        } else { // è l'homepage
            history.push(i18n.buildLocalizedUrl("services", elt.slug))
        }
    }
    const isLoading = loading || isLoadingProp
    return (
        <Box mb={{xs: 4, md: 0}}>
            <Box mb={4} display={"flex"} justifyContent={isMobile ? "flex-start" : "center"} alignItems={"center"}>
                {
                    isMobile ?
                            <Select color={contrast ? "gradient" : "textSecondary"} tags={tags} classes={classes} onChange={(val) => handleChange(null, val)}/> :
                        <Tabs
                            contrast={contrast}
                            isLoading={isLoading}
                            mobile={isMobile}
                            tags={tags} value={selected}
                            onChange={handleChange}
                        />
                }
            </Box>
            <Box display={"flex"} alignItems={"center"}>
                <Carousel
                    key={key}
                    contrast={contrast}
                    className={isMobile ? classes.carouselMobile : classes.carouselDesktop}
                    scaling={1.2}
                    spacing={8}
                    isLoading={isLoading}
                    //buttonWidth={40}
                    height={isMobile ? 142 : 273}
                    cols={isMobile ? 3.2 : isDesktop ? 5 : 3}
                    fluid={isMobile}
                >{
                    services.map((elt, i) =>
                        <ImageCard contrast={contrast} shadow={isMobile ? "mobile" : "desktop"} color={elt.color || "transparent"} key={i} logo={elt.whiteLogo} img={elt.cardImage} onClick={handleClick(elt)}>
                            <Typography color={"white"}>
                                <span className={classes.priceOverline}>Ottieni da</span>
                                <span className={classes.priceMain}>{(elt.bestPrice/100).toFixed(2) + "€"}</span>
                            </Typography>
                        </ImageCard>
                    )
                }
                </Carousel>
            </Box>
        </Box>
    )
}

export default withStyles(styles)(TMainSection)
