import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from "clsx";
import { CardActionArea, CardHeader, Grid, SvgIcon } from "@material-ui/core";
import TextField from "../../base/TextField/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "../../base/Typography/Typography";
import Box from "@material-ui/core/Box";
import { MoreVertIcon, SendIcon } from "../../base/Icon/Icon";
import IconButton from "@material-ui/core/IconButton";
import Menu from "../../base/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import BaloonAnchorLeft from "../../../resources/baloons/baloon_anchor_left.svg";
import BaloonAnchorRight from "../../../resources/baloons/baloon_anchor_right.svg";
import Snackbar from "../../../resources/snackbar.svg";
import capitalize from "@material-ui/core/utils/capitalize"; // We can inject some CSS into the DOM.

const styles = theme => ({
  root: {},
  rootLeft: {
    display: "flex",
    flexDirection: "row"
  },
  rootRight: {
    display: "flex",
    flexDirection: "row-reverse"
  },
  content: {
    maxWidth: "calc(100% - 64px)",
    padding: "12px 14px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: "200",
    fontSize: "16px"
  },
  contentRight: {
    borderRadius: "8px 0 8px 8px"
  },
  contentLeft: {
    borderRadius: "0 8px 8px 8px"
  },
  anchorPrimary: {
    fill: "rgba(193, 131, 255, 0.2)"
  },
  anchorSecondary: {
    fill: "rgba(102, 255, 239, 0.63)"
  },
  colorPrimary: {
    backgroundColor: "rgba(193, 131, 255, 0.2)"
  },
  colorSecondary: {
    backgroundColor: "rgba(102, 255, 239, 0.63)"
  },
  title: {
    fontSize: "14px",
    fontWeight: "600",
    height: "0",
    transform: "translate(0, -36px)"
  },
  subtitle: {
    fontSize: "12px",
    fontWeight: "300",
    height: "0",
    transform: "translate(0, 16px)"
  }
});

const ChatBaloonAnchor = props => {
  const {
    classes,
    position,
    ...other
  } = props;
  return <SvgIcon className={classes.root} component={position === "left" ? BaloonAnchorLeft : BaloonAnchorRight} />;
};

function TChatBaloon(props) {
  const {
    classes,
    color = 'primary',
    children,
    position = "left",
    title,
    subtitle,
    className,
    ...other
  } = props;
  return <Box pt={title ? 2 : 0} pb={subtitle ? 2 : 0} className={clsx(classes.root, classes[`root${capitalize(position)}`])} componentname="t-chat-baloon">
                <ChatBaloonAnchor position={position} classes={{
      root: classes[`anchor${capitalize(color)}`]
    }} />
                    <div className={clsx(classes.content, classes[`content${capitalize(position)}`], classes[`color${capitalize(color)}`])}>
                        <Typography className={classes.title} align={position === "left" ? "left" : "right"} variant={"h6"}>{title}</Typography>
                        <span style={{
        wordWrap: "break-word"
      }}>{children}</span>
                        <Typography className={classes.subtitle} align={position === "left" ? "left" : "right"} variant={"h6"}>{subtitle}</Typography>
                    </div>
            </Box>;
}

export default withStyles(styles, {
  useTheme: true
})(TChatBaloon);
