import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T, withI18n} from "@ticketag/i18nlib";
import Progress from "@ticketag/ticketag-uilib/src/components/base/Progress/Progress";
import {withApi} from "../../hoc/Api/Provider";
import AuthContext from "../../hoc/Auth/context";
import {Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@ticketag/ticketag-uilib/src/components/base/Button/Button";
import Box from "@material-ui/core/Box";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import {getDefaultMessage} from "../../services/errors/defaults";
import qs from "qs";
import Layout from "../../hoc/Layout/Layout";
import {pages} from "../../constants";
import Page from "../../components/Page/Page";
import {withPage} from "../../hoc/Page/Provider";


const styles = (theme) => {
    return {
        root: {},
        errorContainer: {
            minHeight: '50vh',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '100%'
        }
    }
};

class Error extends Component {
    static contextType = AuthContext

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            error: {
                title: '',
                message: '',
                code: null
            }
        }
        this._isMounted=false
    }
    componentDidMount() {
        this._isMounted=true
        const errorState= {
            title: "errors.generic.generic", //C’è un po’ troppo silenzio qui!
            message: "errors.messages.default", //Sfortunatamente non siamo riusciti a trovare la pagina che cercavi.
            code: null,
            debugMessage: null,
            statusCode: this.props?.match?.params?.errorCode
        }
        if (this.props?.match?.params?.errorCode) {
            Object.assign(errorState, getDefaultMessage(this.props.match.params.errorCode))
        }
        //
        localStorage.removeItem('redirect-count')
        const cachedError = localStorage.getItem('error')
        if (cachedError) {
            Object.assign(errorState, JSON.parse(cachedError))
            localStorage.removeItem('error')
        }
        const parsedQs = qs.parse(window.location.href)
        if (parsedQs.message && parsedQs.string_code) {
            Object.assign(errorState, {
                title: parsedQs.string_code,
                message: parsedQs.message,
                stack: parsedQs.stack||null,
                debugMessage: parsedQs.debug_message
            })
        }

        this.setState({
            isLoaded: true,
            error: errorState
        })
    }

    componentWillUnmount() {
        this._isMounted=false
    }

    renderContent() {
        return <Grid item xs={12}>
            <Box classes={{root: this.props.classes.errorContainer}} display="flex" flexDirection="column">
                <ErrorMessage code={this.state.error.code}
                              statusCode={this.state.error.statusCode}
                              stack={this.state.error.stack}
                              title={<T>{this.state.error.title}</T>}
                              debugMessage={this.state.error.debugMessage}
                              message={<T>{this.state.error.message}</T>}/>
                <Box>
                    <Button onClick={() => {this.props.history.go(-2)}} variant="outlined">Torna indietro</Button>
                </Box>
            </Box>
        </Grid>
    }

    render() {
        const isLoading = !this.state.isLoaded
        if (isLoading) {
            return null
        }
        const props = this.props;
        return (
            <Page {...props}>
                {
                    this.renderContent()
                }
            </Page>
        );
    }
}

export default withStyles(styles, {useTheme: true})(withPage(withApi(withI18n(Error))));
