import withStyles from "@material-ui/core/styles/withStyles";
import Header from "@ticketag/ticketag-uilib/src/components/base/Header/Header";
import {T} from "@ticketag/i18nlib";
import {withPage} from "../../hoc/Page/Provider";
import React from "react";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import Box from "@material-ui/core/Box";
import withWidth from "@material-ui/core/withWidth";


const styles = (theme) => {
    return {
        root: {
        },
        title: {
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "28px",
            paddingBottom: "8px"
        },
        content: {
            fontSize: "16px",
            fontWeight: "400",
            paddingBottom: "38px",

        }
    }
}

function Help(props) {
    const {children, classes, id, T : tComponent, multiline = true, useMarkdown = true, useTemplate = true, args, align = "center", title, page, ...other} = props
    const content =
        page && typeof children === "string" ?
            <T multiline={multiline} useMarkdown={useMarkdown} useTemplate={useTemplate} args={args} defaultVal={children}>{`help.${page}.${id}_content`}</T>
            : tComponent || children
    return (
        <Box>
            {
                title ?
                    <Typography color={"textSecondary"} className={classes.title} align={align} variant={"h6"} {...other}>
                        {
                            page && typeof title === "string" ?
                                <T multiline={multiline} useTemplate={useTemplate} args={args} defaultVal={children}>{`help.${page}.${id}_title`}</T>
                                : null
                        }
                    </Typography>
                    :null
            }
            <Typography whiteSpace={"preLine"} color={"textPrimary"} className={classes.content} align={align} variant={"body1"} {...other}>
                {content}
            </Typography>
        </Box>
    )
}

export default withStyles(styles)(withWidth()(withPage(Help)))