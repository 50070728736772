import React, {Component} from 'react';
import Progress from "@ticketag/ticketag-uilib/src/components/base/Progress/Progress";
import ChatContext from "./context";
import {withApi} from "../Api/Provider";
import ProgressPromise from "@ticketag/wsrpc-client/src/progress-promise";

export const CustomerCareTypeCode = 1
export const GroupTypeCode = 0

export class ChatProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chatApi: this.props.api.chat,
            chats: [],
            openedChat: null,
            isLoading: false
        }
    }
    componentDidMount() {
        this.props.api.listenEvent('user.message_received', '*', (evt) => {
            this.getBaseInfos()
        })
        this.getBaseInfos()
    }
    getBaseInfos(){
        return new Promise((resolve, reject) => {
            this.state.chatApi.BaseInfo({}).then(chats => {
                this.setState({
                    chats:chats.map((chat) => this.getChat(chat)),
                    loaded: true,
                })
                resolve(this.state.chats)
            }).catch(err =>{
                reject(err)
            })
        })
    }
    getUnreadCount(uuid) {
        if (uuid) {
            return this.state.chats.find((chat) => chat.uuid === uuid).unread
        }
        return this.state.chats.reduce((acc, elt) => acc + elt.unread, 0)
    }
    getChat(chat) {
        chat.open = (fn) => this.openChat(chat.uuid)(fn)
        return chat
    }
    groupChats() {
        return this.state.chats.filter((elt) => elt.type === GroupTypeCode)
    }
    helpChat() {
        return this.state.chats.find((elt) => elt.type === CustomerCareTypeCode)
    }
    openChat(uuid) {
        return (function (openFn)  {
            const api = this.props.api
            const openedChat = this.state.openedChat
            const toBeOpenedChat = this.state.chats.find((elt) => elt.uuid === uuid)
            return new Promise((resolve, reject) => {
                const chat = toBeOpenedChat
                if (openedChat) {
                    openedChat.close()
                }
                if (chat.type === GroupTypeCode) {
                    api.GetGroupDetails(chat.uuid).then((groupDetails) => {
                        chat.groupDetails = groupDetails
                        chat.connect().progress(openFn).then(() => {
                            resolve(chat)
                        })
                    })
                } else {
                    chat.connect().progress(openFn).then(() => {
                        resolve(chat)
                    })
                }
            }).finally(() => {
                toBeOpenedChat.unread = 0
                this.setState({openedChat: toBeOpenedChat })
            })
        }).bind(this)
    }

    isLoading() {
        return this.state.isLoading
    }

    render() {
        return (
            <ChatContext.Provider value={{
                isLoading: () => this.isLoading(),
                api: this.state.chatApi,
                chats: this.state.chats,
                groupChats: () => this.groupChats(),
                helpChat: () => this.helpChat(),
                GetBaseInfos: () => this.getBaseInfos(),
                GetUnreadCount: (uuid) => this.getUnreadCount(uuid),
            }}>
                {this.props.children}
            </ChatContext.Provider>
        )
    }
}

export const withChat = (Component) => {
    return ({ref, ...props}) => (<ChatContext.Consumer>
        {(chatApi) => {
            return <Component ref={ref} chatApi={chatApi} {...props}/>
        }}
    </ChatContext.Consumer>)
}

export default withApi(ChatProvider);
