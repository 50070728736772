import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import clsx from "clsx";
import StepButton from "@material-ui/core/StepButton";
import SvgIcon from "@material-ui/core/SvgIcon";
import useTheme from "@material-ui/core/styles/useTheme";
import { fade } from '@material-ui/core/styles/colorManipulator';
import StepContent from "@material-ui/core/StepContent";
const ColorStates = Object.freeze({
  DISABLED: 0b0000,
  DISABLED_CONTRAST: 0b1000,
  ACTIVE: 0b0001,
  ACTIVE_CONTRAST: 0b1001,
  COMPLETED: 0b0010,
  COMPLETED_CONTRAST: 0b1010,
  ERROR: 0b0100,
  ERROR_CONTRAST: 0b1100
}); // We can inject some CSS into the DOM.

const styles = theme => ({
  root: {
    display: 'block',
    color: theme.palette.text.disabled //height: '30px',
    //width: '30px',

  },
  content: {
    paddingLeft: theme.spacing(1)
  },
  stepLabelActive: {
    color: theme.palette.text.secondary + '!important'
  }
});

const stepCircle = (stroke, fill = 'none', filter = 'none') => <g><rect x="2" y="2" width="21" height="21" rx="8" stroke={stroke} fill={fill} /></g>;

const Icon = withStyles(styles, {
  name: 'MuiStepIcon'
})(React.forwardRef(function StepIcon({
  completed = false,
  icon,
  active = false,
  error = false,
  contrast = false,
  classes,
  ...other
}, ref) {
  const theme = useTheme();
  let borderColor = 'none';
  let textColor = 'none';
  let backgroundColor = 'none';
  let filter = 'none'; //contrast > error > completed > active

  const colorState = contrast << 3 | error << 2 | completed << 1 | active << 0;

  switch (true) {
    case (colorState & (ColorStates.ERROR | ColorStates.ACTIVE)) === (ColorStates.ERROR | ColorStates.ACTIVE):
      borderColor = theme.palette.error.contrastText;
      textColor = borderColor;
      backgroundColor = theme.palette.error.main;
      break;

    case (colorState & ColorStates.ERROR) === ColorStates.ERROR:
      borderColor = theme.palette.error.main;
      textColor = borderColor;
      backgroundColor = 'none';
      break;

    case (colorState & ColorStates.ACTIVE_CONTRAST) === ColorStates.ACTIVE_CONTRAST:
      borderColor = theme.palette.common.white;
      textColor = borderColor;
      backgroundColor = theme.palette.common.black;
      break;

    case (colorState & ColorStates.ACTIVE) === ColorStates.ACTIVE:
      borderColor = 'url(#paint0_linear)';
      textColor = borderColor;
      backgroundColor = 'none';
      break;

    case colorState === ColorStates.COMPLETED:
      borderColor = 'none';
      textColor = theme.palette.text.disabled;
      backgroundColor = 'none';
      break;

    case colorState === ColorStates.COMPLETED_CONTRAST:
      borderColor = 'none';
      textColor = theme.palette.text.disabled;
      backgroundColor = 'white';
      filter = 'url(#filter_shadow)';
      break;

    case colorState === ColorStates.DISABLED_CONTRAST:
    case colorState === ColorStates.DISABLED:
      borderColor = 'none';
      textColor = theme.palette.text.disabled;
      backgroundColor = fade(theme.palette.action.disabled, 0.1);
      break;
  }

  const className = clsx(classes.root, {
    [classes.active]: active,
    [classes.error]: error,
    [classes.completed]: completed
  });
  return <SvgIcon className={className} ref={ref}>
                {stepCircle(borderColor, backgroundColor, filter)}
                <text className={classes.text} x="12.5" y="16" fontSize="13px" textAnchor="middle" fill={textColor}>
                    {icon}
                </text>
                <defs>
                    <linearGradient id="paint0_linear" x1="21.6071" y1="13.5882" x2="0.194793" y2="13.5286" gradientUnits="userSpaceOnUse">
                        <stop stopColor={theme.palette.default_gradient_start} />
                        <stop offset="1" stopColor={theme.palette.default_gradient_end} />
                    </linearGradient>
                </defs>
            </SvgIcon>;
}));

function TStep({
  label,
  error,
  contrast,
  children,
  title,
  classes,
  ...other
}) {
  const {
    content: contentCls,
    stepLabelActive,
    ...otherClasses
  } = classes;
  const labelKey = `${label}-label`;
  const contentKey = `${label}-content`;
  const content = [<StepButton key={labelKey} disabled={true}>
        <StepLabel classes={{
      root: classes.stepLabel,
      active: stepLabelActive
    }} error={error} StepIconComponent={Icon} StepIconProps={{
      contrast,
      error
    }}>{title ? title : null}</StepLabel>
    </StepButton>, children ? <StepContent classes={{ ...otherClasses,
    root: contentCls
  }} key={contentKey}>{children}</StepContent> : null];
  return <Step key={label} {...other} componentname="t-step">{content}</Step>;
} //TStep.propTypes = Step.propTypes


export default withStyles(styles, {
  useTheme: true
})(TStep);
