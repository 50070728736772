import React, {Component, forwardRef, useEffect, useRef} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Progress from "@ticketag/ticketag-uilib/src/components/base/Progress/Progress";
import {withApi} from "../../hoc/Api/Provider";
import AuthContext from "../../hoc/Auth/context";
import {ConfirmDeletePaymentMethod, NotImplemented} from "../../hoc/Modals/Modals";
import PaymentMethodSelector from "../../components/PaymentMethodSelector/PaymentMethodSelector";
import {withModals} from "@ticketag/ticketag-uilib/src/components/hoc/Modals/Provider";
import {withErrorModal} from "../../components/ErrorMessage/ErrorMessage";
import {T} from "@ticketag/i18nlib";
import PaymentMethodForm from "../../components/PaymentMethodForm/PaymentMethodForm";
import Box from "@material-ui/core/Box/Box";
import Button from "@ticketag/ticketag-uilib/src/components/base/Button/Button";
import * as PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import Form from "@ticketag/ticketag-uilib/src/components/complex/Form/Form";
import {LinearProgress} from "@material-ui/core";
import NextButton from "../../components/NextButton/NextButton";


const styles = (theme) => {
    return {
        root: {},
        progress: {
            width: '100%',
            marginBottom: theme.spacing(6),
        },
        /*
        nextButtonContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            [theme.breakpoints.up("sm")]: {
                position: "absolute",
                bottom: "0",
                left: "50%",
                right: "50%"
            },
            marginBottom: "36px",
        },
        nextButton: {
            width: "80px",
            height: "80px",
            borderRadius: "50%",
            marginBottom: "40px"
        },
         */
    }
};

const InlinePaymentForm = ({children, classes, onSubmit, onChange,insertError, beforeSubmit, disabled, size, ...other})=>{
    let formRef = React.useRef()
    const [valid, setValid] = React.useState(false)
    const [saving, setSaving] = React.useState(false)
    const [error, setError] = React.useState(insertError)
    useEffect(()=>{
        setError(insertError)
    },[insertError])
    const handleSubmit = () => {
        setSaving(true)
        Promise.resolve(beforeSubmit(null, formRef.current.getValues(), (formData) => {
            onSubmit(formData)
        })).catch(err => {
            const message = err?.message|| `Si è verificato un errore durante l'inserimento del metodo di pagamento`
            setSaving(false)
            setError(message)
        })
    }
    const handleChange = (formValues, formState) => {
        setValid(formState.isValid)
    }
    return <>
        {
            error ?
                <Grid item>
                    <Box display="flex" mb={6} mr={{xs: 0, md: 3, lg: 8}}>
                        <Typography color="error" variant="h4">{error}</Typography>
                    </Box>
                </Grid> :
                null
        }
        <PaymentMethodForm inlineVariant size={size} disabled={saving}  onSubmit={onSubmit} onChange={handleChange} formRef={(f) => formRef.current = f}>
            { children }
            <NextButton
                disabled={disabled || !valid}
                onClick={handleSubmit}
            />
        </PaymentMethodForm>
    </>;
}

InlinePaymentForm.propTypes = {
    onSubmit: PropTypes.any,
    onChange: PropTypes.func,
    children: PropTypes.any,
    tAndCChecked: PropTypes.any,
    formValid: PropTypes.any
};

class PaymentMethods extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isUpdating: true,
            paymentMethods: [],
            error: null
        }
        this._isMounted=false
        this.apiCalls=0
    }
    componentDidMount() {
        const actualURL = new URL(window.location.href)
        const setupIntentID = actualURL.searchParams.get("setup_intent_client_secret")
        if (setupIntentID!==null){
            this.props.api.GetSetupIntent(this.props.api.stripe,setupIntentID).then(setupIntent=>{
                if (setupIntent.setupIntent.last_setup_error!== null){
                    const message = setupIntent.setupIntent.last_setup_error.message||`Si è verificato un errore durante l'inserimento del metodo di pagamento`
                    this.setState({
                        ...this.state,
                        error: <T defaultVal={message}>{`errors.stripe.${setupIntent.setupIntent.last_setup_error.code||'generic_error'}`}</T>
                    })
                }else{
                    this.shouldBeDefault=setupIntent.setupIntent.id
                }
                this.loadUserPaymentMethods({isLoaded:true, isUpdating: false}).then(() => {
                    this.props.onLoad && this.props.onLoad(this.state.paymentMethods)
                })
            })
        }else{
            this.loadUserPaymentMethods({isLoaded:true, isUpdating: false}).then(() => {
                this.props.onLoad && this.props.onLoad(this.state.paymentMethods)
            })
        }
        this.interval=setInterval(()=>{
            let pending=false;
            for(let i=0;i<this.state.paymentMethods.length;i++){
                if(this.state.paymentMethods[i].pending){
                    pending=true;
                    break;
                }
            }
            if (pending && this.apiCalls<10){
                this.loadUserPaymentMethods(this.state)
                this.apiCalls++
            }
        },3000)
    }
    componentWillUnmount() {
        clearInterval(this.interval)
    }

    setDefaultPaymentMethodState(uuid, cb) {
        return this.setState({
            isLoaded: true,
            isUpdating: false,
            paymentMethods: this.state.paymentMethods.map(p => {
                p.uuid === uuid ? p.isDefault = true : p.isDefault = false
                return p
            })
        }, cb)
    }

    loadUserPaymentMethods(state={}) {
        return this.props.api.userApi.getPaymentMethods().then((paymentMethods) => {
            if(this.shouldBeDefault){
                for (let i=0;i<paymentMethods.paymentMethods.length;i++){
                    if(paymentMethods.paymentMethods[i].setupIntentId===this.shouldBeDefault && !paymentMethods.paymentMethods[i].isDefault){
                        paymentMethods.paymentMethods[i].pending=true
                    }
                }
            }
            this.setState({
                ...state,
                paymentMethods: paymentMethods.paymentMethods
            })
            return paymentMethods
        })
    }

    setDefaultPaymentMethod(uuid, onComplete) {
        this.shouldBeDefault=null;
        this.setState({isUpdating: true})
        withErrorModal(this.props.modals, this.props.api.SetDefaultPaymentMethod(uuid).then(resp => {
            return this.setDefaultPaymentMethodState(resp.uuid, () => {
                onComplete()
                this.loadUserPaymentMethods({
                    isLoaded: true,
                    isUpdating: false
                })

            })
        }))
    }

    deletePaymentMethod(p, onComplete) {
        const t = this
        this.shouldBeDefault=null;
        this.setState({isUpdating: true})
        return ConfirmDeletePaymentMethod(this.props.modals).then((ok) => {
            if (ok) {
                this.props.api.userApi.getUserGroups().then((groups) => {
                    const userGroups = [...groups.owned,...groups.joined]
                    if (p.pending) {
                        withErrorModal(this.props.modals, this.props.api.userApi.deletePaymentMethod(p.uuid)).finally(() => {
                            onComplete()
                            this.loadUserPaymentMethods({
                                isLoaded: true,
                                isUpdating: false
                            })
                        })
                    } else if (
                        p.isDefault && (groups.joined.length > 0)
                    ) {
                        this.props.modals.OkOnly(
                            <T defaultVal="Elimina metodo di pagamento">modals.delete_payment_method.title</T>,
                            <T defaultVal="Prima di eliminare questo metodo di pagamento assicurati di non partecipare a nessun gruppo">modals.delete_payment_method.user_has_groups_text</T>,
                            {variant: "error"}
                        ).then(() =>  {
                            onComplete()
                             this.loadUserPaymentMethods({
                                isLoaded: true,
                                isUpdating: false
                            })
                        })
                    } else {
                        withErrorModal(this.props.modals, this.props.api.DeletePaymentMethod(p.uuid)).finally(() => {
                            onComplete()
                            this.loadUserPaymentMethods({
                                isLoaded: true,
                                isUpdating: false
                            })
                        })
                    }
                })
            }
        })
    }

    createPaymentMethod(stripe, form, cb) {
        this.setState({isUpdating: true})
        let currentURL=new URL(window.location.href)
        currentURL.searchParams.set("paymentMethod","true")
        return this.props.api.CreatePaymentMethod(stripe, form,currentURL.toString()).then(uuid => {
            cb({uuid})
            this.loadUserPaymentMethods({
                isLoaded: true,
                isUpdating: false
            })
        }).catch(err => {
            const message = err?.message||`Si è verificato un errore durante l'inserimento del metodo di pagamento`
            throw {message: <T defaultVal={message}>{`errors.stripe.${err?.code||'generic_error'}`}</T>}
        })
    }

    render() {
        if (!this.props.isLoaded || !this.state.isLoaded) {
            return <React.Fragment/>
        }
        const {inlineVariant, children, classes, onSubmit, disabled, size, centered, ...other} = this.props;
        if (inlineVariant && this.state.paymentMethods.length === 0) {
            return (
                <InlinePaymentForm
                    insertError={this.state.error}
                    size={size}
                    disabled={disabled}
                    classes={classes}
                    beforeSubmit={(stripe, form, cb) => this.createPaymentMethod(stripe, form, cb)}
                    onSubmit={onSubmit}>
                    {children}
                </InlinePaymentForm>
            )
        }
        return <PaymentMethodSelector
            centered={centered}
            blocked={disabled}
            onSubmit={onSubmit}
            inlineVariant={inlineVariant}
            paymentMethods={this.state.paymentMethods}
            narrowCards={this.props.narrowCards}
            modals={this.props.modals}
            stripe={this.props.api.stripe}
            onChange={this.props.onChange}
            insertError={this.state.error}
            onCreate={(stripe, form, cb) => this.createPaymentMethod(stripe, form, cb)}
            onSelect={(p, onComplete) => this.setDefaultPaymentMethod(p.uuid, onComplete)}
            onDelete={(p, onComplete) => this.deletePaymentMethod(p, onComplete)}
            formRef={(f) => this.form = f }
        >
            {children}
        </PaymentMethodSelector>
    }
}

export default withStyles(styles, {useTheme: true})(PaymentMethods)//withApi(withModals(PaymentMethods))//withStyles(styles, {useTheme: true})();
