import React, {Component} from 'react';
import {I18nContext, T, withI18n} from "@ticketag/i18nlib";
import Progress from "@ticketag/ticketag-uilib/src/components/base/Progress/Progress";
import {withApi} from "../../hoc/Api/Provider";
import {withRedirect} from "../../components/ErrorMessage/ErrorMessage";
import {WebapiprotoPageName as PAGE_NAMES} from "@ticketag/diveedi-client/src/model/WebapiprotoPageName";
import RegistrationSection from "../../components/RegistrationSection/RegistrationSection";
import GoUpSection from "../../components/GoUpSection/GoUpSection";
import ServiceSection from "../../components/ServiceSection/ServiceSection";
import ShareSection from "../../components/ShareSection/ShareSection";
import PromotionSection from "../../components/PromotionSection/PromotionSection";
import Grid from "@material-ui/core/Grid/Grid";
import Faq from "../Faq/Faq";
import Container from "@ticketag/ticketag-uilib/src/components/base/Container/Container";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import Box from "@material-ui/core/Box/Box";
import AuthContext from "../../hoc/Auth/context";
import withWidth from "@material-ui/core/withWidth";
import Services from "../Services/Services";
import {withAuth} from "../../hoc/Auth/Provider";
import {Icon} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";
import Layout from "../../hoc/Layout/Layout";
import Navbar from "../../components/Navbar/Navbar";
import NavTitle from "../../components/Navbar/NavTitle/NavTitle";
import Button from "@ticketag/ticketag-uilib/src/components/base/Button/Button";
import Hidden from "@material-ui/core/Hidden";
import withStyles from "@material-ui/core/styles/withStyles";
import * as PropTypes from "prop-types";
import {pages} from "../../constants";
import Page from "../../components/Page/Page";
import {withPage} from "../../hoc/Page/Provider";

const styles = (theme) => {
    return {
        root: {},
        registrationSection:{
            [theme.breakpoints.down("md")]: {
                paddingTop: '58px',
            },
        },
        promotionSection: {
            overflow: "hidden"
        },
        servicesSection: {
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            position: "relative",
            paddingTop: "80px",
            paddingBottom: "60px",
            background: `url('${process.env.PUBLIC_URL||""}/img/homepage/service_bg_round_mobile.svg') no-repeat center`,
            backgroundSize: "cover",
            width: "100%",
            height: '85vh', //"calc(100vh - 148px)",
            padding: 0,
            [theme.breakpoints.up('sm')]: {
                height: '100vh', //"calc(100vh - 148px)",
                width: "100%",
                backgroundSize: "cover",
                background: `url('${process.env.PUBLIC_URL||""}/img/homepage/service_bg_round.svg') no-repeat center`,
            },
            [theme.breakpoints.down('md')]: {
                paddingTop: "40px",
                paddingBottom: "32px",
            },
            "& .MuiContainer-root": {
                paddingLeft: "32px",
                [theme.breakpoints.up('sm')]: {
                    paddingLeft: "0px",
                }
            }
        },
        goToServicesBox: {
            display: "flex",
            cursor: "pointer",
            alignItems: "center",
            [theme.breakpoints.up("sm")]: {
                flexDirection: "column",
                alignItems: "center"
            },
            "& h3": {
                fontSize: "18px",
                [theme.breakpoints.up("sm")]: {
                    fontSize: "26px"
                }
            },
            "& svg": {
                marginLeft: "8px",
                marginBottom: "-4px",
                width: "20px",
                height: "20px",
                [theme.breakpoints.up("sm")]: {
                    width: "32px",
                    height: "32px",
                    margin: 0
                }
            },
            loginButton:      {
                width:                          "100%",
                maxWidth:                       "150px",
                marginRight:                    theme.spacing(5),
                boxShadow:                      "inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(172, 172, 172, 0.5)",
                filter:                         "drop-shadow(-17px 17px 34px rgba(172, 172, 172, 0.2)), drop-shadow(17px -17px 34px rgba(172, 172, 172, 0.2)), drop-shadow(-17px -17px 34px rgba(255, 255, 255, 0.9)), drop-shadow(17px 17px 43px rgba(172, 172, 172, 0.9))",
                [theme.breakpoints.down('sm')]: {
                    marginRight: 0,
                    width: "128px",
                },
            },
            signupButton:     {
                width:     "100%",
                maxWidth:  "150px",
                boxShadow: "inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(172, 172, 172, 0.5)",
                filter:    "drop-shadow(-17px 17px 34px rgba(172, 172, 172, 0.2)), drop-shadow(17px -17px 34px rgba(172, 172, 172, 0.2)), drop-shadow(-17px -17px 34px rgba(255, 255, 255, 0.9)), drop-shadow(17px 17px 43px rgba(172, 172, 172, 0.9))"
            },
            boxWrapper:       {
                display:    "flex",
                width:      "100%",
                maxWidth:   "340px",
                justifyContent: "flex-end",
                marginLeft: theme.spacing(4),
            },
        }
    }
};

const isStandaloneMode = () =>
    (window.matchMedia('(display-mode: standalone)').matches) || window.navigator.standalone || document.referrer.includes('android-app://');




class HomePage extends Component {
    static contextType = I18nContext;

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            serviceFamilies: [],
            tags: []
        }
        this._isMounted=false;
        this.redirectToSignUp = this.redirectToSignUp.bind(this);
        this.loadPagesName = this.loadPagesName.bind(this);
    }

    componentDidMount() {
        this._isMounted=true;
        if (isStandaloneMode()){
            if (this.props.auth.isAuthenticated()){
                this.props.history.push(`/${this.context.selectedLocale()}/groups`)
            }else{
                window.location.href=`/auth/login?lang=${this.context.selectedLocale()}`
            }
        }
        // wait for fonts and images to load
        setTimeout(() => {
            this.setState({isLoaded: true})
        }, 500)
    }

    componentWillUnmount() {
        this._isMounted=false
    }

    redirectToSignUp(email) {
        if (!this.props.auth.isAuthenticated()) {
            window.location.href = this.props.auth.signUrlWithMail(false, true, email);
        }
    }

    loadPagesName() {
        return this.props.api.GetPage(PAGE_NAMES.homePage, {lang: this.context.selectedLocale()});
    }

    loadServiceFamilies(noCache = false, opts = {}) {
        return this.props.api.GetServiceFamilies(noCache, opts);
    }
    renderContent() {
        return <Grid container direction="column">
            <RegistrationSection classes={{root: this.props.classes.registrationSection}} onSubmit={this.redirectToSignUp}/>
            <Box mt={{xs: 3, sm: 20}}>
                <ShareSection />
            </Box>
            <Box mt={{xs: 3, sm: 20}} classes={{root: this.props.classes.promotionSection}}>
                <PromotionSection />
            </Box>
            <Box mt={{xs: 12, sm: 20}} id={"services"} className={this.props.classes.servicesSection}>
                <Container>
                    <Services
                        title={<T defaultVal={"Domande frequenti"}>pages.homepage.faq_title</T>}
                        contrast={true}
                        layout={false}
                        noSections
                    />
                    <Box className={this.props.classes.goToServicesBox} onClick={() => this.props.history.push(this.props.i18n.buildLocalizedUrl("services"))}>
                        <Typography variant={"h3"} color={"white"}><T defaultVal={"Scopri tutti i servizi"}>pages.homepage.go_to_services</T></Typography>
                        <Icon color={"white"}>right_arrow</Icon>
                    </Box>
                </Container>
            </Box>
            <Container>
                <Box mt={15}>
                    <Faq short
                         title={<T defaultVal={"Domande frequenti"}>pages.homepage.faq_title</T>}
                         subtitle={<T defaultVal={"F.A.Q"}>pages.homepage.faq_subtitle</T>}
                         layout={false}
                    />
                </Box>
            </Container>
            <Box mb={{xs: 20}}>
                {/*<GoUpSection/>*/}
            </Box>
        </Grid>
    }

    renderLoginNavbar() {
        return (
            <Navbar logo>
                {
                    this.props.auth.isAuthenticated() ?
                        null :
                        <Box className={this.props.classes.boxWrapper}>
                            <Button className={this.props.classes.loginButton} color="primary" variant={"contained"} href={this.props.auth.signUrlWithMail(false, false)}>
                                <T defaultVal="Accedi">forms.buttons.login</T>
                            </Button>
                            <Hidden smDown>
                                <Button className={this.props.classes.signupButton} color="accent" variant={"contained"} href={this.props.auth.signUrlWithMail(false, true)}>
                                    <T defaultVal="Registrati">forms.buttons.signup</T>
                                </Button>
                            </Hidden>
                        </Box>
                }
            </Navbar>
        )
    }

    render() {
        const isLoading = !this.state.isLoaded
        if (isLoading) {
            return null
        }
        const {seo, layout, ...other} = this.props
        return (
            <Page
                seo={seo}
                layout={{
                    navbar: this.renderLoginNavbar(),
                    ...layout
                }}
                {...other}>
                {this.renderContent()}
            </Page>
        )
    }
}

export default withStyles(styles, {useTheme: true})((withApi(withWidth()(withAuth(withI18n(HomePage))))));

