import ChipCard from "../ChipCard/ChipCard";
import AvatarIcon from "../../base/Avatar/AvatarIcon";
import Typography from "../../base/Typography/Typography";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import ListItem from "../../base/ListItem/ListItem";
import { Icon, RegisterIcon } from "../../base/Icon/Icon";
import Netflix from "../../../resources/icons/services/netflix.svg";
import Spotify from "../../../resources/icons/services/spotify.svg";
import Disney from "../../../resources/icons/services/disney+.svg";
import clsx from "clsx";
import { Badge, Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import useControlled from "@material-ui/core/utils/useControlled";

const styles = theme => {
  return {
    root: {
      cursor: "pointer",
      "& > div": {
        margin: "8px",
        transition: "box-shadow 0.3s ease-out, transform 0.3s ease-out, margin-left 0.3s ease-in-out!important"
      },
      "& > span": {
        margin: "8px"
      }
    },
    animated: {
      [theme.breakpoints.up("sm")]: {
        maxWidth: "220px",
        marginRight: "24px",
        "& > div:hover": {
          //marginLeft: "24px"
        }
      }
    }
  };
};

const ChatTabContext = React.createContext();
export function useChatTab() {
  return React.useContext(ChatTabContext);
}

function TChatTab(props) {
  const {
    children,
    classes,
    variant,
    name: nameProp,
    value: valueProp,
    onChange,
    animated,
    activeService,
    ...other
  } = props;
  const [value, setValue] = useControlled({
    controlled: valueProp,
    default: activeService,
    name: 'ChatTab'
  });

  const handleChange = (event, value) => {
    setValue(value);

    if (onChange) {
      onChange(event, value);
    }
  };

  return <ChatTabContext.Provider value={{
    name,
    onChange: handleChange,
    value,
    variant
  }}>
        <div className={clsx(classes.root, animated ? classes.animated : null)} componentname="t-chat-tab">
            {children}
        </div>
        </ChatTabContext.Provider>;
}

export default withStyles(styles, {
  useTheme: true
})(TChatTab);
