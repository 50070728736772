/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * The WebapiprotoUserName model module.
 * @module model/WebapiprotoUserName
 * @version v0.4
 */
export class WebapiprotoUserName {
  /**
   * Constructs a new <code>WebapiprotoUserName</code>.
   * @alias module:model/WebapiprotoUserName
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>WebapiprotoUserName</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/WebapiprotoUserName} obj Optional instance to populate.
   * @return {module:model/WebapiprotoUserName} The populated <code>WebapiprotoUserName</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new WebapiprotoUserName();
      if (data.hasOwnProperty('first_name'))
        obj.firstName = ApiClient.convertToType(data['first_name'], 'String');
      if (data.hasOwnProperty('last_name'))
        obj.lastName = ApiClient.convertToType(data['last_name'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} firstName
 */
WebapiprotoUserName.prototype.firstName = undefined;

/**
 * @member {String} lastName
 */
WebapiprotoUserName.prototype.lastName = undefined;


