import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T} from "@ticketag/i18nlib";
import Form from "@ticketag/ticketag-uilib/src/components/complex/Form/Form";
import TextField from "@ticketag/ticketag-uilib/src/components/base/TextField/TextField";
import {Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";


const styles = (theme) => {
    return {
        root: {}
    }
};



function isValidIBAN($v){ //This function check if the checksum if correct
    $v = $v.replace(/^(.{4})(.*)$/,"$2$1"); //Move the first 4 chars from left to the right
    $v = $v.replace(/[A-Z]/g,$e => $e.charCodeAt(0) - 'A'.charCodeAt(0) + 10); //Convert A-Z to 10-25
    let $sum = 0;
    let $ei = 1; //First exponent
    for(let $i = $v.length - 1; $i >= 0; $i--){
        $sum += $ei * parseInt($v.charAt($i),10); //multiply the digit by it's exponent
        $ei = ($ei * 10) % 97; //compute next base 10 exponent  in modulus 97
    }
    return $sum % 97 === 1;
}

const $patterns = { //Map automatic generated by IBAN-Patterns Online Tool
    IBAN($v) {
        return /^AL\d{10}[0-9A-Z]{16}$|^AD\d{10}[0-9A-Z]{12}$|^AT\d{18}$|^BH\d{2}[A-Z]{4}[0-9A-Z]{14}$|^BE\d{14}$|^BA\d{18}$|^BG\d{2}[A-Z]{4}\d{6}[0-9A-Z]{8}$|^HR\d{19}$|^CY\d{10}[0-9A-Z]{16}$|^CZ\d{22}$|^DK\d{16}$|^FO\d{16}$|^GL\d{16}$|^DO\d{2}[0-9A-Z]{4}\d{20}$|^EE\d{18}$|^FI\d{16}$|^FR\d{12}[0-9A-Z]{11}\d{2}$|^GE\d{2}[A-Z]{2}\d{16}$|^DE\d{20}$|^GI\d{2}[A-Z]{4}[0-9A-Z]{15}$|^GR\d{9}[0-9A-Z]{16}$|^HU\d{26}$|^IS\d{24}$|^IE\d{2}[A-Z]{4}\d{14}$|^IL\d{21}$|^IT\d{2}[A-Z]\d{10}[0-9A-Z]{12}$|^[A-Z]{2}\d{5}[0-9A-Z]{13}$|^KW\d{2}[A-Z]{4}22!$|^LV\d{2}[A-Z]{4}[0-9A-Z]{13}$|^LB\d{6}[0-9A-Z]{20}$|^LI\d{7}[0-9A-Z]{12}$|^LT\d{18}$|^LU\d{5}[0-9A-Z]{13}$|^MK\d{5}[0-9A-Z]{10}\d{2}$|^MT\d{2}[A-Z]{4}\d{5}[0-9A-Z]{18}$|^MR13\d{23}$|^MU\d{2}[A-Z]{4}\d{19}[A-Z]{3}$|^MC\d{12}[0-9A-Z]{11}\d{2}$|^ME\d{20}$|^NL\d{2}[A-Z]{4}\d{10}$|^NO\d{13}$|^PL\d{10}[0-9A-Z]{,16}n$|^PT\d{23}$|^RO\d{2}[A-Z]{4}[0-9A-Z]{16}$|^SM\d{2}[A-Z]\d{10}[0-9A-Z]{12}$|^SA\d{4}[0-9A-Z]{18}$|^RS\d{20}$|^SK\d{22}$|^SI\d{17}$|^ES\d{22}$|^SE\d{22}$|^CH\d{7}[0-9A-Z]{12}$|^TN59\d{20}$|^TR\d{7}[0-9A-Z]{17}$|^AE\d{21}$|^GB\d{2}[A-Z]{4}\d{14}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-AL'($v) { //Albania
        return /^AL\d{10}[0-9A-Z]{16}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-AD'($v) { //Andorra
        return /^AD\d{10}[0-9A-Z]{12}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-AT'($v) { //Austria
        return /^AT\d{18}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-BH'($v) { //Kingdom of Bahrain
        return /^BH\d{2}[A-Z]{4}[0-9A-Z]{14}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-BE'($v) { //Belgium
        return /^BE\d{14}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-BA'($v) { //Bosnia and Herzegovina
        return /^BA\d{18}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-BG'($v) { //Bulgaria
        return /^BG\d{2}[A-Z]{4}\d{6}[0-9A-Z]{8}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-HR'($v) { //Croatia
        return /^HR\d{19}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-CY'($v) { //Cyprus
        return /^CY\d{10}[0-9A-Z]{16}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-CZ'($v) { //Czech Republic
        return /^CZ\d{22}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-DK'($v) { //Denmark
        return /^DK\d{16}$|^FO\d{16}$|^GL\d{16}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-DO'($v) { //Dominican Republic
        return /^DO\d{2}[0-9A-Z]{4}\d{20}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-EE'($v) { //Estonia
        return /^EE\d{18}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-FI'($v) { //Finland
        return /^FI\d{16}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-FR'($v) { //France
        return /^FR\d{12}[0-9A-Z]{11}\d{2}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-GE'($v) { //Georgia
        return /^GE\d{2}[A-Z]{2}\d{16}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-DE'($v) { //Germany
        return /^DE\d{20}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-GI'($v) { //Gibraltar
        return /^GI\d{2}[A-Z]{4}[0-9A-Z]{15}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-GR'($v) { //Greece
        return /^GR\d{9}[0-9A-Z]{16}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-HU'($v) { //Hungary
        return /^HU\d{26}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-IS'($v) { //Iceland
        return /^IS\d{24}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-IE'($v) { //Ireland
        return /^IE\d{2}[A-Z]{4}\d{14}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-IL'($v) { //Israel
        return /^IL\d{21}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-IT'($v) { //Italy
        return /^IT\d{2}[A-Z]\d{10}[0-9A-Z]{12}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-KZ'($v) { //Kazakhstan
        return /^[A-Z]{2}\d{5}[0-9A-Z]{13}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-KW'($v) { //KUWAIT
        return /^KW\d{2}[A-Z]{4}22!$/.test($v) && isValidIBAN($v);
    },
    'IBAN-LV'($v) { //Latvia
        return /^LV\d{2}[A-Z]{4}[0-9A-Z]{13}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-LB'($v) { //LEBANON
        return /^LB\d{6}[0-9A-Z]{20}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-LI'($v) { //Liechtenstein (Principality of)
        return /^LI\d{7}[0-9A-Z]{12}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-LT'($v) { //Lithuania
        return /^LT\d{18}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-LU'($v) { //Luxembourg
        return /^LU\d{5}[0-9A-Z]{13}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-MK'($v) { //Macedonia
        return /^MK\d{5}[0-9A-Z]{10}\d{2}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-MT'($v) { //Malta
        return /^MT\d{2}[A-Z]{4}\d{5}[0-9A-Z]{18}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-MR'($v) { //Mauritania
        return /^MR13\d{23}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-MU'($v) { //Mauritius
        return /^MU\d{2}[A-Z]{4}\d{19}[A-Z]{3}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-MC'($v) { //Monaco
        return /^MC\d{12}[0-9A-Z]{11}\d{2}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-ME'($v) { //Montenegro
        return /^ME\d{20}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-NL'($v) { //The Netherlands
        return /^NL\d{2}[A-Z]{4}\d{10}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-NO'($v) { //Norway
        return /^NO\d{13}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-PL'($v) { //Poland
        return /^PL\d{10}[0-9A-Z]{,16}n$/.test($v) && isValidIBAN($v);
    },
    'IBAN-PT'($v) { //Portugal
        return /^PT\d{23}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-RO'($v) { //Romania
        return /^RO\d{2}[A-Z]{4}[0-9A-Z]{16}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-SM'($v) { //San Marino
        return /^SM\d{2}[A-Z]\d{10}[0-9A-Z]{12}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-SA'($v) { //Saudi Arabia
        return /^SA\d{4}[0-9A-Z]{18}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-RS'($v) { //Serbia
        return /^RS\d{20}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-SK'($v) { //Slovak Republic
        return /^SK\d{22}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-SI'($v) { //Slovenia
        return /^SI\d{17}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-ES'($v) { //Spain
        return /^ES\d{22}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-SE'($v) { //Sweden
        return /^SE\d{22}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-CH'($v) { //Switzerland
        return /^CH\d{7}[0-9A-Z]{12}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-TN'($v) { //Tunisia
        return /^TN59\d{20}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-TR'($v) { //Turkey
        return /^TR\d{7}[0-9A-Z]{17}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-AE'($v) { //United Arab Emirates
        return /^AE\d{21}$/.test($v) && isValidIBAN($v);
    },
    'IBAN-GB'($v) { //United Kingdom
        return /^GB\d{2}[A-Z]{4}\d{14}$/.test($v) && isValidIBAN($v);
    },
};

const validateIban = (value, params) => {

    return isValidIBAN(value)
}

const IbanForm = ({...props}) => {

    return <Form T={T} {...props}>

        <Grid xs={12}>
            <Box mt={2}>
                <Typography variant="body1"><T defaultVal="Insersci il codice IBAN al quale inviare il tuo saldo">components.iban_form.caption</T></Typography>
            </Box>
        </Grid>
        <TextField grid={{xs: 12, md: 12}}
                   showErrors
                   variant={'outlined'}
                   label={"IBAN"}
                   name="iban"
                   rules={{
                       required: true,
                       validate: {match: (value, params, form) => validateIban(value, params) , message: {key: 'components.iban_form.invalid_iban', defaultVal: 'Inserisci un IBAN valido' }}
                   }}/>
    </Form>
}

export default withStyles(styles, {useTheme: true})(IbanForm);
