import React from "react";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { Fade } from "@material-ui/core";
import { ExpandMoreIcon } from "../../base/Icon/Icon";
import GridList from "@material-ui/core/GridList";
import CarouselItem from "./CarouselItem";
import { withStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";

const styles = theme => {
  return {
    root: {
    },
    loading: {
      "& *": {
        transition: "none !important"
      }
    },
    gridList: {
      width: "100%",
      alignItems: "center",
      justifyContent: "space-evenly",
      flexWrap: 'nowrap',
      overflow: "visible",
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transition: "margin .3s ease, padding .3s ease"
    },
    fluid: {
      justifyContent: "flex-start",
      width: "100%",
      overflow: "scroll",
      transform: 'translateZ(0)',
      "-ms-overflow-style": "none",

      /* IE and Edge */
      scrollbarWidth: "none",

      /* Firefox */
      "&::-webkit-scrollbar": {
        display: "none"
      }
    },
    prova3: {
       overflow: "visible",
    },
    tile: {
      transition: "width .3s ease, height .3s ease, padding .3s ease"
    },
    button: {
      // background: "linear-gradient(276.55deg, #000000 9.05%, rgba(0, 0, 0, 0) 95.14%)",
      position: "absolute",
      zIndex: "1000",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "& svg": {
        width: "66.66666666%",
        height: "auto"
      }
    },
    buttonPrev: {
      justifyContent: "flex-start",
      left: "0px",
      transform: "translateX(-100px)",
      "& svg": {
        transform: "rotate(90deg)"
      }
    },
    buttonNext: {
      justifyContent: "flex-end",
      right: "0px",
      transform: "translateX(100px)",
      "& svg": {
        transform: "rotate(270deg)"
      }
    },
    skeleton: {
      transform: "none",
      borderRadius: "16px",
      height: "100%"
    }
  };
};

function TCarousel(props) {
  const {
    children,
    isLoading,
    height,
    contrast,
    fluid = false,
    classes,
    className,
    cols = 3.5,
    scaling = 1.5,
    buttonWidth = 120,
    spacing = 0,
    ...other
  } = props;
  const [active, setActive] = React.useState(0);

  const scroll = n => e => {
    const gridList = e.target.parentNode.lastChild;
    setActive(active - n);
    gridList.scroll({
      left: gridList.scrollLeft - gridList.clientWidth / cols * n,
      behavior: 'smooth'
    });
  };

  const onScroll = e => {
    /*
    const gridList = e.target
    console.log(gridList.offsetWidth)
    console.log("gridList.scrollLeft", gridList.scrollLeft)
    console.log("document.body.clientWidth", document.body.clientWidth)
    console.log("gridList.clientWidth", gridList.clientWidth)
    console.log("document.body.clientWidth", document.body.offsetWidth)
     */
  };

  return <React.Fragment>
            <Box width={fluid ? "100%" : undefined} height={height * scaling} className={clsx(classes.root, isLoading ? classes.loading : null, className)} display={"flex"} //px={fluid ? 0 : buttonWidth / 8}
    justifyContent={"center"} position={fluid ? "absolute" : "relative"} componentname="t-carousel">
            {!fluid ? <Fade in={!isLoading && active > 0}>
                        <Box width={buttonWidth} className={clsx(classes.button, classes.buttonPrev)} height={height * scaling} onClick={active > 0 ? scroll(1) : null}>
                            <ExpandMoreIcon color={contrast ? "white" : "textSecondary"} />
                        </Box>
                    </Fade> : null}
            {!fluid ? <Fade in={!isLoading && active < React.Children.count(children) - cols}>
                        <Box width={buttonWidth} className={clsx(classes.button, classes.buttonNext)} height={height * scaling} onClick={active < React.Children.count(children) - cols ? scroll(-1) : null}>
                            <ExpandMoreIcon color={contrast ? "white" : "textSecondary"} />
                        </Box>
                    </Fade> : null}
            <GridList spacing={spacing} cellHeight={height} className={clsx(classes.gridList, fluid ? classes.fluid : null)} cols={cols} onScroll={onScroll}>
                {isLoading ? [...Array(Math.floor(cols)).keys()].map(i => <CarouselItem disabled height={height} fluid={fluid} visible={fluid || i >= active && i < active + cols} spacing={spacing} totCols={cols} scaling={scaling} classes={classes}>
                                <Skeleton className={classes.skeleton} height={height}/>
                            </CarouselItem>) : React.Children.map(children, (child, i) => <CarouselItem height={height} fluid={fluid} visible={fluid || i >= active && i < active + cols} spacing={spacing} totCols={cols} scaling={scaling} classes={classes}>
                            {child}
                        </CarouselItem>)}
            </GridList>
        </Box>
            {fluid ? <Box height={height * scaling} /> : null}
        </React.Fragment>;
}

export default withStyles(styles)(TCarousel);
