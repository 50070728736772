import React, {useEffect} from 'react';
import {useTheme, withStyles} from '@material-ui/core/styles';
import {T} from "@ticketag/i18nlib";
import Box from "@material-ui/core/Box/Box";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import {Skeleton} from "@material-ui/lab";
import {SelectArrowIcon} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";
import IconButton from "@material-ui/core/IconButton";
import {Collapse} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from "clsx";


const styles = (theme) => {
    return {
        root: {
            userSelect: "none",
            "-webkit-user-select": "none", /* Safari */
            "-ms-user-select": "none", /* IE 10 and IE 11 */
            "-moz-user-select": "none",
            [theme.breakpoints.down("sm")]: {
                cursor: "pointer"
            }
        },
        title: {
                fontSize: "20px",
                fontWeight: "600",
                flexGrow: "1"
        },
        arrowButton: {
            transitionDuration: "0.3s",
            transitionProperty: "transform",
            padding: "0px!important"
        },
        expanded: {
            transform: "rotate(180deg)"
        }
    }
};

const ProfileSection = (props) => {
    const {classes, children, title, pt=0, subtitle=null, isLoading,expand, ...other} = props;
    const [expanded, setExpanded] = React.useState(expand || false);
    useEffect(()=>{
        setExpanded(expand)
    },[expand])
    const theme = useTheme()
    const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
    const handleExpand = (event) => {
        setExpanded(!expanded);
    };
    return <Box {...other}>
            <Box pb={4} display={"flex"} alignItems={"center"} width={"100%"} className={classes.root} onClick={() => handleExpand()}>
                <Typography  className={classes.title} color={'textSecondary'} variant={'h4'}>{isLoading? <Skeleton width={200}/> : title}</Typography>
                {
                    isMobile ?
                        <IconButton className={clsx(classes.arrowButton, expanded ? classes.expanded : null)}>
                            <SelectArrowIcon width={36} height={36} color={"textSecondary"}/>
                        </IconButton> : null
                }
            </Box>

            <Collapse in={isMobile ? expanded : true} timeout="auto" unmountOnExit>
                <Box mb={8}>
                    {subtitle ? <Box pb={2.5}>
                        <Typography color={'paragraph'} variant={'subtitle1'}>{subtitle}</Typography>
                    </Box>: null}
                    {children}
                </Box>
            </Collapse>
    </Box>
}

export default withStyles(styles, {})(ProfileSection);
