import React, { Component } from 'react';
import {I18nContext, T} from "@ticketag/i18nlib";
import {withStyles} from "@material-ui/core/styles";
import TFooter from "@ticketag/ticketag-uilib/src/components/base/Footer/Footer";
import {Link as RouterLink} from 'react-router-dom';
import { withRouter } from 'react-router-dom'
import Data from "../../data";

const styles = (theme) => {
    return {
        root: {

        },
        menuItem: {
            width:'100%'
        }
    }
};

class Footer extends Component {
    static contextType = I18nContext

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            showLocaleMenu: false,
            localeMenuAnchor: null
        };
    }

    render() {
        const footerData = Data.DefaultLayout.footer
        return <TFooter
            onSelectLocale={(e, selectedLocale) => {
                this.props.history.replace(this.context.localizedUrl(this.props.location.pathname))
            }}
            i18n={this.context}
            color={"gradient"}
            logo={`${process.env.PUBLIC_URL||""}/img/white_logo.svg`}
            sections={footerData.sections}
            credits={footerData.credits}
            socialLinks={footerData.socialLinks}
            linkComponent={RouterLink}
            style={{width: "100%", bottom: 0, left: 0}}>
        </TFooter>
    }
}

export default withStyles(styles, {useTheme: true})(withRouter(Footer));
