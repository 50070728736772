/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * The WebapiprotoNotificationDevice model module.
 * @module model/WebapiprotoNotificationDevice
 * @version v0.4
 */
export class WebapiprotoNotificationDevice {
  /**
   * Constructs a new <code>WebapiprotoNotificationDevice</code>.
   * @alias module:model/WebapiprotoNotificationDevice
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>WebapiprotoNotificationDevice</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/WebapiprotoNotificationDevice} obj Optional instance to populate.
   * @return {module:model/WebapiprotoNotificationDevice} The populated <code>WebapiprotoNotificationDevice</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new WebapiprotoNotificationDevice();
      if (data.hasOwnProperty('endpoint'))
        obj.endpoint = ApiClient.convertToType(data['endpoint'], 'String');
      if (data.hasOwnProperty('auth'))
        obj.auth = ApiClient.convertToType(data['auth'], 'String');
      if (data.hasOwnProperty('p256dh'))
        obj.p256dh = ApiClient.convertToType(data['p256dh'], 'String');
      if (data.hasOwnProperty('base_url'))
        obj.baseUrl = ApiClient.convertToType(data['base_url'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} endpoint
 */
WebapiprotoNotificationDevice.prototype.endpoint = undefined;

/**
 * @member {String} auth
 */
WebapiprotoNotificationDevice.prototype.auth = undefined;

/**
 * @member {String} p256dh
 */
WebapiprotoNotificationDevice.prototype.p256dh = undefined;

/**
 * @member {String} baseUrl
 */
WebapiprotoNotificationDevice.prototype.baseUrl = undefined;


