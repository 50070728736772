import React from "react";
import {renderRoutes} from "react-router-config";
import {withApi} from "../../hoc/Api/Provider";
import {withI18n} from "@ticketag/i18nlib";
import {withGlobalLoader} from "../../hoc/GlobalLoader/Provider";
import {Routes} from "../../hoc/Routes/Provider";


class groupSwitch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
        }
    }

    componentDidMount() {
        console.log("GROUP SWITCH")
        this.props.loader.Show()
        this.props.api.GetGroupDetails(this.props.match.params.groupUuid).then((group) => {
            this.props.api.GetServiceFamily(group.serviceFamilyUuid).then((serviceFamily) => {
                this.setState({serviceFamily: serviceFamily, group: group, isLoading: false})
            })
        })

    }

    isLoading() {
        return this.state.isLoading
    }

    render() {
        if (this.isLoading()) {
            return null
        }
        const {route, ...other} = this.props
        return <Routes routes={route.routes} group={{...this.state.group, serviceFamily: this.state.serviceFamily}}/>
    }
}

export default withApi(withI18n(withGlobalLoader((groupSwitch))))