/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * The WebapiprotoJoinedGroupCard model module.
 * @module model/WebapiprotoJoinedGroupCard
 * @version v0.4
 */
export class WebapiprotoJoinedGroupCard {
  /**
   * Constructs a new <code>WebapiprotoJoinedGroupCard</code>.
   * @alias module:model/WebapiprotoJoinedGroupCard
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>WebapiprotoJoinedGroupCard</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/WebapiprotoJoinedGroupCard} obj Optional instance to populate.
   * @return {module:model/WebapiprotoJoinedGroupCard} The populated <code>WebapiprotoJoinedGroupCard</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new WebapiprotoJoinedGroupCard();
      if (data.hasOwnProperty('uuid'))
        obj.uuid = ApiClient.convertToType(data['uuid'], 'String');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('owner_name'))
        obj.ownerName = ApiClient.convertToType(data['owner_name'], 'String');
      if (data.hasOwnProperty('membership_uuid'))
        obj.membershipUuid = ApiClient.convertToType(data['membership_uuid'], 'String');
      if (data.hasOwnProperty('state'))
        obj.state = ApiClient.convertToType(data['state'], 'String');
      if (data.hasOwnProperty('service_family_uuid'))
        obj.serviceFamilyUuid = ApiClient.convertToType(data['service_family_uuid'], 'String');
      if (data.hasOwnProperty('service_type_uuid'))
        obj.serviceTypeUuid = ApiClient.convertToType(data['service_type_uuid'], 'String');
      if (data.hasOwnProperty('banners'))
        obj.banners = ApiClient.convertToType(data['banners'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {String} uuid
 */
WebapiprotoJoinedGroupCard.prototype.uuid = undefined;

/**
 * @member {String} name
 */
WebapiprotoJoinedGroupCard.prototype.name = undefined;

/**
 * @member {String} ownerName
 */
WebapiprotoJoinedGroupCard.prototype.ownerName = undefined;

/**
 * @member {String} membershipUuid
 */
WebapiprotoJoinedGroupCard.prototype.membershipUuid = undefined;

/**
 * @member {String} state
 */
WebapiprotoJoinedGroupCard.prototype.state = undefined;

/**
 * @member {String} serviceFamilyUuid
 */
WebapiprotoJoinedGroupCard.prototype.serviceFamilyUuid = undefined;

/**
 * @member {String} serviceTypeUuid
 */
WebapiprotoJoinedGroupCard.prototype.serviceTypeUuid = undefined;

/**
 * @member {Number} banners
 */
WebapiprotoJoinedGroupCard.prototype.banners = undefined;


