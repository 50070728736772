/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';
import {WebapiprotoCard} from './WebapiprotoCard';
import {WebapiprotoPageName} from './WebapiprotoPageName';

/**
 * The WebapiprotoPage model module.
 * @module model/WebapiprotoPage
 * @version v0.4
 */
export class WebapiprotoPage {
  /**
   * Constructs a new <code>WebapiprotoPage</code>.
   * @alias module:model/WebapiprotoPage
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>WebapiprotoPage</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/WebapiprotoPage} obj Optional instance to populate.
   * @return {module:model/WebapiprotoPage} The populated <code>WebapiprotoPage</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new WebapiprotoPage();
      if (data.hasOwnProperty('name'))
        obj.name = WebapiprotoPageName.constructFromObject(data['name']);
      if (data.hasOwnProperty('lang'))
        obj.lang = ApiClient.convertToType(data['lang'], 'String');
      if (data.hasOwnProperty('cards'))
        obj.cards = ApiClient.convertToType(data['cards'], [WebapiprotoCard]);
    }
    return obj;
  }
}

/**
 * @member {module:model/WebapiprotoPageName} name
 */
WebapiprotoPage.prototype.name = undefined;

/**
 * @member {String} lang
 */
WebapiprotoPage.prototype.lang = undefined;

/**
 * @member {Array.<module:model/WebapiprotoCard>} cards
 */
WebapiprotoPage.prototype.cards = undefined;


