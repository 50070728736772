import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { capitalize, Select } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import { CopyIcon, InfoIcon } from "../Icon/Icon";
import Popover from "@material-ui/core/Popover";
import Typography from "../Typography/Typography";
import IconButton from "@material-ui/core/IconButton";
import Clipboard from "../Clipboard/Clipboard"; // We can inject some CSS into the DOM.

const styles = theme => {
  return {
    root: {
      "& input": {
        width: "100%",
        margin: "0"
      },
      "& .MuiInput-underline::after": {
        borderColor: "currentColor"
      },
      "& .MuiInput-underline.Mui-focused::after": {
        borderColor: theme.palette.secondary.main
      },
      "& label": {
        color: theme.palette.text.secondary
      },
      "& .MuiFormHelperText-root": {
        color: theme.palette.text.primary
      }
    },
    error: {
      color: theme.palette.error.main,
      "& .MuiInput-underline": {
        borderColor: theme.palette.error.main
      },
      "& .MuiInput-underline::after": {
        borderColor: theme.palette.error.main
      },
      "& label": {
        color: theme.palette.error.main
      },
      "& .MuiFormHelperText-root": {
        color: theme.palette.error.main
      }
    },
    colorDefault: {
      color: theme.palette.text.primary
    },
    colorPrimary: {
      color: theme.palette.primary.main
    },
    colorSecondary: {
      color: theme.palette.secondary.main
    },
    variantStandard: {
      marginTop: "0px!important",
      marginBottom: "2px"
    },
    variantTransparent: {
      marginTop: "0px!important",
      marginBottom: "2px",
      paddingLeft: "0px!important"
    },
    variantOutlined: {
      color: "currentColor",
      borderRadius: "4px!important",
      transition: "color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
      border: "1px solid rgba(0, 0, 0, 0.23)",
      "&.Mui-disabled": {
        backgroundColor: "rgba(0, 0, 0, 0.05)"
      },
      "&.Mui-focused": {
        border: "1px solid currentColor!important"
      },
      "&.Mui-error": {
        borderColor: theme.palette.error.main + "!important"
      },
      "&.Mui-error.Mui-focused": {
        border: `1px solid ${theme.palette.text.primary}!important`
      }
    },
    variantShadowed: {
      padding: "4px 8px !important",
      background: "#fff",
      boxShadow: "inset 1px 1px 1px #FFFFFF, inset 0px 0px 2px rgba(172, 172, 172, 0.35)",
      color: "currentColor",
      borderRadius: "4px!important",
      transition: "color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
      border: "0.3px solid rgba(224, 224, 224, 0.3)",
      "&.Mui-disabled": {
        backgroundColor: "rgba(0, 0, 0, 0.05)"
      },
      "&.Mui-focused": {
        border: "1px solid currentColor!important"
      },
      "&.Mui-error": {
        borderColor: theme.palette.error.main + "!important"
      },
      "&.Mui-error.Mui-focused": {
        border: `1px solid ${theme.palette.text.primary}!important`
      }
    },
    shapeRounded: {
      "& .MuiInputBase-root": {
        borderRadius: "100px!important"
      },
      "& label": {
        marginLeft: "14px"
      },
      "& .MuiFormHelperText-root": {
        marginLeft: "14px"
      }
    },
    sizeBig: {
      "& .MuiInputBase-root": {
        height: "45px",
        boxShadow: "-10px 10px 24px rgba(172, 172, 172, 0.08), 10px -10px 24px rgba(172, 172, 172, 0.08), 10px 10px 24px rgba(172, 172, 172, 0.08)",
        padding: "12px 20px!important",
        borderRadius: "16px!important",
        border: "1px solid transparent",
      }
    },
    label: {
      position: "relative",
      transform: "none!important",
      textTransform: "uppercase",
      pointerEvents: "auto",
      margin: "0",
      "&.Mui-focused": {
        color: theme.palette.text.secondary
      },
      "&.Mui-disabled": {
        color: theme.palette.text.disabled
      },
      // todo: dipendono dal tema
      fontSize: "14px",
      lineHeight: "30px",
      fontWeight: "500"
    },
    Input: {
      color: "currentColor",
      padding: "6px 12px"
    },
    input: {
      color: theme.palette.text.secondary,
      height: "auto",
      padding: "0!important",
      // todo: dipendono dal tema
      fontSize: "14px!important",
      lineHeight: "20px" //

    },
    helperText: {
      // todo: dipendono dal tema
      fontSize: "14px",
      lineHeight: "20px",
      //
      margin: 0,
      minHeight: "20px",
      "&.Mui-disabled": {
        color: theme.palette.text.disabled
      },
      "&.Mui-focused": {
        color: theme.palette.text.primary + "!important"
      }
    },
    infoIcon: {
      width: "14px",
      height: "14px",
      margin: "0 6px -2px"
    },
    popover: {
      pointerEvents: "none"
    }
  };
};

const PopoverIcon = props => {
  const {
    text,
    classes,
    ...other
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return <span>
            <InfoIcon className={classes.infoIcon} aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} />
            <Popover id="mouse-over-popover" className={classes.popover} open={open} anchorEl={anchorEl} anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }} transformOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }} onClose={handlePopoverClose} disableRestoreFocus>
                <Typography>I use Popover.</Typography>
            </Popover>
        </span>;
};

function TTextfield({
  id,
  select,
  classes,
  color = "default",
  className,
  variant = 'standard',
  shape = "standard",
  fullWidth = false,
  error,
  popover,
  value,
  defaultValue = "",
  label,
  helperText,
    size = "default",
  InputLabelProps,
  InputProps,
  FormHelperTextProps,
  onChange,
  onBlur,
  showErrors,
  showCopy = false,
  copyTooltip = 'Copiato!',
  ...other
}) {
  let variantValue = variant;

  if (["transparent"].includes(variant)) {
    variantValue = "standard";
  }

  if (["shadowed"].includes(variant)) {
    variantValue = "outlined";
  }

  const [currentValue, setCurrentValue] = useState(value ? value : defaultValue);
  return <TextField select={select} value={value} variant={variantValue} label={popover ? <React.Fragment>
                    {label}
                    <PopoverIcon classes={{
      infoIcon: classes.infoIcon,
      popover: classes.popover
    }} />
                </React.Fragment> : label} helperText={helperText} fullWidth={fullWidth} defaultValue={defaultValue} error={error} classes={{
    root: classes.root
  }} className={clsx(
      classes[`color${capitalize(color)}`], classes[`shape${capitalize(shape)}`], error ? classes.error : null, error ? "Mui-error" : null,
      size === "big" ? classes.sizeBig : null,
      className)} InputLabelProps={{ ...{
      shrink: true,
      classes: {
        root: classes.label
      }
    },
    ...InputLabelProps
  }} InputProps={{ ...{
      renderSuffix: () => {},
      classes: {
        root: classes.Input,
        input: classes.input
      },
      className: clsx(classes[`variant${capitalize(variant)}`])
    },
    ...(showCopy ? {
      endAdornment: <Clipboard tooltipText={copyTooltip} component="div" data-clipboard-text={currentValue}>
                                <IconButton aria-label="copy" edge="end"><CopyIcon /></IconButton>
                            </Clipboard>
    } : null),
    ...(variant === "transparent" && {
      disableUnderline: true
    }),
    ...InputProps
  }} FormHelperTextProps={{ ...{
      classes: {
        root: classes.helperText
      }
    },
    ...FormHelperTextProps
  }} onChange={e => {
    setCurrentValue(e.target.value);
    onChange ? onChange(e, e.target.value) : null;
  }} onBlur={e => {
    onBlur ? onBlur(e, e.target.value) : null;
  }} {...other} componentname="t-textfield" />;
  /*
      return (
          <FormControl
              classes={{
                  root: classes.formControl,
              }}
              fullWidth={fullWidth}
          >
              {label ? <InputLabel
                  aria-owns={open ? 'mouse-over-popover' : undefined}
                  aria-haspopup="true"
                  classes={{root: classes.label}} id={id? `${props.id}-label` : null}>{label}
                  <PopoverIcon classes={{infoIcon: classes.infoIcon, popover: classes.popover}}
                  /></InputLabel> : null}
              <Select
                  variant={!["transparent"].includes(variant) ? variant: "standard"}
                  id={id}
                  renderSuffix={() => {}}
                  classes={{
                      root: classes.root,
                      icon: classes.icon,
                      iconOpen: classes.iconOpen,
                  }}
                  inputProps={{
                      classes: {
                          root: classes.input
                      },
                      className: (() => {
                          let className = ""
                          switch (variant) {
                              case "outlined":
                                  className =  classes.outlined
                                  if (shape === "rounded") {
                                      className = className + classes.rounded
                                  }
                              case "transparent":
                                  className = classes.transparent
                          }
                          return className
                      })(),
                  }}
                  IconComponent={SelectArrowIcon}
                  disableUnderline={["transparent"].includes(variant)}
                  {...other}
              >
                  {options.map((o, i) => <MenuItem key={i+1} value={getOptionValue(o)}>{getOptionLabel(o)}</MenuItem>)}
              </Select>
              {helperText ? <FormHelperText classes={{root: classes.helperText}}>{helperText}</FormHelperText>: null}
          </FormControl>
      );
  */
}

export default withStyles(styles, {
  useTheme: true
})(TTextfield);
