import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import {capitalize} from "@material-ui/core";
import clsx from "clsx";
import Typography from "../Typography/Typography";


const styles = (theme) => {
    return {
        root: {
            fontSize: '12px',
            fontFamily: theme.typography.fontFamily,
            textAlign: 'center',
            height: '28px',
            width: '100%',

        },
        colorPrimary: {
            color: theme.palette.primary.main
        },
        colorSecondary: {
            color: theme.palette.secondary.main
        },
        colorError: {
            color: theme.palette.error.main
        },
        text: {

        },
        max: {
            fontSize: "42px"
        }
    }
};

export const ShowSize = ({max, value, classes, color='primary', ...props}) => {
    const colorCls = `color${capitalize(color)}`
    return <Box className={classes[colorCls]} classes={{root: classes.root}} {...props}>
        <Typography variant="h5" color={color}>
            <span>{value} /</span>
            <span className={clsx([classes.max])}>{max}</span>
        </Typography>
    </Box>
}

export default withStyles(styles, {useTheme: true})(ShowSize);
