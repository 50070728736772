import React, { useRef, useEffect, useState } from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T} from "@ticketag/i18nlib";
import Box from "@material-ui/core/Box/Box";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import TextField from "@ticketag/ticketag-uilib/src/components/base/TextField/TextField";
import Form from "@ticketag/ticketag-uilib/src/components/complex/Form/Form";
import Button from "@ticketag/ticketag-uilib/src/components/base/Button/Button";
import {Icon} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";
import Hidden from "@material-ui/core/Hidden";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";

const styles = (theme) => {
    return {
        root: {
            position: "relative",
            minHeight: "550px",
            height: 'calc(100vh - 148px)', //"calc(100vh - 148px)",
            [theme.breakpoints.down('sm')]: {
                height: "auto",
            },
        },
        backgroundImage: {
            width: "50vw",
            height: "calc(100% + 148px)",
            right: "0",
            zIndex: "-1",
            position: "absolute",
            background: `url('${process.env.PUBLIC_URL||""}/img/homepage/home-bg.svg') no-repeat`,
            backgroundSize: "contain",
            backgroundPosition: "50% calc(50% - 50px)",
            [theme.breakpoints.down('lg')]: {
                width: "40vw",
            },
            [theme.breakpoints.down('sm')]: {
                position: "relative",
                width: "100vw",
                margin: "0 -25px",
                height: "350px",
                background: `url('${process.env.PUBLIC_URL||""}/img/homepage/home-bg.svg') no-repeat`,
                backgroundPosition: "center right",
                backgroundSize: "360px",
            },
        },
        boxRoot: {
            height: "100%",
            maxWidth: "820px",
            marginRight: "auto",
            marginLeft: theme.spacing(23),
            [theme.breakpoints.down('lg')]: {
                marginLeft: theme.spacing(12),
            },
            [theme.breakpoints.down('sm')]: {
                margin: "0",
                padding: "0 25px",
            },
        },
        headline: {
            marginBottom: theme.spacing(1),
            fontWeight: "300",
            fontSize: "24px",
            lineHeight: "29px",
            [theme.breakpoints.down('sm')]: {
                fontSize: "20px",
                lineHeight: "24px"
            },
        },
        headlineBox: {
            position: "relative",
            marginTop: "50px",
            [theme.breakpoints.down('sm')]: {
                marginTop: "0",
            },
        },
        decoration: {
            height: "150px",
            width: "120px",
            position: "absolute",
            top: "0",
            left: "-20px",
            transform: "translateX(-100%)",
            [theme.breakpoints.down('sm')]: {
                display: "none"
            },
        },
        title: {
            fontWeight: "600",
            fontSize: "52px",
            lineHeight: "62px",
            marginBottom: theme.spacing(2),
            [theme.breakpoints.down('lg')]: {
                fontSize: "40px",
                lineHeight: "44px"
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: "28px",
                lineHeight: "34px"
            },
        },
        subtitle: {
            maxWidth: "455px",
            paddingRight: theme.spacing(22),
            marginBottom: theme.spacing(4),
            lineHeight: "27px",
            [theme.breakpoints.down('lg')]: {
                maxWidth: "500px",
                fontSize: "16px",
                paddingRight: theme.spacing(26),
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: "16px",
                paddingRight: 0,
                marginBottom: theme.spacing(1),
            },
        },
        showMore: {
            position: "fixed",
            bottom: "45px",
            left: "50%",
            transform: "translateX(-50%)",
            fontSize: "24px",
            lineHeight: "29px",
            fontWeight: "300",
            [theme.breakpoints.down('sm')]: {
                width: "100%",
                textAlign: "center",
                position: "static",
                transform: "none",
                marginTop: theme.spacing(2)
            },
            "& > a": {
                color: "inherit",
                textDecoration: "none",
            },
        },
        arrow: {
            position: "absolute",
            height: "25px",
            top: "calc(100% + 8px)",
            left: "50%",
            transform: "translateX(-50%)"
        },
        inputHeadline: {
            fontWeight: "600",
            fontSize: "24px",
            lineHeight: "29px",
            marginBottom: theme.spacing(6),
            [theme.breakpoints.down('sm')]: {
                marginBottom: theme.spacing(2),
            },
        },
        subscribeBox: {
            display: "flex",
            maxHeight: "40px",
            [theme.breakpoints.down('sm')]: {
                maxHeight: "32px",
                marginTop: "40px"
            },
        },
        subscribeButton: {
            width: "128px",
            marginLeft: theme.spacing(6),
            fontSize: "18px",
            lineHeight: "22px",
            fontWeight: "600",
            [theme.breakpoints.down('sm')]: {
                marginLeft: 0,
            },
        },
        textField: {
            width: "100%",
            maxWidth: "476px",
            [theme.breakpoints.down('sm')]: {
                maxWidth: "100%",
                display: "none",
            },
        },
        form: {
            width: "100%",
            alignItems: "center",
        },
        formGrid: {
            [theme.breakpoints.down('sm')]: {
                marginBottom: "40px",
                justifyContent: "center"
            },
        }
    }
};

const defaultHeadline = (classes) => {
    return <Box classes={{root: classes.headlineBox}}>
        <img className={classes.decoration} src={`${process.env.PUBLIC_URL||""}/img/homepage/decoration.svg`}/>
        <Typography whiteSpace={"preLine"} classes={{root: classes.headline}} variant={'body1'} color="accent">
            <T multiline defaultVal={"Spendi meno, ottieni di piu"}>pages.homepage.registration_section_headline</T>

        </Typography>
    </Box>;
}

const defaultTitle = (classes) => {
    return <Typography whiteSpace={"preLine"} classes={{root: classes.title}} variant={'h2'} component={'h1'} color="accent" semiBold>
        <T multiline defaultVal={"CONDIVIDI I SERVIZI\nCHE AMI, DIVIDI I COSTI"}>pages.homepage.registration_section_title</T>
    </Typography>;
}

const defaultSubtitle = (classes) => {
    return <Typography whiteSpace={"preLine"} classes={{root: classes.subtitle}} variant={'body1'} component={"h2"} color="textPrimary">
        <T multiline defaultVal={"Registrati su Diveedi per condividere i tuoi servizi in abbonamento e dividine le spese con chi vuoi!"}>pages.homepage.registration_section_subtitle</T>
    </Typography>;
}

const defaultInputHeadline = (classes) => {
    return <Typography whiteSpace={"preLine"} classes={{root: classes.inputHeadline}} variant={'h5'} color="accent">
        <T multiline defaultVal={"INIZIA SUBITO..."}>pages.homepage.input_headline</T>
    </Typography>;
}

const defaultShowMore = (classes) => {
    const showMore = useRef(null);
    const [opacity, setOpacity] = useState(1);
    const [scrollTop, setScrollTop] = useState(0);

    useEffect(() => {
        const onScroll = e => {
            setOpacity(1 - (e.target.documentElement.scrollTop - 100) / 250);
            setScrollTop(e.target.documentElement.scrollTop);
        };
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop]);

    const onLinkClick = (event) => {
        event.preventDefault();
        document.getElementById("services").scrollIntoView({ behavior: "smooth" });
    };

    return <Typography classes={{root: classes.showMore}} variant={'h5'} color="accent" ref={showMore} style={
        {
            opacity,
            display: opacity === 0 ? 'none' : 'block'
        }
    }>
        <a onClick={onLinkClick}>
            <T defaultVal={"Scopri i nostri servizi"}>pages.homepage.arrodown_text</T>
            <Icon className={classes.arrow} style={{fontSize: 25}} color="accent">arrowdown</Icon>
        </a>
    </Typography>;
}

export const RegistrationSection = ({
                                        classes={},
                                        headline=defaultHeadline(classes),
                                        title=defaultTitle(classes),
                                        subtitle=defaultSubtitle(classes),
                                        showMore=defaultShowMore(classes),
                                        inputHeadline=defaultInputHeadline(classes),
                                        defaultValues={},
                                        onValidate,
                                        onSubmit,
                                        ...props
                                    }) => {
    const form = useRef();

    const theme = useTheme()
    const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
    const [email, setEmail] = React.useState("")

    const onFormSubmit = (e) => {
        e.preventDefault()
        form.current.trigger().then(({isValid}) => {
            if (isValid) {
                onSubmit(email);
            }
        });
    }

    return <Box {...props} classes={{root: classes.root}}>
        <Box classes={{root: classes.boxRoot}} display="flex" flexDirection="column" justifyContent={{sm: "center"}}>
            { headline }
            { title }
            { subtitle }
            { inputHeadline }
            <Form formRef={(f) => {form.current= f;}}
                  T={T}
                  classes={{root: classes.form, grid: classes.formGrid}}
                  defaultValues={defaultValues}
                  onBlur={() => {}}
                  onSubmit={(e) => e.preventDefault()}
            >
                <TextField classes={{root: classes.textField}}
                           color="secondary"
                           name="email"
                           showErrors
                           onChange={(e, val) => setEmail(val)}
                           box={{my: 0}}
                           grid={{xs: 12, md: 6}}
                           placeholder="Indirizzo Email" rules={isMobile ? null : {
                    email: {value: true, message: {key: 'components.registration_section.insert_valid_email', defaultVal: 'Inserisci un indirizzo email valido'}},
                }}/>
                <Box grid={{xs: 12, md: 6}} classes={{root: classes.subscribeBox}}>
                    <Button color="accent" classes={{root: classes.subscribeButton}} onClick={(e) => onFormSubmit(e)} variant={"contained"}><T defaultVal="Iscriviti">forms.buttons.subscribe</T></Button>
                </Box>
            </Form>
            <div className={classes.backgroundImage}></div>
            {showMore}
        </Box>
    </Box>;
}

export default withStyles(styles, {useTheme: true})(RegistrationSection);
