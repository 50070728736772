
import {withI18n} from "@ticketag/i18nlib";
import React from "react"
import {Redirect, withRouter} from "react-router-dom"
import {withAuth} from "../../hoc/Auth/Provider";

const WELCOME_DISABLED = !!process.env.WELCOME_DISABLED

class WelcomeSwitch extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {children, location, auth, i18n} = this.props;
        const isAuthenticated = auth.isAuthenticated()
        const userInfo = auth.getUserInfo()
        const match = location.pathname.endsWith("/welcome")
        if (isAuthenticated && userInfo && !userInfo.complete && !match && !WELCOME_DISABLED) {
            return <Redirect to={i18n.buildLocalizedUrl("/welcome")}/>
        }
        return children
    }

}

export default withRouter(withI18n(withAuth(WelcomeSwitch)))