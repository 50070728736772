import withStyles from "@material-ui/core/styles/withStyles";
import Header from "@ticketag/ticketag-uilib/src/components/base/Header/Header";
import {T} from "@ticketag/i18nlib";
import {withPage} from "../../hoc/Page/Provider";
import React from "react";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import Box from "@material-ui/core/Box";
import {withModals} from "@ticketag/ticketag-uilib/src/components/hoc/Modals/Provider";

const defaultSubtitlePadding = 7

const styles = (theme) => {
    return {
        root: {
            fontSize: "18px",
            fontWeight: "400",
            lineHeight: "30px",
            letterSpacing: "0.01em",
            paddingBottom: theme.spacing(defaultSubtitlePadding),
            "& a[href='#help']": {
                display: "block"
            }
        }
    }
}


function TTextFooter(props) {
    const {children, classes, modals, T : tComponent, multiline = true, useMarkdown = true, useTemplate = true, args, align = "center", page, helpTitle, help, ...other} = props
    const ref = React.useRef()
    const handleOpenModal = (e) => {
        props.modals.OkOnly(<T defaultVal={helpTitle}>{`pages.${page}.help_title`}</T>,
            <Box pb={2}>
                { help }
            </Box>
            , {size: "md"})
    }
    React.useEffect(() => {
        setTimeout(() => {
            if (ref.current) {
                const help = ref.current.querySelector("a[href='#help']")
                help.addEventListener("click", (e) => {
                    e.preventDefault()
                    handleOpenModal(e)
                })
            }
        }, 500)
    })
    const footer =
        page && typeof children === "string" ?
            <T multiline={multiline} useMarkdown={useMarkdown} useTemplate={useTemplate}
               args={{Help: help ? "#help" : null, ...args}}
               defaultVal={children}>
                {`page.${page}.footer`}
            </T>
            : tComponent || children
    return (
        <Box ref={ref}>
            <Typography color={"textPrimary"} className={classes.root} align={align} variant={"body1"} {...other}>
                {footer}
            </Typography>
        </Box>
    )
}

export default withStyles(styles)(withPage(withModals(TTextFooter)))