import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T, withI18n} from "@ticketag/i18nlib";
import Progress from "@ticketag/ticketag-uilib/src/components/base/Progress/Progress";
import {withApi} from "../../hoc/Api/Provider";
import AuthContext from "../../hoc/Auth/context";
import Grid from "@material-ui/core/Grid";
import {Icon} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import Box from "@material-ui/core/Box";
import Link from "@ticketag/ticketag-uilib/src/components/base/Link/Link";
import TextField from "@ticketag/ticketag-uilib/src/components/base/TextField/TextField";
import {Divider} from "@material-ui/core";
import {withRouter, Link as RouterLink} from "react-router-dom";
import Button from "@ticketag/ticketag-uilib/src/components/base/Button/Button";
import {WebapiprotoInviteType as INVITE_TYPE} from "@ticketag/diveedi-client/src/model/WebapiprotoInviteType";
import ActionCard from "@ticketag/ticketag-uilib/src/components/complex/ActionCard/ActionCard";
import BaseActionCard from "@ticketag/ticketag-uilib/src/components/complex/BaseActionCard/BaseActionCard";
import SelectGroupCard from "../../components/SelectGroupCard/SelectGroupCard";
import Data from "../../data";
import {withAuth} from "../../hoc/Auth/Provider";
import {withErrorModal, withRedirect} from "../../components/ErrorMessage/ErrorMessage";
import {withModals} from "@ticketag/ticketag-uilib/src/components/hoc/Modals/Provider";
import Hidden from "@material-ui/core/Hidden";
import Layout from "../../hoc/Layout/Layout";
import Navbar from "../../components/Navbar/Navbar";
import NavTitle from "../../components/Navbar/NavTitle/NavTitle";
import {pages} from "../../constants";
import Title from "../../components/Title/Title";
import Subtitle from "../../components/Subtitle/Subtitle";
import Page from "../../components/Page/Page";
import {withGlobalLoader} from "../../hoc/GlobalLoader/Provider";

const styles = (theme) => {
    return {
        title: {
            fontWeight: "600",
            fontSize: "36px",
            [theme.breakpoints.up("sm")]: {
                fontSize: "42px",
            },
        },
        subtitle: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(6),
            [theme.breakpoints.up("sm")]: {
                marginTop: theme.spacing(7),
            },
            fontSize: "16px",
        },
        headerIcon: {
            height: '50px',
            marginRight: '16px'
        },
        errorText: {
            fontSize: "18px"
        },
        selectCard: {
            marginBottom: "48px"
        },
        fontWeightBold: {
            fontWeight: "bold"
        }
    }
};

class Invite extends Component {
    static contextType = AuthContext

    constructor(props) {
        super(props);
        this.state = {
            isLoaded:false
        }
    }
    componentDidMount() {
        this.loadData()
    }

    loadData() {
        this.props.loader.Show()
        withRedirect(this.props.history, this.checkInvitationToken().then((group) => {
            if (this.props.auth.isAuthenticated() && this.isJoin()) {
                withRedirect(this.props.history, this.props.api.joinApi.joinGroup({
                    uuid: group.uuid, invitation: this.props.match.params.token
                }).then(data => {
                    this.props.history.push(this.joinUrl(data.uuid))
                }))
            } else {
                withRedirect(this.props.history, this.props.api.GetServiceFamily(group.serviceFamilyUuid).
                then((family) => {
                    this.props.loader.Hide()
                    this.setState({
                        isLoaded: true,
                        family: family,
                        group: group,
                        error: false
                    })
                }))
            }
        }))
    }

    joinUrl(groupUuid) {
        return this.props.i18n.buildLocalizedUrl('groups', groupUuid, 'join', 'payment')
    }

    isJoin() {
        return this.props.match.params.action === "join"
    }

    checkInvitationToken() {
        return this.props.api.groupApi.checkInvitationToken({token: this.props.match.params.token})
    }

    onGroupSelected(uuid) {
        if (!this.props.auth.isAuthenticated()) {
            const currentUrl = window.location.pathname;
            window.location.href = `${this.props.auth.signUrlWithMail(false)}?lang=${this.props.i18n.selectedLocale()}&from=${currentUrl + "/join"}`
        } else {
            withErrorModal(this.props.modals, this.props.api.joinApi.joinGroup({
                uuid: uuid, invitation: this.props.match.params.token
            }).then(data => {
                this.props.history.push(this.joinUrl(uuid))
            }))
        }
    }

    renderContent() {
        const {
            data = Data.Invite,
            classes,
            ...other
        } = this.props;
        const error = false
        const family = this.state.family
        const group = this.state.group
        if (!this.state.isLoaded) {
            return <Box display={"flex"} alignItems={"center"} justifyContent={"center"} height={"100%"} width={"100%"}>
                <Progress/>
            </Box>
        }
        return <Grid container>
            { !error ?
                <Grid item xs={12}>
                    <Box pt={1} display="flex" justifyContent={'center'}>
                        <SelectGroupCard
                            classes={{root: classes.selectCard}}
                            serviceTypeLogo={group.serviceTypeLogo}
                            onClick={() => {this.onGroupSelected(group.uuid)}}
                            group={this.state.group}
                        />
                    </Box>
                </Grid> :
                null
            }
        </Grid>
    }

    renderNavbar() {
        if (!this.state.isLoaded) {
            return <Navbar logo/>
        }
        const serviceFamily = this.state.family
        return <Navbar
            useTemplate
            args={{Name: serviceFamily.name }}
            logo={serviceFamily.logo}
            title={this.props.layout.title}
        />
    }

    render() {
        const isLoading = !this.state.isLoaded
        if (isLoading) {
            return null
        }
        const serviceFamily = this.state.family
        const group = this.state.group
        const {
            layout,
            seo,
            title,
            subtitle,
            ...other
        } = this.props;
        return (
            <Page layout={{...layout, navbar: this.renderNavbar()}} seo={seo} {...other}>
                <Hidden xsDown>
                    <Title
                        isLoading={isLoading}
                        align={"center"}
                        useTemplate
                        args={isLoading ? null : {Name: serviceFamily.name }}
                        avatar={isLoading ?
                            null :
                            serviceFamily.logo
                        }
                    >{title}</Title>
                </Hidden>
                <Subtitle
                    isLoading={isLoading}
                    align={"center"}
                    useTemplate
                    args={isLoading ? null : {Name: serviceFamily.name, Owner:  group.ownerName}}
                >{subtitle}</Subtitle>
                {
                    this.renderContent()
                }
            </Page>
        )
    }
}

export default withStyles(styles, {useTheme: true})(withGlobalLoader(withAuth(withApi(withI18n((withRouter(withModals(Invite))))))));
