import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Link from "@ticketag/ticketag-uilib/src/components/base/Link/Link";
import {Link as RouterLink} from "react-router-dom";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import Box from "@material-ui/core/Box";
import {Icon} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";
import Stepper from "@ticketag/ticketag-uilib/src/components/base/Stepper/Stepper";
import Step from "@ticketag/ticketag-uilib/src/components/base/Stepper/Step";
import {Skeleton} from "@material-ui/lab";
import Breadcrumbs from "@ticketag/ticketag-uilib/src/components/base/Breadcrumbs/Breadcrumbs";
import Header from "@ticketag/ticketag-uilib/src/components/base/Header/Header";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";


const styles = (theme) => {
    return {
        root:    {},
        icon:    {
            marginRight: theme.spacing(1),
            height:      '50px'
        },
        stepper: {
            width: '300px'
        },
        title:   {
            marginBottom: theme.spacing(0.5),
        }
    }
};

export const NewGroupHeader = ({
                                   linkTitle,
                                   linkUrl,
                                   pageTitle,
                                   links = null,
                                   serviceLogo,
                                   classes,
                                   titleContent,
                                   showStepper = false,
                                   showBreadcrumbs : showBreadcrumbsProp = true,
                                    isMobile,
                                   isLoading,
                                   ...props
                               }) => {
    /*
    *             <Header
                mb={spacings.header}
                avatar={this.state.serviceFamily.logo}
                breadcrumbs={
                    <Breadcrumbs
                        linksComponent={RouterLink}
                        links={
                            [
                                {url: this.props.i18n.buildLocalizedUrl('groups'), title: <T defaultVal="Gruppi">global.links.groups</T>}
                            ]
                        }>{this.pageTitle}</Breadcrumbs>
                }
            >{this.pageTitle}</Header>
    * */
    let showBreadcrumbs = showBreadcrumbsProp
    if (isMobile) {
        showBreadcrumbs = false
    }
    return <>
        <Header
            breadcrumbs={
                showBreadcrumbs
                ?
                <Breadcrumbs
                    linksComponent={RouterLink}
                    links={[{title: linkTitle, url: linkUrl}]}>{pageTitle}</Breadcrumbs>
                :
                null
            }
            avatarHeight={showBreadcrumbsProp ? 38 : 75}
            wrap={!showBreadcrumbs}
            avatar={! isMobile ? serviceLogo: null}
            isLoading={isLoading}
            {...props}>{pageTitle}
        </Header>
        {showStepper ? <Box mb={6} display="flex" justifyContent={"center"}>
            <Stepper classes={{root: classes.stepper}}>
                <Step label={1} active={props.step === 1} completed={props.step > 1}/>
                <Step label={2} active={props.step === 2}/>
            </Stepper>
        </Box> : null}
    </>
}

const withWidth = (Component) => {
    return function (props) {
        const theme = useTheme()
        const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));
        return (
            <Component isMobile={isMobile} {...props}/>
        )
    }
}

export default withStyles(styles, {useTheme: true})(withWidth(NewGroupHeader));
