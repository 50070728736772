import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T, withI18n} from "@ticketag/i18nlib";
import Progress from "@ticketag/ticketag-uilib/src/components/base/Progress/Progress";
import {withApi} from "../../hoc/Api/Provider";
import AuthContext from "../../hoc/Auth/context";
import NewGroupHeader from "../../components/NewGroupHeader/NewGroupHeader";
import Grid from "@material-ui/core/Grid";
import NewGroupPage from "../../components/NewGroupPage/NewGroupPage";
import Checkbox from "@ticketag/ticketag-uilib/src/components/base/Checkbox/Checkbox"
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography"
import Box from "@material-ui/core/Box";
import Button from "@ticketag/ticketag-uilib/src/components/base/Button/Button";
import {Icon} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";
import JSForm from "../../components/JSForm/JSForm";
import PartialLoader from "../../components/PartialLoader/PartialLoader";
import {withErrorModal, withRedirect} from "../../components/ErrorMessage/ErrorMessage";
import {withModals} from "../../../../../ticketag-uilib/src/components/hoc/Modals/Provider";
import {UndoCreateGroup} from "../../hoc/Modals/Modals";
import Layout from "../../hoc/Layout/Layout";
import Page from "../../hoc/Page/Provider";



const styles = (theme) => {
    return {
        root: {

        },
        nextButton: {
            width: "80px",
            height: "80px",
            borderRadius: "50%",
            marginBottom: "40px"
        },
        endSection: {
            height: "100%",
            "& > *": {
                height: "100%",
                alignItems: "flex-end"
            }
        },
        subtitle: {
            fontSize: "18px",
            fontWeight: "400",
            lineHeight: "30px",
            letterSpacing: "0.01em",
            marginBottom: "56px"
        },
        footer: {
            fontSize: "18px",
            fontWeight: "400",
            lineHeight: "30px",
            letterSpacing: "0.01em",
            marginBottom: "24px"
        },
        attention: {
            hypens: "none",
            textTransform: "uppercase",
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "20px",
            color: theme.palette.error.main,
            "& svg": {
                marginRight: "8px",
                marginBottom: "-2px",
            }
        },
        success: {
            hypens: "none",
            textTransform: "uppercase",
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "20px",
            color: "#57d9cb",
            "& svg": {
                opacity: "0.7",
                marginBottom: "-10px",
                width: "30px",
                height: "30px"
            }
        },
        checkbox: {
            "& span": {
                fontWeight: "300",
                fontSize: "18px"
            }
        }
    }
};

const defaultSecurityInfo = `La sicurezza è importante: per creare il gruppo, entra nel tuo account e modifica la tua password personale con quella generata automaticamente che ti abbiamo fornito qui sopra!`

class CompleteGroup extends Component {
    static contextType = AuthContext

    constructor(props) {
        super(props);
        this.state = {
            isLoaded:false,
            group: null,
            step: 1,
            isWaiting: false,
            formData: null,
            confirmData: null,
            isValid: false,
            confirm: false,
        }
    }
    componentDidMount() {
        this.loadData()
    }

    completeGroup() {
        this.setState({isWaiting: true})
        if (this.hasCredentials()) {
            withErrorModal(this.props.modals, this.props.api.groupApi.
            setGroupAccount(this.props.match.params.groupUuid, {
                data:  btoa(String.fromCharCode(...new TextEncoder("utf-8").encode(JSON.stringify(this.state.formData))))
            }).then(resp => {
                this.props.history.push(this.props.i18n.buildLocalizedUrl('groups', this.props.match.params.groupUuid, 'create', 'summary'))
            }))
        } else {
            this.props.history.push(this.props.i18n.buildLocalizedUrl('groups', this.props.match.params.groupUuid, 'create', 'summary'))
        }
    }


    loadData() {
        withRedirect(this.props.history, this.props.api.GetGroupDetails(this.props.match.params.groupUuid).then(data => {
            return this.props.api.GetServiceFamily(data.serviceFamilyUuid).then((serviceFamily) => {
                const listItems = []
                listItems.push(...[
                    {
                        title: <T defaultVal="Visibilità">pages.complete_group.scope</T>,
                        subtitle: <T capitalize defaultVal={data.scope}>{`groups.scope.${data.scope}`}</T>,
                    },
                    {
                        title: <T defaultVal="Suddivisione Quote">pages.complete_group.price_type</T>,
                        subtitle: <T defaultVal="Quota fissa">pages.complete_group.price_type_fixed</T>,
                    },
                    {
                        title: <T defaultVal="Posti Disponibili">pages.complete_group.size</T>,
                        subtitle: data.size,
                    },
                ])
                this.setState({
                    isLoaded:true,
                    group: data,
                    serviceFamily: serviceFamily,
                    cardListItems: listItems,
                })
           })
        }))
    }

    hasCredentials() {
        return this.state.group.hasCredentials
    }

    goToStep(step) {
        this.setState({step: step})
    }

    renderTitle() {
        const step = this.state.step
        if (this.hasCredentials()) {
            if (step === 1) {
                return <T key={"group_credentials_title"} defaultVal={"Credenziali"}>pages.complete_group.title</T>
            }
            return <T key={"group_credentials_title_confirm_credentials"} defaultVal={"Conferma credenziali"}>pages.complete_group.title_confirm_credentials</T>
        }
        return (
            <T key={"group_credentials_title_no_credentials"} defaultVal={"Credenziali"}>pages.complete_group.title_no_credentials</T>
        )
    }

    renderSubtitle() {
        const step = this.state.step
        if (!this.hasCredentials()) {
            return <Box width={{xs: "100%", lg: "66.66666%"}} m={"auto"}>
                <Box ml={-2.5} mb={4} mt={2}>
                    <Typography align={"center"} className={this.props.classes.attention}>
                        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.8091 15.735L11.2193 0.857061C10.9648 0.41624 10.509 0.153076 10 0.153076C9.49098 0.153076 9.03516 0.41624 8.78066 0.857061L0.190898 15.735C-0.0636328 16.1758 -0.0636328 16.7021 0.190898 17.1429C0.445391 17.5837 0.901211 17.8469 1.41023 17.8469H18.5898C19.0988 17.8469 19.5546 17.5837 19.8091 17.1429C20.0636 16.7021 20.0636 16.1758 19.8091 15.735ZM18.793 16.5563C18.7677 16.6001 18.7076 16.6736 18.5898 16.6736H1.41023C1.29234 16.6736 1.23227 16.6001 1.20703 16.5563C1.18176 16.5125 1.14809 16.4237 1.20703 16.3216L9.79676 1.4437C9.8557 1.34163 9.94938 1.32636 9.99996 1.32636C10.0506 1.32636 10.1443 1.34159 10.2032 1.4437L18.793 16.3216C18.852 16.4237 18.8183 16.5125 18.793 16.5563Z" fill="#FF5485"/>
                            <path d="M10.5868 5.48804H9.41345V11.7457H10.5868V5.48804Z" fill="#FF5485"/>
                            <path d="M10.0003 14.4835C10.4323 14.4835 10.7825 14.1333 10.7825 13.7013C10.7825 13.2693 10.4323 12.9191 10.0003 12.9191C9.56824 12.9191 9.21802 13.2693 9.21802 13.7013C9.21802 14.1333 9.56824 14.4835 10.0003 14.4835Z" fill="#FF5485"/>
                        </svg>
                        &nbsp;
                        <T defaultVal={"Attenzione"}>pages.complete_group.attention</T>
                    </Typography>
                </Box>
                <Typography  color={"textPrimary"} className={this.props.classes.subtitle} align={"center"} variant={"body1"}>
                    <T key={"group_credentials_subtitle_no_credentials"}
                       useTemplate args={{Group: this.state.serviceFamily.name}}
                       defaultVal={"Questo gruppo non ha le credenziali, una volta che i partecipanti saranno entrati nel gruppo, dovrai invitarli dal pannello di amministrazione di {{.args.Group}}"}
                    >pages.complete_group.subtitle_no_credentials</T>
                </Typography>
            </Box>
        }
        if (step === 1) {
            return <T key={"group_credentials_subtitle"} defaultVal={"Le tue credenziali saranno criptate e salvate in modo sicuro."}>pages.complete_group.subtitle</T>
        }
        return <T key={"group_credentials_subtitle_confirm_credentials"} defaultVal={"Per favore conferma le tue credenziali per sicurezza."}>pages.complete_group.subtitle_confirm_credentials</T>
    }

    renderFooter() {
        const enabled = this.state.step === 1 ? this.state.isValid : this.state.confirm
        if (!this.hasCredentials()) {
            return (
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
                    <Button ref={this.nextButtonRef} variant={"contained"} color={"gradient"} className={this.props.classes.nextButton} onClick={() => this.completeGroup()} disabled={!enabled}>
                        <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M30.6316 16.8585C31.4127 16.0775 31.4127 14.8112 30.6316 14.0301L17.9037 1.3022C17.1226 0.521152 15.8563 0.521152 15.0753 1.3022C14.2942 2.08325 14.2942 3.34958 15.0753 4.13063L26.389 15.4443L15.0753 26.758C14.2942 27.5391 14.2942 28.8054 15.0753 29.5865C15.8563 30.3675 17.1226 30.3675 17.9037 29.5865L30.6316 16.8585ZM0.772949 17.4443H29.2174V13.4443H0.772949V17.4443Z" fill="#FCFCFC"/>
                        </svg>
                    </Button>
                </Box>
            )
        }
        const handleNext = this.state.step === 1 ?
            () => this.goToStep(2) :
            () => this.completeGroup()
        const renderMessage = this.state.step === 1 ? (enabled) => null :
            (enabled) => <Box>
                <Box m={"auto"} mt={2} mb={7} width={{xs: "100%", lg: "66.6666%"}}>
                    {
                        enabled ?
                            <Typography align={"center"} className={this.props.classes.success}>
                                <svg viewBox={"0 0 24 24"} fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.641 15.395l-3.336-3.336-1.136 1.128 4.472 4.472 9.6-9.6-1.128-1.128z" fill="#57d9cb"/>
                                </svg>
                                &nbsp;
                                <T key={"credentials_matching"} defaultVal={"Le credenziali coincidono. Procedi per creare il tuo gruppo."}>pages.complete_group.credentials_matching</T>
                            </Typography>
                            :
                            <Typography align={"center"} className={this.props.classes.attention}>
                                <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.8091 15.735L11.2193 0.857061C10.9648 0.41624 10.509 0.153076 10 0.153076C9.49098 0.153076 9.03516 0.41624 8.78066 0.857061L0.190898 15.735C-0.0636328 16.1758 -0.0636328 16.7021 0.190898 17.1429C0.445391 17.5837 0.901211 17.8469 1.41023 17.8469H18.5898C19.0988 17.8469 19.5546 17.5837 19.8091 17.1429C20.0636 16.7021 20.0636 16.1758 19.8091 15.735ZM18.793 16.5563C18.7677 16.6001 18.7076 16.6736 18.5898 16.6736H1.41023C1.29234 16.6736 1.23227 16.6001 1.20703 16.5563C1.18176 16.5125 1.14809 16.4237 1.20703 16.3216L9.79676 1.4437C9.8557 1.34163 9.94938 1.32636 9.99996 1.32636C10.0506 1.32636 10.1443 1.34159 10.2032 1.4437L18.793 16.3216C18.852 16.4237 18.8183 16.5125 18.793 16.5563Z" fill="#FF5485"/>
                                    <path d="M10.5868 5.48804H9.41345V11.7457H10.5868V5.48804Z" fill="#FF5485"/>
                                    <path d="M10.0003 14.4835C10.4323 14.4835 10.7825 14.1333 10.7825 13.7013C10.7825 13.2693 10.4323 12.9191 10.0003 12.9191C9.56824 12.9191 9.21802 13.2693 9.21802 13.7013C9.21802 14.1333 9.56824 14.4835 10.0003 14.4835Z" fill="#FF5485"/>
                                </svg>
                                &nbsp;
                                <T key={"credentials_no_matching"} defaultVal={"Le credenziali non coincidono. Per favore assicurati di immettere le credenziali corrette"}>pages.complete_group.credentials_no_matching</T>
                            </Typography>

                    }
                </Box>
            </Box>
        return (
            <Box display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
                { renderMessage(enabled) }
                <Button ref={this.nextButtonRef} variant={"contained"} color={"gradient"} className={this.props.classes.nextButton} onClick={handleNext} disabled={!enabled}>
                    <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M30.6316 16.8585C31.4127 16.0775 31.4127 14.8112 30.6316 14.0301L17.9037 1.3022C17.1226 0.521152 15.8563 0.521152 15.0753 1.3022C14.2942 2.08325 14.2942 3.34958 15.0753 4.13063L26.389 15.4443L15.0753 26.758C14.2942 27.5391 14.2942 28.8054 15.0753 29.5865C15.8563 30.3675 17.1226 30.3675 17.9037 29.5865L30.6316 16.8585ZM0.772949 17.4443H29.2174V13.4443H0.772949V17.4443Z" fill="#FCFCFC"/>
                    </svg>
                </Button>
                {
                    /*
                    this.hasCredentials() ?
                        <Typography color={"textPrimary"} className={this.props.classes.footer} align={"center"} variant={"body1"}>
                            <T key={"credentials_footer"} defaultVal={"Che garanzie ho nel darvi i dati?"}>pages.complete_group.footer</T>
                        </Typography>
                        :
                        <Typography color={"textPrimary"} className={this.props.classes.footer} align={"center"} variant={"body1"}>
                            <T key={"credentials_footer"} defaultVal={"Più info?"}>pages.complete_group.footer_no_credentials</T>
                        </Typography>
                        */
                }
            </Box>
        )
    }

    handleChange(formData, isValid) {
        this.setState({formData: formData, isValid})
    }


    handleConfirm(val) {
        this.setState({confirm: val})
    }

    handleClose() {
        UndoCreateGroup(this.props.modals)
            .catch((ok) => {
                if (ok) {
                    this.props.api.groupApi.closeGroup(this.props.match.params.groupUuid, {}).then(() => {
                        this.props.history.push(this.props.i18n.buildLocalizedUrl("groups"))
                    }).catch((err) => {
                        console.log(err)
                    })
                }
            })
            .then(() => {
                this.props.history.push(this.props.i18n.buildLocalizedUrl("groups"))
            })
    }

    renderContent() {
        if (!this.hasCredentials()) {
            return (
                <Grid container justify={"center"} alignItems={"center"}>
                    <Grid item>
                            <Box px={3} pt={2} pb={6} className={this.props.classes.checkbox}>
                                <Checkbox label={
                                    <T defaultVal={"Ho capito"}>pages.complete_group.confirm_no_credentials</T>
                                } onChange={(e, val) => this.handleConfirm( val)}/>
                            </Box>
                    </Grid>
                </Grid>
            )
        }
        const schema = this.state.group.schema
        if (this.state.step === 2) {
            const formData = this.state.formData
            const checkEquals = (newData) => {
                return Object.keys(formData).reduce((acc, key) => {
                    return acc && (newData[key] && newData[key] === formData[key])
                }, true)
            }
            return <Grid container justify={"center"} alignItems={"center"}>
                <Box>
                    <JSForm
                        confirm={true}
                        liveValidate={false}
                        size={"big"}
                        group={this.state.group}
                        serviceFamily={this.state.serviceFamily}
                        disabled={this.state.isWaiting}
                        schema={schema}
                        onFormChange={(formData, isValid) => this.handleConfirm(isValid && checkEquals(formData))}
                    />
                </Box>
            </Grid>

        }
        return <Grid container justify={"center"} alignItems={"center"}>
                    <JSForm
                        size={"big"}
                        group={this.state.group}
                        serviceFamily={this.state.serviceFamily}
                        disabled={this.state.isWaiting}
                        schema={schema}
                        onFormChange={(formData, isValid) => this.handleChange(formData, isValid)}
                    />
            </Grid>
    }

    render() {
        if (!this.state.isLoaded) {
            return <Progress/>
        }
        const layout = this.props.route.layout
        return (
            <Layout {...layout}>
                <Page
                    title={this.renderTitle()}
                    subtitle={this.renderSubtitle()}
                    footer={this.renderFooter()}
                >
                    {this.renderContent()}
                </Page>
            </Layout>
        )
    }
}

export default withStyles(styles, {useTheme: true})(withApi(withI18n(withModals(CompleteGroup))));
