/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * The WebapiprotoPaymentMethod model module.
 * @module model/WebapiprotoPaymentMethod
 * @version v0.4
 */
export class WebapiprotoPaymentMethod {
  /**
   * Constructs a new <code>WebapiprotoPaymentMethod</code>.
   * @alias module:model/WebapiprotoPaymentMethod
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>WebapiprotoPaymentMethod</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/WebapiprotoPaymentMethod} obj Optional instance to populate.
   * @return {module:model/WebapiprotoPaymentMethod} The populated <code>WebapiprotoPaymentMethod</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new WebapiprotoPaymentMethod();
      if (data.hasOwnProperty('uuid'))
        obj.uuid = ApiClient.convertToType(data['uuid'], 'String');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('last_4'))
        obj.last4 = ApiClient.convertToType(data['last_4'], 'String');
      if (data.hasOwnProperty('brand'))
        obj.brand = ApiClient.convertToType(data['brand'], 'String');
      if (data.hasOwnProperty('is_default'))
        obj.isDefault = ApiClient.convertToType(data['is_default'], 'Boolean');
      if (data.hasOwnProperty('exp_month'))
        obj.expMonth = ApiClient.convertToType(data['exp_month'], 'Number');
      if (data.hasOwnProperty('exp_year'))
        obj.expYear = ApiClient.convertToType(data['exp_year'], 'Number');
      if (data.hasOwnProperty('pending'))
        obj.pending = ApiClient.convertToType(data['pending'], 'Boolean');
      if (data.hasOwnProperty('setup_intent_id'))
        obj.setupIntentId = ApiClient.convertToType(data['setup_intent_id'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} uuid
 */
WebapiprotoPaymentMethod.prototype.uuid = undefined;

/**
 * @member {String} name
 */
WebapiprotoPaymentMethod.prototype.name = undefined;

/**
 * @member {String} last4
 */
WebapiprotoPaymentMethod.prototype.last4 = undefined;

/**
 * @member {String} brand
 */
WebapiprotoPaymentMethod.prototype.brand = undefined;

/**
 * @member {Boolean} isDefault
 */
WebapiprotoPaymentMethod.prototype.isDefault = undefined;

/**
 * @member {Number} expMonth
 */
WebapiprotoPaymentMethod.prototype.expMonth = undefined;

/**
 * @member {Number} expYear
 */
WebapiprotoPaymentMethod.prototype.expYear = undefined;

/**
 * @member {Boolean} pending
 */
WebapiprotoPaymentMethod.prototype.pending = undefined;

/**
 * @member {String} setupIntentId
 */
WebapiprotoPaymentMethod.prototype.setupIntentId = undefined;


