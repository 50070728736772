import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { T } from "@ticketag/i18nlib";
import Grid from "@material-ui/core/Grid";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import { Box } from "@material-ui/core";
import capitalize from "@material-ui/core/utils/capitalize";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const styles = theme => {
  return {
    root: {},
    header: {
      color: theme.palette.text.primary,
      textTransform: 'uppercase',
      fontSize: '14px'
    },
    body: {
      color: theme.palette.text.secondary
    },
    alignLeft: {},
    alignCenter: {
      textAlign: 'center'
    },
    alignRight: {
      textAlign: 'right'
    }
  };
};

export const SubscriptionInfoTable = ({
  renewalPeriod,
  nextRenewal,
  amount,
  classes,
  ...props
}) => {
  const isXs = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const headerCell = (align, value) => <Grid className={classes[`align${capitalize(align)}`]} item sm={4} xs={12}><Box>{value}</Box></Grid>;

  const cell = (align, header, value) => <Grid className={classes[`align${capitalize(align)}`]} item sm={4} xs={12}>
        <Box classes={{
      root: classes.header
    }} mt={{
      xs: 2,
      sm: 0
    }}>{header}</Box>
        <Box classes={{
      root: classes.body
    }}>{value}</Box>
    </Grid>;

  return <Box {...props} componentname="subscription-info-table">
        <Grid container>
            {cell('left', <T defaultVal="Rinnovo">components.subscription_info_table.renewal</T>, renewalPeriod)}
            {cell(isXs ? 'left' : 'center', <T defaultVal="Prossimo rinnovo">components.subscription_info_table.next_renewal</T>, nextRenewal)}
            {cell(isXs ? 'left' : 'right', <T defaultVal="Quota">components.subscription_info_table.amount</T>, amount)}
        </Grid>
    </Box>;
};
export default withStyles(styles, {
  useTheme: true
})(SubscriptionInfoTable);