import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import capitalize from "@material-ui/core/utils/capitalize";

import Access from "../../../resources/icons/access_icon.svg";
import Check from "../../../resources/icons/check.svg";
import Checkbox from "../../../resources/icons/checkbox.svg";
import CheckboxChecked from "../../../resources/icons/checkbox-checked.svg";
import Close from "../../../resources/icons/close.svg";
import Edit from "../../../resources/icons/edit.svg";
import Eye from "../../../resources/icons/eye.svg";
import EyeClosed from "../../../resources/icons/eye-closed.svg";
import Radio from "../../../resources/icons/radio.svg";
import RadioChecked from "../../../resources/icons/radio-checked.svg";
import CheckCircle from "../../../resources/icons/check-circle.svg";
import SelectArrow from "../../../resources/icons/select-icon.svg";
import Shopping from "../../../resources/icons/shopping_icon.svg";
import ExpandMore from "../../../resources/icons/expand-more.svg";
import TrashCan from "../../../resources/icons/trash-can.svg";
import ReadMore from "../../../resources/icons/read_more_icon.svg";
import Ticket from "../../../resources/icons/ticket.svg";
import Search from "../../../resources/icons/search.svg";
import Menu from "../../../resources/icons/menu.svg";
import Info from "../../../resources/icons/info.svg";
import Lock from "../../../resources/icons/lock.svg";
import Mail from "../../../resources/icons/mail.svg";
import CashCircle from "../../../resources/icons/cash-circle.svg";
import Plus from "../../../resources/icons/plus.svg";
import PlusCircle from "../../../resources/icons/plus-circle.svg";
import Cog from "../../../resources/icons/cog.svg";
import MoreVert from "../../../resources/icons/more_vert.svg";
import Send from "../../../resources/icons/send.svg";
import BackArrow from "../../../resources/icons/back-arrow.svg";
import Help from "../../../resources/icons/help.svg";
import Exit from "../../../resources/icons/exit.svg";
import Enter from "../../../resources/icons/enter.svg";
import Image from "../../../resources/icons/image.svg";
import Sync from "../../../resources/icons/sync.svg";
import Flag from "../../../resources/icons/flag.svg";
import Language from "../../../resources/icons/language.svg";
import LanguageSync from "../../../resources/icons/language_sync.svg";
import RightArrow from "../../../resources/icons/right-arrow.svg";
import Alert from "../../../resources/icons/alert.svg";
import Copy from "../../../resources/icons/copy.svg";
import BreadcrumbsArrow from "../../../resources/icons/breadcrumbs_arrow.svg";
import Link from "../../../resources/icons/link.svg";
import Address from "../../../resources/icons/address.svg";
import Reload from "../../../resources/icons/reload.svg";
import MobilePlay from "../../../resources/icons/mobile_play.svg";
import NarrowArrow from "../../../resources/icons/narrow-arrow.svg";
import Refund from "../../../resources/icons/refund.svg";
import GoBackward from "../../../resources/icons/go-backward.svg";
import GoForward from "../../../resources/icons/go-forward.svg";



import Video from "../../../resources/icons/video.svg";
import Music from "../../../resources/icons/music.svg";
import Book from "../../../resources/icons/book.svg";
import Document from "../../../resources/icons/document.svg";
import Sport from "../../../resources/icons/sport.svg";
import Fitness from "../../../resources/icons/fitness.svg";
import Gaming from "../../../resources/icons/gaming.svg";
import Gift from "../../../resources/icons/gift.svg";




import FirstPage from "../../../resources/icons/firstpage.svg";
import LastPage from "../../../resources/icons/lastpage.svg";
import KeyboardArrowRight from "../../../resources/icons/keyarrowright.svg";
import KeyboardArrowLeft from "../../../resources/icons/keyarrowleft.svg";

import Amazon from "../../../resources/icons/logos/amazon.svg";
import Facebook from "../../../resources/icons/logos/facebook.svg";
import Instagram from "../../../resources/icons/logos/instagram.svg";
import Pinterest from "../../../resources/icons/logos/pinterest.svg";
import Twitter from "../../../resources/icons/logos/twitter.svg";
import Youtube from "../../../resources/icons/logos/youtube.svg";

import Visa from "../../../resources/icons/logos/visa.svg";
import Mastercard from "../../../resources/icons/logos/mastercard.svg";
import useTheme from "@material-ui/core/styles/useTheme";
import rgbHex from "rgb-hex";
import Box from "@material-ui/core/Box";
import {styled} from "@material-ui/core";
import {spacing} from "@material-ui/system";

const styles = (theme) => {
    return {
        root: {
        },
        colorWhite: {
            fill: theme.palette.common.white
        },
        colorGradient: {
            fill: 'url(#gradient)'
        },
        sizeSmall: {
            height: '0.5em',
            width: '0.5em',
        },
        sizeNormal: {
            height: '1em',
            width: '1em',
        },
        sizeLarge: {
            height: '2em',
            width: '2em',
        },
        fontWeightNormal: {},
        fontWeightBold: {
            stroke: "currentColor",
            strokeWidth: "1"
        },
        overlayIcon: {
            overflow: 'visible'
        }
    }
};

const getColor = (theme, color) => {
    const colors = {
        default: theme.palette.text.primary,
        primary: theme.palette.primary.main,
        textPrimary: theme.palette.text.primary,
        secondary: theme.palette.secondary.main,
        textSecondary: theme.palette.text.secondary,
        error: theme.palette.error.main,
        success: theme.palette.success.main,
        disabled: `#${rgbHex(theme.palette.action.disabled)}`,
        black: theme.palette.common.black,
        white: theme.palette.common.white,
        accent: theme.palette.accent.main,
        gradient: {
            start: theme.palette.default_gradient_start,
            end: theme.palette.default_gradient_end,
        },
    }
    return colors[color]
}

const mapChildren = (children, fn) => {
    if (Array.isArray(children)) {
        return children.map(fn)
    }
    return fn(children, 0)
}

const svgx = styled('svg')(spacing)

const icon = (svg, name) => {
    return ({theme, classes, className,color = 'default', containerClasses, size='Normal', fontWeight='Normal', overlayIcon, width, height,...other}) => {
        const useClasses = [];
        const colorId=`${name}_color_${color}`
        theme = theme||useTheme()
        // useClasses.push(classes["color" + capitalize(color)])
        useClasses.push(classes["size" + capitalize(size)])
        useClasses.push(classes["fontWeight" + capitalize(fontWeight)])
        useClasses.push(className) //, ...useClasses
        overlayIcon ? useClasses.push(classes.overlayIcon) : null

        let colorValue = getColor(theme, color)||getColor(theme,'secondary')
        colorValue = typeof colorValue === 'string' ? {start: colorValue, end: colorValue} : colorValue
        const svgComponent = svg()
        let inlineStyles = {}
        if (width) {
            inlineStyles.width = width
        }
        if (height) {
            inlineStyles.height = height
        }
        const children = mapChildren(svgComponent.props.children, (c, i) => {
            if (!c.props) {
                return c
            }
            const newProps = {}
            if (c.props?.fill && c.props.fill === 'url(#color)') {
                newProps.fill = `url(#${colorId})`
            }
            if (c.props?.stroke && c.props.stroke === 'url(#color)') {
                newProps.stroke = `url(#${colorId})`
            }
            return React.createElement(c.type, {...c.props, key:i, ...newProps})
        })

        return <SvgIcon viewBox={svgComponent.props.viewBox}
                        component={svgx}
                        className={clsx(classes.root, ...useClasses)} {
            ...(Object.keys(inlineStyles).length > 1 ? {style: inlineStyles} : null)
        } {...other}>
            <linearGradient id={colorId} x1='0%' y1='0%' x2='100%' y2='0%' gradientUnits='userSpaceOnUse'>
                <stop stopColor={colorValue.start}/>
                <stop offset="100%" stopColor={colorValue.end}/>
            </linearGradient>
            <g>
                {children}
            </g>
            {overlayIcon ? React.createElement(overlayIcon.type, {...overlayIcon.props, x: '60%', y: '60%', height: '50%', width: '50%'}): null}
        </SvgIcon>
    }
}

export const Icons = {}
const rawIcons = {}

export const Icon = (props) => {
    const i = Icons[props.children];
    if (!i) {
        return <></>
    }
    const I =  withStyles(styles, {withTheme: true})(i ? i : icon("", ""))
    return <I {...props}/>
}

export const getIconSvg = (name) => {
    const i = rawIcons[name];
    if (!i) {
        return <></>
    }
    return i
}

export const parseIcon = (icon) => {
    if (typeof icon !== 'string') {
        return icon
    }
    const parser = new DOMParser()
    const doc = parser.parseFromString(icon, "image/svg+xml");
    const svgElem = doc.getElementsByTagName('svg')
    const paths = doc.getElementsByTagName('path')
    const elements = Array.from(paths).map((p, i) => {
        const attrs = {}
        for (const attr of p.attributes) {
            attrs[attr.name] = attr.value
        }
        return <path key={i} {...attrs}/>
    })

    const attrs = {}
    for (const attr of svgElem[0].attributes) {
        attrs[attr.name] = attr.value
    }
    const parsed = <svg {...attrs}>{elements}</svg>

    return () => parsed
}

export function RegisterIcon(name, i) {
    const muiName = `Mui${name.split('_').map((e) => capitalize(e)).join("")}Icon`
    rawIcons[name] = i
    if (typeof icon !== 'string') {
        i = parseIcon(i)
    }

    return Icons[name] = withStyles(styles, {name: muiName, withTheme: true})(icon(i, name))
}

export const AccessIcon = RegisterIcon("access", Access);
export const CheckIcon = RegisterIcon("check", Check);
export const CheckboxIcon = RegisterIcon("checkbox", Checkbox);
export const CheckboxCheckedIcon = RegisterIcon("checkbox_checked", CheckboxChecked);
export const CloseIcon = RegisterIcon("close", Close);
export const EditIcon = RegisterIcon("edit", Edit);
export const EyeIcon = RegisterIcon("eye", Eye);
export const EyeClosedIcon = RegisterIcon("eye_closed", EyeClosed);
export const RadioIcon = RegisterIcon("radio", Radio);
export const RadioCheckedIcon = RegisterIcon("radio_checked", RadioChecked);
export const CheckCircleIcon = RegisterIcon("check_circle", CheckCircle);
export const SelectArrowIcon = RegisterIcon("select_arrow", SelectArrow);
export const ShoppingChartIcon = RegisterIcon("shopping_chart", Shopping);
export const ExpandMoreIcon = RegisterIcon("expand_more", ExpandMore);
export const TrashCanIcon = RegisterIcon("trash_icon", TrashCan);
export const ReadMoreIcon = RegisterIcon("read_more", ReadMore);
export const TicketIcon = RegisterIcon("ticket", Ticket);
export const SearchIcon = RegisterIcon("search", Search);
export const MenuIcon = RegisterIcon("menu", Menu)
export const InfoIcon = RegisterIcon("info", Info);
export const LockIcon = RegisterIcon("lock", Lock);
export const MailIcon = RegisterIcon("mail", Mail);
export const CashCircleIcon = RegisterIcon("cash_circle", CashCircle);
export const PlusIcon = RegisterIcon("plus", Plus);
export const PlusCircleIcon = RegisterIcon("plus_circle", PlusCircle);
export const CogIcon = RegisterIcon("cog", Cog);
export const MoreVertIcon = RegisterIcon("more_vert", MoreVert);
export const SendIcon = RegisterIcon("send", Send);
export const BackIcon = RegisterIcon("back", BackArrow);
export const HelpIcon = RegisterIcon("help", Help);
export const ExitIcon = RegisterIcon("exit", Exit);
export const EnterIcon = RegisterIcon("enter", Enter);
export const ImageIcon = RegisterIcon("image", Image);
export const SyncIcon = RegisterIcon("sync", Sync);
export const FlagIcon = RegisterIcon("flag", Flag);
export const LanguageIcon = RegisterIcon("language", Language);
export const LanguageSyncIcon = RegisterIcon("language_sync", LanguageSync);
export const RightArrowIcon = RegisterIcon("right_arrow", RightArrow);
export const AlertIcon = RegisterIcon("alert", Alert);
export const CopyIcon = RegisterIcon("copy", Copy);
export const BreadcrumbsArrowIcon = RegisterIcon("breadcrumbs_arrow", BreadcrumbsArrow);
export const LinkIcon = RegisterIcon("link", Link);
export const AddressIcon = RegisterIcon("address", Address);
export const ReloadIcon = RegisterIcon("reload", Reload);
export const MobilePlayIcon = RegisterIcon("mobile_play", MobilePlay);
export const NarrowArrowIcon = RegisterIcon("narrow_arrow", NarrowArrow);
export const RefundIcon = RegisterIcon("refund", Refund);
export const GoBackwardIcon = RegisterIcon("go_backward", GoBackward);
export const GoForwardIcon = RegisterIcon("go_forward", GoForward);




export const VideoIcon = RegisterIcon("video", Video);
export const MusicIcon = RegisterIcon("music", Music);
export const BookIcon = RegisterIcon("book", Book);
export const DocumentIcon = RegisterIcon("document", Document);
export const FitnessIcon = RegisterIcon("fitness", Fitness);
export const SportIcon = RegisterIcon("sport", Sport);
export const GamingIcon = RegisterIcon("game", Gaming);
export const GiftIcon = RegisterIcon("game", Gift);


export const FirstPageIcon = RegisterIcon("first_page", FirstPage);
export const LastPageIcon = RegisterIcon("last_page", LastPage);
export const KeyboardArrowRightIcon = RegisterIcon("keyboard_arrow_right", KeyboardArrowRight);
export const KeyboardArrowLeftIcon = RegisterIcon("keyboard_arrow_left", KeyboardArrowLeft);

export const AmazonIcon = RegisterIcon("amazon",Amazon);
export const FacebookIcon = RegisterIcon("facebook", Facebook);
export const InstagramIcon = RegisterIcon("instagram", Instagram);
export const PinterestIcon = RegisterIcon("pinterest",Pinterest);
export const TwitterIcon = RegisterIcon("twitter", Twitter);
export const YoutubeIcon = RegisterIcon("youtube", Youtube);

export const VisaIcon = RegisterIcon("visa", Visa);
export const MastercardIcon = RegisterIcon("mastercard", Mastercard);
