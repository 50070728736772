import React, {Component} from 'react';
import ApiContext from './context';
import {ApiClient, GroupApi, JoinApi, ServiceApi, SystemApi, UserApi, WalletApi} from "@ticketag/diveedi-client";
import Progress from "@ticketag/ticketag-uilib/src/components/base/Progress/Progress";
import {loadStripe} from '@stripe/stripe-js/pure';
import {Elements} from "@stripe/react-stripe-js";
import {EventListenerClient} from "../../services/eventBus/websocket";
import {registerAvatarIcon} from "@ticketag/ticketag-uilib/src/components/base/Avatar/AvatarIcon";
import {ChatApi} from "@ticketag/chatjslib";
import {WebapiprotoPageName as PAGE_NAMES} from "@ticketag/diveedi-client/dist/model/WebapiprotoPageName";
//import * as WebSharingWebApi from "@ticketag/diveedi-client";

const CACHE_WAIT_TIME_MS = 100
const DEFAULT_EVENT_TIMEOUT_MS = 10000
const DEFAULT_REQUEST_TIMEOUT_MS = 15000

function b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}

export class ApiProvider extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
        this.apiClient = this.props.apiClient || new ApiClient();
        this.cache = {
            serviceFamilies: []
        }

        this.eventListeners = []
        this.listenerCount = 0

        this.userApi = new UserApi(this.apiClient)
        this.serviceApi = new ServiceApi(this.apiClient)
        this.systemApi = new SystemApi(this.apiClient)
        this.groupApi = new GroupApi(this.apiClient)
        this.joinApi = new JoinApi(this.apiClient)
        this.walletApi = new WalletApi(this.apiClient)
        this.stripe = loadStripe(this.props.stripeKey);

        const onEventBusConnect = (e) => {
            return this.eventBus.SendListenEvents({}).progress((val) => {
                this.getListeners(val.body).forEach(l => {
                    l.cb(val.body)
                    if (l.once) {
                        this.removeListener(l.id)
                    }
                })
            }).then(resp => {

            })
        }
        this.chat = new ChatApi(this.props.wsClient, "")
        this.eventBus = new EventListenerClient(null, null, {onConnect: onEventBusConnect}, this.props.wsClient) //
        this.userApi.getAvatars().then(resp => {
            resp.avatars.map(avatar => {
                registerAvatarIcon(avatar.uuid, avatar.content)
            })
        })
        onEventBusConnect()
        /*this.getServiceFamilies().then(data => {
            //data.families.push()
            //RegisterIcon(data.slug, <img src={data.logo}/>)//
        })*/
    }


    componentDidMount() {

    }
    registerNotificationDevice(sub,base_url){
        return this.systemApi.registerNotificationDevice(
            {
                endpoint:sub.endpoint,
                auth:sub.keys.auth,
                p256dh:sub.keys.p256dh,
                base_url:base_url
            })
    }

    groupServiceFamiliesByTags(families, page, lang) {
        let familyTags = {}
        families.forEach((family) => {
            family.tags.forEach((tag) => {
                if (!Object.keys(familyTags).includes(tag)) {
                    familyTags[tag] = [family]
                }
                else {
                    familyTags[tag].push(family)
                }
            })
        })
        return this.getPage(PAGE_NAMES.plp, {lang: lang}).then((tags) => {
            return [
                {key: "all", required: false, checked: true, priority: -1, serviceFamilies: families},
                ...tags.cards.filter((tag) => {
                    return Object.keys(familyTags).includes(tag.key)
                }).map((tag) => (
                        {...tag, serviceFamilies: familyTags[tag.key]}
                    )
                )
            ]
        })
    }

    getTagsByServiceFamilies({lang, _public}) {
        return Promise.all([
            this.getPage(PAGE_NAMES.plp, {lang: lang}),
            this.getServiceFamilies(true, {_public: _public})
        ]).then(([tags, families]) => {
            let familyTags = {}
            families.forEach((family) => {
                family.tags.forEach((tag) => {
                    if (!Object.keys(familyTags).includes(tag)) {
                        familyTags[tag] = [family]
                    }
                    else {
                        familyTags[tag].push(family)
                    }
                })
            })
            return tags.cards.filter((tag) => {
                return Object.keys(familyTags).includes(tag.key)
            }).map((tag) => (
                {...tag, serviceFamilies: familyTags[tag.key]}
                )
            )
        });
    }

    getServiceFamilies(noCache = false, opts = {}) {
        return this.serviceApi.getServiceFamilies(opts).then(data => {
            return data.families
        })
    }

    getServiceFamily(uuid) {
        return this.getServiceFamilies().then(data => {
            const ret = data.find(s => s.uuid === uuid)
            if (!ret) {
                throw {error: 'not found'}
            }
            return ret
        })
    }

    getServiceFamilyByName(name) {
        return this.getServiceFamilies().then(data => {
            const ret = data.find(s => s.name.toLowerCase() === name.toLowerCase())
            if (!ret) {
                throw {error: 'not found'}
            }
            return ret
        })
    }
    getServiceFamilyBySlug(slug) {
        return this.getServiceFamilies().then(data => {
            const ret = data.find(s => s.slug === slug)
            if (!ret) {
                throw {error: 'not found'}
            }
            return ret
        })
    }

    getServiceTypeByName(familyUuid, name) {
        return this.serviceApi.getFamilyServices(familyUuid).then(data => {
            const ret = data.services.find(s => s.name.toLowerCase() === name.toLowerCase())
            if (!ret) {
                throw {error: 'not found'}
            }
            return ret
        })
    }

    getServiceTypeBySlug(familyUuid, slug) {
        return this.serviceApi.getFamilyServices(familyUuid).then(data => {
            const ret = data.services.find(s => s.slug === slug)
            if (!ret) {
                throw {error: 'not found'}
            }
            return ret
        })
    }


    getServiceType(uuid) {
        return this.serviceApi.getService(uuid).then(data => {
            return data
        })
    }

    getPage(name, opts) {
        const sortByPriority = (a, b) => {
            const priorityA = a.priority ? a.priority : -1
            const priorityB = b.priority ? b.priority : -1
            return priorityA - priorityB
        }
        return this.systemApi.getPage(name, opts).then(data => {
            return {
                ...data,
                cards: data.cards ? data?.cards.sort(sortByPriority) : []
            }
        })
    }

    setDefaultPaymentMethod(paymentMethodUuid) {
        return new Promise((resolve, reject) => {
            const timeoutHandler = setTimeout(() => reject({message: "Si è verificato un errore nell'eliminazione del tuo metodo di pagamento"}), DEFAULT_EVENT_TIMEOUT_MS * 2)
            this.listenEvent('user.default_payment_method_edited', '*', (evt) => {
                clearTimeout(timeoutHandler)
                resolve(evt?.referUuids?.payment_method)
            }, true)
            this.userApi.updateUserPreferences({defaultPaymentMethodUuid: paymentMethodUuid})
        })
    }
    getSetupIntent(stripe,clientSecret){
        return stripe.then(stripeClient=>{
            return stripeClient.retrieveSetupIntent(clientSecret)
        })

    }
    createPaymentMethod(stripe, form,returnUrl) {
        return new Promise((resolve, reject) => {
            const timeoutHandler = setTimeout(() => reject({message: "Si è verificato nella selezione del metodo di pagamento"}), DEFAULT_EVENT_TIMEOUT_MS * 2)
            this.listenEvent('user.default_payment_method_edited', '*', (evt) => {
                clearTimeout(timeoutHandler)
                resolve(evt?.referUuids?.payment_method)
            }, true)
            this.userApi.createPaymentMethod({}).then((data) => {
                form.stripe.confirmCardSetup(data.setupIntentClientSecret, {
                    return_url: returnUrl,
                    payment_method: {
                        card: form.cardElement,
                        billing_details: {
                            name: `${form.firstName} ${form.lastName}`,
                        },
                    }
                },{handleActions:false}).then(({setupIntent, error}) => {
                    if (error) {
                        reject(error)
                    }
                    if (setupIntent.next_action!=null && setupIntent.next_action.redirect_to_url!==undefined){
                        window.location.href=setupIntent.next_action.redirect_to_url.url
                    }
                }).catch((err) => reject(err))
            }).catch((err) => reject(err))
        })
    }

    deletePaymentMethod(uuid) {
        return new Promise((resolve, reject) => {
            const timeoutHandler = setTimeout(() => reject({message: "Si è verificato un errore nell'eliminazione del tuo metodo di pagamento"}), DEFAULT_EVENT_TIMEOUT_MS * 2)
            this.listenEvent('user.payment_method_detached', '*', (evt) => {
                clearTimeout(timeoutHandler)
                resolve(evt?.referUuids?.payment_method)
            }, true)
            this.userApi.deletePaymentMethod(uuid).catch((err) => reject(err))
        })
    }

    kickUser(groupUuid, userUuid) {
        return new Promise((resolve, reject) => {
            setTimeout(() => reject({code:'timeout', message: "Si è verificato un errore durante l'espulsione dell'utente dal gruppo"}), DEFAULT_EVENT_TIMEOUT_MS * 2)
            this.listenEvent('group.kick_requested', '*', (evt) => {
                resolve(evt)
            }, true)
            this.groupApi.kickUser(groupUuid, {userUuid: userUuid}).catch((err) => reject(err))
        })
    }

    unkickUser(groupUuid, userUuid) {
        return new Promise((resolve, reject) => {
            setTimeout(() => reject({code:'timeout', message: "Si è verificato un errore durante la riammissione dell'utente nel gruppo"}), DEFAULT_EVENT_TIMEOUT_MS * 2)
            this.listenEvent('group.unkick', '*', (evt) => {
                resolve(evt)
            }, true)
            this.groupApi.unKickUser(groupUuid, {userUuid: userUuid}).catch((err) => reject(err))
        })

    }

    closeGroup(groupUuid, confirm, size = 0) {
        const eventId = size === 0 ? 'group.closed' : 'group.close_requested'
        const eventPromise = new Promise((resolve, reject) => {
            this.listenEvent(eventId, groupUuid, (evt) => {
                resolve(evt)
            }, true)
        })
        return Promise.all([
            this.groupApi.closeGroup(groupUuid, {password: confirm}),
            eventPromise
        ]).then(([closeResp, event]) => {
            return event
        })
    }

    setMainProfile(profileUuid) {
        return this.userApi.updateUserPreferences({defaultProfileUuid: profileUuid}).then(resp => {
            return resp.defaultProfile
        })
    }

    getGroupDetails(groupUuid) {
        return this.groupApi.groupDetails(groupUuid).then(group => {
            group.schema = JSON.parse(b64DecodeUnicode(group.schema))
            return group
        }).catch(e => {
            if (e.status) {
                throw e
            }
            throw {message: e.message, stack: e.stack}
        })
    }

    getGroupAccount(groupUuid) {
        return this.groupApi.getGroupAccount(groupUuid).then(account => {
            account.schema = JSON.parse(b64DecodeUnicode(account.schema))
            account.schema.title = ''
            account.data = JSON.parse(b64DecodeUnicode(account.data))
            account.data.title = ''
            return account
        }).catch(e => {
            if (e.status) {
                throw e
            }
            throw {message: e.message, stack: e.stack}
        })
    }

    generatePassword(serviceTypeUuid) {
        return this.systemApi.getPassword(serviceTypeUuid).then(resp => {
            return resp.password//atob(resp.password)
        }).catch(e => {
            if (e.status) {
                throw e
            }
            throw {message: e.message, stack: e.stack}
        })
    }

    listenEvent(eventId, uuid, cb, once = false) {
        this.listenerCount++
        const id = this.listenerCount
        this.eventListeners.push({eventId: eventId, uuid: uuid, once: once, cb: cb, id})
        return id
    }

    matchEventId(listener, eventId) {
        return listener.eventId === '*' || typeof listener.eventId === 'object' && Array.isArray(listener.eventId) ? listener.eventId.indexOf(eventId) !== -1 : listener.eventId === eventId
    }

    getListeners(eventBody) {
        const referUuids = Object.keys(eventBody.referUuids).map(k => eventBody.referUuids[k])
        return this.eventListeners.filter(l => (this.matchEventId(l, eventBody.eventId)) && (l.uuid === '*' || referUuids.indexOf(l.uuid) !== -1))
    }

    removeListener(id) {
        return this.eventListeners = this.eventListeners.filter(l => l.id !== id)
    }

    render() {
        if (this.state.isLoading) {
            return this.props.loader ? this.props.loader : <Progress/>
        }
        const apiClient = this.apiClient
        const userApi = this.userApi
        const serviceApi = this.serviceApi
        const walletApi = this.walletApi;
        const systemApi = this.systemApi
        const groupApi = this.groupApi
        const joinApi = this.joinApi
        const stripe = this.stripe
        const eventBus = this.eventBus
        const chat = this.chat

        return (
            <ApiContext.Provider value={{
                get client() {return apiClient},
                get userApi() {return userApi},
                get serviceApi() {return serviceApi},
                get walletApi() {return walletApi},
                get groupApi() {return groupApi},
                get systemApi() {return systemApi},
                get joinApi() {return joinApi},
                get stripe() {return stripe},
                get chat() {return chat},
                get eventBus() {return eventBus},
                get defaultEventTimeout() {return DEFAULT_EVENT_TIMEOUT_MS},
                get defaultRequestTimeout() {return DEFAULT_REQUEST_TIMEOUT_MS},
                GetGroupDetails:         (uuid) => this.getGroupDetails(uuid),
                GetGroupAccount:         (uuid) => this.getGroupAccount(uuid),
                GetServiceFamilies:      (noCache, opts) => this.getServiceFamilies(noCache, opts),
                GetTagsByServiceFamilies: (lang, pageName) => this.getTagsByServiceFamilies(lang, pageName),
                RegisterNotificationDevice: (sub,baseUrl) => this.registerNotificationDevice(sub,baseUrl),
                GetServiceFamilyByName:  (name) => this.getServiceFamilyByName(name),
                GetServiceFamilyBySlug:  (slug) => this.getServiceFamilyBySlug(slug),
                GetServiceFamily:        (uuid) => this.getServiceFamily(uuid),
                GetServiceTypeByName:    (familyUuid, name) => this.getServiceTypeByName(familyUuid, name),
                GroupServiceFamiliesBytags: (families, page, lang) => this.groupServiceFamiliesByTags(families, page, lang),
                GetServiceTypeBySlug:    (familyUuid, name) => this.getServiceTypeBySlug(familyUuid, name),
                GetServiceType:          (uuid) => this.getServiceType(uuid),
                GetPage:                 (name, opts) => this.getPage(name, opts),
                SetDefaultPaymentMethod: (paymentMethodUuid) => this.setDefaultPaymentMethod(paymentMethodUuid),
                KickUser:                (groupUuid, userUuid) => this.kickUser(groupUuid, userUuid),
                UnkickUser:              (groupUuid, userUuid) => this.unkickUser(groupUuid, userUuid),
                DeletePaymentMethod:     (uuid) => this.deletePaymentMethod(uuid),
                CreatePaymentMethod:     (stripe, form,returnUrl) => this.createPaymentMethod(stripe, form,returnUrl),
                GetSetupIntent:          (stripe,clientSecret) => this.getSetupIntent(stripe,clientSecret),
                SetMainProfile:          (paymentMethodUuid) => this.setMainProfile(paymentMethodUuid),
                CloseGroup:              (groupUuid, confirm, size = 0) => this.closeGroup(groupUuid, confirm, size),
                GeneratePassword:        (serviceTypeUuid) => this.generatePassword(serviceTypeUuid),
                listenEvent:             (eventId, uuid, cb, once = false) => this.listenEvent(eventId, uuid, cb, once),
                removeListener:          (id) => this.removeListener(id),
            }}>
                <Elements stripe={stripe}>
                    {this.props.children}
                </Elements>
            </ApiContext.Provider>
        )
    }
}

export const withApi = (Component) => {
    return ({ref, ...props}) => (<ApiContext.Consumer>
        {(api) => {
            return <Component ref={ref} api={api} {...props}/>
        }}
    </ApiContext.Consumer>)
}

export default ApiProvider;
