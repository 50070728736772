import React, {useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T} from "@ticketag/i18nlib";
import Box from "@material-ui/core/Box/Box";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import Button from "@ticketag/ticketag-uilib/src/components/base/Button/Button";
import NewCard from "@ticketag/ticketag-uilib/src/components/complex/NewCard/NewCards";
import Grid from "@material-ui/core/Grid";
import {Divider} from "@material-ui/core";
import Tabs from "@ticketag/ticketag-uilib/src/components/base/Tabs/Tabs";
import Tab from "@ticketag/ticketag-uilib/src/components/base/Tabs/Tab";
import JoinCreateGroup from "../../pages/JoinCreateGroup/JoinCreateGroup";
import {Icon} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";
import Progress from "@ticketag/ticketag-uilib/src/components/base/Progress/Progress";

const styles = (theme) => {
    return {
        root: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            position: "relative",
            minHeight: "550px",
            paddingTop: "80px",
            paddingBottom: "60px",
            background: `url('${process.env.PUBLIC_URL||""}/img/homepage/service_bg.svg') no-repeat center`,
            backgroundSize: "cover",
            height: 'calc(100vh - 148px)', //"calc(100vh - 148px)",
            [theme.breakpoints.down('md')]: {
                height: "auto",
                paddingTop: "40px",
                paddingBottom: "32px",
            },
        },
        tabs: {
            marginBottom: "110px",
            justifyContent: "center",
            [theme.breakpoints.down('md')]: {
                marginBottom: "64px",
            },
        },
        boxRoot: {
            position: "relative",
            maxWidth: "1040px",
            margin: "0 auto",
            width: "100%",
            [theme.breakpoints.down('md')]: {
                margin: "0",
            },
            [theme.breakpoints.down('sm')]: {
                width: "unset",
            },
        },
        boxRootGroup: {
            position: "relative",
            maxWidth: "620px",
            margin: "0 auto",
            width: "100%",
            alignItems: "center",
            [theme.breakpoints.down('md')]: {
                margin: "0",
                padding: "0 40px",
            },
            [theme.breakpoints.down('sm')]: {
                width: "unset",
            },
            "& .headerTitle": {
                color: "#FCFCFC"
            }
        },
        card: {
            width: "100% !important",
            maxWidth: "450px",
            height: "100%",
            maxHeight: "175px",
            background: "none",
            display: "flex",
            [theme.breakpoints.down('md')]: {
                maxWidth: "100%",
                height: "auto",
            },
        },
        cardWrapper: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: "285px !important",
            [theme.breakpoints.down('md')]: {
                maxWidth: "225px",
            },
            [theme.breakpoints.down('sm')]: {
                maxWidth: "100% !important",
            },
        },
        tabButton: {
            padding: "0",
            fontWeight: "600", 
            fontSize: "26px",
            lineHeight: "31px",
            letterSpacing: "0.01em",
            color: "#FCFCFC",
            textTransform: "none",
            [theme.breakpoints.down('sm')]: {
                minWidth: "80px",
                fontSize: "14px",
                lineHeight: "17px",
                margin: "0 4px",
            },
            "&.Mui-selected::after": {
                backgroundColor: "#fcfcfc"
            },
        },
        tabButtonWrapper: {
            "&:hover": {
                color: "#fcfcfc",
            },
            "&:hover:after": {
                backgroundColor: "#fcfcfc",
            }
        },
        showMore: {
            marginTop: "140px",
            boxShadow: "none !important",
            "& > .MuiButton-label": {
                display: "flex",
                flexDirection: "column",
                fontWeight: "normal",
                fontSize: "24px",
                lineHeight: "29px",
                color: "#FCFCFC",
                textTransform: "none",
                [theme.breakpoints.down('sm')]: {
                    fontSize: "18px",
                    lineHeight: "22px",
                },
                "& > span": {
                    fontSize: "48px",
                    lineHeight: "49px",
                    [theme.breakpoints.down('sm')]: {
                        fontSize: "18px",
                        lineHeight: "18px",
                    },
                }
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: "56px",
            },
        },
        goBackButton: {
            marginLeft: "auto",
            marginRight: theme.spacing(4),
            boxShadow: "none !important",
            "& > .MuiButton-label": {
                display: "flex",
                flexDirection: "column",
                fontWeight: "normal",
                fontSize: "32px",
                lineHeight: "38px",
                color: "#FCFCFC",
                textTransform: "none",
                [theme.breakpoints.down('sm')]: {
                    fontSize: "18px",
                    lineHeight: "22px",
                },
            }
        },
        goBackButtonArrow: {
            fontSize: "53px",
            transform: "rotate(90deg)",
            marginLeft: "auto",
        },
        container: {
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gridGap: "92px",
            [theme.breakpoints.down('md')]: {
                padding: "0 45px",
                gridGap: "56px",
                maxWidth: "unset",
            },
            [theme.breakpoints.down('sm')]: {
                gridTemplateColumns: "1fr",
            },
        }
    }
};

const hoverContent = (classes, service, link, history) => {
    return <Box classes={{root: classes.cardHover}} container display="flex" justifyContent="space-evenly" alignItems="center">
        <Button classes={{root: classes.cardButton}} variant="text" onClick={(e) => {
            console.log("CLICK");
            e.preventDefault();
            e.stopPropagation();
            history.push(link);
        }}>Crea</Button>
        <Divider classes={{root: classes.cardButtonDivider}} vertical/>
        <Button classes={{root: classes.cardButton}} variant="text" onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            history.push(link);
        }}>Ottieni</Button>
    </Box>;
};

export const ServiceSection = ({
    classes={},
    servicesDefault=[],
    tags=[],
    isMobile=false,
    api=null,
    ...props
}) => {
    const [tab, setTabValue] = useState(0);
    const [services, setServices] = useState(servicesDefault);
    const [loading, setLoading] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [showCreateGroup, setShowCreateGroup] = useState(false);
    const [currentGroup, setCurrentGroup] = useState(null);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        if (api) {
            setLoading(true);
            const tag = tags[newValue];

            api.GetServiceFamilies(true, { tags: [tag.key] }).then((res) => {
                setServices(res); 
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                setLoading(false);
            });
        }
    };

    const toggleShowMore = () => {
        setShowMore(!showMore);
    };


    const servicesFilter = showMore ? [...services] : services.slice(0, 3);
    return <Box {...props} classes={{root: classes.root}} id="services">
        {
            !showCreateGroup
            ?
            <>
                <Tabs classes={{flexContainer: classes.tabs}} value={tab} onChange={handleChange} aria-label="tipologia servizio" center>
                    {
                        tags.map((tab, index) =>
                            <Tab key={index} classes={{root: classes.tabButton, wrapper: classes.tabButtonWrapper}} label={<T defaultVal={tab.title}>{`pages.homepage.${tab.key}`}</T>} />)
                    }
                </Tabs>
                <Box classes={{root: classes.boxRoot}} display="flex" flexDirection="column" justifyContent={{sm: "center"}} alignItems="center">
                    {
                        !loading
                        ?
                        <Grid classes={{root: classes.container}}>
                        {
                            servicesFilter.map(f =>
                                {
                                    return <Grid classes={{root: classes.cardWrapper}}  key={f.uuid} item>
                                        <NewCard elevated={false} contrast classes={{root: classes.card, image: classes.cardImage, content: classes.cardContent}} onClick={() => {
                                            setShowCreateGroup(true);
                                            setCurrentGroup(f.name);
                                        }} src={`${f.cardImage}`}/>
                                    </Grid>;
                                }
                            )
                        }
                        </Grid>
                        :
                        <Progress/>
                    }
                </Box>
                {
                    services.length <= 3
                    ?
                    null
                    :
                    !showMore
                    ?
                    <Button classes={{root: classes.showMore}}  variant="text" onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        toggleShowMore();
                    }}>Carica altri<span>+</span></Button>
                    :
                    <Button classes={{root: classes.showMore}}  variant="text" onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        toggleShowMore();
                    }}>Nascondi</Button>
                }
            </>
            :
            <Box classes={{root: classes.boxRootGroup}} display="flex" flexDirection="column" justifyContent={{sm: "center"}}>
                <JoinCreateGroup match={{params: {
                    serviceFamily: currentGroup
                }}} history={props.history} showBreadcrumbs={false}/>
                <Button classes={{root: classes.goBackButton}}  variant="text" onClick={(e) => {
                    setShowCreateGroup(false);
                    setCurrentGroup(null);
                }}>Torna ai servizi<Icon className={classes.goBackButtonArrow} color="white">arrowdown</Icon></Button>
            </Box>
        }
    </Box>;
}

export default withStyles(styles, {useTheme: true})(ServiceSection);
