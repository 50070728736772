import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T} from "@ticketag/i18nlib";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";


const styles = (theme) => {

    return {
        root: {
            [theme.breakpoints.down('xs')]: {
                paddingRight: "16px"
            },
            //margin: 0,
            //width: '100%',
        }
    }
};

export const CardsContainer = ({classes, mb, box={}, ...props}) => {
    return <Box {...box}><Grid classes={classes} container {...props}/></Box>
}

export default withStyles(styles, {useTheme: true})(CardsContainer);
