import React, {useState} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T} from "@ticketag/i18nlib";
import Box from "@material-ui/core/Box";
import ResponsiveCard from "@ticketag/ticketag-uilib/src/components/complex/ResponsiveCard/ResponsiveCard";
import IconButton from "@ticketag/ticketag-uilib/src/components/base/Button/IconButton";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import RadioGroup from "@ticketag/ticketag-uilib/src/components/base/Radio/RadioGroup";
import TextButton from "@ticketag/ticketag-uilib/src/components/base/Button/TextButton";
import BillingDataForm from "../BillingDataForm/BillingDataForm";

import moment from "moment";

const styles = (theme) => {
    return {
        root: {
            width: '100%'
        },
        box: {
            width: '100%',
            margin: 0,
            marginBottom: theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                marginBottom: theme.spacing(4)
            },
        },
        text: {
            marginLeft: 'auto',
        },
        textBox: {
            marginRight: theme.spacing(1),
        }
    }
};

const getFullName = (p) => `${p.firstName} ${p.lastName}`
const getFullAddress = (p) => `${p.address}, ${p.zipCode} ${p.city}`

const BillingDataSelector = ({smallVariant=false, inlineVariant=false, profiles, onSelect, onCreate, onDelete=null, verticalLayout=false, narrow=false, classes, modals, ...props}) => {
    const [editMode, setEditMode] = useState(false);
    const [disabled, setDisabled] = useState(props.disabled || false);

    const userProfileEmpty = profiles.find(p => p.isEmpty);

    const selectHandler = (p) => {
        setDisabled(true);
        onSelect(p, () => {
            setEditMode(false);
            setDisabled(false);
        })
    };

    const openFormModal = () => {
        setDisabled(true);
        let formRef = null;
        // onSubmit={/*(stripe, form) => onCreate(stripe, data)*/} disabled={formDisabled}
        return modals.Form('Dati di fatturazione', <BillingDataForm i18n={props.i18n} defaultValues={{}}/>, {
                size: 'lg',
                submitText: <T defaultVal="Aggiungi">components.billing_method_form.add_billing_method</T>,
            })
            .then(data => {
                onCreate(userProfileEmpty ? {
                    ...userProfileEmpty,
                    isMain: true,
                    isEmpty: false,
                } : {}, data.value)
            })
            .catch()
            .finally(() => {
                setDisabled(false);
            });
    };

    const openEditFormModal = (profile) => {
        setDisabled(true);
        let formRef = null;
        // onSubmit={/*(stripe, form) => onCreate(stripe, data)*/} disabled={formDisabled}
        return modals.Form('Dati di fatturazione', <BillingDataForm i18n={props.i18n} defaultValues={profile}/>, {
                size: 'lg',
                submitText: <T defaultVal="Modifica">components.billing_method_form.edit_billing_method</T>,
            })
            .then(data => {
                onCreate(profile, data.value)
            })
            .catch()
            .finally(() => {
                setDisabled(false);
            });
    };

    const onFormSubmit = (values) => {
        onCreate(userProfileEmpty ? {
            ...userProfileEmpty,
            isMain: true,
            isEmpty: false,
        } : {}, values);
    }

    return <>
        {
            inlineVariant && userProfileEmpty
            ?
            <BillingDataForm i18n={props.i18n} defaultValues={{}} inlineVariant={inlineVariant} onSubmit={onFormSubmit}/>
            :
            <>
            {
                !userProfileEmpty
                ?
                <RadioGroup classes={{root: classes.root}} row>
                    {profiles.filter(p => editMode === true ? true : p.isMain).map((p) => (
                        <Box classes={{root:classes.box}} key={p.uuid} m={1}>
                            <ResponsiveCard title={getFullName(p)} horizontal={!verticalLayout} selected={p.isMain}
                                            onSelect={() => p.isMain ? null : selectHandler(p)}
                                            narrow={narrow}
                                            action={
                                                <>
                                                    <Box mb={verticalLayout ? -2 : 0}>
                                                        <IconButton width={20} height={20} color={'gradient'} onClick={() => openEditFormModal(p)}>edit</IconButton>
                                                    </Box>
                                                    {
                                                        onDelete
                                                        ?
                                                        <Box px={1} mb={verticalLayout ? -2 : 0}>
                                                            <IconButton disabled={p.isMain} width={20} height={20} onClick={() => onDelete(p.uuid)} color={'error'}>
                                                                trash_icon
                                                            </IconButton>
                                                        </Box>
                                                        :
                                                        null
                                                    }
                                                </>
                                            }>
                                {
                                    (!verticalLayout && !smallVariant)
                                    ?
                                    <Typography variant={'subtitle1'}>
                                        {moment(p.birthday).format("DD/MM/YYYY")}
                                    </Typography>
                                    :
                                    null
                                }
                                <Box px={verticalLayout ? 0 : 5} style={{flexGrow: 1}}><Typography variant={'subtitle1'}>{getFullAddress(p)}</Typography></Box>
                                {
                                    !smallVariant
                                    ?
                                    <Typography variant={'subtitle1'}>
                                        <T defaultVal={'Tel'}>pages.profile.phone_short</T>: {p.phone}
                                    </Typography>
                                    :
                                    null
                                }
                            </ResponsiveCard>
                        </Box>
                    ))}
                </RadioGroup>
                :
                null
            }
            <Box classes={{root: classes.textBox}}>
                {
                    editMode || userProfileEmpty
                    ?
                    <TextButton classes={userProfileEmpty ? {} : {root: classes.text}} onClick={openFormModal} variant={'secondary'}>+ Aggiungi profilo di Fatturazione</TextButton>
                    :
                    <TextButton classes={{root: classes.text}} variant={'secondary'} onClick={() => setEditMode(true)}>
                        <T defaultVal="Cambia profilo di fatturazione">components.payment_method_selector.change_billing_method</T>
                    </TextButton>
                }
            </Box>
            </>
        }
    </>;
}

export default withStyles(styles, {useTheme: true})(BillingDataSelector);
