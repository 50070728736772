import withStyles from "@material-ui/core/styles/withStyles";
import React from 'react';
import Header from "@ticketag/ticketag-uilib/src/components/base/Header/Header";
import {T, withI18n} from "@ticketag/i18nlib";
import Grid from "@material-ui/core/Grid";
import {withRedirect} from "../../components/ErrorMessage/ErrorMessage";
import {withApi} from "../../hoc/Api/Provider";
import {withRouter} from 'react-router-dom';
import {WebapiprotoPageName as PAGE_NAMES} from "@ticketag/diveedi-client/dist/model/WebapiprotoPageName";
import Progress from "@ticketag/ticketag-uilib/src/components/base/Progress/Progress";
import ServiceSection from "./ServiceSection";
import MainSection from "./MainSection";
import Box from "@material-ui/core/Box";
import TextButton from "@ticketag/ticketag-uilib/src/components/base/Button/TextButton";
import {AlertIcon} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";
import {Hidden} from "@material-ui/core";
import Layout from "../../hoc/Layout/Layout";
import Navbar from "../../components/Navbar/Navbar";
import NavTitle from "../../components/Navbar/NavTitle/NavTitle";
import Title from "../../components/Title/Title";
import {pages} from "../../constants";
import Page from "../../components/Page/Page";
import {withPage} from "../../hoc/Page/Provider";


const styles = (theme) => {
    return {
        tab: {

        }
    }
}

class Services extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            popular: [],
            services: [],
            tags: [],
        }
    }

    componentDidMount() {
        withRedirect(this.props.history, this.props.api.GetTagsByServiceFamilies({lang: this.props.i18n.selectedLocale(), _public: true}).then((tags) => {
            console.log(tags)
            this.setState({
                isLoaded: true,
                tags: tags
            })
        }))
    }

    loadServiceFamilies() {
        return this.props.api.GetServiceFamilies(true)
    }

    goToSuggestions() {
        this.props.history.push(this.props.i18n.buildLocalizedUrl('suggestions'))
    }

    renderContent() {
        return (
            <Grid container>
                <Grid item xs={12} justify={"center"}>
                    <MainSection
                        withMobileTab={this.props.noSections} contrast={this.props.contrast} isLoading={!this.state.isLoaded} i18n={this.props.i18n} api={this.props.api}
                        history={this.props.history} tags={this.state.tags}/>
                </Grid>
                {this.props.noSections ? null : this.renderSections()}
            </Grid>
        )
    }

    renderSections() {
        return <>
            {
                !this.state.isLoaded ?
                    <>
                        <ServiceSection isLoading/>
                        <ServiceSection isLoading/>
                    </>
                    :
                    this.state.tags.filter((tag) => tag.required).map((tag, i) => <ServiceSection key={i}
                                                                                                  i18n={this.props.i18n}
                                                                                                  api={this.props.api}
                                                                                                  history={this.props.history}
                                                                                                  tag={tag}/>)
            }
            <Grid item xs={12}>
                <Box mt={4} mb={10}>
                    <TextButton isLoading={!this.state.isLoaded} onClick={() => this.goToSuggestions()} size="large"
                                variant="secondary">
                        <AlertIcon color="secondary"/>&nbsp;&nbsp;<T
                        defaultVal="Ci sono altri servizi che vorresti?">pages.groups.suggest_services</T>
                    </TextButton>
                </Box>
            </Grid>
        </>;
    }

    render() {
        const {noSections, title, subtitle, ...other} = this.props;
        return  (
            <Page {...other}>
                {
                    noSections ?
                        <Title align={{xs: "left", md: "center"}} contrast={this.props.contrast} isLoading={!this.state.isLoaded}>{`Servizi`}</Title>
                        :
                        <Hidden xsDown>
                            <Title align={"center"} contrast={this.props.contrast} isLoading={!this.state.isLoaded}>{`Servizi`}</Title>
                        </Hidden>
                }
                {this.renderContent()}
            </Page>
        )
    }

}



export default withStyles(styles)(withI18n(withApi(withRouter(Services))))