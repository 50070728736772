import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T} from "@ticketag/i18nlib";
import ListItem from "@ticketag/ticketag-uilib/src/components/base/ListItem/ListItem";
import ChipCard from "@ticketag/ticketag-uilib/src/components/complex/ChipCard/ChipCard";
import AvatarIcon from "@ticketag/ticketag-uilib/src/components/base/Avatar/AvatarIcon";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import {Skeleton} from "@material-ui/lab";


const styles = (theme) => {
    return {
        root: {}
    }
};

export const GroupAdminCard = ({user, ...props}) => {

    return <ChipCard {...props}>
        <ListItem isLoading={props.isLoading} prepend={<AvatarIcon clickable={false} size={"medium"} src={user.avatarUuid||"2"}/>}>
            <Typography variant="h5" color={"textSecondary"}>{props.isLoading ? <Skeleton width={80}/> : user.name}</Typography>
            <Typography color={"textPrimary"} variant="body2">{props.isLoading ? <Skeleton width={50}/> : <T defaultVal="Admin">components.group_admin_card.label</T>}</Typography>
        </ListItem>
    </ChipCard>
}

export default withStyles(styles, {useTheme: true})(GroupAdminCard);
