import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Accordion from "@material-ui/core/Accordion";

const styles = () => {
  return {
    root: {
      borderBottom: "1px solid #ededed",
      "&:before": {
        backgroundColor: "transparent"
      },
      boxShadow: "none!important",
      '&.Mui-expanded': {
        margin: '0px'
      }
    }
  };
};

function TAccordion(props) {
  const {
    children,
    ...other
  } = props;
  return <Accordion {...other} componentname="t-accordion">{children}</Accordion>;
}

TAccordion.propTypes = Accordion.propTypes;
export default withStyles(styles, {
  name: "MuiAccordion"
})(TAccordion);
