import Box from "@material-ui/core/Box";
import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {Menu, MenuItem, Typography} from "@material-ui/core";
import TextField from "@ticketag/ticketag-uilib/src/components/base/TextField/TextField"
import {withApi} from "../../hoc/Api/Provider";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/lab/Skeleton";
import {withRedirect} from "../../components/ErrorMessage/ErrorMessage";
import {T, withI18n} from "@ticketag/i18nlib";
import * as PropTypes from "prop-types";
import {SelectArrowIcon} from "@ticketag/ticketag-uilib/src/components/base/Icon/Icon";
import {withRouter} from "react-router-dom";
import IconButton from "@ticketag/ticketag-uilib/src/components/base/Button/IconButton";
import {LensIcon} from "../../components/Icons/Icons";
import {WebapiprotoPageName as PAGE_NAMES} from "@ticketag/diveedi-client/dist/model/WebapiprotoPageName";
import Layout from "../../hoc/Layout/Layout";
import Page from "../../components/Page/Page";
import Scroll from "../../components/Scroll/Scoll";
import Title from "../../components/Title/Title";
import TmpCard from "@ticketag/ticketag-uilib/src/components/complex/TmpCard/TmpCard";
import {pages} from "../../constants";
import {withAuth} from "../../hoc/Auth/Provider";
import {withGlobalLoader} from "../../hoc/GlobalLoader/Provider";

const styles = (theme) => {
    return {
        root: {
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            height: "100%",
            [theme.breakpoints.up("sm")]: {
                overflow: "hidden"
            }

        },
        cardContainer: {
            padding: "16px 0",
        },
        card: {
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            //justifyContent: "center",
            alignItems: "center",
            width: "112px",
            height: "120px",
            boxShadow: "-10px 10px 24px rgba(172, 172, 172, 0.08), 10px -10px 24px rgba(172, 172, 172, 0.08), 10px 10px 24px rgba(172, 172, 172, 0.08)",
            borderRadius: "16px",
            "& img": {
                padding: "12px",
                borderRadius: "50%",
                width: "54px",
                height: "54px",
            },
            "& span": {
                fontSize: "14px",
                fontWeight: "500",

            }
        },
        selectContainer: {
            cursor: "pointer"
        },
        selectTitle: {
            fontSize: "18px",
            fontWeight: "500",
            lineHeight: "22px"
        },
        selectArrow: {
            marginTop: "8px",
            marginLeft: "4px",
            width: "28px",
            height: "28px"

        },
        selectMenuPaper: {
            minWidth: "240px"
        },
        selectMenuItem: {
            fontSize: "20px"
        },
        textfield: {
            width: "auto",
            borderRadius: "16px",
            boxShadow: "-10px 10px 24px rgba(172, 172, 172, 0.08), 10px -10px 24px rgba(172, 172, 172, 0.08), 10px 10px 24px rgba(172, 172, 172, 0.08)",
            "& .MuiInputBase-root": {
                padding: "16px 24px!important"
            }
        }
    }
}

function Card(props) {
    const {classes, logo, name, onClick, ...other} = props;
    return (
        <TmpCard showArrow={false} className={classes.card} onClick={onClick} {...other}>
            <img src={logo}/>
            <Typography align={"center"} variant={"body2"} color={"textSecondary"} component={"span"}>{name}</Typography>
        </TmpCard>
    );
}

function Select(props) {
    const {classes, tags, onChange, color, ...other} = props;
    const [value, setValue] = React.useState(0)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = (event) => {
        setAnchorEl(null);
    }
    const handleSelect = (index) => () => {
        setAnchorEl(false)
        setValue(index)
        onChange(index)
    }
    if (tags && tags.length) {
        const selectedTag = tags[value]
        return (
            <Box>
                <Box  display={"flex"} alignItems={"center"} className={classes.selectContainer} onClick={handleOpen}>
                    <Typography key={selectedTag.key} className={classes.selectTitle} variant={"h4"} color={color}>
                        <T defaultVal={selectedTag.title}>{`pages.homepage.${selectedTag.key}`}</T>
                    </Typography>
                    <SelectArrowIcon className={classes.selectArrow} color={"textSecondary"}/>
                </Box>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    classes={{paper: classes.selectMenuPaper}}
                >
                    { tags.map((tag, index) => (
                        <MenuItem
                            classes={{root: classes.selectMenuItem}}
                            key={index} onClick={handleSelect(index)}><T defaultVal={tag.title}>{`pages.homepage.${tag.key}`}</T></MenuItem>
                    ))}
                </Menu>
            </Box>
        )
    }
    return (
        <Typography className={classes.selectTitle}>
            <Skeleton width={90}/>
        </Typography>
    )
}

class SelectServiceFamily extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            serviceFamilies: [],
            tags: [],
            selectedTag: 0,
            textSearch: "",
        }
    }
    componentDidMount() {
        withRedirect(this.props.history, Promise.all([
            this.props.api.GetServiceFamilies(true),
            this.props.api.userApi.getUserGroups()
        ]).then(([families, groups]) => {
            const allGroups = [...groups.owned, ...groups.joined]
            const availableFamilies = families.filter((f) => {
                return allGroups.find((g) => g.serviceFamilyUuid === f.uuid) === undefined
            })
            this.props.api.GroupServiceFamiliesBytags(availableFamilies, PAGE_NAMES.plp, this.props.i18n.selectedLocale()).then((tags) =>{
                // tag.checked =>  tag == "all"
                // tag.required => tag != "popular"
                this.setState({tags: tags.filter((tag) => tag.checked || tag.required), loaded: true})
            })
        }))
    }

    handleTextSearch(val) {
        this.setState({textSearch: val})
    }

    handleSelect(val) {
        this.setState({selectedTag: val, textSearch: ""})
    }

    renderContent() {
        const {classes, ...other} = this.props;
        return (
            <Box className={classes.root}>
                <Box p={2}>
                    <Grid container wrap={"nowrap"} justify={"center"} alignItems={"center"} spacing={2}>
                        <Grid item>
                            <Select tags={this.state.tags} classes={this.props.classes} onChange={(val) => this.handleSelect(val)}/>
                        </Grid>
                        <Grid item>
                            <TextField
                                size={"big"}
                                className={this.props.classes.textfield} placeholder={"Cerca..."} variant={"outlined"}
                                shape={"rounded"}
                                InputProps={{
                                    endAdornment: <LensIcon style={{marginTop: "4px", marginRight: "-4px"}} color={"accent"}/>
                                }}
                                onChange={(e, val) => this.handleTextSearch(val)}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Scroll>
                        <Grid container spacing={1} className={this.props.classes.cardContainer}>
                            {
                                this.state.loaded ?
                                    this.state.tags[this.state.selectedTag].serviceFamilies.filter((family) =>
                                        this.state.textSearch.length === 0 ? true : family.name.toLowerCase().substr(0, this.state.textSearch.length) === this.state.textSearch.toLowerCase()
                                    ).map((family, index) => (
                                        <Grid item xs={4}>
                                            <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                                <Card classes={classes} {...family} onClick={() => {
                                                    this.props.history.push(this.props.i18n.buildLocalizedUrl("services", family.slug, "create"))
                                                }}/>
                                            </Box>
                                        </Grid>
                                    )) :
                                    [...Array(18).keys()].map(() => {
                                        return <Grid item xs={4}>
                                            <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                                <Skeleton variant={"rect"} width={98} height={120}/>
                                            </Box>
                                        </Grid>
                                    })
                            }
                        </Grid>
                </Scroll>
            </Box>
        )
    }

    render() {
        const {auth, i18n, history} = this.props;
        const isLoading = !this.state.loaded
        const {
            title,
            seo,
            layout,
            ...other
        } = this.props;
        if (isLoading) {
            return null
        }
        return (
            <Page layout={{...layout,
                onGoBack: () => history.replace(i18n.buildLocalizedUrl("groups")),
                onClose: () => history.replace(i18n.buildLocalizedUrl("/groups"))
            }} seo={seo} {...other}>
                <Title>{title}</Title>
                {this.renderContent()}
            </Page>
        )
    }
}
export default withStyles(styles)(withRouter(withI18n(withAuth(withApi((SelectServiceFamily))))))