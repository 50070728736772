import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab'; // We can inject some CSS into the DOM.

const styles = theme => ({
  root: {
    minWidth: "0",
    margin: "0 20px",
    fontWeight: "500",
    color: theme.palette.text.secondary,
    textTransform: "none",
    "&.MuiSelected": {
      opacity: 1
    },
    "&.Mui-selected::after": {
      content: "''",
      height: "2px",
      width: "100%",
      position: "absolute",
      left: "0",
      bottom: "0",
      backgroundColor: theme.palette.text.secondary
    }
  },
  wrapper: {
    width: "auto",
    margin: "0px",
    "&:after": {
      content: "''",
      width: 0,
      postiton: "absolute",
      transitionDuration: "0.1s",
      transitionProperty: "width",
      transitionTimingFunction: "ease-in"
    },
    "&:hover": {
      color: theme.palette.text.secondary //fontWeight: "600",

    },
    "&:hover:after": {
      content: "''",
      height: "2px",
      width: "100%",
      position: "absolute",
      left: "50%",
      bottom: "0",
      transform: "translate(-50%)",
      backgroundColor: theme.palette.text.secondary
    }
  }
});

function TTab(props) {
  const {
    children,
    contrast,
    ...other
  } = props;
  return <Tab {...other} componentname="t-tab">{children}</Tab>;
}

TTab.propTypes = Tab.propTypes;
export default withStyles(styles, {
  useTheme: true
})(TTab);
