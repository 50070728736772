/*
 * WebSharing webAPI
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v0.4
 * Contact: it@ticketag.it
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 2.4.16
 *
 * Do not edit the class manually.
 *
 */

import {ApiClient} from '../ApiClient';

/**
 * The WebapiprotoServiceDetail model module.
 * @module model/WebapiprotoServiceDetail
 * @version v0.4
 */
export class WebapiprotoServiceDetail {
  /**
   * Constructs a new <code>WebapiprotoServiceDetail</code>.
   * @alias module:model/WebapiprotoServiceDetail
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>WebapiprotoServiceDetail</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/WebapiprotoServiceDetail} obj Optional instance to populate.
   * @return {module:model/WebapiprotoServiceDetail} The populated <code>WebapiprotoServiceDetail</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new WebapiprotoServiceDetail();
      if (data.hasOwnProperty('uuid'))
        obj.uuid = ApiClient.convertToType(data['uuid'], 'String');
      if (data.hasOwnProperty('name'))
        obj.name = ApiClient.convertToType(data['name'], 'String');
      if (data.hasOwnProperty('max_size'))
        obj.maxSize = ApiClient.convertToType(data['max_size'], 'Number');
      if (data.hasOwnProperty('total_price'))
        obj.totalPrice = ApiClient.convertToType(data['total_price'], 'Number');
      if (data.hasOwnProperty('locale'))
        obj.locale = ApiClient.convertToType(data['locale'], 'String');
      if (data.hasOwnProperty('service_type_uuid'))
        obj.serviceTypeUuid = ApiClient.convertToType(data['service_type_uuid'], 'String');
      if (data.hasOwnProperty('conditions_url'))
        obj.conditionsUrl = ApiClient.convertToType(data['conditions_url'], 'String');
    }
    return obj;
  }
}

/**
 * @member {String} uuid
 */
WebapiprotoServiceDetail.prototype.uuid = undefined;

/**
 * @member {String} name
 */
WebapiprotoServiceDetail.prototype.name = undefined;

/**
 * @member {Number} maxSize
 */
WebapiprotoServiceDetail.prototype.maxSize = undefined;

/**
 * @member {Number} totalPrice
 */
WebapiprotoServiceDetail.prototype.totalPrice = undefined;

/**
 * @member {String} locale
 */
WebapiprotoServiceDetail.prototype.locale = undefined;

/**
 * @member {String} serviceTypeUuid
 */
WebapiprotoServiceDetail.prototype.serviceTypeUuid = undefined;

/**
 * @member {String} conditionsUrl
 */
WebapiprotoServiceDetail.prototype.conditionsUrl = undefined;


