import {Component} from "react";

export const Rules = {
    required: (value, rule) => !!value,
    max: (value, rule) => {
        let compareVal = null
        switch (rule.type) {
            case 'date':
                compareVal = new Date(value)
                break
            case 'text':
                compareVal = (value||'').length
                break
            default:
                compareVal = value
        }
        return rule.eq ? rule.value >= compareVal : rule.value > compareVal
    },
    min: (value, rule) => {
        let compareVal = null
        if (rule.type === 'date') {
            compareVal = new Date(value)
        }
        switch (rule.type) {
            case 'date':
                compareVal = new Date(value)
                break
            case 'text':
                compareVal = (value||'').length
                break
            default:
                compareVal = value
        }
        return rule.eq ? rule.value <= compareVal : rule.value < compareVal
    },
    pattern: (value, rule) => rule.value.test(value),
    validate: (value, rule, form) => rule.match(value, rule, form),
    email: (value, rule) => new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$", 'i').test(value),
    equals: (value, rule) => rule.value === value
}

export class Controller extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name:  null,
            error: {}
        }

        this.isValid = false
        this.isDirty = false
        this.isTouched = false
        this._value = this.props.defaultValue
    }

    componentDidMount() {
        if (this.props.defaultValue) {
            this.isDirty = true
        }
    }

    get fieldState() {
        return {
            isValid:   this.isValid,
            isDirty:   this.isDirty,
            isTouched: this.isTouched,
            error:     this.state.error
        }
    }

    get value() {
        return this._value;
    }

    set value(value) {
        this._value = value;
    }

    checkRule(ruleName, rule) {
        const validator = Rules[ruleName]||((value, params) => true)
        return validator(this._value, rule, this.props.form)
    }

    trigger() {
        return this.validate().then(({error}) => {
            this.isTouched = true
            this.setState({error: error})
            return {state: this.fieldState, name: this.props.name, error: this.state.error}
        })
    }

    validate() {
        return new Promise(((resolve, reject) => {
            let isValid = true
            let error = null
            let previousMessage = this.state.error?.message
            if (this.props.rules) {
                for (const ruleName in this.props.rules) {
                    const rule = this.props.rules[ruleName]
                    if (!this.checkRule(ruleName, rule)) {
                        error = {message: rule.message ? rule.message : {key: 'global.form_errors.required', defaultVal: 'Campo obbligatorio'}, rule: ruleName}
                        isValid = false
                        break
                    }
                }
            }
            this.isValid = isValid
            this.state.error = error
            resolve({state: this.fieldState, name: this.props.name, error: error})
        }))
    }

    onChange(e, val) {
        this._value = val
        this.isDirty = true

        this.props.control.onChange ? this.props.control.onChange(e, val) : null
        /*if (this.props.validationMode === 'onChange') {
            return this.validate(val)
        }*/
    }

    onBlur(e, val) {
        //this.value = val
        this.props.control.onBlur ? this.props.control.onBlur(e, this._value) : null
        /*if (this.props.validationMode === 'onBlur') { // || this.props.validationMode === 'onChange'
            return this.validate(this.value)
        }*/

    }

    onFocus(e) {
        this.isTouched = true
        this.props.control.onFocus ? this.props.control.onFocus(e) : null
    }


    render() {
        return this.props.render({
            defaultValue: this.props.defaultValue,
            onChange:     (e, val) => this.onChange(e, val),
            onBlur:       (e, val) => this.onBlur(e, val),
            onFocus:      (e, val) => this.onFocus(e, val),
            forceUpdate:  (cb) => this.forceUpdate(cb),
            ...this.fieldState,
        })
    }
}

export default Controller
