import React, {useEffect} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T} from "@ticketag/i18nlib";
import {Grid} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Progress from "@ticketag/ticketag-uilib/src/components/base/Progress/Progress";
import Container from "@ticketag/ticketag-uilib/src/components/base/Container/Container";
import Noisy from "../../services/noisy/noisy";


const styles = (theme) => {
    return {
        root: {},
        content: {
            height: `${window.innerHeight}px`
        },
    }
};

const FullscreenProgress = ({classes, ...props}) => {
    useEffect(() => {
        Noisy.showNoise()
        return () => {
            Noisy.hideNoise()
        }
        // Your code here
    });
    return <Container>
        <Grid item>
            <Box classes={{root: classes.content}} display="flex" justifyContent="center" alignItems="center">
                <Progress/>
            </Box>
        </Grid>
    </Container>
}

export default withStyles(styles, {useTheme: true})(FullscreenProgress);
