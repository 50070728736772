import React, {useRef} from 'react';
import {withStyles} from '@material-ui/core/styles';
import TextField from "@ticketag/ticketag-uilib/src/components/base/TextField/TextField";
import ComboBox from "@ticketag/ticketag-uilib/src/components/base/Select/ComboBox";
import Form from "@ticketag/ticketag-uilib/src/components/complex/Form/Form";
import BirthdayPicker from "@ticketag/ticketag-uilib/src/components/base/DateTimeInput/BirthdayPicker";
import PhoneInput from "@ticketag/ticketag-uilib/src/components/base/PhoneInput/PhoneInput";
import Grid from "@material-ui/core/Grid";
import Button from "@ticketag/ticketag-uilib/src/components/base/Button/Button";
import Box from "@material-ui/core/Box/Box";
import {T, withI18n} from "@ticketag/i18nlib";

const styles = (theme) => {
    return {
        root:        {},
        inputs:      {
            height: '16px',
            //maxWidth: '220px',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            cursor: 'text',
        },
        inputNarrow: {
            maxWidth: '85px'
        },
        grid:        {
            marginBottom: theme.spacing(4)
        },
        label:       {
            textTransform: 'uppercase'
        },
        formControl: {
            width: '100%',
            //width: '195px',
            display: 'block'
        },
        form:        {
            padding: theme.spacing(0)
        },
        formInline:  {
            maxWidth:                     "280px",
            [theme.breakpoints.up('md')]: {
                maxWidth: "unset",
            },
        }
    }
};

const BillingDataForm = ({
                             classes,
                             onSubmit,
                             submitHandler,
                             inlineVariant = false,
                             beforeSubmit = (vals, formState, cb) => cb(vals),
                             onCancel, defaultValues, formRef, onChange, ...props
                         }) => {

    const form = useRef()


    const [isValid, setValid] = React.useState(false)
    const [countrySelected, setCountrySelected] = React.useState(null);
    if (defaultValues.country && defaultValues.country !== "" && !countrySelected) {
        setCountrySelected(true);
    }
    const onFormChange = (vals, formState) => {
        if (vals.country && vals.country !== "" && !countrySelected) {
            setCountrySelected(true);
        }
        setValid(formState.isValid)
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        form.current.trigger().then((isValid) => {
            if (isValid && onSubmit) {
                onSubmit(form.current.getValues());
            }
        });
    }

    const nationOptions = props.i18n.locales.map((locale) => ({
        label: locale.languageLabel,
        value: locale.uuid
    }));

    const inputGrid = inlineVariant ? {xs: 12, md: 6} : {xs: 12, md: 4};
    const inputBox = inlineVariant ? {mb: 4, mt: 0, pr: {md: 4}} : {mb: 6, mt: 0, px: {md: 3.5}};

    return <Form grid={{classes: {container: inlineVariant ? classes.formInline : classes.form}}}
                 T={T}
                 formRef={(f) => {
                     form.current = f;
                     formRef ? formRef(f) : null
                 }}
                 defaultValues={defaultValues}
                 onSubmit={(e) => onFormSubmit(e)}
                 onBlur={() => {}}
                 onChange={(vals, formState) => {onFormChange(vals, formState)}}
                 {...props}>
        <ComboBox grid={inputGrid} box={inputBox} variant={'outlined'} options={nationOptions} label="nazione"
                  onChange={() => {setCountrySelected(true)}}
                  name="country" rules={{required: true}}/>
        <Grid item xs={12}/>
        <TextField grid={inputGrid} box={inputBox} showErrors variant={'outlined'} label={"nome"} name="firstName"
                   rules={{required: true}} disabled={!countrySelected}/>
        <TextField grid={inputGrid} box={inputBox} showErrors variant={'outlined'} label={"cognome"} name="lastName"
                   rules={{required: true}} disabled={!countrySelected}/>
        <BirthdayPicker grid={inputGrid} box={inputBox} variant={'outlined'} label="data di nascita" name="birthday"
                        rules={{
                            required: true,
                            max: {
                                value:   new Date(),
                                type:    'date',
                                message: {
                                    key:        'components.billing_data_form.insert_valid_birthday',
                                    defaultVal: 'Inserisci una data di nascita valida'
                                }
                            }
                        }}
                        disabled={!countrySelected}/>
        <PhoneInput grid={inputGrid} box={inputBox} variant={'outlined'} label="telefono" name="phone"
                    rules={{
                        required: true,
                        pattern: {value: new RegExp(/^\+[1-9]{1}[0-9]{0,2}.?[0-9]{4,14}$/, 'i'), message: {
                                key:        'components.billing_data_form.insert_valid_phone',
                                defaultVal: 'Inserisci un telefono valido'
                            }} //
                    }}
                    disabled={!countrySelected}/>
        <TextField grid={inputGrid} box={inputBox} showErrors variant={'outlined'} label={"codice fiscale"} name="cf"
                   rules={{required: true}} disabled={!countrySelected}/>
        <TextField grid={inputGrid} box={inputBox} showErrors variant={'outlined'} label={"indirizzo"} name="address"
                   rules={{required: true}} disabled={!countrySelected}/>
        <TextField grid={inputGrid} box={inputBox} showErrors variant={'outlined'} label={"città"} name="city"
                   rules={{required: true}} disabled={!countrySelected}/>
        <TextField grid={inputGrid} box={inputBox} showErrors variant={'outlined'} label={"cap"} name="zipCode"
                   rules={{required: true}} disabled={!countrySelected}/>
        {
            inlineVariant
                ?
                <Box mt={8} width="100%" display="flex" justifyContent="center">
                    <Button fixedWidth disabled={!isValid} variant="outlined" color="gradient" type="submit"><T
                        defaultVal="Aggiungi">global.buttons.add</T></Button>
                </Box>
                :
                ""
        }
    </Form>

}

export default withStyles(styles, {useTheme: true})(BillingDataForm);
