import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {T, withI18n} from "@ticketag/i18nlib";
import Progress from "@ticketag/ticketag-uilib/src/components/base/Progress/Progress";
import Grid from "@material-ui/core/Grid";
import Typography from "@ticketag/ticketag-uilib/src/components/base/Typography/Typography";
import TextField from "@ticketag/ticketag-uilib/src/components/base/TextField/TextField";
import Box from "@material-ui/core/Box";
import Button from "@ticketag/ticketag-uilib/src/components/base/Button/Button";
import AvatarSelector from "@ticketag/ticketag-uilib/src/components/base/AvatarSelector/AvatarSelector";
import {withApi} from "../../hoc/Api/Provider";
import {WebapiprotoUserPreferencesUpdateMsg} from "@ticketag/diveedi-client/dist/model/WebapiprotoUserPreferencesUpdateMsg";
import {WebapiprotoUserName} from "@ticketag/diveedi-client/dist/model/WebapiprotoUserName";
import NameForm from "../../components/NameForm/NameForm";
import withWidth from "@material-ui/core/withWidth/withWidth";
import AuthContext from "../../hoc/Auth/context";
import Header from "@ticketag/ticketag-uilib/src/components/base/Header/Header";
import {Hidden} from "@material-ui/core";
import Layout from "../../hoc/Layout/Layout";
import Page from "../../components/Page/Page";
import Navbar from "../../components/Navbar/Navbar";
import NavTitle from "../../components/Navbar/NavTitle/NavTitle";
import {pages} from "../../constants";


const styles = (theme) => {
    return {
        root: {
            textAlign: "center",
        },
    }
};


class Welcome extends Component {
    static contextType = AuthContext

    constructor(props) {
        super(props);
        this.stepTitles= [
            {
                title: {key: 'pages.welcome.title', defaultVal: 'Benvenuto su Diveedi!'},
                subtitle: {key: 'pages.welcome.insert_name', defaultVal: 'Inserisci il tuo nome e cognome per continuare.'}
            },
            {
                title: {key: 'pages.welcome.select_avatar', defaultVal: 'Scegli il tuo avatar'},
                subtitle: {key: 'pages.welcome.select_avatar_subtitle', defaultVal: 'Seleziona l’avatar che preferisci per il tuo profilo Diveedi.'}
            },
        ]
        this.state = {
            isLoaded: false,
            currentStep: 0,
            stepText: this.stepTitles[0],
            selectedAvatar: null,
            formValid: false,
            formValues: {
                firstName: '',
                lastName: '',
            }
        }

    }

    getUserPreferences() {
        return this.props.api.userApi.getUserPreferences().then(data => {
            this.setState({isLoaded: true,formValues: data.name})
        })
    }

    setUserPreferencesName(nameForm) {
        return this.props.api.userApi.updateUserPreferences({
            name: nameForm,
            selectedAvatar: this.context.getUserInfo().avatarUuid
        }).catch((err) => {
            throw err
        })
    }
    setUserPreferencesAvatar(avatarUuid) {
        return this.props.api.userApi.updateUserPreferences({avatarUuid}).catch((err) => {
            throw err
        })
    }

    componentDidMount() {
        if (this.context.getUserInfo().complete) {
           // this.props.history.push(this.props.i18n.buildLocalizedUrl('groups'))
           // return
        }

        this.getUserPreferences().then(data => {
            this.setState({isLoaded: true})
        })
    }

    isContinueDisabled() {
        return this.state.currentStep === 1 && !this.state.selectedAvatar || this.state.currentStep === 0 && !this.state.formValid;
    }

    setLoading(callback=() => {}) {
        this.setState({isLoaded: false}, callback)
    }

    validateForm(formState) {
        this.setState({
            formValues: this.form.getValues(),
            formValid: formState.isValid
        })
    }

    onContinue() {
        switch (this.state.currentStep) {
            case 0:
                this.setLoading(() => {
                    this.setUserPreferencesName(this.state.formValues).then(data => {
                        this.setState({
                            currentStep: 1,
                            stepText: this.stepTitles[1],
                            isLoaded: true
                        })
                    })
                })
            break;
            case 1:
                this.setUserPreferencesAvatar(this.state.selectedAvatar).then(() => {
                    this.context.refreshUserInfo().then(() =>  {
                        this.props.history.push(this.props.i18n.buildLocalizedUrl('groups'));
                    })
                })
        }
    }



    renderStep() {
        const smallScreen = ['xs', 'sm'].indexOf(this.props.width) !== -1
        if (this.state.currentStep === 1) {
            return <Box key={"select_avatar"} py={0}>
                <AvatarSelector
                    onSelect={(val) => {this.setState({selectedAvatar: val})}}
                    defaultValue={this.state.selectedAvatar}
                    size={smallScreen? 'medium' : 'large'}/>
            </Box>
        }
        return <Box key={"name_form"} width={{xs: "100%", md: "60%", lg: "30%"}}>
            <NameForm defaultValues={this.state.formValues} onValidate={(formState) => {this.validateForm(formState)}} formRef={(f) => this.form=f} classes={{root: this.props.classes.root}}/>
        </Box>
    }

    renderTitles() {
        return <Box mb={6} key={this.state.currentStep}>
            <Hidden xsDown>
            <Header mb={{xs: 4, md: 6}} align={"center"}><T defaultVal={this.state.stepText.title.defaultVal}>{this.state.stepText.title.key}</T></Header>
            </Hidden>
            <Typography variant="h4" color={"textPrimary"} align={"center"}><T defaultVal={this.state.stepText.subtitle.defaultVal}>{this.state.stepText.subtitle.key}</T></Typography>
        </Box>
    }

    renderContent() {
        return (
            <Grid container justify={"center"}>
                <Grid item xs={12}>
                        {this.renderTitles()}
                </Grid>
                <Grid item xs={12} classes={{root: this.props.classes.root}}>
                    <Box px={1} display="flex" flexDirection="column" alignItems="center" justifyContent={"center"}>
                        {this.renderStep()}
                        <Box pt={7} pb={5}>
                            <Button disabled={this.isContinueDisabled()} variant={"contained"} onClick={(e) => {this.onContinue(e)}}>
                                <T defaultVal="Continua">forms.buttons.continue</T>
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        )

    }

    render() {
        const {
            ...other
        } = this.props;
        const {isLoading} = !this.state.isLoaded
        if (isLoading) {
            return null
        }
        return (
            <Page {...other}>
                    {this.renderContent()}
            </Page>
        )
    }
}


export default withStyles(styles, {useTheme: true, withTheme: true})(withApi(withWidth()(withI18n(Welcome))));
